import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { options } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import axios from "axios";
import API_BASE_URL from "../../../../../env";

const ClinicBranches = (props) => {
  const {
    clinicDetails,
    enableForm,
    item,
    handleDeleteClinicBranch,
    handleGetClinicDetails,
    handleGetAllclinicBranches,
  } = props;
  const [editForm] = Form.useForm();
  const [editClinicBranchModal, setEditClinicBranchModal] = useState(false);
  const Token = localStorage.getItem("Token");
  const role = localStorage.getItem("role");

  const validatePhoneNumber = (_, value) => {
    const numericRegex = /^[0-9]+$/;
    if (!value || (numericRegex.test(value) && value.length === 10)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Please enter exactly 10 numeric characters for the phone number."
    );
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter 6-digit zip code!");
  };

  const handleEditClinicBranchDetails = async (values) => {
    // setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    const payload = {
      branch_name: item.branch_name,
      address_line_one: values.address_line_one,
      address_line_two: values.address_line_two,
      phone_number: values.phone_number,
      city: values.city,
      province: values.province,
      country: values.country,
      zip_code: values.zip_code,
      profile_picture_url: clinicDetails.profile_picture_url,
      email: item.email,
      phone_country_code: values.phone_country_code,
    };
    await axios
      .put(
        `${API_BASE_URL}/api/v1/clinicBranch/updateClinicDetails/${item.branch_id}`,
        payload,
        config
      )
      .then((res) => {
        // setSpinner(false);
        setEditClinicBranchModal(false);
        message.success("Clinic Branch Updated Successfully");
        handleGetClinicDetails();
        handleGetAllclinicBranches();
      })
      .catch((err) => {
        message.error("Updating clinic was failed");
        // setSpinner(false);
      });
  };

  return (
    <div>
      <Row>
        <Row className="d-flex ai-center jc-between w-100">
          <Col span={16}>
            <Row className="d-flex ai-end" style={{ gap: "0.782vw" }}>
              <span
                style={{
                  fontSize: "1.0417vw",
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                {item.branch_name}
              </span>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 500,
                  color: "#3498DB",
                }}
              >
                CBID #{item.branch_id}
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 500,
                  color: "#717171",
                }}
              >
                {item.address_line_two +
                  `, ` +
                  item.city +
                  `, ` +
                  item.province +
                  `, ` +
                  item.country +
                  `, ` +
                  item.zip_code}
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 500,
                  color: "#717171",
                }}
              >
                {item.phone_country_code + ` ` + item.phone_number}
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 500,
                  color: "#717171",
                }}
              >
                {item.email}
              </span>
            </Row>
          </Col>
          {role === "CLINIC_MASTER_ADMIN" && (
            <Col
              span={8}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 12,
              }}
            >
              <Button
                onClick={() => {
                  // setitem(item);
                  setEditClinicBranchModal(true);
                }}
                style={{
                  height: "1.67vw",
                  border: "1px solid #2CA3FA",
                  borderRadius: "1.563vw",
                  color: "#2CA3FA",
                  background: "#FFF",
                  fontSize: "0.73vw",
                  fontWeight: 600,
                }}
              >
                Edit
              </Button>
              <Button
                style={{
                  height: "1.67vw",
                  border: "1px solid #EC7063",
                  borderRadius: "1.563vw",
                  color: "#EC7063",
                  background: "#FFF",
                  fontSize: "0.73vw",
                  fontWeight: 600,
                }}
                onClick={() => handleDeleteClinicBranch(item.branch_id)}
              >
                Delete
              </Button>
            </Col>
          )}
        </Row>
        <Row style={{ padding: "0.782vw 0" }} className="w-100">
          <div
            style={{
              height: 1,
              background: "#D8D8D8",
              width: "100%",
            }}
          ></div>
        </Row>
      </Row>

      <Modal
        width={"58.2293vw"}
        centered
        open={editClinicBranchModal}
        footer={false}
        closable={false}
        destroyOnClose={true}
      >
        {item && (
          <Form
            form={editForm}
            onFinish={handleEditClinicBranchDetails}
            className="w-100"
          >
            <div style={{ padding: "1.0417vw" }}>
              <Row>
                <span
                  style={{
                    color: "#3498DB",
                    fontSize: "1.25vw",
                    fontWeight: 500,
                  }}
                >
                  Edit Branch
                </span>
              </Row>

              {/* Hospital info */}
              <Row style={{ margin: "1.563vw 0 0 0" }}>
                <span
                  style={{
                    color: "#333333",
                    fontSize: "0.834vw",
                    fontWeight: 700,
                  }}
                >
                  Hospital Infomation
                </span>
              </Row>
              {clinicDetails && (
                <Row className="d-flex jc-between" style={{ margin: "1.25vw 0" }}>
                  <Col span={8}>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                          color: "#2F2F2F",
                        }}
                      >
                        Clinic Name <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Input
                        disabled={enableForm}
                        value={clinicDetails.name}
                        style={{
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: "0.16vw",
                          border: "1px solid #DADADA",
                          color: "black",
                        }}
                      />
                    </Row>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                          color: "#2F2F2F",
                        }}
                      >
                        Clinic Identification{" "}
                        <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Input
                        value={"CID #" + clinicDetails.clinic_id}
                        disabled={enableForm}
                        style={{
                          color: "black",
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: "0.16vw",
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Row>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                          color: "#2F2F2F",
                        }}
                      >
                        Email <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Input
                        value={clinicDetails.email}
                        style={{
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: "0.16vw",
                          border: "1px solid #DADADA",
                          color: "black",
                        }}
                        disabled={enableForm}
                      />
                    </Row>
                  </Col>
                </Row>
              )}
              <Row className="d-flex jc-between">
                <Col span={8}>
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontSize: "0.73vw",
                        fontWeight: 400,
                        color: "#2F2F2F",
                      }}
                    >
                      Phone no. <span style={{ color: "#FF0000" }}>*</span>
                    </span>
                    <Space.Compact
                      className="w-100"
                      style={{
                        width: "16.771vw",
                      }}
                    >
                      {/* {item.phone_country_code && ( */}
                      <Form.Item
                        name="phone_country_code"
                        initialValue={item.phone_country_code}
                      >
                        <Select
                          bordered={false}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "2.0834vw",
                            background: "#FFFFFF",
                            border: "1px solid #E3E3E3",
                            margin: "3px 0 0 0",
                          }}
                          options={options}
                        />
                      </Form.Item>
                      {/* )} */}
                      {/* {item.phone_number && ( */}
                      <Form.Item
                        style={{ width: "100%" }}
                        name="phone_number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter phone number",
                          },
                          { validator: validatePhoneNumber },
                        ]}
                        initialValue={item.phone_number}
                      >
                        <Input
                          bordered={false}
                          style={{
                            height: "2.0834vw",
                            background: "#FFFFFF",
                            borderTop: "1px solid #E3E3E3",
                            borderRight: "1px solid #E3E3E3",
                            borderBottom: "1px solid #E3E3E3",
                            margin: "3px 0 0 0",
                          }}
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            if (!(charCode >= 48 && charCode <= 57)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                      {/* )} */}
                    </Space.Compact>
                  </Row>
                </Col>
              </Row>
              {/* Branch address */}
              <Row style={{ margin: "1.563vw 0 0 0" }}>
                <span
                  style={{
                    color: "#333333",
                    fontSize: "0.834vw",
                    fontWeight: 700,
                  }}
                >
                  Branch Address
                </span>
              </Row>
              <Row style={{ margin: "1.25vw 0" }}>
                {/* <Col span={8}>
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontSize: "0.73vw",
                        fontWeight: 400,
                        color: "#2F2F2F",
                      }}
                    >
                      Apartment <span style={{ color: "#FF0000" }}>*</span>
                    </span>
                    <Form.Item
                      name="address_line_one"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Apartment",
                        },
                      ]}
                      initialValue={item.address_line_one}
                    >
                      <Input
                        style={{
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: "0.16vw",
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Form.Item>
                  </Row>
                </Col> */}
                <Col span={8}>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.73vw",
                        fontWeight: 400,
                        color: "#2F2F2F",
                      }}
                    >
                      Address <span style={{ color: "#FF0000" }}>*</span>
                    </span>
                    {/* {item.address_line_two && ( */}
                    <Form.Item
                      name="address_line_two"
                      rules={[
                        {
                          required: true,
                          message: "Please enter address",
                        },
                      ]}
                      initialValue={item.address_line_two}
                    >
                      <Input
                        style={{
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: "0.16vw",
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Form.Item>
                    {/* )} */}
                  </Row>
                </Col>
                <Col
                  span={8}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontSize: "0.73vw",
                        fontWeight: 400,
                        color: "#2F2F2F",
                      }}
                    >
                      City <span style={{ color: "#FF0000" }}>*</span>
                    </span>
                    {/* {item.city && ( */}
                    <Form.Item
                      name="city"
                      rules={[
                        { required: true, message: "Please enter City" },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and City must contain only alphabeticals",
                        },
                      ]}
                      initialValue={item.city}
                    >
                      <Input
                        style={{
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: "0.16vw",
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Form.Item>
                    {/* )} */}
                  </Row>
                </Col>
                <Col span={8}>
                  <Row style={{ display: "flex", flexDirection: "flex-end" }}>
                    <span
                      style={{
                        fontSize: "0.73vw",
                        fontWeight: 400,
                        color: "#2F2F2F",
                      }}
                    >
                      State / Province{" "}
                      <span style={{ color: "#FF0000" }}>*</span>
                    </span>
                    {/* {item.province && ( */}
                    <Form.Item
                      name="province"
                      rules={[
                        {
                          required: true,
                          message: "Please enter State / Province",
                        },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and State/Province must contain only alphabeticals",
                        },
                      ]}
                      initialValue={item.province}
                    >
                      <Input
                        style={{
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: "0.16vw",
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Form.Item>
                    {/* )} */}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontSize: "0.73vw",
                        fontWeight: 400,
                        color: "#2F2F2F",
                      }}
                    >
                      Country <span style={{ color: "#FF0000" }}>*</span>
                    </span>
                    {/* {item.country && ( */}
                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Country",
                        },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and Country must contain only alphabeticals",
                        },
                      ]}
                      initialValue={item.country}
                    >
                      <Input
                        style={{
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: "0.16vw",
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Form.Item>
                    {/* )} */}
                  </Row>
                </Col>
                <Col
                  span={8}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontSize: "0.73vw",
                        fontWeight: 400,
                        color: "#2F2F2F",
                      }}
                    >
                      ZIP Code <span style={{ color: "#FF0000" }}>*</span>
                    </span>
                    {/* {item.zip_code && ( */}
                    <Form.Item
                      name="zip_code"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Zip code",
                        },
                        { validator: validateZipCode },
                      ]}
                      initialValue={item.zip_code}
                    >
                      <Input
                        style={{
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: "0.16vw",
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Form.Item>
                    {/* )} */}
                  </Row>
                </Col>
              </Row>
              <Row
                className="d-flex jc-end ai-center"
                style={{ gap: 12, margin: "1.25vw 0 0 0" }}
              >
                <Form.Item>
                  <Button
                    className="cancel-btn"
                    onClick={() => {
                      editForm.resetFields();
                      setEditClinicBranchModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Button
                  style={{
                    height: "1.67vw",
                    border: "1px solid #2CA3FA",
                    borderRadius: "1.563vw",
                    color: "#2CA3FA",
                    background: "#FFF",
                    fontSize: "0.73vw",
                    fontWeight: 600,
                  }}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Row>
            </div>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default ClinicBranches;
