import { Row } from "antd";
import React from "react";
import Ivorylogo from "../../assets/ivory-ai-logo.png";
import { UserAuth } from "../../context_api/ContextApi";

const SignupThanksMsg = () => {
  const { signupUserName } = UserAuth();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Row style={{ height: "10vh" }}>
        <img
          style={{ height: "6.68vh", margin: "2.15%" }}
          data-testid="Ivory-logo"
          src={Ivorylogo}
          alt=""
        />
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          width: "100%",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "40px 40px 0px 60px",
            background: "#F8F8F8",
            width: "30.96vw",
            height: "30.68vh",
            borderRadius: "20px",
            gap: "7vh",
          }}
        >
          <Row style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontSize: "40px",
                fontWeight: "400px",
                lineHeight: "46.88px",
                color: "#2CA3FA",
              }}
            >
              Hello!
            </span>
            <span
              style={{
                fontSize: "40px",
                fontWeight: "400px",
                lineHeight: "46.88px",
                color: "#2CA3FA",
              }}
            >
              {signupUserName && signupUserName}
            </span>
          </Row>
          <Row>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "500px",
                lineHeight: "28.88px",
              }}
            >
              Thank you for choosing us.
            </span>
          </Row>
        </Row>
      </Row>
    </div>
  );
};

export default SignupThanksMsg;
