import { Button, Carousel, Col, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import xrayicon from "../../../../../assets/xray-icon.png";
import leftarrow from "../../../../../assets/leftarrow.png";
import rightarrow from "../../../../../assets/rightarrow.png";
import ReportViewImage from "./ReportViewImage";
import axios from "axios";
import API_BASE_URL from "../../../../../env";
import { UserAuth } from "../../../../../context_api/ContextApi";
import ReportFullImage from "./ReportFullImage";
import { LoadingOutlined } from "@ant-design/icons";

const ReportView = ({ allReportDetails, type }) => {
  const carouselRef = useRef();
  const Token = localStorage.getItem("Token");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideData, setCurrentSlideData] = useState(0);
  const [currentSlideReportImage, setCurrentSlideReportImage] = useState(0);
  const [currentSlideReportId, setCurrentSlideReportId] = useState(0);

  const [zoomImageData, setzoomImageData] = useState();

  const onChange = (current) => {
    setcommonArray("");
    setRectangles("");
    setCurrentSlide(current);
    setCurrentSlideData(allReportDetails[current]);
    setCurrentSlideReportImage(allReportDetails[current].report_image);
    setCurrentSlideReportId(allReportDetails[current].id);
  };

  const handlePrev = () => {
    carouselRef.current.prev();
  };

  const handleNext = () => {
    carouselRef.current.next();
  };

  const {
    rectangles,
    setRectangles,
    setReportRefresh,
    commonArray,
    setcommonArray,
    reportRefresh,
  } = UserAuth();

  const handleGettingMLDetails = async () => {
    const payload = {
      path: currentSlideReportImage
        ? currentSlideReportImage
        : allReportDetails && allReportDetails[0].report_image,
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .put(
        `${API_BASE_URL}/api/v1/report/ml-analysis/${
          currentSlideReportId
            ? currentSlideReportId
            : allReportDetails && allReportDetails[0].id
        }`,
        payload,
        config
      )
      .then((res) => {
        setRectangles(JSON.parse(res.data.data).results);
        setReportRefresh(false);
      })
      .catch((err) => {
        setReportRefresh(false);
      });
  };

  useEffect(() => {
    handleGettingMLDetails();
  }, [
    currentSlideReportImage,
    currentSlideReportId,
    currentSlideData.file_name,
  ]);

  const handleCommonArray = () => {
    const uniqueArray = [];
    const uniqueNames = {};

    rectangles &&
      rectangles.forEach((obj) => {
        const name = obj.name;
        if (!uniqueNames[name]) {
          uniqueNames[name] = true;
          uniqueArray.push(obj);
        }
      });
    setcommonArray(uniqueArray);
  };

  useEffect(() => {
    handleCommonArray();
  }, [rectangles]);

  // const slidesToShow = 5;
  const slidesToShow = Math.min(allReportDetails && allReportDetails.length, 5);

  const [openFullimageModal, setOpenFullimageModal] = useState(false);

  return (
    <div
      style={{
        height: "70vh",
        overflow: "auto",
      }}
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: 50, margin: "200px 0 0 0" }}
            spin
          />
        }
        size="large"
        spinning={reportRefresh && true}
        delay={500}
      >
        <Row style={{ padding: "24px 0", display: "flex", gap: 5 }}>
          <img alt="" src={xrayicon} style={{ height: 22 }} />
          <span>{type}</span>
        </Row>
        <Row className="d-flex ai-center " style={{ padding: "0 24px" }}>
          <Col span={1}>
            {allReportDetails && allReportDetails.length > 0 && (
              <img
                onClick={handlePrev}
                alt=""
                src={leftarrow}
                style={{ height: 28, cursor: "pointer" }}
              />
            )}
          </Col>
          <Col span={22}>
            <div
              style={{
                width:
                  (allReportDetails &&
                    allReportDetails.length === 1 &&
                    "15vw") ||
                  (allReportDetails &&
                    allReportDetails.length === 2 &&
                    "30vw") ||
                  (allReportDetails &&
                    allReportDetails.length === 3 &&
                    "45vw") ||
                  (allReportDetails && allReportDetails.length === 4 && "60vw"),
              }}
            >
              {allReportDetails && allReportDetails.length > 0 ? (
                <Carousel
                  infinite
                  afterChange={onChange}
                  ref={carouselRef}
                  slidesToShow={slidesToShow}
                >
                  {allReportDetails &&
                    allReportDetails.length > 0 &&
                    allReportDetails.map((item, index) => {
                      return (
                        <div
                          className={
                            currentSlide === index
                              ? "current-xray-card-main-div"
                              : "xray-card-main-div"
                          }
                          key={index}
                        >
                          <Row
                            style={{
                              borderRadius: 5,
                              height: "112px",
                              overflow: "hidden",
                            }}
                          >
                            <ReportViewImage
                              setOpenFullimageModal={setOpenFullimageModal}
                              openFullimageModal={openFullimageModal}
                              imageUrl={item.report_image.split(".com/")[1]}
                              currentSlide={currentSlide}
                              index={index}
                            />
                          </Row>
                          <Row
                            style={{ paddingTop: 16 }}
                            className="d-flex ai-center jc-between"
                          >
                            <div style={{ gap: 5 }}>
                              <span>{index + 1}.</span>
                              <span className="xray-filename">
                                {item.file_name}
                              </span>
                            </div>
                            {currentSlide === index && (
                              <Button
                                onClick={() => {
                                  setOpenFullimageModal(true);
                                  setzoomImageData(
                                    item.report_image.split(".com/")[1]
                                  );
                                }}
                              >
                                Zoom image
                              </Button>
                            )}
                          </Row>
                        </div>
                      );
                    })}
                </Carousel>
              ) : (
                <Row
                  className="d-flex jc-center"
                  style={{
                    margin: "0 120px",
                    padding: "120px 12px",
                  }}
                >
                  <span
                    style={{
                      color: "#525252",
                      fontSize: 22,
                      padding: "14px 0",
                      fontWeight: 600,
                    }}
                  >
                    You don't have any {type}
                  </span>
                </Row>
              )}
            </div>
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }} span={1}>
            {allReportDetails && allReportDetails.length > 0 && (
              <img
                onClick={handleNext}
                alt=""
                src={rightarrow}
                style={{ height: 28, cursor: "pointer" }}
              />
            )}
          </Col>
        </Row>
        <Row style={{ borderBottom: "1px solid #DEDEDE", margin: "0 120px" }}>
          <span
            style={{
              color: "#1E1E1E",
              fontSize: 18,
              padding: "18px 0",
              fontWeight: 600,
            }}
          >
            {currentSlideData
              ? currentSlideData.file_name
              : allReportDetails &&
                allReportDetails.length > 0 &&
                allReportDetails[0].file_name}
          </span>
        </Row>
        {type === "xrays" && (
          <>
            {commonArray && commonArray.length > 0 && (
              <>
                <Row style={{ margin: "0 120px" }}>
                  <span
                    style={{
                      color: "#525252",
                      fontSize: 18,
                      padding: "14px 0",
                      fontWeight: 600,
                    }}
                  >
                    ML Details
                  </span>
                </Row>
                <Row
                  style={{
                    background: "#F2F2F2",
                    margin: "0 120px",
                    padding: 12,
                  }}
                >
                  <Col span={4}>
                    <span
                      style={{
                        color: "#525252",
                        fontSize: 18,
                        padding: "14px 0",
                        fontWeight: 600,
                      }}
                    >
                      Teeth Number
                    </span>
                  </Col>
                  <Col span={6}>
                    <span
                      style={{
                        color: "#525252",
                        fontSize: 18,
                        padding: "14px 0",
                        fontWeight: 600,
                      }}
                    >
                      Issue Identified
                    </span>
                  </Col>
                  <Col span={8}>
                    <span
                      style={{
                        color: "#525252",
                        fontSize: 18,
                        padding: "14px 0",
                        fontWeight: 600,
                      }}
                    >
                      Confidential Level
                    </span>
                  </Col>
                </Row>
              </>
            )}
            {allReportDetails &&
            allReportDetails.length > 0 &&
            commonArray &&
            commonArray.length > 0 ? (
              commonArray.map((item) => {
                return (
                  <>
                    <Row
                      style={{
                        margin: "0 120px",
                        padding: 12,
                        borderBottom: "1px solid #D1D1D1",
                      }}
                      className="d-flex ai-center"
                    >
                      <Col span={4}>
                        <span
                          className="xray-details-heading d-flex ai-center"
                          style={{ gap: 10 }}
                        >
                          {item.name === "dental_caries" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#01A5E4",
                              }}
                            ></div>
                          )}
                          {item.name === "restorations" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#8DD7C0",
                              }}
                            ></div>
                          )}
                          {item.name === "bone loss" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#FF96C6",
                              }}
                            ></div>
                          )}
                          {item.name === "prosthetics" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#FE5766",
                              }}
                            ></div>
                          )}
                          {item.name === "periapical abnormality" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#FFBF65",
                              }}
                            ></div>
                          )}
                          {item.name === "root stump" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#FB6238",
                              }}
                            ></div>
                          )}
                          {item.name === "absent_tooth" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#FFD871",
                              }}
                            ></div>
                          )}
                          {item.name === "fracture" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#F2D4CC",
                              }}
                            ></div>
                          )}
                          {item.name === "other" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#E77477",
                              }}
                            ></div>
                          )}
                          {item.name === "microdontia" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#6C89C5",
                              }}
                            ></div>
                          )}
                          {item.name === "macrodontia" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#C05780",
                              }}
                            ></div>
                          )}
                          {item.name === "position" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#FF828A",
                              }}
                            ></div>
                          )}
                          {item.name === "altered morphology" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#E8C583",
                              }}
                            ></div>
                          )}
                          {item.name === "impacted_tooth" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#00B1BB",
                              }}
                            ></div>
                          )}
                          {item.name === "implants" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#00B1BB",
                              }}
                            ></div>
                          )}
                          {item.name === "attrition" && (
                            <div
                              style={{
                                width: 16,
                                height: 16,
                                background: "#CFF800",
                              }}
                            ></div>
                          )}
                        </span>
                      </Col>
                      <Col span={6}>
                        <span
                          style={{
                            color: "#1E1E1E",
                            fontSize: 16,
                            padding: "14px 0",
                            fontWeight: 400,
                          }}
                        >
                          {item.name}
                        </span>
                      </Col>
                      <Col span={8}>
                        <span
                          style={{
                            color: "#1E1E1E",
                            fontSize: 16,
                            padding: "14px 0",
                            fontWeight: 400,
                          }}
                        >
                          {item.confidence}
                        </span>
                      </Col>
                    </Row>
                  </>
                );
              })
            ) : (
              <Row
                className="d-flex jc-center"
                style={{
                  margin: "0 120px",
                  padding: "120px 12px",
                }}
              >
                <span
                  style={{
                    color: "#525252",
                    fontSize: 22,
                    padding: "14px 0",
                    fontWeight: 600,
                  }}
                >
                  ML Analysis not available
                </span>
              </Row>
            )}
          </>
        )}
      </Spin>
      {/* <Row style={{ margin: "0 120px" }}>
        <span
          style={{
            color: "#525252",
            fontSize: 18,
            padding: "33.5px 0 14px 0",
            fontWeight: 600,
          }}
        >
          Diagnosis
        </span>
        <span
          style={{
            color: "#525252",
            fontSize: 14,
            // padding: "14px 0",
            fontWeight: 400,
          }}
        >
          Lorem ipsum dolor sit amet consectetur. Arcu elit non rhoncus sodales
          tristique sem nullam. Commodo rutrum laoreet purus sed at nulla augue.
          Turpis commodo magna at consequat imperdiet at a mi tempor. Nec nulla
          vestibulum lectus fringilla sed nulla. Odio neque arcu in et ut
          praesent semper volutpat condimentum. Eget tortor nulla porta ac a.
          Praesent nec at ultricies elementum dapibus non senectus eu sit. Odio
          rutrum lorem ac eget gravida magna sapien arcu. Tincidunt eget amet
          scelerisque nec odio elit.
        </span>
      </Row>
      <Row style={{ margin: "0 120px" }}>
        <span
          style={{
            color: "#525252",
            fontSize: 18,
            padding: "33.5px 0 14px 0",
            fontWeight: 600,
          }}
        >
          Treatment
        </span>
        <span
          style={{
            color: "#525252",
            fontSize: 14,
            // padding: "14px 0",
            fontWeight: 400,
          }}
        >
          Lorem ipsum dolor sit amet consectetur. Arcu elit non rhoncus sodales
          tristique sem nullam. Commodo rutrum laoreet purus sed at nulla augue.
          Turpis commodo magna at consequat imperdiet at a mi tempor. Nec nulla
          vestibulum lectus fringilla sed nulla. Odio neque arcu in et ut
          praesent semper volutpat condimentum. Eget tortor nulla porta ac a.
          Praesent nec at ultricies elementum dapibus non senectus eu sit. Odio
          rutrum lorem ac eget gravida magna sapien arcu. Tincidunt eget amet
          scelerisque nec odio elit.
        </span>
      </Row> */}

      <ReportFullImage
        imageUrl={zoomImageData}
        setOpenFullimageModal={setOpenFullimageModal}
        openFullimageModal={openFullimageModal}
      />
    </div>
  );
};

export default ReportView;
