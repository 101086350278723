import { Button, Col, Row, message } from "antd";
import React from "react";
import axios from "axios";
import API_BASE_URL from "../../../../../env";
import AppointmentProfilePic from "../../admin_appointments/AppointmentProfilePic";

const PatientJoinToClinic = (props) => {
  const { user_id, clinicId, item } = props;
  const Token = localStorage.getItem("Token");

  const handleInvitePatientToJoinClinic = async (user_id) => {
    const payload = {
      clinic_id: clinicId,
      patient_id: user_id,
      title: "Join Clinic Invitation",
      message: "Please Join To our clinic",
      notification_type: "join_clinic",
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .post(`${API_BASE_URL}/api/v1/notification`, payload, config)
      .then((res) => {
        message.success("Request sent successfully");
      })
      .catch((err) => {
        message.error("Request failed");
      });
  };
  return (
    <Row className="doctor-details-row">
      <Row
        className="d-flex w-100 ai-center"
        style={{
          padding: "21px 30px",
        }}
      >
        <Col span={1}></Col>
        <Col span={5}>
          <Row>
            <Col>
              <AppointmentProfilePic
                pro={
                  item &&
                  item.profile_picture_url &&
                  item.profile_picture_url.split(".com/")[1]
                }
              />
            </Col>
            <Col
              span={15}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 0 0 10px",
              }}
            >
              <Row>
                <span
                  style={{
                    // fontWeight: 600,
                    color: "#3498DB",
                    fontSize: 16,
                    fontWeight: 600,
                    // fontSize: "0.73vw",
                    // // textDecorationLine: "underline",
                    // color: "#2CA3FA",
                    // display: "block",
                    // width: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {/* {data.doctor.name} */}
                  {item.name}
                </span>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.73vw",
                    color: "#000000",
                  }}
                >
                  SID #
                  <span
                    style={{
                      color: "#7D7D7D",
                    }}
                  >
                    {/* {data.doctor.id} */}
                    {item.user_id}
                  </span>
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={16}></Col>
        <Col
          span={2}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              border: "1px solid #009DF7",
              borderRadius: "3px",
              fontWeight: 600,
              fontSize: "0.73vw",
              color: "#2CA3FA",
              height: "1.67vw",
              padding: "0.208vw 0.83vw",
            }}
            onClick={() => handleInvitePatientToJoinClinic(user_id)}
          >
            Join Clinic
          </Button>
        </Col>
      </Row>
    </Row>
    // </>
  );
};

export default PatientJoinToClinic;
