import { Row, Button, Modal, Input, message, Switch } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "./PatientAccountSettings.css";
import { UserAuth } from "../../../../context_api/ContextApi";
import API_BASE_URL from "../../../../env";
import deleteUserIcon from "../../../../assets/delete-user-icon.png";
import resetSuccessLogo from "../../../../assets/reset-password-success.png";
import errorDeleting from "../../../../assets/error-delete-acc.png";
import cancelIcon from "../../../../assets/cancel-button.png";
import {
    routes_forgot_password,
    routes_patientlogin,
} from "../../../../utils/patient_navbar_constants/PatientNavbarConstants"
import { useNavigate } from "react-router";

const PatientAccountSettings = () => {
    const Token = localStorage.getItem("Token");
    const { allPatientNotificationsMap, handleGetAllPatientNotifications } =
        UserAuth();
    const inputRefs = useRef([]);
    const intervalRef = useRef(null);
    const navigate = useNavigate();
    const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);
    const [password, setPassword] = useState('');
    const [step, setStep] = useState(1);
    const [otpCode, setOtpCode] = useState(['', '', '', '']);
    const [resendTimer, setResendTimer] = useState(30);
    const [isResendEnabled, setIsResendEnabled] = useState(false);
    const [isResendInProgress, setIsResendInProgress] = useState(false);
    const [isPasswordReset, setPasswordReset] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isInvalidOTP, setInvalidOTP] = useState(false);
    const email = localStorage.getItem("email");
    const patientId = localStorage.getItem("patient_id");

    const config = {
        headers: {
            Authorization: `Bearer ${Token}`,
        },
    };

    const handleDeleteAccount = () => {
        setOpenDeleteAccountModal(true);
    }

    const handleCancel = () => {
        setStep(1);
        setOpenDeleteAccountModal(false);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleVerifyPassword = async () => {
        setLoading(true);
        const payload = {}
        await axios.post(
            `${API_BASE_URL}/api/v1/patient/${patientId}/${password}`, payload, config
        ).then((res) => {
            message.success(res.data.data)
            setStep(step + 1);
            setLoading(false);
            startResendTimer();
        })
            .catch((err) => {
                message.error(err.response.data.data.errors);
                setLoading(false);
            });
    }

    useEffect(() => {
        // Focus on the first input field when the component mounts
        inputRefs.current[0]?.focus();

        // Add event listener for keydown event
        document.addEventListener('keydown', handleKeyClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyClick);
        };
    }, []);

    useEffect(() => {
        const cleanUpTimer = () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };

        return cleanUpTimer;
    }, [openDeleteAccountModal]);


    const handleKeyClick = (event) => {
        const { key, target, currentTarget: inputFields } = event;
        const inputs = Array.from(inputFields.querySelectorAll('input'));
        const currentIndex = inputs.indexOf(target);

        if (key === 'ArrowRight') {
            if (currentIndex < inputs.length - 1) {
                inputs[currentIndex + 1].focus();
            }
        } else if (key === 'ArrowLeft') {
            if (currentIndex > 0) {
                inputs[currentIndex - 1].focus();
            }
        }
    };

    const handleOTPChange = (index, value) => {
        setInvalidOTP(false);
        const updatedOtpCode = [...otpCode];
        updatedOtpCode[index] = value;
        setOtpCode(updatedOtpCode);

        if (value && index < otpCode.length - 1) {
            inputRefs.current[index + 1].focus();
        }

        if (!value && index > 0 && updatedOtpCode[index - 1]) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleResendOTP = async () => {
        if (isResendInProgress) return; // If resend is already in progress, do nothing
        setIsResendInProgress(true); // Set the resend in progress flag

        try {
            const res = await axios.post(
                `${API_BASE_URL}/api/v1/auth/resendOTP?email=${email}`
            );
            message.success(res.data.data.message);
            startResendTimer();
        } catch (err) {
            message.error(err.response.data.data.errors);
        } finally {
            setIsResendInProgress(false); // Reset the resend in progress flag
        }
        setTimeout(() => {
        }, 1500);
    };

    const startResendTimer = () => {
        setIsResendEnabled(false);
        intervalRef.current = setInterval(() => {
            setResendTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(intervalRef.current);
            setIsResendEnabled(true);
            setResendTimer(30);
        }, 30000);
    };

    const handleDeleteNow = async () => {
        let otp = otpCode.join("")
        await axios
            .post(
                `${API_BASE_URL}/api/v1/auth/validateOTP?email=${email}&enteredOtp=${otp}`
            )
            .then((res) => {
                message.success(res.data.data.message);
                deleteAccount();
            })
            .catch((err) => {
                message.error(err.response.data.data.errors);
                setInvalidOTP(true);
            });
        setTimeout(() => {
            setOtpCode(['', '', '', '']);
        }, 1500);
    }

    const deleteAccount = async () => {
        await axios
            .delete(`${API_BASE_URL}/api/v1/patient/${patientId}`, config)
            .then(() => {
                setStep(step + 1);
                setPasswordReset(true);

                setTimeout(() => {
                    navigate(routes_patientlogin);
                }, 5000)
            })
    }

    return (
        <div className="patient-profile-main-div">
            <Helmet>
                <title>Account Settings</title>
            </Helmet>
            <Row className="patient-profile-row">
                <Row className="account-settings-row"
                    style={{
                        padding: "0 0 1.563vw",
                        borderBottom: "1px solid #A8A8A8",
                    }}>
                    <span style={{
                        fontSize: "1.25vw",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal"
                    }}>Account Settings</span>
                    <span style={{
                        color: "#717171",
                        fontSize: "0.936vw",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal"
                    }}>Manage your account preferences here</span>
                </Row>
                <div className="patient-profile-body">
                    <Row style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <Row className="account-settings-row">
                            <span className="account-settings-header-text">
                                Notifications
                            </span>
                            <span className="account-settings-desc-text">
                                Enable or disable notifications for various updates and reminders.
                            </span>
                        </Row> 
                        <Switch defaultChecked />
                    </Row>
                    <Row className="account-settings-row">
                        <span className="account-settings-header-text">
                            Language
                        </span>
                        <span style={{
                            color: "#B3B9BD",
                            fontSize: "0.834vw"
                        }}>
                            Select Language
                        </span>
                        <select className="account-settings-select" >
                            <option value="English">English</option>
                        </select>
                    </Row>
                    <Row className="account-settings-row" style={{
                        gap: "0.834vw"
                    }}>
                        <span className="account-settings-header-text">
                            Password
                        </span>
                        <Button className="button-class">Change Password</Button>
                    </Row>
                    <Row className="account-settings-row">
                        <span className="account-settings-header-text">
                            Delete Account
                        </span>
                        <span className="account-settings-desc-text" style={{
                            color: "#B3B9BD",
                            fontSize: "0.834vw"
                        }}>
                            Deleting your account will
                        </span>
                        <ul>
                            <li>Permanently remove all your data from this account</li>
                            <li>Delete your treatment history</li>
                            <li>Delete your payment Info</li>
                        </ul>
                        <Button className="button-class"
                            onClick={handleDeleteAccount}>Delete Account</Button>
                    </Row>
                </div>

            </Row>

            <Modal
                centered
                width={"29.271vw"}
                open={openDeleteAccountModal}
                footer={false}
                destroyOnClose={true}
                closable={false}
            >
                {step != 3 && (
                    <img style={{
                        width: "1.4586vw",
                        height: "1.4586vw",
                        position: "absolute",
                        right: "1.67vw",
                        top: "1.67vw",
                        cursor: "pointer"
                    }} src={cancelIcon}
                        onClick={handleCancel} />)}
                <div className="account-settings-modal">
                    {step == 1 && (<Row style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "2.865vw"
                    }}>
                        <img style={{
                            width: "7.4485vw",
                            height: "9.271vw"
                        }} src={deleteUserIcon} />
                        <Row style={{
                            gap: "1.872vw"
                        }}>
                            <span className="modal-text">Please enter your password to verify the deletion of your account.</span>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "1.0417vw"
                            }}>
                                <Row>
                                    <label>Password</label>
                                    <Input.Password className="password" name="passowrd" maxLength={30}
                                        onChange={handlePasswordChange} />
                                </Row>
                                <span
                                    className="forgot-password"
                                    onClick={() => {
                                        navigate(routes_forgot_password);
                                    }}
                                >
                                    <u>Forgot Password?</u>
                                </span>
                            </div>
                        </Row>
                        <Row style={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "2.8vw"
                        }}>
                            <Button className="verify-button"
                                onClick={handleVerifyPassword}
                                disabled={isLoading}>{isLoading ? 'Verifying...' : 'Verify'}</Button>
                        </Row>

                    </Row>)}
                    {step == 2 && (<Row>
                        <Row style={{
                            gap: "0.936vw"
                        }}>
                            <span className="otp-verification">OTP Verification</span>
                            <span className="step-description">We’ve sent OTP to your email at <b>{email}</b>. Please enter 4 digit code you receive.</span>
                        </Row>
                        <Row className="otp-field-cont">
                            <span className="forgot-password-input-title">
                                OTP
                                <span style={{ color: "red" }}> *</span>
                            </span>
                            <div className="otp-input-group">
                                {otpCode.map((digit, index) => (
                                    <input
                                        className="otp-input"
                                        key={index}
                                        type="text"
                                        maxLength={1}
                                        value={digit}
                                        onChange={(e) => handleOTPChange(index, e.target.value)}
                                        ref={(ref) => (inputRefs.current[index] = ref)}
                                    />
                                ))}
                            </div>
                        </Row>
                        <Row>
                            <span style={{
                                padding: "0.834vw 0 0",
                                color: "#EC7063",
                                fontSize: "0.73vw",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal"
                            }}>{isInvalidOTP ? 'Please enter correct OTP' : ''}</span>
                        </Row>
                        <Row className="code-not-received">
                            <span style={{
                                color: "#000",
                                fontSize: "0.834vw",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal"
                            }}>Didn't receive code?</span>
                            {isResendEnabled && !isResendInProgress && (
                                <span className="resend-otp" onClick={handleResendOTP}>
                                    Resend OTP
                                </span>
                            )}
                            {(!isResendEnabled || isResendInProgress) && (
                                <span className="resend-code">
                                    {isResendInProgress ? "Resending..." : ` Resend in ${resendTimer}s`}
                                </span>
                            )}
                        </Row>
                        <Row style={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "8.32vw"
                        }}>
                            <Button className="delete-now-button"
                                onClick={handleDeleteNow}>Delete Now</Button>
                        </Row>
                    </Row>)}
                    {step == 3 && (isPasswordReset ? (
                        <Row className="reset-success">
                            <img src={resetSuccessLogo} alt="" style={{
                                width: "5.417vw",
                                height: "5.417vw"
                            }} />
                            <Row style={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "19.4275vw"
                            }}>
                                <span style={{
                                    color: "#333",
                                    textAlign: "center",
                                    fontSize: "1.354165vw",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "148.59%"/* 38.633px */
                                }}>Account Deleted Successfully!</span>
                                <span style={{
                                    color: "#333",
                                    fontSize: "0.834vw",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "148.59%",
                                    textAlign: "center"
                                }}>Your account has been successfully deleted. We're sorry to see you go</span>
                            </Row>
                            <Button className="login-button" onClick={() => {
                                navigate("/");
                            }}>
                                Back to Login
                            </Button>
                        </Row>) : (<Row className="reset-success">
                            <img src={errorDeleting} alt="" style={{
                                width: "5.417vw",
                                height: "10.8vh"
                            }} />
                            <Row style={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "19.4275vw"
                            }}>
                                <span style={{
                                    color: "#000",
                                    textAlign: "center",
                                    fontSize: "1.354165vw",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "148.59%"/* 38.633px */
                                }}>Error Deleting Account</span>
                                <span style={{
                                    color: "#333",
                                    fontSize: "0.834vw",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "148.59%",
                                    textAlign: "center"
                                }}>We encountered an error while attempting to delete your account. Please try again later</span>
                            </Row>
                            <Button className="login-button" onClick={() => {
                                setStep(1);
                            }}>
                                Try Again
                            </Button>
                        </Row>)
                    )}
                </div>
            </Modal >

        </div >
    );
};

export default PatientAccountSettings;
