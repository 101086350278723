import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import "./PatientProfile.css";
import LocationIcon from "../../../../../assets/location-icon.png";
import bloodgroupicon from "../../../../../assets/Blood-drop.png";
import Associated from "../../../../../assets/associated-star-icon.png";
import {
  options,
  routes_super_admin_patients,
} from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import { useParams } from "react-router-dom";
import API_BASE_URL from "../../../../../env";
import axios from "axios";
import ProfilePic from "../../../../../assets/default-profile-pic.png";
import { UserAuth } from "../../../../../context_api/ContextApi";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../string";
import AWS from "aws-sdk";

const SuperAdminPatientProfile = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const Token = localStorage.getItem("Token");
  const role = localStorage.getItem("role");
  const { getSubUuserPpermissions } = UserAuth();
  const [form] = Form.useForm();
  const [inputsEnable, setInputsEnable] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [patientUserDetails, setPatientUserDetails] = useState("");
  const [s3Url, setS3Url] = useState("");
  const [patientRegisteredClinics, setPatientRegisteredClinics] = useState();

  const { id } = useParams();

  const getPatientDetails = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/patient/${id}`, config)
      .then((res) => {
        setPatientUserDetails(res.data.data);
        setS3Url(
          res.data.data.profile_picture_url &&
            res.data.data.profile_picture_url.split(".com/")[1]
        );
        setSpinner(false);
      });
  };

  const getClinicsListLinkedWithPatient = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/patient/clinics/{patientId}?patientId=${id}`,
        config
      )
      .then((res) => {
        setPatientRegisteredClinics(res.data.data.clinic_patient);
        setSpinner(false);
      });
  };

  const handleUpdatePatientDetailsBySuperAdmin = async (values) => {
    setSpinner(true);
    let payload;
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    payload = {
      name: values.name,
      email: values.email,
      phone_country_code: values.countryCode,
      phone_number: values.phone,
      address_line_one: values.apartment,
      address_line_two: values.address,
      city: values.cityname,
      province: values.state,
      country: values.country,
      zip_code: values.zipcode,
      insurance_id: values.insuranceID,
      citizen_id: values.citizenID,
      blood_group: values.bloodgroup,
      gender: values.Gender,
    };
    await axios
      .put(`${API_BASE_URL}/api/v1/patient/${id}`, payload, config)
      .then((res) => {
        getPatientDetails();
        getClinicsListLinkedWithPatient();
        setSpinner(false);
        message.success("Patient Updated Successfully");
        setInputsEnable(true);
      });
  };

  const validatePhoneNumber = (rule, value, callback) => {
    const phoneNumberRegex = /^\d{10}$/; // Assumes the phone number is 10 digits long
    if (!phoneNumberRegex.test(value)) {
      callback("Please enter a valid phone number");
    } else {
      callback();
    }
  };

  useEffect(() => {
    getPatientDetails();
    getClinicsListLinkedWithPatient();
  }, []);
  const [fileContent, setFileContent] = useState("");
  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: s3Url,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [s3Url]);

  return (
    <div className="clinic-user-add-patient-main-div">
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "13px 23px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Patient Name ({patientUserDetails && patientUserDetails.name})
          </span>
          <Breadcrumb>
            <Breadcrumb.Item
              style={{
                background: "#2381C6",
                padding: "1px 10px",
                borderRadius: "3px",
              }}
            >
              <a href={routes_super_admin_patients} style={{ color: "#FFF" }}>
                Patients
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{
                background: "#2381C6",
                padding: "1px 10px",
                borderRadius: "3px",
                color: "#FFF",
              }}
            >
              Patient Profile
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <span
          style={{
            fontWeight: 700,
            fontSize: 22,
            color: "#525252",
          }}
        >
          {patientUserDetails &&
            patientUserDetails.referral_code &&
            "Referred By : " + patientUserDetails.referral_code}
        </span>
      </Row>
      <Row className="w-100" style={{ padding: "0 0 0 23px" }}>
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          onFinish={handleUpdatePatientDetailsBySuperAdmin}
          autoComplete="off"
          className="w-100"
        >
          <Spin
            indicator={antIcon}
            size="large"
            spinning={spinner && true}
            delay={500}
          >
            <Row className="d-flex">
              <Col xs={24} sm={24} md={12} xl={6}>
                <div className="profile-pic-col-main-div">
                  <div
                    style={{
                      padding: "3.3333333333333335vw",
                      borderBottom: "1px solid #D9D9D9",
                    }}
                  >
                    <Row className="d-flex jc-center">
                      <div
                        style={{
                          borderRadius: "40%",
                          background: "#D9D9D9",
                          width: "118px",
                          height: "118px",
                        }}
                      >
                        <img
                          style={{
                            height: "118px",
                            width: "118px",
                            borderRadius: 40,
                          }}
                          src={fileContent ? fileContent : ProfilePic}
                          alt=""
                        />
                      </div>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "27px 0 0 0",
                        gap: "2.302vh",
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Name
                        </span>
                        {patientUserDetails && (
                          <Form.Item
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: "Enter name",
                              },
                            ]}
                            initialValue={patientUserDetails.name}
                          >
                            <Input
                              name="patient_name"
                              label="patient_name"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#525252",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              disabled={inputsEnable}
                            />
                          </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Patient Identification
                        </span>
                        {patientUserDetails && (
                          <Form.Item
                            name="patientID"
                            initialValue={"PID# " + patientUserDetails.user_id}
                          >
                            <Input
                              name="patient_id"
                              label="patient_id"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#525252",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                                background: "#FFF8E7",
                              }}
                              disabled={true}
                              bordered={false}
                              defaultValue="PID# + patientUserDetails.user_id"
                            />
                          </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Gender
                        </span>
                        {patientUserDetails && (
                          <Form.Item
                            name="Gender"
                            rules={[
                              { required: true, message: "Select Gender" },
                            ]}
                            initialValue={patientUserDetails.gender}
                          >
                            <Select
                              name="patient_gender"
                              label="patient_gender"
                              bordered={false}
                              disabled={inputsEnable}
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#525252",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            >
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Others">Others</Option>
                            </Select>
                          </Form.Item>
                        )}
                      </Row>
                    </Row>
                  </div>
                  <div
                    style={{
                      padding:
                        "3.7151702786377707vh 3.3333333333333335vw 4vh 3.3854166666666665vw",
                      borderBottom: "1px solid #D9D9D9",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Email
                    </span>
                    {patientUserDetails && (
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: "Enter email" },
                          {
                            type: "email",
                            message: "Enter valid email address",
                          },
                        ]}
                        initialValue={patientUserDetails.email}
                      >
                        <Input
                          name="patient_email"
                          label="patient_email"
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#7D7D7D",
                            height: "40px",
                            borderRadius: "3px",
                            border: "1px solid #E3E3E3",
                          }}
                          bordered={false}
                          disabled={inputsEnable}
                        />
                      </Form.Item>
                    )}
                  </div>
                  <div
                    style={{
                      padding:
                        "3.7151702786377707vh 3.3333333333333335vw 4.615384615384615vh 3.3854166666666665vw",
                    }}
                  >
                    <Row>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Phone
                      </span>
                    </Row>
                    {patientUserDetails && (
                      <Space.Compact>
                        <Form.Item
                          name="countryCode"
                          initialValue={patientUserDetails.phone_country_code}
                        >
                          <Select
                            bordered={false}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: 40,
                              background: "#FFFFFF",
                              border: "1px solid #E3E3E3",
                              margin: "3px 0 0 0",
                            }}
                            options={options}
                            disabled={inputsEnable}
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ width: "100%" }}
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter phone number with country code",
                            },
                            { validator: validatePhoneNumber },
                          ]}
                          initialValue={patientUserDetails.phone_number}
                        >
                          <Input
                            bordered={false}
                            style={{
                              height: 40,
                              background: "#FFFFFF",
                              borderTop: "1px solid #E3E3E3",
                              borderRight: "1px solid #E3E3E3",
                              borderBottom: "1px solid #E3E3E3",
                              margin: "3px 0 0 0",
                            }}
                            disabled={inputsEnable}
                          />
                        </Form.Item>
                      </Space.Compact>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} xl={6}>
                <div
                  style={{
                    width: "23.4375vw",
                    background: "#fff",
                    borderRadius: "5px",
                  }}
                >
                  <Row style={{ padding: "22px" }}>
                    <Col span={2}>
                      <img
                        style={{ height: "26px" }}
                        src={LocationIcon}
                        alt=""
                      />
                    </Col>
                    <Col>
                      <span
                        style={{
                          fontWeight: "700",
                          fontSize: "18px",
                          color: "#525252",
                        }}
                      >
                        Address
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5vh",
                      padding:
                        "1.3333333333333335vw 3.3333333333333335vw 3.3333333333333335vw 3.3333333333333335vw",
                    }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Address
                      </span>
                      {patientUserDetails && (
                        <Form.Item
                          initialValue={patientUserDetails.address_line_two}
                          name="address"
                          rules={[{ required: true, message: "Enter Address" }]}
                        >
                          <Input
                            name="patient_address"
                            label="patient_address"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#7D7D7D",
                              height: "40px",
                              border: "1px solid #E3E3E3",
                            }}
                            bordered={false}
                            disabled={inputsEnable}
                          />
                        </Form.Item>
                      )}
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        City
                      </span>
                      {patientUserDetails && (
                        <Form.Item
                          initialValue={patientUserDetails.city}
                          name="patient_city"
                          rules={[{ required: true, message: "Enter City" }]}
                        >
                          <Input
                            name="cityname"
                            label="patient_city"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              height: "40px",
                              color: "#7D7D7D",
                              border: "1px solid #E3E3E3",
                            }}
                            bordered={false}
                            disabled={inputsEnable}
                          />
                        </Form.Item>
                      )}
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Apartment / Suite ETC.,
                      </span>
                      {patientUserDetails && (
                        <Form.Item
                          name="apartment"
                          rules={[
                            {
                              required: true,
                              message: "Enter Apartment / Suite Etc.,",
                            },
                          ]}
                          initialValue={patientUserDetails.address_line_one}
                        >
                          <Input
                            name="patient_apartment"
                            label="patient_apartment"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#7D7D7D",
                              height: "40px",
                              border: "1px solid #E3E3E3",
                            }}
                            bordered={false}
                            disabled={inputsEnable}
                          />
                        </Form.Item>
                      )}
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Country
                      </span>
                      {patientUserDetails && (
                        <Form.Item
                          initialValue={patientUserDetails.country}
                          name="country"
                          rules={[{ required: true, message: "Enter Country" }]}
                        >
                          <Input
                            name="patient_country"
                            label="patient_country"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              height: "40px",
                              color: "#7D7D7D",
                              border: "1px solid #E3E3E3",
                            }}
                            bordered={false}
                            disabled={inputsEnable}
                          />
                        </Form.Item>
                      )}
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        State / Province
                      </span>
                      {patientUserDetails && (
                        <Form.Item
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: "Enter State / Province",
                            },
                          ]}
                          initialValue={patientUserDetails.province}
                        >
                          <Input
                            name="patient_state"
                            label="patient_state"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#7D7D7D",
                              height: "40px",
                              border: "1px solid #E3E3E3",
                            }}
                            bordered={false}
                            disabled={inputsEnable}
                          />
                        </Form.Item>
                      )}
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Zip Code
                      </span>
                      {patientUserDetails && (
                        <Form.Item
                          name="zipcode"
                          rules={[
                            { required: true, message: "Enter Zip code" },
                          ]}
                          initialValue={patientUserDetails.zip_code}
                        >
                          <Input
                            name="patient_zipcode"
                            label="patient_zipcode"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              height: "40px",
                              color: "#7D7D7D",
                              border: "1px solid #E3E3E3",
                            }}
                            bordered={false}
                            disabled={inputsEnable}
                          />
                        </Form.Item>
                      )}
                    </Row>
                  </Row>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} xl={6}>
                <div
                  className="profile-pic-col-main-div"
                  style={{
                    width: "23.4375vw",
                    background: "#fff",
                    borderRadius: "5px",
                    padding: "26px 25px",
                  }}
                >
                  <Row className="d-flex ai-center">
                    <Col>
                      <img
                        src={bloodgroupicon}
                        alt=""
                        style={{ height: "24px" }}
                      />
                    </Col>
                    <Col>
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: 18,
                          color: "#525252",
                          margin: "0 0 0 13px",
                        }}
                      >
                        Blood Group
                      </span>
                    </Col>
                  </Row>

                  <Row
                    style={{
                      margin: "5vh 0 6.2vh 0",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Row className="w-100">
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          color: "#A5A4A4",
                        }}
                      >
                        Blood Group
                      </span>
                    </Row>
                    <Row className="w-100">
                      {patientUserDetails && (
                        <Form.Item
                          initialValue={patientUserDetails.blood_group}
                          className="w-100"
                          name="bloodgroup"
                          rules={[
                            { required: true, message: "Select blood group" },
                          ]}
                        >
                          <Select
                            name="patient_blood_group"
                            label="patient_blood_group"
                            bordered={false}
                            disabled={inputsEnable}
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #E3E3E3",
                              borderRadius: 3,
                              height: "40px",
                              color: "#7D7D7D",
                            }}
                          >
                            <Option value="A+">A+</Option>
                            <Option value="A-">A-</Option>
                            <Option value="B+">B+</Option>
                            <Option value="B-">B-</Option>
                            <Option value="O+">O+</Option>
                            <Option value="O-">O-</Option>
                            <Option value="AAB+">AAB+</Option>
                            <Option value="AAB-">AAB-</Option>
                          </Select>
                        </Form.Item>
                      )}
                    </Row>
                  </Row>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} xl={6}>
                <div
                  style={{
                    width: "23.4375vw",
                    background: "#fff",
                    borderRadius: "5px",
                    margin: "0 0 2.5641025641025643vh 0",
                    padding: "1.74vh 24px",
                  }}
                >
                  <Row
                    className="d-flex ai-center"
                    style={{
                      borderBottom: "0.5px solid #E3E3E3",
                      padding: "0 0 15px 0",
                    }}
                  >
                    <Col>
                      <img src={Associated} alt="" style={{ height: "22px" }} />
                    </Col>
                    <Col>
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: 18,
                          color: "#525252",
                          margin: "0 0 0 13px",
                        }}
                      >
                        Associated Clinics
                      </span>
                    </Col>
                  </Row>
                  {patientRegisteredClinics &&
                    patientRegisteredClinics.map((item) => {
                      return (
                        <Row
                          className="d-flex ai-center"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "0.5px solid #E3E3E3",
                            padding: "0 0 15px 0",
                            margin: "10px 0 0 0",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: 18,
                              color: "#2CA3FA",
                              margin: "0 0 0 13px",
                            }}
                          >
                            {item.created_by}
                          </span>
                        </Row>
                      );
                    })}
                </div>
              </Col>
            </Row>
            {/* <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "19px",
                margin: "20px 0 50px 0",
              }}
            >
              {inputsEnable ? (
                <Button
                  style={{
                    height: "30px",
                    border: "1px solid #009DF7",
                    borderRadius: "30px",
                    color: "#2CA3FA",
                    background: "#FFFFFF",
                  }}
                  onClick={() => {
                    setInputsEnable(false);
                  }}
                >
                  {(role === "SUPER_ADMIN" && inputsEnable) ||
                  (role !== "SUPER_ADMIN" &&
                    getSubUuserPpermissions &&
                    getSubUuserPpermissions.UPDATE_PATIENT &&
                    inputsEnable) ? (
                    <span>Edit</span>
                  ) : (
                    ""
                  )}
                </Button>
              ) : (
                <Button
                  style={{
                    height: "30px",
                    border: "1px solid red",
                    borderRadius: "30px",
                    color: "red",
                    background: "#red",
                  }}
                  onClick={() => {
                    setInputsEnable(true);
                  }}
                >
                  Cancel
                </Button>
              )}
              {inputsEnable === false && (
                <Form.Item>
                  <Button
                    style={{
                      height: "30px",
                      width: "64px",
                      border: "1px solid #009DF7",
                      borderRadius: "30px",
                      color: "#2CA3FA",
                      background: "#E0F2FF",
                    }}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              )}
            </Row> */}
          </Spin>
        </Form>
      </Row>
    </div>
  );
};

export default SuperAdminPatientProfile;
