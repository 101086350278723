import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { ACCESS_KEY, REGION, S3_BUCKET, SECRET_ACCESS_KEY } from "../../../../../../string";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CertificatePreview = ({ filePath }) => {
    const [fileContent, setFileContent] = useState("");

    useEffect(() => {
        const s3 = new AWS.S3({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION,
        });
        s3.getSignedUrl(
            "getObject",
            {
                Bucket: S3_BUCKET,
                Key: filePath,
            },
            (err, url) => {
                if (err) {
                    message.error(err);
                } else {
                    fetch(url)
                        .then((response) => response.blob())
                        .then((data) => {
                            const url = URL.createObjectURL(data);
                            setFileContent(url);
                        })
                        .catch((err) => message.error(err));
                }
            }
        );
    }, [filePath]);

    const getFileExtension = (filename) => {
        return filename?.slice((filename?.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
    };

    const renderPreview = () => {
        const extension = getFileExtension(filePath);

        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return <img
                    style={{
                        width: '100%',
                        height: '100%'
                    }} 
                    src={fileContent} />
            case 'pdf':
                return (
                    <Document 
                    file={fileContent}>
                        <Page pageNumber={1} />
                    </Document>
                );
            default:
                return <p>Unsupported file type</p>;
        }
    }

    return (<div className="file-preview">
        {renderPreview()}
    </div>
    )
};

export default CertificatePreview;