import React, { useEffect, useState } from "react";
import "./AddClinicBranches.css";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { routes_clinic_my_account } from "../../../../utils/clinic_admin_constants/ClinicAdminConstants";
import { LoadingOutlined } from "@ant-design/icons";
import { options } from "../../../../utils/super_admin_constants/SuperAdminConsts";
import LocationIcon from "../../../../assets/location-icon.png";
import { UserAuth } from "../../../../context_api/ContextApi";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../string";
import AWS from "aws-sdk";
import ProfilePic from "../../../../assets/default-profile-pic.png";
import axios from "axios";
import API_BASE_URL from "../../../../env";
import { useLocation, useNavigate, useParams } from "react-router";

const AddClinicBranch = () => {
  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");
  const {
    s3UrlForClinic,
    setEnableBranchInputs,
    enableBranchInputs,
    setInputsEnable,
  } = UserAuth();
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 70, margin: "150px 0 0 0" }} spin />
  );
  const navigate = useNavigate();
  const location = useLocation();
  const location_path = location.pathname;
  const { branch_id } = useParams();
  const newlocation_path = `/clinic-admin/edit-clinic-branch/${branch_id}`;
  const [form] = Form.useForm();
  const [spinner, setSpinner] = useState(false);
  const [get_branch_details, setGetBranchDetails] = useState();
  const [patientUserDetails, setPatientUserDetails] = useState();
  const [clinic_img_url, setClinicImgUrl] = useState();

  const enableFormFields = () => {
    setEnableBranchInputs(false);
  };

  const handleGetClinicBranchDetails = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (location_path === newlocation_path) {
      await axios
        .get(
          `${API_BASE_URL}/api/v1/clinicBranch/fetchBranchDetails/${branch_id}`,
          config
        )
        .then((res) => {
          setGetBranchDetails(res.data.data);
        })
        .catch((err) => {
          message.error("Unable to fetch branch data");
        });
    } else {
      await axios
        .get(`${API_BASE_URL}/api/v1/clinic/${clinic_id}`, config)
        .then((res) => {
          setPatientUserDetails(res.data.data);
          setClinicImgUrl(
            res.data.data.profile_picture_url &&
              res.data.data.profile_picture_url
          );
        });
    }
  };

  const handleAddClinicBranch = async (values) => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    const payload = {
      clinic_id: values.clinic_id,
      branch_name: values.clinic_name,
      address_line_one: values.clinic_branch_apartment,
      address_line_two: values.clinic_branch_address,
      phone_number: values.phone_number,
      city: values.clinic_branch_city,
      province: values.clinic_branch_state,
      country: values.clinic_branch_country,
      zip_code: values.clinic_branch_zipcode,
      profile_picture_url: clinic_img_url,
      email: values.clinic_email,
      phone_country_code: values.phone_country_code,
    };
    await axios
      .post(
        `${API_BASE_URL}/api/v1/clinicBranch/createClinicBranch`,
        payload,
        config
      )
      .then((res) => {
        setSpinner(false);
        message.success("Clinic Branch Added Successfully");
        navigate(routes_clinic_my_account);
      })
      .catch((err) => {
        message.error("Adding clinic was failed");
        setSpinner(false);
      });
  };

  const handleEditClinicBranchDetails = async (values) => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    const payload = {
      branch_name: values.clinic_name,
      address_line_one: values.clinic_branch_apartment,
      address_line_two: values.clinic_branch_address,
      phone_number: values.phone_number,
      city: values.clinic_branch_city,
      province: values.clinic_branch_state,
      country: values.clinic_branch_country,
      zip_code: values.clinic_branch_zipcode,
      profile_picture_url: clinic_img_url,
      email: values.clinic_email,
      phone_country_code: values.phone_country_code,
    };
    await axios
      .put(
        `${API_BASE_URL}/api/v1/clinicBranch/updateClinicDetails/${branch_id}`,
        payload,
        config
      )
      .then((res) => {
        setSpinner(false);
        setEnableBranchInputs(true);
        message.success("Clinic Branch Updated Successfully");
      })
      .catch((err) => {
        message.error("Updating clinic was failed");
        setSpinner(false);
      });
  };
  const validatePhoneNumber = (_, value) => {
    const numericRegex = /^[0-9]+$/;
    if (!value || (numericRegex.test(value) && value.length === 10)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Please enter exactly 10 numeric characters for the phone number."
    );
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter 6-digit zip code!");
  };

  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: s3UrlForClinic,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [s3UrlForClinic]);

  useEffect(() => {
    handleGetClinicBranchDetails();
  }, []);
  return (
    <div className="clinic-admin-add-clinic-branch-main-div">
      <Form
        form={form}
        initialValues={{
          remember: true,
        }}
        onFinish={
          location_path === newlocation_path
            ? handleEditClinicBranchDetails
            : handleAddClinicBranch
        }
        autoComplete="off"
        className="w-100"
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "13px 23px",
          }}
        >
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontWeight: 700,
                fontSize: 22,
                color: "#525252",
              }}
            >
              {location_path === newlocation_path
                ? "Edit Clinic Branch"
                : "Add Clinic Branch"}
            </span>
            <Breadcrumb>
              <Breadcrumb.Item
                style={{
                  background: "#2381C6",
                  padding: "1px 10px",
                  borderRadius: "3px",
                  color: "#FFF",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(-1);
                  setInputsEnable(true);
                  setEnableBranchInputs(true);
                }}
              >
                Clinic Profile
              </Breadcrumb.Item>
              <Breadcrumb.Item
                style={{
                  background: "#2381C6",
                  padding: "1px 10px",
                  borderRadius: "3px",
                  color: "#FFF",
                }}
              >
                {location_path === newlocation_path
                  ? "Edit Clinic Branch"
                  : "Add Clinic Branch"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>

          {location_path === newlocation_path ? (
            <Row>
              {enableBranchInputs ? (
                <Button
                  style={{
                    height: "30px",
                    width: "64px",
                    border: "1px solid #009DF7",
                    borderRadius: "30px",
                    color: "#009DF7",
                  }}
                  onClick={enableFormFields}
                >
                  Edit
                </Button>
              ) : (
                <Row style={{ width: "10vw", justifyContent: "space-between" }}>
                  <Button
                    style={{
                      height: "30px",
                      border: "1px solid #F7927E",
                      borderRadius: "30px",
                      color: "#F7927E",
                      background: "#FFF6F2",
                    }}
                    onClick={() => {
                      setEnableBranchInputs(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      height: "30px",
                      width: "64px",
                      border: "1px solid #29C3C1",
                      borderRadius: "30px",
                      color: "#29C3C1",
                      background: "#ECFEFF",
                    }}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Row>
              )}
            </Row>
          ) : (
            <Row
              style={{
                gap: "19px",
              }}
            >
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                  border: "1px solid #F7927E",
                  borderRadius: "30px",
                  color: "#F7927E",
                  background: "#FFF6F2",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              <Form.Item>
                <Button
                  style={{
                    height: "30px",
                    width: "64px",
                    border: "1px solid #29C3C1",
                    borderRadius: "30px",
                    color: "#29C3C1",
                    background: "#ECFEFF",
                  }}
                  htmlType="submit"
                >
                  Add
                </Button>
              </Form.Item>
            </Row>
          )}
          <span></span>
        </Row>
        <Spin
          indicator={antIcon}
          size="large"
          spinning={spinner && true}
          delay={500}
        >
          <Row className="w-100" style={{ padding: "0 0 0 23px" }}>
            <div className="w-100">
              <Row className="d-flex">
                <Col xs={24} sm={24} md={12} xl={6}>
                  <div className="profile-pic-col-main-div">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "3.3333333333333335vw",
                        borderBottom: "1px solid #D9D9D9",
                        gap: "3vh",
                      }}
                    >
                      <Row style={{ display: "flex", alignItems: "center" }}>
                        <Col>
                          <div
                            style={{
                              borderRadius: "40%",
                              background: "#D9D9D9",
                              width: "118px",
                              height: "118px",
                            }}
                          >
                            <Form.Item>
                              <Image
                                preview={fileContent && true}
                                style={{
                                  height: "118px",
                                  width: "118px",
                                  borderRadius: 40,
                                }}
                                src={fileContent ? fileContent : ProfilePic}
                                alt=""
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2.302vh",
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#A5A4A4",
                            }}
                          >
                            {location_path === newlocation_path
                              ? "Branch Name"
                              : "Clinic Name"}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                          {get_branch_details && (
                            <Form.Item
                              name="clinic_name"
                              initialValue={get_branch_details.branch_name}
                              rules={[
                                {
                                  required: true,
                                  message: "Enter Clinic name",
                                },
                              ]}
                            >
                              <Input
                                name="clinic_name"
                                label="clinic_name"
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#525252",
                                  height: "40px",
                                  borderRadius: "3px",
                                  border: "1px solid #E3E3E3",
                                  background: "#FFF8E7",
                                }}
                                disabled={true}
                                bordered={false}
                              />
                            </Form.Item>
                          )}
                          {patientUserDetails && (
                            <Form.Item
                              name="clinic_name"
                              initialValue={patientUserDetails.name}
                              rules={[
                                {
                                  required: true,
                                  message: "Enter Clinic name",
                                },
                              ]}
                            >
                              <Input
                                name="clinic_name"
                                label="clinic_name"
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#525252",
                                  height: "40px",
                                  borderRadius: "3px",
                                  border: "1px solid #E3E3E3",
                                  background: "#FFF8E7",
                                }}
                                disabled={true}
                                bordered={false}
                              />
                            </Form.Item>
                          )}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#A5A4A4",
                            }}
                          >
                            {location_path === newlocation_path
                              ? "Branch Id"
                              : "Clinic Id"}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                          {get_branch_details && (
                            <Form.Item
                              name="clinic_id"
                              initialValue={get_branch_details.branch_id}
                              rules={[
                                { required: true, message: "Select Gender" },
                              ]}
                            >
                              <Input
                                name="clinic_id"
                                label="clinic_id"
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#525252",
                                  height: "40px",
                                  borderRadius: "3px",
                                  border: "1px solid #E3E3E3",
                                  background: "#FFF8E7",
                                }}
                                disabled={true}
                                bordered={false}
                              />
                            </Form.Item>
                          )}
                          {patientUserDetails && (
                            <Form.Item
                              name="clinic_id"
                              initialValue={patientUserDetails.clinic_id}
                              rules={[
                                {
                                  required: true,
                                  message: "Select Gender",
                                },
                              ]}
                            >
                              <Input
                                name="clinic_id"
                                label="clinic_id"
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#525252",
                                  height: "40px",
                                  borderRadius: "3px",
                                  border: "1px solid #E3E3E3",
                                  background: "#FFF8E7",
                                }}
                                disabled={true}
                                bordered={false}
                              />
                            </Form.Item>
                          )}
                        </Row>
                      </Row>
                    </div>
                    <div
                      style={{
                        padding:
                          "3.7151702786377707vh 3.3333333333333335vw 4vh 3.3854166666666665vw",
                        borderBottom: "1px solid #D9D9D9",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Email<span style={{ color: "red" }}>*</span>
                      </span>
                      {get_branch_details && (
                        <Form.Item
                          name="clinic_email"
                          initialValue={get_branch_details.email}
                          rules={[
                            { required: true, message: "Enter email" },
                            {
                              type: "email",
                              message: "Enter valid email address",
                            },
                          ]}
                        >
                          <Input
                            name="clinic_email"
                            label="clinic_email"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#525252",
                              height: "40px",
                              borderRadius: "3px",
                              border: "1px solid #E3E3E3",
                              background: "#FFF8E7",
                            }}
                            bordered={false}
                            disabled={true}
                          />
                        </Form.Item>
                      )}
                      {patientUserDetails && (
                        <Form.Item
                          name="clinic_email"
                          initialValue={patientUserDetails.email}
                          rules={[
                            { required: true, message: "Enter email" },
                            {
                              type: "email",
                              message: "Enter valid email address",
                            },
                          ]}
                        >
                          <Input
                            name="clinic_email"
                            label="clinic_email"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#525252",
                              height: "40px",
                              borderRadius: "3px",
                              border: "1px solid #E3E3E3",
                              background: "#FFF8E7",
                            }}
                            bordered={false}
                            disabled={true}
                          />
                        </Form.Item>
                      )}
                    </div>
                    <div
                      style={{
                        padding:
                          "3.7151702786377707vh 3.3333333333333335vw 4.615384615384615vh 3.3854166666666665vw",
                      }}
                    >
                      <Row>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Phone<span style={{ color: "red" }}>*</span>
                        </span>
                      </Row>
                      {get_branch_details && (
                        <Space.Compact className="w-100">
                          <Form.Item
                            name="phone_country_code"
                            initialValue={get_branch_details.phone_country_code}
                          >
                            <Select
                              bordered={false}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: 40,
                                background: "#FFFFFF",
                                border: "1px solid #E3E3E3",
                                margin: "3px 0 0 0",
                              }}
                              options={options}
                              disabled={enableBranchInputs}
                            />
                          </Form.Item>
                          <Form.Item
                            style={{ width: "100%" }}
                            name="phone_number"
                            rules={[
                              {
                                required: true,
                                message: "Please enter phone number",
                              },
                              { validator: validatePhoneNumber },
                            ]}
                            initialValue={get_branch_details.phone_number}
                          >
                            <Input
                              bordered={false}
                              style={{
                                height: 40,
                                background: "#FFFFFF",
                                borderTop: "1px solid #E3E3E3",
                                borderRight: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                margin: "3px 0 0 0",
                              }}
                              disabled={enableBranchInputs}
                              onKeyPress={(e) => {
                                const charCode = e.which || e.keyCode;
                                if (!(charCode >= 48 && charCode <= 57)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Space.Compact>
                      )}
                      {patientUserDetails && (
                        <Space.Compact className="w-100">
                          <Form.Item
                            name="phone_country_code"
                            initialValue="+91"
                          >
                            <Select
                              bordered={false}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: 40,
                                background: "#FFFFFF",
                                border: "1px solid #E3E3E3",
                                margin: "3px 0 0 0",
                              }}
                              options={options}
                            />
                          </Form.Item>
                          <Form.Item
                            style={{ width: "100%" }}
                            name="phone_number"
                            rules={[
                              {
                                required: true,
                                message: "Please enter phone number",
                              },
                              { validator: validatePhoneNumber },
                            ]}
                          >
                            <Input
                              bordered={false}
                              style={{
                                height: 40,
                                background: "#FFFFFF",
                                borderTop: "1px solid #E3E3E3",
                                borderRight: "1px solid #E3E3E3",
                                borderBottom: "1px solid #E3E3E3",
                                margin: "3px 0 0 0",
                              }}
                              onKeyPress={(e) => {
                                const charCode = e.which || e.keyCode;
                                if (!(charCode >= 48 && charCode <= 57)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Space.Compact>
                      )}
                    </div>
                  </div>
                </Col>

                {/* Address Col */}
                <Col xs={24} sm={24} md={12} xl={6}>
                  <div className="profile-pic-col-main-div">
                    <Row style={{ padding: "22px" }}>
                      <Col span={2}>
                        <img
                          style={{ height: "26px" }}
                          src={LocationIcon}
                          alt=""
                        />
                      </Col>
                      <Col>
                        <span
                          style={{
                            fontWeight: "700",
                            fontSize: "18px",
                            color: "#525252",
                          }}
                        >
                          Address
                        </span>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4vh",
                        padding:
                          "1.3333333333333335vw 3.3333333333333335vw 3.3333333333333335vw 3.3333333333333335vw",
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Address<span style={{ color: "red" }}>*</span>
                        </span>
                        {get_branch_details && (
                          <Form.Item
                            name="clinic_branch_address"
                            initialValue={get_branch_details.address_line_two}
                            rules={[
                              {
                                required: true,
                                message: "Please enter address",
                              },
                            ]}
                          >
                            <Input
                              name="clinic_branch_address"
                              label="clinic_branch_address"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#7D7D7D",
                                height: "40px",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              disabled={enableBranchInputs}
                            />
                          </Form.Item>
                        )}
                        {patientUserDetails && (
                          <Form.Item
                            name="clinic_branch_address"
                            rules={[
                              {
                                required: true,
                                message: "Please enter address",
                              },
                            ]}
                          >
                            <Input
                              name="clinic_branch_address"
                              label="clinic_branch_address"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#7D7D7D",
                                height: "40px",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                            />
                          </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          City<span style={{ color: "red" }}>*</span>
                        </span>
                        {get_branch_details && (
                          <Form.Item
                            name="clinic_branch_city"
                            initialValue={get_branch_details.city}
                            rules={[
                              { required: true, message: "Please enter City" },
                              {
                                pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                message:
                                  "First character should be alphabetical and City must contain only alphabeticals",
                              },
                            ]}
                          >
                            <Input
                              name="clinic_branch_city"
                              label="clinic_branch_city"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                height: "40px",
                                color: "#7D7D7D",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              disabled={enableBranchInputs}
                              onKeyPress={(e) => {
                                const charCode = e.which || e.keyCode;
                                const inputValue = e.target.value;
                                if (
                                  !(
                                    (charCode >= 65 && charCode <= 90) ||
                                    (charCode >= 97 && charCode <= 122) ||
                                    (charCode === 32 &&
                                      inputValue.trim() !== "")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        )}
                        {patientUserDetails && (
                          <Form.Item
                            name="clinic_branch_city"
                            rules={[
                              { required: true, message: "Please enter City" },
                              {
                                pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                message:
                                  "First character should be alphabetical and City must contain only alphabeticals",
                              },
                            ]}
                          >
                            <Input
                              name="clinic_branch_city"
                              label="clinic_branch_city"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                height: "40px",
                                color: "#7D7D7D",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              onKeyPress={(e) => {
                                const charCode = e.which || e.keyCode;
                                const inputValue = e.target.value;
                                if (
                                  !(
                                    (charCode >= 65 && charCode <= 90) ||
                                    (charCode >= 97 && charCode <= 122) ||
                                    (charCode === 32 &&
                                      inputValue.trim() !== "")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Apartment / Suite ETC.,
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        {get_branch_details && (
                          <Form.Item
                            name="clinic_branch_apartment"
                            initialValue={get_branch_details.address_line_one}
                            rules={[
                              {
                                required: true,
                                message: "Please enter Apartment",
                              },
                            ]}
                          >
                            <Input
                              name="clinic_branch_apartment"
                              label="clinic_branch_apartment"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#7D7D7D",
                                height: "40px",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              disabled={enableBranchInputs}
                            />
                          </Form.Item>
                        )}
                        {patientUserDetails && (
                          <Form.Item
                            name="clinic_branch_apartment"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Apartment",
                              },
                            ]}
                          >
                            <Input
                              name="clinic_branch_apartment"
                              label="clinic_branch_apartment"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#7D7D7D",
                                height: "40px",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                            />
                          </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Country<span style={{ color: "red" }}>*</span>
                        </span>
                        {get_branch_details && (
                          <Form.Item
                            name="clinic_branch_country"
                            initialValue={get_branch_details.country}
                            rules={[
                              {
                                required: true,
                                message: "Please enter Country",
                              },
                              {
                                pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                message:
                                  "First character should be alphabetical and Country must contain only alphabeticals",
                              },
                            ]}
                          >
                            <Input
                              name="clinic_branch_country"
                              label="clinic_branch_country"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                height: "40px",
                                color: "#7D7D7D",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              disabled={enableBranchInputs}
                              onKeyPress={(e) => {
                                const charCode = e.which || e.keyCode;
                                const inputValue = e.target.value;
                                if (
                                  !(
                                    (charCode >= 65 && charCode <= 90) ||
                                    (charCode >= 97 && charCode <= 122) ||
                                    (charCode === 32 &&
                                      inputValue.trim() !== "")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        )}
                        {patientUserDetails && (
                          <Form.Item
                            name="clinic_branch_country"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Country",
                              },
                              {
                                pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                message:
                                  "First character should be alphabetical and Country must contain only alphabeticals",
                              },
                            ]}
                          >
                            <Input
                              name="clinic_branch_country"
                              label="clinic_branch_country"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                height: "40px",
                                color: "#7D7D7D",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              onKeyPress={(e) => {
                                const charCode = e.which || e.keyCode;
                                const inputValue = e.target.value;
                                if (
                                  !(
                                    (charCode >= 65 && charCode <= 90) ||
                                    (charCode >= 97 && charCode <= 122) ||
                                    (charCode === 32 &&
                                      inputValue.trim() !== "")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          State / Province
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        {get_branch_details && (
                          <Form.Item
                            name="clinic_branch_state"
                            initialValue={get_branch_details.province}
                            rules={[
                              {
                                required: true,
                                message: "Please enter State / Province",
                              },
                              {
                                pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                message:
                                  "First character should be alphabetical and State/Province must contain only alphabeticals",
                              },
                            ]}
                          >
                            <Input
                              name="clinic_branch_state"
                              label="clinic_branch_state"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#7D7D7D",
                                height: "40px",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              disabled={enableBranchInputs}
                              onKeyPress={(e) => {
                                const charCode = e.which || e.keyCode;
                                const inputValue = e.target.value;
                                if (
                                  !(
                                    (charCode >= 65 && charCode <= 90) ||
                                    (charCode >= 97 && charCode <= 122) ||
                                    (charCode === 32 &&
                                      inputValue.trim() !== "")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        )}
                        {patientUserDetails && (
                          <Form.Item
                            name="clinic_branch_state"
                            rules={[
                              {
                                required: true,
                                message: "Please enter State / Province",
                              },
                              {
                                pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                message:
                                  "First character should be alphabetical and State/Province must contain only alphabeticals",
                              },
                            ]}
                          >
                            <Input
                              name="clinic_branch_state"
                              label="clinic_branch_state"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#7D7D7D",
                                height: "40px",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              onKeyPress={(e) => {
                                const charCode = e.which || e.keyCode;
                                const inputValue = e.target.value;
                                if (
                                  !(
                                    (charCode >= 65 && charCode <= 90) ||
                                    (charCode >= 97 && charCode <= 122) ||
                                    (charCode === 32 &&
                                      inputValue.trim() !== "")
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Zip Code<span style={{ color: "red" }}>*</span>
                        </span>
                        {get_branch_details && (
                          <Form.Item
                            name="clinic_branch_zipcode"
                            initialValue={get_branch_details.zip_code}
                            rules={[
                              {
                                required: true,
                                message: "Please enter Zip code",
                              },
                              { validator: validateZipCode },
                            ]}
                          >
                            <Input
                              name="clinic_branch_zipcode"
                              label="clinic_branch_zipcode"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                height: "40px",
                                color: "#7D7D7D",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              disabled={enableBranchInputs}
                              onKeyPress={(e) => {
                                const charCode = e.which || e.keyCode;
                                if (!(charCode >= 48 && charCode <= 57)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        )}
                        {patientUserDetails && (
                          <Form.Item
                            name="clinic_branch_zipcode"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Zip code",
                              },
                              { validator: validateZipCode },
                            ]}
                          >
                            <Input
                              name="clinic_branch_zipcode"
                              label="clinic_branch_zipcode"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                height: "40px",
                                color: "#7D7D7D",
                                border: "1px solid #E3E3E3",
                              }}
                              bordered={false}
                              onKeyPress={(e) => {
                                const charCode = e.which || e.keyCode;
                                if (!(charCode >= 48 && charCode <= 57)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        )}
                      </Row>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </Spin>
      </Form>
    </div>
  );
};

export default AddClinicBranch;
