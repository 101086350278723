import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../string";
import { Modal, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import AiCanvasReport from "./AiXrayReport";

const ReportFullImage = ({
  imageUrl,
  openFullimageModal,
  setOpenFullimageModal,
}) => {
  const [fileContent, setFileContent] = useState("");
  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: imageUrl,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [imageUrl]);

  return (
    <Modal
      centered
      width={"1045px"}
      open={openFullimageModal}
      closable={false}
      footer={false}
      style={{
        borderRadius: "5px",
      }}
    >
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 0 20px 0",
        }}
      >
        <div style={{ fontSize: 22, fontWeight: 600 }}>View Full Image</div>
        <CloseOutlined
          style={{ color: "#FF0000", fontSize: 20 }}
          onClick={() => setOpenFullimageModal(false)}
        />
      </Row>
      <AiCanvasReport xrayImageUrl={fileContent} />
    </Modal>
  );
};

export default ReportFullImage;
