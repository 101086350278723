import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../string";
import AiCanvasReport from "./AiXrayReport";

const ReportViewImage = ({ imageUrl, currentSlide, index }) => {
  const [fileContent, setFileContent] = useState("");
  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: imageUrl,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [imageUrl]);

  return (
    <div>
      {currentSlide === index ? (
        <AiCanvasReport xrayImageUrl={fileContent} />
      ) : (
        <img
          style={{
            width: "100%",
          }}
          src={fileContent}
          alt="example"
        />
      )}
    </div>
  );
};

export default ReportViewImage;
