import { LoadingOutlined } from "@ant-design/icons";
import { Button, Calendar, Col, Form, Row, Select, Tabs, TimePicker, Modal, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import API_BASE_URL from "../../../../../env";
import successLogo from "../../../../../assets/success.png";

const TimeOffRequests = () => {
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 50, margin: "130px 0 0 0" }} spin />
    );
    const navigate = useNavigate();
    const Token = localStorage.getItem("Token");
    const clinic_id = localStorage.getItem("clinic_id");
    const branch_id = localStorage.getItem("branch_id");
    const staff_id = localStorage.getItem("user_id");
    const [spinner, setspinner] = useState(false);
    const [isSendReqClicked, setIsSendReqClicked] = useState(false);
    const [displayDate, setDisplayDate] = useState(moment());
    const [selectedDate, setSelectedDate] = useState(null);
    const format = "hh:mm A";
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [reason, setReason] = useState(null);
    const [staffTimeOffData, setStaffTimeOffData] = useState([]);
    const [successMessageModal, setSuccessMessageModal] = useState(false);

    const convertTimestamp = (timestamp, type) => {
        const date = new Date(timestamp);

        const dateOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };

        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

        return type === "date" ? formattedDate : formattedTime;
    };

    const items = [
        // Approved
        {
            key: "1",
            label: (
                <div className="d-flex">
                    Approved&nbsp;
                    <span className="time-off-req-length">
                        {staffTimeOffData &&
                            staffTimeOffData
                                .filter((item) => item.status === "Approved")
                                .filter((item) => item.status === "Approved").length}
                    </span>
                </div>
            ),
            children: (
                <div className="time-off-req-data">
                    {staffTimeOffData &&
                        staffTimeOffData
                            .filter((item) => item.status === "Approved")
                            .map((item) => {
                                return (
                                    <div className="manage-hours-data">
                                        <Row style={{
                                            display: "flex",
                                            gap: "0.52vw",
                                            padding: "0.73vw 1.25vw 0.417vw"
                                        }}>
                                            <span style={{
                                                color: "#B3B9BD",
                                                fontSize: "0.73vw",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "148.59 %" /* 20.803px */
                                            }}>
                                                Submitted on
                                            </span>
                                            <span className="time-off-time">
                                                {convertTimestamp(item.requested_on, "date")}, {convertTimestamp(item.requested_on, "time")}
                                            </span>
                                        </Row>
                                        <hr style={{
                                            width: "100%",
                                            strokeWidth: "1px",
                                            color: " #D0D0D0",
                                        }} />
                                        <Row style={{
                                            padding: "0.417vw 1.25vw 0"
                                        }}>
                                            <Col span={7}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.417vw"
                                                }}>
                                                <span className="time-off-div-header"
                                                >From Date</span>
                                                <Row style={{
                                                    display: "flex",
                                                    gap: "0.73vw"
                                                }}>
                                                    <span className="date-wrapper">{convertTimestamp(item.from_time, "date")}</span>
                                                    <span className="date-wrapper">{convertTimestamp(item.from_time, "time")}</span>
                                                </Row>
                                            </Col>
                                            <Col span={10}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.417vw"
                                                }}>
                                                <span className="time-off-div-header"
                                                >To Date</span>
                                                <Row style={{
                                                    display: "flex",
                                                    gap: "0.73vw"
                                                }}>
                                                    <span className="date-wrapper">{convertTimestamp(item.to_time, "date")}</span>
                                                    <span className="date-wrapper">{convertTimestamp(item.to_time, "time")}</span>
                                                </Row>
                                            </Col>
                                            <Col span={7}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.417vw"
                                                }}>
                                                <span className="approved-on"
                                                >
                                                    {item.status}
                                                </span>
                                                <span className="time-off-time">
                                                    {convertTimestamp(item.from_time, "date")}, {convertTimestamp(item.from_time, "time")}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.417vw",
                                            padding: "1.563vw 1.25vw"
                                        }}>
                                            <span className="time-off-div-header">Reason</span>
                                            <span className="time-off-div-description">{item.reason}</span>
                                        </Row>
                                    </div>
                                )
                            })}
                </div>
            )
        },
        // Pending
        {
            key: "2",
            label: (
                <div className="d-flex">
                    Pending&nbsp;
                    <span className="time-off-req-length">
                        {staffTimeOffData &&
                            staffTimeOffData
                                .filter((item) => item.status === "Pending")
                                .filter((item) => item.status === "Pending").length}
                    </span>
                </div>
            ),
            children: (
                <div className="time-off-req-data">
                    {staffTimeOffData &&
                        staffTimeOffData
                            .filter((item) => item.status === "Pending")
                            .map((item) => {
                                return (
                                    <div className="manage-hours-data">
                                        <Row style={{
                                            display: "flex",
                                            gap: "0.52vw",
                                            padding: "0.73vw 1.25vw 0.417vw"
                                        }}>
                                            <span style={{
                                                color: "#B3B9BD",
                                                fontSize: "0.73vw",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "148.59 %" /* 20.803px */
                                            }}>
                                                Submitted on
                                            </span>
                                            <span className="time-off-time">
                                                {convertTimestamp(item.requested_on, "date")}, {convertTimestamp(item.requested_on, "time")}
                                            </span>
                                        </Row>
                                        <hr style={{
                                            width: "100%",
                                            strokeWidth: "1px",
                                            color: " #D0D0D0",
                                        }} />
                                        <Row style={{
                                            padding: "0.417vw 1.25vw 0"
                                        }}>
                                            <Col span={7}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.417vw"
                                                }}>
                                                <span className="time-off-div-header"
                                                >From Date</span>
                                                <Row style={{
                                                    display: "flex",
                                                    gap: "0.73vw"
                                                }}>
                                                    <span className="date-wrapper">{convertTimestamp(item.from_time, "date")}</span>
                                                    <span className="date-wrapper">{convertTimestamp(item.from_time, "time")}</span>
                                                </Row>
                                            </Col>
                                            <Col span={10}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.417vw"
                                                }}>
                                                <span className="time-off-div-header"
                                                >To Date</span>
                                                <Row style={{
                                                    display: "flex",
                                                    gap: "0.73vw"
                                                }}>
                                                    <span className="date-wrapper">{convertTimestamp(item.to_time, "date")}</span>
                                                    <span className="date-wrapper">{convertTimestamp(item.to_time, "time")}</span>
                                                </Row>
                                            </Col>
                                            <Col span={7}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.417vw"
                                                }}>
                                                <span className="pending-on"
                                                >
                                                    {item.status}
                                                </span>
                                                <span className="time-off-time">
                                                    {convertTimestamp(item.requested_on, "date")}, {convertTimestamp(item.requested_on, "time")}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.417vw",
                                            padding: "1.563vw 1.25vw"
                                        }}>
                                            <span className="time-off-div-header">Reason</span>
                                            <span className="time-off-div-description">{item.reason}</span>
                                        </Row>
                                    </div>
                                )
                            })}
                </div>
            )
        },
        // Rejected
        {
            key: "3",
            label: (
                <div className="d-flex">
                    Rejected&nbsp;
                    <span className="time-off-req-length">
                        {staffTimeOffData &&
                            staffTimeOffData
                                .filter((item) => item.status === "Rejected")
                                .filter((item) => item.status === "Rejected").length}
                    </span>
                </div>
            ),
            children: (
                <div className="time-off-req-data">
                    {staffTimeOffData &&
                        staffTimeOffData
                            .filter((item) => item.status === "Rejected")
                            .map((item) => {
                                return (
                                    <div className="manage-hours-data">
                                        <Row style={{
                                            display: "flex",
                                            gap: "0.52vw",
                                            padding: "0.73vw 1.25vw 0.417vw"
                                        }}>
                                            <span style={{
                                                color: "#B3B9BD",
                                                fontSize: "0.73vw",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "148.59 %" /* 20.803px */
                                            }}>
                                                Submitted on
                                            </span>
                                            <span className="time-off-time">
                                                {convertTimestamp(item.requested_on, "date")}, {convertTimestamp(item.requested_on, "time")}
                                            </span>
                                        </Row>
                                        <hr style={{
                                            width: "100%",
                                            strokeWidth: "1px",
                                            color: " #D0D0D0",
                                        }} />
                                        <Row style={{
                                            padding: "0.417vw 1.25vw 0"
                                        }}>
                                            <Col span={7}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.417vw"
                                                }}>
                                                <span className="time-off-div-header"
                                                >From Date</span>
                                                <Row style={{
                                                    display: "flex",
                                                    gap: "0.73vw"
                                                }}>
                                                    <span className="date-wrapper">{convertTimestamp(item.from_time, "date")}</span>
                                                    <span className="date-wrapper">{convertTimestamp(item.from_time, "time")}</span>
                                                </Row>
                                            </Col>
                                            <Col span={10}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.417vw"
                                                }}>
                                                <span className="time-off-div-header"
                                                >To Date</span>
                                                <Row style={{
                                                    display: "flex",
                                                    gap: "0.73vw"
                                                }}>
                                                    <span className="date-wrapper">{convertTimestamp(item.to_time, "date")}</span>
                                                    <span className="date-wrapper">{convertTimestamp(item.to_time, "time")}</span>
                                                </Row>
                                            </Col>
                                            <Col span={7}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.417vw"
                                                }}>
                                                <span className="rejected-on"
                                                >
                                                    {item.status}
                                                </span>
                                                <span className="time-off-time">
                                                    {convertTimestamp(item.changed_on, "date")}, {convertTimestamp(item.changed_on, "time")}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.417vw",
                                            padding: "1.563vw 1.25vw"
                                        }}>
                                            <span className="time-off-div-header">Reason</span>
                                            <span className="time-off-div-description">{item.reason}</span>
                                        </Row>
                                    </div>
                                )
                            })}
                </div>
            )
        },
    ];

    const onPanelChange = (date, mode) => {
        setDisplayDate(date); // Update display date
    };

    const onDateSelect = (date) => {
        setSelectedDate(null);
        setDisplayDate(date);
        setSelectedDate(date.format("YYYY-MM-DD"));
    };

    const handleStartTimeChange = (val) => {
        const timeString = val.format("THH:mm:ss.SSS[Z]");
        setStartTime(timeString);
    };

    const handleEndTimeChange = (val) => {
        const timeString = val.format("THH:mm:ss.SSS[Z]");
        setEndTime(timeString);
    };

    useEffect(() => {
        getTimeOffData();
    }, []);

    const config = {
        headers: {
            Authorization: `Bearer ${Token}`,
        },
    };

    const getTimeOffData = async () => {
        await axios
            .get(`${API_BASE_URL}/api/v1/adminStaff/staff/${staff_id}/time-off`, config)
            .then((res) => {
                setStaffTimeOffData(res.data.data);
            })
    }

    const handleSendTimeOffRequest = async () => {
        let payload = {
            staff_id: staff_id,
            clinic_id: clinic_id,
            branch_id: branch_id,
            from_time: selectedDate + startTime,
            to_time: selectedDate + endTime,
            status: "Pending",
            reason: reason
        }

        await axios
            .post(`${API_BASE_URL}/api/v1/adminStaff`, payload, config)
            .then((res) => {
                setStaffTimeOffData([...staffTimeOffData, res.data.data]);
                setSuccessMessageModal(true);
                setIsSendReqClicked(false);
            })
            .catch((err) => {
                message.error(err.response.data.data.errors[0]);
            });
    }


    return (
        <div style={{
            width: "100%"
        }}>
            {isSendReqClicked ? (
                <div>
                    <Row className="d-flex"
                        style={{
                            flexDirection: "column",
                            gap: "0.417vw"
                        }}>
                        <span className="send-reqeust">
                            Send Request
                        </span>
                        <span className="send-req-desc">
                            Submit your time off request here. Please provide details regarding the requested dates and reason for absence
                        </span>
                    </Row>
                    <hr style={{
                        width: "100%",
                        strokeWidth: "1px",
                        color: " #D0D0D0",
                    }} />
                    <Row style={{
                        padding: "2.6045vw 0 0"
                    }}>
                        <Col span={8}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.73vw"
                            }}>
                            <span className="send-req-hearder"
                            >Select Date</span>
                            <Calendar
                                className="time-off-calendar"
                                fullscreen={false}
                                headerRender={({ value, type, onChange, onTypeChange }) => {
                                    const start = 0;
                                    const end = 12;
                                    const monthOptions = [];
                                    let current = value.clone();
                                    const localeData = value.localeData();
                                    const months = [];
                                    for (let i = 0; i < 12; i++) {
                                        current = current.month(i);
                                        months.push(localeData.monthsShort(current));
                                    }
                                    for (let i = start; i < end; i++) {
                                        monthOptions.push(
                                            <Select.Option key={i} value={i} className="month-item">
                                                {months[i]}
                                            </Select.Option>,
                                        );
                                    }
                                    const year = value.year();
                                    const month = value.month();
                                    const options = [];
                                    for (let i = year - 10; i < year + 10; i += 1) {
                                        options.push(
                                            <Select.Option key={i} value={i} className="year-item">
                                                {i}
                                            </Select.Option>,
                                        );
                                    }
                                    return (
                                        <div
                                            style={{
                                                padding: "0.417vw",
                                            }}
                                        >
                                            <Row gutter={8}>
                                                <Col>
                                                    <Select
                                                        size="small"
                                                        dropdownMatchSelectWidth={false}
                                                        className="my-year-select"
                                                        value={year}
                                                        onChange={(newYear) => {
                                                            const now = value.clone().year(newYear);
                                                            onChange(now);
                                                        }}
                                                    >
                                                        {options}
                                                    </Select>
                                                </Col>
                                                <Col>
                                                    <Select
                                                        size="small"
                                                        dropdownMatchSelectWidth={false}
                                                        value={month}
                                                        onChange={(newMonth) => {
                                                            const now = value.clone().month(newMonth);
                                                            onChange(now);
                                                        }}
                                                    >
                                                        {monthOptions}
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                }}
                                onSelect={onDateSelect}
                                onPanelChange={onPanelChange}
                            />
                        </Col>
                        <Col span={16} style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2.6045vw",
                            padding: "0 0 0 2.34vw"
                        }}>
                            <Row style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.834vw"
                            }}>
                                <span className="send-req-hearder">Select Timing</span>
                                <Row
                                    className="d-flex ai-center"
                                    style={{
                                        gap: "1.0938vw",
                                        padding: "0.26vw 0 0 0",
                                    }}
                                >
                                    <TimePicker
                                        className="time-off-time-picker"
                                        format={format}
                                        minuteStep={15}
                                        onChange={handleStartTimeChange}
                                    />
                                    <span
                                        style={{
                                            fontSize: "0.834vw",
                                        }}
                                    >
                                        to
                                    </span>
                                    <TimePicker
                                        className="time-off-time-picker"
                                        format={format}
                                        minuteStep={15}
                                        onChange={handleEndTimeChange}
                                    />
                                </Row>
                            </Row>
                            <Row style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.834vw"
                            }}>
                                <span className="send-req-hearder">Reason</span>
                                <TextArea className="text-area" onChange={(e) => {
                                    setReason(e.target.value)
                                }} />
                            </Row>
                        </Col>
                    </Row>
                    <Row
                        className="d-flex jc-end ai-center"
                        style={{
                            padding: "6vw 0 0",
                            gap: "0.938vw"
                        }}
                    >
                        <Button
                            className="cancel-button"
                            type="danger"
                            onClick={() => {
                                setIsSendReqClicked(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="save-button"
                            type="primary"
                            htmlType="submit"
                            onClick={handleSendTimeOffRequest}
                        >
                            Submit
                        </Button>
                    </Row>
                </div>
            ) : (
                <Row className="d-flex jc-right">
                    <Tabs
                        onChange={() => {
                            // handleGetAllAppointmentsForClinic();
                        }}
                        className="w-100 time-off-tabs"
                        items={items}
                    />
                    <Button className="send-req-button"
                        onClick={() => {
                            setIsSendReqClicked(true);
                        }}>Send Request
                    </Button>
                </Row>
            )}
            <Modal
                width={"33vw"}
                centered
                open={successMessageModal}
                footer={false}
                closable={false}
                destroyOnClose={true}
                className="approved-modal">
                <div
                    className="approved-message"
                >
                    <img className="success-logo" src={successLogo} />
                    <span className="approve-message-header">Request Sent Successfully!!</span>
                    <span className="approve-message-desc">
                        Your request for time off has been successfully submitted.
                        You will be notified once it has been processed
                    </span>
                    <Button className="close-button" onClick={() => setSuccessMessageModal(false)}>View Status</Button>
                </div>
            </Modal>
        </div >


    );
};

export default TimeOffRequests;