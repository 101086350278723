import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./SuperAdminSubUser.css";
import { Helmet } from "react-helmet";
import SubUsersDetails from "./sub_users_details/SubUsersDetails";
import { routes_super_admin_add_sub_user } from "../../../../utils/super_admin_constants/SuperAdminConsts";
import { useNavigate } from "react-router";
import AddStaffIcon from "../../../../assets/plus-icon-green-col.png";
import axios from "axios";
import API_BASE_URL from "../../../../env";

const SuperAdminSubUsers = () => {
  const Token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const [subUsers, setSubUsers] = useState();

  const handleGetAllSubUsers = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/user/subusers`, config)
      .then((res) => {
        setSubUsers(res.data.data);
      });
  };
  useEffect(() => {
    handleGetAllSubUsers();
  }, []);

  return (
    <div className="super-admin-sub-users-main-div">
      <Helmet>
        <title>Sub Users</title>
      </Helmet>
      <Row
        className="d-flex ai-center jc-between"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "12px 23px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Sub Users ({subUsers && subUsers.length})
          </span>
        </Row>
        <Row
          style={{
            display: "flex",
            gap: 44,
          }}
        >
          <Button
            style={{
              background: "#FFFFFF",
              border: "1px solid #97C46F",
              borderRadius: 30,
              fontWeight: 600,
              fontSize: 14,
              color: "#525252",
            }}
            onClick={() => {
              navigate(routes_super_admin_add_sub_user);
            }}
          >
            <Row className="d-flex ai-center" style={{ gap: 7 }}>
              <img style={{ height: "20px" }} src={AddStaffIcon} alt="" />
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14PX",
                  color: "#525252",
                }}
              >
                Add New User
              </span>
            </Row>
          </Button>
        </Row>
        <Row></Row>
      </Row>
      {subUsers ? (
        <Row
          style={{
            background: "#FFF",
            padding: "0 33px 33px 33px",
            margin: "0 23px",
          }}
        >
          <Row
            className="xray-details"
            style={{
              width: "100%",
              padding: "0px 32px 0 32px",
              margin: "23px 4px 15px 0",
            }}
          >
            <Col span={4}>
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#A8A8A8",
                }}
              >
                Upload Date
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#A8A8A8",
                }}
              >
                Name / ID
              </span>
            </Col>
            <Col span={5}>
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#A8A8A8",
                }}
              >
                Email
              </span>
            </Col>
            <Col span={5}>
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#A8A8A8",
                }}
              >
                Phone
              </span>
            </Col>
            <Col span={5}>
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#A8A8A8",
                }}
              >
                Role
              </span>
            </Col>
          </Row>
          <Row
            style={{
              maxHeight: "70vh",
              overflow: "auto",
              width: "100%",
            }}
          >
            {subUsers &&
              subUsers.map((SubUsers) => {
                return (
                  <SubUsersDetails
                    SubUsers={SubUsers}
                    handleGetAllSubUsers={handleGetAllSubUsers}
                  />
                );
              })}
          </Row>
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#FFF",
            padding: "0 33px 33px 33px",
            margin: "0 23px",
            height: "80vh",
          }}
        >
          <span
            style={{
              fontSize: "30px",
              fontWeight: "700",
              color: "#323232",
              opacity: 0.8,
            }}
          >
            You don't have user's now
          </span>
        </Row>
      )}
    </div>
  );
};

export default SuperAdminSubUsers;
