import { Button, Col, Input, Modal, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import notesicon from "../../../../../assets/notes.png";
import axios from "axios";
import "./AdminAppointment.css";
import {
  DATETIME_FORMAT_WITH_FULL,
  _scheduled_by_clinic,
  _status_cancelled,
  _status_completed,
  _status_confirmed,
  _status_in_progress,
  _status_rescheduled,
  _status_scheduled,
} from "../../../../../utils/appointment_constants/AppointmentConstants";
import API_BASE_URL from "../../../../../env";
import { CloseOutlined } from "@ant-design/icons";
import StaffProfilePicture from "../../clinic_admin_dashboard/StaffProfilePicture";
import TextArea from "antd/lib/input/TextArea";
import { useLocation, useNavigate } from "react-router";
import AppointmentProfilePic from "../AppointmentProfilePic";
import Item from "antd/lib/list/Item";
import { UserAuth } from "../../../../../context_api/ContextApi";
import { tabs_mlanalysis } from "../../../../../utils/patient_navbar_constants/PatientNavbarConstants";

const AppointmentManagement = (props) => {
  const Token = localStorage.getItem("Token");
  const {
    data,
    // handleGetAllAppointmentsForClinic,
    // handleSchedulingAppointments,
    setopenRescheduleModal,
    openRescheduleModal,
    setopenRescheduleReasonModal,
    setAppointmentSpinner,
    doc_name,
    handleGetAllAppointments,
  } = props;

  const {
    handleCancelPatientAppointment,
    handleGetAllAppointmentsForClinic,
    setRescheduleData,
    setTabActiveKey,
    // setCancellationReason,
    // cancellationReason,
  } = UserAuth();

  const [treatmentNotesModal, setTreatmentNotesModal] = useState(false);
  const branch_id = localStorage.getItem("main_branch_id")
    ? localStorage.getItem("main_branch_id")
    : localStorage.getItem("branch_id");
  const role = localStorage.getItem("role");
  const [treatmentNotes, settreatmentNotes] = useState(
    data.note.note_text && data.note.note_text
  );

  const handleConfirmPatientAppointment = () => {
    let payload;
    // setAppointmentSpinner(true);
    if (branch_id) {
      payload = {
        patient_id: data.patient_id,
        clinic_id: data.clinic.id,
        treatment_type_id: data.treatment_type.id,
        from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
        scheduled_by: data.scheduled_by,
        status: _status_confirmed,
        doc_id: data.doctor.id,
        doc_slot: data.slots.id,
        notes: "",
        branch_id: branch_id,
      };
    } else {
      payload = {
        patient_id: data.patient_id,
        clinic_id: data.clinic.id,
        treatment_type_id: data.treatment_type.id,
        from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
        scheduled_by: data.scheduled_by,
        status: _status_confirmed,
        doc_id: data.doctor.id,
        doc_slot: data.slots.id,
        notes: "",
      };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(`${API_BASE_URL}/api/v1/appointment/${data.id}`, payload, config)
      .then((res) => {
        setTimeout(() => {
          if (role === "STANDARD") {
            handleGetAllAppointments();
          } else {
            handleGetAllAppointmentsForClinic();
          }
          setAppointmentSpinner(false);
        }, 300);
      })
      .catch((err) => {
        setTimeout(() => {
          if (role === "STANDARD") {
            handleGetAllAppointments();
          } else {
            handleGetAllAppointmentsForClinic();
          }
          setAppointmentSpinner(false);
        }, 300);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  // const handleCancelPatientAppointment = () => {
  //   let payload;
  //   // setAppointmentSpinner(true);
  //   if (branch_id) {
  //     payload = {
  //       patient_id: data.patient_id,
  //       clinic_id: data.clinic.id,
  //       treatment_type_id: data.treatment_type.id,
  //       from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
  //       scheduled_by: data.scheduled_by,
  //       status: _status_cancelled,
  //       doc_id: data.doctor.id,
  //       doc_slot: data.slots.id,
  //       notes: "",
  //       branch_id: branch_id,
  //     };
  //   } else {
  //     payload = {
  //       patient_id: data.patient_id,
  //       clinic_id: data.clinic.id,
  //       treatment_type_id: data.treatment_type.id,
  //       from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
  //       scheduled_by: data.scheduled_by,
  //       status: _status_cancelled,
  //       doc_id: data.doctor.id,
  //       doc_slot: data.slots.id,
  //       notes: "",
  //     };
  //   }
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   };
  //   axios
  //     .put(`${API_BASE_URL}/api/v1/appointment/${data.id}`, payload, config)
  //     .then((res) => {
  //       setTimeout(() => {
  //         // handleSchedulingAppointments();
  //         handleGetAllAppointmentsForClinic();
  //       }, 300);
  //     })
  //     .catch((err) => {
  //       message.error(err.response.data.data.errors);
  //     });
  // };

  const handleInProgressAppointment = () => {
    let payload;
    // setAppointmentSpinner(true);
    if (branch_id) {
      payload = {
        patient_id: data.patient_id,
        clinic_id: data.clinic.id,
        treatment_type_id: data.treatment_type.id,
        from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
        scheduled_by: data.scheduled_by,
        status: _status_in_progress,
        doc_id: data.doctor.id,
        doc_slot: data.slots.id,
        notes: "",
        branch_id: branch_id,
      };
    } else {
      payload = {
        patient_id: data.patient_id,
        clinic_id: data.clinic.id,
        treatment_type_id: data.treatment_type.id,
        from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
        scheduled_by: data.scheduled_by,
        status: _status_in_progress,
        doc_id: data.doctor.id,
        doc_slot: data.slots.id,
        notes: "",
      };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(`${API_BASE_URL}/api/v1/appointment/${data.id}`, payload, config)
      .then((res) => {
        setTimeout(() => {
          if (role === "STANDARD") {
            handleGetAllAppointments();
          } else {
            handleGetAllAppointmentsForClinic();
          }
          // handleSchedulingAppointments();
        }, 300);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const handleCompleteAppointment = () => {
    let payload;
    // setAppointmentSpinner(true);
    if (branch_id) {
      payload = {
        patient_id: data.patient_id,
        clinic_id: data.clinic.id,
        treatment_type_id: data.treatment_type.id,
        from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
        scheduled_by: data.scheduled_by,
        status: "Complete",
        doc_id: data.doctor.id,
        doc_slot: data.slots.id,
        notes: "",
        branch_id: branch_id,
      };
    } else {
      payload = {
        patient_id: data.patient_id,
        clinic_id: data.clinic.id,
        treatment_type_id: data.treatment_type.id,
        from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
        scheduled_by: data.scheduled_by,
        status: "Complete",
        doc_id: data.doctor.id,
        doc_slot: data.slots.id,
        notes: "",
      };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(`${API_BASE_URL}/api/v1/appointment/${data.id}`, payload, config)
      .then((res) => {
        setTimeout(() => {
          // handleSchedulingAppointments();
          if (role === "STANDARD") {
            handleGetAllAppointments();
          } else {
            handleGetAllAppointmentsForClinic();
          }
        }, 300);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const handleAddTreatmentNotes = () => {
    // setAppointmentSpinner(true);
    let payload;
    if (branch_id) {
      payload = {
        patient_id: data.patient_id,
        clinic_id: data.clinic.id,
        treatment_type_id: data.treatment_type.id,
        from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
        scheduled_by: data.scheduled_by,
        status: "Complete",
        doc_id: data.doctor.id,
        doc_slot: data.slots.id,
        notes: treatmentNotes,
        branch_id: branch_id,
      };
    } else {
      payload = {
        patient_id: data.patient_id,
        clinic_id: data.clinic.id,
        treatment_type_id: data.treatment_type.id,
        from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
        scheduled_by: data.scheduled_by,
        status: "Complete",
        doc_id: data.doctor.id,
        doc_slot: data.slots.id,
        notes: treatmentNotes,
      };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(`${API_BASE_URL}/api/v1/appointment/${data.id}`, payload, config)
      .then((res) => {
        setTreatmentNotesModal(false);
        setTimeout(() => {
          // handleSchedulingAppointments();
          if (role === "STANDARD") {
            handleGetAllAppointments();
          } else {
            handleGetAllAppointmentsForClinic();
          }
        }, 300);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const navigate = useNavigate();
  const location = useLocation();
  const key = location.pathname;
  const newkey = key.split("/")[1];

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  let config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  const { patientUserDetails, setSpinner } = UserAuth();

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const payload = {
      type: "invoice",
      description: "Invoice for the month of January 2020",
      partialPayment: true,
      customer: {
        name: patientUserDetails.name,
        contact: patientUserDetails.phone_number,
        email: patientUserDetails.email,
        billingAddress: {
          // line1: patientUserDetails.address_line_one,
          line2: patientUserDetails.address_line_one,
          zipcode: patientUserDetails.zip_code,
          city: patientUserDetails.city,
          state: patientUserDetails.province,
          country: patientUserDetails.country,
        },
      },
      line_items: [
        {
          name: data.treatment_type.treatment_name,
          description: data.treatment_type.description,
          // amount: item.treatment_type.rate,
          amount: 25000,
          currency: "INR",
          quantity: 1,
        },
      ],
      smsNotify: 1,
      emailNotify: 1,
      currency: "INR",
      expireBy: 1765046400,
    };

    const response = await axios.post(
      `http://ec2-43-205-173-70.ap-south-1.compute.amazonaws.com:8080/api/v1/payment/generateInvoice`,
      payload,
      config
    );

    window.location.href = response.data;
  }

  const pay_appointment_id = localStorage.getItem("appointment_id");
  const pay_treatment_id = localStorage.getItem("treatment_id");
  const pay_branch_id = localStorage.getItem("pay_branch_id");
  const searchParams = new URLSearchParams(location.search);
  const razorpay_invoice_id = searchParams.get("razorpay_invoice_id");
  const paymentId = searchParams.get("razorpay_payment_id");
  const signature = searchParams.get("razorpay_signature");
  const patientId = localStorage.getItem("patient_id");

  useEffect(() => {
    if (razorpay_invoice_id || paymentId || signature) {
      // setSpinner(true);
      // setAppointmentSpinner(true);
      let paymentdata;
      paymentdata = {
        orderId: razorpay_invoice_id,
        paymentId: paymentId,
        paymentSignature: signature,
        branchId: pay_branch_id,
        patientId: patientId,
        clinicId: data.clinic.id,
        amount: 0,
        status: "string",
        treatmentId: pay_treatment_id,
        appointmentId: pay_appointment_id,
      };
      axios
        .post(
          "http://ec2-43-205-173-70.ap-south-1.compute.amazonaws.com:8080/api/v1/payment/process-payment-razorpay",
          paymentdata,
          config
        )
        .then((res) => {
          message.success("payment success");
          navigate("");
          if (role === "STANDARD") {
            handleGetAllAppointments();
          } else {
            handleGetAllAppointmentsForClinic();
          }
          setAppointmentSpinner(false);
          // setSpinner(false);
        })
        .catch((err) => {
          navigate("");
          if (role === "STANDARD") {
            handleGetAllAppointments();
          } else {
            handleGetAllAppointmentsForClinic();
          }
          setAppointmentSpinner(false);
          // setSpinner(false);
        });
    }
  }, []);

  return (
    <Row
      key={data.id}
      style={{
        // background: "#F5F5F5",
        // height: 66,
        // margin: "16px 0 0 0",
        padding: "19px 42px",
        borderBottom: "1px solid #BBB",
      }}
      className="d-flex ai-center"
    >
      {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
        <Col span={4}>
          <Row>
            <Col>
              <AppointmentProfilePic
                pro={
                  data &&
                  data.doctor.profile_picture_url &&
                  data.doctor.profile_picture_url.split(".com/")[1]
                }
              />
              {/* <img alt="" src={ssdental}  /> */}
            </Col>
            <Col
              span={15}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 0 0 10px",
              }}
            >
              <Row>
                <span
                  style={{
                    // fontWeight: 600,
                    color: "#3498DB",
                    fontSize: 16,
                    fontWeight: 600,
                    // fontSize: "0.73vw",
                    // // textDecorationLine: "underline",
                    // color: "#2CA3FA",
                    // display: "block",
                    // width: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {data.doctor.name}
                </span>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.73vw",
                    color: "#000000",
                  }}
                >
                  SID #
                  <span
                    style={{
                      color: "#7D7D7D",
                    }}
                  >
                    {data.doctor.id}
                  </span>
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      {role === "STANDARD" && (
        <Col span={4}>
          <Row>
            <Col>
              <AppointmentProfilePic
                pro={
                  data &&
                  data.clinic.profile_picture_url &&
                  data.clinic.profile_picture_url.split(".com/")[1]
                }
              />
            </Col>
            <Col
              span={15}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 0 0 10px",
              }}
            >
              <Row>
                <span
                  style={{
                    // fontWeight: 600,
                    color: "#3498DB",
                    fontSize: 16,
                    fontWeight: 600,
                    // fontSize: "0.73vw",
                    // // textDecorationLine: "underline",
                    // color: "#2CA3FA",
                    // display: "block",
                    // width: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {data.clinic.name}
                </span>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.73vw",
                    color: "#000000",
                  }}
                >
                  SID #
                  <span
                    style={{
                      color: "#7D7D7D",
                    }}
                  >
                    {data.clinic.id}
                  </span>
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
      )}

      {role === "STANDARD" ? (
        <Col span={4}>
          <Row>
            <Col>
              <AppointmentProfilePic
                pro={
                  data &&
                  data.doctor.profile_picture_url &&
                  data.doctor.profile_picture_url.split(".com/")[1]
                }
              />
              {/* <img alt="" src={ssdental}  /> */}
            </Col>
            <Col
              span={15}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 0 0 10px",
              }}
            >
              <Row>
                <span
                  style={{
                    // fontWeight: 600,
                    color: "#3498DB",
                    fontSize: 16,
                    fontWeight: 600,
                    // fontSize: "0.73vw",
                    // // textDecorationLine: "underline",
                    // color: "#2CA3FA",
                    // display: "block",
                    // width: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {data.doctor.name}
                </span>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.73vw",
                    color: "#000000",
                  }}
                >
                  SID #
                  <span
                    style={{
                      color: "#7D7D7D",
                    }}
                  >
                    {data.doctor.id}
                  </span>
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col span={4}>
          <Row>
            <Col>
              <AppointmentProfilePic
                pro={
                  data.profile_picture_url &&
                  data.profile_picture_url.split(".com/")[1]
                }
              />
              {/* <img alt="" src={ssdental}  /> */}
            </Col>
            <Col
              span={15}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 0 0 10px",
              }}
            >
              <Row>
                <span
                  style={{
                    // fontWeight: 600,
                    color: "#3498DB",
                    fontSize: 16,
                    fontWeight: 600,
                    // fontSize: "0.73vw",
                    // // textDecorationLine: "underline",
                    // color: "#2CA3FA",
                    // display: "block",
                    // width: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {data.patient_name}
                </span>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.73vw",
                    color: "#000000",
                  }}
                >
                  SID #
                  <span
                    style={{
                      color: "#7D7D7D",
                    }}
                  >
                    {data.patient_id}
                  </span>
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
      )}

      {/* {role === "CLINIC_USER" && (
        <Col span={4}>
          <span
            style={{
              color: "#000",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {data.treatment_type.treatment_name}
          </span>
        </Col>
      )} */}
      {/* {role === "STANDARD" && ( */}
      <Col span={4}>
        <Row>
          <span
            style={{
              color: "#000",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {data.treatment_type.treatment_name}
          </span>
        </Row>
        <Row>
          {data.status === "Complete" && (
            <span
              style={{
                color: "#3498DB",
                fontSize: 16,
                fontWeight: 500,
                gap: 6,
                cursor: "pointer",
              }}
              className="d-flex ai-center"
              onClick={() => {
                setTreatmentNotesModal(true);
              }}
            >
              <img alt="" src={notesicon} style={{ width: 16 }} />
              Notes
            </span>
          )}
        </Row>
      </Col>
      {/* )} */}
      {role === "CLINIC_USER" && (
        <Col span={4}>
          <span
            style={{
              color: "#000",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {data.treatment_type.treatment_type
              ? data.treatment_type.treatment_type
              : "NA"}
          </span>
        </Col>
      )}

      <Col span={4}>
        {role !== "STANDARD" && data.status === "Rescheduled" && (
          <Row>
            <span
              style={{
                color: "#EC7063",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              We're sorry, but we have rescheduled the appointment due to the
              doctor's unavailability.
            </span>
          </Row>
        )}
        <Row style={{ gap: 14, margin: "6px 0 0 0" }}>
          <Col>
            <span
              style={{
                color: "#000",
                fontSize: 16,
                fontWeight: 500,
                borderRadius: 5,
                background: "#EAF6FF",
                padding: "8px 10px",
              }}
            >
              {moment(data.from_date).format("MMMM Do, YYYY")}
            </span>
          </Col>
          <Col>
            <span
              style={{
                color: "#000",
                fontSize: 16,
                fontWeight: 500,
                borderRadius: 5,
                background: "#EAF6FF",
                padding: "8px 10px",
              }}
            >
              {moment(data.from_date).format("HH:mm a")}
            </span>
          </Col>
        </Row>
      </Col>

      <Col span={4}>
        {data.status === _status_scheduled && (
          <span
            style={{
              color: "#E67E22",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {data.status}
          </span>
        )}
        {data.status === _status_confirmed && (
          <span
            style={{
              color: "#27AE60",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {data.status}
          </span>
        )}
        {data.status === _status_in_progress && (
          <span
            style={{
              color: "#3498DB",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {data.status}
          </span>
        )}
        {data.status === _status_completed && (
          <span
            style={{
              color: "#333",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {data.status}
          </span>
        )}
        {data.status === _status_cancelled && (
          <span
            style={{
              color: "#EC7063",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {data.status}
          </span>
        )}
        {data.status === _status_rescheduled && (
          <span
            style={{
              color: "#E67E22",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {/* {data.status} */}
            Pending
          </span>
        )}
      </Col>

      <Col span={4}>
        {data.status === _status_scheduled &&
          (role === "STANDARD" ? (
            <Button
              style={{
                display: "flex",
                width: 128 + 128,
                padding: "12px 30px",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
                height: 45,
                borderRadius: 3,
                border: "1px solid var(--Primary-color, #3498db)",
                color: "#3498db",
                fontSize: 14,
                fontWeight: 500,
              }}
              onClick={() => {
                setopenRescheduleReasonModal(true);
                setRescheduleData(data);
              }}
              // onClick={() => {
              //   if (newkey === "clinic-user") {
              //     navigate(
              //       `/clinic-user/add-report/${data.patient_id}/${data.id}`
              //     );
              //   }
              //   if (newkey === "clinic-admin") {
              //     navigate(
              //       `/clinic-admin/add-report/${data.patient_id}/${data.id}`
              //     );
              //   }
              //   // setAdminTabActiveKey(tabs_admin_patients);
              // }}
            >
              Reschedule
            </Button>
          ) : (
            <Row style={{ gap: 14 }}>
              <Col>
                <Button
                  style={{
                    borderRadius: 3,
                    background: "#27AE60",
                    display: "flex",
                    width: 128,
                    height: 45,
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: 500,
                    border: "1px solid #27AE60",
                  }}
                  onClick={handleConfirmPatientAppointment}
                >
                  Confirm
                </Button>
              </Col>
              <Col>
                <Button
                  // onClick={() =>
                  //   handleCancelPatientAppointment(data, _scheduled_by_clinic)
                  // }
                  onClick={() => {
                    setopenRescheduleReasonModal(true);
                    setRescheduleData(data);
                  }}
                  style={{
                    borderRadius: 3,
                    background: "#fff",
                    display: "flex",
                    width: 128,
                    height: 45,
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#EC7063",
                    fontSize: 14,
                    fontWeight: 500,
                    border: "1px solid #EC7063",
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          ))}
        {data.status === _status_confirmed &&
          (role === "STANDARD" ? (
            <Button
              style={{
                display: "flex",
                width: 128 + 128,
                padding: "12px 30px",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
                height: 45,
                borderRadius: 3,
                border: "1px solid var(--Primary-color, #3498db)",
                color: "#3498db",
                fontSize: 14,
                fontWeight: 500,
              }}
              onClick={() => {
                setopenRescheduleReasonModal(true);
                setRescheduleData(data);
              }}
              // onClick={() => {
              //   if (newkey === "clinic-user") {
              //     navigate(
              //       `/clinic-user/add-report/${data.patient_id}/${data.id}`
              //     );
              //   }
              //   if (newkey === "clinic-admin") {
              //     navigate(
              //       `/clinic-admin/add-report/${data.patient_id}/${data.id}`
              //     );
              //   }
              //   // setAdminTabActiveKey(tabs_admin_patients);
              // }}
            >
              Reschedule
            </Button>
          ) : (
            <Row style={{ gap: 14 }}>
              <Col>
                <Button
                  style={{
                    border: "1px solid #3498DB",
                    background: "#3498DB",
                    display: "flex",
                    width: 128,
                    padding: "12px 30px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    height: 45,
                    borderRadius: 3,
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  onClick={handleInProgressAppointment}
                >
                  In- Progress
                </Button>
              </Col>
              <Col>
                <Button
                  style={{
                    display: "flex",
                    width: 128,
                    padding: "12px 30px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    height: 45,
                    borderRadius: 3,
                    border: "1px solid var(--Primary-color, #3498db)",
                    color: "#3498db",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    setopenRescheduleReasonModal(true);
                    setRescheduleData(data);
                  }}
                >
                  Reschedule
                </Button>
              </Col>
            </Row>
          ))}
        {data.status === _status_in_progress &&
          (role === "STANDARD" ? (
            <Button
              style={{
                display: "flex",
                width: 128 + 128,
                padding: "12px 30px",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
                height: 45,
                borderRadius: 3,
                border: "1px solid var(--Primary-color, #3498db)",
                color: "#3498db",
                fontSize: 14,
                fontWeight: 500,
              }}
              onClick={() => {
                //   if (newkey === "clinic-user") {
                navigate(`/patient/ml-analysis`);
                setTabActiveKey(tabs_mlanalysis);
                //   }
                //   if (newkey === "clinic-admin") {
                //     navigate(
                //       `/clinic-admin/add-report/${data.patient_id}/${data.id}`
                //     );
                //   }
              }}
            >
              Reports
            </Button>
          ) : (
            <Row style={{ gap: 14 }}>
              <Col>
                <Button
                  style={{
                    border: "1px solid #3498DB",
                    background: "#3498DB",
                    display: "flex",
                    width: 128,
                    padding: "12px 30px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    height: 45,
                    borderRadius: 3,
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  onClick={handleCompleteAppointment}
                >
                  Complete
                </Button>
              </Col>
              <Col>
                <Button
                  style={{
                    display: "flex",
                    width: 128,
                    padding: "12px 30px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    height: 45,
                    borderRadius: 3,
                    border: "1px solid var(--Primary-color, #3498db)",
                    color: "#3498db",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    if (newkey === "clinic-user") {
                      navigate(
                        `/clinic-user/add-report/${data.patient_id}/${data.id}`
                      );
                    }
                    if (newkey === "clinic-admin") {
                      navigate(
                        `/clinic-admin/add-report/${data.patient_id}/${data.id}`
                      );
                    }
                    // setAdminTabActiveKey(tabs_admin_patients);
                  }}
                >
                  Reports
                </Button>
              </Col>
            </Row>
          ))}
        {data.status === _status_completed && (
          <Row style={{ gap: 14 }}>
            {role === "STANDARD" &&
              (data.payment_status === false ? (
                <Col>
                  <Button
                    style={{
                      border: "1px solid #3498DB",
                      background: "#3498DB",
                      display: "flex",
                      width: 128,
                      padding: "12px 30px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 8,
                      height: 45,
                      borderRadius: 3,
                      color: "#fff",
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      displayRazorpay();
                      localStorage.setItem(
                        "pay_branch_id",
                        data.clinic_branch.branch_id
                      );
                      localStorage.setItem("appointment_id", data.id);
                      localStorage.setItem(
                        "treatment_id",
                        data.treatment_type.id
                      );
                    }}
                  >
                    Pay now
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Button
                    style={{
                      display: "flex",
                      width: 128 + 128,
                      padding: "12px 30px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 8,
                      height: 45,
                      borderRadius: 3,
                      border: "1px solid var(--Primary-color, #3498db)",
                      color: "#3498db",
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      // if (newkey === "clinic-user") {
                      //   navigate(
                      //     `/clinic-user/add-report/${data.patient_id}/${data.id}`
                      //   );
                      // }
                      // if (newkey === "clinic-admin") {
                      //   navigate(
                      //     `/clinic-admin/add-report/${data.patient_id}/${data.id}`
                      //   );
                      // }
                      // setAdminTabActiveKey(tabs_admin_patients);
                    }}
                  >
                    Invoice
                  </Button>
                </Col>
              ))}
            {data.payment_status === false && role === "STANDARD" ? (
              <Col>
                <Button
                  style={{
                    display: "flex",
                    width: role === "STANDARD" ? 128 : 128 + 128,
                    padding: "12px 30px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    height: 45,
                    borderRadius: 3,
                    border: "1px solid var(--Primary-color, #3498db)",
                    color: "#3498db",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    if (newkey === "clinic-user") {
                      navigate(
                        `/clinic-user/add-report/${data.patient_id}/${data.id}`
                      );
                    }
                    if (newkey === "clinic-admin") {
                      navigate(
                        `/clinic-admin/add-report/${data.patient_id}/${data.id}`
                      );
                    }
                    // setAdminTabActiveKey(tabs_admin_patients);
                  }}
                >
                  Reports
                </Button>
              </Col>
            ) : (
              <Col>
                <Button
                  style={{
                    display: "flex",
                    width: 128 + 128,
                    padding: "12px 30px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    height: 45,
                    borderRadius: 3,
                    border: "1px solid var(--Primary-color, #3498db)",
                    color: "#3498db",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    if (newkey === "clinic-user") {
                      navigate(
                        `/clinic-user/add-report/${data.patient_id}/${data.id}`
                      );
                    }
                    if (newkey === "clinic-admin") {
                      navigate(
                        `/clinic-admin/add-report/${data.patient_id}/${data.id}`
                      );
                    }
                    // setAdminTabActiveKey(tabs_admin_patients);
                  }}
                >
                  Reports
                </Button>
              </Col>
            )}
          </Row>
        )}
        {data.status === _status_cancelled && (
          <Row>
            <span
              style={{
                color: "#B3B9BD",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Cancellation Reason:
            </span>
            <span
              style={{
                color: "#7D7D7D",
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              The appointment has been cancelled due to {data.reason}
            </span>
          </Row>
        )}
        {data.status === _status_rescheduled &&
          role === "STANDARD" &&
          data.scheduled_by === "Patient" && (
            <Row style={{ gap: 6 }}>
              <Row>
                <span
                  style={{
                    color: "#EC7063",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  {/* {
                    role
                  } */}
                  {/* Awaiting patient confirmation for the newly scheduled slot on */}
                  Awaiting doctor confirmation for the newly scheduled slot on
                </span>
              </Row>
              <Row style={{ gap: 14 }}>
                <Col>
                  <div
                    style={{
                      color: "#000",
                      fontSize: 16,
                      fontWeight: 500,
                      borderRadius: 5,
                      background: "#EAF6FF",
                      padding: "8px 10px",
                      height: 38,
                    }}
                    className="d-flex jc-center ai-centerz2"
                  >
                    {moment(data.from_date).format("MMMM Do, YYYY")}
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      color: "#000",
                      fontSize: 16,
                      fontWeight: 500,
                      borderRadius: 5,
                      background: "#EAF6FF",
                      padding: "8px 10px",
                      height: 38,
                    }}
                    className="d-flex jc-center ai-centerz2"
                  >
                    {moment(data.from_date).format("HH:mm a")}
                  </div>
                </Col>
              </Row>
            </Row>
          )}
        {data.status === _status_rescheduled &&
          role !== "STANDARD" &&
          data.scheduled_by === "Patient" && (
            <Row style={{ gap: 14 }}>
              <Col>
                <Button
                  style={{
                    borderRadius: 3,
                    background: "#27AE60",
                    display: "flex",
                    width: 128,
                    height: 45,
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: 500,
                    border: "1px solid #27AE60",
                  }}
                  onClick={handleConfirmPatientAppointment}
                >
                  Confirm
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={handleCancelPatientAppointment}
                  style={{
                    borderRadius: 3,
                    background: "#fff",
                    display: "flex",
                    width: 128,
                    height: 45,
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#EC7063",
                    fontSize: 14,
                    fontWeight: 500,
                    border: "1px solid #EC7063",
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}

        {data.status === _status_rescheduled &&
          role === "STANDARD" &&
          data.scheduled_by === "Clinic" && (
            <Row style={{ gap: 6 }}>
              <Row>
                <span
                  style={{
                    color: "#EC7063",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  {/* {
                    role
                  } */}
                  {/* Awaiting patient confirmation for the newly scheduled slot on */}
                  Awaiting doctor confirmation for the newly scheduled slot on
                </span>
              </Row>
              <Row style={{ gap: 14 }}>
                <Col>
                  <div
                    style={{
                      color: "#000",
                      fontSize: 16,
                      fontWeight: 500,
                      borderRadius: 5,
                      background: "#EAF6FF",
                      padding: "8px 10px",
                      height: 38,
                    }}
                    className="d-flex jc-center ai-centerz2"
                  >
                    {moment(data.from_date).format("MMMM Do, YYYY")}
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      color: "#000",
                      fontSize: 16,
                      fontWeight: 500,
                      borderRadius: 5,
                      background: "#EAF6FF",
                      padding: "8px 10px",
                      height: 38,
                    }}
                    className="d-flex jc-center ai-centerz2"
                  >
                    {moment(data.from_date).format("HH:mm a")}
                  </div>
                </Col>
              </Row>
            </Row>
          )}
        {data.status === _status_rescheduled &&
          role !== "STANDARD" &&
          data.scheduled_by === "Clinic" && (
            <Row style={{ gap: 14 }}>
              <Col>
                <Button
                  style={{
                    borderRadius: 3,
                    background: "#27AE60",
                    display: "flex",
                    width: 128,
                    height: 45,
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: 500,
                    border: "1px solid #27AE60",
                  }}
                  onClick={handleConfirmPatientAppointment}
                >
                  Confirm
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={handleCancelPatientAppointment}
                  style={{
                    borderRadius: 3,
                    background: "#fff",
                    display: "flex",
                    width: 128,
                    height: 45,
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#EC7063",
                    fontSize: 14,
                    fontWeight: 500,
                    border: "1px solid #EC7063",
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
      </Col>

      <Modal
        open={treatmentNotesModal}
        width={"28.697916666666668vw"}
        closable={false}
        footer={false}
        centered
        destroyOnClose={true}
      >
        <div
          style={{
            height: "27.136vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                fontWeight: "700",
                fontSize: "0.938vw",
                color: "#525252",
              }}
            >
              Note
            </span>
            <CloseOutlined
              style={{ color: "#FF0000", fontSize: "1.0417vw" }}
              onClick={() => setTreatmentNotesModal(false)}
            />
          </Row>
          <Row>
            <TextArea
              placeholder="Enter Treatment Notes"
              value={treatmentNotes}
              disabled={role === "STANDARD" && true}
              onChange={(e) => settreatmentNotes(e.target.value)}
              style={{
                height: "20.834vw",
                border: "1px solid #B3B9BD",
              }}
            />
          </Row>
          <Row
            className="w-100 d-flex jc-center"
            style={{ padding: "0 0 0.83vw 0" }}
          >
            <Button
              style={{
                height: "1.67vw",
                border: "1px solid #17A1FA",
                // borderRadius: "1.563vw",
                color: "#fff",
                fontWeight: 600,
                padding: "12px 30px",
                fontSize: "0.73vw",
                borderRadius: "5px",
                background: "#3498DB",
              }}
              onClick={() => {
                if (role === "STANDARD") {
                  setTreatmentNotesModal(false);
                } else {
                  handleAddTreatmentNotes();
                }
              }}
            >
              {role === "STANDARD" ? "Close" : "Save"}
            </Button>
          </Row>
        </div>
      </Modal>
    </Row>
  );
};

export default AppointmentManagement;
