import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import ProfilePic from "../../../../../assets/default-profile-pic.png";
import calendar_icon from "../../../../../assets/calendar-icon.png";
import axios from "axios";
import {
  DATETIME_FORMAT_WITH_FULL,
  _status_cancelled,
  _status_confirmed,
} from "../../../../../utils/appointment_constants/AppointmentConstants";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../string";
import API_BASE_URL from "../../../../../env";
import { useNavigate } from "react-router";
import { UserAuth } from "../../../../../context_api/ContextApi";
import { clinic_user_tabs_patients } from "../../../../../utils/clinic_user_constants/ClinicUserConstants";
import StaffProfilePicture from "../../../../clinic_admin/modules/clinic_admin_dashboard/StaffProfilePicture";

const DashboardAppointment = (props) => {
  const Token = localStorage.getItem("Token");
  const branch_id = localStorage.getItem("main_branch_id") ? localStorage.getItem("main_branch_id") : localStorage.getItem("branch_id") ;
  const { data, handleGetAllAppointmentsForClinic } = props;
  const [treatmentTypes, setTreatmentTypes] = useState();
  const navigate = useNavigate();
  const { setTabActiveKey } = UserAuth();

  const handleGetAllTreatmentTypes = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/static/treatment-type`, config)
      .then((res) => {
        setTreatmentTypes(res.data.data);
      });
  };

  const handleConfirmPatientAppointment = () => {
    const payload = {
      patient_id: data.patient_id,
      clinic_id: data.clinic.id,
      treatment_type_id: data.treatment_type_id,
      from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
      scheduled_by: data.scheduled_by,
      status: _status_confirmed,
      doc_id: data.doctor.id,
      doc_slot: data.slots.id,
      notes: "",
      branch_id: branch_id,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(`${API_BASE_URL}/api/v1/appointment/${data.id}`, payload, config)
      .then((res) => {
        setTimeout(() => {
          handleGetAllAppointmentsForClinic();
        }, 300);
      });
  };

  const handleCancelPatientAppointment = () => {
    const payload = {
      patient_id: data.patient_id,
      clinic_id: data.clinic.id,
      treatment_type_id: data.treatment_type_id,
      from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
      scheduled_by: data.scheduled_by,
      status: _status_cancelled,
      doc_id: data.doctor.id,
      doc_slot: data.slots.id,
      notes: "",
      branch_id: branch_id,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(`${API_BASE_URL}/api/v1/appointment/${data.id}`, payload, config)
      .then((res) => {
        setTimeout(() => {
          handleGetAllAppointmentsForClinic();
        }, 300);
      });
  };

  useEffect(() => {
    handleGetAllTreatmentTypes();
  }, []);

  return (
    <Row
      style={{
        borderRadius: "5px",
        background: "#FBFBFB",
        borderLeft: "4px solid #2CA3FA",
        display: "flex",
        alignItems: "center",
        padding: "15px 20px",
      }}
    >
      <Col span={6}>
        <Row>
          <Col>
            {/* <img
              src={fileContent ? fileContent : ProfilePic}
              alt=""
              style={{
                width: 49,
                height: 49,
                borderRadius: 18,
              }}
            /> */}
            <StaffProfilePicture
              pro={`public/profile-pictures/profile-picture-${data.patient_id}.jpg`}
            />
          </Col>
          <Col
            span={15}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "0 0 0 10px",
            }}
          >
            <Row>
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  textDecorationLine: "underline",
                  color: "#2CA3FA",
                  display: "block",
                  width: "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/clinic-user/patient-profile/${data.patient_id}`);
                  setTabActiveKey(clinic_user_tabs_patients);
                }}
              >
                {data && data.patient_name}
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#000000",
                }}
              >
                #PID
                <span
                  style={{
                    color: "#7D7D7D",
                  }}
                >
                  &nbsp;{data.patient_id}
                </span>
              </span>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        span={5}
        style={{
          display: "flex",
          gap: "7px",
          alignItems: "center",
        }}
      >
        <div style={{ width: 150, gap: 10 }} className="d-flex ai-center">
          <Row>
            <img
              src={calendar_icon}
              alt=""
              style={{
                width: "13px",
                height: "16px",
              }}
            />
          </Row>
          <Row>
            <Col
              style={{
                gap: 3,
              }}
              className="d-flex flex-col"
            >
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#858585",
                }}
              >
                {data.from_date &&
                  moment(data.from_date).format("ddd, DD/MM/YYYY  HH:mm A")}
              </span>
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={7}>
        {treatmentTypes &&
          treatmentTypes
            .filter((item) => item.id === data.treatment_type_id)
            .map((item) => {
              return (
                <span
                  key={item.id}
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#858585",
                  }}
                >
                  {item.name}
                </span>
              );
            })}
      </Col>
      <Col span={6}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 10,
            // width: 300,
          }}
        >
          {data.status === "Scheduled" && data.scheduled_by === "Patient" && (
            <Button
              style={{
                border: "1px solid #009DF7",
                borderRadius: 30,
                fontWeight: 600,
                fontSize: 14,
                color: "#2CA3FA",
              }}
              onClick={handleConfirmPatientAppointment}
            >
              Confirm
            </Button>
          )}
          {data.status !== "Cancelled" && (
            <Button
              onClick={handleCancelPatientAppointment}
              style={{
                height: "30px",
                border: "1px solid #F7927E",
                borderRadius: "30px",
                color: "#F7927E",
                background: "#FFF6F2",
              }}
            >
              Cancel
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default DashboardAppointment;