import React, { useState } from "react";
import "./AddPatientModal.css";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  message,
} from "antd";
import { options } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import { Option } from "antd/lib/mentions";
import axios from "axios";
import moment from "moment";
import API_BASE_URL from "../../../../../env";
import { UserAuth } from "../../../../../context_api/ContextApi";

const AddPatientModal = (props) => {
  const {
    addClinicBranchModal,
    setaddClinicBranchModal,
    handleGetAllPatientsForClinic,
    form,
  } = props;
  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");
  const { setSpinner } = UserAuth();

  const validatePhoneNumber = (_, value) => {
    const numericRegex = /^[0-9]+$/;
    if (!value || (numericRegex.test(value) && value.length === 10)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Please enter exactly 10 numeric characters for the phone number."
    );
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter 6-digit zip code!");
  };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  const handleAddPatientToClinic = (values) => {
    setSpinner(true);
    let payload;
    payload = {
      name: values.name,
      username: values.patient_username,
      email: values.email,
      phone_country_code: values.phone_country_code
        ? values.phone_country_code
        : "+91",
      phone_number: values.phone_number,
      address_line_one: values.patient_address,
      address_line_two: values.patient_apartment,
      city: values.patient_city,
      province: values.patient_state,
      country: values.patient_country,
      zip_code: values.patient_zipcode,
      insurance_id: values.patient_insurance_id,
      citizen_id: values.patient_citizen_id,
      blood_group: values.patient_blood_group,
      gender: values.gender,
      branch_id: 0,
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
    };
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    axios
      .post(
        `${API_BASE_URL}/api/v1/clinic/${clinic_id}/patient`,
        payload,
        config
      )
      .then((res) => {
        setaddClinicBranchModal(false);
        form.resetFields();
        handleGetAllPatientsForClinic();
        message.success("Patient added successfully");
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  return (
    <Modal
      width={"58.2293vw"}
      centered
      open={addClinicBranchModal}
      footer={false}
      closable={false}
      destroyOnClose={true}
    >
      <Form
        form={form}
        initialValues={{
          remember: true,
        }}
        onFinish={handleAddPatientToClinic}
        autoComplete="off"
        className="w-100"
      >
        <div style={{ padding: "1.0417vw" }}>
          <Row>
            <span
              style={{
                color: "#3498DB",
                fontSize: "1.25vw",
                fontWeight: 500,
              }}
            >
              Add New Patient
            </span>
          </Row>
          <Row>
            <span
              style={{
                color: "#6F6F6F",
                fontSize: "0.73vw",
                fontWeight: 400,
              }}
            >
              Add information about the patient, including personal details and
              contact address.
            </span>
          </Row>
          {/* Hospital info */}
          {/* <Row style={{ margin: "1.563vw 0 0 0" }}>
            <span
              style={{
                color: "#333333",
                fontSize: "0.834vw",
                fontWeight: 700,
              }}
            >
              Hospital Infomation
            </span>
          </Row> */}
          <Divider />
          {/* {clinicDetails && ( */}
          <Row className="d-flex jc-between" style={{ margin: "1.25vw 0" }}>
            <Col span={8}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Name <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Patient name",
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters long",
                    },
                    {
                      max: 100,
                      message: "Name must be no more than 100 characters long",
                    },
                    {
                      pattern: /^[A-Za-z][A-Za-z\s]*$/,
                      message:
                        "First character should be alphabetical and Name must contain only alphabeticals",
                    },
                    { whitespace: true, message: "No white spaces" },
                  ]}
                >
                  <Input
                    name="name"
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                      color: "black",
                    }}
                    maxLength={101}
                    bordered={false}
                    onKeyPress={(e) => {
                      const charCode = e.which || e.keyCode;
                      const inputValue = e.target.value;
                      if (
                        !(
                          (charCode >= 65 && charCode <= 90) ||
                          (charCode >= 97 && charCode <= 122) ||
                          (charCode === 32 && inputValue.trim() !== "")
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Username <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Form.Item
                  className="w-100"
                  name="patient_username"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Username!",
                    },
                    {
                      min: 4,
                      message: "Please enter minimum 4 characters",
                    },
                    { whitespace: true, message: "No white spaces" },
                    {
                      pattern: /^[a-zA-Z0-9._]+$/,
                      message:
                        "Username can only contain letters, numbers, fullstops, and underscores.",
                    },
                    {
                      max: 100,
                      message:
                        "Username should not be more than 100 characters long",
                    },
                  ]}
                >
                  <Input
                    style={{
                      color: "black",
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                    maxLength={101}
                    onKeyPress={handleUserNameKeyPress}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Gender <span style={{ color: "#FF0000" }}></span>
                </span>
                <Form.Item
                  name="gender"
                  rules={[{ required: true, message: "Please select Gender" }]}
                >
                  <Select
                    name="gender"
                    bordered={false}
                    style={{
                      color: "black",
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="Others">Others</Option>
                  </Select>
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <Row className="d-flex jc-between" style={{ margin: "1.25vw 0" }}>
            <Col span={8}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Email <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please enter email" },
                    {
                      type: "email",
                      message: "Please Enter valid email address",
                    },
                    {
                      max: 320,
                      message:
                        "Email address must be no more than 320 characters long",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                      color: "black",
                    }}
                    bordered={false}
                    maxLength={321}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Phone No. <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Space.Compact
                  className="w-100"
                  style={{
                    width: "16.771vw",
                  }}
                >
                  <Form.Item name="phone_country_code" initialValue="+91">
                    <Select
                      bordered={false}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "2.0834vw",
                        background: "#FFFFFF",
                        border: "1px solid #E3E3E3",
                        margin: "3px 0 0 0",
                      }}
                      options={options}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: "100%" }}
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                      { validator: validatePhoneNumber },
                    ]}
                  >
                    <Input
                      bordered={false}
                      style={{
                        height: "2.0834vw",
                        background: "#FFFFFF",
                        borderTop: "1px solid #E3E3E3",
                        borderRight: "1px solid #E3E3E3",
                        borderBottom: "1px solid #E3E3E3",
                        margin: "3px 0 0 0",
                      }}
                      onKeyPress={(e) => {
                        const charCode = e.which || e.keyCode;
                        if (!(charCode >= 48 && charCode <= 57)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Space.Compact>
              </Row>
            </Col>
            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Blood Group <span style={{ color: "#FF0000" }}></span>
                </span>

                <Form.Item className="w-100" name="blood_group">
                  <Select
                    // name="patient_blood_group"
                    // label="patient_blood_group"
                    bordered={false}
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                      color: "black",
                    }}
                  >
                    <Option value="A+">A+</Option>
                    <Option value="A-">A-</Option>
                    <Option value="B+">B+</Option>
                    <Option value="B-">B-</Option>
                    <Option value="O+">O+</Option>
                    <Option value="O-">O-</Option>
                    <Option value="AAB+">AB+</Option>
                    <Option value="AAB-">AB-</Option>
                  </Select>
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <Row className="d-flex jc-between">
            <Col span={8}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Date of Birth <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Form.Item
                  name="date_of_birth"
                  rules={[
                    {
                      required: true,
                      message: "Please select Date of birth",
                    },
                  ]}
                >
                  <DatePicker
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                      color: "black",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>

          {/* Branch address */}
          <Row style={{ margin: "1.563vw 0 0 0" }}>
            <span
              style={{
                color: "#333333",
                fontSize: "0.834vw",
                fontWeight: 700,
              }}
            >
              Address details
            </span>
          </Row>
          <div className="antdividerhorizontal">
            <Divider />
          </div>
          <Row style={{ margin: "1.25vw 0" }}>
            <Col span={8}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Apartment
                </span>
                <Form.Item
                  name="apartment"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter Apartment",
                  //   },
                  // ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Address
                </span>
                <Form.Item
                  name="address"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter address",
                  //   },
                  // ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Country
                </span>
                <Form.Item
                  name="country"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please enter Country",
                    // },
                    {
                      pattern: /^[A-Za-z][A-Za-z\s]*$/,
                      message:
                        "First character should be alphabetical and Country must contain only alphabeticals",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{ display: "flex", justifyContent: "" }}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  State / Province
                </span>
                <Form.Item
                  name="state"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please enter State / Province",
                    // },
                    {
                      pattern: /^[A-Za-z][A-Za-z\s]*$/,
                      message:
                        "First character should be alphabetical and State/Province must contain only alphabeticals",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  City
                </span>
                <Form.Item
                  name="city"
                  rules={[
                    // { required: true, message: "Please enter City" },
                    {
                      pattern: /^[A-Za-z][A-Za-z\s]*$/,
                      message:
                        "First character should be alphabetical and City must contain only alphabeticals",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  ZIP Code
                </span>
                <Form.Item
                  name="zip_code"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please enter Zip code",
                    // },
                    { validator: validateZipCode },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <Row
            className="d-flex jc-end ai-center"
            style={{ gap: 12, margin: "1.25vw 0 0 0" }}
          >
            <Button
              className="cancel-btn"
              onClick={() => setaddClinicBranchModal(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                height: "1.67vw",
                border: "1px solid #2CA3FA",
                borderRadius: "1.563vw",
                color: "#2CA3FA",
                background: "#FFF",
                fontSize: "0.73vw",
                fontWeight: 600,
              }}
              htmlType="submit"
            >
              Save
            </Button>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default AddPatientModal;
