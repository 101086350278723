import { Checkbox, Col, Dropdown, message, Row } from "antd";
import React from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import AppointmentProfilePic from "../../admin_appointments/AppointmentProfilePic";
import { useLocation, useNavigate } from "react-router";
import { UserAuth } from "../../../../../context_api/ContextApi";
import API_BASE_URL from "../../../../../env";
import axios from "axios";

const ClinicAdminPatientData = (props) => {
  const {
    item,
    checkedDoctorValues,
    setSpinner,
    handleGetAllPatientsForClinic,
    setcheckedDoctorValues,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const key = location.pathname;
  const newkey = key.split("/")[1];
  const { setTabActiveKey, setAdminTabActiveKey } = UserAuth();
  const Token = localStorage.getItem("Token");

  const handleNameNavigation = () => {
    if (newkey === "clinic-user") {
      navigate(`/clinic-user/patient-profile/${item.user_id}`);
      setTabActiveKey("");
    } else {
      navigate(`/clinic-admin/patient-profile/${item.user_id}`);
      setAdminTabActiveKey("");
    }
  };

  const handleDeletePatient = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .delete(
        `${API_BASE_URL}/api/v1/patient?patient_id=${
          checkedDoctorValues ? checkedDoctorValues : item.user_id
        }`,
        config
      )
      .then((res) => {
        message.success(res.data);
        handleGetAllPatientsForClinic();
        setcheckedDoctorValues();
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const items = [
    {
      label: <span onClick={handleNameNavigation}>View Profile</span>,
      key: "0",
    },
    {
      label: <span>Sent Reminder</span>,
      key: "1",
    },
    {
      label: <span>Chat Now</span>,
      key: "2",
    },
    {
      label: <span onClick={handleDeletePatient}>Delete</span>,
      key: "3",
    },
  ];

  return (
    <div
      style={{
        borderBottom: "1px solid #DDD",
      }}
    >
      <div>
        <Row
          className={
            checkedDoctorValues && checkedDoctorValues.includes(item.user_id)
              ? "checked-doctor-details-row"
              : "doctor-details-row"
          }
        >
          <Row
            className="d-flex w-100 ai-center"
            style={{
              padding: "21px 60px",
            }}
          >
            <Col span={1}>
              <Checkbox value={item.user_id} className="custom-checkbox" />
            </Col>
            <Col span={5}>
              <Row>
                <Col>
                  <AppointmentProfilePic
                    pro={
                      item &&
                      item.profile_picture_url &&
                      item.profile_picture_url.split(".com/")[1]
                    }
                  />
                </Col>
                <Col
                  span={15}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    margin: "0 0 0 10px",
                  }}
                >
                  <Row>
                    <span
                      style={{
                        color: "#3498DB",
                        fontSize: 16,
                        fontWeight: 600,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        cursor: "pointer",
                      }}
                      onClick={handleNameNavigation}
                    >
                      {item.name}
                    </span>
                  </Row>
                  <Row>
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "0.73vw",
                        color: "#000000",
                      }}
                    >
                      SID #
                      <span
                        style={{
                          color: "#7D7D7D",
                        }}
                      >
                        {item.user_id}
                      </span>
                    </span>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <span className="patient-list-table-data">{item.email}</span>
            </Col>
            <Col span={4}>
              <span className="patient-list-table-data">
                {item.phone_number}
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    margin: "0 0 0 10px",
                  }}
                >
                  <Row>
                    <span
                      style={{
                        color: "#333",
                        fontSize: 16,
                        fontWeight: 600,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        cursor: "pointer",
                      }}
                    >
                      {item.last_visited_appointment_response &&
                      item.last_visited_appointment_response.from_date
                        ? moment(
                            item.last_visited_appointment_response.from_date
                          ).format("MMMM Do YYYY")
                        : "NA"}
                    </span>
                  </Row>
                  <Row>
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "0.73vw",
                        color: "#7D7D7D",
                      }}
                    >
                      {item.last_visited_appointment_response &&
                      item.last_visited_appointment_response.treatment_type
                        .treatment_name
                        ? item.last_visited_appointment_response.treatment_type
                            .treatment_name
                        : "NA"}
                    </span>
                  </Row>
                </Row>
              </span>
            </Col>
            <Col span={4} style={{ display: "flex", alignItems: "center" }}>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "0 0 0 10px",
                }}
              >
                <Row>
                  <span
                    style={{
                      color: "#3498DB",
                      fontSize: 16,
                      fontWeight: 600,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                    }}
                  >
                    {item.next_visited_appointment_response &&
                    item.next_visited_appointment_response.from_date
                      ? moment(
                          item.next_visited_appointment_response.from_date
                        ).format("MMMM Do YYYY")
                      : "NA"}
                  </span>
                </Row>
                <Row>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "0.73vw",
                      color: "#7D7D7D",
                    }}
                  >
                    {item.next_visited_appointment_response &&
                    item.next_visited_appointment_response.treatment_type
                      .treatment_name
                      ? item.next_visited_appointment_response.treatment_type
                          .treatment_name
                      : "NA"}
                  </span>
                </Row>
              </Row>
            </Col>
            <Col
              span={2}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <EllipsisOutlined
                  rotate={90}
                  style={{ fontSize: "1.25vw", cursor: "pointer" }}
                />
              </Dropdown>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default ClinicAdminPatientData;
