import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { options } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import { Option } from "antd/lib/mentions";
import axios from "axios";
import moment from "moment";
import API_BASE_URL from "../../../../../env";
import { UserAuth } from "../../../../../context_api/ContextApi";
import { yearsMap } from "../../../../../utils/clinic_admin_constants/ClinicAdminConstants";
import { LoadingOutlined } from "@ant-design/icons";

const AddNewStaffModal = (props) => {
  const {
    addClinicStaffModal,
    setaddClinicStaffModal,
    form,
    handleGetAllAdminDetails,
  } = props;
  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");
  const branch_id = localStorage.getItem("main_branch_id");
  const { setSpinner, spinner } = UserAuth();

  const validatePhoneNumber = (_, value) => {
    const numericRegex = /^[0-9]+$/;
    if (!value || (numericRegex.test(value) && value.length === 10)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Please enter exactly 10 numeric characters for the phone number."
    );
  };

  // const validateZipCode = (_, value) => {
  //   const phoneRegex = /^\d{6}$/;
  //   if (!value || phoneRegex.test(value)) {
  //     return Promise.resolve();
  //   }
  //   return Promise.reject("Please enter 6-digit zip code!");
  // };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  const handleAddStaffToClinic = async (values) => {
    setSpinner(true);
    const payload = {
      name: values.name,
      username: values.username,
      email: values.email,
      phone_country_code: values.phone_country_code
        ? values.phone_country_code
        : "+91",
      phone_number: values.phone_number,
      gender: values.gender,
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
      qualification: values.qualification,
      total_experience: {
        years: values.years,
        months: values.months,
      },
      designation: values.designation,
      access_type: values.access_type,
      branch_id: branch_id,
      specialization: JSON.stringify(values.specialization),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .post(`${API_BASE_URL}/api/v1/adminStaff/${clinic_id}`, payload, config)
      .then((res) => {
        setaddClinicStaffModal(false);
        form.resetFields();
        handleGetAllAdminDetails();
        setSpinner(false);
      })
      .catch((err) => {
        message.warning(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const [designationValue, setdesignationValue] = useState();
  const onChange = (checkedValues) => {
    setdesignationValue(checkedValues);
  };

  const disabledDate = (current) => {
    return current && current > moment().startOf("day");
  };

  return (
    <Spin
      indicator={
        <LoadingOutlined
          style={{ fontSize: "2.6045vw", margin: "14.588vw 0 0 0" }}
          spin
        />
      }
      size="large"
      spinning={spinner && true}
      delay={500}
    >
      <Modal
        width={"55.2293vw"}
        centered
        open={addClinicStaffModal}
        footer={false}
        closable={false}
        destroyOnClose
      >
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          onFinish={handleAddStaffToClinic}
          autoComplete="off"
          className="w-100"
        >
          <div style={{ padding: "1.0417vw" }}>
            <Row>
              <span
                style={{
                  color: "#3498DB",
                  fontSize: "1.25vw",
                  fontWeight: 500,
                }}
              >
                Add New Staff
              </span>
            </Row>
            <Row>
              <span
                style={{
                  color: "#6F6F6F",
                  fontSize: "0.73vw",
                  fontWeight: 400,
                }}
              >
                Add information about the staff, including personal and
                professional details.
              </span>
            </Row>
            <Row className="d-flex jc-between" style={{ margin: "1.25vw 0" }}>
              <Col span={8}>
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Name <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Patient name",
                      },
                      {
                        min: 3,
                        message: "Name must be at least 3 characters long",
                      },
                      {
                        max: 100,
                        message:
                          "Name must be no more than 100 characters long",
                      },
                      {
                        pattern: /^[A-Za-z][A-Za-z\s]*$/,
                        message:
                          "First character should be alphabetical and Name must contain only alphabeticals",
                      },
                      { whitespace: true, message: "No white spaces" },
                    ]}
                  >
                    <Input
                      name="name"
                      style={{
                        width: "14.583333333333334vw",
                        margin: "5px 0 0 0",
                        height: "2.0834vw",
                        borderRadius: 3,
                        border: "1px solid #DADADA",
                        color: "black",
                      }}
                      maxLength={101}
                      bordered={false}
                      onKeyPress={(e) => {
                        const charCode = e.which || e.keyCode;
                        const inputValue = e.target.value;
                        if (
                          !(
                            (charCode >= 65 && charCode <= 90) ||
                            (charCode >= 97 && charCode <= 122) ||
                            (charCode === 32 && inputValue.trim() !== "")
                          )
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Row>
              </Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Username <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  <Form.Item
                    className="w-100"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Username!",
                      },
                      {
                        min: 4,
                        message: "Please enter minimum 4 characters",
                      },
                      { whitespace: true, message: "No white spaces" },
                      {
                        pattern: /^[a-zA-Z0-9._]+$/,
                        message:
                          "Username can only contain letters, numbers, fullstops, and underscores.",
                      },
                      {
                        max: 100,
                        message:
                          "Username should not be more than 100 characters long",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        color: "black",
                        width: "14.583333333333334vw",
                        margin: "5px 0 0 0",
                        height: "2.0834vw",
                        borderRadius: 3,
                        border: "1px solid #DADADA",
                      }}
                      maxLength={101}
                      onKeyPress={handleUserNameKeyPress}
                    />
                  </Form.Item>
                </Row>
              </Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Gender <span style={{ color: "#FF0000" }}></span>
                  </span>
                  <Form.Item
                    name="gender"
                    rules={[
                      { required: true, message: "Please select Gender" },
                    ]}
                  >
                    <Select
                      name="gender"
                      bordered={false}
                      style={{
                        color: "black",
                        width: "14.583333333333334vw",
                        margin: "5px 0 0 0",
                        height: "2.0834vw",
                        borderRadius: 3,
                        border: "1px solid #DADADA",
                      }}
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                      <Option value="Others">Others</Option>
                    </Select>
                  </Form.Item>
                </Row>
              </Col>
            </Row>
            <Row className="d-flex jc-between" style={{ margin: "1.25vw 0" }}>
              <Col span={8}>
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Email <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please enter email" },
                      {
                        type: "email",
                        message: "Please Enter valid email address",
                      },
                      {
                        max: 320,
                        message:
                          "Email address must be no more than 320 characters long",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "14.583333333333334vw",
                        margin: "5px 0 0 0",
                        height: "2.0834vw",
                        borderRadius: 3,
                        border: "1px solid #DADADA",
                        color: "black",
                      }}
                      bordered={false}
                      maxLength={321}
                    />
                  </Form.Item>
                </Row>
              </Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Phone No. <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  <Space.Compact
                    className="w-100"
                    style={{
                      width: "14.583333333333334vw",
                    }}
                  >
                    <Form.Item name="phone_country_code" initialValue="+91">
                      <Select
                        bordered={false}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "2.0834vw",
                          background: "#FFFFFF",
                          border: "1px solid #E3E3E3",
                          margin: "3px 0 0 0",
                        }}
                        options={options}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: "100%" }}
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number",
                        },
                        { validator: validatePhoneNumber },
                      ]}
                    >
                      <Input
                        bordered={false}
                        style={{
                          height: "2.0834vw",
                          background: "#FFFFFF",
                          borderTop: "1px solid #E3E3E3",
                          borderRight: "1px solid #E3E3E3",
                          borderBottom: "1px solid #E3E3E3",
                          margin: "3px 0 0 0",
                        }}
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          if (!(charCode >= 48 && charCode <= 57)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Space.Compact>
                </Row>
              </Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Date of Birth <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  <Form.Item
                    name="date_of_birth"
                    rules={[
                      {
                        required: true,
                        message: "Please select Date of birth",
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledDate}
                      style={{
                        width: "14.583333333333334vw",
                        margin: "5px 0 0 0",
                        height: "2.0834vw",
                        borderRadius: 3,
                        border: "1px solid #DADADA",
                        color: "black",
                      }}
                    />
                  </Form.Item>
                </Row>
              </Col>
            </Row>
            <Row className="d-flex jc-between">
              <Col span={8}>
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Access <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  <Form.Item
                    className="w-100"
                    name="access_type"
                    rules={[
                      { required: true, message: "Please select access" },
                    ]}
                  >
                    <Select
                      placeholder="Select Access"
                      bordered={false}
                      style={{
                        width: "14.583333333333334vw",
                        margin: "5px 0 0 0",
                        height: "2.0834vw",
                        borderRadius: 3,
                        border: "1px solid #DADADA",
                        color: "black",
                      }}
                    >
                      <Option value="Admin">Admin</Option>
                      <Option value="Can Edit">Staff</Option>
                    </Select>
                  </Form.Item>
                </Row>
              </Col>
            </Row>

            <Row style={{ margin: "1.563vw 0 0 0" }}>
              <span
                style={{
                  color: "#333333",
                  fontSize: "0.834vw",
                  fontWeight: 700,
                }}
              >
                Professional Details
              </span>
            </Row>
            <div className="antdividerhorizontal">
              <Divider />
            </div>
            <Row style={{ margin: "1.25vw 0" }}>
              <Col span={8}>
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Designation <span style={{ color: "#FF0000" }}>*</span>
                  </span>

                  <Form.Item
                    className="w-100"
                    name="designation"
                    rules={[
                      { required: true, message: "Please select designation" },
                    ]}
                  >
                    <Select
                      placeholder="Select Designation"
                      onChange={onChange}
                      allowClear
                      bordered={false}
                      style={{
                        width: "14.583333333333334vw",
                        margin: "5px 0 0 0",
                        height: "2.0834vw",
                        borderRadius: 3,
                        border: "1px solid #DADADA",
                        color: "black",
                      }}
                    >
                      <Option value="Dentist">Dentist</Option>
                      <Option value="DentalAssistant">Dental Assistant</Option>
                      <Option value="DentalHygienist">Dental Hygienist</Option>
                      <Option value="Receptionist">Receptionist</Option>
                    </Select>
                  </Form.Item>
                </Row>
              </Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Qualification
                  </span>
                  <Form.Item name="qualification">
                    <Input
                      placeholder="E.g: BDS, MDS"
                      style={{
                        width: "14.583333333333334vw",
                        margin: "5px 0 0 0",
                        height: "2.0834vw",
                        borderRadius: 3,
                        border: "1px solid #DADADA",
                        color: "black",
                      }}
                      bordered={false}
                    />
                  </Form.Item>
                </Row>
              </Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Total Experience
                  </span>
                  <Row
                    className="w-100 d-flex jc-end"
                    style={{ gap: 16, margin: "5px 0 0 0" }}
                  >
                    <Col>
                      <Form.Item name="years">
                        <Select
                          placeholder="0 Years"
                          bordered={false}
                          allowClear
                          style={{
                            fontWeight: "400",
                            width: "6.875vw",
                            fontSize: "16px",
                            color: "#525252",
                            height: "40px",
                            borderRadius: "3px",
                            border: "1px solid #E3E3E3",
                          }}
                        >
                          {yearsMap.map((item) => {
                            return (
                              <Option value={item.year}>{item.year}</Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name="months">
                        <Select
                          placeholder="0 Months"
                          allowClear
                          bordered={false}
                          style={{
                            width: "6.875vw",
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#525252",
                            height: "40px",
                            borderRadius: "3px",
                            border: "1px solid #E3E3E3",
                          }}
                        >
                          <Option value="1">0</Option>
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                          <Option value="6">6</Option>
                          <Option value="7">7</Option>
                          <Option value="8">8</Option>
                          <Option value="9">9</Option>
                          <Option value="10">10</Option>
                          <Option value="11">11</Option>
                          <Option value="12">12</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
            <Row style={{ margin: "1.25vw 0" }} className="d-flex flex-col">
              <span
                className="w-100"
                style={{
                  fontSize: "0.73vw",
                  fontWeight: 400,
                  color: "#2F2F2F",
                }}
              >
                Specialisation <span style={{ color: "#FF0000" }}></span>
              </span>
              <Form.Item className="w-100" name="specialization">
                <Checkbox.Group
                  disabled={!designationValue}
                  className="w-100 specialisation-checkbox"
                >
                  <Row className="w-100">
                    <Col span={6} style={{ padding: "10px 0" }}>
                      <Checkbox
                        value="General Dentistry"
                        style={{ display: "flex", alignItems: "" }}
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSsize: 16,
                            fontWeight: 500,
                            padding: "0 0 0 12px",
                          }}
                        >
                          General Dentistry
                        </span>
                      </Checkbox>
                    </Col>
                    <Col span={6} style={{ padding: "10px 0" }}>
                      <Checkbox
                        value="Surgeon"
                        style={{ display: "flex", alignItems: "" }}
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSsize: 16,
                            fontWeight: 500,
                            padding: "0 0 0 12px",
                          }}
                        >
                          Surgeon
                        </span>
                      </Checkbox>
                    </Col>
                    <Col span={6} style={{ padding: "10px 0" }}>
                      <Checkbox
                        value="Pediatric Dentistry"
                        style={{ display: "flex", alignItems: "" }}
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSsize: 16,
                            fontWeight: 500,
                            padding: "0 0 0 12px",
                          }}
                        >
                          Pediatric Dentistry
                        </span>
                      </Checkbox>
                    </Col>
                    <Col span={6} style={{ padding: "10px 0" }}>
                      <Checkbox
                        value="Ortho dentistry"
                        style={{ display: "flex", alignItems: "" }}
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSsize: 16,
                            fontWeight: 500,
                            padding: "0 0 0 12px",
                          }}
                        >
                          Ortho dentistry
                        </span>
                      </Checkbox>
                    </Col>
                    <Col span={6} style={{ padding: "10px 0" }}>
                      <Checkbox
                        value="Periodontics"
                        style={{ display: "flex", alignItems: "" }}
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSsize: 16,
                            fontWeight: 500,
                            padding: "0 0 0 12px",
                          }}
                        >
                          Periodontics
                        </span>
                      </Checkbox>
                    </Col>
                    <Col span={6} style={{ padding: "10px 0" }}></Col>
                    <Col span={6} style={{ padding: "10px 0" }}></Col>
                    <Col span={6} style={{ padding: "10px 0" }}></Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Row>

            <Row
              className="d-flex jc-end ai-center"
              style={{ gap: 12, margin: "1.25vw 0 0 0" }}
            >
              <Button
                className="cancel-btn"
                onClick={() => setaddClinicStaffModal(false)}
              >
                Cancel
              </Button>
              <Button
                style={{
                  height: "1.67vw",
                  border: "1px solid #2CA3FA",
                  borderRadius: "1.563vw",
                  color: "#2CA3FA",
                  background: "#FFF",
                  fontSize: "0.73vw",
                  fontWeight: 600,
                }}
                htmlType="submit"
              >
                Save
              </Button>
            </Row>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
};

export default AddNewStaffModal;
