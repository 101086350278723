import {
  Button,
  Calendar,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  TimePicker,
  message,
  notification,
  DatePicker,
  Badge,
  Radio,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./ClinicMyAccount.css";
import ssdental from "../../../../assets/ssdental.png";
import eventadded from "../../../../assets/eventadded.png";
import clinicopenimg from "../../../../assets/clinic-operating-hours.png";
import warningIcon from "../../../../assets/warning-line.png";
import timerIcon from "../../../../assets/timer-icon.png";
import addTimerIcon from "../../../../assets/add-timer-icon.png";
import deleteIcon from "../../../../assets/delete-icon.png";
import deleteicon from "../../../../assets/deleteicon.png";
import calendardeleteicon from "../../../../assets/delete-icon.png";
import successLogo from "../../../../assets/success.png";
import rejectLogo from "../../../../assets/reject-logo.png";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import API_BASE_URL from "../../../../env";
import {
  CloseCircleFilled,
  LoadingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ClinicBranchModal from "./clinic_branch_modals/ClinicBranchModal";
import ClinicTreatmentsModal from "./clinic_branch_modals/ClinicTreatmentsModal";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../string";
import { options } from "../../../../utils/super_admin_constants/SuperAdminConsts";
import ClinicDoctors from "./ClinicDoctors";
import ClinicBranches from "./clinic_branch_modals/ClinicBranches";
import StaffProfilePicture from "../../modules/clinic_admin_dashboard/StaffProfilePicture";
import ClinicTreatments from "./ClinicTreatments";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

export const staffOptions = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "doctor",
    label: "Doctor",
  },
  {
    value: "staff",
    label: "Staff",
  },
];

export const dateOptions = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "today",
    label: "Today",
  },
  {
    value: "customDate",
    label: "Custom Date",
  },
];

const ClinicMyAccount = () => {
  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");
  const branch_id = localStorage.getItem("main_branch_id")
    ? localStorage.getItem("main_branch_id")
    : localStorage.getItem("branch_id");
  const admin_id = localStorage.getItem("user_id");
  const [form] = Form.useForm();
  const [spinner, setSpinner] = useState(false);
  const [clinicDetails, setClinicDetails] = useState();
  const [clinicBranches, setClinicBranches] = useState();
  const [addClinicBranchModal, setaddClinicBranchModal] = useState(false);
  const [editClinicModal, setEditClinicModal] = useState(false);
  const [addTreatmentModal, setaddTreatmentModal] = useState(false);
  const [clinicImageurl, setClinicImageUrl] = useState();
  const [enableForm, setEnableForm] = useState(true);
  const role = localStorage.getItem("role");
  const [treatmentTypes, setTreatmentTypes] = useState();
  const [fileContent, setFileContent] = useState("");
  const [sampleDoctorData, setSampleDoctorData] = useState(false);
  const [checkedDoctorValues, setcheckedDoctorValues] = useState(false);
  const [currentDate, setCurrentDate] = useState({
    year: moment().format("yyyy"),
    month: moment().format("MM"),
  });
  const [checkedSlotValues, setCheckedSlotValues] = useState();
  const [timeRange, setTimeRange] = useState([]);
  const [clinicOpenHours, setClinicOpenHours] = useState();
  const [holiday, setHoliday] = useState(false);
  const [isSetOpenHoursClicked, setOpenHourButtonState] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [calendarEvents, setcalendarEvents] = useState();
  const [selectedInputValue, setselectedInputValue] = useState();

  const [defaultValue, setdefaultValue] = useState({
    description: "string",
    eventDate: "",
    eventName: "",
    year: 0,
    month: 0,
  });
  const format = "hh:mm A";
  const [cancelButton, setcancelButton] = useState(true);
  const validRange = [moment("2024-01-01"), moment("2025-12-31")];
  const [displayDate, setDisplayDate] = useState(moment());
  const [api, contextHolder] = notification.useNotification();
  const [value, setValue] = useState();
  const [selectedDates, setSelectedDates] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isUpdateClinicHours, setUpdateClinicHours] = useState(false);
  let newSelectedDates = [];
  const [clinicOpenTime, setClinicOpenTime] = useState("");
  const [clinicCloseTime, setClinicCloseTime] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [activeTabKey, setActiveTabKey] = useState("by Week");
  const [timeOffReqData, setTimeOffReqData] = useState([]);
  const [timeOffReqItem, setTimeOffReqItem] = useState([]);
  const [isApproveRequestModal, setApprovedRequestModal] = useState(false);
  const [isRejectReasonModal, setRejectReasonModal] = useState(false);
  const [isRejectRequestModal, setRejectRequestModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("Staff Shortage");
  const [params, setParams] = useState({});
  const [isDateSelectModal, setDateSelectModal] = useState(false);

  const onChange = (key) => {};

  const onDoctorChange = (checkedValues) => {
    setcheckedDoctorValues(checkedValues);
  };

  const handleAddClinicBranch = async (values) => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    const payload = {
      clinic_id: clinicDetails.clinic_id,
      branch_name: clinicDetails.name,
      address_line_one: values.apartment,
      address_line_two: values.address,
      phone_number: values.phone_number,
      city: values.city,
      province: values.state,
      country: values.country,
      zip_code: values.clinic_branch_zipcode,
      profile_picture_url: clinicImageurl,
      email: clinicDetails.email,
      phone_country_code: values.phone_country_code,
    };
    await axios
      .post(
        `${API_BASE_URL}/api/v1/clinicBranch/createClinicBranch`,
        payload,
        config
      )
      .then((res) => {
        setSpinner(false);
        message.success("Clinic Branch Added Successfully");
        form.resetFields();
        handleGetClinicDetails();
        handleGetAllclinicBranches();
        setaddClinicBranchModal(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
        handleGetClinicDetails();
        handleGetAllclinicBranches();
      });
  };

  const handleDeleteClinicBranch = async (branchid) => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .delete(
        `${API_BASE_URL}/api/v1/clinicBranch/deleteBranchDetails/${branchid}`,
        config
      )
      .then((res) => {
        message.success("Branch Deleted successfully");
        handleGetClinicDetails();
        handleGetAllclinicBranches();
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const handleAddNewTreatmentType = async (values) => {
    const payload = {
      clinic_id: clinic_id,
      treatment_name: values.treatment_name,
      description: values.description,
      status: "ACTIVE",
      rate: values.cost,
      duration: values.duration,
      treatment_type: values.treatment_type,
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .post(`${API_BASE_URL}/api/v1/treatments/treatment`, payload, config)
      .then((res) => {
        message.success("Treatment Type Added Successfully");
        handleGetTreatmentsTypes();
        setaddTreatmentModal(false);
        form.resetFields();
      })
      .catch((err) => {
        handleGetTreatmentsTypes();
        message.error(err.response.data.data.errors);
      });
  };

  const handleDeleteTreatmentType = async (id) => {
    const payload = {};
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .put(
        `${API_BASE_URL}/api/v1/treatments/update-treatment-status?id=${id}&status=INACTIVE`,
        payload,
        config
      )
      .then((res) => {
        message.success("Treatment Type Deleted Successfully");
        handleGetTreatmentsTypes();
      })
      .catch((err) => {
        handleGetTreatmentsTypes();
        message.error(err.response.data.data.errors);
      });
  };

  const clinicProPic =
    clinicDetails &&
    clinicDetails.profile_picture_url &&
    clinicDetails.profile_picture_url.split(".com/")[1];

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: clinicProPic,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [clinicProPic]);

  const handleGetClinicDetails = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/clinic/${clinic_id}/${branch_id}`, config)
      .then((res) => {
        setClinicDetails(res.data.data);
        setClinicImageUrl(
          res.data.data.profile_picture_url && res.data.data.profile_picture_url
        );
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const handleGetAllclinicBranches = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/clinicBranch/getAllBranchDetails/${clinic_id}`,
        config
      )
      .then((res) => {
        setClinicBranches(res.data.data);
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const handleGetTreatmentsTypes = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/treatments?clinic_id=${clinic_id}`, config)
      .then((res) => {
        setTreatmentTypes(res.data.data);
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const handleGetAllDoctorinClinic = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (branch_id) {
      await axios
        .get(
          `${API_BASE_URL}/api/v1/slots/clinic/${clinic_id}?branch_id=${branch_id}`,
          config
        )
        .then((res) => {
          setSampleDoctorData(res.data.data);
          setSpinner(false);
        });
    } else {
      await axios
        .get(`${API_BASE_URL}/api/v1/slots/clinic/${clinic_id}`, config)
        .then((res) => {
          setSampleDoctorData(res.data.data);
          setSpinner(false);
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
        });
    }
  };

  const today = moment();
  const dateTabs = [];

  for (let i = 0; i < 7; i++) {
    const date = today.clone().add(i, "days");
    dateTabs.push({
      date: date.format("YYYY-MM-DD"),
      label: date.format("dddd"),
    });
  }

  const handleDeleteSlots = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .delete(
        `${API_BASE_URL}/api/v1/slots/{doctorIds}?doctorIds=${checkedDoctorValues}`,
        config
      )
      .then((res) => {
        // handleGetAllSurveyDetails();
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const handleSelectSlotDay = (values) => {
    setCheckedSlotValues(values);
  };

  const handleTimeChange = (value) => {
    setTimeRange([startTime, endTime]);
  };

  const handleAddClinicOpenHours = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    if (checkedSlotValues?.length === 0 && selectedDates?.length === 0) {
      message.warning("Please Select days or dates to continue");
    } else if (!holiday && startTime === null && endTime === null) {
      message.warning("Please Select timings continue");
    }

    if (activeTabKey === "by Week" && checkedSlotValues?.length > 0) {
      let payload = [];

      for (let i = 0; i < checkedSlotValues?.length; i++) {
        const day = isUpdateClinicHours
          ? checkedSlotValues[i]
          : moment(checkedSlotValues[i]).format("dddd");
        const fromTime = startTime?.split("T")[1];
        const toTime = endTime?.split("T")[1];

        let payloadObj;

        if (holiday === true) {
          payloadObj = {
            day: day,
            from_time: "",
            to_time: "",
            is_holiday: false,
            clinic_id: clinic_id,
            branch_id: branch_id,
          };
        } else {
          payloadObj = {
            day: day,
            from_time: fromTime,
            to_time: toTime,
            is_holiday: true,
            clinic_id: clinic_id,
            branch_id: branch_id,
          };
        }

        payload.push(payloadObj);
      }

      if (
        (isUpdateClinicHours && startTime !== null && endTime !== null) ||
        (isUpdateClinicHours && holiday)
      ) {
        await axios
          .put(
            `${API_BASE_URL}/api/v1/clinic/hours/updateHospitalOpenHours`,
            payload,
            config
          )
          .then((res) => {
            handleGetClinicOpenHours();
            message.success("Updated Successfully");
            setSpinner(false);
            form.resetFields();
            setCheckedSlotValues([]);
            setHoliday(false);
            setOpenHourButtonState(false);
            newSelectedDates = [];
            setSelectedDates([]);
            setUpdateClinicHours(false);
            setStartTime(null);
            setEndTime(null);
          })
          .catch((err) => {
            message.error(
              err.response.data.data.errors[0] !== null
                ? err.response.data.data.errors[0]
                : "Open hours already exist for the specified day(s)"
            );
            handleGetClinicOpenHours();
            setSpinner(false);
          });
      } else if (
        (!isUpdateClinicHours && holiday) ||
        (startTime !== null && endTime !== null)
      ) {
        await axios
          .post(
            `${API_BASE_URL}/api/v1/clinic/hours/createHospitalOpenHours`,
            payload,
            config
          )
          .then((res) => {
            handleGetClinicOpenHours();
            message.success("Added Successfully");
            setSpinner(false);
            form.resetFields();
            setCheckedSlotValues([]);
            setHoliday(false);
            setOpenHourButtonState(false);
            newSelectedDates = [];
            setSelectedDates([]);
            setStartTime(null);
            setEndTime(null);
          })
          .catch((err) => {
            message.error(err.response.data.data.errors);
            handleGetClinicOpenHours();
            setSpinner(false);
            form.resetFields();
            setCheckedSlotValues([]);
          });
      }
    } else if (activeTabKey === "by Date" && selectedDates?.length > 0) {
      const fromDate = moment(selectedDates[0]).format("YYYY-MM-DD");
      const toDate = moment(selectedDates[selectedDates.length - 1]).format(
        "YYYY-MM-DD"
      );

      let payload;
      if (holiday === true) {
        payload = {
          from_time: fromDate + "T00:00:00",
          to_time: toDate + "T00:00:00",
          is_holiday: false,
          clinic_id: clinic_id,
          branch_id: branch_id,
        };
      } else {
        payload = {
          from_time: fromDate + startTime,
          to_time: toDate + endTime,
          is_holiday: true,
          clinic_id: clinic_id,
          branch_id: branch_id,
        };
      }
      if (
        (isUpdateClinicHours && startTime !== null && endTime !== null) ||
        (isUpdateClinicHours && holiday)
      ) {
        await axios
          .put(
            `${API_BASE_URL}/api/v1/clinic/hours/updateHospitalHours?clinicId=${clinic_id}&fromDate=${clinicOpenTime}&toDate=${clinicCloseTime}`,
            payload,
            config
          )
          .then((res) => {
            handleGetClinicOpenHours();
            message.success("Updated Successfully");
            setSpinner(false);
            form.resetFields();
            setCheckedSlotValues([]);
            setHoliday(false);
            setOpenHourButtonState(false);
            newSelectedDates = [];
            setSelectedDates([]);
            setUpdateClinicHours(false);
            setStartTime(null);
            setEndTime(null);
            setActiveTabKey("by Week");
          })
          .catch((err) => {
            message.error(err.response.data.data.errors);
            handleGetClinicOpenHours();
            setSpinner(false);
          });
      } else if (
        (!isUpdateClinicHours && holiday) ||
        (startTime !== null && endTime !== null)
      ) {
        const start_time = startTime !== null ? startTime : "T00:00:00";
        const end_time = endTime !== null ? endTime : "T00:00:00";

        await axios
          .post(
            `${API_BASE_URL}/api/v1/clinic/hours/createHospitalHours?fromDate=${
              fromDate + start_time
            }&toDate=${toDate + end_time}`,
            payload,
            config
          )
          .then((res) => {
            handleGetClinicOpenHours();
            message.success("Added Successfully");
            setSpinner(false);
            form.resetFields();
            setCheckedSlotValues([]);
            setHoliday(false);
            setOpenHourButtonState(false);
            newSelectedDates = [];
            setSelectedDates([]);
            setActiveTabKey("by Week");
            setStartTime(null);
            setEndTime(null);
          })
          .catch((err) => {
            message.error(err.response.data.data.errors);
            handleGetClinicOpenHours();
            setSpinner(false);
          });
      }
    }

    // for (i = 0; i < checkedSlotValues.length; i++) {
    //   setSpinner(true);
    //   let payload;
    //   if (holiday === true) {
    //     payload = {
    //       from_time: "",
    //       to_time: "",
    //       is_holiday: false,
    //       clinic_id: clinic_id,
    //       branch_id: 6,
    //     };
    //   } else {
    //     payload = {
    //       from_time: checkedSlotValues[i] + startTime,
    //       to_time: checkedSlotValues[i] + endTime,
    //       is_holiday: true,
    //       clinic_id: clinic_id,
    //       branch_id: 6,
    //     };
    //   }
    //   await axios
    //     .post(
    //       `${API_BASE_URL}/api/v1/clinic/hours/createHospitalHours?fromDate=${checkedSlotValues[i] + startTime}&toDate=${checkedSlotValues[i] + endTime}`,
    //       payload,
    //       config
    //     )
    //     .then((res) => {
    //       handleGetClinicOpenHours();
    //       message.success("Added Successfully");
    //       setSpinner(false);
    //       form.resetFields();
    //       setCheckedSlotValues([]);
    //       setHoliday(false);
    //       setOpenHourButtonState(false);
    //     })
    //     .catch((err) => {
    //       message.error("Adding clinic open hours was failed");
    //       handleGetClinicOpenHours();
    //       setSpinner(false);
    //     });
    // }
  };

  const handleStartTimeChange = (val) => {
    setStartTime(val?.format("THH:mm:ss"));
  };

  const handleEndTimeChange = (val) => {
    setEndTime(val?.format("THH:mm:ss"));
  };

  const handleDateSelect = (date) => {
    let newSelectedDates = [...selectedDates];

    const index = newSelectedDates.findIndex((d) => d.isSame(date, "day"));

    if (index === -1) {
      if (newSelectedDates.length < 1) {
        newSelectedDates.push(date);
      } else {
        const startDate = newSelectedDates[0];
        newSelectedDates = [];

        if (date.isBefore(startDate, "day")) {
          const datesInRange = getDatesInRange(date, startDate);
          newSelectedDates = [...newSelectedDates, ...datesInRange];
        } else {
          const datesInRange = getDatesInRange(startDate, date);
          newSelectedDates = [...newSelectedDates, ...datesInRange];
        }
      }
    } else {
      const startDate = newSelectedDates[0];
      const endDate = newSelectedDates[newSelectedDates.length - 1];
      newSelectedDates = [];

      if (date.isBefore(endDate, "day")) {
        const datesInRange = getDatesInRange(startDate, date);
        newSelectedDates = [...newSelectedDates, ...datesInRange];
      } else {
        newSelectedDates.splice(index, 1);
      }
    }

    setSelectedDates(newSelectedDates);
  };

  const getDatesInRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = startDate;
    const endDateCopy = endDate.clone();

    while (currentDate.isSameOrBefore(endDateCopy, "day")) {
      dates.push(currentDate.clone());
      currentDate.add(1, "day");
    }

    return dates;
  };

  const isDateSelected = (date) => {
    return selectedDates.some((d) => d.isSame(date, "day"));
  };

  const onOpenHoursPanelChange = (date, mode) => {
    setSelectedDates([]);
  };

  const handleClinicOpenHours = (value) => {
    if (value.day) {
      setActiveTabKey("by Week");
      setCheckedSlotValues([value.day]);
    } else {
      setActiveTabKey("by Date");
    }
    setUpdateClinicHours(true);

    const fromDate = value.from_time.split("T")[0];
    const toDate = value.to_time.split("T")[0];

    setClinicOpenTime(value.from_time);
    setClinicCloseTime(value.to_time);

    setFromTime(moment(value.from_time).format(format));
    setToTime(moment(value.to_time).format(format));

    const datesInRange = getDatesInRange(moment(fromDate), moment(toDate));
    newSelectedDates = [...newSelectedDates, ...datesInRange];

    setSelectedDates(newSelectedDates);
  };

  const handleTabChange = (activeKey) => {
    setActiveTabKey(activeKey);
    form.resetFields();
  };

  const handleClinicOpenHoursDiv = () => {
    return (
      <div
        className="set-open-hours-col"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1.563vw",
        }}
      >
        <Row>
          <span
            style={{
              fontSize: "1.0417vw",
              fontWeight: 600,
            }}
          >
            Set Open Hours
          </span>
          <Row>
            <span
              style={{
                fontSize: "0.936vw",
                fontWeight: 400,
                color: "#333333",
              }}
            >
              Modify the clinic’s open hours to set consistent weekly hours or
              customize hours for specific dates
            </span>
          </Row>
        </Row>

        <Tabs
          defaultActiveKey={activeTabKey}
          className="tabs-class"
          onTabClick={handleTabChange}
        >
          <TabPane tab="by Week" key="by Week" className="tab-pane">
            <Row
              style={{
                display: "flex",
                gap: "0.834vw",
                flexDirection: "column",
              }}
            >
              <Row>
                <span
                  style={{
                    fontSize: "0.834vw",
                    fontWeight: 600,
                  }}
                >
                  Select Days
                </span>
              </Row>
              <Row className="w-100">
                <Form.Item
                  name="day_selection"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select Day!",
                  //   },
                  // ]}
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                    }}
                    onChange={handleSelectSlotDay}
                    value={checkedSlotValues}
                  >
                    <Row
                      style={{
                        display: "flex",
                        gap: "1.0417vw",
                      }}
                    >
                      {dateTabs &&
                        dateTabs.map((tab, index) => (
                          <Col>
                            <Checkbox
                              value={tab.date}
                              className="custom-checkbox-slots-days"
                            >
                              <div
                                className={
                                  checkedSlotValues &&
                                  (checkedSlotValues.includes(tab.date) ||
                                    checkedSlotValues.includes(tab.label))
                                    ? "checked-slot-weeks-div"
                                    : "slot-weeks-div"
                                }
                              >
                                <span
                                  style={{
                                    fontSize: "0.834vw",
                                    fontWeight: 500,
                                  }}
                                >
                                  {tab.label}
                                </span>
                              </div>
                            </Checkbox>
                          </Col>
                        ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Row>
            </Row>
            <Row
              style={{
                diaply: "flex",
                flexDirection: "column",
                gap: "0.834vw",
              }}
            >
              <Row>
                <span
                  style={{
                    fontSize: "0.834vw",
                    fontWeight: 600,
                  }}
                >
                  Select Timing
                </span>
              </Row>
              <Row
                className="d-flex ai-center"
                style={{
                  gap: "1.0938vw",
                  padding: "0.26vw 0 0 0",
                }}
              >
                <TimePicker
                  className="time-picker"
                  format={format}
                  disabled={holiday}
                  minuteStep={15}
                  onChange={handleStartTimeChange}
                />
                <span
                  style={{
                    fontSize: "0.834vw",
                  }}
                >
                  to
                </span>
                <TimePicker
                  className="time-picker"
                  format={format}
                  disabled={holiday}
                  minuteStep={15}
                  onChange={handleEndTimeChange}
                />
                {/* <TimePicker.RangePicker
                                disabled={holiday}
                                minuteStep={15}
                                format="hh:mm a"
                                style={{
                                  width: "15.63vw",
                                  height: "4.673vh",
                                }}
                                onChange={handleTimeChange}
                              /> */}
              </Row>
            </Row>
            <Row
              style={{
                display: "flex",
                gap: "1.3023vw",
                alignItems: "center",
              }}
            >
              <Col>
                <Checkbox
                  checked={holiday}
                  onChange={onHolidayChecked}
                ></Checkbox>
              </Col>
              <Col>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.417vw",
                  }}
                >
                  <span
                    style={{
                      leadingTrim: "both",
                      textEdge: "cap",
                      fontSize: "0.936vw",
                      fontWeight: 600,
                    }}
                  >
                    Make this day a Holiday
                  </span>
                  <img
                    style={{
                      width: "1.25vw",
                      height: "2.493vh",
                    }}
                    src={warningIcon}
                    alt=""
                  />
                </Row>
                <Row>
                  <span
                    style={{
                      leadingTrim: "both",
                      textEdge: "cap",
                      fontSize: "0.834vw",
                    }}
                  >
                    Checking this box marks the selected day as a holiday, even
                    if you've chosen a specific time.
                  </span>
                </Row>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="by Date" key="by Date" className="date-tab-pane">
            <Row
              style={{
                display: "flex",
                gap: "0.834vw",
                flexDirection: "column",
              }}
            >
              <Row>
                <span
                  style={{
                    fontSize: "0.834vw",
                    fontWeight: 600,
                  }}
                >
                  Select specific dates
                </span>
              </Row>
              <Row
                style={{
                  display: "flex",
                  gap: "2.33vw",
                }}
              >
                <Col>
                  <div
                    style={{
                      width: "19vw",
                    }}
                  >
                    <Calendar
                      className="openhours-calendar"
                      disabledDate={disabledDate}
                      mode="month"
                      defaultValue={moment()}
                      onPanelChange={onOpenHoursPanelChange}
                      onSelect={handleDateSelect}
                      dateFullCellRender={(date) => {
                        const isSelected = isDateSelected(date);
                        return (
                          <div
                            style={{
                              backgroundColor: isSelected ? "#e6f7ff" : "",
                              color: isSelected ? "#1890ff" : "inherit",
                              width: "1.67vw",
                              height: "1.67vw",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "none",
                              fontSize: "0.73vw",
                              fontWeight: 500,
                            }}
                          >
                            {date.date()}
                          </div>
                        );
                      }}
                    />
                  </div>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.63vw",
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.834vw",
                        fontWeight: 600,
                      }}
                    >
                      Date Range
                    </span>
                    <Row
                      className="d-flex ai-center"
                      style={{
                        gap: "1vw",
                        padding: "0.26vw 0 0 0",
                      }}
                    >
                      <sapn className="time-picker">
                        {moment(selectedDates[0]).format("DD/MM/YYYY")}
                      </sapn>
                      <span
                        style={{
                          fontSize: "0.834vw",
                        }}
                      >
                        to
                      </span>
                      <sapn className="time-picker">
                        {moment(selectedDates[selectedDates.length - 1]).format(
                          "DD/MM/YYYY"
                        )}
                      </sapn>
                    </Row>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.834vw",
                        fontWeight: 600,
                      }}
                    >
                      Select the Timing
                    </span>
                    <Row
                      className="d-flex ai-center"
                      style={{
                        gap: "1vw",
                        padding: "0.26vw 0 0 0",
                      }}
                    >
                      <TimePicker
                        className="time-picker"
                        format={format}
                        disabled={holiday}
                        minuteStep={15}
                        onChange={handleStartTimeChange}
                        // defaultValue={fromTime ? dayjs(fromTime, format): ''}
                      />
                      <span
                        style={{
                          fontSize: "0.834vw",
                        }}
                      >
                        to
                      </span>
                      <TimePicker
                        className="time-picker"
                        format={format}
                        disabled={holiday}
                        minuteStep={15}
                        onChange={handleEndTimeChange}
                        // defaultValue={toTime ? dayjs(toTime, format) : ''}
                      />
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Row>

            <Row
              style={{
                display: "flex",
                gap: "1.3023vw",
                alignItems: "center",
              }}
            >
              <Col>
                <Checkbox
                  checked={holiday}
                  onChange={onHolidayChecked}
                ></Checkbox>
              </Col>
              <Col>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.417vw",
                  }}
                >
                  <span
                    style={{
                      leadingTrim: "both",
                      textEdge: "cap",
                      fontSize: "0.936vw",
                      fontWeight: 600,
                    }}
                  >
                    Make this day a Holiday
                  </span>
                  <img
                    style={{
                      width: "1.25vw",
                      height: "2.493vh",
                    }}
                    src={warningIcon}
                    alt=""
                  />
                </Row>
                <Row>
                  <span
                    style={{
                      leadingTrim: "both",
                      textEdge: "cap",
                      fontSize: "0.834vw",
                    }}
                  >
                    Checking this box marks the selected day as a holiday, even
                    if you've chosen a specific time.
                  </span>
                </Row>
              </Col>
            </Row>

            {/* <RangePicker
                            onChange={handleDateRangeChange}
                            defaultValue={[moment('2024-08-02'), moment('2024-08-17')]}
                          /> */}
          </TabPane>
        </Tabs>

        <Row className="d-flex jc-end ai-center" style={{ gap: "0.938vw" }}>
          <Form.Item>
            <Button
              className="cancel-button"
              type="danger"
              onClick={() => {
                setHoliday(false);
                setOpenHourButtonState(false);
                form.resetFields();
                setCheckedSlotValues([]);
                setUpdateClinicHours(false);
                newSelectedDates = [];
                setSelectedDates([]);
                setUpdateClinicHours(false);
                setStartTime(null);
                setEndTime(null);
                setActiveTabKey("by Week");
              }}
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button className="save-button" type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Row>
      </div>
    );
  };

  const baseUrl = `${API_BASE_URL}/api/v1/adminStaff/${admin_id}/timeOff?adminId=${admin_id}`;

  // Function to construct new URL with updated query params
  const constructUrl = (baseUrl, paramsToAdd) => {
    const url = new URL(baseUrl);
    const queryParams = new URLSearchParams(url.search);
    for (const key in paramsToAdd) {
      if (paramsToAdd[key]) {
        queryParams.set(key, paramsToAdd[key]);
      } else {
        queryParams.delete(key);
      }
    }
    url.search = queryParams.toString();
    return url.toString();
  };

  const handleSelectChange = (key, val) => {
    if (key === "designation") {
      if (val === "doctor") {
        setParams({
          ...params,
          [key]: 1,
        });
      } else if (val === "staff") {
        setParams({
          ...params,
          [key]: 2,
        });
      } else {
        setParams({
          ...params,
          [key]: "",
        });
      }
    } else if (key === "date") {
      if (val === "today") {
        setParams({
          ...params,
          [key]: moment(today).format("YYYY-MM-DD"),
        });
      } else if (val === "customDate") {
        setDateSelectModal(true);
      } else {
        setParams({
          ...params,
          [key]: "",
        });
      }
    }
  };

  const handleDateApply = (key, val) => {
    setParams({
      ...params,
      ["date"]: val,
    });
  };

  const handleApplyButton = () => {
    setDateSelectModal(false);
    let date = moment(selectedDate).format("YYYY-MM-DD");
    setDisplayDate(date);
    handleDateApply("date", date);
  };

  const newUrl = constructUrl(baseUrl, params);

  useEffect(() => {
    handleGetAllTimeOffRequests();
  }, [newUrl]);

  const handleGetAllTimeOffRequests = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    await axios.get(newUrl, config).then((res) => {
      setTimeOffReqData(res.data.data);
      setSpinner(false);
    });
  };

  const handleRejectTimeOffReq = (item) => {
    setRejectReasonModal(true);
    setTimeOffReqItem(item);
  };

  const onRadioChange = (e) => {
    setRejectReason(e.target.value);
  };

  const handleTimeOffRequests = async (req_status, req_item) => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    setTimeOffReqItem(req_item);

    let reject_reason = "";

    if (req_status === "Approved") {
      reject_reason = "";
    } else {
      reject_reason = rejectReason;
    }

    const body = {
      id: req_item.id,
      status: req_status,
      rejected_reason: reject_reason,
    };

    setSpinner(true);

    await axios
      .delete(`${API_BASE_URL}/api/v1/adminStaff/timeOffRequest`, {
        ...config,
        data: body,
      })
      .then((res) => {
        handleGetAllTimeOffRequests();
        req_status === "Approved"
          ? setApprovedRequestModal(true)
          : setApprovedRequestModal(false);
        if (req_status === "Rejected") {
          setRejectReasonModal(false);
          setRejectRequestModal(true);
        }
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors[0]);
        setSpinner(false);
      });
  };

  const handleGetClinicOpenHours = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    let response1 = [];
    let response2 = [];
    await axios
      .get(
        `${API_BASE_URL}/api/v1/clinic/hours/AllClinicHoursDetails/${clinic_id}?branchId=${branch_id}`,
        config
      )
      .then((res) => {
        setCheckedSlotValues([]);
        response1 = res.data.data;
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
    await axios
      .get(
        `${API_BASE_URL}/api/v1/clinic/hours/ClinicOpenHoursDetails/${clinic_id}?branchId=${branch_id}`,
        config
      )
      .then((res) => {
        setCheckedSlotValues([]);
        response2 = res.data.data;
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });

    const combinedArray = [...response2, ...response1];

    setClinicOpenHours(combinedArray);
  };

  const convertTime = (timeString) => {
    const [hours, minutes, seconds] = timeString?.split(":");
    const formattedHours = parseInt(hours) % 12 || 12; // Handle 0 as 12 PM
    const period = parseInt(hours) < 12 ? "AM" : "PM";
    const formattedMinutes = minutes.padStart(2, "0"); // Add leading zero if needed
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  const handlDeleteClinicOpenHours = async (id) => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .delete(
        `${API_BASE_URL}/api/v1/clinic/hours/deleteClinicHoursDetails/${id}`,
        config
      )
      .then((res) => {
        message.success("Deleted Successfully");
        setCheckedSlotValues([]);
        handleGetClinicOpenHours();
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const validatePhoneNumber = (_, value) => {
    const numericRegex = /^[0-9]+$/;
    if (!value || (numericRegex.test(value) && value.length === 10)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Please enter exactly 10 numeric characters for the phone number."
    );
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter 6-digit zip code!");
  };

  const handleUpdateClinicDetails = async (values) => {
    setSpinner(true);
    let payload;
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    payload = {
      name: values.name,
      email: values.email,
      username: clinicDetails.username,
      phone_country_code: values.phone_country_code,
      phone_number: values.phone_number,
      address_line_one: values.address_line_one,
      address_line_two: values.address_line_two,
      city: values.city,
      province: values.province,
      country: values.country,
      zip_code: values.zip_code,
      coordinate: {
        latitude: clinicDetails.coordinate.latitude,
        longitude: clinicDetails.coordinate.longitude,
      },
      profile_picture_url: clinicDetails.profile_picture_url,
      referred_by: clinicDetails.referred_by,
      terms_accepted: clinicDetails.terms_accepted,
      account_name: values.account_name,
      account_no: values.confirm_account_no,
      confirm_account_no: values.confirm_account_no,
      ifsc_code: values.ifsc_code,
      contact_person: clinicDetails.contact_person,
    };
    await axios
      .put(`${API_BASE_URL}/api/v1/clinic/${clinic_id}`, payload, config)
      .then((res) => {
        handleGetClinicDetails();
        handleGetAllclinicBranches();
        setEditClinicModal(false);
        setSpinner(false);
        message.success("Clinic Updated Successfully");
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  useEffect(() => {
    handleGetClinicDetails();
    handleGetAllclinicBranches();
    handleGetClinicOpenHours();
    handleGetTreatmentsTypes();
    handleGetAllDoctorinClinic();
    handleGetAllTimeOffRequests();
  }, []);

  const onHolidayChecked = (e) => {
    setHoliday(e.target.checked);
  };

  const onDateSelect = (date) => {
    setSelectedDate(date);
  };

  const onSelect = (date) => {
    setSelectedDate(null);
    setDisplayDate(date);
    setdefaultValue({ eventName: "" });
    setInputValue("");
    const dateString = date.format("YYYY-MM-DD");
    const events =
      calendarEvents &&
      calendarEvents.filter((event) => event.event_date === dateString);
    setSelectedDate(date);
    if (events.length > 0) {
      setcancelButton(false);
      setselectedInputValue(events);
    } else {
      setselectedInputValue(calendarEvents);
    }
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const dateCellRender = (date) => {
    const dateString = date.format("YYYY-MM-DD");
    const events =
      calendarEvents &&
      calendarEvents.filter((event) => event.event_date === dateString);
    return (
      events &&
      events.map((event, index) => (
        <div
          key={index}
          style={{
            minHeight: "3.3368091762252345vh",
            background: "#E0F4FF",
            borderLeft: "3px solid #3498DB",
            padding: "0 0 0 5px",
            fontSize: "0.7291666666666666vw",
            fontWeight: 400,
            color: "#000000",
            margin: "0 0 5px 0",
          }}
          className="d-flex ai-center"
        >
          {event.event_name}
        </div>
      ))
    );
  };

  const onPanelChange = (date, mode) => {
    setCurrentDate({ year: date.year(), month: date.month() + 1 });
    setDisplayDate(date); // Update display date
    getCalendarEvents(date.year(), date.month() + 1);
  };

  const getCalendarEvents = async (year, month) => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (branch_id) {
      await axios
        .get(
          `${API_BASE_URL}/api/v1/events/month_events/${clinic_id}/{branchId}/${year}/${month}?branchId=${branch_id}`,
          config
        )
        .then((res) => {
          setcalendarEvents(res.data.data);
          setselectedInputValue(res.data.data);
          setcancelButton(true);
          setSpinner(false);
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
        });
    } else {
      await axios
        .get(
          `${API_BASE_URL}/api/v1/events/month_events/${clinic_id}/{branchId}/${year}/${month}`,
          config
        )
        .then((res) => {
          setcalendarEvents(res.data.data);
          setselectedInputValue(res.data.data);
          setcancelButton(true);
          setSpinner(false);
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
        });
    }
  };

  const deleteCalendarEvents = (event_name) => {
    setSpinner(true);
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .delete(`${API_BASE_URL}/api/v1/events/${event_name}`, config)
      .then((res) => {
        getCalendarEvents(currentDate.year, currentDate.month);
        openNotification("bottom");
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const createCalendarEvents = async (values) => {
    if (selectedDate) {
      setSpinner(true);
      let payload;
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      if (branch_id) {
        payload = {
          description: "",
          eventDate: selectedDate.format("YYYY-MM-DD"),
          eventName: inputValue.trim(),
          clinicId: clinic_id,
          year: selectedDate.format("YYYY"),
          month: selectedDate.format("MM"),
          branchId: branch_id,
        };
      } else {
        payload = {
          description: "",
          eventDate: selectedDate.format("YYYY-MM-DD"),
          eventName: inputValue.trim(),
          clinicId: clinic_id,
          year: selectedDate.format("YYYY"),
          month: selectedDate.format("MM"),
        };
      }
      await axios
        .post(`${API_BASE_URL}/api/v1/events/createEvent`, payload, config)
        .then((res) => {
          setSelectedDate(null);
          setInputValue("");
          getCalendarEvents(currentDate.year, currentDate.month);
          setSpinner(false);
          openCreateNotification("bottom");
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
          getCalendarEvents(currentDate.year, currentDate.month);
          setSpinner(false);
        });
    }
  };

  const handleUpdatePublicHolidays = async () => {
    setSpinner(true);
    let payload;
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    payload = {
      description: "",
      eventDate: defaultValue.eventDate,
      eventName: inputValue.trim(),
      year: defaultValue.year,
      month: defaultValue.month,
    };
    await axios
      .put(
        `${API_BASE_URL}/api/v1/events/${defaultValue.eventName}`,
        payload,
        config
      )
      .then((res) => {
        setSelectedDate(null);
        setInputValue("");
        getCalendarEvents(currentDate.year, currentDate.month);
        setSpinner(false);
        openUpdateeNotification("bottom");
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const openNotification = (placement) => {
    api.info({
      message: "Event deleted",
      description: "The calendar has been deleted the holiday.",
      placement,
      icon: (
        <div
          className="h-100 d-flex ai-center"
          style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
          <img
            alt=""
            src={deleteIcon}
            style={{
              color: "#108ee9",
              width: "1.7708333333333333vw",
            }}
          />
        </div>
      ),
      style: {
        width: "28.854166666666668vw",
        height: "10.531803962460897vh",
        bordeRadius: 10,
        border: "0px solid #FF0000",
        display: "flex",
        alignItems: "center",
        boxShadow: "0px 0px 4px 0px #FF0000",
      },
    });
  };

  const openCreateNotification = (placement) => {
    api.info({
      message: "Event added successfully!",
      description: "The calendar has been updated with the new holiday.",
      placement,
      icon: (
        <div
          className="h-100 d-flex ai-center"
          style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
          <img
            alt=""
            src={eventadded}
            style={{
              color: "#108ee9",
              width: "1.7708333333333333vw",
            }}
          />
        </div>
      ),
      style: {
        width: "28.854166666666668vw",
        height: "10.531803962460897vh",
        bordeRadius: 10,
        border: "0px solid #FF0000",
        display: "flex",
        alignItems: "center",
        boxShadow: "0px 0px 4px 0px #27AE60",
      },
    });
  };

  const openUpdateeNotification = (placement) => {
    api.info({
      message: "Event Updated successfully!",
      description: "The calendar has been updated with the holiday.",
      placement,
      icon: (
        <div
          className="h-100 d-flex ai-center"
          style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
          <img
            alt=""
            src={eventadded}
            style={{
              color: "#108ee9",
              width: "1.7708333333333333vw",
            }}
          />
        </div>
      ),
      style: {
        width: "28.854166666666668vw",
        height: "10.531803962460897vh",
        bordeRadius: 10,
        border: "0px solid #FF0000",
        display: "flex",
        alignItems: "center",
        boxShadow: "0px 0px 4px 0px #27AE60",
      },
    });
  };

  const handleTodayClick = () => {
    const today = moment();
    setCurrentDate({ year: today.year(), month: today.month() + 1 });
    setDisplayDate(today); // Update display date
    setSelectedDate(today); // Ensure today is selected
    getCalendarEvents(today.year(), today.month() + 1);
  };

  useEffect(() => {
    getCalendarEvents(currentDate.year, currentDate.month);
  }, [currentDate]);

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().startOf("day");
  };

  const convertTimestamp = (timestamp, type) => {
    const date = new Date(timestamp);

    const dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    return type === "date" ? formattedDate : formattedTime;
  };

  const timeOffItems = [
    // scheduled
    {
      key: "1",
      label: (
        <div className="d-flex">
          Pending&nbsp;
          {/* <span className="appointment-length">
                    {appointmentDataForClinic &&
                        appointmentDataForClinic
                            .filter((item) => item.scheduled_by === "Patient")
                            .filter((item) => item.status === "Scheduled").length}
                </span> */}
        </div>
      ),
      children: (
        <div>
          <Row
            style={{
              background: "#F5F5F5",
              height: "3.437vw",
              margin: "16px 0 0 0",
              padding: "0 2.2vw",
              borderBottom: "1px solid #BBB",
            }}
            className="d-flex ai-center"
          >
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Staff
              </span>
            </Col>
            <Col span={3}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Requested on
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                From Date
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                To Date
              </span>
            </Col>
            <Col span={5}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Reason
              </span>
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row
            style={{
              maxHeight: "53vh",
              overflow: "auto",
            }}
          >
            {timeOffReqData &&
              timeOffReqData
                .filter((item) => item.status === "Pending")
                .map((item) => {
                  return (
                    <Row className="time-off-req-item">
                      <Col
                        span={4}
                        style={{
                          display: "flex",
                          gap: "0.52vw",
                        }}
                      >
                        <StaffProfilePicture
                          id={item.id}
                          pro={
                            item.profile_picture_url &&
                            item.profile_picture_url.split(".com/")[1]
                          }
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.417vw",
                          }}
                        >
                          <span className="staff-name">{item.name}</span>
                          <span className="staff-id">SID #{item.staff_id}</span>
                        </div>
                      </Col>
                      <Col span={3}>
                        <span className="requested-on">
                          {convertTimestamp(item.requested_on, "date")},{" "}
                          {convertTimestamp(item.requested_on, "time")}
                        </span>
                      </Col>
                      <Col span={4}>
                        <Row
                          style={{
                            display: "flex",
                            gap: "0.73vw",
                          }}
                        >
                          <span className="date-wrapper">
                            {convertTimestamp(item.from_time, "date")}
                          </span>
                          <span className="date-wrapper">
                            {convertTimestamp(item.from_time, "time")}
                          </span>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row
                          style={{
                            display: "flex",
                            gap: "0.73vw",
                          }}
                        >
                          <span className="date-wrapper">
                            {convertTimestamp(item.to_time, "date")}
                          </span>
                          <span className="date-wrapper">
                            {convertTimestamp(item.to_time, "time")}
                          </span>
                        </Row>
                      </Col>
                      <Col span={5} className="time-off-req-reason">
                        <span>{item.reason ? item.reason : "-"}</span>
                      </Col>
                      <Col
                        span={4}
                        style={{
                          display: "flex",
                          gap: "0.52vw",
                        }}
                      >
                        <Button
                          className="approve-button"
                          onClick={() =>
                            handleTimeOffRequests("Approved", item)
                          }
                        >
                          Approve
                        </Button>
                        <Button
                          className="reject-button"
                          onClick={() => handleRejectTimeOffReq(item)}
                        >
                          Reject
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
          </Row>

          <Modal
            width={"33vw"}
            centered
            open={isApproveRequestModal}
            footer={false}
            closable={false}
            destroyOnClose={true}
            className="approved-modal"
          >
            <div className="approved-message">
              <img className="success-logo" src={successLogo} />
              <span className="approve-message-header">
                Request Approved Successfully!
              </span>
              <span className="approve-message-desc">
                The time off request for{" "}
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {timeOffReqItem.name}
                </span>{" "}
                has been successfully approved from{" "}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {convertTimestamp(timeOffReqItem.from_time, "date")},{" "}
                  {convertTimestamp(timeOffReqItem.from_time, "time")}
                </span>{" "}
                to{" "}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {convertTimestamp(timeOffReqItem.to_time, "date")},{" "}
                  {convertTimestamp(timeOffReqItem.to_time, "time")}
                </span>
              </span>
              <Button
                className="close-button"
                onClick={() => setApprovedRequestModal(false)}
              >
                Close
              </Button>
            </div>
          </Modal>

          <Modal
            width={"25vw"}
            centered
            open={isRejectReasonModal}
            footer={false}
            closable={false}
            destroyOnClose={true}
            className="reject-reason-modal"
          >
            <div className="reject-reason-content">
              <Row>
                <span className="reject-reason-header">
                  Select Rejection Reason
                </span>
              </Row>
              <Radio.Group
                onChange={onRadioChange}
                value={rejectReason}
                className="w-100"
              >
                <Row className="d-flex w-100">
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      gap: "0.834vw",
                      flexDirection: "column",
                    }}
                  >
                    <Radio value={"Staff Shortage"}>
                      <span
                        style={{
                          color: "#000",
                          fontSize: "0.834vw",
                          fontWeight: 400,
                        }}
                      >
                        Staff Shortage
                      </span>
                    </Radio>
                    <Radio value={"Conflicting Schedules"}>
                      <span
                        style={{
                          color: "#000",
                          fontSize: "0.834vw",
                          fontWeight: 400,
                        }}
                      >
                        Conflicting Schedules
                      </span>
                    </Radio>
                    <Radio value={"Training Sessions"}>
                      <span
                        style={{
                          color: "#000",
                          fontSize: "0.834vw",
                          fontWeight: 400,
                        }}
                      >
                        Training Sessions
                      </span>
                    </Radio>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      gap: "0.834vw",
                      flexDirection: "column",
                    }}
                  >
                    <Radio value="Peak Season">
                      <span
                        style={{
                          color: "#000",
                          fontSize: "0.834vw",
                          fontWeight: 400,
                        }}
                      >
                        Peak Season
                      </span>
                    </Radio>
                    <Radio value="Previous Requests">
                      <span
                        style={{
                          color: "#000",
                          fontSize: "0.834vw",
                          fontWeight: 400,
                        }}
                      >
                        Previous Requests
                      </span>
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
              <Row
                style={{
                  display: "flex",
                  gap: "0.63vw",
                }}
              >
                <Button
                  className="cancel-button"
                  onClick={() => setRejectReasonModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="reject-req-button"
                  onClick={() =>
                    handleTimeOffRequests("Rejected", timeOffReqItem)
                  }
                >
                  Reject Request
                </Button>
              </Row>
            </div>
          </Modal>

          <Modal
            width={"33vw"}
            centered
            open={isRejectRequestModal}
            footer={false}
            closable={false}
            destroyOnClose={true}
            className="rejected-modal"
          >
            <div className="rejected-message">
              <img className="reject-logo" src={rejectLogo} />
              <span className="reject-message-header">Request Rejected!</span>
              <span className="reject-message-desc">
                The time off request for{" "}
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {timeOffReqItem.name}
                </span>{" "}
                has been rejected from{" "}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {convertTimestamp(timeOffReqItem.from_time, "date")},{" "}
                  {convertTimestamp(timeOffReqItem.from_time, "time")}
                </span>{" "}
                to{" "}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {convertTimestamp(timeOffReqItem.to_time, "date")},{" "}
                  {convertTimestamp(timeOffReqItem.to_time, "time")}
                </span>
              </span>
              <Button
                className="close-button"
                onClick={() => setRejectRequestModal(false)}
              >
                Close
              </Button>
            </div>
          </Modal>
        </div>
      ),
    },
    {
      key: "2",
      label: <div className="d-flex">Approved&nbsp;</div>,
      children: (
        <div>
          <Row
            style={{
              background: "#F5F5F5",
              height: "3.437vw",
              margin: "16px 0 0 0",
              padding: "0 42px",
              borderBottom: "1px solid #BBB",
            }}
            className="d-flex ai-center"
          >
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Staff
              </span>
            </Col>
            <Col span={3}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Requested on
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                From Date
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                To Date
              </span>
            </Col>
            <Col span={5}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Reason
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Approved on
              </span>
            </Col>
          </Row>
          <Row
            style={{
              maxHeight: "53vh",
              overflow: "auto",
            }}
          >
            {timeOffReqData &&
              timeOffReqData
                .filter((item) => item.status === "Approved")
                .map((item) => {
                  return (
                    <Row className="time-off-req-item">
                      <Col
                        span={4}
                        style={{
                          display: "flex",
                          gap: "0.52vw",
                        }}
                      >
                        <StaffProfilePicture
                          id={item.id}
                          pro={
                            item.profile_picture_url &&
                            item.profile_picture_url.split(".com/")[1]
                          }
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.417vw",
                          }}
                        >
                          <span className="staff-name">{item.name}</span>
                          <span className="staff-id">SID #{item.staff_id}</span>
                        </div>
                      </Col>
                      <Col span={3}>
                        <span className="requested-on">
                          {convertTimestamp(item.requested_on, "date")},{" "}
                          {convertTimestamp(item.requested_on, "time")}
                        </span>
                      </Col>
                      <Col span={4}>
                        <Row
                          style={{
                            display: "flex",
                            gap: "0.73vw",
                          }}
                        >
                          <span className="date-wrapper">
                            {convertTimestamp(item.from_time, "date")}
                          </span>
                          <span className="date-wrapper">
                            {convertTimestamp(item.from_time, "time")}
                          </span>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row
                          style={{
                            display: "flex",
                            gap: "0.73vw",
                          }}
                        >
                          <span className="date-wrapper">
                            {convertTimestamp(item.to_time, "date")}
                          </span>
                          <span className="date-wrapper">
                            {convertTimestamp(item.to_time, "time")}
                          </span>
                        </Row>
                      </Col>
                      <Col span={5} className="time-off-req-reason">
                        <span>{item.reason ? item.reason : "-"}</span>
                      </Col>
                      <Col span={4}>
                        <span className="requested-on">
                          {convertTimestamp(item.changed_on, "date")},{" "}
                          {convertTimestamp(item.changed_on, "time")}
                        </span>
                      </Col>
                    </Row>
                  );
                })}
          </Row>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="d-flex">
          Rejected&nbsp;
          {/* <span className="appointment-length">
                    {appointmentDataForClinic &&
                        appointmentDataForClinic
                            .filter((item) => item.scheduled_by === "Patient")
                            .filter((item) => item.status === "In-Progress").length}
                </span> */}
        </div>
      ),
      children: (
        <div>
          <Row
            style={{
              background: "#F5F5F5",
              height: "3.437vw",
              margin: "16px 0 0 0",
              padding: "0 42px",
              borderBottom: "1px solid #BBB",
            }}
            className="d-flex ai-center"
          >
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Staff
              </span>
            </Col>
            <Col span={3}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Requested on
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                From Date
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                To Date
              </span>
            </Col>
            <Col span={5}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Reason
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Rejected on
              </span>
            </Col>
          </Row>
          <Row
            style={{
              maxHeight: "53vh",
              overflow: "auto",
            }}
          >
            {timeOffReqData &&
              timeOffReqData
                .filter((item) => item.status === "Rejected")
                .map((item) => {
                  return (
                    <Row className="time-off-req-item">
                      <Col
                        span={4}
                        style={{
                          display: "flex",
                          gap: "0.52vw",
                        }}
                      >
                        <StaffProfilePicture
                          id={item.id}
                          pro={
                            item.profile_picture_url &&
                            item.profile_picture_url.split(".com/")[1]
                          }
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.417vw",
                          }}
                        >
                          <span className="staff-name">{item.name}</span>
                          <span className="staff-id">SID #{item.staff_id}</span>
                        </div>
                      </Col>
                      <Col span={3}>
                        <span className="requested-on">
                          {convertTimestamp(item.requested_on, "date")},{" "}
                          {convertTimestamp(item.requested_on, "time")}
                        </span>
                      </Col>
                      <Col span={4}>
                        <Row
                          style={{
                            display: "flex",
                            gap: "0.73vw",
                          }}
                        >
                          <span className="date-wrapper">
                            {convertTimestamp(item.from_time, "date")}
                          </span>
                          <span className="date-wrapper">
                            {convertTimestamp(item.from_time, "time")}
                          </span>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row
                          style={{
                            display: "flex",
                            gap: "0.73vw",
                          }}
                        >
                          <span className="date-wrapper">
                            {convertTimestamp(item.to_time, "date")}
                          </span>
                          <span className="date-wrapper">
                            {convertTimestamp(item.to_time, "time")}
                          </span>
                        </Row>
                      </Col>
                      <Col span={5} className="time-off-req-reason">
                        <span>{item.reason ? item.reason : "-"}</span>
                      </Col>
                      <Col
                        span={4}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.417vw",
                        }}
                      >
                        <span className="requested-on">
                          {convertTimestamp(item.changed_on, "date")},{" "}
                          {convertTimestamp(item.changed_on, "time")}
                        </span>
                        <span className="rejected-reason">
                          Rejection Reason:{" "}
                          {item.rejection_reason ? item.rejection_reason : "-"}
                        </span>
                      </Col>
                    </Row>
                  );
                })}
          </Row>
        </div>
      ),
    },
    // completed
  ];

  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = async (event) => {
    setSpinner(true);
    const propic = event.target.files[0];
    let formData = new FormData();
    formData.append("file", propic);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .post(
        `${API_BASE_URL}/api/v1/clinic/${clinic_id}/upload-profile-picture`,
        formData,
        config
      )
      .then((res) => {
        message.success("Updated Profile Picture");
        handleGetClinicDetails();
        setSpinner(false);
        // getPatientDetails();
      })
      .catch((err) => {
        message.error(err);
        setSpinner(false);
      });
  };

  const items = [
    {
      key: "1",
      label: (
        <span
          style={{
            fontSize: "0.834vw",
            fontWeight: 400,
          }}
        >
          Clinic Profile
        </span>
      ),
      children: (
        <div className="tabs-content">
          <Row
            className="w-100"
            style={{ gap: "0.73vw", margin: "0.782vw 0 0 0" }}
          >
            {clinicDetails && (
              <div className="clinic-details-new w-100">
                <Row>
                  <span
                    style={{
                      color: "#3498DB",
                      fontSize: "1.25vw",
                      fontWeight: 500,
                    }}
                  >
                    Clinic Details
                  </span>
                </Row>
                <Row className="w-100 d-flex jc-between ai-center">
                  <span
                    style={{
                      color: "#616161",
                      fontSize: "0.834vw",
                      fontWeight: 400,
                    }}
                  >
                    Welcome, Admin! Manage and update the primary details
                    of&nbsp;
                    <span
                      style={{
                        color: "#3498DB",
                        fontSize: "0.834vw",
                        fontWeight: 500,
                      }}
                    >
                      {clinicDetails.name}
                    </span>
                    &nbsp;Keep the information accurate and up-to-date to better
                    serve your patients.
                  </span>
                  {role === "CLINIC_MASTER_ADMIN" && (
                    <Button
                      style={{
                        height: "3.116vh",
                        border: "1px solid #2CA3FA",
                        borderRadius: "1.563vw",
                        color: "#2CA3FA",
                        background: "#FFF",
                        fontSize: "0.73vw",
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        setEditClinicModal(true);
                        // setbranchDetails();
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </Row>
                <Row style={{ padding: "0.782vw 0px 1.302vw" }}>
                  <div
                    style={{ height: 1, background: "#D8D8D8", width: "100%" }}
                  ></div>
                </Row>
                <Row style={{ gap: "58px" }}>
                  <Col>
                    <img
                      alt=""
                      src={fileContent ? fileContent : ssdental}
                      style={{
                        width: "9.532vw",
                        height: "19.004vh",
                        borderRadius: "0.521vw",
                        cursor: "pointer",
                      }}
                      onClick={handleImageClick}
                    />
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "center",
                      gap: 20,
                      // alignItems: "center",
                    }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                        }}
                      >
                        Clinic Name <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Input
                        disabled={enableForm}
                        value={clinicDetails.name}
                        style={{
                          width: "16.771vw",
                          color: "black",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                        }}
                      >
                        Email <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Input
                        value={clinicDetails.email}
                        disabled={enableForm}
                        style={{
                          color: "black",
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Row>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "center",
                      gap: 20,
                      // alignItems: "center",
                    }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                        }}
                      >
                        Contact Person{" "}
                        <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Input
                        disabled={enableForm}
                        value={clinicDetails.contact_person}
                        style={{
                          width: "16.771vw",
                          margin: "0.26vw 0 0 0",
                          color: "black",
                          height: "2.0834vw",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                        }}
                      >
                        Phone No. <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Input
                        value={clinicDetails.phone_number}
                        disabled={enableForm}
                        style={{
                          width: "16.771vw",
                          color: "black",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Row>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      // flexDirection: "column",
                      justifyContent: "space-around",
                      // alignItems: "center",
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                        }}
                      >
                        Username <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Input
                        value={
                          // clinicDetails.address_line_one +
                          // `, ` +
                          // clinicDetails.address_line_two +
                          // `, ` +
                          // clinicDetails.city +
                          // `, ` +
                          // clinicDetails.province +
                          // `, ` +
                          // clinicDetails.country +
                          // `, ` +
                          clinicDetails.username
                        }
                        disabled={enableForm}
                        style={{
                          width: "16.771vw",
                          color: "black",
                          margin: "0.26vw 0 0 0",
                          height: "2.0834vw",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Row>
                  </Col>
                </Row>

                <Tabs defaultActiveKey="1" className="address-details">
                  <Tabs.TabPane tab="Address Details" key="1">
                    <Row
                      style={{ gap: "58px", padding: "20px 0" }}
                      className="d-flex"
                    >
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          // padding:"20px 0"
                          gap: 20,
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            Street Address{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          <Input
                            disabled={enableForm}
                            value={clinicDetails.address_line_one}
                            style={{
                              width: "16.771vw",
                              color: "black",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                            }}
                          />
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            Country <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          <Input
                            disabled={enableForm}
                            value={clinicDetails.country}
                            style={{
                              width: "16.771vw",
                              margin: "0.26vw 0 0 0",
                              color: "black",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                            }}
                          />
                        </Row>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          gap: 36,
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            City <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          <Input
                            value={clinicDetails.city}
                            disabled={enableForm}
                            style={{
                              color: "black",
                              width: "16.771vw",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                            }}
                          />
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            ZIP Code <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          <Input
                            value={clinicDetails.zip_code}
                            disabled={enableForm}
                            style={{
                              width: "16.771vw",
                              color: "black",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                            }}
                          />
                        </Row>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "space-around",
                          // alignItems: "center",
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            State <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          {/* <TextArea
                        value={
                          clinicDetails.address_line_one +
                          `, ` +
                          clinicDetails.address_line_two +
                          `, ` +
                          clinicDetails.city +
                          `, ` +
                          clinicDetails.province +
                          `, ` +
                          clinicDetails.country +
                          `, ` +
                          clinicDetails.zip_code
                        }
                        disabled={enableForm}
                        style={{
                          color: "black",
                          width: "24.532vw",
                          margin: "0.26vw 0 0 0",
                          height: "13.6vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                        }}
                      /> */}
                          <Input
                            value={clinicDetails.province}
                            disabled={enableForm}
                            style={{
                              color: "black",
                              width: "16.771vw",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Bank Details" key="2">
                    <Row
                      style={{ gap: "58px", padding: "20px 0" }}
                      className="d-flex"
                    >
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          gap: 20,
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            Account Name{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          <Input
                            disabled={enableForm}
                            value={
                              clinicDetails.account_name &&
                              clinicDetails.account_name
                            }
                            style={{
                              width: "16.771vw",
                              color: "black",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                            }}
                          />
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            IFSC Code{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          <Input
                            disabled={enableForm}
                            value={clinicDetails.ifsc_code}
                            style={{
                              width: "16.771vw",
                              margin: "0.26vw 0 0 0",
                              color: "black",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                            }}
                          />
                        </Row>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          gap: 36,
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            Account No.{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          <Input
                            value={clinicDetails.confirm_account_no}
                            disabled={enableForm}
                            style={{
                              color: "black",
                              width: "16.771vw",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                            }}
                          />
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {/* <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            Beneficiary Name <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          <Input
                            value={clinicDetails.zip_code}
                            disabled={enableForm}
                            style={{
                              width: "16.771vw",
                              color: "black",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                            }}
                          /> */}
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            Confirm Account No.*{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          {/* <TextArea
                        value={
                          clinicDetails.address_line_one +
                          `, ` +
                          clinicDetails.address_line_two +
                          `, ` +
                          clinicDetails.city +
                          `, ` +
                          clinicDetails.province +
                          `, ` +
                          clinicDetails.country +
                          `, ` +
                          clinicDetails.zip_code
                        }
                        disabled={enableForm}
                        style={{
                          color: "black",
                          width: "24.532vw",
                          margin: "0.26vw 0 0 0",
                          height: "13.6vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                        }}
                      /> */}
                          <Input
                            value={clinicDetails.confirm_account_no}
                            disabled={enableForm}
                            style={{
                              color: "black",
                              width: "16.771vw",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                            }}
                          />
                        </Row>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "space-around",
                          // alignItems: "center",
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        ></Row>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Certificate" key="3">
                    Certificate
                  </Tabs.TabPane>
                </Tabs>
              </div>
            )}
          </Row>
          <Row
            className="w-100 d-flex jc-between"
            style={{ margin: "0.782vw 0 0 0" }}
          >
            <Col>
              <div className="branch-management-new">
                <Row className="d-flex ai-center jc-between">
                  <span
                    style={{
                      color: "#3498DB",
                      fontSize: "1.25vw",
                      fontWeight: 500,
                    }}
                  >
                    Branches ({clinicBranches && clinicBranches.length})
                  </span>
                  {role === "CLINIC_MASTER_ADMIN" && (
                    <Button
                      onClick={() => setaddClinicBranchModal(true)}
                      style={{
                        height: "3.116vh",
                        border: "1px solid #2CA3FA",
                        borderRadius: "1.563vw",
                        color: "#2CA3FA",
                        background: "#FFF",
                        fontSize: "0.73vw",
                        fontWeight: 600,
                      }}
                    >
                      Add Branch
                    </Button>
                  )}
                </Row>
                <Row style={{ padding: "0.782vw 0px 1.302vw" }}>
                  <div
                    style={{ height: 1, background: "#D8D8D8", width: "100%" }}
                  ></div>
                </Row>
                <div style={{ maxHeight: "28vh", overflow: "auto" }}>
                  {clinicBranches &&
                    clinicBranches.map((item) => {
                      return (
                        <ClinicBranches
                          item={item}
                          handleDeleteClinicBranch={handleDeleteClinicBranch}
                          clinicDetails={clinicDetails}
                          enableForm={enableForm}
                          handleGetClinicDetails={handleGetClinicDetails}
                          handleGetAllclinicBranches={
                            handleGetAllclinicBranches
                          }
                        />
                      );
                    })}
                </div>
              </div>
            </Col>
            <Col>
              <div className="branch-management-new">
                <Row className="d-flex ai-center jc-between">
                  <span
                    style={{
                      color: "#3498DB",
                      fontSize: "1.25vw",
                      fontWeight: 500,
                    }}
                  >
                    Treatments (
                    {treatmentTypes &&
                      treatmentTypes.filter((item) => item.status === "ACTIVE")
                        .length}
                    )
                  </span>
                  {role === "CLINIC_MASTER_ADMIN" && (
                    <Button
                      style={{
                        height: "3.116vh",
                        border: "1px solid #2CA3FA",
                        borderRadius: "1.563vw",
                        color: "#2CA3FA",
                        background: "#FFF",
                        fontSize: "0.73vw",
                        fontWeight: 600,
                      }}
                      onClick={() => setaddTreatmentModal(true)}
                    >
                      Add Treatments
                    </Button>
                  )}
                </Row>
                <Row style={{ padding: "0.782vw 0px 1.302vw" }}>
                  <div
                    style={{ height: 1, background: "#D8D8D8", width: "100%" }}
                  ></div>
                </Row>
                <div style={{ maxHeight: "28vh", overflow: "auto" }}>
                  {treatmentTypes &&
                    treatmentTypes
                      .filter((item) => item.status === "ACTIVE")
                      .map((item) => {
                        return (
                          <ClinicTreatments
                            item={item}
                            handleGetTreatmentsTypes={handleGetTreatmentsTypes}
                            form={form}
                            handleDeleteTreatmentType={
                              handleDeleteTreatmentType
                            }
                            role={role}
                            clinicDetails={clinicDetails}
                          />
                        );
                      })}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span
          style={{
            fontSize: "0.834vw",
            fontWeight: 400,
          }}
        >
          Clinic Open Hours
        </span>
      ),
      children: (
        <div className="tabs-content">
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: "2.6045vw", margin: "280px 0 0 0" }}
                spin
              />
            }
            size="large"
            spinning={spinner && true}
            delay={500}
          >
            <Form
              form={form}
              autoComplete="off"
              className="w-100"
              onFinish={handleAddClinicOpenHours}
            >
              <Row
                className="w-100 d-flex jc-between"
                style={{ margin: "0.782vw 0 0 0" }}
              >
                <Col>
                  <div className="view-hospital-hours-col">
                    <Row>
                      <span
                        style={{
                          fontSize: "1.0417vw",
                          fontWeight: 600,
                        }}
                      >
                        Clinic Operating Hours
                      </span>
                    </Row>
                    {clinicOpenHours && clinicOpenHours.length > 0 ? (
                      <Row>
                        <Row>
                          <span
                            style={{
                              fontSize: "0.936vw",
                              fontWeight: 500,
                            }}
                          >
                            Click to update opening and closing timings.
                          </span>
                        </Row>
                        <Row className="open-hours-list">
                          {clinicOpenHours &&
                            clinicOpenHours.map((item) => {
                              return (
                                <Row className="clinic-operating-hours-div">
                                  <Col
                                    span={22}
                                    onClick={() => handleClinicOpenHours(item)}
                                    style={{
                                      display: "flex",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Col span={13}>
                                      <span
                                        style={{
                                          fontSize: "0.938vw",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {item.day
                                          ? item.day
                                          : moment(item.from_time).format(
                                              "DD/MM/YYYY"
                                            ) ===
                                            moment(item.to_time).format(
                                              "DD/MM/YYYY"
                                            )
                                          ? moment(item.from_time).format(
                                              "DD/MM/YYYY"
                                            )
                                          : moment(item.from_time).format(
                                              "DD/MM/YYYY"
                                            ) +
                                            " - " +
                                            moment(item.to_time).format(
                                              "DD/MM/YYYY"
                                            )}
                                      </span>
                                    </Col>
                                    <Col>
                                      {item.is_holiday === false ? (
                                        <span
                                          style={{
                                            fontSize: "1.0417vw",
                                            fontWeight: 400,
                                            color: "#676767",
                                          }}
                                        >
                                          Holiday
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: "1.0417vw",
                                            fontWeight: 400,
                                            color: "#676767",
                                          }}
                                        >
                                          {item.day
                                            ? convertTime(item.from_time) +
                                              " - " +
                                              convertTime(item.to_time)
                                            : moment(item.from_time).format(
                                                "hh:mm A"
                                              ) +
                                              " - " +
                                              moment(item.to_time).format(
                                                "hh:mm A"
                                              )}
                                        </span>
                                      )}
                                    </Col>
                                  </Col>
                                  <Col hidden={item.day ? true : false}>
                                    <img
                                      onClick={() => {
                                        handlDeleteClinicOpenHours(item.id);
                                      }}
                                      alt=""
                                      src={deleteIcon}
                                      style={{
                                        width: "1.25vw",
                                        height: "1.4vw",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              );
                            })}
                        </Row>
                      </Row>
                    ) : (
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "5.73vw 0",
                          gap: "1.563vw",
                        }}
                      >
                        <img
                          alt=""
                          src={clinicopenimg}
                          style={{
                            width: "17.865vw",
                            height: "23.365vh",
                          }}
                        />
                        <span
                          style={{
                            width: "22.761vw",
                            textAlign: "center",
                            fontSize: "0.936vw",
                            fontWeight: 400,
                            lineHeight: "140.04%" /* 25.207px */,
                          }}
                        >
                          You haven't added any operating hours for the clinic
                          yet. Once added, they will be displayed here.
                        </span>
                      </Row>
                    )}
                  </div>
                </Col>
                <Col>
                  {clinicOpenHours && clinicOpenHours.length > 0 ? (
                    isUpdateClinicHours ? (
                      handleClinicOpenHoursDiv()
                    ) : isSetOpenHoursClicked ? (
                      handleClinicOpenHoursDiv()
                    ) : (
                      <div
                        className="set-open-hours-col"
                        style={{
                          padding: "7.03125vw 8.855vw",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "1.3023vw",
                        }}
                      >
                        <Row>
                          <img
                            src={addTimerIcon}
                            alt=""
                            style={{
                              width: "13.96vw",
                              height: "10.86vw",
                            }}
                          />
                        </Row>
                        <Row>
                          <span
                            style={{
                              fontSize: "1.0417vw",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "normal",
                            }}
                          >
                            Add a New Time Slot
                          </span>
                        </Row>
                        <Row>
                          <span
                            style={{
                              fontSize: "0.936vw",
                              fontWeight: 500,
                              textAlign: "center",
                            }}
                          >
                            Click the button below to add a new time slot to
                            your clinic's open hours. This allows you to specify
                            additional times when your clinic is available for
                            appointments.
                          </span>
                        </Row>
                        <Row>
                          <Button
                            className="set-open-hour-button"
                            onClick={() => setOpenHourButtonState(true)}
                          >
                            Add New Time Slot
                          </Button>
                        </Row>
                      </div>
                    )
                  ) : isSetOpenHoursClicked ? (
                    handleClinicOpenHoursDiv()
                  ) : (
                    <div
                      className="set-open-hours-col"
                      style={{
                        padding: "8.0729vw 8.855vw",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "1.3023vw",
                      }}
                    >
                      <Row>
                        <img
                          src={timerIcon}
                          alt=""
                          style={{
                            width: "10.417vw",
                            height: "21.08vh",
                          }}
                        />
                      </Row>
                      <Row>
                        <span
                          style={{
                            fontSize: "1.0417vw",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                          }}
                        >
                          No Open Hours Set Yet
                        </span>
                      </Row>
                      <Row>
                        <span
                          style={{
                            fontSize: "0.936vw",
                            fontWeight: 500,
                          }}
                        >
                          It looks like you haven't set the open hours for your
                          clinic yet. Setting open hours ensures that patients
                          know when they can visit your clinic.
                        </span>
                      </Row>
                      <Row>
                        <Button
                          className="set-open-hour-button"
                          onClick={() => setOpenHourButtonState(true)}
                        >
                          Set Open Hours
                        </Button>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <span
          style={{
            fontSize: "0.834vw",
            fontWeight: 400,
          }}
        >
          Doctor Availability
        </span>
      ),
      children: (
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: "2.6045vw", margin: "14.588vw 0 0 0" }}
              spin
            />
          }
          size="large"
          spinning={spinner && true}
          delay={500}
        >
          <div className="tabs-content">
            <Row
              className="w-100"
              style={{ gap: "0.73vw", margin: "0.782vw 0 0 0" }}
            >
              <div className="doctor-details-new w-100">
                <Row
                  className="w-100 jc-between ai-center"
                  style={{
                    padding: "0 1.563vw",
                  }}
                >
                  <Col>
                    <Row>
                      <span
                        style={{
                          fontSize: "1.0417vw",
                          fontWeight: 500,
                        }}
                      >
                        Doctors in the Dental Clinic
                      </span>
                    </Row>
                    <Row>
                      <span
                        style={{
                          fontSize: "0.936vw",
                          fontWeight: 400,
                        }}
                      >
                        View and manage the list of doctors available in the
                        clinic.
                      </span>
                    </Row>
                  </Col>
                  <Col>
                    <Input
                      placeholder="Search By doctor Name"
                      style={{
                        width: "20.261vw",
                        height: "4.986vh",
                        fontSize: "0.834vw",
                        fontWeight: 400,
                        color: "#565656",
                      }}
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    padding: "1.0417vw 0 0 0",
                    borderBottom: "1px solid #E3E3E3",
                  }}
                  className="w-100"
                >
                  <Row
                    style={{ height: "6.232vh", padding: "0 2.0834vw" }}
                    className="w-100 d-flex ai-center"
                  >
                    <Col span={1}></Col>
                    <Col span={5}>
                      <span
                        style={{
                          fontSize: "1.0417vw",
                          fontWeight: 500,
                        }}
                      >
                        NAME ({sampleDoctorData && sampleDoctorData.length})
                      </span>
                    </Col>
                    <Col span={4}>
                      <span
                        style={{
                          fontSize: "1.0417vw",
                          fontWeight: 500,
                        }}
                      >
                        SPECIALITY
                      </span>
                    </Col>
                    <Col span={8}>
                      <span
                        style={{
                          fontSize: "1.0417vw",
                          fontWeight: 500,
                        }}
                      >
                        AVAILABILITY
                      </span>
                    </Col>
                    <Col span={4}>
                      <span
                        style={{
                          fontSize: "1.0417vw",
                          fontWeight: 500,
                        }}
                      >
                        {/* TIME */}
                      </span>
                    </Col>
                    <Col span={2}>
                      {checkedDoctorValues &&
                        checkedDoctorValues.length > 0 && (
                          <Button
                            style={{
                              height: "4.673vh",
                              background: "#EC7063",
                              gap: "0.26vw",
                              display: "flex",
                              borderRadius: 5,
                              alignItems: "center",
                              fontSize: "0.73vw",
                              fontWeight: 600,
                            }}
                            type="danger"
                            onClick={() => {
                              handleDeleteSlots();
                            }}
                          >
                            <img
                              style={{ width: 19 }}
                              alt=""
                              src={deleteicon}
                            />
                            <span>Delete Slots</span>
                          </Button>
                        )}
                    </Col>
                  </Row>
                </Row>
                <div
                  style={{
                    height: "57vh",
                    overflow: "auto",
                  }}
                >
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    onChange={onDoctorChange}
                  >
                    {sampleDoctorData &&
                      sampleDoctorData.map((item, index) => {
                        return (
                          <ClinicDoctors
                            handleGetAllDoctorinClinic={
                              handleGetAllDoctorinClinic
                            }
                            item={item}
                            checkedDoctorValues={checkedDoctorValues}
                          />
                        );
                      })}
                  </Checkbox.Group>
                </div>
              </div>
            </Row>
          </div>
        </Spin>
      ),
    },
    {
      key: "4",
      label: (
        <span
          style={{
            fontSize: "0.834vw",
            fontWeight: 400,
          }}
        >
          Public Holiday Management {contextHolder}
        </span>
      ),
      children: (
        <div className="tabs-content">
          <Row
            className="w-100"
            style={{ gap: "0.73vw", margin: "0.782vw 0 0 0" }}
          >
            <div className="public-holiday-div w-100">
              <Row
                className="w-100 jc-between ai-center"
                style={{
                  padding: "0 1vw",
                }}
              >
                <Col>
                  <Row>
                    <span
                      style={{
                        fontSize: "1.0417vw",
                        fontWeight: 500,
                      }}
                    >
                      Select Holiday Dates
                    </span>
                  </Row>
                  <Row>
                    <span
                      style={{
                        fontSize: "0.936vw",
                        fontWeight: 400,
                      }}
                    >
                      Choose and mark holiday dates effortlessly for streamlined
                      clinic scheduling.
                    </span>
                  </Row>
                </Col>
              </Row>
              <Row
                className="h-100"
                style={{
                  padding: "0 20px",
                }}
              >
                <Col span={15}>
                  <Button
                    style={{ margin: "7px 0 0 0" }}
                    onClick={handleTodayClick}
                  >
                    Today
                  </Button>
                  <Calendar
                    disabledDate={disabledDate}
                    value={displayDate}
                    className="public-calendar"
                    onSelect={onSelect}
                    dateCellRender={dateCellRender}
                    mode="month"
                    onPanelChange={onPanelChange}
                    defaultValue={moment()}
                    validRange={validRange}
                  />
                </Col>
                <Col span={9}>
                  <Row
                    style={{ padding: "4.1710114702815435vh 0 0 3.125vw" }}
                    className="w-100"
                  >
                    <span
                      style={{
                        fontSize: "0.8333333333333334vw",
                        fontWeight: 500,
                      }}
                    >
                      Event
                    </span>
                  </Row>
                  <Row style={{ padding: "0 0 0 3.125vw" }}>
                    <Col span={20}>
                      <Input
                        style={{
                          width: "97%",
                          height: "6.360792492179353vh",
                          borderRadius: 3,
                          border: "1px solid #AEAEAE",
                          color: "#000",
                          fontSize: "1.0416666666666667vw",
                          fontWeight: 400,
                        }}
                        type="text"
                        // const isDisabled = selectedDate === null || moment(selectedDate).isBefore(moment(), 'day');
                        // disabled={selectedDate === null}
                        disabled={
                          selectedDate === null ||
                          moment(selectedDate).isBefore(moment(), "day")
                        }
                        value={inputValue}
                        onChange={onInputChange}
                        placeholder={
                          selectedDate === null
                            ? `Select Date`
                            : `Enter data for ${
                                selectedDate &&
                                selectedDate.format("YYYY-MM-DD")
                              }`
                        }
                        autoFocus
                        // onKeyPress={(event) => {
                        //   if (event.key === "Enter") {
                        //     if (defaultValue.eventName === "") {
                        //       createCalendarEvents();
                        //     } else {
                        //       handleUpdatePublicHolidays();
                        //     }
                        //   }
                        // }}
                      />
                    </Col>
                    <Col span={4}>
                      <Button
                        onClick={() => {
                          if (defaultValue.eventName === "") {
                            createCalendarEvents();
                          } else {
                            handleUpdatePublicHolidays();
                          }
                        }}
                        disabled={inputValue === "" || selectedDate === null}
                        style={{
                          height: "6.360792492179353vh",
                          width: "100%",
                          borderRadius: 3,
                          border: "1px solid #AEAEAE",
                          fontSize: "0.8333333333333334vw",
                        }}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      padding:
                        "4.1710114702815435vh 0 4.1710114702815435vh 3.125vw",
                    }}
                    className="w-100"
                  >
                    <Row className="w-100 d-flex jc-between ai-center">
                      <span
                        style={{
                          fontSize: "0.8333333333333334vw",
                          fontWeight: 500,
                        }}
                      >
                        All Events
                      </span>
                      {!cancelButton && (
                        <CloseCircleFilled
                          style={{
                            cursor: "pointer",
                            fontSize: "0.8333333333333334vw",
                          }}
                          onClick={() => {
                            setdefaultValue({ eventName: "" });
                            getCalendarEvents(
                              currentDate.year,
                              currentDate.month
                            );
                          }}
                        />
                      )}
                    </Row>
                    <div
                      style={{ height: "45vh", overflow: "auto" }}
                      className="w-100"
                    >
                      {selectedInputValue && selectedInputValue.length > 0 ? (
                        selectedInputValue.map((item) => {
                          return (
                            <div
                              style={{
                                background: "#E0F4FF",
                                borderLeft: "3px solid #3498DB",
                                padding: "0 0 0 5px",
                                height: "6.777893639207508vh",
                                margin: "10px 0 0 0",
                                cursor: item.clinic_id
                                  ? !cancelButton && "pointer"
                                  : "",
                              }}
                              className="w-100 d-flex ai-center"
                              onClick={() => {
                                if (item.clinic_id) {
                                  setInputValue(item.event_name);
                                  setdefaultValue({
                                    eventDate: moment(item.event_date).format(
                                      "YYYY-MM-DD"
                                    ),
                                    eventName: item.event_name,
                                    year: moment(item.event_date).format(
                                      "YYYY"
                                    ),
                                    month: moment(item.event_date).format("MM"),
                                  });
                                }
                              }}
                            >
                              <Row className="w-100 d-flex ai-center">
                                <Col
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "10px",
                                  }}
                                  span={3}
                                >
                                  <Row>
                                    <span
                                      style={{
                                        fontSize: "1.25vw",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {moment(item.event_date).format("DD")}
                                    </span>
                                  </Row>
                                  <Row style={{ margin: "-8px 0 0 0" }}>
                                    <span
                                      style={{
                                        fontSize: "0.7291666666666666vw",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {moment(item.event_date).format("MMM")}
                                    </span>
                                  </Row>
                                </Col>
                                <Col
                                  style={{
                                    padding: "0 10px",
                                  }}
                                  span={18}
                                >
                                  <span
                                    style={{
                                      fontSWize: "1.0416666666666667vw",
                                      fontWeight: 400,
                                      color: "#676767",
                                    }}
                                  >
                                    {item.event_name}
                                  </span>
                                </Col>
                                <Col span={3}>
                                  {item.clinic_id && (
                                    <img
                                      alt=""
                                      src={calendardeleteicon}
                                      style={{
                                        width: "1.09375vw",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => {
                                        deleteCalendarEvents(item.event_name);
                                        e.stopPropagation();
                                      }}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      ) : (
                        <div className="w-100">No data</div>
                      )}
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <span
          style={{
            fontSize: "0.834vw",
            fontWeight: 400,
          }}
        >
          Staffs Time off Requests
        </span>
      ),
      children: (
        <div
          style={{
            padding: "0 2.0834vw",
            margin: "0.782vw 0 0 0",
          }}
        >
          <div className="staff-time-off-requests w-100">
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.417vw",
              }}
            >
              <span className="staff-time-off-header">
                Staffs Time off Requests
              </span>
              <span className="staff-time-off-desc">
                Review and manage pending staff time off requests, using the
                available options to approve or reject as needed
              </span>
            </Row>
            <Row>
              <Tabs
                onChange={() => {
                  // handleGetAllAppointmentsForClinic();
                }}
                className="staff-time-off-tabs"
                items={timeOffItems}
              />
            </Row>
            <Row
              style={{
                position: "absolute",
                justifyContent: "flex-end",
                right: "3.75vw",
                top: "7vw",
              }}
            >
              <Col
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "0.52vw",
                }}
              >
                <span
                  style={{
                    fontSize: "0.834vw",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "140.04 %" /* 22.406px */,
                  }}
                >
                  Filters:
                </span>
                <Select
                  allowClear
                  className="staff-select"
                  placeholder="By Staff"
                  options={staffOptions}
                  onChange={(value) => handleSelectChange("designation", value)}
                  showSearch
                ></Select>
                <Select
                  allowClear
                  className="date-select"
                  placeholder="By Date"
                  options={dateOptions}
                  onChange={(value) => handleSelectChange("date", value)}
                  showSearch
                ></Select>
              </Col>
            </Row>
          </div>
          <Modal
            centered
            width={"26.46vw"}
            height={"33.85vw"}
            open={isDateSelectModal}
            closable={false}
            footer={false}
            className="open-calendar-modal"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <CloseOutlined
                style={{
                  color: "#EC7063",
                  fontSize: "1.25vw",
                  margin: "-0.63vw -2.3vw",
                }}
                onClick={() => {
                  setDateSelectModal(false);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.0417vw",
              }}
            >
              <Row>
                <span
                  style={{
                    fontSize: "1.0417vw",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "140.04%",
                  }}
                >
                  Choose Date from the Calendar
                </span>
              </Row>
              <Row>
                <Calendar
                  className="treatment-calendar"
                  fullscreen={false}
                  headerRender={({ value, type, onChange, onTypeChange }) => {
                    const start = 0;
                    const end = 12;
                    const monthOptions = [];
                    let current = value.clone();
                    const localeData = value.localeData();
                    const months = [];
                    for (let i = 0; i < 12; i++) {
                      current = current.month(i);
                      months.push(localeData.monthsShort(current));
                    }
                    for (let i = start; i < end; i++) {
                      monthOptions.push(
                        <Select.Option key={i} value={i} className="month-item">
                          {months[i]}
                        </Select.Option>
                      );
                    }
                    const year = value.year();
                    const month = value.month();
                    const options = [];
                    for (let i = year - 10; i < year + 10; i += 1) {
                      options.push(
                        <Select.Option key={i} value={i} className="year-item">
                          {i}
                        </Select.Option>
                      );
                    }
                    return (
                      <div
                        style={{
                          padding: "0.417vw",
                        }}
                      >
                        <Row gutter={8}>
                          <Col>
                            <Select
                              size="small"
                              dropdownMatchSelectWidth={false}
                              className="my-year-select"
                              value={year}
                              onChange={(newYear) => {
                                const now = value.clone().year(newYear);
                                onChange(now);
                              }}
                            >
                              {options}
                            </Select>
                          </Col>
                          <Col>
                            <Select
                              size="small"
                              dropdownMatchSelectWidth={false}
                              value={month}
                              onChange={(newMonth) => {
                                const now = value.clone().month(newMonth);
                                onChange(now);
                              }}
                            >
                              {monthOptions}
                            </Select>
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                  onSelect={onDateSelect}
                  onPanelChange={onPanelChange}
                />
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  className="cal-apply-button"
                  onClick={handleApplyButton}
                >
                  Apply
                </Button>
              </Row>
            </div>
          </Modal>
        </div>
      ),
    },
  ];

  const filteredItems = items.filter((item) => {
    if (role === "CLINIC_USER") {
      return item.key === "1";
    }
    if (role === "CLINIC_ADMIN") {
      // return item.key !== "2";
    }
    return true;
  });

  return (
    <div
      style={{
        height: "92.55vh",
        width: "100vw",
        // padding: "0 10.4167vw",
        overflow: "auto",
      }}
    >
      <Spin
        className="spinner-container"
        indicator={<LoadingOutlined style={{ fontSize: "3.65vw" }} spin />}
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <Tabs
          defaultActiveKey="1"
          items={filteredItems}
          onChange={onChange}
          className="tabs-list"
        />
        <ClinicBranchModal
          handleAddClinicBranch={handleAddClinicBranch}
          addClinicBranchModal={addClinicBranchModal}
          clinicDetails={clinicDetails}
          setaddClinicBranchModal={setaddClinicBranchModal}
          setEnableForm={setEnableForm}
          enableForm={enableForm}
          form={form}
        />
        <ClinicTreatmentsModal
          handleAddNewTreatmentType={handleAddNewTreatmentType}
          addTreatmentModal={addTreatmentModal}
          clinicDetails={clinicDetails}
          setaddTreatmentModal={setaddTreatmentModal}
          setEnableForm={setEnableForm}
          enableForm={enableForm}
          form={form}
        />
        <Modal
          width={"60.5632vw"}
          centered
          open={editClinicModal}
          footer={false}
          closable={false}
          destroyOnClose={true}
        >
          {clinicDetails && (
            <Form
              form={form}
              onFinish={handleUpdateClinicDetails}
              className="w-100"
            >
              <div style={{ padding: "1.0417vw" }}>
                <Row>
                  <span
                    style={{
                      color: "#3498DB",
                      fontSize: "1.25vw",
                      fontWeight: 500,
                    }}
                  >
                    Edit Clinic
                  </span>
                </Row>

                {/* Hospital info */}
                <Row style={{ margin: "1.563vw 0 0 0" }}>
                  <span
                    style={{
                      color: "#333333",
                      fontSize: "0.834vw",
                      fontWeight: 700,
                    }}
                  >
                    Hospital Infomation
                  </span>
                </Row>
                {clinicDetails && (
                  <Row
                    className="d-flex jc-between"
                    style={{ margin: "1.302vw 0" }}
                  >
                    <Col span={8}>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontSize: "0.73vw",
                            fontWeight: 400,
                            color: "#2F2F2F",
                          }}
                        >
                          Clinic Name{" "}
                          <span style={{ color: "#FF0000" }}>*</span>
                        </span>
                        <Form.Item
                          style={{ width: "100%" }}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter clinic name",
                            },
                          ]}
                          initialValue={clinicDetails.name}
                        >
                          <Input
                            style={{
                              width: "16.771vw",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                              color: "black",
                            }}
                          />
                        </Form.Item>
                      </Row>
                    </Col>
                    <Col
                      span={8}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontSize: "0.73vw",
                            fontWeight: 400,
                            color: "#2F2F2F",
                          }}
                        >
                          Clinic Identification{" "}
                          <span style={{ color: "#FF0000" }}>*</span>
                        </span>
                        <Input
                          value={"CID #" + clinicDetails.clinic_id}
                          disabled={enableForm}
                          style={{
                            color: "black",
                            width: "16.771vw",
                            margin: "0.26vw 0 0 0",
                            height: "2.0834vw",
                            borderRadius: 3,
                            border: "1px solid #DADADA",
                          }}
                        />
                      </Row>
                    </Col>
                    <Col
                      span={8}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontSize: "0.73vw",
                            fontWeight: 400,
                            color: "#2F2F2F",
                          }}
                        >
                          Email <span style={{ color: "#FF0000" }}>*</span>
                        </span>
                        <Form.Item
                          style={{ width: "100%" }}
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email",
                            },
                          ]}
                          initialValue={clinicDetails.email}
                        >
                          <Input
                            style={{
                              width: "16.771vw",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                              color: "black",
                            }}
                          />
                        </Form.Item>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row className="d-flex jc-between">
                  <Col span={8}>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                          color: "#2F2F2F",
                        }}
                      >
                        Phone no. <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Space.Compact
                        className="w-100"
                        style={{
                          width: "16.771vw",
                        }}
                      >
                        <Form.Item
                          name="phone_country_code"
                          initialValue={clinicDetails.phone_country_code}
                        >
                          <Select
                            bordered={false}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "2.0834vw",
                              background: "#FFFFFF",
                              border: "1px solid #E3E3E3",
                              margin: "3px 0 0 0",
                            }}
                            options={options}
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ width: "100%" }}
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter phone number",
                            },
                            { validator: validatePhoneNumber },
                          ]}
                          initialValue={clinicDetails.phone_number}
                        >
                          <Input
                            bordered={false}
                            style={{
                              height: "2.0834vw",
                              background: "#FFFFFF",
                              borderTop: "1px solid #E3E3E3",
                              borderRight: "1px solid #E3E3E3",
                              borderBottom: "1px solid #E3E3E3",
                              margin: "3px 0 0 0",
                            }}
                            onKeyPress={(e) => {
                              const charCode = e.which || e.keyCode;
                              if (!(charCode >= 48 && charCode <= 57)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Row>
                  </Col>
                </Row>

                {/* Branch address */}
                <Row style={{ margin: "1.563vw 0 0 0" }}>
                  <span
                    style={{
                      color: "#333333",
                      fontSize: "0.834vw",
                      fontWeight: 700,
                    }}
                  >
                    Branch Address
                  </span>
                </Row>
                <Row style={{ margin: "1.302vw 0" }}>
                  <Col span={8}>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                          color: "#2F2F2F",
                        }}
                      >
                        Apartment <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="address_line_one"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Apartment",
                          },
                        ]}
                        initialValue={clinicDetails.address_line_one}
                      >
                        <Input
                          style={{
                            width: "16.771vw",
                            margin: "0.26vw 0 0 0",
                            height: "2.0834vw",
                            borderRadius: 3,
                            border: "1px solid #DADADA",
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                          color: "#2F2F2F",
                        }}
                      >
                        Address <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="address_line_two"
                        rules={[
                          {
                            required: true,
                            message: "Please enter address",
                          },
                        ]}
                        initialValue={clinicDetails.address_line_two}
                      >
                        <Input
                          style={{
                            width: "16.771vw",
                            margin: "0.26vw 0 0 0",
                            height: "2.0834vw",
                            borderRadius: 3,
                            border: "1px solid #DADADA",
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                          color: "#2F2F2F",
                        }}
                      >
                        City <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="city"
                        rules={[
                          { required: true, message: "Please enter City" },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and City must contain only alphabeticals",
                          },
                        ]}
                        initialValue={clinicDetails.city}
                      >
                        <Input
                          style={{
                            width: "16.771vw",
                            margin: "0.26vw 0 0 0",
                            height: "2.0834vw",
                            borderRadius: 3,
                            border: "1px solid #DADADA",
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                          color: "#2F2F2F",
                        }}
                      >
                        State / Province{" "}
                        <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="province"
                        rules={[
                          {
                            required: true,
                            message: "Please enter State / Province",
                          },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and State/Province must contain only alphabeticals",
                          },
                        ]}
                        initialValue={clinicDetails.province}
                      >
                        <Input
                          style={{
                            width: "16.771vw",
                            margin: "0.26vw 0 0 0",
                            height: "2.0834vw",
                            borderRadius: 3,
                            border: "1px solid #DADADA",
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                          color: "#2F2F2F",
                        }}
                      >
                        Country <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Country",
                          },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and Country must contain only alphabeticals",
                          },
                        ]}
                        initialValue={clinicDetails.country}
                      >
                        <Input
                          style={{
                            width: "16.771vw",
                            margin: "0.26vw 0 0 0",
                            height: "2.0834vw",
                            borderRadius: 3,
                            border: "1px solid #DADADA",
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "0.73vw",
                          fontWeight: 400,
                          color: "#2F2F2F",
                        }}
                      >
                        ZIP Code <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="zip_code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Zip code",
                          },
                          { validator: validateZipCode },
                        ]}
                        initialValue={clinicDetails.zip_code}
                      >
                        <Input
                          style={{
                            width: "16.771vw",
                            margin: "0.26vw 0 0 0",
                            height: "2.0834vw",
                            borderRadius: 3,
                            border: "1px solid #DADADA",
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>

                {/*  */}
                <Row>
                  <Col>
                    <Row style={{ margin: "1.563vw 0 0 0" }}>
                      <span
                        style={{
                          color: "#333333",
                          fontSize: "0.834vw",
                          fontWeight: 700,
                        }}
                      >
                        Bank Details
                      </span>
                    </Row>
                    <Row
                      style={{ gap: "58px", padding: "20px 0" }}
                      className="d-flex"
                    >
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          gap: 20,
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            Account Name{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                          </span>

                          <Form.Item
                            name="account_name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Account Name",
                              },
                              // {
                              //   pattern: /^[A-Za-z][A-Za-z\s]*$/,
                              //   message:
                              //     "First character should be alphabetical and State/Province must contain only alphabeticals",
                              // },
                            ]}
                            initialValue={
                              clinicDetails.account_name &&
                              clinicDetails.account_name
                            }
                          >
                            <Input
                              // disabled={enableForm}
                              style={{
                                width: "16.771vw",
                                color: "black",
                                margin: "0.26vw 0 0 0",
                                height: "2.0834vw",
                                borderRadius: 3,
                                border: "1px solid #DADADA",
                              }}
                            />
                          </Form.Item>
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            IFSC Code{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                          </span>

                          <Form.Item
                            name="ifsc_code"
                            rules={[
                              {
                                required: true,
                                message: "Please enter IFSC Code",
                              },
                              // {
                              //   pattern: /^[A-Za-z][A-Za-z\s]*$/,
                              //   message:
                              //     "First character should be alphabetical and State/Province must contain only alphabeticals",
                              // },
                            ]}
                            initialValue={clinicDetails.ifsc_code}
                          >
                            <Input
                              // disabled={enableForm}
                              style={{
                                width: "16.771vw",
                                margin: "0.26vw 0 0 0",
                                color: "black",
                                height: "2.0834vw",
                                borderRadius: 3,
                                border: "1px solid #DADADA",
                              }}
                            />
                          </Form.Item>
                        </Row>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          gap: 36,
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            Account No.{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          <Form.Item
                            name="confirm_account_no"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Account No",
                              },
                              // {
                              //   pattern: /^[A-Za-z][A-Za-z\s]*$/,
                              //   message:
                              //     "First character should be alphabetical and State/Province must contain only alphabeticals",
                              // },
                            ]}
                            initialValue={clinicDetails.confirm_account_no}
                          >
                            <Input
                              // disabled={enableForm}
                              style={{
                                color: "black",
                                width: "16.771vw",
                                margin: "0.26vw 0 0 0",
                                height: "2.0834vw",
                                borderRadius: 3,
                                border: "1px solid #DADADA",
                              }}
                            />
                          </Form.Item>
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {/* <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                              }}
                              >
                              Beneficiary Name <span style={{ color: "#FF0000" }}>*</span>
                              </span>
                              <Input
                              value={clinicDetails.zip_code}
                            disabled={enableForm}
                            style={{
                              width: "16.771vw",
                              color: "black",
                              margin: "0.26vw 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                              }}
                          /> */}
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                            }}
                          >
                            Confirm Account No.*{" "}
                            <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          {/* <TextArea
                        value={
                          clinicDetails.address_line_one +
                          `, ` +
                          clinicDetails.address_line_two +
                          `, ` +
                          clinicDetails.city +
                          `, ` +
                          clinicDetails.province +
                          `, ` +
                          clinicDetails.country +
                          `, ` +
                          clinicDetails.zip_code
                          }
                          disabled={enableForm}
                          style={{
                            color: "black",
                            width: "24.532vw",
                            margin: "0.26vw 0 0 0",
                            height: "13.6vh",
                            borderRadius: 3,
                            border: "1px solid #DADADA",
                            }}
                      /> */}
                          <Form.Item
                            name="confirm_account_no"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Account No",
                              },
                              // {
                              //   pattern: /^[A-Za-z][A-Za-z\s]*$/,
                              //   message:
                              //     "First character should be alphabetical and State/Province must contain only alphabeticals",
                              // },
                            ]}
                            initialValue={clinicDetails.confirm_account_no}
                          >
                            <Input
                              // value={clinicDetails.confirm_account_no}
                              // disabled={enableForm}
                              style={{
                                color: "black",
                                width: "16.771vw",
                                margin: "0.26vw 0 0 0",
                                height: "2.0834vw",
                                borderRadius: 3,
                                border: "1px solid #DADADA",
                              }}
                            />
                          </Form.Item>
                        </Row>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "space-around",
                          // alignItems: "center",
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        ></Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    <Row
                      className="d-flex jc-end ai-center"
                      style={{ gap: "0.63vw", margin: "1.302vw 0 0 0" }}
                    >
                      <Form.Item>
                        <Button
                          className="cancel-btn"
                          onClick={() => {
                            form.resetFields();
                            setEditClinicModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          style={{
                            height: "3.116vh",
                            border: "1px solid #2CA3FA",
                            borderRadius: "1.563vw",
                            color: "#2CA3FA",
                            background: "#FFF",
                            fontSize: "0.73vw",
                            fontWeight: 600,
                          }}
                          htmlType="submit"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Modal>
      </Spin>
    </div>
  );
};

export default ClinicMyAccount;
