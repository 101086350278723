import { Button, Col, Row, Spin, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import "./MlAnalysis.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import API_BASE_URL from "../../../../env";
import ClinicalReportForm from "./clinical_report_form/ClinicalReportForm";
import { useLocation, useNavigate, useParams } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import UploafXrayModal from "../my_account/upload_xray_modal/UploafXrayModal";
import ReportView from "./report_view/ReportView";
import { UserAuth } from "../../../../context_api/ContextApi";

const MlAnalysis = () => {
  const patientid = localStorage.getItem("patient_id");
  const Token = localStorage.getItem("Token");
  const { setRectangles, spinner, setSpinner } = UserAuth();
  const { patient_id, index, appointment_id } = useParams();
  const navigate = useNavigate();
  const [reportUploadType, setreportUploadType] = useState();
  const [isOpenUploadXrayModal, setIsOpenUploadXrayModal] = useState(false);
  const location = useLocation();
  const key = location.pathname;
  const [disableFormItems, setDisableFormItems] = useState(false);
  const [allReportDetails, setallReportDetails] = useState();
  const [allClinicalReports, setAllClinicalReports] = useState();
  const [appointmentData, setappointmentData] = useState();

  const handleGetAllReportDetails = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/report/patientClinics/${
          patient_id ? patient_id : patientid
        }`,
        config
      )
      .then((res) => {
        setallReportDetails(res.data.data.all_responses[0]);
        setSpinner(false);
      })
      .catch((err) => {
        message.error("error occured");
        setSpinner(false);
      });
  };

  const handleGetAppointmentDetails = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (appointment_id) {
      await axios
        .get(`${API_BASE_URL}/api/v1/appointment/${appointment_id}`, config)
        .then((res) => {
          setappointmentData(res.data.data);
          // setallReportDetails(res.data.data.all_responses[0]);
          setSpinner(false);
        })
        .catch((err) => {
          message.error("error occured");
          setSpinner(false);
        });
    }
  };

  const handleGetAllClinicalReportForms = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/report/reportForms/${
          patient_id ? patient_id : patientid
        }`,
        config
      )
      .then((res) => {
        setAllClinicalReports(res.data.data);
      })
      .catch((err) => {
        message.error("error occured");
      });
  };

  useEffect(() => {
    handleGetAllReportDetails();
  }, []);

  useEffect(() => {
    handleGetAllClinicalReportForms();
  }, []);

  useEffect(() => {
    handleGetAppointmentDetails();
  }, []);

  const currentUrl = window.location.href;

  const handleGoBack = () => {
    setRectangles("");
    navigate(-1);
  };

  const onChange = (key) => {};

  // items for patient to view the report
  const items = [
    {
      key: "1",
      label: "X-Ray Report",
      children: (
        <ReportView
          allReportDetails={
            allReportDetails &&
            index &&
            allReportDetails[index].value1.filter(
              (item) => item.report_type === "xrays"
            )
          }
          type={"xrays"}
        />
      ),
    },
    {
      key: "2",
      label: "Intra Oral Report",
      children: (
        <ReportView
          allReportDetails={
            allReportDetails &&
            index &&
            allReportDetails[index].value1.filter(
              (item) => item.report_type === "intra-oral-reports"
            )
          }
          type={"intra-oral-reports"}
        />
      ),
    },
    {
      key: "3",
      label: "Clinical Report",
      children: (
        <ClinicalReportForm
          allReportDetails={allReportDetails && index && allReportDetails}
          allClinicalReports={allClinicalReports}
          handleGetAllClinicalReportForms={handleGetAllClinicalReportForms}
        />
      ),
    },
    {
      key: "4",
      label: "Miscellaneous Report",
      children: (
        <ReportView
          allReportDetails={
            allReportDetails &&
            index &&
            allReportDetails[index].value1.filter(
              (item) => item.report_type === "miscellaneous-reports"
            )
          }
          type={"miscellaneous-reports"}
        />
      ),
    },
  ];

  // items for clinic to view the report
  const clinicitems = [
    {
      key: "1",
      label: "X-Ray Report",
      children: (
        <>
          {currentUrl.includes("patient") ? (
            <ReportView
              allReportDetails={
                allReportDetails &&
                index &&
                allReportDetails[index].value1.filter(
                  (item) => item.report_type === "xrays"
                )
              }
              type={"xrays"}
            />
          ) : (
            <ReportView
              allReportDetails={
                allReportDetails &&
                index &&
                allReportDetails
                  .filter(
                    (item) =>
                      item.key.clinicId == localStorage.getItem("clinic_id")
                  )
                  [index].value1.filter((item) => item.report_type === "xrays")
              }
              type={"xrays"}
            />
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Intra Oral Report",
      children: (
        <>
          {currentUrl.includes("patient") ? (
            <ReportView
              allReportDetails={
                allReportDetails &&
                index &&
                allReportDetails[index].value1.filter(
                  (item) => item.report_type === "intra-oral-reports"
                )
              }
              type={"intra-oral-reports"}
            />
          ) : (
            <ReportView
              allReportDetails={
                allReportDetails &&
                index &&
                allReportDetails
                  .filter(
                    (item) =>
                      item.key.clinicId == localStorage.getItem("clinic_id")
                  )
                  [index].value1.filter(
                    (item) => item.report_type === "intra-oral-reports"
                  )
              }
              type={"intra-oral-reports"}
            />
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "Clinical Report",
      children: (
        <>
          {currentUrl.includes("patient") ? (
            <ClinicalReportForm
              allReportDetails={allReportDetails && index && allReportDetails}
              allClinicalReports={allClinicalReports}
              handleGetAllClinicalReportForms={handleGetAllClinicalReportForms}
            />
          ) : (
            <ClinicalReportForm
              allReportDetails={
                allReportDetails &&
                index &&
                allReportDetails.filter(
                  (item) =>
                    item.key.clinicId == localStorage.getItem("clinic_id")
                )
              }
              allClinicalReports={allClinicalReports}
              handleGetAllClinicalReportForms={handleGetAllClinicalReportForms}
            />
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "Miscellaneous Report",
      children: (
        <>
          {currentUrl.includes("patient") ? (
            <ReportView
              allReportDetails={
                allReportDetails &&
                index &&
                allReportDetails[index].value1.filter(
                  (item) => item.report_type === "miscellaneous-reports"
                )
              }
              type={"miscellaneous-reports"}
            />
          ) : (
            <ReportView
              allReportDetails={
                allReportDetails &&
                index &&
                allReportDetails
                  .filter(
                    (item) =>
                      item.key.clinicId == localStorage.getItem("clinic_id")
                  )
                  [index].value1.filter(
                    (item) => item.report_type === "miscellaneous-reports"
                  )
              }
              type={"miscellaneous-reports"}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <div className="ml-analysis-main-div">
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: 50, margin: "20vh 0 0 0" }}
            spin
          />
        }
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <Helmet>
          <title>ML Analysis</title>
        </Helmet>
        <Row
          className="d-flex ai-center jc-between"
          style={{ margin: "12px 0" }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Reports
          </span>
          {key !== "/patient/ml-analysis" && (
            <Button
              className="go-back-btn"
              onClick={() => {
                handleGoBack();
              }}
            >
              Go Back
            </Button>
          )}
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "transparent",
            }}
          >
            View Reports
          </span>
        </Row>
        {(key === "/patient/ml-analysis" ||
          currentUrl.includes("clinic-user/reports") ||
          currentUrl.includes("clinic-admin/reports")) && (
          <Row
            className="w-100"
            style={{
              background: "#FFF",
              padding: "10px 33px",
            }}
          >
            {key === "/patient/ml-analysis" ? (
              <Tabs className="w-100" defaultActiveKey="1">
                <Tabs.TabPane tab="View Reports" key="1">
                  {allReportDetails && allReportDetails.length > 0 && (
                    <Row
                      style={{
                        background: "#F4F4F4",
                        width: "100%",
                        padding: "10px 20px",
                        margin: "5px 0",
                      }}
                    >
                      <Col span={1}></Col>
                      <Col span={5}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#7D7D7D",
                          }}
                        >
                          Date
                        </span>
                      </Col>
                      <Col span={5}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#7D7D7D",
                          }}
                        >
                          Clinic id
                        </span>
                      </Col>
                      <Col span={13}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#7D7D7D",
                          }}
                        >
                          Uploaded by
                        </span>
                      </Col>
                    </Row>
                  )}
                  {allReportDetails && allReportDetails.length > 0 ? (
                    allReportDetails.map((item, index) => {
                      return (
                        <Row
                          style={{
                            background: "#FBFBFB",
                            width: "100%",
                            padding: "15px 20px",
                            margin: "0 0 5px 0",
                          }}
                        >
                          <Col span={1}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#7D7D7D",
                              }}
                            >
                              {index + 1}
                            </span>
                          </Col>
                          <Col span={5}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#7D7D7D",
                              }}
                            >
                              {item.key.date}
                            </span>
                          </Col>
                          <Col span={5}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#7D7D7D",
                              }}
                            >
                              {item.key.clinicId ? item.key.clinicId : "NA"}
                            </span>
                          </Col>
                          <Col span={11}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#7D7D7D",
                              }}
                            >
                              {item.key.clinicId === null
                                ? "Self"
                                : item.key.clinicName}
                            </span>
                          </Col>
                          <Col span={2}>
                            <Button
                              style={{
                                background: "#FFFFFF",
                                border: "1px solid #97C46F",
                                borderRadius: 30,
                                fontWeight: 600,
                                fontSize: 14,
                                color: "#525252",
                              }}
                              onClick={() => {
                                navigate(`/patient/view-report/${index}`);
                              }}
                            >
                              <Row
                                className="d-flex ai-center"
                                style={{ gap: 7 }}
                              >
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "14PX",
                                    color: "#525252",
                                  }}
                                >
                                  View Report
                                </span>
                              </Row>
                            </Button>
                          </Col>
                        </Row>
                      );
                    })
                  ) : (
                    <div className="w-100">
                      <Row
                        className="d-flex jc-center"
                        style={{
                          margin: "0 22px",
                          padding: "50px 0",
                          background: "#FFF",
                          fontWeight: 600,
                          fontSize: 20,
                        }}
                      >
                        Reports Not available
                      </Row>
                    </div>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Upload reports" key="2">
                  <div
                    style={{
                      width: "100%",
                      height: "70vh",
                    }}
                    className="d-flex jc-center ai-center"
                  >
                    <div
                      style={{
                        borderRadius: 5,
                        border: "1px solid #E0E0E0",
                        padding: 40,
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                      }}
                    >
                      <Row
                        style={{
                          padding: "15px 32px",
                          borderRadius: 5,
                          width: "50vw",
                          background: "#F9F9F9",
                        }}
                        className="d-flex jc-between ai-center"
                      >
                        <Col>Xray Report</Col>
                        <Col>
                          <Button
                            onClick={() => {
                              setreportUploadType("XRAY");
                              setIsOpenUploadXrayModal(true);
                            }}
                          >
                            Upload
                          </Button>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          padding: "15px 32px",
                          borderRadius: 5,
                          width: "50vw",
                          background: "#F9F9F9",
                        }}
                        className="d-flex jc-between ai-center"
                      >
                        <Col>Intra Oral Report</Col>
                        <Col>
                          <Button
                            onClick={() => {
                              setreportUploadType("INTRA_ORAL_REPORTS");
                              setIsOpenUploadXrayModal(true);
                            }}
                          >
                            Upload
                          </Button>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          padding: "15px 32px",
                          borderRadius: 5,
                          width: "50vw",
                          background: "#F9F9F9",
                        }}
                        className="d-flex jc-between ai-center"
                      >
                        <Col>Misc Report</Col>
                        <Col>
                          <Button
                            onClick={() => {
                              setreportUploadType("MISCELLANEOUS_REPORTS");
                              setIsOpenUploadXrayModal(true);
                            }}
                          >
                            Upload
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            ) : (
              <>
                {allReportDetails &&
                  allReportDetails.filter(
                    (item) =>
                      item.key.clinicId == localStorage.getItem("clinic_id")
                  ).length > 0 && (
                    <Row
                      style={{
                        background: "#F4F4F4",
                        width: "100%",
                        padding: "10px 20px",
                        margin: "5px 0",
                      }}
                    >
                      <Col span={1}></Col>
                      <Col span={5}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#7D7D7D",
                          }}
                        >
                          Date
                        </span>
                      </Col>
                      <Col span={5}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#7D7D7D",
                          }}
                        >
                          Clinic id
                        </span>
                      </Col>
                      <Col span={13}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#7D7D7D",
                          }}
                        >
                          Uploaded by
                        </span>
                      </Col>
                    </Row>
                  )}
                {allReportDetails &&
                allReportDetails.filter(
                  (item) =>
                    item.key.clinicId == localStorage.getItem("clinic_id")
                ).length > 0 ? (
                  allReportDetails
                    .filter(
                      (item) =>
                        item.key.clinicId == localStorage.getItem("clinic_id")
                    )
                    .map((item, index) => {
                      return (
                        <Row
                          style={{
                            background: "#FBFBFB",
                            width: "100%",
                            padding: "15px 20px",
                            margin: "0 0 5px 0",
                          }}
                        >
                          <Col span={1}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#7D7D7D",
                              }}
                            >
                              {index + 1}
                            </span>
                          </Col>
                          <Col span={5}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#7D7D7D",
                              }}
                            >
                              {item.key.date}
                            </span>
                          </Col>
                          <Col span={5}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#7D7D7D",
                              }}
                            >
                              {item.key.clinicId ? item.key.clinicId : "NA"}
                            </span>
                          </Col>
                          <Col span={11}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#7D7D7D",
                              }}
                            >
                              {item.key.clinicId === null
                                ? "Self"
                                : item.key.clinicName}
                            </span>
                          </Col>
                          <Col span={2}>
                            <Button
                              style={{
                                background: "#FFFFFF",
                                border: "1px solid #97C46F",
                                borderRadius: 30,
                                fontWeight: 600,
                                fontSize: 14,
                                color: "#525252",
                              }}
                              onClick={() => {
                                if (currentUrl.includes("patient")) {
                                  navigate(`/patient/view-report/${index}`);
                                }
                                if (currentUrl.includes("clinic-user")) {
                                  navigate(
                                    `/clinic-user/view-report/${patient_id}/${index}`
                                  );
                                }
                                if (currentUrl.includes("clinic-admin")) {
                                  navigate(
                                    `/clinic-admin/view-report/${patient_id}/${index}`
                                  );
                                }
                              }}
                            >
                              <Row
                                className="d-flex ai-center"
                                style={{ gap: 7 }}
                              >
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "14PX",
                                    color: "#525252",
                                  }}
                                >
                                  View Report
                                </span>
                              </Row>
                            </Button>
                          </Col>
                        </Row>
                      );
                    })
                ) : (
                  <div
                    style={{ padding: 24 }}
                    className="d-flex jc-center ai-center w-100"
                  >
                    <span
                      style={{
                        color: "#525252",
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    >
                      You dont have any reports
                    </span>
                  </div>
                )}
              </>
            )}
          </Row>
        )}

        {currentUrl.includes("view-report") && (
          <Row
            className="w-100"
            style={{
              background: "#FFF",
              padding: "10px 33px",
              minHeight: "80vh",
            }}
          >
            {currentUrl.includes("patient") ? (
              <Tabs
                className="w-100"
                defaultActiveKey="1"
                items={items}
                onChange={onChange}
              />
            ) : (
              <Tabs
                className="w-100"
                defaultActiveKey="1"
                items={clinicitems}
                onChange={onChange}
              />
            )}
          </Row>
        )}

        {currentUrl.includes("add-report") && (
          <Row
            className="w-100"
            style={{
              background: "#FFF",
              padding: "10px 33px",
            }}
          >
            <Tabs className="w-100" defaultActiveKey="1">
              <Tabs.TabPane tab="Upload reports" key="2">
                <div
                  style={{
                    width: "100%",
                    height: "70vh",
                  }}
                  className="d-flex jc-center ai-center"
                >
                  <div
                    style={{
                      borderRadius: 5,
                      border: "1px solid #E0E0E0",
                      padding: 40,
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                    }}
                  >
                    <Row
                      style={{
                        padding: "15px 32px",
                        borderRadius: 5,
                        width: "50vw",
                        background: "#F9F9F9",
                      }}
                      className="d-flex jc-between ai-center"
                    >
                      <Col>Xray Report</Col>
                      <Col>
                        <Button
                          onClick={() => {
                            setreportUploadType("XRAY");
                            setIsOpenUploadXrayModal(true);
                          }}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        padding: "15px 32px",
                        borderRadius: 5,
                        width: "50vw",
                        background: "#F9F9F9",
                      }}
                      className="d-flex jc-between ai-center"
                    >
                      <Col>Intra Oral Report</Col>
                      <Col>
                        <Button
                          onClick={() => {
                            setreportUploadType("INTRA_ORAL_REPORTS");
                            setIsOpenUploadXrayModal(true);
                          }}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        padding: "15px 32px",
                        borderRadius: 5,
                        width: "50vw",
                        background: "#F9F9F9",
                      }}
                      className="d-flex jc-between ai-center"
                    >
                      <Col>Misc Report</Col>
                      <Col>
                        <Button
                          onClick={() => {
                            setreportUploadType("MISCELLANEOUS_REPORTS");
                            setIsOpenUploadXrayModal(true);
                          }}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Clinical report" key="3">
                <ClinicalReportForm
                  allReportDetails={allReportDetails}
                  allClinicalReports={allClinicalReports}
                  handleGetAllClinicalReportForms={
                    handleGetAllClinicalReportForms
                  }
                  setDisableFormItems={setDisableFormItems}
                  disableFormItems={disableFormItems}
                  appointmentData={appointmentData}
                />
              </Tabs.TabPane>
            </Tabs>
          </Row>
        )}
      </Spin>
      <UploafXrayModal
        isOpenUploadXrayModal={isOpenUploadXrayModal}
        setIsOpenUploadXrayModal={setIsOpenUploadXrayModal}
        reportType={reportUploadType}
        getXrayDetails={handleGetAllReportDetails}
        appointmentData={appointmentData}
      />
    </div>
  );
};

export default MlAnalysis;
