import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { routes_super_admin_surveys } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import addicon from "../../../../../assets/addicon.png";
import { Form, Input, Button, Space, Row, Col, Spin, message } from "antd";
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import API_BASE_URL from "../../../../../env";

const EditSurveys = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { survey_id } = useParams();
  const Token = localStorage.getItem("Token");
  const [spinner, setSpinner] = useState(false);
  const [surveyData, setSurveyData] = useState();
  const [questions, setQuestions] = useState([]);
  const [surveyTitle, setSurveyTitle] = useState("");

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      { id: "", text: "", survey_answers: [{ id: "", text: "" }] },
    ]);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleAddOption = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].survey_answers.push({ id: "", text: "" });
    setQuestions(updatedQuestions);
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].survey_answers.splice(optionIndex, 1);
    setQuestions(updatedQuestions);
  };

  const handleCreateSurvey = (values) => {
    let payload;
    payload = {
      id: survey_id,
      text: values.survey_title,
      survey_questions: values.questions.map((item, questionIndex) =>
        surveyData[questionIndex]?.id
          ? {
              id: surveyData[questionIndex]?.id,
              text: item.text,
              survey_question_type: {
                name: "RADIO_BUTTON",
              },
              mandatory: true,
              randomize_options: true,
              survey_answers: values.questions[
                questionIndex
              ].survey_answers.map((answer, optionIndex) => ({
                id:
                  surveyData[questionIndex]?.survey_answers[optionIndex]?.id ||
                  answer.id,
                text: answer.text,
                answer_order: 0,
                answered:
                  surveyData[questionIndex]?.survey_answers[optionIndex]
                    ?.answered || false,
              })),
              question_order: 0,
            }
          : {
              text: item.text,
              survey_question_type: {
                name: "RADIO_BUTTON",
              },
              mandatory: true,
              randomize_options: true,
              survey_answers: values.questions[
                questionIndex
              ].survey_answers.map((answer, optionIndex) =>
                surveyData[questionIndex]?.survey_answers[optionIndex]?.id
                  ? {
                      id: surveyData[questionIndex]?.survey_answers[optionIndex]
                        ?.id,
                      text: answer.text,
                      answer_order: 0,
                      answered:
                        surveyData[questionIndex]?.survey_answers[optionIndex]
                          ?.answered || false,
                    }
                  : {
                      text: answer.text,
                      answer_order: 0,
                      answered:
                        surveyData[questionIndex]?.survey_answers[optionIndex]
                          ?.answered || false,
                    }
              ),
              question_order: 0,
            }
      ),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .post(`${API_BASE_URL}/api/v1/survey/v1/updateSurvey`, payload, config)
      .then((res) => {
        navigate(routes_super_admin_surveys);
        setSpinner(false);
        message.success("Survey updated successfully!");
        // form.resetFields();
      })
      .catch((err) => {
        setSpinner(false);
        message.err("error occured");
      });
  };

  // Retireves the survey details based on the selected survey topic
  const handleGetSurveyById = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/survey/${survey_id}`, config)
      .then((res) => {
        setSurveyTitle(res.data.data.text);
        setSurveyData(res.data.data.survey_questions);
        if (
          res.data.data.survey_questions &&
          res.data.data.survey_questions.length > 0
        ) {
          setQuestions(res.data.data.survey_questions);
        }
      });
  };
  //

  useEffect(() => {
    handleGetSurveyById();
  }, []);

  return (
    <div className="my-account-main-div">
      <Spin
        indicator={antIcon}
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <Row
          className="d-flex ai-center jc-between"
          style={{ margin: "12px 23px" }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Edit Survey
          </span>
        </Row>
        {surveyData && surveyData.length > 0 && (
          <div
            style={{
              margin: "0 23px",
              background: "#FFF",
              padding: "23px",
              maxHeight: "75vh",
              overflow: "auto",
            }}
          >
            <Form
              form={form}
              onFinish={handleCreateSurvey}
              initialValues={{ questions }}
            >
              <Row className="w-100 d-flex" style={{ gap: 20 }}>
                <Col>
                  <span
                    style={{
                      color: "#525252",
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    Enter Survey Title :
                  </span>
                </Col>
                <Col>
                  {surveyTitle && (
                    <Form.Item
                      name={["survey_title"]}
                      label={`Survey Title`}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a survey title",
                        },
                      ]}
                      initialValue={surveyTitle}
                    >
                      <Input placeholder="Survey Title" />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row className="w-100">
                <Form.Item>
                  <Button
                    icon={
                      <img
                        alt=""
                        src={addicon}
                        style={{ width: 25, height: 25 }}
                      />
                    }
                    onClick={handleAddQuestion}
                    style={{
                      margin: "36px 0 0 0",
                      borderRadius: 6,
                      background: "#F4F4F4",
                      fontSize: 16,
                      fontWeight: 400,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 7,
                      height: 50,
                    }}
                  >
                    Add Question
                  </Button>
                </Form.Item>
              </Row>
              <Row className="w-100">
                {questions.map((question, questionIndex) => (
                  <div
                    className="d-flex jc-center"
                    style={{
                      margin: "30px 30px 0 30px",
                      width: "100%",
                      borderRadius: 6,
                      background: "#F4F4F4",
                      padding: "40px",
                    }}
                  >
                    <Space
                      key={question.id || questionIndex}
                      // key={questionIndex}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 8,
                      }}
                    >
                      <Row style={{ display: "flex", gap: 8 }}>
                        <Form.Item
                          name={["questions", questionIndex, "text"]}
                          label={`Question ${questionIndex + 1}`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter a question",
                            },
                          ]}
                          initialValue={question.text}
                        >
                          <Input style={{ width: "70vw" }} />
                        </Form.Item>
                        <Button
                          type="danger"
                          icon={<MinusCircleOutlined />}
                          onClick={() => handleRemoveQuestion(questionIndex)}
                        />
                      </Row>
                      {question.survey_answers.map((option, optionIndex) => (
                        <Space
                          key={option.id || optionIndex}
                          // key={optionIndex}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Form.Item
                            name={[
                              "questions",
                              questionIndex,
                              "survey_answers",
                              optionIndex,
                              "text",
                            ]}
                            label={`Option ${optionIndex + 1}`}
                            rules={[
                              {
                                required: true,
                                message: "Please enter an option",
                              },
                            ]}
                            initialValue={option.text}
                          >
                            <Input style={{ width: "70vw" }} />
                          </Form.Item>
                          <Button
                            type="danger"
                            icon={<MinusCircleOutlined />}
                            onClick={() =>
                              handleRemoveOption(questionIndex, optionIndex)
                            }
                          />
                        </Space>
                      ))}
                      <Button
                        style={{
                          color: "#2CA3FA",
                          fontSize: 14,
                          fontWeight: 600,
                          borderRadius: 30,
                          border: "1px solid #009DF7",
                        }}
                        icon={<PlusCircleOutlined />}
                        onClick={() => handleAddOption(questionIndex)}
                      >
                        Add Option
                      </Button>
                    </Space>
                  </div>
                ))}
              </Row>
              {questions && questions.length > 0 && (
                <Row
                  className="w-100 d-flex jc-center"
                  style={{ gap: 30, margin: "30px 0 0 0" }}
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        color: "#2CA3FA",
                        fontSize: 14,
                        fontWeight: 600,
                        borderRadius: 30,
                        border: "1px solid #009DF7",
                        background: "#E0F2FF",
                      }}
                    >
                      Update Survey
                    </Button>
                  </Form.Item>
                </Row>
              )}
            </Form>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default EditSurveys;
