import { Helmet } from "react-helmet";
import {
  Row,
  Input,
  Form,
  Col,
  Button,
  Select,
  Breadcrumb,
  DatePicker,
  message,
  Spin,
  Space,
  Radio,
  Menu,
  Divider,
  Checkbox,
} from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import ProfilePic from "../../../../../assets/default-profile-pic.png";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  CloseOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AWS from "aws-sdk";
import {
  monthsMap,
  routes_clinic_admin_staff,
  yearsMap,
} from "../../../../../utils/clinic_admin_constants/ClinicAdminConstants";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../string";
import { options } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import API_BASE_URL from "../../../../../env";

const AdministratorProfile = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const Token = localStorage.getItem("Token");
  const clinicid = localStorage.getItem("clinic_id");
  const role = localStorage.getItem("role");
  const { id, clinic_id } = useParams();
  const [form] = Form.useForm();
  const [inputsEnable, setInputsEnable] = useState(true);
  const [adminUserDetails, setAdminUserDetails] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [adminProfilePicUrl, setAdminProfilePicUrl] = useState();
  const [admin_branch_id, setAdminBranchId] = useState("");
  const [branch_details, setBranchDetails] = useState();
  const [clinic_branches, setClinicBranches] = useState();

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };

  const handleGetAdminDetails = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/admin/${id}/clinic/${
          clinic_id ? clinic_id : clinicid
        }`,
        config
      )
      .then((res) => {
        setAdminUserDetails(res.data.data);
        setAdminBranchId(res.data.data.branch_id);
        setAdminProfilePicUrl(
          res.data.data.profile_picture_url &&
            res.data.data.profile_picture_url.split(".com/")[1]
        );
      });
  };

  const handleGetBranchDetails = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/clinicBranch/fetchBranchDetails/${admin_branch_id}`,
        config
      )
      .then((res) => {
        setBranchDetails(res.data.data);
      });
  };

  const handleUpdateAdminDetails = async (values) => {
    setSpinner(true);
    const payload = {
      name: values.name,
      username: values.username,
      email: values.email,
      phone_country_code: values.phone_country_code,
      phone_number: values.phone_number,
      gender: values.gender,
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
      qualification: values.qualification,
      total_experience: {
        years: values.years,
        months: values.months,
      },
      designation: values.designation,
      access_type: adminUserDetails.access_type_name,
      branch_id: adminUserDetails.branch_id,
      specialization: JSON.stringify(values.specialization),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .put(
        `${API_BASE_URL}/api/v1/admin/${id}/clinic/${
          clinic_id ? clinic_id : clinicid
        }`,
        payload,
        config
      )
      .then((res) => {
        setSpinner(false);
        setInputsEnable(true);
        handleGetAdminDetails();
        message.success("Admin Updated SuccessFully");
      })
      .catch((err) => {
        setSpinner(false);
        message.error(err.response.data.data.errors);
      });
  };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  const handleGetAllclinicBranches = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/clinicBranch/getAllBranchDetails/${clinicid}`,
        config
      )
      .then((res) => {
        setClinicBranches(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleGetAdminDetails();
  }, []);

  useEffect(() => {
    handleGetBranchDetails();
  }, [admin_branch_id]);

  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: adminProfilePicUrl,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [adminProfilePicUrl]);

  const items = [
    {
      type: "group",
      label: (
        <Row
          style={{
            display: "flex",
            gap: "0.417vw",
          }}
        >
          {/* <AppointmentProfilePic
            pro={
              // item &&
              adminProfilePicUrl && adminProfilePicUrl
              // item.profile_picture_url &&
              // item.profile_picture_url.split(".com/")[1]
            }
          /> */}
          <img
            style={{
              height: "72px",
              width: "72px",
              borderRadius: 10,
            }}
            src={fileContent ? fileContent : ProfilePic}
            alt=""
          />
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.417vw",
            }}
          >
            <span
              // onClick={() => {
              //   navigate(`${navigateTo}/${item.id}`);
              //   setAdminTabActiveKey("");
              // }}
              style={{
                color: "#333",
                fontSize: "24px",
                fontWeight: 700,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {adminUserDetails.name}
            </span>

            <span
              style={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#000000",
              }}
            >
              SID #
              <span
                style={{
                  color: "#7D7D7D",
                }}
              >
                {adminUserDetails.id}
              </span>
            </span>
          </Row>
        </Row>
      ),
      children: [
        {
          label: (
            <span
              style={{
                fontSize: "0.9375vw",
                fontWeight: 400,
              }}
            >
              Staff Information
            </span>
          ),
          key: "1",
        },
        {
          label: (
            <span
              style={{
                fontSize: "0.9375vw",
                fontWeight: 400,
              }}
            >
              Treatment Information
            </span>
          ),
          key: "2",
        },
      ],
    },
  ];

  const [selectTab, setselectTab] = useState("1");
  const onClick = (e) => {
    setselectTab(e.key);
  };

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current > moment().startOf("day");
  };

  // const [designationValue, setdesignationValue] = useState();
  // const onChange = (checkedValues) => {
  //   setdesignationValue(checkedValues);
  // };

  const handleImageClick = () => {
    // if (route === "patient") {
    document.getElementById("fileInput").click();
    // }
  };

  const handleFileChange = async (event) => {
    const propic = event.target.files[0];
    // let formData = new FormData();
    // formData.append("file", propic);
    // let config = {
    //   headers: {
    //     Authorization: `Bearer ${Token}`,
    //   },
    // };
    // await axios
    //   .post(
    //     `${API_BASE_URL}/api/v1/patient/${patientid}/upload-profile-picture`,
    //     formData,
    //     config
    //   )
    //   .then((res) => {
    //     handleGetPatientDetails();
    //     getPatientDetails();
    //     message.success("Updated Profile Picture");
    //     setSpinner(false);
    //   });

    setSpinner(true);
    let formData = new FormData();
    formData.append("file", propic);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (role === "CLINIC_USER") {
      await axios
        .post(
          `${API_BASE_URL}/api/v1/adminStaff/${id}/upload-profile-picture`,
          formData,
          config
        )
        .then((res) => {
          setSpinner(false);
          handleGetAdminDetails();
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
          setSpinner(false);
        });
    }
    if (role === "CLINIC_ADMIN") {
      await axios
        .post(
          `${API_BASE_URL}/api/v1/admin/${id}/upload-profile-picture`,
          formData,
          config
        )
        .then((res) => {
          setSpinner(false);
          handleGetAdminDetails();
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
          setSpinner(false);
        });
    }
  };

  return (
    // <div className="add-staff-main-div">
    //   <Helmet>
    //     <title>Administrator Profile</title>
    //   </Helmet>
    //   <Row
    //     style={{
    //       display: "flex",
    //       flexDirection: "column",
    //       margin: "12px 23px",
    //     }}
    //   >
    //     <span
    //       style={{
    //         fontWeight: 700,
    //         fontSize: 22,
    //         color: "#525252",
    //       }}
    //     >
    //       Staff Profile
    //     </span>
    //     <Breadcrumb>
    //       {role === "CLINIC_MASTER_ADMIN" || role === "CLINIC_ADMIN" ? (
    //         <Breadcrumb.Item>
    //           <a href={routes_clinic_admin_staff}>Admin/Staff</a>
    //         </Breadcrumb.Item>
    //       ) : (
    //         <></>
    //       )}
    //       <Breadcrumb.Item>Administrator Profile</Breadcrumb.Item>
    //     </Breadcrumb>
    //   </Row>
    //   <Row className="w-100" style={{ padding: "0 0 0 23px" }}>
    //     <Form
    //       form={form}
    //       initialValues={{
    //         remember: true,
    //       }}
    //       autoComplete="off"
    //       className="w-100"
    //       onFinish={handleUpdateAdminDetails}
    //     >
    //       <Spin
    //         indicator={antIcon}
    //         size="large"
    //         spinning={spinner && true}
    //         delay={500}
    //       >
    //         <Row className="d-flex">
    //           {/* profile col */}
    //           <Col xs={24} sm={24} md={12} xl={6}>
    //             <div className="profile-pic-col-main-div">
    //               <div
    //                 style={{
    //                   padding: "3vh 3.33vw",
    //                   borderBottom: "1px solid #D9D9D9",
    //                 }}
    //               >
    //                 <Row className="d-flex jc-center ai-center">
    //                   <div
    //                     style={{
    //                       borderRadius: "40%",
    //                       background: "#D9D9D9",
    //                       width: "118px",
    //                       height: "100px",
    //                     }}
    //                   >
    //                     <Form.Item>
    //                       <img
    //                         style={{
    //                           height: "118px",
    //                           width: "118px",
    //                           borderRadius: 40,
    //                         }}
    //                         src={fileContent ? fileContent : ProfilePic}
    //                         alt=""
    //                       />
    //                     </Form.Item>
    //                   </div>
    //                 </Row>
    //                 <Row
    //                   style={{
    //                     display: "flex",
    //                     flexDirection: "column",
    //                     margin: "27px 0 0 0",
    //                     gap: "2.302vh",
    //                   }}
    //                 >
    //                   <Row style={{ display: "flex", flexDirection: "column" }}>
    //                     <span
    //                       style={{
    //                         fontWeight: "400",
    //                         fontSize: "12px",
    //                         color: "#A5A4A4",
    //                       }}
    //                     >
    //                       Name
    //                     </span>
    //                     {adminUserDetails && (
    //                       <Form.Item
    //                         name="admin_name"
    //                         initialValue={adminUserDetails.name}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please Enter name",
    //                           },
    //                           {
    //                             min: 3,
    //                             message:
    //                               "Name must be at least 3 characters long",
    //                           },
    //                           {
    //                             max: 100,
    //                             message:
    //                               "Name must be no more than 100 characters long",
    //                           },
    //                           {
    //                             pattern: /^[A-Za-z][A-Za-z\s]*$/,
    //                             message:
    //                               "First character should be alphabetical and Name must contain only alphabeticals",
    //                           },
    //                           { whitespace: true, message: "No white spaces" },
    //                         ]}
    //                       >
    //                         <Input
    //                           name="admin_name"
    //                           style={{
    //                             fontWeight: "400",
    //                             fontSize: "16px",
    //                             color: "#525252",
    //                             height: "40px",
    //                             borderRadius: "3px",
    //                             border: "1px solid #E3E3E3",
    //                           }}
    //                           bordered={false}
    //                           disabled={inputsEnable}
    //                           maxLength={101}
    //                           onKeyPress={(e) => {
    //                             const charCode = e.which || e.keyCode;
    //                             const inputValue = e.target.value;
    //                             if (
    //                               !(
    //                                 (charCode >= 65 && charCode <= 90) ||
    //                                 (charCode >= 97 && charCode <= 122) ||
    //                                 (charCode === 32 &&
    //                                   inputValue.trim() !== "")
    //                               )
    //                             ) {
    //                               e.preventDefault();
    //                             }
    //                           }}
    //                         />
    //                       </Form.Item>
    //                     )}
    //                   </Row>
    //                   <Row style={{ display: "flex", flexDirection: "column" }}>
    //                     <span
    //                       style={{
    //                         fontWeight: "400",
    //                         fontSize: "12px",
    //                         color: "#A5A4A4",
    //                       }}
    //                     >
    //                       Patient Identification
    //                     </span>
    //                     {adminUserDetails && (
    //                       <Form.Item
    //                         name="patientID"
    //                         initialValue={"PID# " + adminUserDetails.id}
    //                       >
    //                         <Input
    //                           name="patientID"
    //                           label="patientID"
    //                           style={{
    //                             fontWeight: "400",
    //                             fontSize: "16px",
    //                             color: "#525252",
    //                             height: "40px",
    //                             borderRadius: "3px",
    //                             border: "1px solid #E3E3E3",
    //                           }}
    //                           bordered={false}
    //                           disabled={true}
    //                         />
    //                       </Form.Item>
    //                     )}
    //                   </Row>
    //                   <Row style={{ display: "flex", flexDirection: "column" }}>
    //                     <span
    //                       style={{
    //                         fontWeight: "400",
    //                         fontSize: "12px",
    //                         color: "#A5A4A4",
    //                       }}
    //                     >
    //                       Gender
    //                     </span>
    //                     {adminUserDetails && (
    //                       <Form.Item
    //                         name="gender"
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please Select Gender",
    //                           },
    //                         ]}
    //                         initialValue={adminUserDetails.gender}
    //                       >
    //                         <Select
    //                           name="gender"
    //                           disabled={inputsEnable}
    //                           bordered={false}
    //                           style={{
    //                             fontWeight: "400",
    //                             fontSize: "16px",
    //                             color: "#525252",
    //                             height: "40px",
    //                             borderRadius: "3px",
    //                             border: "1px solid #E3E3E3",
    //                           }}
    //                         >
    //                           <Option value="Male">Male</Option>
    //                           <Option value="Female">Female</Option>
    //                           <Option value="Others">Others</Option>
    //                         </Select>
    //                       </Form.Item>
    //                     )}
    //                   </Row>
    //                 </Row>
    //               </div>
    //               <div
    //                 style={{
    //                   padding:
    //                     "3.7151702786377707vh 3.3333333333333335vw 4vh 3.3854166666666665vw",
    //                   borderBottom: "1px solid #D9D9D9",
    //                 }}
    //               >
    //                 <span
    //                   style={{
    //                     fontWeight: "400",
    //                     fontSize: "12px",
    //                     color: "#A5A4A4",
    //                   }}
    //                 >
    //                   Email
    //                 </span>
    //                 {adminUserDetails && (
    //                   <Form.Item
    //                     name="email"
    //                     initialValue={adminUserDetails.email}
    //                     rules={[
    //                       { required: true, message: "Please enter email" },
    //                       {
    //                         type: "email",
    //                         message: "Please enter a valid email address",
    //                       },
    //                       {
    //                         max: 320,
    //                         message:
    //                           "Email address must be no more than 320 characters long",
    //                       },
    //                     ]}
    //                   >
    //                     <Input
    //                       name="email"
    //                       label="email"
    //                       style={{
    //                         fontWeight: "400",
    //                         fontSize: "16px",
    //                         color: "#7D7D7D",
    //                         height: "40px",
    //                         borderRadius: "3px",
    //                         border: "1px solid #E3E3E3",
    //                       }}
    //                       disabled={inputsEnable}
    //                       bordered={false}
    //                       maxLength={321}
    //                     />
    //                   </Form.Item>
    //                 )}
    //               </div>
    //               <div
    //                 style={{
    //                   padding:
    //                     "3.7151702786377707vh 3.3333333333333335vw 4.615384615384615vh 3.3854166666666665vw",
    //                 }}
    //               >
    //                 <Row>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Phone
    //                   </span>
    //                 </Row>
    //                 {adminUserDetails && (
    //                   <Space.Compact>
    //                     <Form.Item
    //                       name="phone_country_code"
    //                       initialValue={adminUserDetails.phone_country_code}
    //                     >
    //                       <Select
    //                         bordered={false}
    //                         disabled={inputsEnable}
    //                         style={{
    //                           display: "flex",
    //                           alignItems: "center",
    //                           height: 40,
    //                           background: "#FFFFFF",
    //                           border: "1px solid #E3E3E3",
    //                           margin: "3px 0 0 0",
    //                         }}
    //                         defaultValue="Select"
    //                         options={options}
    //                       />
    //                     </Form.Item>
    //                     <Form.Item
    //                       style={{ width: "100%" }}
    //                       name="phone_number"
    //                       initialValue={adminUserDetails.phone_number}
    //                       rules={[
    //                         {
    //                           required: true,
    //                           message: "Please enter phone number",
    //                         },
    //                         { validator: validatePhoneNumber },
    //                       ]}
    //                     >
    //                       <Input
    //                         bordered={false}
    //                         disabled={inputsEnable}
    //                         style={{
    //                           height: 40,
    //                           background: "#FFFFFF",
    //                           borderTop: "1px solid #E3E3E3",
    //                           borderRight: "1px solid #E3E3E3",
    //                           borderBottom: "1px solid #E3E3E3",
    //                           margin: "3px 0 0 0",
    //                         }}
    //                         defaultValue=""
    //                         onKeyPress={(e) => {
    //                           const charCode = e.which || e.keyCode;
    //                           if (!(charCode >= 48 && charCode <= 57)) {
    //                             e.preventDefault();
    //                           }
    //                         }}
    //                       />
    //                     </Form.Item>
    //                   </Space.Compact>
    //                 )}
    //               </div>
    //             </div>
    //           </Col>
    //           {/* address col */}
    //           <Col xs={24} sm={24} md={12} xl={6}>
    //             <div className="profile-pic-col-main-div">
    //               <Row
    //                 style={{
    //                   display: "flex",
    //                   flexDirection: "column",
    //                   gap: "5vh",
    //                   padding: "53px 23px",
    //                   borderBottom:
    //                     branch_details && role === "CLINIC_MASTER_ADMIN"
    //                       ? "1px solid #D9D9D9"
    //                       : "",
    //                 }}
    //               >
    //                 <Row style={{ display: "flex", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Username
    //                   </span>
    //                   {adminUserDetails && (
    //                     <Form.Item
    //                       name="admin_username"
    //                       initialValue={adminUserDetails.username}
    //                       rules={[
    //                         {
    //                           required: true,
    //                           message: "Please enter username",
    //                         },
    //                         {
    //                           min: 4,
    //                           message:
    //                             "Username must be at least 4 characters long",
    //                         },
    //                         {
    //                           max: 100,
    //                           message:
    //                             "Username must be no more than 100 characters long",
    //                         },
    //                         { whitespace: true, message: "No white spaces" },
    //                         {
    //                           pattern: /^[a-zA-Z0-9._]+$/,
    //                           message:
    //                             "Username can only contain letters, numbers, fullstops, and underscores.",
    //                         },
    //                       ]}
    //                     >
    //                       <Input
    //                         name="admin_username"
    //                         style={{
    //                           fontWeight: "400",
    //                           fontSize: "16px",
    //                           color: "#7D7D7D",
    //                           height: "40px",
    //                           border: "1px solid #E3E3E3",
    //                         }}
    //                         bordered={false}
    //                         disabled={true}
    //                         maxLength={101}
    //                         onKeyPress={handleUserNameKeyPress}
    //                       />
    //                     </Form.Item>
    //                   )}
    //                 </Row>
    //                 <Row style={{ display: "flex", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Designation
    //                   </span>
    //                   {adminUserDetails && (
    //                     <Form.Item
    //                       name="designation"
    //                       initialValue={adminUserDetails.designation_name}
    //                       rules={[
    //                         {
    //                           required: true,
    //                           message: "Please Enter Designation",
    //                         },
    //                       ]}
    //                     >
    //                       <Select
    //                         name="designation"
    //                         bordered={false}
    //                         style={{
    //                           fontWeight: "400",
    //                           fontSize: "16px",
    //                           color: "#525252",
    //                           height: "40px",
    //                           borderRadius: "3px",
    //                           border: "1px solid #E3E3E3",
    //                         }}
    //                         disabled={inputsEnable}
    //                       >
    //                         <Option value="Doctor">Doctor</Option>
    //                         <Option value="Nurse">Nurse</Option>
    //                       </Select>
    //                     </Form.Item>
    //                   )}
    //                 </Row>
    //                 <Row style={{ display: "flex", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Date of Birth*
    //                   </span>
    //                   {adminUserDetails && (
    //                     <Form.Item
    //                       name="date_of_birth"
    //                       rules={[
    //                         {
    //                           required: true,
    //                           message: "Please Enter Date of Birth",
    //                         },
    //                       ]}
    //                       initialValue={moment(adminUserDetails.date_of_birth)}
    //                     >
    //                       <DatePicker
    //                         name="date_of_birth"
    //                         style={{
    //                           fontWeight: "400",
    //                           width: "70%",
    //                           fontSize: "16px",
    //                           color: "#525252",
    //                           height: "40px",
    //                           borderRadius: "3px",
    //                           border: "1px solid #E3E3E3",
    //                         }}
    //                         disabled={inputsEnable}
    //                       />
    //                     </Form.Item>
    //                   )}
    //                 </Row>
    //                 <Row style={{ display: "flex", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Qualification*
    //                   </span>
    //                   {adminUserDetails && (
    //                     <Form.Item
    //                       name="qualification"
    //                       initialValue={adminUserDetails.qualification}
    //                       rules={[
    //                         {
    //                           required: true,
    //                           message: "Please Enter Qualification",
    //                         },
    //                       ]}
    //                     >
    //                       <Input
    //                         name="qualification"
    //                         label="qualification"
    //                         style={{
    //                           fontWeight: "400",
    //                           fontSize: "16px",
    //                           color: "#7D7D7D",
    //                           height: "40px",
    //                           border: "1px solid #E3E3E3",
    //                         }}
    //                         bordered={false}
    //                         disabled={inputsEnable}
    //                       />
    //                     </Form.Item>
    //                   )}
    //                 </Row>
    //                 <Row style={{ display: "flex", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Total Experience*
    //                   </span>
    //                   <Row>
    //                     <Col span={12}>
    //                       {adminUserDetails && (
    //                         <Form.Item
    //                           name="years"
    //                           initialValue={
    //                             adminUserDetails.total_experience.years
    //                           }
    //                           rules={[
    //                             {
    //                               required: true,
    //                               message: "Please Enter years of experience",
    //                             },
    //                           ]}
    //                         >
    //                           <Select
    //                             name="years"
    //                             disabled={inputsEnable}
    //                             bordered={false}
    //                             style={{
    //                               fontWeight: "400",
    //                               width: "90%",
    //                               fontSize: "16px",
    //                               color: "#525252",
    //                               height: "40px",
    //                               borderRadius: "3px",
    //                               border: "1px solid #E3E3E3",
    //                             }}
    //                           >
    //                             {yearsMap.map((item) => {
    //                               return (
    //                                 <Option value={item.year}>
    //                                   {item.year}
    //                                 </Option>
    //                               );
    //                             })}
    //                           </Select>
    //                         </Form.Item>
    //                       )}
    //                     </Col>
    //                     <Col span={12}>
    //                       {adminUserDetails && (
    //                         <Form.Item
    //                           name="months"
    //                           initialValue={
    //                             adminUserDetails.total_experience.months
    //                           }
    //                           rules={[
    //                             {
    //                               required: true,
    //                               message: "Please Enter months of experience",
    //                             },
    //                           ]}
    //                         >
    //                           <Select
    //                             name="months"
    //                             disabled={inputsEnable}
    //                             bordered={false}
    //                             style={{
    //                               width: "90%",
    //                               fontWeight: "400",
    //                               fontSize: "16px",
    //                               color: "#525252",
    //                               height: "40px",
    //                               borderRadius: "3px",
    //                               border: "1px solid #E3E3E3",
    //                             }}
    //                           >
    //                             {monthsMap.map((item) => {
    //                               return (
    //                                 <Option value={item.year}>
    //                                   {item.year}
    //                                 </Option>
    //                               );
    //                             })}
    //                           </Select>
    //                         </Form.Item>
    //                       )}
    //                     </Col>
    //                   </Row>
    //                 </Row>
    //                 <Row style={{ display: "none", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Access Type*
    //                   </span>
    //                   <Row>
    //                     <Col span={12}>
    //                       {adminUserDetails && (
    //                         <Form.Item
    //                           name="access_type"
    //                           initialValue={adminUserDetails.access_type_name}
    //                           rules={[
    //                             {
    //                               required: true,
    //                               message: "Select Access Type",
    //                             },
    //                           ]}
    //                         >
    //                           {adminUserDetails.access_type_name === "Admin" ||
    //                           adminUserDetails.access_type_name ===
    //                             "Master Admin" ? (
    //                             <Select
    //                               name="access_type"
    //                               disabled={inputsEnable}
    //                               bordered={false}
    //                               style={{
    //                                 fontWeight: "400",
    //                                 fontSize: "16px",
    //                                 color: "#525252",
    //                                 height: "40px",
    //                                 borderRadius: "3px",
    //                                 border: "1px solid #E3E3E3",
    //                               }}
    //                             >
    //                               <Option value="Master Admin">
    //                                 Master Admin
    //                               </Option>
    //                               <Option value="Admin">Admin</Option>
    //                             </Select>
    //                           ) : (
    //                             <Select
    //                               name="access_type"
    //                               disabled={inputsEnable}
    //                               bordered={false}
    //                               style={{
    //                                 fontWeight: "400",
    //                                 fontSize: "16px",
    //                                 color: "#525252",
    //                                 height: "40px",
    //                                 borderRadius: "3px",
    //                                 border: "1px solid #E3E3E3",
    //                               }}
    //                             >
    //                               <Option value="Can Edit">Can Edit</Option>
    //                               <Option value="Can View">Can View</Option>
    //                             </Select>
    //                           )}
    //                         </Form.Item>
    //                       )}
    //                     </Col>
    //                   </Row>
    //                 </Row>
    //               </Row>
    //               {branch_details && role === "CLINIC_MASTER_ADMIN" && (
    //                 <div
    //                   style={{
    //                     padding:
    //                       "2.7151702786377707vh 2.3333333333333335vw 2.615384615384615vh 1.3854166666666665vw",
    //                   }}
    //                 >
    //                   <Row>
    //                     <span style={{ fontSize: "20px", fontWeight: "700" }}>
    //                       Assigned branch details
    //                     </span>
    //                   </Row>

    //                   <Row>
    //                     <Col span={24}>
    //                       <Row>
    //                         <Col span={24}>
    //                           <div>
    //                             <span
    //                               style={{
    //                                 fontSize: "18px",
    //                                 fontWeight: "600",
    //                               }}
    //                             >
    //                               {branch_details.branch_name}
    //                             </span>
    //                             <span
    //                               style={{
    //                                 fontSize: "18px",
    //                                 fontWeight: "400",
    //                                 color: "#3498DB",
    //                               }}
    //                             >
    //                               {branch_details &&
    //                                 "#" + branch_details.branch_id}
    //                             </span>
    //                           </div>
    //                           <div style={{ width: "100%" }}>
    //                             <span
    //                               style={{
    //                                 fontSize: "14px",
    //                                 fontWeight: "400",
    //                                 color: "#626262",
    //                               }}
    //                             >
    //                               {branch_details &&
    //                                 branch_details.address_line_one +
    //                                   ", " +
    //                                   branch_details.address_line_two +
    //                                   ", " +
    //                                   branch_details.city +
    //                                   ", " +
    //                                   branch_details.province +
    //                                   ", " +
    //                                   branch_details.zip_code}
    //                             </span>
    //                           </div>
    //                           <div style={{ width: "100%" }}>
    //                             <span
    //                               style={{
    //                                 fontSize: "14px",
    //                                 fontWeight: "400",
    //                                 color: "#626262",
    //                               }}
    //                             >
    //                               {branch_details.phone_country_code +
    //                                 " " +
    //                                 branch_details.phone_number}
    //                             </span>
    //                           </div>
    //                           <div style={{ width: "100%" }}>
    //                             <span
    //                               style={{
    //                                 fontSize: "14px",
    //                                 fontWeight: "400",
    //                                 color: "#3498DB",
    //                               }}
    //                             >
    //                               {branch_details.email}
    //                             </span>
    //                           </div>
    //                         </Col>
    //                       </Row>
    //                     </Col>
    //                   </Row>
    //                 </div>
    //               )}
    //             </div>
    //           </Col>

    //           {/* Branches col */}

    //           {!inputsEnable &&
    //             clinic_branches &&
    //             role === "CLINIC_MASTER_ADMIN" && (
    //               <Col xs={24} sm={24} md={12} xl={12}>
    //                 <div
    //                   style={{
    //                     width: "48.2vw",
    //                     background: "#fff",
    //                     borderRadius: "5px",
    //                   }}
    //                 >
    //                   <Row
    //                     style={{
    //                       gap: "1vh",
    //                       padding: "43px 23px",
    //                     }}
    //                   >
    //                     <Row
    //                       style={{
    //                         display: "flex",
    //                         justifyContent: "space-between",
    //                         width: "100%",
    //                       }}
    //                     >
    //                       <Col
    //                         style={{
    //                           display: "flex",
    //                           flexDirection: "column",
    //                         }}
    //                       >
    //                         <span
    //                           style={{ fontSize: "20px", fontWeight: "700" }}
    //                         >
    //                           Assign Admin to Branch
    //                         </span>
    //                         <span
    //                           style={{
    //                             fontSize: "14px",
    //                             fontWeight: "400",
    //                             color: "rgba(122, 122, 122, 1)",
    //                           }}
    //                         >
    //                           Branches(
    //                           {clinic_branches ? clinic_branches.length : "0"})
    //                         </span>
    //                       </Col>
    //                       <Col>
    //                         <Input
    //                           style={{
    //                             width: 300,
    //                             height: 40,
    //                             borderRadius: 3,
    //                           }}
    //                           className="d-flex ai-center"
    //                           placeholder="Search by Clinic Name or ID"
    //                           prefix={
    //                             <SearchOutlined style={{ opacity: 0.5 }} />
    //                           }
    //                           // value={searchPatients}
    //                           // onChange={(e) => {
    //                           //   handleGetPatientsBySearch(e);
    //                           //   setSearchPatients(e.target.value);
    //                           // }}
    //                           suffix={
    //                             <CloseOutlined
    //                               style={{ height: "10px", color: "#A5A5A5" }}
    //                               // onClick={() => {
    //                               //   setSearchedGlobalPatients("");
    //                               //   setSearchPatients("");
    //                               //   handleGetAllPatientsForClinic();
    //                               // }}
    //                             />
    //                           }
    //                         />
    //                       </Col>
    //                     </Row>
    //                     <Row style={{ width: "100%", maxHeight: "60vh" }}>
    //                       {clinic_branches ? (
    //                         clinic_branches.map((item_branches) => {
    //                           return (
    //                             <Col span={12}>
    //                               <Row>
    //                                 <Col span={24}>
    //                                   <Form.Item
    //                                     name="branch_id"
    //                                     initialValue={
    //                                       branch_details &&
    //                                       branch_details.branch_id &&
    //                                       branch_details.branch_id
    //                                     }
    //                                   >
    //                                     <Radio.Group
    //                                       style={{ margin: "10px 0 0 0" }}
    //                                     >
    //                                       <Space direction="vertical">
    //                                         <Radio
    //                                           value={item_branches.branch_id}
    //                                         >
    //                                           <div>
    //                                             <span
    //                                               style={{
    //                                                 fontSize: "18px",
    //                                                 fontWeight: "700",
    //                                               }}
    //                                             >
    //                                               {item_branches.branch_name}
    //                                             </span>
    //                                             <span
    //                                               style={{
    //                                                 fontSize: "18px",
    //                                                 fontWeight: "400",
    //                                                 color: "#3498DB",
    //                                               }}
    //                                             >
    //                                               {item_branches &&
    //                                                 "#" +
    //                                                   item_branches.branch_id}
    //                                             </span>
    //                                           </div>
    //                                           <div style={{ width: "100%" }}>
    //                                             <span
    //                                               style={{
    //                                                 fontSize: "14px",
    //                                                 fontWeight: "400",
    //                                                 color: "#626262",
    //                                               }}
    //                                             >
    //                                               {item_branches &&
    //                                                 item_branches.address_line_one +
    //                                                   ", " +
    //                                                   item_branches.address_line_two +
    //                                                   ", " +
    //                                                   item_branches.city +
    //                                                   ", " +
    //                                                   item_branches.province +
    //                                                   ", " +
    //                                                   item_branches.zip_code}
    //                                             </span>
    //                                           </div>
    //                                           <div style={{ width: "100%" }}>
    //                                             <span
    //                                               style={{
    //                                                 fontSize: "14px",
    //                                                 fontWeight: "400",
    //                                                 color: "#626262",
    //                                               }}
    //                                             >
    //                                               {item_branches.phone_country_code +
    //                                                 " " +
    //                                                 item_branches.phone_number}
    //                                             </span>
    //                                           </div>
    //                                           <div style={{ width: "100%" }}>
    //                                             <span
    //                                               style={{
    //                                                 fontSize: "14px",
    //                                                 fontWeight: "400",
    //                                                 color: "#3498DB",
    //                                               }}
    //                                             >
    //                                               {item_branches.email}
    //                                             </span>
    //                                           </div>
    //                                         </Radio>
    //                                       </Space>
    //                                     </Radio.Group>
    //                                   </Form.Item>
    //                                 </Col>
    //                               </Row>
    //                             </Col>
    //                           );
    //                         })
    //                       ) : (
    //                         <Row
    //                           style={{
    //                             display: "flex",
    //                             justifyContent: "center",
    //                             alignItems: "center",
    //                             width: "100%",
    //                             height: "10vh",
    //                           }}
    //                         >
    //                           <span
    //                             style={{ fontSize: "24px", fontWeight: "500" }}
    //                           >
    //                             You don't have clinic branches
    //                           </span>
    //                         </Row>
    //                       )}
    //                     </Row>
    //                   </Row>
    //                 </div>
    //               </Col>
    //             )}
    //         </Row>

    //         <Row
    //           style={{
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             gap: "19px",
    //             margin: "20px 0 50px 0",
    //           }}
    //         >
    //           {role === "CLINIC_ADMIN" &&
    //           adminUserDetails &&
    //           adminUserDetails.access_type_name &&
    //           adminUserDetails.access_type_name === "Admin" ? (
    //             <></>
    //           ) : (
    //             <>
    //               {inputsEnable ? (
    //                 <Button
    //                   style={{
    //                     height: "30px",
    //                     border: "1px solid #2CA3FA",
    //                     borderRadius: "30px",
    //                     color: "#2CA3FA",
    //                     background: "#FFF",
    //                   }}
    //                   onClick={() => {
    //                     setInputsEnable(false);
    //                     handleGetAllclinicBranches();
    //                   }}
    //                 >
    //                   Edit
    //                 </Button>
    //               ) : (
    //                 <Button
    //                   style={{
    //                     height: "30px",
    //                     border: "1px solid red",
    //                     borderRadius: "30px",
    //                     color: "red",
    //                     background: "#red",
    //                   }}
    //                   onClick={() => {
    //                     setInputsEnable(true);
    //                     form.resetFields();
    //                   }}
    //                 >
    //                   Cancel
    //                 </Button>
    //               )}
    //               {inputsEnable === false && (
    //                 <Button
    //                   style={{
    //                     height: "30px",
    //                     width: "64px",
    //                     border: "1px solid #29C3C1",
    //                     borderRadius: "30px",
    //                     color: "#29C3C1",
    //                     background: "#ECFEFF",
    //                   }}
    //                   htmlType="submit"
    //                 >
    //                   Save
    //                 </Button>
    //               )}
    //             </>
    //           )}
    //         </Row>
    //       </Spin>
    //     </Form>
    //   </Row>
    // </div>

    <div className="add-staff-main-div">
      <Helmet>
        {role === "CLINIC_ADMIN" ? (
          <title>Administrator Profile</title>
        ) : (
          <title>Clinic User Profile</title>
        )}
      </Helmet>
      {/* <Row
      style={{
        // display: "flex",
        // flexDirection: "column",
        margin: "12px 23px",
      }}
    >
      {/* <span
        style={{
          fontWeight: 700,
          fontSize: 22,
          color: "#525252",
        }}
      >
        My Profile
      </span>
  <Button onClick={() => navigate(-1)}>Back</Button> 
  </Row> */}
      <div
        style={{
          margin: "50px",
          minWidth: "79.6875vw",
          maxWidth: "89.6875vw",
          background: "#fff",
          padding: "4.1710114702815435vh 1.0416666666666667vw",
          borderRadius: "10px",
        }}
      >
        <Row>
          <Col
            span={6}
            style={{
              borderRight: "1px solid #C2C2C2",
              padding: "0 20px 0 0",
            }}
          >
            <Menu
              onClick={onClick}
              style={{
                width: "100%",
              }}
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["1"]}
              mode="inline"
              items={items}
            />
          </Col>
          <Col span={18}>
            {/* {selectTab == 1 && (
            <PatientProfile
              patientUserDetails={patientUserDetails}
              getPatientDetails={getPatientDetails}
              setPatientUserDetails={setPatientUserDetails}
              setSpinner={setSpinner}
              form={form}
              patient_id={patient_id}
              patientTabsData={patientTabsData}
              setPatientTabsData={setPatientTabsData}
            />
          )} */}
            {selectTab == 1 && (
              <div
                style={{
                  padding: "0 50px",
                }}
              >
                {adminUserDetails && (
                  <Form
                    form={form}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={handleUpdateAdminDetails}
                    autoComplete="off"
                    className="w-100"
                  >
                    <div style={{ padding: "1.0417vw" }}>
                      <Row
                        style={{
                          // margin: "0 1.5625vw",
                          gap: "1.563vw",
                          // borderBottom: "1px solid #A8A8A8",
                          // padding: "0 0 1.25vw 0",
                        }}
                        className="d-flex ai-center"
                      >
                        <Col>
                          <img
                            preview={fileContent && true}
                            style={{
                              height: "5.313vw",
                              width: "5.313vw",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                            src={fileContent ? fileContent : ProfilePic}
                            alt=""
                            onClick={handleImageClick}
                            // src={previewSrc}
                            // alt="Profile"
                          />
                          <input
                            id="fileInput"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </Col>
                        <Col>
                          <Row>
                            <span
                              style={{
                                fontSize: "1.25vw",
                                fontWeight: 700,
                                color: "#333333",
                              }}
                            >
                              Staff Information
                            </span>
                          </Row>
                          <Row>
                            <span
                              style={{
                                fontSize: "0.9375vw",
                                fontWeight: 400,
                                color: "#717171",
                              }}
                            >
                              Add information about the staff, including
                              personal and professional details.
                            </span>
                          </Row>
                        </Col>
                      </Row>

                      {/* <Row>
                    <span
                      style={{
                        color: "#3498DB",
                        fontSize: "1.25vw",
                        fontWeight: 500,
                      }}
                    >
                      Add New Staff
                    </span>
                  </Row>
                  <Row>
                    <span
                      style={{
                        color: "#6F6F6F",
                        fontSize: "0.73vw",
                        fontWeight: 400,
                      }}
                    >
                      Add information about the staff, including personal and
                      professional details.
                    </span>
                  </Row> */}
                      <Divider />
                      <Row
                        className="d-flex jc-between"
                        style={{ margin: "1.25vw 0" }}
                      >
                        <Col span={8}>
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Name <span style={{ color: "#FF0000" }}>*</span>
                            </span>
                            <Form.Item
                              name="name"
                              initialValue={adminUserDetails.name}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Patient name",
                                },
                                {
                                  min: 3,
                                  message:
                                    "Name must be at least 3 characters long",
                                },
                                {
                                  max: 100,
                                  message:
                                    "Name must be no more than 100 characters long",
                                },
                                {
                                  pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                  message:
                                    "First character should be alphabetical and Name must contain only alphabeticals",
                                },
                                {
                                  whitespace: true,
                                  message: "No white spaces",
                                },
                              ]}
                            >
                              <Input
                                style={{
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                  color: "black",
                                }}
                                maxLength={101}
                                bordered={false}
                                disabled={inputsEnable}
                                onKeyPress={(e) => {
                                  const charCode = e.which || e.keyCode;
                                  const inputValue = e.target.value;
                                  if (
                                    !(
                                      (charCode >= 65 && charCode <= 90) ||
                                      (charCode >= 97 && charCode <= 122) ||
                                      (charCode === 32 &&
                                        inputValue.trim() !== "")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Username{" "}
                              <span style={{ color: "#FF0000" }}>*</span>
                            </span>
                            <Form.Item
                              className="w-100"
                              name="username"
                              initialValue={adminUserDetails.username}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Username!",
                                },
                                {
                                  min: 4,
                                  message: "Please enter minimum 4 characters",
                                },
                                {
                                  whitespace: true,
                                  message: "No white spaces",
                                },
                                {
                                  pattern: /^[a-zA-Z0-9._]+$/,
                                  message:
                                    "Username can only contain letters, numbers, fullstops, and underscores.",
                                },
                                {
                                  max: 100,
                                  message:
                                    "Username should not be more than 100 characters long",
                                },
                              ]}
                            >
                              <Input
                                disabled={true}
                                style={{
                                  color: "black",
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                }}
                                maxLength={101}
                                onKeyPress={handleUserNameKeyPress}
                              />
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Gender <span style={{ color: "#FF0000" }}></span>
                            </span>
                            <Form.Item
                              name="gender"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select Gender",
                                },
                              ]}
                              initialValue={adminUserDetails.gender}
                            >
                              <Select
                                disabled={inputsEnable}
                                bordered={false}
                                style={{
                                  color: "black",
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                }}
                              >
                                <Option value="Male">Male</Option>
                                <Option value="Female">Female</Option>
                                <Option value="Others">Others</Option>
                              </Select>
                            </Form.Item>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        className="d-flex jc-between"
                        style={{ margin: "1.25vw 0" }}
                      >
                        <Col span={8}>
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Email <span style={{ color: "#FF0000" }}>*</span>
                            </span>
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter email",
                                },
                                {
                                  type: "email",
                                  message: "Please Enter valid email address",
                                },
                                {
                                  max: 320,
                                  message:
                                    "Email address must be no more than 320 characters long",
                                },
                              ]}
                              initialValue={adminUserDetails.email}
                            >
                              <Input
                                style={{
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                  color: "black",
                                }}
                                bordered={false}
                                disabled={inputsEnable}
                                maxLength={321}
                              />
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Phone No.{" "}
                              <span style={{ color: "#FF0000" }}>*</span>
                            </span>
                            <Space.Compact
                              className="w-100"
                              style={{
                                width: "14.583333333333334vw",
                              }}
                            >
                              <Form.Item
                                name="phone_country_code"
                                // initialValue="+91"
                                initialValue={
                                  adminUserDetails.phone_country_code
                                    ? adminUserDetails.phone_country_code
                                    : "+91"
                                }
                              >
                                <Select
                                  bordered={false}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "2.0834vw",
                                    background: "#FFFFFF",
                                    border: "1px solid #E3E3E3",
                                    margin: "3px 0 0 0",
                                  }}
                                  options={options}
                                  disabled={inputsEnable}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: "100%" }}
                                name="phone_number"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter phone number",
                                  },
                                  { validator: validatePhoneNumber },
                                ]}
                                initialValue={adminUserDetails.phone_number}
                              >
                                <Input
                                  bordered={false}
                                  disabled={inputsEnable}
                                  style={{
                                    height: "2.0834vw",
                                    background: "#FFFFFF",
                                    borderTop: "1px solid #E3E3E3",
                                    borderRight: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    margin: "3px 0 0 0",
                                  }}
                                  onKeyPress={(e) => {
                                    const charCode = e.which || e.keyCode;
                                    if (!(charCode >= 48 && charCode <= 57)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Space.Compact>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Date of Birth{" "}
                              <span style={{ color: "#FF0000" }}>*</span>
                            </span>
                            <Form.Item
                              initialValue={moment(
                                adminUserDetails.date_of_birth
                              )}
                              name="date_of_birth"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select Date of birth",
                                },
                              ]}
                            >
                              <DatePicker
                                disabled={inputsEnable}
                                disabledDate={disabledDate}
                                style={{
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                  color: "black",
                                }}
                              />
                            </Form.Item>
                          </Row>
                        </Col>
                      </Row>
                      {/* <Row className="d-flex jc-between">
                    <Col span={8}>
                      <Row
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <span
                          style={{
                            fontSize: "0.73vw",
                            fontWeight: 400,
                            color: "#2F2F2F",
                          }}
                        >
                          Access <span style={{ color: "#FF0000" }}>*</span>
                        </span>
                        <Form.Item
                          className="w-100"
                          name="access_type"
                          rules={[
                            {
                              required: true,
                              message: "Please select access",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Access"
                            bordered={false}
                            style={{
                              width: "14.583333333333334vw",
                              margin: "5px 0 0 0",
                              height: "2.0834vw",
                              borderRadius: 3,
                              border: "1px solid #DADADA",
                              color: "black",
                            }}
                          >
                            <Option value="Admin">Admin</Option>
                            <Option value="Can Edit">Staff</Option>
                          </Select>
                        </Form.Item>
                      </Row>
                    </Col>
                  </Row> */}

                      <Row style={{ margin: "1.563vw 0 0 0" }}>
                        <span
                          style={{
                            color: "#333333",
                            fontSize: "0.834vw",
                            fontWeight: 700,
                          }}
                        >
                          Professional Details
                        </span>
                      </Row>
                      <div className="antdividerhorizontal">
                        <Divider />
                      </div>
                      <Row style={{ margin: "1.25vw 0" }}>
                        <Col span={8}>
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Designation{" "}
                              <span style={{ color: "#FF0000" }}>*</span>
                            </span>

                            <Form.Item
                              className="w-100"
                              name="designation"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select designation",
                                },
                              ]}
                              initialValue={adminUserDetails.designation_name}
                            >
                              <Select
                                placeholder="Select Designation"
                                // onChange={onChange}
                                allowClear
                                bordered={false}
                                style={{
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                  color: "black",
                                }}
                                disabled={inputsEnable}
                              >
                                <Option value="Dentist">Dentist</Option>
                                <Option value="DentalAssistant">
                                  Dental Assistant
                                </Option>
                                <Option value="DentalHygienist">
                                  Dental Hygienist
                                </Option>
                                <Option value="Receptionist">
                                  Receptionist
                                </Option>
                                <Option value="Doctor">Doctor</Option>
                                <Option value="Nurse">Nurse</Option>
                              </Select>
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Row
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Qualification
                            </span>
                            <Form.Item
                              name="qualification"
                              initialValue={adminUserDetails.qualification}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Qualification",
                                },
                              ]}
                            >
                              <Input
                                disabled={inputsEnable}
                                placeholder="E.g: BDS, MDS"
                                style={{
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                  color: "black",
                                }}
                                bordered={false}
                              />
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                            // className="w-100"`
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Total Experience
                            </span>
                            <Row
                              className="w-100 d-flex jc-end"
                              style={{ gap: 16, margin: "5px 0 0 0" }}
                            >
                              <Col>
                                <Form.Item
                                  name="years"
                                  initialValue={
                                    adminUserDetails.total_experience.years
                                  }
                                  // rules={[{ required: true, message: "Please enter year" }]}
                                >
                                  <Select
                                    placeholder="0 Years"
                                    bordered={false}
                                    allowClear
                                    style={{
                                      fontWeight: "400",
                                      width: "6.875vw",
                                      fontSize: "16px",
                                      color: "#525252",
                                      height: "40px",
                                      borderRadius: "3px",
                                      border: "1px solid #E3E3E3",
                                    }}
                                    disabled={inputsEnable}
                                  >
                                    {yearsMap.map((item) => {
                                      return (
                                        <Option value={item.year}>
                                          {item.year}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item
                                  name="months"
                                  initialValue={
                                    adminUserDetails.total_experience.months
                                  }
                                  //   rules={[
                                  //     { required: true, message: "Please enter month" },
                                  //   ]}
                                >
                                  <Select
                                    placeholder="0 Months"
                                    allowClear
                                    bordered={false}
                                    style={{
                                      width: "6.875vw",
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      color: "#525252",
                                      height: "40px",
                                      borderRadius: "3px",
                                      border: "1px solid #E3E3E3",
                                    }}
                                    disabled={inputsEnable}
                                  >
                                    {monthsMap.map((item) => {
                                      return (
                                        <Option value={item.year}>
                                          {item.year}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        style={{ margin: "1.25vw 0" }}
                        className="d-flex flex-col"
                      >
                        <span
                          className="w-100"
                          style={{
                            fontSize: "0.73vw",
                            fontWeight: 400,
                            color: "#2F2F2F",
                          }}
                        >
                          Specialisation{" "}
                          <span style={{ color: "#FF0000" }}></span>
                        </span>
                        <Form.Item
                          className="w-100"
                          name="specialization"
                          //   rules={[
                          //     { required: true, message: "Please enter month" },
                          //   ]}
                        >
                          {inputsEnable ? (
                            <Row className="w-100">
                              {JSON.parse(adminUserDetails.specialization)
                                .length > 0 ? (
                                JSON.parse(adminUserDetails.specialization).map(
                                  (data) => {
                                    return (
                                      <>
                                        <Col
                                          span={6}
                                          style={{ padding: "10px 0" }}
                                        >
                                          <Checkbox
                                            value="General Dentistry"
                                            style={{
                                              display: "flex",
                                              alignItems: "",
                                            }}
                                            checked
                                          >
                                            <span
                                              style={{
                                                color: "#333",
                                                fontSsize: 16,
                                                fontWeight: 500,
                                                padding: "0 0 0 12px",
                                              }}
                                            >
                                              {data}
                                            </span>
                                          </Checkbox>
                                        </Col>
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </Row>
                          ) : (
                            <Checkbox.Group
                              // disabled={!designationValue}
                              className="w-100 specialisation-checkbox"
                            >
                              <Row className="w-100">
                                <Col span={6} style={{ padding: "10px 0" }}>
                                  <Checkbox
                                    value="General Dentistry"
                                    style={{ display: "flex", alignItems: "" }}
                                  >
                                    <span
                                      style={{
                                        color: "#333",
                                        fontSsize: 16,
                                        fontWeight: 500,
                                        padding: "0 0 0 12px",
                                      }}
                                    >
                                      General Dentistry
                                    </span>
                                  </Checkbox>
                                </Col>
                                <Col span={6} style={{ padding: "10px 0" }}>
                                  <Checkbox
                                    value="Surgeon"
                                    style={{ display: "flex", alignItems: "" }}
                                  >
                                    <span
                                      style={{
                                        color: "#333",
                                        fontSsize: 16,
                                        fontWeight: 500,
                                        padding: "0 0 0 12px",
                                      }}
                                    >
                                      Surgeon
                                    </span>
                                  </Checkbox>
                                </Col>
                                <Col span={6} style={{ padding: "10px 0" }}>
                                  <Checkbox
                                    value="Pediatric Dentistry"
                                    style={{ display: "flex", alignItems: "" }}
                                  >
                                    <span
                                      style={{
                                        color: "#333",
                                        fontSsize: 16,
                                        fontWeight: 500,
                                        padding: "0 0 0 12px",
                                      }}
                                    >
                                      Pediatric Dentistry
                                    </span>
                                  </Checkbox>
                                </Col>
                                <Col span={6} style={{ padding: "10px 0" }}>
                                  <Checkbox
                                    value="Ortho dentistry"
                                    style={{ display: "flex", alignItems: "" }}
                                  >
                                    <span
                                      style={{
                                        color: "#333",
                                        fontSsize: 16,
                                        fontWeight: 500,
                                        padding: "0 0 0 12px",
                                      }}
                                    >
                                      Ortho dentistry
                                    </span>
                                  </Checkbox>
                                </Col>
                                <Col span={6} style={{ padding: "10px 0" }}>
                                  <Checkbox
                                    value="Periodontics"
                                    style={{ display: "flex", alignItems: "" }}
                                  >
                                    <span
                                      style={{
                                        color: "#333",
                                        fontSsize: 16,
                                        fontWeight: 500,
                                        padding: "0 0 0 12px",
                                      }}
                                    >
                                      Periodontics
                                    </span>
                                  </Checkbox>
                                </Col>
                                <Col
                                  span={6}
                                  style={{ padding: "10px 0" }}
                                ></Col>
                                <Col
                                  span={6}
                                  style={{ padding: "10px 0" }}
                                ></Col>
                                <Col
                                  span={6}
                                  style={{ padding: "10px 0" }}
                                ></Col>
                              </Row>
                            </Checkbox.Group>
                          )}
                        </Form.Item>
                      </Row>

                      <Row
                        className="w-100"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "20px 0",
                        }}
                      >
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 19,
                          }}
                        >
                          {inputsEnable ? (
                            <Button
                              style={{
                                height: "30px",
                                border: "1px solid #009DF7",
                                borderRadius: "30px",
                                color: "#009DF7",
                                background: "#FFF",
                              }}
                              onClick={() => {
                                setInputsEnable(false);
                              }}
                            >
                              Edit
                            </Button>
                          ) : (
                            <Button
                              style={{
                                height: "30px",
                                border: "1px solid red",
                                borderRadius: "30px",
                                color: "red",
                                background: "#red",
                              }}
                              onClick={() => {
                                setInputsEnable(true);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                          {inputsEnable === false && (
                            <Button
                              style={{
                                height: "30px",
                                width: "64px",
                                border: "1px solid #29C3C1",
                                borderRadius: "30px",
                                color: "#29C3C1",
                                background: "#ECFEFF",
                              }}
                              htmlType="submit"
                            >
                              Save
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                )}
              </div>
            )}
            {selectTab == 2 && <div>Insurance in progress</div>}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdministratorProfile;
