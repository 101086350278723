import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarPicture from './CalendarPicture';

const localizer = momentLocalizer(moment);


const StaffCalendar = ({ events }) => {
    return (
        <div style={{ height: '75vh' }}>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                views={['month', 'week', 'day']}
                defaultView="week"
                components={{
                    event: CustomEvent,
                }}
            />
        </div>
    );
};

const CustomEvent = ({ event }) => {
    const isSpecialEvent = ['Holiday', 'On Leave', 'Time Off'].includes(event.title);
    const isHoliday = ['Holiday'].includes(event.title)
    return (
        <div className={`event ${isSpecialEvent ? 'special-event' : ''}`}>
            {event.avatar && (
                <CalendarPicture
                    pro={
                        event.avatar &&
                        event.avatar.split(".com/")[1]
                    }
                />
            )}
            <div className="event-data">
                <span className='person-name'>{event.title}</span>
                {isHoliday && (
                    <>
                        <span className="event-type">{event.desc}</span>
                    </>
                )}
                {!isHoliday && (
                    <>
                        <div className='timings'>{moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')}</div>
                        <div className={`status ${event.status}`}>{event.status}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default StaffCalendar;