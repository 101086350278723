export const _scheduled_by_patient = "Patient";
export const _scheduled_by_clinic = "Clinic";
export const _status_scheduled = "Scheduled";
export const _status_rescheduled = "Rescheduled";
export const _status_confirmed = "Confirmed";
export const _status_in_progress = "In-Progress";
export const _status_rejected = "Declined";
export const _status_cancelled = "Cancelled";
export const _status_completed = "Complete";

export const DATETIME_FORMAT_WITH_FULL = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
export const DATETIME_FORMAT_WITH_FULL_TIME = "YYYY-MM-DDTHH:mm:ss";
export const DATETIME_FORMAT_WITHZONE = "THH:mm:ss.SSS[Z]";
export const DATETIME_FORMAT_AMPM = "HH:mm a";
export const DATETIME_FORMAT_FULLDATE = "YYYY-MM-DD";
export const DATETIME_FORMAT_FULLDATE_WITHDAY = "ddd, YYYY-MM-DD";
