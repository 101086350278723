import { SearchOutlined } from "@ant-design/icons";
import {
  Row,
  Input,
  Form,
  Col,
  Button,
  Select,
  Breadcrumb,
  message,
  Space,
} from "antd";
import { Option } from "antd/lib/mentions";
import axios from "axios";
import React from "react";
import "./AddClinicUserPatient.css";
import ProfilePic from "../../../../../assets/default-profile-pic.png";
import bloodgroupicon from "../../../../../assets/Blood-drop.png";
import LocationIcon from "../../../../../assets/location-icon.png";
import { routes_clinic_user_patients } from "../../../../../utils/clinic_user_constants/ClinicUserConstants";
import { options } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import { useNavigate } from "react-router";
import API_BASE_URL from "../../../../../env";

const AddClinicUserPatient = () => {
  const [form] = Form.useForm();
  const clinicId = localStorage.getItem("clinic_id");
  const Token = localStorage.getItem("Token");
  const navigate = useNavigate();

  const validatePhoneNumber = (rule, value, callback) => {
    const phoneNumberRegex = /^\d{10}$/; // Assumes the phone number is 10 digits long
    if (!phoneNumberRegex.test(value)) {
      callback("Please enter a valid 10 digit phone number");
    } else {
      callback();
    }
  };

  const handleAddPatientToClinic = (values) => {
    const payload = {
      name: values.patient_name,
      username: values.patient_username,
      email: values.patient_email,
      phone_country_code: values.patient_phone_code
        ? values.patient_phone_code
        : "+91",
      phone_number: values.patient_phone_number,
      address_line_one: values.patient_address,
      address_line_two: values.patient_apartment,
      city: values.patient_city,
      province: values.patient_state,
      country: values.patient_country,
      zip_code: values.patient_zipcode,
      insurance_id: values.patient_insurance_id,
      citizen_id: values.patient_citizen_id,
      blood_group: values.patient_blood_group,
      gender: values.patient_gender,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .post(
        `${API_BASE_URL}/api/v1/clinic/${clinicId}/patient`,
        payload,
        config
      )
      .then((res) => {
        message.success("Patient added successfully");
        navigate(routes_clinic_user_patients);
        form.resetFields();
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;

    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject("Please enter 6-digit zip code!");
  };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className="clinic-user-add-patient-main-div">
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "13px 23px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Add Patient
          </span>
          <Breadcrumb>
            <Breadcrumb.Item
              style={{
                background: "#2381C6",
                padding: "1px 10px",
                borderRadius: "3px",
              }}
            >
              <a href={routes_clinic_user_patients} style={{ color: "#FFF" }}>
                Patients
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{
                background: "#2381C6",
                padding: "1px 10px",
                borderRadius: "3px",
                color: "#FFF",
              }}
            >
              Add Patient
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Row>
      <Row className="w-100" style={{ padding: "0 0 0 23px" }}>
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          onFinish={handleAddPatientToClinic}
          autoComplete="off"
          className="w-100"
        >
          <Row className="d-flex">
            {/* col 1*/}
            <Col xs={24} sm={24} md={12} xl={6}>
              <div className="profile-pic-col-main-div">
                <div
                  style={{
                    padding: "3.3333333333333335vw",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <Row className="d-flex jc-center">
                    <div
                      style={{
                        borderRadius: "40%",
                        background: "#D9D9D9",
                        width: "118px",
                        height: "118px",
                      }}
                    >
                      <img
                        style={{
                          height: "118px",
                          width: "118px",
                          borderRadius: 40,
                        }}
                        src={ProfilePic}
                        alt=""
                      />
                    </div>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "27px 0 0 0",
                      gap: "2.302vh",
                    }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Name<span style={{ color: "red" }}>*</span>
                      </span>

                      <Form.Item
                        name="patient_name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter name",
                          },
                          {
                            min: 3,
                            message: "Name must be at least 3 characters long",
                          },
                          {
                            max: 100,
                            message:
                              "Name must be no more than 100 characters long",
                          },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and Name must contain only alphabeticals",
                          },
                          { whitespace: true, message: "No white spaces" },
                        ]}
                      >
                        <Input
                          name="patient_name"
                          maxLength={101}
                          label="patient_name"
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#525252",
                            height: "40px",
                            borderRadius: "3px",
                            border: "1px solid #E3E3E3",
                          }}
                          bordered={false}
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            const inputValue = e.target.value;
                            if (
                              !(
                                (charCode >= 65 && charCode <= 90) ||
                                (charCode >= 97 && charCode <= 122) ||
                                (charCode === 32 && inputValue.trim() !== "")
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Gender<span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="patient_gender"
                        rules={[
                          { required: true, message: "Please select Gender" },
                        ]}
                      >
                        <Select
                          name="patient_gender"
                          label="patient_gender"
                          bordered={false}
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#525252",
                            height: "40px",
                            borderRadius: "3px",
                            border: "1px solid #E3E3E3",
                          }}
                        >
                          <Option value="Male">Male</Option>
                          <Option value="Female">Female</Option>
                          <Option value="Others">Others</Option>
                        </Select>
                      </Form.Item>
                    </Row>
                  </Row>
                </div>
                <div
                  style={{
                    padding:
                      "3.7151702786377707vh 3.3333333333333335vw 4vh 3.3854166666666665vw",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#A5A4A4",
                    }}
                  >
                    Email<span style={{ color: "red" }}>*</span>
                  </span>

                  <Form.Item
                    name="patient_email"
                    rules={[
                      { required: true, message: "Please enter email" },
                      {
                        type: "email",
                        message: "Enter valid email address",
                      },
                      {
                        max: 320,
                        message:
                          "Email address must be no more than 320 characters long",
                      },
                    ]}
                  >
                    <Input
                      name="patient_email"
                      label="patient_email"
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#7D7D7D",
                        height: "40px",
                        borderRadius: "3px",
                        border: "1px solid #E3E3E3",
                      }}
                      bordered={false}
                      maxLength={321}
                    />
                  </Form.Item>
                </div>
                <div
                  style={{
                    padding:
                      "3.7151702786377707vh 3.3333333333333335vw 4.615384615384615vh 3.3854166666666665vw",
                  }}
                >
                  <Row>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Phone<span style={{ color: "red" }}>*</span>
                    </span>
                  </Row>

                  <Space.Compact>
                    <Form.Item name="patient_phone_code">
                      <Select
                        bordered={false}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: 40,
                          background: "#FFFFFF",
                          border: "1px solid #E3E3E3",
                          margin: "3px 0 0 0",
                        }}
                        defaultValue="+91"
                        options={options}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: "100%" }}
                      name="patient_phone_number"
                      rules={[{ validator: validatePhoneNumber }]}
                    >
                      <Input
                        bordered={false}
                        style={{
                          height: 40,
                          background: "#FFFFFF",
                          borderTop: "1px solid #E3E3E3",
                          borderRight: "1px solid #E3E3E3",
                          borderBottom: "1px solid #E3E3E3",
                          margin: "3px 0 0 0",
                        }}
                        defaultValue=""
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          if (!(charCode >= 48 && charCode <= 57)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Space.Compact>
                </div>
              </div>
            </Col>
            {/* address col */}
            <Col xs={24} sm={24} md={12} xl={6}>
              <div className="profile-pic-col-main-div">
                <Row style={{ padding: "22px" }}>
                  <Col span={2}>
                    <img style={{ height: "26px" }} src={LocationIcon} alt="" />
                  </Col>
                  <Col>
                    <span
                      style={{
                        fontWeight: "700",
                        fontSize: "18px",
                        color: "#525252",
                      }}
                    >
                      Address
                    </span>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5vh",
                    padding:
                      "1.3333333333333335vw 3.3333333333333335vw 3.3333333333333335vw 3.3333333333333335vw",
                  }}
                >
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Address
                    </span>
                    <Form.Item name="patient_address">
                      <Input
                        name="patient_address"
                        label="patient_address"
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#7D7D7D",
                          height: "40px",
                          border: "1px solid #E3E3E3",
                        }}
                        bordered={false}
                      />
                    </Form.Item>
                  </Row>

                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      City
                    </span>
                    <Form.Item
                      name="patient_city"
                      rules={[
                        { required: true, message: "Please enter City" },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and City must contain only alphabeticals",
                        },
                      ]}
                    >
                      <Input
                        name="patient_city"
                        label="patient_city"
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          height: "40px",
                          color: "#7D7D7D",
                          border: "1px solid #E3E3E3",
                        }}
                        bordered={false}
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          const inputValue = e.target.value;
                          if (
                            !(
                              (charCode >= 65 && charCode <= 90) ||
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode === 32 && inputValue.trim() !== "")
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Apartment / Suite ETC.,
                    </span>
                    <Form.Item name="patient_apartment">
                      <Input
                        name="patient_apartment"
                        label="patient_apartment"
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#7D7D7D",
                          height: "40px",
                          border: "1px solid #E3E3E3",
                        }}
                        bordered={false}
                      />
                    </Form.Item>
                  </Row>

                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Country
                    </span>
                    <Form.Item
                      name="patient_country"
                      rules={[
                        { required: true, message: "Please enter Country" },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and Country must contain only alphabeticals",
                        },
                      ]}
                    >
                      <Input
                        name="patient_country"
                        label="patient_country"
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          height: "40px",
                          color: "#7D7D7D",
                          border: "1px solid #E3E3E3",
                        }}
                        bordered={false}
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          const inputValue = e.target.value;
                          if (
                            !(
                              (charCode >= 65 && charCode <= 90) ||
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode === 32 && inputValue.trim() !== "")
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>

                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      State / Province
                    </span>
                    <Form.Item
                      name="patient_state"
                      rules={[
                        {
                          required: true,
                          message: "Please enter State / Province",
                        },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and State/Province must contain only alphabeticals",
                        },
                      ]}
                    >
                      <Input
                        name="patient_state"
                        label="patient_state"
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#7D7D7D",
                          height: "40px",
                          border: "1px solid #E3E3E3",
                        }}
                        bordered={false}
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          const inputValue = e.target.value;
                          if (
                            !(
                              (charCode >= 65 && charCode <= 90) ||
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode === 32 && inputValue.trim() !== "")
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>

                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Zip Code
                    </span>
                    <Form.Item
                      name="patient_zipcode"
                      rules={[{ validator: validateZipCode }]}
                    >
                      <Input
                        name="patient_zipcode"
                        label="patient_zipcode"
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          height: "40px",
                          color: "#7D7D7D",
                          border: "1px solid #E3E3E3",
                        }}
                        bordered={false}
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          if (!(charCode >= 48 && charCode <= 57)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>
                </Row>
              </div>
            </Col>
            {/* Blood group, Citizen ID, notification Col */}
            <Col xs={24} sm={24} md={12} xl={6}>
              <div>
                {/* Blood group */}
                <div
                  className="profile-pic-col-main-div"
                  style={{
                    margin: "0 0 2.5641025641025643vh 0",
                    padding: "1.74vh 24px",
                  }}
                >
                  <Row className="d-flex ai-center">
                    <Col>
                      <img
                        src={bloodgroupicon}
                        alt=""
                        style={{ width: "0.9895833333333334vw" }}
                      />
                    </Col>
                    <Col>
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: 18,
                          color: "#525252",
                          margin: "0 0 0 13px",
                        }}
                      >
                        Blood Group
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      margin: "5vh 0 6.2vh 0",
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Row className="w-100">
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          color: "#A5A4A4",
                        }}
                      >
                        Blood Group
                      </span>
                    </Row>
                    <Row className="w-100">
                      <Form.Item className="w-100" name="patient_blood_group">
                        <Select
                          name="patient_blood_group"
                          label="patient_blood_group"
                          bordered={false}
                          style={{
                            background: "#FFFFFF",
                            border: "1px solid #E3E3E3",
                            borderRadius: 3,
                            height: "40px",
                            color: "#7D7D7D",
                          }}
                        >
                          <Option value="A+">A+</Option>
                          <Option value="A-">A-</Option>
                          <Option value="B+">B+</Option>
                          <Option value="B-">B-</Option>
                          <Option value="O+">O+</Option>
                          <Option value="O-">O-</Option>
                          <Option value="AAB+">AB+</Option>
                          <Option value="AAB-">AB-</Option>
                        </Select>
                      </Form.Item>
                    </Row>
                  </Row>
                </div>

                <div
                  className="profile-pic-col-main-div"
                  style={{
                    minHeight: "22.76923076923077vh",
                    margin: "2.5641025641025643vh 0 0 0",
                    padding: "1.74vh 24px",
                  }}
                >
                  <Row
                    className=""
                    style={{
                      margin: "5vh 0 6.2vh 0",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Row className="w-100">
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          color: "#A5A4A4",
                        }}
                      >
                        Username<span style={{ color: "red" }}>*</span>
                      </span>
                    </Row>
                    <Row className="w-100">
                      <Form.Item
                        className="w-100"
                        name="patient_username"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Username!",
                          },
                          {
                            min: 4,
                            message: "Please enter minimum 4 characters",
                          },
                          { whitespace: true, message: "No white spaces" },
                          {
                            pattern: /^[a-zA-Z0-9._]+$/,
                            message:
                              "Username can only contain letters, numbers, fullstops, and underscores.",
                          },
                          {
                            max: 100,
                            message:
                              "Username should not be more than 100 characters long",
                          },
                        ]}
                      >
                        <Input
                          name="patient_username"
                          label="patient_username"
                          className="insurance-id-input"
                          onKeyPress={handleUserNameKeyPress}
                          maxLength={101}
                        />
                      </Form.Item>
                    </Row>
                  </Row>
                </div>
                {/* notification to patient */}
              </div>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "19px",
              margin: "20px 0 50px 0",
            }}
          >
            <Button
              style={{
                height: "30px",
                border: "1px solid #F7927E",
                borderRadius: "30px",
                color: "#F7927E",
                background: "#FFF6F2",
              }}
              onClick={() => {
                form.resetFields();
                navigate(routes_clinic_user_patients);
              }}
            >
              Cancel
            </Button>
            <Form.Item>
              <Button
                style={{
                  height: "30px",
                  width: "64px",
                  border: "1px solid #29C3C1",
                  borderRadius: "30px",
                  color: "#29C3C1",
                  background: "#ECFEFF",
                }}
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

export default AddClinicUserPatient;
