import { Button, Col, Row, Spin, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import "./ClinicUserWorkinghours.css"
import TimeOffRequests from "./time_off_requests/TimeOffRequests";

const ClinicUserWorkinghours = () => {
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 50, margin: "130px 0 0 0" }} spin />
    );
    const navigate = useNavigate();
    const Token = localStorage.getItem("Token");
    const clinic_id = localStorage.getItem("clinic_id");
    const branch_id = localStorage.getItem("main_branch_id") ? localStorage.getItem("main_branch_id") : localStorage.getItem("branch_id");
    const [spinner, setspinner] = useState(false);

    const workingHourItems = [
        {
            key: "1",
            label: (
                <div className="d-flex">
                    Working Hours
                </div>
            ),
            children: (
                <div></div>
            )
        },
        {
            key: "2",
            label: (
                <div className="d-flex">
                    Time off Requests
                </div>
            ),
            children: (
                <Col
                    style={{
                        padding: "0 0.52vw 0 0",
                        width: "58vw"
                    }}>
                    <Row style={{
                            marginTop: "-6.3vw"
                    }}>
                        <TimeOffRequests />
                    </Row>
                </Col>
            )
        }
    ]

    return (
        <div className="clinic-user-workinghours-main-div">
            <Helmet>
                <title>Working Hours</title>
            </Helmet>
            <Spin
                indicator={antIcon}
                size="large"
                spinning={spinner && true}
                delay={500}
            >
                <Row className="clinic-user-workinghours">
                    <Col 
                        style={{
                            padding: "2.4vw 0"
                        }}>
                        <Row style={{
                            padding: "0 2.0834vw 0 2.7vw",
                            display: "flex",
                            flexDirection: "column"
                            }}>
                            <span style={{
                                width: "13vw",
                                color: "#333",
                                fontSize: "1.25vw",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "140.04%"
                            }}>View and Manage Working Hours</span>

                            <Tabs
                            className="time-off-req-tabs"
                                defaultActiveKey="1"
                                tabPosition={"left"}
                                style={{
                                    height: "70vh",
                                    paddingTop: "2.3vw"
                                }}
                                items={workingHourItems}
                            />
                        </Row>
                    </Col>
                </Row>
            </Spin >
        </div >
    );
};

export default ClinicUserWorkinghours;