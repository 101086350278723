import axios from "axios";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { tabs_admin_dashboard } from "../utils/clinic_admin_constants/ClinicAdminConstants";
import { tabs_dashboard } from "../utils/patient_navbar_constants/PatientNavbarConstants";
import { routes_super_admin_dashboard_register_requests } from "../utils/super_admin_constants/SuperAdminConsts";
import API_BASE_URL from "../../src/env";
import { message } from "antd";
import {
  DATETIME_FORMAT_FULLDATE,
  DATETIME_FORMAT_WITH_FULL,
  _status_cancelled,
} from "../utils/appointment_constants/AppointmentConstants";

const UserContext = createContext();
export const AuthContextProvider = ({ children }) => {
  const clinic_id = localStorage.getItem("clinic_id");
  const role = localStorage.getItem("role");
  const patient_id = localStorage.getItem("patient_id");
  const branch_id = localStorage.getItem("branch_id");
  const Token = localStorage.getItem("Token");
  const [tabActiveKey, setTabActiveKey] = useState(tabs_dashboard);
  const [patientUserDetails, setPatientUserDetails] = useState(null);
  const [adminTabActiveKey, setAdminTabActiveKey] =
    useState(tabs_admin_dashboard);
  const [inputsEnable, setInputsEnable] = useState(true);
  const [allPatientNotificationsMap, setAllPatientNotificationsMap] =
    useState();
  const [clinicDetails, setClinicDetails] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerSetPatientID, setOpenDrawerSetPatientID] = useState(false);
  const [appointmentDataForClinic, setAppointmentDataForClinic] = useState("");
  // const [filterAppointmentsByDate, setFilterAppointmentsByDate] = useState();
  const [supertAdminClinicModuleTabs, setSupertAdminClinicModuleTabs] =
    useState(routes_super_admin_dashboard_register_requests);
  const [rectangles, setRectangles] = useState();
  const [reportRefresh, setReportRefresh] = useState(false);
  const [s3Url, setS3Url] = useState();
  const [s3UrlForClinic, sets3UrlForClinic] = useState();
  const [spinner, setSpinner] = useState(false);
  const [commonArray, setcommonArray] = useState();
  const [signupUserName, setSignupUserName] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [appointmentTableData, setAppointmentTableData] = useState();
  const [allDoctorsSlots, setallDoctorsSlots] = useState();
  const [enableBranchInputs, setEnableBranchInputs] = useState(true);
  const [appointmentSpinner, setAppointmentSpinner] = useState(false);
  const [super_admin_clinic_route_key, setSuperAdminClinicRouteKey] =
    useState("3");
  const [filteredDate, setFilteredDate] = useState();

  const [rescheduleData, setRescheduleData] = useState();
  const [openRescheduleReasonModal, setopenRescheduleReasonModal] =
    useState(false);
  const [cancellationReason, setCancellationReason] = useState(
    role === "STANDARD" ? "Scheduling conflicts" : "Staffing Shortage"
  );

  // Patient Flow
  const handleGetAllPatientNotifications = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (patient_id) {
      axios
        .get(
          `${API_BASE_URL}/api/v1/notification/patient/${patient_id}`,
          config
        )
        .then((res) => {
          setAllPatientNotificationsMap(res.data.data);
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
        });
    }
  };

  const handleGetAllClinicDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios.get(`${API_BASE_URL}/api/v1/clinic`, config).then((res) => {
      setClinicDetails(res.data.data);
    });
  };

  const handleGetPatientDetails = async () => {
    setS3Url(null);
    setPatientUserDetails(null);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/patient/${patient_id}`, config)
      .then((res) => {
        setPatientUserDetails(res.data.data);
        setS3Url(
          res.data.data.profile_picture_url &&
            res.data.data.profile_picture_url.split(".com/")[1]
        );
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const handleGetClinicDetails = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (branch_id) {
      await axios
        .get(
          `${API_BASE_URL}/api/v1/clinicBranch/fetchBranchDetails/${branch_id}`,
          config
        )
        .then((res) => {
          setPatientUserDetails(res.data.data);
          sets3UrlForClinic(
            res.data.data.profile_picture_url &&
              res.data.data.profile_picture_url.split(".com/")[1]
          );
        });
    } else if (!branch_id) {
      await axios
        .get(`${API_BASE_URL}/api/v1/clinic/${clinic_id}`, config)
        .then((res) => {
          setPatientUserDetails(res.data.data);
          sets3UrlForClinic(
            res.data.data.profile_picture_url &&
              res.data.data.profile_picture_url.split(".com/")[1]
          );
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
        });
    }
  };

  // Clinic Flow
  const handleGetAllAppointmentsForClinic = (selected_date) => {
    setAppointmentSpinner(true);
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    // if (branch_id) {
    axios
      .get(
        `${API_BASE_URL}/api/v1/appointment/clinic/${clinic_id}${
          branch_id ? `?branch_id=${branch_id}` : ``
        }`,
        config
      )
      .then((res) => {
        setAppointmentDataForClinic(res.data.data);
        setFilteredDate(null);
        setAppointmentSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
    // } else {
    //   axios
    //     .get(`${API_BASE_URL}/api/v1/appointment/clinic/${clinic_id}`, config)
    //     .then((res) => {
    //       setAppointmentDataForClinic(res.data.data);
    //       setAppointmentSpinner(false);
    //     });
    // }
  };

  // Function to get all the appointments that are scheduled for patient
  const handleGetAllAppointments = (fromDate) => {
    setSpinner(true);
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/appointment/patient/${patient_id}/{fromDate}${
          fromDate ? `?fromDate=${fromDate}` : ""
        }`,
        config
      )
      .then((res) => {
        // setAppointmentTableData(res.data.data);
        setAppointmentDataForClinic(res.data.data);
        setFilteredDate(null);
        setAppointmentSpinner(false);
        // setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const handleGetAllSlotDetails = (clinic_id, selected_date, branch_id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (branch_id) {
      axios
        .get(
          `${API_BASE_URL}/api/v1/slots/getAvailableSlots/${clinic_id}?date=${selected_date}&branchId=${branch_id}`,
          config
        )
        .then((res) => {
          setSpinner(false);
          setallDoctorsSlots(res.data.data);
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
        });
    } else {
      axios
        .get(
          `${API_BASE_URL}/api/v1/slots/getAvailableSlots/${clinic_id}?date=${selected_date}`,
          config
        )
        .then((res) => {
          setSpinner(false);
          setallDoctorsSlots(res.data.data);
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
        });
    }
  };

  const handleGetAllDoctorSlots = (doc_id, date, branch_id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    // if (branch_id) {
    axios
      .get(
        `${API_BASE_URL}/api/v1/slots/getSlots/${doc_id}?date=${moment(
          date
        ).format(DATETIME_FORMAT_FULLDATE)}`,
        config
      )
      .then((res) => {
        setSpinner(false);
        setallDoctorsSlots(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
    // } else {
    //   axios
    //     .get(
    //       `${API_BASE_URL}/api/v1/slots/getAvailableSlots/${clinic_id}?date=${selected_date}`,
    //       config
    //     )
    //     .then((res) => {
    //       setSpinner(false);
    //       setallDoctorsSlots(res.data.data);
    //     })
    //     .catch((err) => {
    //       message.error(err.response.data.data.errors);
    //     });
    // }
  };

  const handleCancelPatientAppointment = (data, _scheduled_by) => {
    let payload;
    setAppointmentSpinner(true);
    if (branch_id) {
      payload = {
        patient_id: data.patient_id,
        clinic_id: data.clinic.id,
        treatment_type_id: data.treatment_type.id,
        from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
        scheduled_by: _scheduled_by,
        status: _status_cancelled,
        doc_id: data.doctor.id,
        doc_slot: data.slots.id,
        notes: "",
        branch_id: branch_id,
        reason: cancellationReason,
        previous_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
      };
    } else {
      payload = {
        patient_id: data.patient_id,
        clinic_id: data.clinic.id,
        treatment_type_id: data.treatment_type.id,
        from_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
        scheduled_by: _scheduled_by,
        status: _status_cancelled,
        doc_id: data.doctor.id,
        doc_slot: data.slots.id,
        notes: "",
        reason: cancellationReason,
        previous_date: moment(data.from_date).format(DATETIME_FORMAT_WITH_FULL),
      };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(`${API_BASE_URL}/api/v1/appointment/${data.id}`, payload, config)
      .then((res) => {
        setopenRescheduleReasonModal(false);
        if (role === "STANDARD") {
          handleGetAllAppointments();
        } else {
          handleGetAllAppointmentsForClinic();
        }
        message.success("Appointment Cancelled");
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setAppointmentSpinner(false);
      });
  };

  return (
    <UserContext.Provider
      value={{
        setCancellationReason,
        handleGetAllAppointments,
        cancellationReason,
        handleCancelPatientAppointment,
        setopenRescheduleReasonModal,
        openRescheduleReasonModal,
        setRescheduleData,
        rescheduleData,
        setTabActiveKey,
        tabActiveKey,
        setInputsEnable,
        inputsEnable,
        allPatientNotificationsMap,
        setAllPatientNotificationsMap,
        handleGetAllPatientNotifications,
        handleGetAllClinicDetails,
        clinicDetails,
        setAdminTabActiveKey,
        adminTabActiveKey,
        openDrawer,
        setOpenDrawer,
        openDrawerSetPatientID,
        setOpenDrawerSetPatientID,
        setPatientUserDetails,
        patientUserDetails,
        handleGetPatientDetails,
        handleGetAllAppointmentsForClinic,
        appointmentDataForClinic,
        setAppointmentDataForClinic,
        // setFilterAppointmentsByDate,
        // filterAppointmentsByDate,
        setSupertAdminClinicModuleTabs,
        supertAdminClinicModuleTabs,
        setRectangles,
        rectangles,
        setReportRefresh,
        reportRefresh,
        setSpinner,
        spinner,
        s3Url,
        setS3Url,
        setcommonArray,
        commonArray,
        handleGetClinicDetails,
        sets3UrlForClinic,
        s3UrlForClinic,
        signupUserName,
        setSignupUserName,
        latitude,
        setlatitude,
        longitude,
        setlongitude,
        appointmentTableData,
        setAppointmentTableData,
        allDoctorsSlots,
        setallDoctorsSlots,
        handleGetAllSlotDetails,
        enableBranchInputs,
        setEnableBranchInputs,
        setAppointmentSpinner,
        appointmentSpinner,
        super_admin_clinic_route_key,
        setSuperAdminClinicRouteKey,
        filteredDate,
        setFilteredDate,
        handleGetAllDoctorSlots,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const UserAuth = () => {
  return useContext(UserContext);
};
