import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Calendar,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Segmented,
  Select,
  Space,
  message,
} from "antd";
import { Option } from "antd/lib/mentions";
import AWS from "aws-sdk";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import calIcon from "../../../../../../assets/cal-icon.png";
import ProfilePic from "../../../../../../assets/default-profile-pic.png";
import { UserAuth } from "../../../../../../context_api/ContextApi";
import API_BASE_URL from "../../../../../../env";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../../string";
import { options } from "../../../../../../utils/super_admin_constants/SuperAdminConsts";
import "./PatientProfile.css";

export const statusOptions = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Paid",
    label: "Paid",
  },
  {
    value: "Pending",
    label: "Pending",
  },
];

const PatientProfile = (props) => {
  const {
    patientUserDetails,
    getPatientDetails,
    setSpinner,
    form,
    patientPaymentHistory,
    treatmentHistory,
    setTreatmentHistoryDetails,
    route,
    patientTabsData,
    setPatientTabsData,
    isInvoiceClicked,
    setInvoiceClicked,
    invoiceDetails,
    setInvoiceDetails,
    setInvoiceNumber,
    patient_id
  } = props;
  const profile_picture_url =
    patientUserDetails &&
    patientUserDetails.profile_picture_url &&
    patientUserDetails.profile_picture_url.split(".com/")[1];
  const patientid = localStorage.getItem("patient_id");
  const clinic_id = localStorage.getItem("clinic_id");
  const { url_clinic_id } = useParams();
  const Token = localStorage.getItem("Token");
  const [inputsEnable, setInputsEnable] = useState(true);
  const currentUrl = window.location.href;
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [displayDate, setDisplayDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState({
    year: moment().format("yyyy"),
    month: moment().format("MM"),
  });
  const [params, setParams] = useState({});

  const { handleGetPatientDetails } = UserAuth();

  // For profile Input fields
  const enableFormFields = () => {
    setInputsEnable(false);
  };

  const handleUpdatePatientDetails = async (values) => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    const payload = {
      name: values.name,
      email: values.email,
      phone_country_code: values.phone_country_code,
      phone_number: values.phone_number,
      address_line_one: values.address,
      address_line_two: values.apartment,
      city: values.city,
      province: values.province,
      country: values.country,
      zip_code: values.zip_code,
      insurance_id: values.insuranceID,
      citizen_id: values.citizenID,
      blood_group: values.blood_group,
      gender: values.gender,
      branch_id: "",
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
    };
    await axios
      .put(`${API_BASE_URL}/api/v1/patient/${patientid}`, payload, config)
      .then((res) => {
        getPatientDetails();
        handleGetPatientDetails();
        message.success("Patient Updated Successfully");
        setInputsEnable(true);
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors[0].error_message);
        setSpinner(false);
      });
  };

  const handleEditPatientInClinic = (values) => {
    setSpinner(true);
    const payload = {
      name: values.name,
      email: values.email,
      phone_country_code: values.phone_country_code,
      phone_number: values.phone_number,
      address_line_one: values.address,
      address_line_two: values.apartment,
      city: values.city,
      province: values.province,
      country: values.country,
      zip_code: values.zip_code,
      insurance_id: values.insurance_id,
      citizen_id: values.citizen_id,
      blood_group: values.blood_group,
      gender: values.gender,
      branch_id: "",
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
    };
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/${url_clinic_id ? url_clinic_id : clinic_id
        }/patient/${patient_id}`,
        payload,
        config
      )
      .then((res) => {
        message.success("Patient Updated successfully");
        setInputsEnable(true);
        getPatientDetails();
        handleGetPatientDetails();
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const validatePhoneNumber = (_, value) => {
    const numericRegex = /^[0-9]+$/;
    if (!value || (numericRegex.test(value) && value.length === 10)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Please enter exactly 10 numeric characters for the phone number."
    );
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;

    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject("Please enter 6-digit zip code!");
  };

  useEffect(() => {
    getPatientDetails();
  }, []);

  const [fileContent, setFileContent] = useState("");
  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: profile_picture_url,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [profile_picture_url]);

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleImageClick = () => {
    if (route === "patient") {
      document.getElementById("fileInput").click();
    }
  };

  const location = useLocation();
  const key = location.pathname;

  const handleFileChange = async (event) => {
    const propic = event.target.files[0];
    let formData = new FormData();
    formData.append("file", propic);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .post(
        `${API_BASE_URL}/api/v1/patient/${patientid}/upload-profile-picture`,
        formData,
        config
      )
      .then((res) => {
        handleGetPatientDetails();
        getPatientDetails();
        message.success("Updated Profile Picture");
        setSpinner(false);
      });
  };

  const handleByDateClick = () => {
    setOpenCalendarModal(true);
  };

  const onPanelChange = (date, mode) => {
    setCurrentDate({ year: date.year(), month: date.month() + 1 });
    setDisplayDate(date); // Update display date
  };

  const onDateSelect = (date) => {
    setSelectedDate(null);
    setDisplayDate(date);
    setSelectedDate(date);
  };

  const handleApplyButton = () => {
    setOpenCalendarModal(false);
    let date = moment(selectedDate).format("yyyy-MM-DD");
    setDisplayDate(date);
    handleStatusChange("fromDate", date);
  };

  const baseUrl = `${API_BASE_URL}/api/v1/treatments/treatment_payment_history/patient?patient_id=${patientid}`;

  // Function to construct new URL with updated query params
  const constructUrl = (baseUrl, paramsToAdd) => {
    const url = new URL(baseUrl);
    const queryParams = new URLSearchParams(url.search);
    for (const key in paramsToAdd) {
      if (paramsToAdd[key]) {
        queryParams.set(key, paramsToAdd[key]);
      } else {
        queryParams.delete(key);
      }
    }
    url.search = queryParams.toString();
    return url.toString();
  };

  const handleStatusChange = (key, value) => {
    if (value === "Paid") {
      setParams({
        ...params,
        [key]: "captured",
      });
    } else if (value === "Pending") {
      setParams({
        ...params,
        [key]: "failed",
      });
    } else if (
      key === "fromDate" ||
      key === "clinicNameOrDoctorNameOrTreatmentName"
    ) {
      setParams({
        ...params,
        [key]: value,
      });
    } else {
      setParams({
        ...params,
        [key]: "",
      });
    }
  };

  const newUrl = constructUrl(baseUrl, params);

  useEffect(() => {
    getTreatmentHistoryByFilters();
  }, [newUrl]);

  const getTreatmentHistoryByFilters = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios.get(newUrl, config).then((res) => {
      setTreatmentHistoryDetails(res.data.data);
      setSpinner(false);
    });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(item) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const payload = {
      type: "invoice",
      description: `Invoice for ${patientUserDetails.name}`,
      partialPayment: true,
      customer: {
        name: patientUserDetails.name,
        contact: patientUserDetails.phone_number,
        email: patientUserDetails.email,
        billingAddress: {
          // line1: patientUserDetails.address_line_one,
          line2: patientUserDetails.address_line_one,
          zipcode: patientUserDetails.zip_code,
          city: patientUserDetails.city,
          state: patientUserDetails.province,
          country: patientUserDetails.country,
        },
      },
      line_items: [
        {
          name: item.treatment_name,
          description: item.description,
          // amount: item.treatment_type.rate,
          amount: item.rate ? item.rate : 10,
          currency: "INR",
          quantity: 1,
        },
      ],
      smsNotify: 1,
      emailNotify: 1,
      currency: "INR",
      expireBy: 1765046400,
    };

    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    const response = await axios.post(
      `http://ec2-43-205-173-70.ap-south-1.compute.amazonaws.com:8080/api/v1/payment/generateInvoice`,
      payload,
      config
    );

    window.location.href = response.data;
  }

  return (
    <Form
      form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={
        currentUrl.includes("clinic")
          ? handleEditPatientInClinic
          : handleUpdatePatientDetails
      }
      autoComplete="off"
      className="w-100"
      style={{
        minWidth: "60vw",
        background: "#fff",
        padding: "2.0834vw 1.0417vw 0",
        borderRadius: "0.52vw",
      }}
    >
      <div style={{ padding: "0 2.0834vw" }}>
        <Row
          style={{
            margin: "0 1.5625vw",
            gap: "1.563vw",
            borderBottom: "1px solid #A8A8A8",
            padding: "0 0 1.25vw 0",
          }}
          className="d-flex ai-center"
        >
          <Col>
            <img
              preview={fileContent && true}
              style={{
                height: "5.313vw",
                width: "5.313vw",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              src={fileContent ? fileContent : ProfilePic}
              alt=""
              onClick={handleImageClick}
            // src={previewSrc}
            // alt="Profile"
            />
            <input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Col>
          <Col>
            <Row>
              <span
                style={{
                  fontSize: "1.25vw",
                  fontWeight: 700,
                  color: "#333333",
                }}
              >
                My Profile
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontSize: "0.9375vw",
                  fontWeight: 400,
                  color: "#717171",
                }}
              >
                View and manage your personal information, insurance details,
                and request status below.
              </span>
            </Row>
          </Col>
        </Row>
      </div>
      <div
        style={{
          padding: "0 2.0834vw",
          margin: "1.0417vw 1.3020834vw -1.0417vw",
        }}
      >
        {route === "patient" && (
          <Segmented
            size="large"
            block
            options={[
              "Personal Information",
              "Insurance Request Status",
              "Treatment History",
              // "Payment History",
            ]}
            onChange={(value) => {
              setPatientTabsData(value);
            }}
          />
        )}
      </div>
      {patientTabsData === "Personal Information" && (
        <>
          <div
            style={{
              padding: "1.0417vw 2.0834vw 0 2.0834vw",
            }}
          >
            <Row style={{ margin: "1.25vw 0" }}>
              <Col span={8} style={{ padding: "0 1.5625vw" }}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="w-100"
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Name <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  {patientUserDetails && (
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter name",
                        },
                        {
                          min: 3,
                          message: "Name must be at least 3 characters long",
                        },
                        {
                          max: 100,
                          message:
                            "Name must be no more than 100 characters long",
                        },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and Name must contain only alphabeticals",
                        },
                        { whitespace: true, message: "No white spaces" },
                      ]}
                      initialValue={patientUserDetails.name}
                      className="w-100"
                    >
                      <Input
                        name="name"
                        maxLength={101}
                        style={{
                          width: "100%",
                          margin: "5px 0 0 0",
                          height: "4.796663190823775vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          fontWeight: "400",
                          fontSize: "0.8333333333333334vw",
                          color: "#525252",
                        }}
                        bordered={false}
                        disabled={inputsEnable}
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          const inputValue = e.target.value;
                          if (
                            !(
                              (charCode >= 65 && charCode <= 90) ||
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode === 32 && inputValue.trim() !== "")
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col>
              <Col
                span={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "0 1.5625vw",
                }}
              >
                <Row
                  className="w-100"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Patient Identification{" "}
                    <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  {patientUserDetails && (
                    <Input
                      name="id"
                      style={{
                        width: "100%",
                        margin: "5px 0 0 0",
                        height: "4.796663190823775vh",
                        borderRadius: 3,
                        border: "1px solid #DADADA",

                        fontWeight: "400",
                        fontSize: "0.8333333333333334vw",
                        color: "#525252",
                        background: "#FFF8E7",
                      }}
                      disabled={true}
                      bordered={false}
                      defaultValue={"PID#" + patientUserDetails.user_id}
                    />
                  )}
                </Row>
              </Col>
              <Col
                span={8}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0 1.5625vw",
                }}
              >
                <Row
                  style={{ display: "flex", flexDirection: "column" }}
                  className="w-100"
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Gender <span style={{ color: "#FF0000" }}></span>
                  </span>
                  {patientUserDetails && (
                    <Form.Item
                      name="gender"
                      initialValue={patientUserDetails.gender}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select Gender",
                    //   },
                    // ]}
                    >
                      <Select
                        name="gender"
                        bordered={false}
                        disabled={inputsEnable}
                        style={{
                          fontWeight: "400",
                          fontSize: "0.8333333333333334vw",
                          color: "#7D7D7D",
                          width: "100%",
                          margin: "5px 0 0 0",
                          height: "4.796663190823775vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                        <Option value="Others">Others</Option>
                      </Select>
                    </Form.Item>
                  )}
                </Row>
              </Col>
            </Row>
            <Row style={{ margin: "1.25vw 0" }}>
              <Col span={8} style={{ padding: "0 1.5625vw" }}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="w-100"
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Email <span style={{ color: "#FF0000" }}>*</span>
                  </span>

                  {patientUserDetails && (
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Please enter email" },
                        {
                          type: "email",
                          message: "Enter valid email address",
                        },
                        {
                          max: 320,
                          message:
                            "Email address must be no more than 320 characters long",
                        },
                      ]}
                      initialValue={patientUserDetails.email}
                    >
                      <Input
                        name="email"
                        disabled={inputsEnable}
                        style={{
                          width: "100%",
                          margin: "5px 0 0 0",
                          height: "4.796663190823775vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          fontWeight: "400",
                          fontSize: "0.8333333333333334vw",
                          color: "#7D7D7D",
                        }}
                        bordered={false}
                        maxLength={321}
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col>
              <Col
                span={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "0 1.5625vw",
                }}
              >
                <Row
                  className="w-100"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Phone No. <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  {patientUserDetails && (
                    <Space.Compact>
                      <Form.Item
                        name="phone_country_code"
                        initialValue={patientUserDetails.phone_country_code}
                      >
                        <Select
                          disabled={inputsEnable}
                          bordered={false}
                          style={{
                            width: "100%",
                            margin: "5px 0 0 0",
                            height: "4.796663190823775vh",
                            borderRadius: "3px 0 0 3px",
                            border: "1px solid #DADADA",
                            display: "flex",
                            alignItems: "center",
                            background: "#FFFFFF",
                            fontWeight: "400",
                            fontSize: "0.8333333333333334vw",
                            color: "#7D7D7D",
                          }}
                          defaultValue="Select"
                          options={options}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "100%" }}
                        name="phone_number"
                        initialValue={patientUserDetails.phone_number}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Phone number",
                          },
                          { validator: validatePhoneNumber },
                        ]}
                      >
                        <Input
                          disabled={inputsEnable}
                          bordered={false}
                          style={{
                            width: "100%",
                            margin: "5px 0 0 0",
                            height: "4.796663190823775vh",
                            borderRadius: "0 3px 3px 0",
                            fontWeight: "400",
                            fontSize: "0.8333333333333334vw",
                            color: "#7D7D7D",
                            background: "#FFFFFF",
                            borderTop: "1px solid #DADADA",
                            borderRight: "1px solid #DADADA",
                            borderBottom: "1px solid #DADADA",
                          }}
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            if (!(charCode >= 48 && charCode <= 57)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Space.Compact>
                  )}
                </Row>
              </Col>
              <Col
                span={8}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0 1.5625vw",
                }}
              >
                <Row
                  style={{ display: "flex", flexDirection: "column" }}
                  className="w-100"
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Blood Group <span style={{ color: "#FF0000" }}></span>
                  </span>
                  {patientUserDetails && (
                    <Form.Item
                      initialValue={
                        patientUserDetails.blood_group &&
                        patientUserDetails.blood_group
                        // : "NA"
                      }
                      className="w-100"
                      name="blood_group"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select Blood group",
                    //   },
                    // ]}
                    >
                      <Select
                        placeholder={inputsEnable ? "NA" : "Select Blood Group"}
                        name="blood_group"
                        disabled={inputsEnable}
                        bordered={false}
                        style={{
                          fontWeight: "400",
                          fontSize: "0.8333333333333334vw",
                          color: "#7D7D7D",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          margin: "5px 0 0 0",
                          height: "4.796663190823775vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          background: "#FFFFFF",
                        }}
                      >
                        <Option value="A+">A+</Option>
                        <Option value="A-">A-</Option>
                        <Option value="B+">B+</Option>
                        <Option value="B-">B-</Option>
                        <Option value="O+">O+</Option>
                        <Option value="O-">O-</Option>
                        <Option value="AAB+">AB+</Option>
                        <Option value="AAB-">AB-</Option>
                      </Select>
                    </Form.Item>
                  )}
                </Row>
              </Col>
            </Row>
            <Row style={{ margin: "1.25vw 0" }}>
              <Col span={8} style={{ padding: "0 1.5625vw" }}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="w-100"
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Date of Birth <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  {patientUserDetails && (
                    <Form.Item
                      name="date_of_birth"
                      initialValue={
                        patientUserDetails.date_of_birth &&
                        moment(patientUserDetails.date_of_birth)
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Age",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={disabledDate}
                        allowClear={false}
                        disabled={inputsEnable}
                        style={{
                          fontWeight: "400",
                          fontSize: "0.8333333333333334vw",
                          color: "#7D7D7D",
                          width: "100%",
                          margin: "5px 0 0 0",
                          height: "4.796663190823775vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          background: "#FFF",
                        }}
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
          <div style={{ padding: "0 2.0834vw" }}>
            <Row
              style={{
                borderBottom: "1px solid #A8A8A8",
                margin: "0px 1.3020833333333333vw",
                padding: "2px 0px",
              }}
            >
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 600,
                  color: "#B3B9BD",
                }}
              >
                Address Information
              </span>
            </Row>
            <Row style={{ margin: "25px 0" }}>
              <Col span={8} style={{ padding: "0 1.5625vw" }}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="w-100"
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Address <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  {patientUserDetails && (
                    <Form.Item
                      initialValue={patientUserDetails.address_line_one}
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Adress",
                        },
                      ]}
                    >
                      <Input
                        name="address"
                        disabled={inputsEnable}
                        style={{
                          fontWeight: "400",
                          fontSize: "0.8333333333333334vw",
                          color: "#7D7D7D",
                          height: "4.796663190823775vh",
                          border: "1px solid #E3E3E3",
                          width: "100%",
                          margin: "5px 0 0 0",
                          borderRadius: 3,
                        }}
                        bordered={false}
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col>
              {/* <Col
                span={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "0 1.5625vw",
                }}
              >
                <Row
                  className="w-100"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Apartment <span style={{ color: "#FF0000" }}></span>
                  </span>
                  {patientUserDetails && (
                    <Form.Item
                      name="apartment"
                      initialValue={patientUserDetails.address_line_two}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter your Apartment",
                    //   },
                    // ]}
                    >
                      <Input
                        name="apartment"
                        disabled={inputsEnable}
                        style={{
                          width: "100%",
                          margin: "5px 0 0 0",
                          height: "4.796663190823775vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          fontWeight: "400",
                          fontSize: "0.8333333333333334vw",
                          color: "#7D7D7D",
                        }}
                        bordered={false}
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col> */}
              <Col
                span={8}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0 1.5625vw",
                }}
              >
                <Row
                  style={{ display: "flex", flexDirection: "column" }}
                  className="w-100"
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    City <span style={{ color: "#FF0000" }}>*</span>
                  </span>

                  {patientUserDetails && (
                    <Form.Item
                      initialValue={patientUserDetails.city}
                      name="city"
                      rules={[
                        { required: true, message: "Please enter City" },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and City must contain only alphabeticals",
                        },
                      ]}
                    >
                      <Input
                        name="city"
                        disabled={inputsEnable}
                        style={{
                          width: "100%",
                          margin: "5px 0 0 0",
                          height: "4.796663190823775vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          fontWeight: "400",
                          fontSize: "0.8333333333333334vw",
                          color: "#7D7D7D",
                        }}
                        bordered={false}
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          const inputValue = e.target.value;
                          if (
                            !(
                              (charCode >= 65 && charCode <= 90) ||
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode === 32 && inputValue.trim() !== "")
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col>
            </Row>
            <Row style={{ margin: "25px 0" }}>
              <Col span={8} style={{ padding: "0 1.5625vw" }}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="w-100"
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    State <span style={{ color: "#FF0000" }}>*</span>
                  </span>

                  {patientUserDetails && (
                    <Form.Item
                      name="province"
                      initialValue={patientUserDetails.province}
                      rules={[
                        {
                          required: true,
                          message: "Please enter State / Province",
                        },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and State/Province must contain only alphabeticals",
                        },
                      ]}
                    >
                      <Input
                        name="province"
                        disabled={inputsEnable}
                        style={{
                          width: "100%",
                          margin: "5px 0 0 0",
                          height: "4.796663190823775vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          fontWeight: "400",
                          fontSize: "0.8333333333333334vw",
                          color: "#7D7D7D",
                        }}
                        bordered={false}
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          const inputValue = e.target.value;
                          if (
                            !(
                              (charCode >= 65 && charCode <= 90) ||
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode === 32 && inputValue.trim() !== "")
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col>
              <Col
                span={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "0 1.5625vw",
                }}
              >
                <Row
                  className="w-100"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Country <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  {patientUserDetails && (
                    <Form.Item
                      initialValue={patientUserDetails.country}
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Country",
                        },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and Country must contain only alphabeticals",
                        },
                      ]}
                    >
                      <Input
                        name="country"
                        disabled={inputsEnable}
                        style={{
                          fontWeight: "400",
                          width: "100%",
                          margin: "5px 0 0 0",
                          height: "4.796663190823775vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          fontSize: "0.8333333333333334vw",
                          color: "#7D7D7D",
                        }}
                        bordered={false}
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          const inputValue = e.target.value;
                          if (
                            !(
                              (charCode >= 65 && charCode <= 90) ||
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode === 32 && inputValue.trim() !== "")
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col>
              <Col
                span={8}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0 1.5625vw",
                }}
              >
                <Row
                  style={{ display: "flex", flexDirection: "column" }}
                  className="w-100"
                >
                  <span
                    style={{
                      fontSize: "0.7291666666666666vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Zip Code <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  {patientUserDetails && (
                    <Form.Item
                      name="zip_code"
                      initialValue={patientUserDetails.zip_code}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Zip code",
                        },
                        {
                          validator: validateZipCode,
                        },
                      ]}
                    >
                      <Input
                        name="zip_code"
                        disabled={inputsEnable}
                        style={{
                          width: "100%",
                          margin: "5px 0 0 0",
                          height: "4.796663190823775vh",
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          fontWeight: "400",
                          fontSize: "0.8333333333333334vw",
                          color: "#7D7D7D",
                        }}
                        bordered={false}
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          if (!(charCode >= 48 && charCode <= 57)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col>
            </Row>
            <Row className="w-100 d-flex jc-center">
              {inputsEnable ? (
                <Button
                  style={{
                    height: "1.563vw",
                    width: "64px",
                    border: "1px solid #009DF7",
                    borderRadius: "1.563vw",
                    color: "#009DF7",
                  }}
                  onClick={enableFormFields}
                >
                  Edit
                </Button>
              ) : (
                <Row style={{ display: "flex", gap: 15 }}>
                  <Button
                    style={{
                      height: "1.563vw",
                      border: "1px solid #F7927E",
                      borderRadius: "1.563vw",
                      color: "#F7927E",
                      background: "#FFF6F2",
                    }}
                    onClick={() => {
                      form.resetFields();
                      getPatientDetails();
                      setInputsEnable(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Form.Item>
                    <Button
                      style={{
                        height: "1.563vw",
                        width: "64px",
                        border: "1px solid #29C3C1",
                        borderRadius: "1.563vw",
                        color: "#29C3C1",
                        background: "#ECFEFF",
                      }}
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Row>
              )}
            </Row>
          </div>
        </>
      )}
      {patientTabsData === "Payment History" &&
        (patientPaymentHistory ? (
          <div
            style={{
              padding: "1.0417vw 2.0834vw 0 2.0834vw",
              height: "55vh",
              overflow: "auto",
            }}
          >
            {patientPaymentHistory.map((item) => {
              return (
                <div>
                  <Row style={{ margin: "1.25vw 1.563vw" }}>
                    <div
                      style={{
                        boxShadow: "2px 2px 1.0417vw 0px #0000000D",
                        border: "1px solid #EBEBEB",
                        padding: "1.25vw 2.6045vw",
                        width: "100%",
                        borderRadius: "0.52vw",
                      }}
                    >
                      <Row>
                        <Col
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.26vw",
                          }}
                          span={6}
                        >
                          <Row>
                            <span
                              style={{
                                fontSize: "0.73vw",
                                color: "#B3B9BD",
                                fontWeight: 400,
                              }}
                            >
                              Date
                            </span>
                          </Row>
                          <Row>
                            <span
                              style={{
                                fontSize: "0.834vw",
                                fontWeight: 600,
                              }}
                            >
                              Decemeber 2, 2018
                            </span>
                          </Row>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.26vw",
                          }}
                          span={5}
                        >
                          <Row>
                            <span
                              style={{
                                fontSize: "0.73vw",
                                color: "#B3B9BD",
                                fontWeight: 400,
                              }}
                            >
                              Invoice Number
                            </span>
                          </Row>
                          <Row>
                            <span
                              style={{
                                fontSize: "0.834vw",
                                fontWeight: 600,
                              }}
                            >
                              {item.order_id}
                            </span>
                          </Row>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.26vw",
                          }}
                          span={5}
                        >
                          <Row>
                            <span
                              style={{
                                fontSize: "0.73vw",
                                color: "#B3B9BD",
                                fontWeight: 400,
                              }}
                            >
                              Treatment Type
                            </span>
                          </Row>
                          <Row>
                            <span
                              style={{
                                fontSize: "0.834vw",
                                fontWeight: 600,
                              }}
                            >
                              {item.treatment_name}
                            </span>
                          </Row>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.26vw",
                          }}
                          span={5}
                        >
                          <Row>
                            <span
                              style={{
                                fontSize: "0.73vw",
                                color: "#B3B9BD",
                                fontWeight: 400,
                              }}
                            >
                              Clinic Name
                            </span>
                          </Row>
                          <Row>
                            <span
                              style={{
                                fontSize: "0.834vw",
                                fontWeight: 600,
                              }}
                            >
                              Date
                            </span>
                          </Row>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.26vw",
                          }}
                          span={3}
                        >
                          <Row>
                            <span
                              style={{
                                fontSize: "0.73vw",
                                color: "#B3B9BD",
                                fontWeight: 400,
                              }}
                            >
                              Total Amount
                            </span>
                          </Row>
                          <Row>
                            <span
                              style={{
                                fontSize: "0.834vw",
                                fontWeight: 600,
                              }}
                            >
                              Date
                            </span>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              padding: "1.0417vw 2.0834vw 0 2.0834vw",
              height: "55vh",
              overflow: "auto",
            }}
          >
            <Row
              className="d-flex jc-center"
              style={{ margin: "1.25vw 1.563vw" }}
            >
              No Payment History available
            </Row>
          </div>
        ))}
      {patientTabsData === "Treatment History" && (
        <div
          style={{
            padding: "1.0417vw 2.0834vw 0 2.0834vw",
            minHeight: "65vh",
          }}
        >
          <div
            className="d-flex flex-col"
            style={{ margin: "1.25vw 1.563vw 0", gap: "1.3023vw" }}
          >
            <Row
              className="d-flex jc-between"
              style={{
                width: "100%",
              }}
            >
              <Col>
                <Input
                  prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
                  className="search-treatment"
                  placeholder="Search by Treatment, Doctor..."
                  onPressEnter={(value) =>
                    handleStatusChange(
                      "clinicNameOrDoctorNameOrTreatmentName",
                      value.target.value
                    )
                  }
                />
              </Col>
              <Col
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "0.52vw",
                }}
              >
                <span
                  style={{
                    leadingTrim: "both",
                    textEdge: "cap",
                    fontSize: "0.834vw",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "140.04 %" /* 22.406px */,
                  }}
                >
                  Filters:
                </span>
                <Select
                  className="status-select"
                  placeholder="By Status"
                  options={statusOptions}
                  onChange={(value) => handleStatusChange("status", value)}
                ></Select>
                <Button
                  style={{
                    display: "flex",
                    width: "8.46vw",
                    height: "2.292vw",
                    padding: "0.63vw",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    border: "1px solid #AEAEAE",
                  }}
                  onClick={handleByDateClick}
                >
                  <span
                    style={{
                      color: "#6D6D6D",
                      fontSize: "0.834vw",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: " 140.04%",
                    }}
                  >
                    By Date
                  </span>
                  <img
                  alt=""
                    style={{
                      height: "1.25vw",
                      width: "1.25vw",
                    }}
                    src={calIcon}
                  />
                </Button>
              </Col>
            </Row>
            <Row className="">
              {treatmentHistory ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.938vw",
                    height: "52vh",
                    overflowY: "scroll",
                  }}
                >
                  {treatmentHistory.map((item) => {
                    return (
                        <div
                          style={{
                            width: "49.375vw",
                            height: "15.938vw",
                            padding: "1.834vw 2.5vw",
                            borderRadius: "0.52vw",
                            border: "1px solid #EBEBEB",
                            background: "var(--Button-Text-color, #FFF)",
                            boxShadow: "0.10425vw 0.10425vw 1.0417vw 0px rgba(0, 0, 0, 0.05)",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1.872vw"
                          }}
                        >
                          <Row>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.26vw",
                              }}
                              span={6}
                            >
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.73vw",
                                    color: "#B3B9BD",
                                    fontWeight: 400,
                                  }}
                                >
                                  Treatment Name
                                </span>
                              </Row>
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.834vw",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.treatment_name}
                                </span>
                              </Row>
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.26vw",
                              }}
                              span={6}
                            >
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.73vw",
                                    color: "#B3B9BD",
                                    fontWeight: 400,
                                  }}
                                >
                                  Treatment Type
                                </span>
                              </Row>
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.834vw",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.treatment_type}
                                </span>
                              </Row>
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.26vw",
                              }}
                              span={6}
                            >
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.73vw",
                                    color: "#B3B9BD",
                                    fontWeight: 400,
                                  }}
                                >
                                  Date
                                </span>
                              </Row>
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.834vw",
                                    fontWeight: 600,
                                  }}
                                >
                                  {moment(item.appointment_date).format("MMMM DD, YYYY")}
                                </span>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.26vw",
                              }}
                              span={6}
                            >
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.73vw",
                                    color: "#B3B9BD",
                                    fontWeight: 400,
                                  }}
                                >
                                  Clinic Name
                                </span>
                              </Row>
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.834vw",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.clinic_name}
                                </span>
                              </Row>
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.26vw",
                              }}
                              span={6}
                            >
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.73vw",
                                    color: "#B3B9BD",
                                    fontWeight: 400,
                                  }}
                                >
                                  Doctor
                                </span>
                              </Row>
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.834vw",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.doc_name}
                                </span>
                              </Row>
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.26vw",
                              }}
                              span={6}
                            >
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.73vw",
                                    color: "#B3B9BD",
                                    fontWeight: 400,
                                  }}
                                >
                                  Cost of Treatment
                                </span>
                              </Row>
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.834vw",
                                    fontWeight: 600,
                                  }}
                                >
                                  Rs.{item.rate ? item.rate : 0}
                                </span>
                              </Row>
                            </Col>
                            <Col
                              span={6}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end"
                              }}
                            >
                              {item.payment_status === true ?
                                (<div style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.5vw",
                                }}
                                >
                                  <Button
                                    className="invoice-button"
                                    onClick={() => {
                                      setInvoiceClicked(true);
                                      setInvoiceNumber(item.invoice_number);
                                    }}
                                  >
                                    Invoice
                                  </Button>
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "#3498DB",
                                      fontSize: "0.73vw",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      lineHeight: "148.59%", /* 20.803px */
                                    }}>Give Feedback</span>
                                </div>
                                ) :
                                <Button className="pay-now-button"
                                  onClick={() => {
                                    displayRazorpay(item);
                                    localStorage.setItem(
                                      "treatment_id",
                                      item.id
                                    );
                                  }}>
                                  Pay Now
                                </Button>}
                            </Col>
                          </Row>
                          <Row>
                            {/* <Col
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.26vw",
                                  }}
                                  span={6}
                                >
                                  <Row>
                                    <span
                                      style={{
                                        fontSize: "0.73vw",
                                        color: "#B3B9BD",
                                        fontWeight: 400,
                                      }}
                                    >
                                      Give Feedback
                                    </span>
                                  </Row>
                                  <Row>
                                    <span
                                      style={{
                                        fontSize: "0.834vw",
                                        fontWeight: 600,
                                      }}
                                    >
                                      -
                                    </span>
                                  </Row>
                                </Col> */}
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.26vw",
                              }}
                              span={6}
                            >
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.73vw",
                                    color: "#B3B9BD",
                                    fontWeight: 400,
                                  }}
                                >
                                  Payment Status
                                </span>
                              </Row>
                              <Row>
                                <span
                                  className={item.payment_status === true ? "paid" : "pending"}
                                  style={{
                                    fontSize: "0.834vw",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.payment_status === true ? "Paid" : "Pending"}
                                </span>
                              </Row>
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.26vw",
                              }}
                              span={6}
                            >
                              <Row>
                                <span
                                  style={{
                                    fontSize: "0.73vw",
                                    color: "#B3B9BD",
                                    fontWeight: 400,
                                  }}
                                >
                                  Payable Amount
                                </span>
                              </Row>
                              <Row>
                                <span
                                  style={{
                                    color: "#3498DB",
                                    fontSize: "1.25vw",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "148.59%"
                                  }}
                                >
                                  Rs.{item.rate ? item.rate : 0}
                                </span>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}
            </Row>
          </div>
        </div>
      )}

      <Modal
        centered
        width={"26.46vw"}
        height={"33.85vw"}
        open={openCalendarModal}
        closable={false}
        footer={false}
        className="open-calendar-modal"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.0417vw",
          }}
        >
          <Row>
            <span
              style={{
                fontSize: "1.0417vw",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "140.04%",
              }}
            >
              Choose Date from the Calendar
            </span>
          </Row>
          <Row>
            {/* <Calendar
              value={displayDate}
              className="treatment-calendar"
              onSelect={onSelect}
              dateCellRender={dateCellRender}
              mode="month"
              onPanelChange={onPanelChange}
              defaultValue={moment()}

            /> */}
            <Calendar
              className="treatment-calendar"
              fullscreen={false}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                const start = 0;
                const end = 12;
                const monthOptions = [];
                let current = value.clone();
                const localeData = value.localeData();
                const months = [];
                for (let i = 0; i < 12; i++) {
                  current = current.month(i);
                  months.push(localeData.monthsShort(current));
                }
                for (let i = start; i < end; i++) {
                  monthOptions.push(
                    <Select.Option key={i} value={i} className="month-item">
                      {months[i]}
                    </Select.Option>
                  );
                }
                const year = value.year();
                const month = value.month();
                const options = [];
                for (let i = year - 10; i < year + 10; i += 1) {
                  options.push(
                    <Select.Option key={i} value={i} className="year-item">
                      {i}
                    </Select.Option>
                  );
                }
                return (
                  <div
                    style={{
                      padding: "0.417vw",
                    }}
                  >
                    <Row gutter={8}>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          className="my-year-select"
                          value={year}
                          onChange={(newYear) => {
                            const now = value.clone().year(newYear);
                            onChange(now);
                          }}
                        >
                          {options}
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          value={month}
                          onChange={(newMonth) => {
                            const now = value.clone().month(newMonth);
                            onChange(now);
                          }}
                        >
                          {monthOptions}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                );
              }}
              onSelect={onDateSelect}
              onPanelChange={onPanelChange}
            />
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              className="cal-cancel-button"
              onClick={() => {
                setOpenCalendarModal(false);
                handleStatusChange("fromDate", "");
              }}
            >
              Cancel
            </Button>
            <Button className="cal-apply-button" onClick={handleApplyButton}>
              Apply
            </Button>
          </Row>
        </div>
      </Modal>
    </Form>
  );
};

export default PatientProfile;
