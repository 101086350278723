import React, { useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { UserAuth } from "../../../context_api/ContextApi";
import { GOOGLE_API_KEY } from "../../../string";

const MapComponent = ({ google, onLocationSelect }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleMapClick = (mapProps, map, clickEvent) => {
    const { latLng } = clickEvent;
    const latitude = latLng.lat();
    const longitude = latLng.lng();

    setSelectedLocation({ latitude, longitude });
    onLocationSelect({ latitude, longitude });
  };

  return (
    <Map
      style={{ width: 585, height: 395 }}
      google={google}
      zoom={10}
      initialCenter={{ lat: 17, lng: 78 }}
      onClick={handleMapClick}
    >
      {selectedLocation && (
        <Marker
          position={{
            lat: selectedLocation.latitude,
            lng: selectedLocation.longitude,
          }}
        />
      )}
    </Map>
  );
};

const MapContainer = GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY, // Replace with your actual Google Maps API key
})(MapComponent);

const SelectLocateOnMap = () => {
  const { setlongitude, setlatitude } = UserAuth();

  const handleLocationSelect = (location) => {
    setlatitude(location.latitude);
    setlongitude(location.longitude);
  };

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <MapContainer onLocationSelect={handleLocationSelect} />
    </div>
  );
};

export default SelectLocateOnMap;
