import { Col, Input, Row } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import PatientXrayReports from "./patients_xray_&_reports/PatientXrayReports";
import API_BASE_URL from "../../../../env";

const SuperAdminPatients = () => {
  const Token = localStorage.getItem("Token");
  const [xraysAndReports, setXraysAndReports] = useState();
  const [patients, setPatients] = useState();
  const [searchPatients, setSearchPatients] = useState("");

  // PEDNING
  const handleGetAllPatients = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios.get(`${API_BASE_URL}/api/v1/clinic/patients`, config).then((res) => {
      setPatients(res.data.data);
    });
  };

  const handleGetPatientsBySearch = (e) => {
    const searching_value = e.target.value;
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/patient/searchPatient?patientIdOrName=${searching_value}`,
        config
      )
      .then((res) => {
        setPatients(res.data.data.global_patients);
      })
      .catch((err) => {
        handleGetAllPatients();
      });
  };

  const handleGetXrayAndReportDetails = async (patientID, xrayType) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/report?patient_id=${patientID}&report_type=${xrayType}`,
        config
      )
      .then((res) => {
        setXraysAndReports(res.data.data);
      });
  };

  useEffect(() => {
    handleGetAllPatients();
  }, []);

  return (
    <div className="my-account-main-div">
      <Helmet>
        <title>Patients</title>
      </Helmet>
      <Row
        className="d-flex ai-center jc-between"
        style={{ margin: "12px 23px" }}
      >
        <span
          style={{
            fontWeight: 700,
            fontSize: 22,
            color: "#525252",
          }}
        >
          Patients({patients && patients.length})
        </span>
        <Input
          style={{
            width: 399,
            height: 40,
            borderRadius: 3,
          }}
          className="d-flex ai-center"
          placeholder="Search Patients by Name or ID"
          prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
          value={searchPatients}
          onChange={(e) => {
            handleGetPatientsBySearch(e);
            setSearchPatients(e.target.value);
          }}
          suffix={
            <CloseOutlined
              style={{ height: "10px", color: "#A5A5A5" }}
              onClick={() => {
                handleGetAllPatients();
                setSearchPatients("");
              }}
            />
          }
        />
      </Row>
      <div
        style={{
          margin: "0 23px",
          background: "#FFF",
          padding: "23px",
          maxHeight: "75vh",
          overflow: "auto",
        }}
      >
        <Row
          style={{
            background: "#F4F4F4",
            padding: "16px 18px",
          }}
        >
          <Col span={2}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#7d7d7d",
              }}
            >
              Upload Date
            </span>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
            span={4}
          >
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#7d7d7d",
              }}
            >
              Patient Name / ID
            </span>
            <SearchOutlined style={{ color: "#7d7d7d" }} />
          </Col>
          <Col span={3}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#7d7d7d",
              }}
            >
              Clinic Name
            </span>
          </Col>
          <Col span={4}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#7d7d7d",
              }}
            >
              Email
            </span>
          </Col>
          <Col span={3}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#7d7d7d",
              }}
            >
              Phone
            </span>
          </Col>
          <Col span={3}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#7d7d7d",
              }}
            >
              X-Ray Reports
            </span>
          </Col>
          <Col span={4}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#7d7d7d",
              }}
            >
              Other Reports
            </span>
          </Col>
          <Col span={1}></Col>
        </Row>
        {patients &&
          patients.map((item) => {
            return (
              <PatientXrayReports
                item={item}
                xraysAndReports={xraysAndReports}
                handleGetXrayAndReportDetails={handleGetXrayAndReportDetails}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SuperAdminPatients;
