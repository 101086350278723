import {
  Button,
  Calendar,
  Col,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Tabs,
  message,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import moment from "moment";
import {
  DATETIME_FORMAT_WITH_FULL,
  _scheduled_by_clinic,
  _scheduled_by_patient,
  _status_rescheduled,
} from "../../../../utils/appointment_constants/AppointmentConstants";
import { UserAuth } from "../../../../context_api/ContextApi";
import API_BASE_URL from "../../../../env";
import {
  CloseOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./AdminAppointmentModule.css";
import calendaricon from "../../../../assets/calendaricon.png";
import cancelicon from "../../../../assets/cancel-icon.png";
import success from "../../../../assets/success.png";
import noappointments from "../../../../assets/noappointments.png";
import AppointmentManagement from "./appointment/AdminAppointment";
import { useLocation, useNavigate } from "react-router";
import {
  routes_clinics,
  tabs_clinics,
} from "../../../../utils/patient_navbar_constants/PatientNavbarConstants";

const AdminAppointment = () => {
  const clinic_id = localStorage.getItem("clinic_id");
  const patient_id = localStorage.getItem("patient_id");
  const branch_id = localStorage.getItem("main_branch_id")
    ? localStorage.getItem("main_branch_id")
    : localStorage.getItem("branch_id");
  const user_id = localStorage.getItem("user_id");
  const Token = localStorage.getItem("Token");
  const role = localStorage.getItem("role");
  const [isModalOpen, setisModalOpen] = useState(false);

  const [openRescheduleModal, setopenRescheduleModal] = useState(false);

  const {
    appointmentDataForClinic,
    handleGetAllAppointmentsForClinic,
    appointmentSpinner,
    setAppointmentSpinner,
    setAppointmentDataForClinic,
    filteredDate,
    setFilteredDate,
    setopenRescheduleReasonModal,
    openRescheduleReasonModal,
    setCancellationReason,
    cancellationReason,
    handleCancelPatientAppointment,
    rescheduleData,
    allDoctorsSlots,
    handleGetAllDoctorSlots,
    handleGetAllAppointments,
  } = UserAuth();

  const [RescheduleDate, setRescheduleDate] = useState();

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const uniqueDoctors =
    appointmentDataForClinic &&
    appointmentDataForClinic.reduce((acc, appointment) => {
      const doctorExists = acc.some((doc) => doc.id === appointment.doctor.id);
      if (!doctorExists) {
        acc.push(appointment.doctor);
      }
      return acc;
    }, []);

  const doctorOptions =
    appointmentDataForClinic &&
    uniqueDoctors &&
    uniqueDoctors.map((doctor) => ({
      value: doctor.id.toString(), // Converting to string as React requires string values for options
      label: doctor.name,
    }));

  // Extract unique patients
  const uniquePatients =
    appointmentDataForClinic &&
    appointmentDataForClinic.reduce((acc, appointment) => {
      const patientExists = acc.some(
        (patient) => patient.id === appointment.patient_id
      );
      if (!patientExists) {
        acc.push({
          id: appointment.patient_id,
          name: appointment.patient_name,
        });
      }
      return acc;
    }, []);

  // Create options for the dropdown
  const patientOptions =
    appointmentDataForClinic &&
    uniquePatients &&
    uniquePatients.map((patient) => ({
      value: patient.id.toString(), // Converting to string as React requires string values for options
      label: patient.name,
    }));

  // Extract unique clinic
  const uniqueClinics =
    appointmentDataForClinic &&
    appointmentDataForClinic.reduce((acc, appointment) => {
      const patientExists = acc.some(
        (clinic) => clinic.id === appointment.clinic.id
      );
      if (!patientExists) {
        acc.push({
          id: appointment.clinic.id,
          name: appointment.clinic.name,
        });
      }
      return acc;
    }, []);

  const clinicOptions =
    appointmentDataForClinic &&
    uniqueClinics &&
    uniqueClinics.map((clinic) => ({
      value: clinic.id.toString(), // Converting to string as React requires string values for options
      label: clinic.name,
    }));

  const originalUrl = `${API_BASE_URL}/api/v1/appointment/clinic/${clinic_id}/appointment_specific`;

  const [params, setParams] = useState({
    patient_id: "",
    branch_id: "",
    fromDate: "",
    doc_id: localStorage.getItem("role") === "CLINIC_USER" ? user_id : "",
  });

  // Function to construct new URL with updated query params
  const constructUrl = (baseUrl, newParams) => {
    const url = new URL(baseUrl);
    const queryParams = new URLSearchParams(url.search);
    for (const key in newParams) {
      if (newParams[key]) {
        queryParams.set(key, newParams[key]);
      } else {
        queryParams.delete(key);
      }
    }
    url.search = queryParams.toString();
    return url.toString();
  };

  const handleSelectChange = (name, value) => {
    setParams({
      ...params,
      [name]: value,
    });
  };

  const newUrl = constructUrl(originalUrl, params);

  const handleFilterByDocName = () => {
    setAppointmentSpinner(true);
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${
          role === "STANDARD"
            ? `${API_BASE_URL}/api/v1/appointment/patient/${patient_id}/{fromDate}${
                params.fromDate ? `?fromDate=${params.fromDate}` : ""
              }`
            : newUrl
        }`,
        config
      )
      .then((res) => {
        setAppointmentDataForClinic(res.data.data);
        setAppointmentSpinner(false);
        setisModalOpen(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setAppointmentSpinner(false);
      });
  };

  useEffect(() => {
    if (role === "STANDARD") {
      handleGetAllAppointments();
    } else {
      handleGetAllAppointmentsForClinic();
    }
  }, []);

  useEffect(() => {
    handleFilterByDocName();
  }, [newUrl]);

  const onChange = (e) => {
    setCancellationReason(e.target.value);
  };

  const [filteredDoctors, setfilteredDoctors] = useState();
  const [filteredClinics, setfilteredClinics] = useState();

  const operations = (
    <div>
      <Row
        style={{ gap: 10, margin: "0 0 8px 0" }}
        className="d-flex ai-center"
      >
        <span className="filters-text">Filters:</span>
        {(localStorage.getItem("role") === "CLINIC_ADMIN" ||
          localStorage.getItem("role") === "CLINIC_MASTER_ADMIN") && (
          <Select
            allowClear
            placeholder="By Doctor Name"
            onChange={(value) => handleSelectChange("doc_id", value)}
            bordered={false}
            className="patient-filter-option"
            options={doctorOptions}
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            showArrow={false}
          />
        )}
        {localStorage.getItem("role") !== "STANDARD" && (
          <Select
            allowClear
            placeholder="By Patient Name"
            onChange={(value) => handleSelectChange("patient_id", value)}
            bordered={false}
            className="patient-filter-option"
            options={patientOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            showSearch
          />
        )}

        {localStorage.getItem("role") === "STANDARD" && (
          <Select
            allowClear
            placeholder="By Doctor Name"
            onChange={(value) => {
              handleSelectChange("doc_id", value);
              setfilteredDoctors(value);
            }}
            bordered={false}
            className="patient-filter-option"
            options={doctorOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            showSearch
          />
        )}
        {localStorage.getItem("role") === "STANDARD" && (
          <Select
            allowClear
            placeholder="By Clinic Name"
            onChange={(value) => {
              handleSelectChange("clinic_id", value);
              setfilteredClinics(value);
            }}
            bordered={false}
            className="patient-filter-option"
            options={clinicOptions}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            showSearch
          />
        )}

        <Button
          className="today-filter-btn d-flex jc-between"
          style={{ gap: 30 }}
        >
          {filteredDate ? filteredDate : "Today"}
          {filteredDate ? (
            <img
              alt=""
              src={cancelicon}
              style={{ width: 14 }}
              onClick={(e) => {
                setFilteredDate(null);
                handleSelectChange("fromDate", "");
              }}
            />
          ) : (
            <img
              alt=""
              src={calendaricon}
              style={{ width: 24 }}
              onClick={(e) => {
                setisModalOpen(true);
                setFilteredDate(null);
              }}
            />
          )}
        </Button>
      </Row>
    </div>
  );

  const items = [
    // scheduled
    {
      key: "1",
      label: (
        <div className="d-flex">
          {role === "STANDARD" ? "Pending" : "Requests"}&nbsp;
          <span className="appointment-length">
            {appointmentDataForClinic &&
              appointmentDataForClinic
                .filter((item) => {
                  if (filteredClinics) {
                    return filteredClinics.includes(item.clinic.id);
                  }
                  return true;
                })
                .filter((item) => {
                  if (filteredDoctors) {
                    return filteredDoctors.includes(item.doctor.id);
                  }
                  return true;
                })
                .filter((item) => item.status === "Scheduled").length}
          </span>
        </div>
      ),
      children: (
        <div>
          <Row
            style={{
              background: "#F5F5F5",
              height: 66,
              margin: "16px 0 0 0",
              padding: "0 42px",
              borderBottom: "1px solid #BBB",
            }}
            className="d-flex ai-center"
          >
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Clinic
                </span>
              </Col>
            )}
            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Name
                </span>
              </Col>
            )}
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Type
                </span>
              </Col>
            )}
            {role !== "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment
                </span>
              </Col>
            )}
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Scheduled on
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Appointment Status
              </span>
            </Col>
          </Row>
          <div style={{ height: "50vh", overflow: "auto" }}>
            {appointmentDataForClinic && appointmentDataForClinic.length > 0 ? (
              appointmentDataForClinic
                .filter((item) => {
                  if (filteredClinics) {
                    return filteredClinics.includes(item.clinic.id);
                  }
                  return true;
                })
                .filter((item) => {
                  if (filteredDoctors) {
                    return filteredDoctors.includes(item.doctor.id);
                  }
                  return true;
                })
                .filter((item) => item.status === "Scheduled")
                .map((item) => {
                  return (
                    <AppointmentManagement
                      handleGetAllAppointmentsForClinic={
                        handleGetAllAppointmentsForClinic
                      }
                      handleGetAllAppointments={handleGetAllAppointments}
                      setopenRescheduleReasonModal={
                        setopenRescheduleReasonModal
                      }
                      data={item}
                    />
                  );
                })
            ) : (
              <div className="w-100 d-flex flex-col jc-center ai-center h-100">
                <img alt="" style={{ width: 300 }} src={noappointments} />
                <span className="no-appointments-text">
                  No Requested Appointments!
                </span>
                <span className="no-appointments-sub-text">
                  There are currently no appointments booked. Please check back
                  later.
                </span>
              </div>
            )}
          </div>
        </div>
      ),
    },
    // confirmed
    {
      key: "2",
      label: (
        <div className="d-flex">
          Confirmed&nbsp;
          <span className="appointment-length">
            {appointmentDataForClinic &&
              appointmentDataForClinic.filter(
                (item) => item.status === "Confirmed"
              ).length}
          </span>
        </div>
      ),
      children: (
        <div>
          <Row
            style={{
              background: "#F5F5F5",
              height: 66,
              margin: "16px 0 0 0",
              padding: "0 42px",
              borderBottom: "1px solid #BBB",
            }}
            className="d-flex ai-center"
          >
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Clinic
                </span>
              </Col>
            )}
            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Name
                </span>
              </Col>
            )}
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Type
                </span>
              </Col>
            )}
            {role !== "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment
                </span>
              </Col>
            )}
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Scheduled on
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Appointment Status
              </span>
            </Col>
          </Row>
          <div style={{ height: "50vh", overflow: "auto" }}>
            {appointmentDataForClinic && appointmentDataForClinic.length > 0 ? (
              appointmentDataForClinic
                .filter((item) => item.status === "Confirmed")
                .map((item) => {
                  return (
                    <AppointmentManagement
                      handleGetAllAppointmentsForClinic={
                        handleGetAllAppointmentsForClinic
                      }
                      handleGetAllAppointments={handleGetAllAppointments}
                      data={item}
                      setopenRescheduleReasonModal={
                        setopenRescheduleReasonModal
                      }
                      setopenRescheduleModal={setopenRescheduleModal}
                    />
                  );
                })
            ) : (
              <div className="w-100 d-flex flex-col jc-center ai-center h-100">
                <img alt="" style={{ width: 300 }} src={noappointments} />
                <span className="no-appointments-text">
                  No Confirmed Appointments!
                </span>
                <span className="no-appointments-sub-text">
                  Please check your requested appointments and confirm them.
                </span>
              </div>
            )}
          </div>
        </div>
      ),
    },
    // in-progress
    {
      key: "3",
      label: (
        <div className="d-flex">
          In-Progress&nbsp;
          <span className="appointment-length">
            {appointmentDataForClinic &&
              appointmentDataForClinic.filter(
                (item) => item.status === "In-Progress"
              ).length}
          </span>
        </div>
      ),
      children: (
        <div>
          <Row
            style={{
              background: "#F5F5F5",
              height: 66,
              margin: "16px 0 0 0",
              padding: "0 42px",
              borderBottom: "1px solid #BBB",
            }}
            className="d-flex ai-center"
          >
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Clinic
                </span>
              </Col>
            )}

            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Name
                </span>
              </Col>
            )}

            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Type
                </span>
              </Col>
            )}
            {role !== "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment
                </span>
              </Col>
            )}

            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Scheduled on
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Appointment Status
              </span>
            </Col>
          </Row>
          <div style={{ height: "50vh", overflow: "auto" }}>
            {appointmentDataForClinic && appointmentDataForClinic.length > 0 ? (
              appointmentDataForClinic
                .filter((item) => item.status === "In-Progress")
                .map((item) => {
                  return (
                    <AppointmentManagement
                      handleGetAllAppointmentsForClinic={
                        handleGetAllAppointmentsForClinic
                      }
                      handleGetAllAppointment={handleGetAllAppointments}
                      data={item}
                    />
                  );
                })
            ) : (
              <div className="w-100 d-flex flex-col jc-center ai-center h-100">
                <img alt="" style={{ width: 300 }} src={noappointments} />
                <span className="no-appointments-text">
                  No Appointments In-progress!
                </span>
                <span className="no-appointments-sub-text">
                  Please check your confirmed appointments and update them to
                  in-progress when necessary.
                </span>
              </div>
            )}
          </div>
        </div>
      ),
    },
    // completed
    {
      key: "4",
      label: (
        <div className="d-flex">
          Completed&nbsp;
          <span className="appointment-length">
            {appointmentDataForClinic &&
              appointmentDataForClinic.filter(
                (item) => item.status === "Complete"
              ).length}
          </span>
        </div>
      ),
      children: (
        <div>
          <Row
            style={{
              background: "#F5F5F5",
              height: 66,
              margin: "16px 0 0 0",
              padding: "0 42px",
              borderBottom: "1px solid #BBB",
            }}
            className="d-flex ai-center"
          >
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Clinic
                </span>
              </Col>
            )}

            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Name
                </span>
              </Col>
            )}

            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Type
                </span>
              </Col>
            )}
            {role !== "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment
                </span>
              </Col>
            )}

            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Scheduled on
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Appointment Status
              </span>
            </Col>
          </Row>
          <div style={{ height: "50vh", overflow: "auto" }}>
            {appointmentDataForClinic && appointmentDataForClinic.length > 0 ? (
              appointmentDataForClinic
                .filter((item) => item.status === "Complete")
                .map((item) => {
                  return (
                    <AppointmentManagement
                      handleGetAllAppointmentsForClinic={
                        handleGetAllAppointmentsForClinic
                      }
                      handleGetAllAppointments={handleGetAllAppointments}
                      data={item}
                    />
                  );
                })
            ) : (
              <div className="w-100 d-flex flex-col jc-center ai-center h-100">
                <img alt="" style={{ width: 300 }} src={noappointments} />
                <span className="no-appointments-text">
                  No Completed Appointments!
                </span>
                <span className="no-appointments-sub-text">
                  Once you finish an appointment, it will appear here.
                </span>
              </div>
            )}
          </div>
        </div>
      ),
    },
    // cancelled
    {
      key: "5",
      label: (
        <div className="d-flex">
          Cancelled&nbsp;
          <span className="appointment-length">
            {appointmentDataForClinic &&
              appointmentDataForClinic.filter(
                (item) => item.status === "Cancelled"
              ).length}
          </span>
        </div>
      ),
      children: (
        <div>
          <Row
            style={{
              background: "#F5F5F5",
              height: 66,
              margin: "16px 0 0 0",
              padding: "0 42px",
              borderBottom: "1px solid #BBB",
            }}
            className="d-flex ai-center"
          >
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Clinic
                </span>
              </Col>
            )}

            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Name
                </span>
              </Col>
            )}

            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Type
                </span>
              </Col>
            )}
            {role !== "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment
                </span>
              </Col>
            )}

            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Scheduled on
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Appointment Status
              </span>
            </Col>
          </Row>
          <div style={{ height: "50vh", overflow: "auto" }}>
            {appointmentDataForClinic && appointmentDataForClinic.length > 0 ? (
              appointmentDataForClinic
                .filter((item) => item.status === "Cancelled")
                .map((item) => {
                  return (
                    <AppointmentManagement
                      handleGetAllAppointmentsForClinic={
                        handleGetAllAppointmentsForClinic
                      }
                      handleGetAllAppointments={handleGetAllAppointments}
                      data={item}
                    />
                  );
                })
            ) : (
              <div className="w-100 d-flex flex-col jc-center ai-center h-100">
                <img alt="" style={{ width: 300 }} src={noappointments} />
                <span className="no-appointments-text">
                  No Cancelled Appointments!
                </span>
                <span className="no-appointments-sub-text">
                  Any appointment cancelled by you or by patients will appear
                  here
                </span>
              </div>
            )}
          </div>
        </div>
      ),
    },
    // re-scheduled
    {
      key: "6",
      label: (
        <div className="d-flex">
          Re-scheduled&nbsp;
          <span className="appointment-length">
            {appointmentDataForClinic &&
              appointmentDataForClinic.filter(
                (item) => item.status === "Rescheduled"
              ).length}
          </span>
        </div>
      ),
      children: (
        <div>
          <Row
            style={{
              background: "#F5F5F5",
              height: 66,
              margin: "16px 0 0 0",
              padding: "0 42px",
              borderBottom: "1px solid #BBB",
            }}
            className="d-flex ai-center"
          >
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Clinic
                </span>
              </Col>
            )}

            {role === "STANDARD" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Doctor
                </span>
              </Col>
            )}
            {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Patient
                </span>
              </Col>
            )}
            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Name
                </span>
              </Col>
            )}

            {role === "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment Type
                </span>
              </Col>
            )}
            {role !== "CLINIC_USER" && (
              <Col span={4}>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Treatment
                </span>
              </Col>
            )}

            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Scheduled on
              </span>
            </Col>
            <Col span={4}>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Appointment Status
              </span>
            </Col>
          </Row>
          <div style={{ height: "50vh", overflow: "auto" }}>
            {appointmentDataForClinic && appointmentDataForClinic.length > 0 ? (
              appointmentDataForClinic
                .filter((item) => item.status === "Rescheduled")
                .map((item) => {
                  return (
                    <AppointmentManagement
                      handleGetAllAppointmentsForClinic={
                        handleGetAllAppointmentsForClinic
                      }
                      handleGetAllAppointments={handleGetAllAppointments}
                      data={item}
                    />
                  );
                })
            ) : (
              <div className="w-100 d-flex flex-col jc-center ai-center h-100">
                <img alt="" style={{ width: 300 }} src={noappointments} />
                <span className="no-appointments-text">
                  No Re-scheduled Appointments!
                </span>
                <span className="no-appointments-sub-text"></span>
                Any appointment re-schedule requested by you or by patient will
                appear here.
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  const [selectedSlot, setselectedSlot] = useState();
  const [successRescheduleModal, setsuccessRescheduleModal] = useState(false);

  const [originaldate, setoriginaldate] = useState();
  const [newdate, setnewdate] = useState();

  const handleRescheduleAppointment = () => {
    let payload;
    payload = {
      patient_id: rescheduleData.patientid,
      clinic_id: rescheduleData.clinic.id,
      treatment_type_id: rescheduleData.treatment_type.id,
      from_date: RescheduleDate
        ? RescheduleDate + selectedSlot
        : moment(rescheduleData && rescheduleData.from_date).format(
            "YYYY-MM-DD"
          ) + selectedSlot,
      scheduled_by:
        role === "STANDARD" ? _scheduled_by_patient : _scheduled_by_clinic,
      status: _status_rescheduled,
      doc_id: rescheduleData.doctor.id,
      doc_slot: rescheduleData.slots.id,
      notes: "",
      branch_id: rescheduleData.clinic_branch.branch_id,
      reason: cancellationReason,
      previous_date: moment(rescheduleData.from_date).format(
        DATETIME_FORMAT_WITH_FULL
      ),
    };
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(
        `${API_BASE_URL}/api/v1/appointment/${rescheduleData.id}`,
        payload,
        config
      )
      .then((res) => {
        message.success(res.data.data);
        setoriginaldate(res.data.data.from_date);
        setnewdate(res.data.data.previous_date);
        setopenRescheduleModal(false);
        handleGetAllAppointmentsForClinic();
        handleGetAllAppointments();
        setsuccessRescheduleModal(true);
      })
      .catch((err) => {
        api.info({
          message: "Error",
          description: err.response.data.data.errors,
          placement: "bottom",
          icon: <InfoCircleOutlined style={{ color: "Red" }} />,
          style: {
            width: "28.854166666666668vw",
            height: "10.531803962460897vh",
            bordeRadius: 10,
            border: "0px solid #FF0000",
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 0px 4px 0px #FF0000",
          },
        });
      });
  };

  const [api, contextHolder] = notification.useNotification();

  const location = useLocation();

  const navigate = useNavigate();
  const { setTabActiveKey } = UserAuth();

  const searchParams = new URLSearchParams(location.search);
  const razorpay_invoice_id = searchParams.get("razorpay_invoice_id");
  const paymentId = searchParams.get("razorpay_payment_id");
  const signature = searchParams.get("razorpay_signature");

  return (
    <div className="clinic-user-appointment-main-div">
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: "2.6045vw", margin: "14.588vw 0 0 0" }}
            spin
          />
        }
        size="large"
        spinning={appointmentSpinner && true}
        delay={500}
      >
        {contextHolder}
        <Helmet>
          <title>Clinic Admin Appointments</title>
        </Helmet>
        <div
          style={{
            margin: "31px 40px",
            paddingBottom: "2vw",
            background: "#FFFFFF",
            padding: "44px 30px",
            borderRadius: 10,
          }}
        >
          <Row className="w-100 d-flex jc-between ai-center">
            <Col>
              <Row style={{ gap: 8 }}>
                <span className="w-100 appointments-management">
                  {role === "STANDARD"
                    ? "Appointments"
                    : "Appointments Management"}
                </span>
                <span className="appointments-management-subtitle">
                  {role === "STANDARD"
                    ? "View, reschedule, or keep track of your upcoming and past appointments here."
                    : "Manage and oversee all scheduled appointments efficiently. Access pending and confirmed appointments, and take necessaryactions as needed."}
                </span>
              </Row>
            </Col>

            <Col>
              {role === "STANDARD" && (
                <Button
                  style={{
                    border: "1px solid #3498DB",
                    background: "#3498DB",
                    display: "flex",
                    width: 128 + 128,
                    padding: "12px 30px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    height: 45,
                    borderRadius: 3,
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    navigate(routes_clinics);
                    setTabActiveKey(tabs_clinics);
                  }}
                >
                  Book New Appointment
                </Button>
              )}
            </Col>
          </Row>
          <Row style={{ margin: "34px 0 0 0" }} className="w-100">
            <Tabs
              className="w-100 admin-appointments-tabs"
              tabBarExtraContent={operations}
              items={items}
              defaultActiveKey={
                (razorpay_invoice_id || paymentId || signature) && "4"
              }
            />
          </Row>
        </div>

        <Modal
          open={isModalOpen}
          footer={false}
          closable={false}
          centered
          destroyOnClose
        >
          <Row className="w-100 d-flex jc-end">
            <CloseOutlined
              style={{ color: "#EC7063", fontSize: 24 }}
              onClick={() => {
                setisModalOpen(false);
                setFilteredDate(null);
                setParams({ fromDate: "" });
              }}
            />
          </Row>
          <Row className="d-flex jc-center w-100">
            <span
              style={{
                color: "#000",
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              Choose Date from the Calendar
            </span>
          </Row>
          <Row
            className="w-100 d-flex jc-center"
            style={{ margin: "20px 0 0 0" }}
          >
            <Calendar
              fullscreen={false}
              onSelect={(value) =>
                setFilteredDate(moment(value).format("YYYY-MM-DD"))
              }
              style={{
                borderRadius: 5,
                border: "1px solid #D2D2D2",
                width: 360,
              }}
            />
          </Row>
          <Row
            className="w-100 d-flex jc-center"
            style={{ margin: "20px 0 0 0" }}
          >
            <Button
              style={{
                borderRadius: 5,
                background: "#3498DB",
                color: "#FFF",
                height: 52,
                width: 168,
                fontSize: 18,
                fontWeight: 500,
              }}
              onClick={() => {
                handleSelectChange("fromDate", filteredDate);
              }}
            >
              Apply
            </Button>
          </Row>
        </Modal>

        <Modal
          open={openRescheduleReasonModal}
          footer={false}
          closable={false}
          centered
          width={471}
          destroyOnClose
          style={{
            borderRadius: "10px",
          }}
          className="cancellation-reason-modal"
        >
          <Row className="w-100 d-flex jc-between">
            <span
              style={{
                color: "#000",
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              Select Cancellation Reason
            </span>
            <CloseOutlined
              style={{ color: "#EC7063", fontSize: 24 }}
              onClick={() => setopenRescheduleReasonModal(false)}
            />
          </Row>
          <Radio.Group
            onChange={onChange}
            value={cancellationReason}
            className="w-100"
          >
            <Row className="d-flex w-100">
              {role === "STANDARD" ? (
                <Row
                  style={{
                    margin: "30px 0 0 0",
                  }}
                  className="w-100"
                >
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      gap: 16,
                      flexDirection: "column",
                    }}
                  >
                    <Radio value={"Scheduling conflicts"}>
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        Scheduling conflicts
                      </span>
                    </Radio>
                    <Radio value={"Transportation issues"}>
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        Transportation issues
                      </span>
                    </Radio>
                    <Radio value={"No longer needing the appointment"}>
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        No longer needing the appointment
                      </span>
                    </Radio>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      gap: 16,
                      flexDirection: "column",
                    }}
                  >
                    <Radio value="Emergency situations">
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        Emergency situations
                      </span>
                    </Radio>
                    <Radio value="Personal Reasons">
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        Personal Reasons
                      </span>
                    </Radio>
                  </Col>
                </Row>
              ) : (
                <Row
                  style={{
                    margin: "30px 0 0 0",
                  }}
                  className="w-100"
                >
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      gap: 16,
                      flexDirection: "column",
                    }}
                  >
                    <Radio value={"Staffing Shortage"}>
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        Staffing Shortage
                      </span>
                    </Radio>
                    <Radio value={"Scheduling Conflicts"}>
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        Scheduling Conflicts
                      </span>
                    </Radio>
                    <Radio value={"Personal Reasons"}>
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        Personal Reasons
                      </span>
                    </Radio>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      gap: 16,
                      flexDirection: "column",
                    }}
                  >
                    <Radio value="Facility Issues">
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        Facility Issues
                      </span>
                    </Radio>
                    <Radio value="Emergency situations">
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        Emergency situations
                      </span>
                    </Radio>
                    <Radio value="Missed Appointment">
                      <span
                        style={{ color: "#000", fontSize: 16, fontWeight: 400 }}
                      >
                        Missed Appointment
                      </span>
                    </Radio>
                  </Col>
                </Row>
              )}
            </Row>
          </Radio.Group>
          <Row className="w-100" style={{ margin: "30px 0 0 0" }}>
            <Col span={10}>
              <Button
                onClick={() =>
                  handleCancelPatientAppointment(
                    rescheduleData,
                    _scheduled_by_clinic
                  )
                }
                style={{
                  borderRadius: 3,
                  background: "#fff",
                  display: "flex",
                  width: "100%",
                  height: 45,
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#EC7063",
                  fontSize: 14,
                  fontWeight: 500,
                  border: "1px solid #EC7063",
                }}
              >
                Cancel Only
              </Button>
            </Col>
            <Col span={14} style={{ padding: "0 0 0 12px" }}>
              <Button
                style={{
                  width: "100%",
                  border: "1px solid #3498DB",
                  background: "#3498DB",
                  display: "flex",
                  padding: "12px 30px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 8,
                  height: 45,
                  borderRadius: 3,
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: 500,
                }}
                onClick={() => {
                  setopenRescheduleReasonModal(false);
                  handleGetAllDoctorSlots(
                    rescheduleData.doctor.id,
                    rescheduleData.from_date
                  );
                  setopenRescheduleModal(true);
                }}
              >
                Cancel & Re-schedule
              </Button>
            </Col>
          </Row>
        </Modal>

        <Modal
          open={openRescheduleModal}
          footer={false}
          closable={false}
          centered
          destroyOnClose
          width={1180}
        >
          <Row className="d-flex w-100">
            <span
              style={{
                color: "#000",
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              Request to Reschedule Appointment
            </span>
          </Row>
          <Row className="d-flex w-100">
            <span
              style={{
                color: "#000",
                fontSize: 18,
                fontWeight: 400,
              }}
            >
              Select a new date and time for the rescheduled appointment
            </span>
          </Row>
          <Row className="w-100" style={{ margin: "20px 0 0 0" }}>
            <Col span={9}>
              <Row>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Select date
                </span>
              </Row>
              <Row
                style={{
                  margin: "12px 0 0 0",
                }}
              >
                <Calendar
                  fullscreen={false}
                  disabledDate={disabledDate}
                  onSelect={(value) => {
                    setRescheduleDate(
                      rescheduleData &&
                        moment(rescheduleData.from_date).format(
                          "YYYY-MM-DD"
                        ) === moment(value).format("YYYY-MM-DD")
                        ? ""
                        : moment(value).format("YYYY-MM-DD")
                    );
                    handleGetAllDoctorSlots(
                      rescheduleData.doctor.id,
                      moment(value).format("YYYY-MM-DD")
                    );
                  }}
                  defaultValue={moment(
                    rescheduleData && rescheduleData.from_date
                  )}
                  style={{
                    borderRadius: 5,
                    border: "1px solid #D2D2D2",
                    width: 360,
                  }}
                />
              </Row>
            </Col>
            <Col span={15}>
              <Row>
                <span
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Available Slots on{" "}
                  {RescheduleDate
                    ? RescheduleDate
                    : moment(rescheduleData && rescheduleData.from_date).format(
                        "YYYY-MM-DD"
                      )}
                </span>
              </Row>
              <Row>
                {rescheduleData && (
                  <Row
                    style={{
                      display: "flex",
                      gap: "0.9375vw",
                      maxHeight: "42vh",
                      overflow: "auto",
                      margin: "12px 0 0 0",
                    }}
                  >
                    {allDoctorsSlots && allDoctorsSlots.length > 0 ? (
                      allDoctorsSlots.map((data) => {
                        const currentDate = moment().format(
                          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                        );
                        const inputDateMoment = moment(data.from_time).format(
                          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                        );
                        const date1 = moment(inputDateMoment);
                        const date2 = moment(currentDate);

                        return (
                          <Button
                            className={
                              data.status.name === "Scheduled" ||
                              data.status.name === "Rescheduled" ||
                              data.status.name === "Confirmed"
                                ? "doctor-slot-div-booked"
                                : "slot-div"
                            }
                            onClick={() => {
                              if (
                                data.status.name === "Scheduled" ||
                                data.status.name === "Rescheduled" ||
                                data.status.name === "Confirmed"
                              ) {
                                message.warning("Slot is booked");
                              } else {
                                setselectedSlot(
                                  moment(data.from_time).format("THH:mm:ss")
                                );
                              }
                            }}
                            disabled={date1.isBefore(date2) ? true : false}
                          >
                            <span>
                              {moment(data.from_time).format("hh:mm a")}
                            </span>
                          </Button>
                        );
                      })
                    ) : (
                      <>No Slots</>
                    )}
                  </Row>
                )}
              </Row>
            </Col>
          </Row>
          <Row
            className="w-100 d-flex jc-end ai-center"
            style={{ margin: "30px 0 0 0" }}
          >
            <Col span={2}>
              <Button
                onClick={() => {
                  setopenRescheduleModal(false);
                }}
                style={{
                  borderRadius: 3,
                  background: "#fff",
                  display: "flex",
                  width: "100%",
                  height: 45,
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#EC7063",
                  fontSize: 14,
                  fontWeight: 500,
                  border: "1px solid #EC7063",
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={3} style={{ padding: "0 0 0 12px" }}>
              <Button
                style={{
                  width: "100%",
                  border: "1px solid #3498DB",
                  background: "#3498DB",
                  display: "flex",
                  padding: "12px 30px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 8,
                  height: 45,
                  borderRadius: 3,
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: 500,
                }}
                onClick={() => {
                  handleRescheduleAppointment();
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Modal>

        <Modal
          open={successRescheduleModal}
          footer={false}
          closable={false}
          centered
          destroyOnClose
          width={720}
        >
          <Row
            className="w-100 d-flex jc-center"
            style={{ margin: "15px 0 0 0" }}
          >
            <img alt="" src={success} style={{ height: "10vh" }} />
          </Row>
          <Row
            className="w-100 d-flex jc-center"
            style={{ margin: "29px 0 0 0" }}
          >
            <span
              style={{
                color: "#000",
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              Reschedule Appointment Request Submitted Successfully!
            </span>
          </Row>
          <Row
            className="w-100 d-flex jc-center"
            style={{ margin: "13px 0 0 0" }}
          >
            <span
              style={{
                color: "#333",
                fontSize: 16,
                fontWeight: 400,
                width: 465,
              }}
            >
              "The request to reschedule the appointment has been successfully
              submitted. You will be notified once the appointment has been
              confirmed."
            </span>
          </Row>
          <Row
            className="w-100 d-flex jc-center"
            style={{ gap: 24, margin: "24px 0 0 0" }}
          >
            <Col>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Original Appointment Date and Time
              </span>
            </Col>
            <Col>
              <Row style={{ gap: 14 }}>
                <Col>
                  <span
                    style={{
                      color: "#000",
                      fontSize: 16,
                      fontWeight: 500,
                      borderRadius: 5,
                      background: "#EAF6FF",
                      padding: "8px 10px",
                    }}
                  >
                    {moment(originaldate).format("MMMM Do, YYYY")}
                  </span>
                </Col>
                <Col>
                  <span
                    style={{
                      color: "#000",
                      fontSize: 16,
                      fontWeight: 500,
                      borderRadius: 5,
                      background: "#EAF6FF",
                      padding: "8px 10px",
                    }}
                  >
                    {moment(originaldate).format("HH:mm a")}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            className="w-100 d-flex jc-center"
            style={{ margin: "30px 0 0 0", gap: 24 }}
          >
            <Col>
              <span
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                New Appointment Date and Time
              </span>
            </Col>
            <Col>
              <Row style={{ gap: 14 }}>
                <Col>
                  <span
                    style={{
                      color: "#000",
                      fontSize: 16,
                      fontWeight: 500,
                      borderRadius: 5,
                      background: "#EAF6FF",
                      padding: "8px 10px",
                    }}
                  >
                    {moment(newdate).format("MMMM Do, YYYY")}
                  </span>
                </Col>
                <Col>
                  <span
                    style={{
                      color: "#000",
                      fontSize: 16,
                      fontWeight: 500,
                      borderRadius: 5,
                      background: "#EAF6FF",
                      padding: "8px 10px",
                    }}
                  >
                    {moment(newdate).format("HH:mm a")}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            className="w-100 d-flex jc-center"
            style={{ margin: "34px 0 0 0" }}
          >
            <Button
              onClick={() => {
                setsuccessRescheduleModal(false);
                setoriginaldate();
                setnewdate();
              }}
              style={{
                borderRadius: 30,
                border: "1px solid #2CA3FA",
                background: "#FFF",
                color: "#2CA3FA",
                fontSize: 14,
                fontWeight: 600,
                padding: "25px 60px",
              }}
            >
              Close
            </Button>
          </Row>
        </Modal>
      </Spin>
    </div>
  );
};

export default AdminAppointment;
