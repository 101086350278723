import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  message
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Document, Page } from 'react-pdf';
import { useNavigate } from "react-router";
import Ivorylogo from "../../../assets/logo.png";
import uploadIcon from "../../../assets/upload.png";
import { UserAuth } from "../../../context_api/ContextApi";
import API_BASE_URL from "../../../env";
import { phonecode } from "../../../utils/clinic_admin_constants/ClinicAdminConstants";
import { routes_signup_thanks_message } from "../../../utils/patient_navbar_constants/PatientNavbarConstants";
import "./AdminSignup.css";
import SelectLocateOnMap from "./SelectLocateOnMap";

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const AdminSignup = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [locateOnMap, setLocateOnMap] = useState(false);
  const [error, setError] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { setSignupUserName } = UserAuth();
  const { latitude, setlatitude } = UserAuth();
  const { longitude, setlongitude } = UserAuth();
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [terms, setTerms] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [ifsc, setIfsc] = useState('');

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (file.type === 'application/pdf') {
      const pdfPreview = (
        <Document
          file={file.preview}
          onLoadSuccess={() => console.log('PDF loaded successfully')}
          onLoadError={() => console.log('PDF loading failed')}
        >
          <Page pageNumber={1} />
        </Document>
      );

      // Render the PDF preview
      return (
        <div style={{ width: '100%', height: '500px' }}>
          {pdfPreview}
        </div>
      );
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = async ({ fileList: newFile }) => {
    if (newFile.length > 0) {
      const fileType = newFile[0].type;
      const fileSize = newFile[0].size;
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      const maxSize = 4 * 1024 * 1024; // 4MB

      if (allowedTypes.includes(fileType) && fileSize < maxSize) {
        setFileList(newFile);
        if (newFile[0].status == "error" || newFile[0].status == "done") {
          message.success("File uploaded successfully");
        }
      } else {
        message.warning('Please select a JPEG, PNG, or PDF file with a size less than 4MB.');
      }
    } else {
      setFileList(newFile);
    }

  };

  const handleTermsAcceptChange = (event) => {
    setTerms(event.target.checked);
  };

  const handleTermsAndConditionOpen = () => {
    setOpenTermsModal(true);
  };

  const handleAdminSignup = async () => {
    try {
      const values = await form.validateFields();
      const finalFormData = { ...formData, ...values };
      delete finalFormData.confirm;
      const additionalData = {
        country: "India",
        phone_country_code: values.phone_country_code ? values.phone_country_code : "+91",
        is_registor_by: "self",
        coordinate: {
          latitude: "",
          longitude: "",
        },
        profile_picture_url: "",
        terms_accepted: terms,
      };
      Object.assign(finalFormData, additionalData);
      // Submit finalFormData
      if (terms === false) {
        // setOpenTermsModal(true);
        message.warning("Please accept the Terms and Conditions.");
      }
      if (terms === true) {
        setSignupUserName(values.ClinicName);
        setSpinner(true);
        const payload = finalFormData;

        // const payload = {
        //   name: values.ClinicName,
        //   email: values.Email,
        //   username: values.Username,
        //   password: values.password,
        //   phone_country_code: values.Code ? values.Code : "+91",
        //   is_registor_by: "self",
        //   phone_number: values.Phone,
        //   address_line_one: values.Address,
        //   address_line_two: values.Apartment,
        //   city: values.City,
        //   province: values.State,
        //   country: values.Country,
        //   zip_code: values.Zip,
        //   coordinate: {
        //     latitude: latitude,
        //     longitude: longitude,
        //   },
        //   profile_picture_url: "",
        //   referred_by: values.Referral_Code,
        //   terms_accepted: terms,
        // };
        await axios
          .post(`${API_BASE_URL}/api/v1/clinic/clinic-sign-up`, payload)
          .then(async (res) => {
            const id = res.data.data["clinic_id"];
            if (id && fileList) {
              await uploadFile(id, fileList[0].originFileObj);
            }
            message.success("Successfully registered");
            navigate(routes_signup_thanks_message);
            setTimeout(() => {
              navigate("/");
            }, 3000);
            setSpinner(false);
            form.resetFields();
          })
          .catch((err) => {
            setSpinner(false);
            message.error(err.response.data.data.errors);
          });
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }

  };

  const uploadFile = async (id, file) => {
    let formData = new FormData();
    formData.append("file", file);
    await axios
      .post(`${API_BASE_URL}/api/v1/clinic/${id}/upload-certifications`, formData)
      .then((res) => { })
  }

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter 6-digit zip code!");
  };

  const handleIFSCChange = (event) => {
    const value = event.target.value.toUpperCase();
    setIfsc(value);
    form.setFieldsValue({ ifsc_code: value });
  };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handlePasswordOnChange = (e) => {
    const value = e.target.value;
    const repetitionRegex = /(.)\1{4,}/;
    if (repetitionRegex.test(value)) {
      setError("Password must not contain repeated characters or sequences.");
    } else {
      setError("");
      setPassword(value);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleNextStep = async () => {
    try {
      const values = await form.validateFields();
      setFormData({ ...formData, ...values });
      setStep(step + 1);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleKeyPress = (e) => {
    const value = e.key;
    const lastChar = password.charAt(password.length - 1);
    const secondLastChar = password.charAt(password.length - 2);
    const thirdLastChar = password.charAt(password.length - 3);
    const fourthLastChar = password.charAt(password.length - 4);
    if (
      lastChar &&
      secondLastChar &&
      thirdLastChar &&
      value === lastChar &&
      value === secondLastChar &&
      value === thirdLastChar &&
      value === fourthLastChar
    ) {
      e.preventDefault();
      setError("Password must not contain repeated characters or sequences.");
    }
  };

  const validatePassword = (_, value) => {
    if (/(\w)\1{3,}/.test(value)) {
      return Promise.reject(
        new Error("Password should not have more than 3 sequence characters")
      );
    }
    return Promise.resolve();
  };

  const validatePasswords = (_, value) => {
    const { Username, ClinicName } = form.getFieldsValue([
      "Username",
      "ClinicName",
    ]);
    if (value.includes(Username) || value.includes(ClinicName)) {
      return Promise.reject(
        new Error(
          "Password must not contain username or parts of the clinic name"
        )
      );
    }
    return Promise.resolve();
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const allFieldsFilled = () => {
    return userName !== '' && password !== '' && confirmPassword !== '';
  };

  const handleCancelLocateMap = () => {
    setlatitude("");
    setlongitude("");
    setLocateOnMap(false);
  };

  return (
    <div>
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: "2.6045vw", margin: "14.588vw 0 0 0" }}
            spin
          />
        }
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <Row className="admin-register-main-div">
          <Helmet>
            <title>Clinic Registration</title>
          </Helmet>
          <Col
            className="ivory-logo-container"
            span={14}
            sm={14}
            xs={24}
          >
            <Row className="logo-description">
              <img
                data-testid="Ivory-logo"
                className="Ivory-logo-img-tag"
                src={Ivorylogo}
                alt=""
              />
              <span className="description">Your Healthiest Smile Begins Here!</span>
            </Row>

          </Col>
          <Col span={10} sm={10} xs={24} className="admin-register-form-container">
            <Row className="register-form">
              <Form className="register-details-filling-form"
                onFinish={handleAdminSignup} form={form}>
                <Row>
                  <span className="admin-register-text">Clinic Registration</span>
                </Row>
                {step == 1 && (<Row className="register-details-filling-step">
                  <Row className="step-one-personal">
                    <span className="step-number">Step 1 of 5</span>
                    <span className="personal-info">Clinic Information</span>
                    <span className="step-description">Just a few details to kick things off and get your clinic registered!</span>
                  </Row>
                  <Row className="clinic-name-field">
                    <span className="admin-register-input-title">
                      Clinic Name
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your clinic name",
                        },
                        {
                          min: 3,
                          message: "Name must be at least 3 characters long",
                        },
                        {
                          max: 100,
                          message:
                            "Clinic Name must be no more than 100 characters long",
                        },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and Clinic Name must contain only alphabeticals",
                        },
                        { whitespace: true, message: "No white spaces" },
                      ]}
                    >
                      <Input
                        data-testid="jest-input-clinic-name"
                        label="ClinicName"
                        maxLength={101}
                        className="admin-register-input"
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          const inputValue = e.target.value;
                          if (
                            !(
                              (charCode >= 65 && charCode <= 90) ||
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode === 32 && inputValue.trim() !== "")
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="contact-person-field">
                    <span className="admin-register-input-title">
                      Contact Person
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="contact_person"
                      rules={[
                        { required: true, message: "Please enter Account Name" },
                        {
                          pattern: /^[a-zA-Z ]+$/,
                          message: "Account Name must be letters and spaces only."
                        }
                      ]}
                    >
                      <Input
                        data-testid="jest-input-username"
                        maxLength={101}
                        label="contact-person"
                        className="admin-register-input"
                        onKeyPress={handleUserNameKeyPress}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="email-field">
                    <span className="admin-register-input-title">
                      Email
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Please enter your email" },
                        {
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                        {
                          max: 320,
                          message:
                            "Email address must be no more than 320 characters long",
                        },
                      ]}
                    >
                      <Input
                        data-testid="jest-input-email"
                        label="Email"
                        className="admin-register-input"
                        maxLength={321}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="phone-field">
                    <span className="admin-register-input-title">
                      Phone
                      <span style={{ color: "red" }}>*</span>
                    </span>

                    <Space.Compact className="admin-register-phone-input">
                      <Form.Item name="phone_country_code">
                        <Select
                          bordered={false}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "5.2084vw",
                            background: "#FFFFFF",
                            margin: "0.834vw 0 0 0",
                            borderBottom: "1px solid #000",
                          }}
                          defaultValue="+91"
                          options={phonecode}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "100%" }}
                        name="phone_number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter phone number",
                          },
                          { validator: validatePhoneNumber },
                        ]}
                      >
                        <Input
                          className="admin-register-input"
                          style={
                            { width: "19.38vw" }
                          }
                          defaultValue=""
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            if (!(charCode >= 48 && charCode <= 57)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Space.Compact>
                  </Row>
                  <Row className="button-field">
                    <Button className="next-button" onClick={handleNextStep}>
                      Next
                    </Button>
                  </Row>
                  <Row className="existing-user-link">
                    <span className="">Already Have Account?</span>
                    <span className="login-link" onClick={() => {
                      navigate("/");
                    }}>Login</span>
                  </Row>
                </Row>)}
                {step == 2 && (<Row className="register-details-filling-step">
                  <Row className="step-one-personal">
                    <span className="step-number">Step 2 of 5</span>
                    <span className="address-details">Address Details</span>
                    <span className="step-description">We're almost there! Just add your clinic's address to finish.</span>
                  </Row>
                  <Row className="address-field">
                    <span className="admin-register-input-title">
                      Street Address
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="address_line_one"
                      rules={[
                        { required: true, message: "Please enter address" },
                      ]}
                    >
                      <Input
                        data-testid="jest-input-address"
                        label="Address"
                        className="admin-register-input"
                      />
                    </Form.Item>
                  </Row>
                  <Row className="city-field">
                    <span className="admin-register-input-title">
                      City
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="city"
                      rules={[
                        { required: true, message: "Please enter city" },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and City must contain only alphabeticals",
                        },
                      ]}
                    >
                      <Input
                        data-testid="jest-input-city"
                        label="City"
                        className="admin-register-input"
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          const inputValue = e.target.value;
                          if (
                            !(
                              (charCode >= 65 && charCode <= 90) ||
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode === 32 && inputValue.trim() !== "")
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="state-field">
                    <span className="admin-register-input-title">
                      State
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="province"
                      rules={[
                        {
                          required: true,
                          message: "Please enter state/province",
                        },
                        {
                          pattern: /^[A-Za-z][A-Za-z\s]*$/,
                          message:
                            "First character should be alphabetical and State/Province must contain only alphabeticals",
                        },
                      ]}
                    >
                      <Input
                        data-testid="jest-input-state"
                        label="State"
                        className="admin-register-input"
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          const inputValue = e.target.value;
                          if (
                            !(
                              (charCode >= 65 && charCode <= 90) ||
                              (charCode >= 97 && charCode <= 122) ||
                              (charCode === 32 && inputValue.trim() !== "")
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="zipcode-field">
                    <span className="admin-register-input-title">
                      Zip Code
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="zip_code"
                      rules={[
                        {
                          required: true,
                          message: "Please enter zip code",
                        },
                        { validator: validateZipCode },
                      ]}
                    >
                      <Input
                        data-testid="jest-input-zip"
                        label="Zip"
                        className="admin-register-input"
                        onKeyPress={(e) => {
                          const charCode = e.which || e.keyCode;
                          if (!(charCode >= 48 && charCode <= 57)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>

                  <Row className="buttons-container">
                    <span className="go-back" onClick={handlePreviousStep}>Go Back</span>
                    <Button className="next-button" onClick={handleNextStep}>
                      Next
                    </Button>
                  </Row>
                  <Row className="existing-user-link">
                    <span className="">Already Have Account?</span>
                    <span className="login-link" onClick={() => {
                      navigate("/");
                    }}>Login</span>
                  </Row>
                </Row>)}
                {step == 3 && (
                  <Row className="register-details-filling-step">
                    <Row className="step-one-personal">
                      <span className="step-number">Step 3 of 5</span>
                      <span className="credentials">Upload Certificate</span>
                      <span className="step-description">Upload clinic registration certificate.</span>
                    </Row>
                    <Row className="certificate-upload">
                      <Upload.Dragger
                        className={fileList.length >= 1 ? 'upload-disabled' : 'draggable-area'}
                        fileList={fileList}
                        listType="picture-card"
                        onChange={handleChange}
                        onPreview={handlePreview}
                        showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
                      >
                        <Row className="upload-row-tag">
                          <img className="upload-icon" src={uploadIcon} alt="" />
                          <span className="drag-text">Drag your file here.</span>
                        </Row>
                        <span style={{
                          fontSize: " 0.72916vw"
                        }}>Or</span>
                        <Row className="upload-row-tag">
                          <Button className="browse-button">
                            Browse
                          </Button>
                          <span className="document-type-text">Supported document formats: PNG, JPG, and PDF. Maximum file size: 4 MB.</span>
                        </Row>
                      </Upload.Dragger>
                      {previewImage && (
                        <Image
                          wrapperStyle={{ display: 'none' }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            // afterOpenChange: (visible) => !visible && setPreviewImage(''),
                          }}
                          src={previewImage}
                        />
                      )}
                    </Row>
                    <Row className="buttons-container">
                      <span className="go-back" onClick={handlePreviousStep}>Go Back</span>
                      <Button className="next-button" onClick={handleNextStep}
                        disabled={!fileList.length >= 1}>
                        Next
                      </Button>
                    </Row>
                    <Row className="existing-user-link">
                      <span className="">Already Have Account?</span>
                      <span className="login-link" onClick={() => {
                        navigate("/");
                      }}>Login</span>
                    </Row>
                  </Row>
                )}
                {step == 4 && (<Row className="register-details-filling-step">
                  <Row className="step-one-personal">
                    <span className="step-number">Step 4 of 5</span>
                    <span className="address-details">Enter Bank Details</span>
                    <span className="step-description">Provide clinic's bank information.</span>
                  </Row>
                  <Row className="account-name-field">
                    <span className="admin-register-input-title">
                      Account Name
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="account_name"
                      rules={[
                        { required: true, message: "Please enter Account Name" },
                        {
                          pattern: /^[a-zA-Z ]+$/,
                          message: "Account Name must be letters and spaces only."
                        }
                      ]}
                    >
                      <Input
                        data-testid="jest-input-account"
                        label="Account-Name"
                        className="admin-register-input"
                      />
                    </Form.Item>
                  </Row>
                  <Row className="account-no-field">
                    <span className="admin-register-input-title">
                      Account No.
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="account_no"
                      rules={[
                        { required: true, message: "Please enter Account No" },
                        {
                          min: 8,
                          message: "Please enter a valid account number",
                        },
                        { whitespace: true, message: "No white spaces" },
                        {
                          pattern: /^[0-9]+$/,
                          message:
                            "Account number can only contain numbers.",
                        },

                      ]}
                    >
                      <Input
                        data-testid="jest-input-account"
                        label="Account-No"
                        className="admin-register-input"
                        maxLength={16}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="account-no-field">
                    <span className="admin-register-input-title">
                      Confirm Account No.
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="confirm_account_no"
                      dependencies={["account_no"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm account no.",
                        },

                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("account_no") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The account numbers does not match!"
                              )
                            );
                          },
                        })
                      ]}
                    >
                      <Input
                        data-testid="jest-input-account"
                        label="Confirm-Account_no"
                        className="admin-register-input"
                        maxLength={16}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="ifsc-code-field">
                    <span className="admin-register-input-title">
                      IFSC Code
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="ifsc_code"
                      rules={[
                        {
                          required: true,
                          message: "Please enter IFSC code.",
                        },
                        {
                          min: 8,
                          message: "Please enter minimum 8 characters",
                        },
                        { whitespace: true, message: "No white spaces" },
                        {
                          pattern: /^[a-zA-Z0-9]+$/,
                          message:
                            "IFSC code can only contain letters, numbers.",
                        }
                      ]}
                    >
                      <Input
                        data-testid="jest-input-account"
                        label="ifsc-code"
                        className="admin-register-input"
                        maxLength={11}
                        value={ifsc}
                        onChange={handleIFSCChange}
                      />
                    </Form.Item>
                  </Row>
                  {/* <Row className=" -name-field">
                    <span className="admin-register-input-title">
                      Beneficiary Name
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="beneficiary_name"
                      rules={[
                        { required: true, message: "Please enter beneficiary Name" },
                        {
                          pattern: /^[a-zA-Z ]+$/,
                          message: "Beneficiary Name must be letters and spaces only."
                        }
                      ]}
                    >
                      <Input
                        data-testid="jest-input-account"
                        label="Beneficiary-Name"
                        className="admin-register-input"
                      />
                    </Form.Item>
                  </Row> */}

                  <Row className="buttons-container">
                    <span className="go-back" onClick={handlePreviousStep}>Go Back</span>
                    <Button className="next-button" onClick={handleNextStep}>
                      Next
                    </Button>
                  </Row>
                  <Row className="existing-user-link">
                    <span className="">Already Have Account?</span>
                    <span className="login-link" onClick={() => {
                      navigate("/");
                    }}>Login</span>
                  </Row>
                </Row>)}
                {step == 5 && (<Row className="register-details-filling-step">
                  <Row className="step-one-personal">
                    <span className="step-number">Step 5 of 5</span>
                    <span className="credentials">Create Credentials</span>
                    <span className="step-description">Almost done! Let's set up your secure login.</span>
                  </Row>
                  <Row className="username-field">
                    <span className="admin-register-input-title">
                      Username
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Username!",
                        },
                        {
                          min: 4,
                          message: "Please enter minimum 4 characters",
                        },
                        { whitespace: true, message: "No white spaces" },
                        {
                          pattern: /^[a-zA-Z0-9._]+$/,
                          message:
                            "Username can only contain letters, numbers, fullstops, and underscores.",
                        },
                        {
                          max: 100,
                          message:
                            "Username must be no more than 100 characters long",
                        },
                      ]}
                    >
                      <Input
                        data-testid="jest-input-username"
                        label="Username"
                        className="admin-register-input"
                        maxLength={101}
                        onChange={handleUserNameChange}
                        onKeyPress={handleUserNameKeyPress}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="password-field">
                    <span className="admin-register-input-title">
                      Password
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          min: 8,
                          message: "Password must be atleast 8 characters",
                        },
                        { whitespace: true, message: "No white spaces" },
                        {
                          required: true,
                          message: "Please Enter Password",
                        },
                        {
                          pattern:
                            /^[A-Z](?=.*[A-Za-z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@#$!%*?&_]{7,}$/,
                          message:
                            "Password must contain first letter capital and at least one alphanumeric character and a symbol from @#$%^&*",
                        },
                        {
                          max: 30,
                          message:
                            "Password must not be more than 30 characters long",
                        },
                        { validator: validatePassword },
                        { validator: validatePasswords },
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        data-testid="jest-input-password"
                        maxLength={31}
                        className="admin-register-input-password"
                        onChange={(e) => {
                          handlePasswordOnChange(e);
                        }}
                        onKeyPress={(e) => {
                          handleKeyPress(e);
                        }}
                        onKeyDown={handleKeyDown}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="confirm-password-field">
                    <span className="admin-register-input-title">
                      Confirm Password
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="confirm"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        { whitespace: true, message: "No white spaces" },
                        { required: true, message: "Re-enter Password" },

                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("password") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        data-testid="jest-input-confirm-password"
                        maxLength={30}
                        className="admin-register-input-password"
                        onChange={handleConfirmPasswordChange}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="terms-and-condition-row">
                    <Form.Item
                      name="terms_checked"
                      valuePropName="checked"
                      style={{ width: "100%" }}
                    >
                      <Checkbox
                        data-testid="agree-terms-checkbox"
                        className="agree-terms-checkbox"
                        type="checkbox"
                        onChange={handleTermsAcceptChange}
                      >
                        <span className="agree-text">Agree</span>
                      </Checkbox>
                      <span className="terms-conditions" onClick={handleTermsAndConditionOpen}>Terms and Condition</span>
                    </Form.Item>
                  </Row>
                  <Row className="buttons-container">
                    <span className="go-back" onClick={handlePreviousStep}>Go Back</span>
                    <Button className="register-button"
                      type="primary"
                      htmlType="submit"
                      disabled={!allFieldsFilled()}
                    >
                      Register
                    </Button>
                  </Row>
                  <Row className="existing-user-link">
                    <span className="">Already Have Account?</span>
                    <span className="login-link" onClick={() => {
                      navigate("/");
                    }}>Login</span>
                  </Row>
                </Row>)}
              </Form>
            </Row>
          </Col>
        </Row>
      </Spin>

      <Modal
        centered
        width={"647px"}
        open={locateOnMap}
        closable={false}
        footer={false}
        className="clinic-details-modal"
        style={{
          borderRadius: "5px",
        }}
      >
        <Row style={{ margin: "0 0 20px 0" }}>
          <span style={{ fontSize: "18px", fontWeight: "500" }}>
            You can select your clinic map address by using this maps :
          </span>
        </Row>
        <div style={{ height: "" }}>
          <Row>
            <SelectLocateOnMap
              setlatitude={setlatitude}
              setlongitude={setlongitude}
            />
          </Row>
        </div>
        <Row className="d-flex jc-between" style={{ margin: "20px 0 0 0" }}>
          {latitude ? (
            <Button
              style={{
                borderRadius: 30,
                border: "1px solid #29C3C1",
                background: "#FFF",
                color: "#29C3C1",
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() => setLocateOnMap(false)}
            >
              Save
            </Button>
          ) : (
            <span></span>
          )}
          <Button
            style={{
              borderRadius: 30,
              border: "1px solid #D35850",
              background: "#FFF",
              color: "#D35850",
              fontSize: 14,
              fontWeight: 600,
            }}
            onClick={() => handleCancelLocateMap()}
          >
            Cancel
          </Button>
        </Row>
      </Modal>

      <Modal
        centered
        width={"647px"}
        open={openTermsModal}
        closable={false}
        footer={false}
        className="clinic-details-modal"
        style={{
          borderRadius: "5px",
        }}
        destroyOnClose={true}
      >
        <div style={{ height: "50vh", overflow: "auto" }}>
          <Row>
            <h1>TERMS & CONDITIONS</h1>
            <section>
              <h3>Last Updated: 12-11-2023</h3>
              <p>
                These Terms and Conditions ("Terms") govern your use of Ivory.ai
                ("the App"), provided by [Your Company Name], an Indian company.
                By using the App, you agree to these Terms. If you do not agree
                with these Terms, you are advised to not use the App.
              </p>
            </section>
            <section>
              <h2>1. Acceptance of Terms</h2>
              <p>
                1.1 By accessing or using the App, you confirm that you are of
                legal age to enter into a binding contract in your jurisdiction.
              </p>
              <div>
                <p>
                  1.2 Ivory.ai is an online service offered by the Company. By
                  accessing or using any website with an authorized link to the
                  Website and/or the App, registering an account, or accessing
                  or using any content, information, services, features, or
                  resources available or enabled via the Website and/or the App
                  (collectively, the "Services"), clicking on a button, or
                  taking another action to signify your acceptance of this
                  Agreement, you
                </p>
                <p>
                  (a) agree to be bound by this Agreement and any future
                  amendments and additions to this Agreement as published
                  through the Services.
                </p>
                <p>
                  (b) represent you are of legal age in your jurisdiction of
                  residence to form a binding contract.
                </p>
                <p>
                  (c) represent that you have the authority to enter into this
                  Agreement personally and, if applicable, on behalf of any
                  company, organization, or other legal entity on whose behalf
                  you use the Services. If you are using the App on behalf of an
                  organization or entity, you represent and warrant that you
                  have the authority to bind that entity to these Terms, and
                  "you" refers to both you and the entity.
                </p>
                <p>
                  Except as otherwise provided herein, if you do not agree to be
                  bound by this Agreement, you may not access or use the
                  Services.
                </p>
              </div>
            </section>
            <section>
              <h2>2. App Purpose</h2>
              <p>
                2.1 The App provides dental information and self-diagnosis tools
                to assist users in assessing their dental health by way of (i)
                Patient Record Management System including managing contact
                details, treatment history, medical reports, progress notes and
                assessing health charts (ii) managing and processing the
                Information (defined below) of the patient in electronic format
                (iii) patient referrals through the mobile application (iv).
                Medical diagnosis of patient data such as X-rays by using AI
                Diagnosis tool and (v). patient appointment scheduling for
                meeting with the Client. It is not a substitute for professional
                dental advice, diagnosis, or treatment. The app shall refer you
                to consult with a qualified dental professional around your
                location for any dental health concerns.
              </p>
            </section>
            <section>
              <h3>3. User Responsibilities</h3>
              <p>
                3.1 You are solely responsible for the accuracy and completeness
                of any information you provide when using the App.
              </p>
              <p>
                3.2 You agree to use the App in accordance with these Terms and
                applicable laws and regulations. You will not violate any
                applicable law contract, intellectual property or other third
                party right or commit a tort, and you are solely responsible for
                your conduct while accessing or using the App. In connection
                with your use of the App and the Services, you will not
              </p>
              <ul>
                <li>
                  Engage in any harassing, threatening, intimidating, predatory,
                  or stalking conduct.
                </li>
                <li>
                  Impersonate another person or log into an account which you
                  are not authorized to access.
                </li>
                <li>
                  Use or attempt to use another User's account without
                  authorization from that User.
                </li>
                <li>
                  Use the App in any manner that could interfere with, disrupt
                  negatively affect or inhibit other users from fully enjoying
                  use of the App and the Services or that could damage, disable,
                  or impair the functioning of the App and the Services in any
                  manner or network security. Interfere or attempt to interfere
                  with service or any user, host or network, including by way of
                  introducing a virus, overloading.
                </li>
                <li>
                  Develop or use any third-party applications that Interact with
                  the App or Services without our prior written consent,
                  including any scripts designed to scrape or extract data from
                  the App.
                </li>
              </ul>
            </section>
            <section>
              <h3>4. Privacy Policy</h3>
              <p>
                4.1 This Privacy Policy has last been updated on 12-11-2023.
                Your use of the App is also governed by our Privacy Policy. By
                using the App, you consent to the collection, use, and sharing
                of your information as described in the Privacy Policy.
              </p>
              <p>
                While using Our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you. Personally identifiable information may
                include, but is not limited to:
              </p>
              <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Date of birth, Gender</li>
              </ul>
              <p>
                We also use information regarding your location to provide
                features of Our Service, to improve and customize Our Service.
                The information may be uploaded to the Company's servers and/or
                a Service Provider's server or it may be simply stored on Your
                device.
              </p>
              <ul>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>
                  Usage Data such as IP address, the time and date of Your
                  visit, the time spent on the platform, unique device
                  identifiers and other diagnostic data. (If not required can be
                  removed)
                </li>
              </ul>
              <p>The Personal Data shall be used for:</p>
              <ul>
                <li>To provide dental assessment and diagnostic services</li>
                <li>To manage your account on the app</li>
                <li>To manage your requests</li>
                <li>To contact you</li>
              </ul>
            </section>
            <section>
              <h3>5. No Medical Advice</h3>
              <p>
                5.1 The App does not provide medical or dental advice. The
                information provided through the App is for informational
                purposes only and is not a substitute for professional dental or
                medical advice, diagnosis, or treatment. Users are advised to
                use the dentist referral option on the app in case they need a
                professional dental diagnosis.
              </p>
            </section>
            <section>
              <h3>6. Limitation of Liability</h3>
              <p>
                6.1. Ivory.ai shall not be liable for any direct, indirect,
                incidental, special, consequential, or punitive damages, or any
                loss of profits, data, use, goodwill, or other intangible
                losses, resulting from your use of the App or reliance on the
                information provided by the App.
              </p>
            </section>
            <section>
              <h3>7. App Updates</h3>
              <p>
                7.1. Ivory.ai may release updates to the App from time to time.
                You are encouraged to use the latest version of the App for the
                most accurate information and functionality.
              </p>
            </section>
            <section>
              <h3>8. Termination</h3>
              <p>
                8.1. Ivory.ai may, at its sole discretion, terminate or suspend
                your access to the App at any time, with or without cause and
                without notice.
              </p>
            </section>
            <section>
              <h3>9. Changes to Terms</h3>
              <p>
                9.1. Ivory.ai reserves the right to modify or revise these Terms
                at any time. We will provide notice of any material changes.
                Your continued use of the App after the effective date of the
                updated Terms constitutes your acceptance of the changes.
              </p>
            </section>
            <section>
              <h3>10. Governing Law and Jurisdiction</h3>
              <p>
                10.1. These Terms shall be governed by and construed in
                accordance with the laws of India, without regard to its
                conflict of law principles. Any disputes arising under or in
                connection with these Terms shall be subject to the exclusive
                jurisdiction of the courts of Hyderabad.
              </p>
            </section>
            <section>
              <h3>
                11. Contact Information (If not required we can try to remove
                this part)
              </h3>
              <p>
                11.1. For questions or concerns regarding these Terms, please
                contact us at business@ivory.ai
              </p>
              <p>
                By accepting these Terms, you acknowledge that you have read,
                understood, and agree to be bound by them. These Terms are a
                legally binding agreement between you and Ivory.ai.
              </p>
            </section>
          </Row>
          <Row style={{ padding: "0 0 20px 0", gap: 15 }}>
            {/* <Button
              style={{
                borderRadius: 30,
                border: "1px solid #29c3c1",
                background: "#fff",
                color: "#29c3c1",
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() => {
                setTerms(true);
                setOpenTermsModal(false);
              }}
            >
              Accept
            </Button> */}
            <Button
              style={{
                borderRadius: 30,
                border: "1px solid #F7927E",
                background: "#fff",
                color: "#F7927E",
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() => {
                setOpenTermsModal(false)
              }}
            // onClick={() => {
            //   setTimeout(() => {
            //     form.resetFields();
            //     setTerms(false);
            //     setOpenTermsModal(false);
            //   }, 700);
            // }}
            >
              Close
            </Button>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default AdminSignup;
