import { Col, Pagination, Row } from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import ClinicData from "./clinic_data/ClinicData";

const ClinicList = (props) => {
  const { clinicList, status, handleGetAllClinics } = props;
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = window.innerWidth < 1550 ? 3 : 5;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = clinicList && clinicList.slice(startIndex, endIndex);

  useEffect(() => {
    handleGetAllClinics();
  }, []);

  return (
    <div
      style={{
        background: "#FFF",
      }}
      className="clinic-info"
    >
      <div>
        {/* header */}
        <Row
          style={{
            padding: "0px 32px",
            margin: "15px 4px 15px 0",
          }}
        >
          <Col span={1}></Col>
          <Col span={4}>
            <span
              style={{ fontWeight: "600", fontSize: "0.73vw", color: "#7D7D7D" }}
            >
              Clinic Name
            </span>
          </Col>
          <Col span={7}>
            <span
              style={{ fontWeight: "600", fontSize: "0.73vw", color: "#7D7D7D" }}
            >
              Address
            </span>
          </Col>
          <Col span={3}>
            <span
              style={{ fontWeight: "600", fontSize: "0.73vw", color: "#7D7D7D" }}
            >
              Contact
            </span>
          </Col>
          <Col span={4}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "0.73vw",
                color: "#7D7D7D",
              }}
            >
              Email
            </span>
          </Col>
          <Col span={5}>
            <span style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
              fontSize: "0.73vw",
              color: "#7D7D7D",
            }}>{status === "PENDING_APPROVAL" && "Registration Requests"}</span>
          </Col>
        </Row>

        {/* data */}
        {currentData && currentData.length > 0 ? (
          currentData.map((item) => {
            return (
              <ClinicData
                item={item}
                clinic_id={item.clinic_id}
                status={status}
                handleGetAllClinics={handleGetAllClinics}
                s3Url={
                  item.profile_picture_url &&
                  item.profile_picture_url.split(".com/")[1]
                }
              />
            );
          })
        ) : (
          <Row className="d-flex jc-center ai-center" style={{ height: "50vh" }}>
            <span
              style={{
                fontWeight: 700,
                fontSize: 25,
                color: "#323232",
                opacity: 0.8,
              }}
            >
              {status === "ACTIVE" && "You have no Clinics in the List"}
              {status === "PENDING_APPROVAL" &&
                "You have no Clinic Registration Requests"}
              {status === "INACTIVE" && "There is No De-Activated Clinics"}
            </span>
          </Row>
        )}
      </div>
      <div className="d-flex jc-end" style={{ padding: "10px 5px 0 0" }}>
        {clinicList && clinicList.length > 0 && (
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={clinicList && clinicList.length}
            onChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default ClinicList;
