import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  message,
} from "antd";
import { options } from "../../../../../utils/super_admin_constants/SuperAdminConsts";

const ClinicBranchModal = (props) => {
  const {
    handleAddClinicBranch,
    addClinicBranchModal,
    clinicDetails,
    setaddClinicBranchModal,
    setEnableForm,
    enableForm,
    form,
  } = props;

  //   const Token = localStorage.getItem("Token");
  //   const clinic_id = localStorage.getItem("clinic_id");

  //   const [spinner, setSpinner] = useState(false);
  //   const [clinicDetails, setClinicDetails] = useState();
  //   const [clinicBranches, setClinicBranches] = useState();
  //   const [addClinicBranchModal, setaddClinicBranchModal] = useState(false);
  //   const [clinic_img_url, setClinicImgUrl] = useState();

  const validatePhoneNumber = (_, value) => {
    const numericRegex = /^[0-9]+$/;
    if (!value || (numericRegex.test(value) && value.length === 10)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Please enter exactly 10 numeric characters for the phone number."
    );
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter 6-digit zip code!");
  };

  return (
    <Modal
      width={"58.2293vw"}
      centered
      open={addClinicBranchModal}
      footer={false}
      closable={false}
    >
      <Form
        form={form}
        initialValues={{
          remember: true,
        }}
        onFinish={handleAddClinicBranch}
        autoComplete="off"
        className="w-100"
      >
        <div style={{ padding: "1.0417vw" }}>
          <Row>
            <span
              style={{
                color: "#3498DB",
                fontSize: "1.25vw",
                fontWeight: 500,
              }}
            >
              Add New Dental Clinic Branch
            </span>
          </Row>
          <Row>
            <span
              style={{
                color: "#6F6F6F",
                fontSize: "0.73vw",
                fontWeight: 400,
              }}
            >
              Expand your hospital network by adding a new branch. Fill in the
              details below to create and manage this additional location.
            </span>
          </Row>
          {/* Hospital info */}
          <Row style={{ margin: "1.563vw 0 0 0" }}>
            <span
              style={{
                color: "#333333",
                fontSize: "0.834vw",
                fontWeight: 700,
              }}
            >
              Clinic Information
            </span>
          </Row>
          {clinicDetails && (
            <Row className="d-flex jc-between" style={{ margin: "1.25vw 0" }}>
              <Col span={8}>
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Clinic Name <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  <Input
                    disabled={enableForm}
                    value={clinicDetails.name}
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                      color: "black",
                    }}
                  />
                </Row>
              </Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Clinic Identification{" "}
                    <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  <Input
                    value={"CID #" + clinicDetails.clinic_id}
                    disabled={enableForm}
                    style={{
                      color: "black",
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Row>
              </Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Email <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  <Input
                    value={clinicDetails.email}
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                      color: "black",
                    }}
                    disabled={enableForm}
                  />
                </Row>
              </Col>
            </Row>
          )}
          <Row className="d-flex jc-between">
            <Col span={8}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Phone no. <span style={{ color: "#FF0000" }}>*</span>
                </span>
                {/* <Input
              style={{
                width: "16.771vw",
                margin: "5px 0 0 0",
                height: "2.0834vw",
                borderRadius: 3,
                border: "1px solid #DADADA",
              }}
            /> */}
                <Space.Compact
                  className="w-100"
                  style={{
                    width: "16.771vw",
                  }}
                >
                  <Form.Item name="phone_country_code" initialValue="+91">
                    <Select
                      bordered={false}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "2.0834vw",
                        background: "#FFFFFF",
                        border: "1px solid #E3E3E3",
                        margin: "3px 0 0 0",
                      }}
                      options={options}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: "100%" }}
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                      { validator: validatePhoneNumber },
                    ]}
                  >
                    <Input
                      bordered={false}
                      style={{
                        height: "2.0834vw",
                        background: "#FFFFFF",
                        borderTop: "1px solid #E3E3E3",
                        borderRight: "1px solid #E3E3E3",
                        borderBottom: "1px solid #E3E3E3",
                        margin: "3px 0 0 0",
                      }}
                      onKeyPress={(e) => {
                        const charCode = e.which || e.keyCode;
                        if (!(charCode >= 48 && charCode <= 57)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Space.Compact>
              </Row>
            </Col>
          </Row>
          {/* Branch address */}
          <Row style={{ margin: "1.563vw 0 0 0" }}>
            <span
              style={{
                color: "#333333",
                fontSize: "0.834vw",
                fontWeight: 700,
              }}
            >
              Branch Address
            </span>
          </Row>
          <Row style={{ margin: "1.25vw 0" }}>
            {/* <Col span={8}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Apartment <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Form.Item
                  name="apartment"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Apartment",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col> */}
            <Col span={8} style={{ display: "flex", justifyContent: "" }}>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Address <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please enter address",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  City <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Form.Item
                  name="city"
                  rules={[
                    { required: true, message: "Please enter City" },
                    {
                      pattern: /^[A-Za-z][A-Za-z\s]*$/,
                      message:
                        "First character should be alphabetical and City must contain only alphabeticals",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  State / Province <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Form.Item
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "Please enter State / Province",
                    },
                    {
                      pattern: /^[A-Za-z][A-Za-z\s]*$/,
                      message:
                        "First character should be alphabetical and State/Province must contain only alphabeticals",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Country <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Form.Item
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Country",
                    },
                    {
                      pattern: /^[A-Za-z][A-Za-z\s]*$/,
                      message:
                        "First character should be alphabetical and Country must contain only alphabeticals",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  ZIP Code <span style={{ color: "#FF0000" }}>*</span>
                </span>
                <Form.Item
                  name="clinic_branch_zipcode"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Zip code",
                    },
                    { validator: validateZipCode },
                  ]}
                >
                  <Input
                    style={{
                      width: "16.771vw",
                      margin: "5px 0 0 0",
                      height: "2.0834vw",
                      borderRadius: 3,
                      border: "1px solid #DADADA",
                    }}
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <Row
            className="d-flex jc-end ai-center"
            style={{ gap: 12, margin: "1.25vw 0 0 0" }}
          >
            <Button
              className="cancel-btn"
              onClick={() => setaddClinicBranchModal(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                height: "1.67vw",
                border: "1px solid #2CA3FA",
                borderRadius: "1.563vw",
                color: "#2CA3FA",
                background: "#FFF",
                fontSize: "0.73vw",
                fontWeight: 600,
              }}
              htmlType="submit"
            >
              Add
            </Button>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ClinicBranchModal;
