import { Button, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import ImageMap from "../../../../../home/modules/ml_analysis/xray_and_report_details/xray_image_component/image_map/ImageMap";

const XraysModal = (props) => {
  const { IsOpenFullXrayModal, setIsOpenFullXrayModal, xraysAndReports } =
    props;
  const [xrayImageUrl, setXrayImageUrl] = useState("");

  const handleSendingImageUrl = (imageUrl) => {
    setXrayImageUrl(imageUrl);
  };

  return (
    <div>
      <Modal
        open={IsOpenFullXrayModal}
        centered
        footer={false}
        closable={false}
        width={"51.145833333333336vw"}
        mask={false}
      >
        <div
          style={{
            height: "75.07692307692308vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Row className="w-100">
            <Row className="w-100 d-flex jc-between ai-center">
              <span
                style={{
                  fontWeight: 700,
                  fontSize: 22,
                  color: "#525252",
                }}
              >
                ML Analysis
              </span>
              <CloseOutlined
                style={{
                  color: "red",
                  fontSize: "20px",
                }}
                onClick={() => {
                  setIsOpenFullXrayModal(false);
                  setXrayImageUrl("");
                }}
              />
            </Row>
            {xraysAndReports && xraysAndReports.length > 0 ? (
              <>
                <Row
                  style={{
                    width: "100%",
                    margin: "25px 0 0 0",
                    gap: "10px",
                  }}
                >
                  <Col span={7}>
                    {xraysAndReports &&
                      xraysAndReports.map((item, id) => {
                        return item.xray_reports.map((item) => {
                          return (
                            <ImageMap
                              s3Url={item.report_image.split(".com/")[1]}
                              file_name={item.file_name}
                              title={item.title}
                              file_mime_type={item.file_mime_type}
                              handleSendingImageUrl={handleSendingImageUrl}
                            />
                          );
                        });
                      })}
                  </Col>
                  <Col
                    span={16}
                    style={{
                      margin: "5px 0 0 0",
                    }}
                  >
                    {xrayImageUrl ? (
                      <Row
                        style={{
                          width: "100%",
                        }}
                      >
                        <img
                          src={xrayImageUrl}
                          style={{
                            width: "100%",
                            height: "27vh",
                            borderRadius: "5px",
                          }}
                          alt=""
                        />
                      </Row>
                    ) : (
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "25vh",
                        }}
                      >
                        <span style={{ fontSize: "25px", fontWeight: "600" }}>
                          Click on Image to display here
                        </span>
                      </Row>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <span style={{ fontSize: "30px", fontWeight: "700" }}>
                    No Data
                  </span>
                </Row>
              </>
            )}
          </Row>
        </div>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85px",
          }}
        >
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
              fontSize: "16px",
              color: "#D35850",
              border: "1px solid #D35850",
              borderRadius: "16px",
            }}
            onClick={() => {
              setIsOpenFullXrayModal(false);
              setXrayImageUrl("");
            }}
          >
            Close
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

export default XraysModal;
