import {
  CloseOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  message,
} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import TabPane from "antd/lib/tabs/TabPane";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";
import defpropic from "../../../../../assets/default-profile-pic.png";
import API_BASE_URL from "../../../../../env";
import { routes_appointments } from "../../../../../utils/patient_navbar_constants/PatientNavbarConstants";
import {
  _scheduled_by_patient,
  _status_cancelled,
  _status_rescheduled,
  _status_scheduled,
} from "../../../../../utils/appointment_constants/AppointmentConstants";

const RescheduleAppointment = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const Token = localStorage.getItem("Token");
  const patientid = localStorage.getItem("patient_id");
  const navigate = useNavigate();
  const { appointment_id } = useParams();
  const [allDoctorsSlots, setallDoctorsSlots] = useState();
  const [clinicName, setClinicName] = useState("");
  const [clinicDetails, setClinicDetails] = useState();
  const [activeTab, setActiveTab] = useState("0");
  const today = moment();
  const [spinner, setSpinner] = useState(false);
  const [openBookAppointmentModal, setopenBookAppointmentModal] =
    useState(false);
  const [defaultAppointmentDetails, setdefaultAppointmentDetails] = useState();
  const [treatmentTypes, setTreatmentTypes] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [slotId, setslotId] = useState();
  const [docId, setDocId] = useState();
  const [first, setfirst] = useState();

  const dateTabs = [];

  const handleGetTreatmentsTypes = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/treatments?clinic_id=${
          defaultAppointmentDetails && defaultAppointmentDetails.clinic.id
        }`,
        config
      )
      .then((res) => {
        setTreatmentTypes(res.data.data);
      });
  };

  for (let i = 0; i < 14; i++) {
    const date = today.clone().add(i, "days");
    dateTabs.push({
      date: date.format("YYYY-MM-DD"),
      label: date.format("ddd, DD MMM"),
    });
  }

  const handleTabChange = (key) => {
    setSpinner(true);
    setActiveTab(key);
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/slots/getAvailableSlots/${clinicName}?date=${dateTabs[key].date}`,
        config
      )
      .then((res) => {
        setSpinner(false);
        setallDoctorsSlots(res.data.data);
      });
  };

  const handlePrevClick = () => {
    const prevTab = parseInt(activeTab) - 1;
    if (prevTab >= 0) {
      setActiveTab(prevTab.toString());
    }
  };

  const handleNextClick = () => {
    const nextTab = parseInt(activeTab) + 1;
    if (nextTab < dateTabs.length) {
      setActiveTab(nextTab.toString());
    }
  };

  const handleGetAppointmentById = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/appointment/${appointment_id}`, config)
      .then((res) => {
        setallDoctorsSlots(res.data.data.doctor);
        setdefaultAppointmentDetails(res.data.data);
        const config = {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        };
        axios
          .get(
            `${API_BASE_URL}/api/v1/slots/getSlots/${
              res.data.data.doctor.id
            }?date=${moment(res.data.data.from_date).format("YYYY-MM-DD")}`,
            config
          )
          .then((res) => {
            setSpinner(false);
            setfirst(res.data.data);
          });
      });
  };

  const handleGetAllSlotDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/slots/getAvailableSlots/${clinicName}?date=${moment(
          defaultAppointmentDetails && defaultAppointmentDetails.from_date
        ).format("YYYY-MM-DD")}`,
        config
      )
      .then((res) => {
        setSpinner(false);
        setallDoctorsSlots(res.data.data);
      });
  };

  const handleGetAllClinicDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios.get(`${API_BASE_URL}/api/v1/clinic`, config).then((res) => {
      setClinicDetails(res.data.data);
    });
  };

  const handleOpenBookAppointmentModal = (slot_id, doc_id, selected_slot) => {
    setopenBookAppointmentModal(true);
    setslotId(slot_id);
    setDocId(doc_id);
    setSelectedSlot(selected_slot);
  };

  const handleRescheduleAppoinment = () => {
    const payload = {
      patient_id: patientid,
      clinic_id: defaultAppointmentDetails.clinic.id,
      treatment_type_id: defaultAppointmentDetails.treatment_type.id,
      from_date: selectedSlot,
      scheduled_by: _scheduled_by_patient,
      status: _status_rescheduled,
      doc_id: docId,
      doc_slot: slotId,
      notes: "",
      branch_id: defaultAppointmentDetails.clinic_branch.branch_id,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(
        `${API_BASE_URL}/api/v1/appointment/${appointment_id}`,
        payload,
        config
      )
      .then((res) => {
        message.success(res.data.data);
        setopenBookAppointmentModal(false);
        navigate(routes_appointments);
      })
      .catch((err) => {
        message.warning(err.response.data.data.errors);
      });
  };

  useEffect(() => {
    handleGetAppointmentById();
    handleGetAllClinicDetails();
  }, []);

  useEffect(() => {
    handleGetTreatmentsTypes();
  }, [defaultAppointmentDetails]);

  useEffect(() => {
    handleGetAllSlotDetails();
  }, [clinicName]);

  return (
    <div className="book-appointment-main-div">
      <Helmet>
        <title>Patient Appointments</title>
      </Helmet>
      <Row
        style={{
          height: "6.564102564102564vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 1.1458333333333333vw",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            fontSize: 22,
            color: "#525252",
          }}
        >
          Book Appointment
        </span>
        <Button onClick={() => navigate(routes_appointments)}>Cancel</Button>
      </Row>
      <Spin
        indicator={antIcon}
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <Row
          style={{
            display: "flex",
            gap: "1.1458333333333333vw",
            margin: "0 1.1458333333333333vw",
          }}
        >
          <Col>
            <div
              className="select-clinic-treatment-col"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "75px",
              }}
            >
              <Row className="d-flex w-100 ai-center">
                <Col span={6}>
                  <div
                    style={{ display: "flex", gap: 20, alignItems: "center" }}
                  >
                    <span
                      style={{
                        color: "#525252",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Select Clinics
                    </span>
                    {defaultAppointmentDetails && (
                      <Select
                        defaultValue={defaultAppointmentDetails.clinic.name}
                        bordered={false}
                        style={{
                          borderRadius: 3,
                          border: "1px solid #E3E3E3",
                          background: "#FFF",
                          width: 243,
                          height: 36,
                        }}
                        disabled={true}
                        onChange={(value) => {
                          setClinicName(value);
                        }}
                      >
                        {clinicDetails &&
                          clinicDetails.map((item) => {
                            return (
                              <Select.Option value={item.clinic_id}>
                                {item.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    )}
                  </div>
                </Col>
                <Col span={6}>
                  <div
                    style={{ display: "flex", gap: 20, alignItems: "center" }}
                  >
                    <span
                      style={{
                        color: "#525252",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Treatment Type
                    </span>
                    {defaultAppointmentDetails && (
                      <Select
                        defaultValue={
                          defaultAppointmentDetails.treatment_type
                            .treatment_name
                        }
                        disabled={true}
                        bordered={false}
                        style={{
                          borderRadius: 3,
                          border: "1px solid #E3E3E3",
                          background: "#FFF",
                          width: 243,
                          height: 36,
                        }}
                      >
                        {treatmentTypes &&
                          treatmentTypes
                            .filter((item) => item.status === "ACTIVE")
                            .map((item) => {
                              return (
                                <Select.Option value={item.id}>
                                  {item.treatment_name}
                                </Select.Option>
                              );
                            })}
                      </Select>
                    )}
                  </div>
                </Col>
                {clinicName && (
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "30px",
                      width: "100%",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      onClick={handlePrevClick}
                      disabled={activeTab === "0"}
                      icon={<LeftOutlined />}
                      size="large"
                      style={{ borderRadius: "50%" }}
                    ></Button>
                    <Tabs
                      activeKey={activeTab}
                      onChange={handleTabChange}
                      style={{ width: "500px" }}
                    >
                      {dateTabs &&
                        dateTabs.map((tab, index) => (
                          <TabPane tab={tab.label} key={index.toString()}>
                            {/* <p>Date: {tab.date}</p> */}
                            {/* Add your content for each tab here */}
                          </TabPane>
                        ))}
                    </Tabs>
                    <Button
                      onClick={handleNextClick}
                      icon={<RightOutlined />}
                      disabled={activeTab === (dateTabs.length - 1).toString()}
                      size="large"
                      style={{ borderRadius: "50%" }}
                    ></Button>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
          <Col>
            <div className="select-slot-col">
              <Row>
                <Col span={12}>
                  <div
                    style={{
                      borderLeft: "6px solid #2CA3FA",
                      padding: "0 14px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        color: "#525252",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Appointment Date
                    </span>
                    <span
                      style={{
                        color: "#2CA3FA",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      {moment(
                        defaultAppointmentDetails &&
                          defaultAppointmentDetails.from_date
                      ).format("ddd, DD MMM YYYY")}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ padding: "20px 0" }}>
                <Col span={24} style={{ padding: "0 20px 0 0" }}>
                  {allDoctorsSlots ? (
                    <Collapse ghost className="w-100">
                      <CollapsePanel
                        style={{
                          border: "1px solid #D9D9D9",
                          margin: "0 0 5px 0",
                          borderRadius: 3,
                        }}
                        showArrow={false}
                        header={
                          <div
                            style={{
                              padding: "20px",
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Col span={4}>
                              <Row>
                                <Col>
                                  <img
                                    alt=""
                                    src={defpropic}
                                    style={{ width: 48, height: 48 }}
                                  ></img>
                                </Col>
                                <Col
                                  xl={15}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    margin: "0 0 0 10px",
                                  }}
                                >
                                  <Row>
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: 14,
                                        textDecorationLine: "underline",
                                        color: "#2CA3FA",
                                        display: "block",
                                        width: "100%",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {allDoctorsSlots.name}
                                    </span>
                                  </Row>
                                  <Row>
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: 14,
                                        color: "#000000",
                                      }}
                                    >
                                      #ID
                                      <span
                                        style={{
                                          color: "#7D7D7D",
                                        }}
                                      >
                                        &nbsp;{allDoctorsSlots.id}
                                      </span>
                                    </span>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={5}>{allDoctorsSlots.email}</Col>
                            <Col span={5}>{allDoctorsSlots.phone_number}</Col>
                            <Col span={5}>{allDoctorsSlots.qualification}</Col>
                            <Col
                              span={5}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button>Select Slot</Button>
                            </Col>
                          </div>
                        }
                        key="1"
                      >
                        <Row style={{ display: "flex", gap: 11 }}>
                          {first &&
                            first.map((data) => {
                              const currentDate = moment().format(
                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                              );
                              const inputDateMoment = moment(
                                data.from_time
                              ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                              const date1 = moment(inputDateMoment);
                              const date2 = moment(currentDate);
                              return (
                                <Button
                                  // className={
                                  className={
                                    data.status.name === "Scheduled" ||
                                    data.status.name === "Rescheduled" ||
                                    data.status.name === "Confirmed"
                                      ? "doctor-slot-div-booked"
                                      : "slot-div"
                                  }
                                  //   defaultAppointmentDetails.from_date ===
                                  //   data.from_time
                                  //     ? "slot-div-btn"
                                  //     : "slot-div"
                                  // }
                                  onClick={() => {
                                    if (
                                      data.status.name === "Scheduled" ||
                                      data.status.name === "Rescheduled" ||
                                      data.status.name === "Confirmed"
                                    ) {
                                      message.warning("Slot is booked");
                                    } else {
                                      handleOpenBookAppointmentModal(
                                        data.id,
                                        defaultAppointmentDetails.doctor.id,
                                        data.from_time
                                      );
                                    }
                                  }}
                                  // disabled={
                                  //   data.status.name === "Scheduled" ||
                                  //   data.status.name === "Rescheduled" ||
                                  //   data.status.name === "Confirmed"
                                  //     ? true
                                  //     : false
                                  // }
                                  disabled={
                                    date1.isBefore(date2) ? true : false
                                  }
                                >
                                  <span>
                                    {moment(data.from_time).format("HH:mm a")}
                                  </span>
                                </Button>
                              );
                            })}
                        </Row>
                      </CollapsePanel>
                    </Collapse>
                  ) : (
                    <div
                      className="w-100 d-flex jc-center ai-center"
                      style={{
                        fontSize: 20,
                        padding: 100,
                        fontWeight: 600,
                      }}
                    >
                      Select Clinic for available slots to display
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Spin>
      <Modal
        open={openBookAppointmentModal}
        width={"450px"}
        closable={false}
        footer={false}
        centered
        destroyOnClose={true}
        style={{
          borderRadius: 5,
          background: "#FFF",
          boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              fontWeight: "700",
              fontSize: "18px",
              color: "#525252",
            }}
          >
            Confirm Slot
          </span>
          <CloseOutlined
            style={{ color: "#FF0000", fontSize: 20 }}
            onClick={() => setopenBookAppointmentModal(false)}
          />
        </Row>
        <Row
          style={{
            padding: "50px 0",
            background: "#EEFEFF",
            border: "1px dashed #3CC9D2",
            margin: "20px 0",
            gap: 10,
          }}
        >
          <Row
            style={{
              width: "100%",
            }}
            className="d-flex jc-center ai-center flex-col"
          >
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#525252",
              }}
            >
              You have selected the slot at:&nbsp;
            </span>
            <Button
              className="slot-div-active"
              onClick={() => setopenBookAppointmentModal(true)}
            >
              <span>{moment(selectedSlot).format("HH:mm a")}</span>
            </Button>
          </Row>
        </Row>
        <Row className="w-100 d-flex jc-center" style={{ gap: 17 }}>
          <Button onClick={() => setopenBookAppointmentModal(false)}>
            Cancel
          </Button>
          <Button onClick={handleRescheduleAppoinment}>Confirm</Button>
        </Row>
      </Modal>
    </div>
  );
};

export default RescheduleAppointment;
