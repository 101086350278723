import { EllipsisOutlined, MoreOutlined } from "@ant-design/icons";
import { Checkbox, Col, Dropdown, Row, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import StaffProfilePicture from "../../clinic_admin_dashboard/StaffProfilePicture";
import API_BASE_URL from "../../../../../env";
import { UserAuth } from "../../../../../context_api/ContextApi";
import moment from "moment";
import AppointmentProfilePic from "../../admin_appointments/AppointmentProfilePic";

const StaffDetails = (props) => {
  const { navigateTo, handleGetAllAdminDetails } = props;
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const Token = localStorage.getItem("Token");
  const [spinner, setspinner] = useState(true);
  const { setAdminTabActiveKey } = UserAuth();

  const { item, checkedDoctorValues } = props;

  const handleDeleteStaff = async () => {
    setspinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .delete(`${API_BASE_URL}/api/v1/admin/${item.id}`, config)
      .then((res) => {
        message.success("Status deleted");
        setspinner(false);
        handleGetAllAdminDetails();
      });
  };

  const patientDeleteDropdown = (
    <div className="patient-delete-dropdown">
      <Row
        className="full-profile-row"
        onClick={() => {
          navigate(`${navigateTo}/${item.id}`);
          setAdminTabActiveKey("");
        }}
      >
        View full profile
      </Row>
      {role === "CLINIC_ADMIN" && item.access_type_name === "Admin" ? (
        <></>
      ) : (
        <Row className="delete-patient-row" onClick={handleDeleteStaff}>
          Delete
        </Row>
      )}
    </div>
  );

  const items = [
    {
      label: (
        <span
          onClick={() => {
            navigate(`${navigateTo}/${item.id}`);
            setAdminTabActiveKey("");
          }}
        >
          View Profile
        </span>
      ),
      key: "0",
    },
    {
      label: <span>Sent Reminder</span>,
      key: "1",
    },
    {
      label: <span>Chat Now</span>,
      key: "2",
    },
    {
      label: <span onClick={handleDeleteStaff}>Delete</span>,
      key: "3",
    },
  ];

  return (
    <div
      style={{
        borderBottom: "1px solid #DDD",
        width: "100%",
      }}
    >
      <Row
        className={
          checkedDoctorValues && checkedDoctorValues.includes(item.id)
            ? "checked-doctor-details-row"
            : "doctor-details-row"
        }
      >
        <Row
          className="d-flex w-100 ai-center"
          style={{
            padding: "1.25vw 60px",
          }}
        >
          <Col span={1}>
            {role !== "SUPER_ADMIN" && (
              <Checkbox value={item.id} className="custom-checkbox" />
            )}
          </Col>
          {/* <Col span={3}></Col> */}
          <Col span={5}>
            <Row
              style={{
                display: "flex",
                gap: "0.417vw",
              }}
            >
              <AppointmentProfilePic
                pro={
                  item &&
                  item.profile_picture_url &&
                  item.profile_picture_url.split(".com/")[1]
                }
              />
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.417vw",
                }}
              >
                <span
                  onClick={() => {
                    navigate(`${navigateTo}/${item.id}`);
                    setAdminTabActiveKey("");
                  }}
                  style={{
                    color: "#3498DB",
                    fontSize: "0.8vw",
                    fontWeight: 600,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {item.name}
                </span>

                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.73vw",
                    color: "#000000",
                  }}
                >
                  SID #
                  <span
                    style={{
                      color: "#7D7D7D",
                    }}
                  >
                    {item.id}
                  </span>
                </span>
              </Row>
            </Row>
          </Col>
          <Col span={4}>
            <Row>
              <span className="patient-list-table-data">
                {item.designation_name && item.designation_name}
              </span>
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  padding: "0.208vw 0.834vw",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0.26vw",
                  background: "rgba(207, 227, 241, 0.70)",
                }}
              >
                <span
                  style={{
                    fontSize: "0.63vw",
                    fontWeight: 400,
                    color: "#333",
                  }}
                >
                  {item.access_type_name === "Can Edit" ? "Staff" : "Admin"}
                </span>
              </div>
            </Row>
          </Col>
          <Col span={4}>
            {item.specialization ? (
              <span className="patient-list-table-data">
                {JSON.parse(item.specialization).length > 0 ? (
                  JSON.parse(item.specialization).map((data) => {
                    return <>{data}, </>;
                  })
                ) : (
                  <></>
                )}
              </span>
            ) : (
              <span className="patient-list-table-data">NA</span>
            )}
          </Col>
          <Col span={4}>
            <span
              style={{
                fontSize: "0.834vw",
                fontWeight: 500,
              }}
            >
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "0 0 0 10px",
                }}
              >
                <Row>
                  <span
                    style={{
                      color: "#333",
                      fontSize: "0.8vw",
                      fontWeight: 600,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                    }}
                  >
                    {item.email}
                  </span>
                </Row>
                <Row>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "0.73vw",
                      color: "#7D7D7D",
                    }}
                  >
                    {item.phone_number}
                  </span>
                </Row>
              </Row>
            </span>
          </Col>
          <Col span={4} style={{ display: "flex", alignItems: "center" }}>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 0 0 10px",
              }}
            >
              <Row>
                <span
                  style={{
                    color: "#3498DB",
                    fontSize: "0.8vw",
                    fontWeight: 600,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {item.next_appointment && item.next_appointment.from_date
                    ? moment(item.next_appointment.from_date).format(
                        "MMMM Do YYYY"
                      )
                    : "NA"}
                </span>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.73vw",
                    color: "#7D7D7D",
                  }}
                >
                  {item.next_appointment &&
                  item.next_appointment.treatment_type.treatment_name
                    ? item.next_appointment.treatment_type.treatment_name
                    : "NA"}
                </span>
              </Row>
            </Row>
          </Col>
          <Col
            span={2}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              placement="bottomRight"
            >
              <EllipsisOutlined
                rotate={90}
                style={{ fontSize: "1.25vw", cursor: "pointer" }}
              />
            </Dropdown>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default StaffDetails;
