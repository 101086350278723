import { Button, Col, Row, Modal, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import deletexrayicon from "../../../../../assets/delete-xray-icon.png";
import axios from "axios";
import { CloseOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import API_BASE_URL from "../../../../../env";
import {
  intra_oral,
  misc_files,
  other_reports,
  xrays,
} from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import { useLocation, useParams } from "react-router";
import { UserAuth } from "../../../../../context_api/ContextApi";
import dicomParser from "dicom-parser";

const UploafXrayModal = (props) => {
  const {
    isOpenUploadXrayModal,
    setIsOpenUploadXrayModal,
    reportType,
    getXrayDetails,
    setopenAddReportModal,
    appointmentData,
  } = props;
  const { setSpinner } = UserAuth();
  const patientid_local = localStorage.getItem("patient_id");
  const clinic_id = localStorage.getItem("clinic_id");
  const Token = localStorage.getItem("Token");
  const location = useLocation();
  const key = location.pathname;
  const { patient_id, url_clinic_id } = useParams();

  // temp disaply of images before upload
  const [xRayArrayStore, setXrayArrayStore] = useState([]);
  const [xrayImageUrl, setXrayImageUrl] = useState("");
  const [xrayFileName, setXrayFileName] = useState(null);
  const [xraySendingToBE, setXraySendingToBE] = useState([]);

  // for Uploading Xray and report
  const hiddenXrayFileInput = useRef(null);

  // Button click action for Uploading X-ray Multiple Times ( triggers on browse button )
  const handleXrayUpload = () => {
    hiddenXrayFileInput.current.click();
  };

  const handleXrayImageUpload = async (e) => {
    const file = e.target.files;
    for (let i = 0; i < file.length; i++) {
      if (file[i].name.toLowerCase().endsWith(".dcm")) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const dicomArrayBuffer = event.target.result;
          const byteArray = new Uint8Array(dicomArrayBuffer);
          const dataSet = dicomParser.parseDicom(byteArray);
          // Extract pixel data from the DICOM file
          const pixelDataElement = dataSet.elements.x7fe00010;
          const pixelData = new Uint8Array(
            dataSet.byteArray.buffer,
            pixelDataElement.dataOffset,
            pixelDataElement.length
          );
          // Create a canvas and draw the image
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const rows = dataSet.uint16("x00280010");
          const columns = dataSet.uint16("x00280011");
          const bitsAllocated = dataSet.uint16("x00280100");
          const pixelRepresentation = dataSet.uint16("x00280103");
          const imageData = context.createImageData(columns, rows);
          for (let i = 0; i < pixelData.length; i++) {
            // Handle different pixel representations and bits allocated
            if (bitsAllocated === 8) {
              imageData.data[i * 4] = pixelData[i];
              imageData.data[i * 4 + 1] = pixelData[i];
              imageData.data[i * 4 + 2] = pixelData[i];
              imageData.data[i * 4 + 3] = 255; // Alpha channel
            } else if (bitsAllocated === 16 && pixelRepresentation === 0) {
              const value = pixelData[i] + (pixelData[i + 1] << 8);
              const normalizedValue = (value / 65535) * 255;
              imageData.data[i * 4] = normalizedValue;
              imageData.data[i * 4 + 1] = normalizedValue;
              imageData.data[i * 4 + 2] = normalizedValue;
              imageData.data[i * 4 + 3] = 255; // Alpha channel
              i++; // Skip the next byte
            } else {
              // Handle other cases as needed
            }
          }
          canvas.width = columns;
          canvas.height = rows;
          context.putImageData(imageData, 0, 0);
          // Convert canvas to PNG blob
          canvas.toBlob((blob) => {
            // Create a File object from the blob
            const convertedImageFile = new File(
              [blob],
              file[i].name.split(".dcm" ? ".dcm" : ".DCM")[0] + ".png",
              {
                type: "image/png",
                lastModified: new Date().getTime(),
              }
            );
            // Optionally, you can handle the blob (e.g., send to the server, display, etc.)
            let tempXrayArr = xraySendingToBE;
            tempXrayArr.push(convertedImageFile);
            let tempXrayArrURL = xRayArrayStore;
            tempXrayArrURL.push({
              name: convertedImageFile.name,
              report_url: URL.createObjectURL(convertedImageFile),
            });
            setXrayArrayStore(tempXrayArrURL);
            setXraySendingToBE(tempXrayArr);
            setXrayFileName(file[i]);
          }, "image/png");
        };
        reader.readAsArrayBuffer(file[i]);
      } else {
        let tempXrayArr = xraySendingToBE;
        tempXrayArr.push(file[i]);
        let tempXrayArrURL = xRayArrayStore;
        tempXrayArrURL.push({
          name: file[i].name,
          report_url: URL.createObjectURL(file[i]),
        });
        setXrayArrayStore(tempXrayArrURL);
        setXraySendingToBE(tempXrayArr);
        setXrayFileName(file[i]);
      }
    }
  };

  // submit xray to backend
  const handleAddXrayForPatient = async () => {
    setSpinner(true);
    setIsOpenUploadXrayModal(false);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    let formData = new FormData();
    xraySendingToBE.forEach((file) => {
      formData.append("file", file);
    });
    if (key === "/patient/ml-analysis") {
      await axios
        .post(
          `${API_BASE_URL}/api/v1/report/${patientid_local}/upload-reports/${reportType}`,
          formData,
          config
        )
        .then((res) => {
          if (reportType === xrays) {
            setSpinner(false);
            message.success("Added Xrays successfully");
          } else if (reportType === intra_oral) {
            setSpinner(false);
            message.success("Added Intra Oral report successfully");
          } else if (reportType === misc_files) {
            setSpinner(false);
            message.success("Added Misc Images successfully");
          }
          setXrayArrayStore([]);
          setXrayImageUrl("");
          setXraySendingToBE([]);
          hiddenXrayFileInput.current.value = null;
          getXrayDetails();
          setopenAddReportModal(false);
        })
        .catch((err) => {
          setSpinner(false);
          message.error(err.response.data.data.errors);
          setXrayArrayStore([]);
          setXrayImageUrl("");
          setXraySendingToBE([]);
          hiddenXrayFileInput.current.value = null;
          getXrayDetails();
          setopenAddReportModal(false);
        });
    } else {
      await axios
        .post(
          `${API_BASE_URL}/api/v1/clinic/${
            url_clinic_id ? url_clinic_id : clinic_id
          }/${
            patient_id ? patient_id : patientid_local
          }/treatment-type-id/upload-reports/${reportType}?treatmentTypeId=${
            appointmentData.treatment_type.id
          }`,
          formData,
          config
        )
        .then((res) => {
          if (reportType === xrays) {
            setSpinner(false);
            message.success("Added Xrays successfully");
          } else if (reportType === intra_oral) {
            setSpinner(false);
            message.success("Added Intra Oral report successfully");
          } else if (reportType === misc_files) {
            setSpinner(false);
            message.success("Added Misc Images successfully");
          }
          setXrayArrayStore([]);
          setXrayImageUrl("");
          setXraySendingToBE([]);
          hiddenXrayFileInput.current.value = null;
          getXrayDetails();
          setopenAddReportModal(false);
        })
        .catch((err) => {
          setSpinner(false);
          message.error(err.response.data.data.errors);
          setXrayArrayStore([]);
          setXrayImageUrl("");
          setXraySendingToBE([]);
          hiddenXrayFileInput.current.value = null;
          getXrayDetails();
          setopenAddReportModal(false);
        });
    }
  };

  const handleDeletingXrayFromInitialList = (index) => {
    const newItems = xRayArrayStore.filter((item, i) => i !== index);
    setXrayArrayStore(newItems);
  };

  useEffect(() => {
    getXrayDetails();
  }, []);

  return (
    <Modal
      open={isOpenUploadXrayModal}
      centered
      footer={false}
      closable={false}
      width={window.innerWidth > 700 && "51.145833333333336vw"}
      mask={false}
    >
      <div
        style={{
          minHeight: "75.07692307692308vh",
        }}
      >
        <Row className="d-flex jc-between ai-center">
          <Col>
            <span
              style={{
                fontWeight: 700,
                fontSize: 22,
                color: "#525252",
              }}
            >
              {reportType === xrays && "Upload X-Rays"}
              {reportType === intra_oral && "Upload Intra-oral reports"}
              {reportType === misc_files && "Upload Miscellaneous"}
            </span>
          </Col>
          <Col style={{ display: "flex", alignItems: "center", gap: "30px" }}>
            <Button onClick={handleXrayUpload} className="browse-xray-btn">
              <input
                type="file"
                id="file"
                accept=".png, .jpg, .jpeg, .dcm"
                multiple
                ref={hiddenXrayFileInput}
                style={{ display: "none" }}
                onChange={(e) => handleXrayImageUpload(e)}
              />
              {reportType === xrays && "Add X-Rays for ML"}
              {reportType === intra_oral && "Add Intra-oral reports"}
              {reportType === misc_files && "Add Miscellaneous"}
            </Button>
            <CloseOutlined
              style={{
                color: "red",
                fontSize: "16px",
              }}
              onClick={() => {
                setIsOpenUploadXrayModal(false);
                setXrayArrayStore([]);
                setXrayImageUrl();
              }}
            />
          </Col>
        </Row>

        <Row
          style={{
            width: "100%",
            margin: "25px 0 0 0",
            gap: "10px",
          }}
        >
          <Col lg={10}>
            {xRayArrayStore &&
              xRayArrayStore.map((item, id) => {
                return (
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "#F1FBFF",
                      padding: "10px 0",
                      margin: "5px 0 0 0",
                    }}
                  >
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      span={7}
                    >
                      <img
                        style={{
                          height: "41px",
                          width: "59px",
                          cursor: "pointer",
                        }}
                        // src={item && URL.createObjectURL(item)}
                        src={item && item.report_url}
                        alt=""
                        onClick={() => {
                          // setXrayImageUrl(item && URL.createObjectURL(item));
                          setXrayImageUrl(item && item.report_url);
                        }}
                      />
                    </Col>
                    <Col span={13}>
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#525252",
                          display: "block",
                          width: "100%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.name}
                      </span>
                    </Col>
                    <Col
                      span={4}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        style={{ height: "15px", cursor: "pointer" }}
                        src={deletexrayicon}
                        alt=""
                        onClick={() => handleDeletingXrayFromInitialList(id)}
                      />
                    </Col>
                  </Row>
                );
              })}
          </Col>
          {xrayImageUrl ? (
            <Col
              lg={13}
              style={{
                margin: "5px 0 0 0",
              }}
            >
              <Row>
                <img
                  src={xrayImageUrl}
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                  }}
                  alt=""
                />
              </Row>
              <Row style={{ margin: "20px 0 0 0" }}>
                <span>Suggested Treatment</span>
                <TextArea></TextArea>
              </Row>
            </Col>
          ) : (
            <Col
              span={13}
              style={{
                margin: "5px 0 0 0",
              }}
            >
              <Row>
                {xRayArrayStore &&
                  xRayArrayStore.slice(0, 1).map((item) => {
                    return (
                      <img
                        // src={item && URL.createObjectURL(item)}
                        src={item && item.report_url}
                        style={{
                          width: "100%",
                          height: "269px",
                          borderRadius: "5px",
                        }}
                        alt=""
                      />
                    );
                  })}
              </Row>
              {xRayArrayStore && xRayArrayStore.length === 0 && (
                <div
                  style={{
                    height: "51.282051282051285vh",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontSize: 35, height: 100 }}>
                    {reportType === xrays && "No X-Rays"}
                    {reportType === other_reports && "No Reports"}
                  </span>
                </div>
              )}
            </Col>
          )}
        </Row>

        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85px",
          }}
        >
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
              fontSize: "16px",
              color: "#D35850",
              border: "1px solid #D35850",
              borderRadius: "16px",
            }}
            onClick={handleAddXrayForPatient}
          >
            Save
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default UploafXrayModal;
