import { Input, Tabs, Button } from "antd";
import { Helmet } from "react-helmet";
import RegisterNewClinic from "./register_new_clinic/RegisterNewClinic";
import { Row } from "antd";
import React, { useState } from "react";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import ClinicList from "./clinic_list/ClinicList";
import API_BASE_URL from "../../../../env";
import {
  ADMIN,
  CUSTOMER_CARE,
  DATA_SCIENTIST,
  SALES,
  SUPER_ADMIN,
} from "../../../../utils/super_admin_constants/SuperAdminConsts";
import "./SuperAdminClinics.css";
import { UserAuth } from "../../../../context_api/ContextApi";

const SuperAdminClinics = () => {
  const Token = localStorage.getItem("Token");
  const role = localStorage.getItem("role");
  const [pendingClinics, setPendingClinics] = useState();
  const [activeClinics, setActiveClinics] = useState();
  const [inActiveClinics, setInActiveClinics] = useState();
  const status_active = "ACTIVE";
  const status_pending = "PENDING_APPROVAL";
  const status_inactive = "INACTIVE";
  const [searchInput, setSearchInput] = useState("");
  const { super_admin_clinic_route_key } = UserAuth();

  // PENDING
  const handleGetAllPendingClinics = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/clinic/clinics?status=${status_pending}`,
        config
      )
      .then((res) => {
        setPendingClinics(res.data.data);
      });
  };

  // ACTIVE
  const handleGetAllActiveClinics = () => {
    setSearchInput("");
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/clinic/clinics?status=${status_active}`,
        config
      )
      .then((res) => {
        setActiveClinics(res.data.data);
      });
  };

  const handleGetAllInActiveClinics = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/clinic/clinics?status=${status_inactive}`,
        config
      )
      .then((res) => {
        setInActiveClinics(res.data.data);
      });
  };

  // clinic search
  const handleGetClinicsBySearch = (e) => {
    const search_clinic = e.target.value;
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/clinic/searchClinic?clinicIdOrName=${search_clinic}`,
        config
      )
      .then((res) => {
        setActiveClinics(res.data.data);
      })
      .catch((err) => {
        handleGetAllActiveClinics();
      });
  };

  return (
    <div className="clinics-list-main-div">
      <Helmet>
        <title>Clinics</title>
      </Helmet>
      <Row
        className="d-flex ai-center jc-between"
        style={{ margin: "0.63vw 1.25vw" }}
      >
        <span
          style={{
            fontWeight: 700,
            fontSize: "1.145833vw",
            color: "#525252",
          }}
        >
          {searchInput !== ""
            ? `Clinic Search Results (${activeClinics && activeClinics.length})`
            : "Dashboard"}
        </span>
        <Row
          className="d-flex jc-center ai-center"
          style={{
            gap: "1.25vw",
          }}
        >
          <Input
            style={{
              width: "20.782vw",
              height: "4.155vh",
              borderRadius: "0.1563vw",
            }}
            className="d-flex ai-center"
            placeholder="Search Clinic or Global Patients by Name or ID"
            prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
            value={searchInput}
            onChange={(e) => {
              handleGetClinicsBySearch(e);
              setSearchInput(e.target.value);
            }}
            suffix={
              <CloseOutlined
                style={{ height: "10px", color: "#A5A5A5" }}
                onClick={() => {
                  setSearchInput("");
                  handleGetAllActiveClinics();
                }}
              />
            }
          />
        </Row>
      </Row>
      <Row
        style={{
          background: "#fff",
          margin: "0 1.25vw",
          padding: "1.563vw 2.0834vw",
          borderRadius: "0.52084vw"
          // minHeight: "85vh",
        }}
        className="d-flex"
      >
        {searchInput !== "" && (
          <div className="w-100">
            <ClinicList
              clinicList={activeClinics}
              status={status_active}
              handleGetAllClinics={handleGetAllPendingClinics}
            />
          </div>
        )}
        {searchInput === "" && (
          <div style={{
            width: "100%"
          }}>
            <Tabs
              onTabClick={(activKey) => {
                if (activKey === "1") {
                  handleGetAllPendingClinics();
                } else if (activKey === "3") {
                  handleGetAllActiveClinics();
                } else if (activKey === "4") {
                  handleGetAllInActiveClinics();
                }
              }}
              type="card"
              defaultActiveKey="1"
              style={{ width: "100%" }}
              className="tab-list"
            >
              {role === SUPER_ADMIN ||
                role === ADMIN ||
                role === CUSTOMER_CARE ? (
                <Tabs.TabPane tab="Clinic Registration Requests" key="1">
                  <ClinicList
                    clinicList={pendingClinics}
                    status={status_pending}
                    handleGetAllClinics={handleGetAllPendingClinics}
                  />
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              {role === SUPER_ADMIN ||
                role === ADMIN ||
                role === SALES ||
                role === CUSTOMER_CARE ? (
                <Tabs.TabPane tab="Register New Clinic" key="2">
                  <RegisterNewClinic />
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              {role === SUPER_ADMIN ||
                role === ADMIN ||
                role === SALES ||
                role === DATA_SCIENTIST ||
                role === CUSTOMER_CARE ? (
                <Tabs.TabPane tab="Active Clinics" key="3">
                  <ClinicList
                    clinicList={activeClinics}
                    status={status_active}
                    handleGetAllClinics={handleGetAllActiveClinics}
                  />
                </Tabs.TabPane>
              ) : (
                <></>
              )}
              {role === SUPER_ADMIN || role === ADMIN ? (
                <Tabs.TabPane tab="De-Activated Clinics" key="4">
                  <ClinicList
                    clinicList={inActiveClinics}
                    status={status_inactive}
                    handleGetAllClinics={handleGetAllInActiveClinics}
                  />
                </Tabs.TabPane>
              ) : (
                <></>
              )}

            </Tabs>

          </div>
        )}
      </Row>
    </div>
  );
};

export default SuperAdminClinics;
