import { MoreOutlined } from "@ant-design/icons";
import { Col, Dropdown, Row, message } from "antd";
import React from "react";
import "./SubUsersDetails.css";
import { useNavigate } from "react-router";
import API_BASE_URL from "../../../../../env";
import axios from "axios";

const SubUsersDetails = (props) => {
  const Token = localStorage.getItem("Token");
  const { SubUsers, handleGetAllSubUsers } = props;
  const navigate = useNavigate();

  const handleDeleteSubUser = async (userID) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .delete(`${API_BASE_URL}/api/v1/user/${userID}`, config)
      .then((res) => {
        handleGetAllSubUsers();
        message.success("User Deleted Sucessfully");
      });
  };

  const subUserDeleteDropdown = (
    <div className="sub-user-delete-dropdown">
      <Row
        className="sub-user-profile-row"
        onClick={() => {
          navigate(`/super-admin/sub-user-profile/${SubUsers.user_id}`);
        }}
      >
        View full profile
      </Row>

      <Row
        className="delete-patient-row"
        onClick={() => {
          handleDeleteSubUser(SubUsers.user_id);
        }}
      >
        Delete
      </Row>
    </div>
  );

  return (
    <Row
      style={{
        display: "flex",
        alignItems: "center",
        width: "94vw",
        minHeight: "88px",
        background: "#FBFBFB",
        borderRadius: 5,
        padding: "25px 32px 25px 32px",
        margin: "4px 0 0 0",
      }}
    >
      <Col
        span={4}
        style={{
          display: "flex",
          flexDirection: "column",
          fontWeight: "400",
          fontSize: "14px",
          color: "#7D7D7D",
        }}
      >
        <span>{SubUsers && SubUsers.created_at}</span>
      </Col>

      <Col span={4}>
        <Row>
          <Col
            span={15}
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "0 0 0 10px",
            }}
          >
            <span
              style={{
                fontWeight: "600",
                fontSize: "16px",
                color: "#2CA3FA",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/super-admin/sub-user-profile/${SubUsers.user_id}`);
              }}
            >
              {SubUsers.name}
            </span>
            <span
              style={{
                fontWeight: "600",
                fontSize: "16px",
                color: "#000000",
              }}
            >
              UID#
              <span style={{ color: "#7D7D7D" }}>
                {SubUsers && SubUsers.user_id}
              </span>
            </span>
          </Col>
        </Row>
      </Col>
      <Col
        span={5}
        style={{ fontWeight: "400", fontSize: "16px", color: "#7D7D7D" }}
      >
        {SubUsers.email}
      </Col>
      <Col
        span={5}
        style={{ fontWeight: "400", fontSize: "16px", color: "#7D7D7D" }}
      >
        {SubUsers.phone_number}
      </Col>
      <Col
        span={5}
        style={{
          fontWeight: "400",
          fontSize: "16px",
          color: "#7D7D7D",
          display: "block",
          width: "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {SubUsers.role_type}
      </Col>
      <Col
        span={1}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span style={{ fontSize: "24px", color: "#7D7D7D", cursor: "pointer" }}>
          <Dropdown overlay={subUserDeleteDropdown} placement="bottomRight">
            <MoreOutlined />
          </Dropdown>
        </span>
      </Col>
    </Row>
  );
};

export default SubUsersDetails;
