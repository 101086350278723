import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import ClinicTreatmentsModal from "./clinic_branch_modals/ClinicTreatmentsModal";

const ClinicTreatments = ({
  item,
  handleDeleteTreatmentType,
  role,
  clinicDetails,
  form,
  handleGetTreatmentsTypes,
}) => {
  const [editTreatmentModal, seteditTreatmentModal] = useState(false);

  return (
    <>
      <Row className="w-100">
        <Row className="d-flex" style={{ gap: "2.6045vw" }}>
          <Col>
            <Row>
              <span
                style={{
                  fontSize: "0.73vw",
                  fontWeight: 400,
                  color: "#BDC3C7",
                }}
              >
                Treatment Type
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                {item.treatment_type}
              </span>
            </Row>
          </Col>
          <Col>
            <Row>
              <span
                style={{
                  fontSize: "0.73vw",
                  fontWeight: 400,
                  color: "#BDC3C7",
                }}
              >
                Treatment Name
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                {item.treatment_name}
              </span>
            </Row>
          </Col>
          <Col>
            <Row>
              <span
                style={{
                  fontSize: "0.73vw",
                  fontWeight: 400,
                  color: "#BDC3C7",
                }}
              >
                Duration
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                {item.duration} Hours
              </span>
            </Row>
          </Col>
          <Col>
            <Row>
              <span
                style={{
                  fontSize: "0.73vw",
                  fontWeight: 400,
                  color: "#BDC3C7",
                }}
              >
                Cost
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 500,
                }}
              >
                Rs.{item.rate}/-
              </span>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            margin: "1.0417vw 0 0 0",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Col
            style={{
              width: "20.834vw",
            }}
          >
            <Row>
              <span
                style={{
                  fontSize: "0.73vw",
                  fontWeight: 400,
                  color: "#BDC3C7",
                }}
              >
                Description
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 500,
                  width: "20.834vw",
                }}
              >
                {item.description}
              </span>
            </Row>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "0.63vw",
            }}
          >
            {role === "CLINIC_MASTER_ADMIN" && (
              <>
                <Button
                  onClick={() => {
                    // setitem(item);
                    seteditTreatmentModal(true);
                  }}
                  style={{
                    height: "1.67vw",
                    border: "1px solid #2CA3FA",
                    borderRadius: "1.563vw",
                    color: "#2CA3FA",
                    background: "#FFF",
                    fontSize: "0.73vw",
                    fontWeight: 600,
                  }}
                >
                  Edit
                </Button>
                <Button
                  style={{
                    height: "3.116vh",
                    border: "1px solid #EC7063",
                    borderRadius: "1.563vw",
                    color: "#EC7063",
                    background: "#FFF",
                    fontSize: "0.73vw",
                    fontWeight: 600,
                  }}
                  onClick={() => handleDeleteTreatmentType(item.id)}
                >
                  Delete
                </Button>
              </>
            )}
          </Col>
        </Row>
        <Row style={{ padding: "0.782vw 0px" }} className="w-100">
          <div
            style={{
              height: 1,
              background: "#D8D8D8",
              width: "100%",
            }}
          ></div>
        </Row>
      </Row>

      <ClinicTreatmentsModal
        item={item}
        handleGetTreatmentsTypes={handleGetTreatmentsTypes}
        // handleAddNewTreatmentType={handleAddNewTreatmentType}
        editTreatmentModal={editTreatmentModal}
        clinicDetails={clinicDetails}
        seteditTreatmentModal={seteditTreatmentModal}
        // setEnableForm={setEnableForm}
        enableForm={true}
        form={form}
        type="edit"
      />
    </>
  );
};

export default ClinicTreatments;
