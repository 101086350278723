import { Button, Checkbox, Col, Form, Input, message, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import StaffDetails from "./staff_details/StaffDetails";
import "./AdminStaff.css";
import axios from "axios";
import API_BASE_URL from "../../../../env";
import { UserAuth } from "../../../../context_api/ContextApi";
import {
  CloseOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AddNewStaffModal from "./adding_new_staff/AddNewStaffModal";

const AdminStaff = () => {
  const [form] = Form.useForm();
  const branch_id = localStorage.getItem("main_branch_id")
    ? localStorage.getItem("main_branch_id")
    : localStorage.getItem("branch_id");
  const clinic_id = localStorage.getItem("clinic_id");
  const Token = localStorage.getItem("Token");
  const [AdminUserDetails, setAdminUserDetails] = useState();
  const navigateToStaffProfileinSuperAdmin = `/clinic-admin/administrator-profile`;
  const { spinner, setSpinner } = UserAuth();

  const [addClinicStaffModal, setaddClinicStaffModal] = useState(false);

  const handleDeleteStaff = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .delete(`${API_BASE_URL}/api/v1/admin/${checkedDoctorValues}`, config)
      .then((res) => {
        message.success("Status deleted");
        setSpinner(false);
        handleGetAllAdminDetails();
      });
  };

  const handleGetAllAdminDetails = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (branch_id) {
      await axios
        .get(
          `${API_BASE_URL}/api/v1/adminStaff/clinic/${clinic_id}?branch_id=${branch_id}`,
          config
        )
        .then((res) => {
          setAdminUserDetails(res.data.data);
          setSpinner(false);
        });
    } else {
      await axios
        .get(`${API_BASE_URL}/api/v1/adminStaff/clinic/${clinic_id}`, config)
        .then((res) => {
          setAdminUserDetails(res.data.data);
          setSpinner(false);
        });
    }
  };

  useEffect(() => {
    handleGetAllAdminDetails();
  }, []);

  const [checkedDoctorValues, setcheckedDoctorValues] = useState([]);

  const [checkedAllList, setCheckedAllList] = useState([]);
  const checkAll =
    AdminUserDetails && AdminUserDetails.length === checkedAllList.length;

  const indeterminate =
    checkedAllList.length > 0 &&
    checkedAllList.length < AdminUserDetails.length;

  const onChange = (list) => {
    setCheckedAllList(list);
    setcheckedDoctorValues(list);
  };

  const onCheckAllChange = (e) => {
    setcheckedDoctorValues(
      e.target.checked ? AdminUserDetails.map((option) => option.id) : []
    );
    setCheckedAllList(
      e.target.checked ? AdminUserDetails.map((option) => option.id) : []
    );
  };

  return (
    <div className="admin-staff-main-div">
      <Helmet>
        <title>Clinic Staff</title>
      </Helmet>
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: "2.6045vw", margin: "14.588vw 0 0 0" }}
            spin
          />
        }
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <div
          style={{
            margin: "36px 9.51683748169839vw",
            padding: "45px 0 0 0",
            background: "#FFFFFF",

            borderRadius: 10,
          }}
        >
          <Row
            className="w-100 d-flex jc-between ai-center"
            style={{ padding: "0 30px" }}
          >
            <Col>
              <Row style={{ gap: 8 }}>
                <span className="w-100 appointments-management">
                  Staff List
                </span>
                <span className="appointments-management-subtitle">
                  View and manage the list of registered staff in the clinic.
                </span>
              </Row>
            </Col>
            <Col>
              <Button
                style={{
                  border: "1px solid #3498DB",
                  background: "#3498DB",
                  display: "flex",
                  width: 128 + 128,
                  padding: "12px 30px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 8,
                  height: 45,
                  borderRadius: 3,
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: 500,
                }}
                onClick={() => {
                  setaddClinicStaffModal(true);
                }}
              >
                Add Staff
              </Button>
            </Col>
          </Row>
          <Row
            className="w-100 d-flex jc-between ai-center"
            style={{ margin: "30px 0 24px 0", padding: "0 30px" }}
          >
            <Col>
              <Input
                style={{
                  width: "20.834vw",
                  height: "2.0834vw",
                  borderRadius: "0.15vw",
                  border: "1px solid #878787",
                }}
                className="d-flex ai-center"
                placeholder="Search By Staff Name or ID"
                prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
              />
            </Col>
            <Col
              style={{ gap: 10, margin: "0 0 8px 0" }}
              className="d-flex ai-center"
            ></Col>
          </Row>
          <Row style={{ padding: "0 30px" }}>
            <Row
              style={{
                background: "#F5F5F5",
                padding: "21px 30px",
              }}
              className="w-100 d-flex ai-center"
            >
              <Col span={1}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                  className="custom-checkbox"
                />
              </Col>
              <Col span={5}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  NAME
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Designation
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Specialization
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Contact information
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Next Appointment
                </span>
              </Col>
              <Col span={2}></Col>
            </Row>
          </Row>
          <div className="w-100">
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedAllList}
              onChange={onChange}
            >
              {AdminUserDetails &&
                AdminUserDetails.length > 0 &&
                AdminUserDetails.map((item) => {
                  return (
                    <StaffDetails
                      navigateTo={navigateToStaffProfileinSuperAdmin}
                      item={item}
                      checkedDoctorValues={checkedDoctorValues}
                      setcheckedDoctorValues={setcheckedDoctorValues}
                      name={item.name}
                      user_id={item.user_id}
                      email={item.email}
                      phone_number={item.phone_number}
                      address_line_one={item.address_line_one}
                      profile_picture_url={
                        item.profile_picture_url &&
                        item.profile_picture_url.split(".com/")[1]
                      }
                      handleGetAllAdminDetails={handleGetAllAdminDetails}
                      checkedList={checkedAllList}
                    />
                  );
                })}
            </Checkbox.Group>
            {checkedDoctorValues && checkedDoctorValues.length > 0 && (
              <Row
                style={{
                  background: "#FFF",
                  padding: "21px 30px",
                  boxShadow: "2px 2px 20px 0px rgba(0, 0, 0, 0.05)",
                }}
                className="d-flex ai-center"
              >
                <Col span={21}>
                  <Button type="danger" onClick={handleDeleteStaff}>
                    Delete
                  </Button>
                </Col>
                <Col span={2}>
                  <Button type="primary">Send reminder</Button>
                </Col>
                <Col
                  span={1}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <CloseOutlined
                    onClick={() => {
                      setcheckedDoctorValues(null);
                    }}
                  />
                </Col>
              </Row>
            )}
          </div>
        </div>
        <AddNewStaffModal
          addClinicStaffModal={addClinicStaffModal}
          setaddClinicStaffModal={setaddClinicStaffModal}
          handleGetAllPatientsForClinic={handleGetAllAdminDetails}
          form={form}
          handleGetAllAdminDetails={handleGetAllAdminDetails}
        />
      </Spin>
    </div>
  );
};

export default AdminStaff;
