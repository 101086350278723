import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./AdminPatients.css";
import axios from "axios";
import ClinicAdminPatientData from "./patient_data/ClinicAdminPatientData";
import {
  SearchOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import API_BASE_URL from "../../../../env";
import PatientJoinToClinic from "./patient_data/PatientJoinToClinic";
import { UserAuth } from "../../../../context_api/ContextApi";
import AddPatientModal from "./add_patient_modal/AddPatientModal";
import { Option } from "antd/lib/mentions";

const AdminPatients = () => {
  const [form] = Form.useForm();
  const { spinner, setSpinner } = UserAuth();
  const clinicId = localStorage.getItem("clinic_id");
  const Token = localStorage.getItem("Token");
  const [patientDataForClinic, setPatientDataForClinic] = useState();
  const [searchPatients, setSearchPatients] = useState("");
  const [searchedGlobalPatients, setSearchedGlobalPatients] = useState();
  const [checkedDoctorValues, setcheckedDoctorValues] = useState([]);

  const [addClinicPatienthModal, setaddClinicPatienthModal] = useState(false);

  const handleGetAllPatientsForClinic = () => {
    setSearchedGlobalPatients();
    setSpinner(true);
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/clinic/${clinicId}/patient`, config)
      .then((res) => {
        setPatientDataForClinic(res.data.data);
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const handleGetPatientsBySearch = (e) => {
    setSpinner(true);
    const value = e.target.value;
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (value) {
      axios
        .get(
          `${API_BASE_URL}/api/v1/patient/searchPatient?patientIdOrName=${value}&clinicId=${clinicId}`,
          config
        )
        .then((res) => {
          setPatientDataForClinic(res.data.data.clinic_patients);
          setSearchedGlobalPatients(res.data.data.global_patients);
          setSpinner(false);
        })
        .catch((err) => {
          handleGetAllPatientsForClinic();
          setSearchedGlobalPatients("");
          message.error(err.response.data.data.errors);
          setSpinner(false);
        });
    } else {
      handleGetAllPatientsForClinic();
    }
  };

  useEffect(() => {
    handleGetAllPatientsForClinic();
  }, []);

  const handleDeletePatient = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .delete(
        `${API_BASE_URL}/api/v1/patient?patient_id=${checkedDoctorValues}`,
        config
      )
      .then((res) => {
        message.success(res.data);
        handleGetAllPatientsForClinic();
        setcheckedDoctorValues();
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const [selectedTimeRange, setSelectedTimeRange] = useState(null);

  const handleTimeRangeChange = (value) => {
    setSelectedTimeRange(value);
  };

  const getFilteredData = () => {
    if (!selectedTimeRange) return patientDataForClinic;

    const now = new Date();
    let pastDate = new Date();

    switch (selectedTimeRange) {
      case "180":
        pastDate.setMonth(now.getMonth() - 6);
        break;
      case "90":
        pastDate.setMonth(now.getMonth() - 3);
        break;
      case "30":
        pastDate.setDate(now.getDate() - 30);
        break;
      default:
        break;
    }

    return patientDataForClinic.filter((item) => {
      if (item.last_visited_appointment_response) {
        const lastVisitedDate = new Date(
          item.last_visited_appointment_response.from_date
        );
        return lastVisitedDate < pastDate;
      }
      return false;
    });
  };

  const filteredData = getFilteredData();

  const [checkedAllList, setCheckedAllList] = useState([]);
  const checkAll =
    patientDataForClinic &&
    patientDataForClinic.length === checkedAllList.length;

  const indeterminate =
    checkedAllList.length > 0 &&
    checkedAllList.length < patientDataForClinic.length;

  const onChange = (list) => {
    setCheckedAllList(list);
    setcheckedDoctorValues(list);
  };

  const onCheckAllChange = (e) => {
    setcheckedDoctorValues(
      e.target.checked
        ? patientDataForClinic.map((option) => option.user_id)
        : []
    );
    setCheckedAllList(
      e.target.checked
        ? patientDataForClinic.map((option) => option.user_id)
        : []
    );
  };

  return (
    <div className="clinic-user-patients-main-div">
      <Helmet>
        <title>Clinic Patients</title>
      </Helmet>

      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: "2.6045vw", margin: "14.588vw 0 0 0" }}
            spin
          />
        }
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <div
          style={{
            margin: "36px 9.51683748169839vw",
            padding: "45px 0 0 0",
            background: "#FFFFFF",

            borderRadius: 10,
          }}
        >
          <Row
            className="w-100 d-flex jc-between ai-center"
            style={{ padding: "0 30px" }}
          >
            <Col>
              <Row style={{ gap: 8 }}>
                <span className="w-100 appointments-management">
                  Patients List
                </span>
                <span className="appointments-management-subtitle">
                  View and manage the list of registered patients in the clinic.
                </span>
              </Row>
            </Col>
            <Col>
              <Button
                style={{
                  border: "1px solid #3498DB",
                  background: "#3498DB",
                  display: "flex",
                  width: 128 + 128,
                  padding: "12px 30px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 8,
                  height: 45,
                  borderRadius: 3,
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: 500,
                }}
                onClick={() => {
                  setaddClinicPatienthModal(true);
                }}
              >
                Add Patient
              </Button>
            </Col>
          </Row>
          <Row
            className="w-100 d-flex jc-between ai-center"
            style={{ margin: "30px 0 24px 0", padding: "0 30px" }}
          >
            <Col>
              <Input
                style={{
                  width: "20.834vw",
                  height: "2.0834vw",
                  borderRadius: "0.15vw",
                  border: "1px solid #878787",
                }}
                className="d-flex ai-center"
                placeholder="Search By Patient Name or ID"
                prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
                value={searchPatients}
                onChange={(e) => {
                  handleGetPatientsBySearch(e);
                  setSearchPatients(e.target.value);
                }}
                suffix={
                  <CloseOutlined
                    style={{ height: "0.52vw", color: "#A5A5A5" }}
                    onClick={() => {
                      setSearchedGlobalPatients("");
                      setSearchPatients("");
                      handleGetAllPatientsForClinic();
                    }}
                  />
                }
              />
            </Col>
            <Col
              style={{ gap: 10, margin: "0 0 8px 0" }}
              className="d-flex ai-center"
            >
              <span className="filters-text">Filter visit by:</span>
              <Select
                allowClear
                placeholder="Select time"
                bordered={false}
                className="patient-filter-option"
                onChange={handleTimeRangeChange}
              >
                <Option value="180">Last 6 months</Option>
                <Option value="90">Last 3 months</Option>
                <Option value="30">Last 30 days</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ padding: "0 30px" }}>
            <Row
              style={{
                background: "#F5F5F5",
                padding: "21px 30px",
              }}
              className="w-100 d-flex ai-center"
            >
              <Col span={1}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                  className="custom-checkbox"
                ></Checkbox>
              </Col>
              <Col span={5}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  NAME
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Email
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Phone
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Last Visit
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Next Appointment
                </span>
              </Col>
              <Col span={2}>
                {checkedDoctorValues && checkedDoctorValues.length > 0 && <></>}
              </Col>
            </Row>
          </Row>
          <div
            className="w-100"
            style={{
              maxHeight:
                checkedDoctorValues && checkedDoctorValues.length > 0
                  ? "45vh"
                  : "52vh",
              overflow: "auto",
            }}
          >
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedAllList}
              onChange={onChange}
            >
              {patientDataForClinic &&
                patientDataForClinic.length > 0 &&
                filteredData.map((item) => {
                  return (
                    <ClinicAdminPatientData
                      item={item}
                      checkedDoctorValues={checkedDoctorValues}
                      setcheckedDoctorValues={setcheckedDoctorValues}
                      name={item.name}
                      user_id={item.user_id}
                      email={item.email}
                      phone_number={item.phone_number}
                      address_line_one={item.address_line_one}
                      profile_picture_url={
                        item.profile_picture_url &&
                        item.profile_picture_url.split(".com/")[1]
                      }
                      handleGetAllPatientsForClinic={
                        handleGetAllPatientsForClinic
                      }
                      setSpinner={setSpinner}
                    />
                  );
                })}
            </Checkbox.Group>
          </div>
          {checkedDoctorValues && checkedDoctorValues.length > 0 && (
            <Row
              style={{
                background: "#FFF",
                padding: "21px 30px",
                boxShadow: "2px 2px 20px 0px rgba(0, 0, 0, 0.05)",
              }}
              className="d-flex ai-center"
            >
              <Col span={21}>
                <Button type="danger" onClick={handleDeletePatient}>
                  Delete
                </Button>
              </Col>
              <Col span={2}>
                <Button type="primary">Send reminder</Button>
              </Col>
              <Col
                span={1}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <CloseOutlined
                  onClick={() => {
                    setcheckedDoctorValues(null);
                  }}
                />
              </Col>
            </Row>
          )}
          {searchedGlobalPatients && searchedGlobalPatients.length > 0 ? (
            searchedGlobalPatients.map((item) => {
              return (
                <PatientJoinToClinic
                  name={item.name}
                  item={item}
                  profile_picture_url={item.profile_picture_url}
                  user_id={item.user_id}
                  clinicId={clinicId}
                />
              );
            })
          ) : patientDataForClinic &&
            patientDataForClinic.length >= 0 &&
            !searchedGlobalPatients ? (
            <></>
          ) : (
            <div className="w-100">
              {searchPatients && (
                <span
                  style={{
                    fontSize: "1.14595vw",
                    fontWeight: 500,
                    width: "100%",
                  }}
                  className="d-flex jc-center"
                >
                  You Have No Patients with {searchPatients} outside the clinic
                </span>
              )}
            </div>
          )}
        </div>
        <AddPatientModal
          addClinicBranchModal={addClinicPatienthModal}
          setaddClinicBranchModal={setaddClinicPatienthModal}
          handleGetAllPatientsForClinic={handleGetAllPatientsForClinic}
          form={form}
        />
      </Spin>
    </div>
  );
};
export default AdminPatients;
