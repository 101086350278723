import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { ACCESS_KEY, REGION, S3_BUCKET, SECRET_ACCESS_KEY } from "../../../../../../string";

const DashboardXrayThumbnail = (props) => {
  const {
    openXrayModalAndSendingImageUrl,
    setXrayArrayId,
    arrayId,
    s3Url,
  } = props;

  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region : REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: s3Url,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [s3Url]);

  return (
    <img
      src={fileContent}
      alt=""
      style={{
        width: "81px",
        height: "56px",
        margin: window.innerWidth < 576 && "10px",
        cursor: "pointer",
        borderRadius: 5,
      }}
      onClick={() => {
        setXrayArrayId(arrayId);
        openXrayModalAndSendingImageUrl(fileContent);
      }}
    />
  );
};

export default DashboardXrayThumbnail;
