import {
  CloseOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Row,
  Input,
  Form,
  Col,
  Button,
  Select,
  Breadcrumb,
  message,
  Space,
  Spin,
  Radio,
} from "antd";
import { Option } from "antd/lib/mentions";
import axios from "axios";
import React, { useState } from "react";
import "./AddAdminPatient.css";
import bloodgroupicon from "../../../../../assets/Blood-drop.png";
import LocationIcon from "../../../../../assets/location-icon.png";
import { routes_clinic_admin_patients } from "../../../../../utils/clinic_admin_constants/ClinicAdminConstants";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  options,
  routes_super_admin_add_patient,
  routes_super_admin_patients,
} from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import API_BASE_URL from "../../../../../env";
import { useEffect } from "react";

const AddAdminPatient = () => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 70, margin: "150px 0 0 0" }} spin />
  );
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const key = location.pathname;
  const clinicId = localStorage.getItem("clinic_id");
  const role = localStorage.getItem("role");
  const Token = localStorage.getItem("Token");
  const { clinic_id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [clinicBranches, setClinicBranches] = useState();

  const handleAddPatientToClinic = (values) => {
    setRefresh(true);
    let payload;
    // if (key === routes_super_admin_add_patient) {
    //   payload = {
    //     name: values.name,
    //     email: values.email,
    //     phone_country_code: values.phone_country_code
    //       ? values.phone_country_code
    //       : "+91",
    //     phone_number: values.phone_number,
    //     address_line_one: values.patient_address,
    //     address_line_two: values.patient_apartment,
    //     city: values.patient_city,
    //     province: values.patient_state,
    //     country: values.patient_country,
    //     zip_code: values.patient_zipcode,
    //     insurance_id: values.patient_insurance_id,
    //     citizen_id: values.patient_citizen_id,
    //     blood_group: values.patient_blood_group,
    //     gender: values.gender,
    //   };
    // } else {
    payload = {
      name: values.name,
      username: values.patient_username,
      email: values.email,
      phone_country_code: values.phone_country_code
        ? values.phone_country_code
        : "+91",
      phone_number: values.phone_number,
      address_line_one: values.patient_address,
      address_line_two: values.patient_apartment,
      city: values.patient_city,
      province: values.patient_state,
      country: values.patient_country,
      zip_code: values.patient_zipcode,
      insurance_id: values.patient_insurance_id,
      citizen_id: values.patient_citizen_id,
      blood_group: values.patient_blood_group,
      gender: values.gender,
      // branch_id: values.branch_id,
      // };
    };
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (key === routes_super_admin_add_patient) {
      axios
        .post(`${API_BASE_URL}/api/v1/patient`, payload, config)
        .then((res) => {
          message.success("Patient added successfully");
          form.resetFields();
          setRefresh(false);
          navigate(routes_super_admin_patients);
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
          setRefresh(false);
        });
    } else {
      axios
        .post(
          `${API_BASE_URL}/api/v1/clinic/${
            clinic_id ? clinic_id : clinicId
          }/patient`,
          payload,
          config
        )
        .then((res) => {
          message.success("Patient added successfully");
          form.resetFields();
          setRefresh(false);
          navigate(
            clinic_id
              ? `/super-admin/clinic-profile/${clinic_id}`
              : routes_clinic_admin_patients
          );
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
          setRefresh(false);
        });
    }
  };

  const validatePhoneNumber = (_, value) => {
    const numericRegex = /^[0-9]+$/;
    if (!value || (numericRegex.test(value) && value.length === 10)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Please enter exactly 10 numeric characters for the phone number."
    );
  };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;

    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject("Please enter 6-digit zip code!");
  };

  const handleGetAllclinicBranches = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/clinicBranch/getAllBranchDetails/${clinicId}`,
        config
      )
      .then((res) => {
        setClinicBranches(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleGetAllclinicBranches();
  }, []);

  return (
    <div className="clinic-user-add-patient-main-div">
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "13px 23px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Add Patient
          </span>
          <Breadcrumb>
            <Breadcrumb.Item
              style={{
                background: "#2381C6",
                padding: "1px 10px",
                borderRadius: "3px",
              }}
            >
              <a
                href={
                  clinic_id
                    ? `/super-admin/clinic-profile/${clinic_id}`
                    : routes_clinic_admin_patients
                }
                style={{ color: "#FFF" }}
              >
                Patients
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{
                background: "#2381C6",
                padding: "1px 10px",
                borderRadius: "3px",
                color: "#FFF",
              }}
            >
              Add Patient
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Row>
      <Spin
        indicator={antIcon}
        size="large"
        spinning={refresh && true}
        delay={500}
      >
        <Row className="w-100" style={{ padding: "0 0 0 23px" }}>
          <Form
            form={form}
            initialValues={{
              remember: true,
            }}
            onFinish={handleAddPatientToClinic}
            autoComplete="off"
            className="w-100"
          >
            <Row className="d-flex">
              {/* col 1*/}
              <Col xs={24} sm={24} md={12} xl={6}>
                <div className="profile-pic-col-main-div">
                  <div
                    style={{
                      padding: "1.3333333333333335vw 3.3333333333333335vw",
                      borderBottom: "1px solid #D9D9D9",
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2.302vh",
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Name<span style={{ color: "red" }}>*</span>
                        </span>
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Patient name",
                            },
                            {
                              min: 3,
                              message:
                                "Name must be at least 3 characters long",
                            },
                            {
                              max: 100,
                              message:
                                "Name must be no more than 100 characters long",
                            },
                            {
                              pattern: /^[A-Za-z][A-Za-z\s]*$/,
                              message:
                                "First character should be alphabetical and Name must contain only alphabeticals",
                            },
                            { whitespace: true, message: "No white spaces" },
                          ]}
                        >
                          <Input
                            name="name"
                            label="name"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#525252",
                              height: "40px",
                              borderRadius: "3px",
                              border: "1px solid #E3E3E3",
                            }}
                            maxLength={101}
                            bordered={false}
                            onKeyPress={(e) => {
                              const charCode = e.which || e.keyCode;
                              const inputValue = e.target.value;
                              if (
                                !(
                                  (charCode >= 65 && charCode <= 90) ||
                                  (charCode >= 97 && charCode <= 122) ||
                                  (charCode === 32 && inputValue.trim() !== "")
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Gender<span style={{ color: "red" }}>*</span>
                        </span>
                        <Form.Item
                          name="gender"
                          rules={[
                            { required: true, message: "Please select Gender" },
                          ]}
                        >
                          <Select
                            name="gender"
                            bordered={false}
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#525252",
                              height: "40px",
                              borderRadius: "3px",
                              border: "1px solid #E3E3E3",
                            }}
                          >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Others">Others</Option>
                          </Select>
                        </Form.Item>
                      </Row>
                    </Row>
                  </div>
                  <div
                    style={{
                      padding:
                        "3.7151702786377707vh 3.3333333333333335vw 4vh 3.3854166666666665vw",
                      borderBottom: "1px solid #D9D9D9",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Email<span style={{ color: "red" }}>*</span>
                    </span>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Please enter email" },
                        {
                          type: "email",
                          message: "Please Enter valid email address",
                        },
                        {
                          max: 320,
                          message:
                            "Email address must be no more than 320 characters long",
                        },
                      ]}
                    >
                      <Input
                        name="email"
                        label="email"
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#7D7D7D",
                          height: "40px",
                          borderRadius: "3px",
                          border: "1px solid #E3E3E3",
                        }}
                        bordered={false}
                        maxLength={321}
                      />
                    </Form.Item>
                  </div>
                  <div
                    style={{
                      padding:
                        "3.7151702786377707vh 3.3333333333333335vw 4.615384615384615vh 3.3854166666666665vw",
                    }}
                  >
                    <Row>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Phone<span style={{ color: "red" }}>*</span>
                      </span>
                    </Row>
                    <Space.Compact className="w-100">
                      <Form.Item name="phone_country_code">
                        <Select
                          bordered={false}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: 40,
                            background: "#FFFFFF",
                            border: "1px solid #E3E3E3",
                            margin: "3px 0 0 0",
                          }}
                          defaultValue="+91"
                          options={options}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "100%" }}
                        name="phone_number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter phone number",
                          },
                          { validator: validatePhoneNumber },
                        ]}
                      >
                        <Input
                          bordered={false}
                          style={{
                            height: 40,
                            background: "#FFFFFF",
                            borderTop: "1px solid #E3E3E3",
                            borderRight: "1px solid #E3E3E3",
                            borderBottom: "1px solid #E3E3E3",
                            margin: "3px 0 0 0",
                          }}
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            if (!(charCode >= 48 && charCode <= 57)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Space.Compact>
                  </div>
                </div>
                <div
                  className="profile-pic-col-main-div"
                  style={{
                    margin: "0.2641025641025643vh 0 0 0",
                    background: "#FFF",
                    padding: "16px 64px",
                  }}
                >
                  <Row
                    style={{
                      margin: "2vh 0 3.2vh 0",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Row className="w-100">
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          color: "#A5A4A4",
                        }}
                      >
                        Username<span style={{ color: "red" }}>*</span>
                      </span>
                    </Row>
                    <Row className="w-100">
                      <Form.Item
                        className="w-100"
                        name="patient_username"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Username!",
                          },
                          {
                            min: 4,
                            message: "Please enter minimum 4 characters",
                          },
                          { whitespace: true, message: "No white spaces" },
                          {
                            pattern: /^[a-zA-Z0-9._]+$/,
                            message:
                              "Username can only contain letters, numbers, fullstops, and underscores.",
                          },
                          {
                            max: 100,
                            message:
                              "Username should not be more than 100 characters long",
                          },
                        ]}
                      >
                        <Input
                          name="patient_username"
                          label="patient_username"
                          className="insurance-id-input"
                          maxLength={101}
                          onKeyPress={handleUserNameKeyPress}
                        />
                      </Form.Item>
                    </Row>
                  </Row>
                </div>

                <div
                  className="profile-pic-col-main-div"
                  style={{
                    margin: "0.2641025641025643vh 0 0 0",
                    background: "#FFF",
                    padding: "16px 64px",
                  }}
                >
                  <Row
                    style={{
                      margin: "2vh 0 3.2vh 0",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Row className="w-100">
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          color: "#A5A4A4",
                        }}
                      >
                        Blood Group
                      </span>
                    </Row>
                    <Row className="w-100">
                      <Form.Item className="w-100" name="patient_blood_group">
                        <Select
                          name="patient_blood_group"
                          label="patient_blood_group"
                          bordered={false}
                          style={{
                            background: "#FFFFFF",
                            border: "1px solid #E3E3E3",
                            borderRadius: 3,
                            height: "40px",
                            color: "#7D7D7D",
                          }}
                        >
                          <Option value="A+">A+</Option>
                          <Option value="A-">A-</Option>
                          <Option value="B+">B+</Option>
                          <Option value="B-">B-</Option>
                          <Option value="O+">O+</Option>
                          <Option value="O-">O-</Option>
                          <Option value="AAB+">AB+</Option>
                          <Option value="AAB-">AB-</Option>
                        </Select>
                      </Form.Item>
                    </Row>
                  </Row>
                </div>
              </Col>
              {/* address col */}
              <Col xs={24} sm={24} md={12} xl={6}>
                <div className="profile-pic-col-main-div">
                  <Row style={{ padding: "22px" }}>
                    <Col span={2}>
                      <img
                        style={{ height: "26px" }}
                        src={LocationIcon}
                        alt=""
                      />
                    </Col>
                    <Col>
                      <span
                        style={{
                          fontWeight: "700",
                          fontSize: "18px",
                          color: "#525252",
                        }}
                      >
                        Address
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5vh",
                      padding:
                        "1.3333333333333335vw 3.3333333333333335vw 3.3333333333333335vw 3.3333333333333335vw",
                    }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Address
                      </span>
                      <Form.Item name="patient_address">
                        <Input
                          name="patient_address"
                          label="patient_address"
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#7D7D7D",
                            height: "40px",
                            border: "1px solid #E3E3E3",
                          }}
                          bordered={false}
                        />
                      </Form.Item>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        City
                      </span>
                      <Form.Item
                        name="patient_city"
                        rules={[
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and City must contain only alphabeticals",
                          },
                        ]}
                      >
                        <Input
                          name="patient_city"
                          label="patient_city"
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            height: "40px",
                            color: "#7D7D7D",
                            border: "1px solid #E3E3E3",
                          }}
                          bordered={false}
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            const inputValue = e.target.value;
                            if (
                              !(
                                (charCode >= 65 && charCode <= 90) ||
                                (charCode >= 97 && charCode <= 122) ||
                                (charCode === 32 && inputValue.trim() !== "")
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Apartment / Suite ETC.,
                      </span>
                      <Form.Item name="patient_apartment">
                        <Input
                          name="patient_apartment"
                          label="patient_apartment"
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#7D7D7D",
                            height: "40px",
                            border: "1px solid #E3E3E3",
                          }}
                          bordered={false}
                        />
                      </Form.Item>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Country
                      </span>
                      <Form.Item
                        name="patient_country"
                        rules={[
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and country must contain only alphabeticals",
                          },
                        ]}
                      >
                        <Input
                          name="patient_country"
                          label="patient_country"
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            height: "40px",
                            color: "#7D7D7D",
                            border: "1px solid #E3E3E3",
                          }}
                          bordered={false}
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            const inputValue = e.target.value;
                            if (
                              !(
                                (charCode >= 65 && charCode <= 90) ||
                                (charCode >= 97 && charCode <= 122) ||
                                (charCode === 32 && inputValue.trim() !== "")
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        State / Province
                      </span>
                      <Form.Item
                        name="patient_state"
                        rules={[
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and State/Province must contain only alphabeticals",
                          },
                        ]}
                      >
                        <Input
                          name="patient_state"
                          label="patient_state"
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#7D7D7D",
                            height: "40px",
                            border: "1px solid #E3E3E3",
                          }}
                          bordered={false}
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            const inputValue = e.target.value;
                            if (
                              !(
                                (charCode >= 65 && charCode <= 90) ||
                                (charCode >= 97 && charCode <= 122) ||
                                (charCode === 32 && inputValue.trim() !== "")
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Zip Code
                      </span>
                      <Form.Item
                        name="patient_zipcode"
                        rules={[{ validator: validateZipCode }]}
                      >
                        <Input
                          name="patient_zipcode"
                          label="patient_zipcode"
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            height: "40px",
                            color: "#7D7D7D",
                            border: "1px solid #E3E3E3",
                          }}
                          bordered={false}
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            if (!(charCode >= 48 && charCode <= 57)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Row>
                </div>
              </Col>
              {/* Blood group, Citizen ID, insurance id */}
              {/* <Col xs={24} sm={24} md={12} xl={6}>
                <div>
                  <div
                    className="profile-pic-col-main-div"
                    style={{
                      margin: "0 0 2.5641025641025643vh 0",
                      padding: "1.74vh 24px",
                    }}
                  >
                    <Row className="d-flex ai-center">
                      <Col>
                        <img
                          src={bloodgroupicon}
                          alt=""
                          style={{ width: "0.9895833333333334vw" }}
                        />
                      </Col>
                      <Col>
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: 18,
                            color: "#525252",
                            margin: "0 0 0 13px",
                          }}
                        >
                          Blood Group
                        </span>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        margin: "5vh 0 6.2vh 0",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Row className="w-100">
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: 12,
                            color: "#A5A4A4",
                          }}
                        >
                          Blood Group
                        </span>
                      </Row>
                      <Row className="w-100">
                        <Form.Item className="w-100" name="patient_blood_group">
                          <Select
                            name="patient_blood_group"
                            label="patient_blood_group"
                            bordered={false}
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #E3E3E3",
                              borderRadius: 3,
                              height: "40px",
                              color: "#7D7D7D",
                            }}
                          >
                            <Option value="A+">A+</Option>
                            <Option value="A-">A-</Option>
                            <Option value="B+">B+</Option>
                            <Option value="B-">B-</Option>
                            <Option value="O+">O+</Option>
                            <Option value="O-">O-</Option>
                            <Option value="AAB+">AB+</Option>
                            <Option value="AAB-">AB-</Option>
                          </Select>
                        </Form.Item>
                      </Row>
                    </Row>
                  </div>
                </div>
              </Col> */}

              {/* Branches col */}
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "19px",
                margin: "40px 0 50px 0",
              }}
            >
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                  border: "1px solid #F7927E",
                  borderRadius: "30px",
                  color: "#F7927E",
                  background: "#FFF6F2",
                }}
              >
                <a
                  href={
                    clinic_id
                      ? `/super-admin/clinic-profile/${clinic_id}`
                      : routes_clinic_admin_patients
                  }
                >
                  Cancel
                </a>
              </Button>
              <Form.Item>
                <Button
                  style={{
                    height: "30px",
                    width: "64px",
                    border: "1px solid #29C3C1",
                    borderRadius: "30px",
                    color: "#29C3C1",
                    background: "#ECFEFF",
                  }}
                  htmlType="submit"
                >
                  Add
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Row>
      </Spin>
    </div>
  );
};

export default AddAdminPatient;

// {role === "CLINIC_MASTER_ADMIN" && (
//   <Col xs={24} sm={24} md={12} xl={12}>
//     <div
//       style={{
//         width: "48.2vw",
//         background: "#fff",
//         borderRadius: "5px",
//       }}
//     >
//       <Row
//         style={{
//           gap: "1vh",
//           padding: "43px 23px",
//         }}
//       >
//         <Row
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             width: "100%",
//           }}
//         >
//           <Col
//             style={{
//               display: "flex",
//               flexDirection: "column",
//             }}
//           >
//             <span style={{ fontSize: "20px", fontWeight: "700" }}>
//               Assign Patients to Branch
//             </span>
//             <span
//               style={{
//                 fontSize: "14px",
//                 fontWeight: "400",
//                 color: "rgba(122, 122, 122, 1)",
//               }}
//             >
//               Branches(
//               {clinic_branches ? clinic_branches.length : "0"})
//             </span>
//           </Col>
//           <Col>
//             <Input
//               style={{
//                 width: 300,
//                 height: 40,
//                 borderRadius: 3,
//               }}
//               className="d-flex ai-center"
//               placeholder="Search by Clinic Name or ID"
//               prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
//               // value={searchPatients}
//               // onChange={(e) => {
//               //   handleGetPatientsBySearch(e);
//               //   setSearchPatients(e.target.value);
//               // }}
//               suffix={
//                 <CloseOutlined
//                   style={{ height: "10px", color: "#A5A5A5" }}
//                   // onClick={() => {
//                   //   setSearchedGlobalPatients("");
//                   //   setSearchPatients("");
//                   //   handleGetAllPatientsForClinic();
//                   // }}
//                 />
//               }
//             />
//           </Col>
//         </Row>
//         <Row style={{ width: "100%", maxHeight: "60vh" }}>
//           {clinic_branches ? (
//             clinic_branches.map((item_branches) => {
//               return (
//                 <Col span={12}>
//                   <Row>
//                     <Col span={24}>
//                       <Form.Item name="branch_id">
//                         <Radio.Group
//                           style={{ margin: "10px 0 0 0" }}
//                         >
//                           <Space direction="vertical">
//                             <Radio
//                               value={item_branches.branch_id}
//                             >
//                               <div>
//                                 <span
//                                   style={{
//                                     fontSize: "18px",
//                                     fontWeight: "700",
//                                   }}
//                                 >
//                                   {item_branches.branch_name}
//                                 </span>
//                                 <span
//                                   style={{
//                                     fontSize: "18px",
//                                     fontWeight: "400",
//                                     color: "#3498DB",
//                                   }}
//                                 >
//                                   {item_branches &&
//                                     "#" + item_branches.branch_id}
//                                 </span>
//                               </div>
//                               <div style={{ width: "100%" }}>
//                                 <span
//                                   style={{
//                                     fontSize: "14px",
//                                     fontWeight: "400",
//                                     color: "#626262",
//                                   }}
//                                 >
//                                   {item_branches &&
//                                     item_branches.address_line_one +
//                                       ", " +
//                                       item_branches.address_line_two +
//                                       ", " +
//                                       item_branches.city +
//                                       ", " +
//                                       item_branches.province +
//                                       ", " +
//                                       item_branches.zip_code}
//                                 </span>
//                               </div>
//                               <div style={{ width: "100%" }}>
//                                 <span
//                                   style={{
//                                     fontSize: "14px",
//                                     fontWeight: "400",
//                                     color: "#626262",
//                                   }}
//                                 >
//                                   {item_branches.phone_country_code +
//                                     " " +
//                                     item_branches.phone_number}
//                                 </span>
//                               </div>
//                               <div style={{ width: "100%" }}>
//                                 <span
//                                   style={{
//                                     fontSize: "14px",
//                                     fontWeight: "400",
//                                     color: "#3498DB",
//                                   }}
//                                 >
//                                   {item_branches.email}
//                                 </span>
//                               </div>
//                             </Radio>
//                           </Space>
//                         </Radio.Group>
//                       </Form.Item>
//                     </Col>
//                   </Row>
//                 </Col>
//               );
//             })
//           ) : (
//             <Row
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: "100%",
//                 height: "10vh",
//               }}
//             >
//               <span
//                 style={{ fontSize: "24px", fontWeight: "500" }}
//               >
//                 You don't have clinic branches
//               </span>
//             </Row>
//           )}
//         </Row>

//         {/* <Row style={{ width: "100%", maxHeight: "60vh" }}>
//         <Form.Item name="branch_id">
//           <Radio.Group style={{ margin: "10px 0 0 0" }}>
//             <Space direction="vertical" style={{ width: "100%" }}>
//               <Row>
//                 {clinic_branches &&
//                   clinic_branches.map((item_branches) => {
//                     return (
//                       <Col span={12}>
//                         <Radio value={item_branches.branch_id}>
//                           <div>
//                             <span
//                               style={{
//                                 fontSize: "18px",
//                                 fontWeight: "700",
//                               }}
//                             >
//                               {item_branches.branch_name}
//                             </span>
//                             <span
//                               style={{
//                                 fontSize: "18px",
//                                 fontWeight: "400",
//                                 color: "#3498DB",
//                               }}
//                             >
//                               {item_branches &&
//                                 "#" + item_branches.branch_id}
//                             </span>
//                           </div>
//                           <div style={{ width: "100%" }}>
//                             <span
//                               style={{
//                                 fontSize: "14px",
//                                 fontWeight: "400",
//                                 color: "#626262",
//                               }}
//                             >
//                               {item_branches &&
//                                 item_branches.address_line_one +
//                                   ", " +
//                                   item_branches.address_line_two +
//                                   ", " +
//                                   item_branches.city +
//                                   ", " +
//                                   item_branches.province +
//                                   ", " +
//                                   item_branches.zip_code}
//                             </span>
//                           </div>
//                           <div style={{ width: "100%" }}>
//                             <span
//                               style={{
//                                 fontSize: "14px",
//                                 fontWeight: "400",
//                                 color: "#626262",
//                               }}
//                             >
//                               {item_branches.phone_country_code +
//                                 " " +
//                                 item_branches.phone_number}
//                             </span>
//                           </div>
//                           <div style={{ width: "100%" }}>
//                             <span
//                               style={{
//                                 fontSize: "14px",
//                                 fontWeight: "400",
//                                 color: "#3498DB",
//                               }}
//                             >
//                               {item_branches.email}
//                             </span>
//                           </div>
//                         </Radio>
//                       </Col>
//                     );
//                   })}
//               </Row>
//             </Space>
//           </Radio.Group>
//         </Form.Item>
//       </Row> */}
//       </Row>
//     </div>
//   </Col>
// )}
