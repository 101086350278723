import { Button, Col, Collapse, Modal, Row, message } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import API_BASE_URL from "../../../../../env";

const Notification = (props) => {
  const Token = localStorage.getItem("Token");
  const patient_id = localStorage.getItem("patient_id");
  const {
    handleMarkAsReadPatientNotification,
    id,
    created_at,
    title,
    notification_message,
    message_Type,
    clinic_ID,
    clinic_Name,
    handleGetAllPatientNotifications,
  } = props;
  const [openClinicAccept, setOpenClinicAccept] = useState(false);
  const [openRequestDecline, setOpenRequestDecline] = useState(false);
  // const [timeAgo, setTimeAgo] = useState("");
  // const createdAt = moment(created_at);

  // useEffect(() => {
  //   const timeAgo = moment(createdAt).fromNow();
  //   setTimeAgo(timeAgo);
  // }, []);

  const handleAcceptClinicJoinRequest = async () => {
    const payload = {};
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .post(
        `${API_BASE_URL}/api/v1/admin/joinClinic?clinicId=${clinic_ID}&patientId=${patient_id}`,
        payload,
        config
      )
      .then((res) => {
        message.success("You have joined in this clinic now");
      })
      .catch((err) => {
        message.error("You are already in this clinic");
      });
  };

  const handleDeleteRequestNotification = async (Notification_Id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .delete(
        `${API_BASE_URL}/api/v1/notification/deleteNotification/${Notification_Id}`,
        config
      )
      .then((res) => {
        message.success("You are Declined Joined Request for this clinic");
        handleGetAllPatientNotifications();
      })
      .catch((err) => {
        message.error("Failed to Decline the Join Request");
      });
  };

  return (
    <Collapse
      ghost
      style={{
        width: "100%",
        minHeight: "79px",
        background: "#FBFBFB",
        borderRadius: 5,
        margin: "0 0 4px 0",
      }}
      expandIconPosition="right"
      className="notification-collapse"
      collapsible="icon"
      expandIcon={({ isActive }) => (
        <Button
          className="read-notif-btn"
          onClick={() => handleMarkAsReadPatientNotification(id)}
        >
          {isActive ? "Close" : "Read"}
        </Button>
      )}
    >
      <CollapsePanel
        header={
          <Row
            style={{
              display: "flex",
              align: "center",
              width: "100%",
            }}
          >
            <Col
              xs={24}
              sm={4}
              md={3}
              lg={3}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <span
                className="notification-created-at"
                style={{ width: "100%" }}
              >
                {/* {timeAgo && timeAgo} */}
                {moment(created_at).format("YYYY-MM-DD hh:mm a")}
              </span>
            </Col>
            <Col
              xs={15}
              sm={4}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <span className="notification-title">{clinic_Name}</span>
            </Col>
            <Col
              sm={15}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <span className="notification-title">{title}</span>
            </Col>
          </Row>
        }
        key="1"
      >
        <Row className="notification-msg-row">
          {message_Type && message_Type === "join_clinic" ? (
            <>
              <Col xs={24} sm={4} md={3} lg={2}></Col>
              <Col xs={24} sm={16}>
                <span className="notification-msg">{notification_message}</span>
              </Col>
              <Col span={2}>
                <Button
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "16px",
                    background: "#E0F2FF",
                    border: "1px solid #009df7",
                    borderRadius: "30px",
                    color: "#009DF7",
                  }}
                  onClick={() => setOpenClinicAccept(true)}
                >
                  Accept
                </Button>
                <Modal
                  open={openClinicAccept}
                  centered
                  footer={false}
                  closable={false}
                  mask={false}
                >
                  <div>
                    <Row className="d-flex jc-between ai-center">
                      <Col>
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: 22,
                            color: "#525252",
                          }}
                        >
                          Accept Request
                        </span>
                      </Col>
                      <Col>
                        <CloseOutlined
                          style={{
                            color: "red",
                            fontSize: "16px",
                          }}
                          onClick={() => {
                            setOpenClinicAccept(false);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row
                      className="d-flex jc-center"
                      style={{ margin: "15px 0 0 0" }}
                    >
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 20,
                          color: "#525252",
                        }}
                      >
                        Are you sure you want to Accept the Request
                      </span>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "25px",
                      }}
                    >
                      <Button
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "16px",
                          background: "#E0F2FF",
                          border: "1px solid #009df7",
                          borderRadius: "30px",
                          color: "#009DF7",
                        }}
                        onClick={() => {
                          setOpenClinicAccept(false);
                          handleAcceptClinicJoinRequest();
                        }}
                      >
                        Accept
                      </Button>
                    </Row>
                  </div>
                </Modal>
              </Col>
              <Col span={2} style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "16px",
                    background: "#FFEDED",
                    border: "1px solid #FF0000",
                    borderRadius: "30px",
                    color: "#FF0000",
                  }}
                  onClick={() => setOpenRequestDecline(true)}
                >
                  Decline
                </Button>
                <Modal
                  open={openRequestDecline}
                  centered
                  footer={false}
                  closable={false}
                  mask={false}
                >
                  <div>
                    <Row className="d-flex jc-between ai-center">
                      <Col>
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: 22,
                            color: "#525252",
                          }}
                        >
                          Decline Request
                        </span>
                      </Col>
                      <Col>
                        <CloseOutlined
                          style={{
                            color: "red",
                            fontSize: "16px",
                          }}
                          onClick={() => {
                            setOpenRequestDecline(false);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row
                      className="d-flex jc-center"
                      style={{ margin: "15px 0 0 0" }}
                    >
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 20,
                          color: "#525252",
                        }}
                      >
                        Are you sure you want to Decline the Request
                      </span>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "25px",
                      }}
                    >
                      <Button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "#D35850",
                          border: "1px solid #D35850",
                          borderRadius: "16px",
                        }}
                        onClick={() => {
                          setOpenRequestDecline(false);
                          handleDeleteRequestNotification(id);
                        }}
                      >
                        Decline
                      </Button>
                    </Row>
                  </div>
                </Modal>
              </Col>
            </>
          ) : (
            <>
              <Col xs={24} sm={4} md={3} lg={2}></Col>
              <Col xs={24} sm={20}>
                <span className="notification-msg">{notification_message}</span>
              </Col>
            </>
          )}
        </Row>
      </CollapsePanel>
    </Collapse>
  );
};

export default Notification;
