import React from "react";
import StaffProfilePic from "../../../../../assets/default-profile-pic.png";

const StaffProfilePicture = ({ pro }) => {
  return (
    <img
      style={{ height: "48px", width: 48, borderRadius: 18 }}
      src={pro ? pro : StaffProfilePic}
      alt=""
    />
  );
};

export default StaffProfilePicture;
