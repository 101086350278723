import { Button, Col, Dropdown, message, Modal, Row, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useState } from "react";
import ivoryailogo from "../../../assets/ivory-ai-logo.png";
import ProfilePic from "../../../assets/default-profile-pic.png";
import { routes_patientlogin } from "../../../utils/patient_navbar_constants/PatientNavbarConstants";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import logout from "../../../assets/logout.png";
import {
  routes_clinic_admin_dashboard,
  routes_clinic_admin_ml_analysis,
  routes_clinic_my_account,
  routes_clinic_admin_patients,
  routes_clinic_admin_staff,
  routes_clinic_appointments,
  tabs_admin_appointments,
  tabs_admin_dashboard,
  tabs_admin_mlanalysis,
  tabs_admin_patients,
  tabs_admin_staff,
} from "../../../utils/clinic_admin_constants/ClinicAdminConstants";
import { UserAuth } from "../../../context_api/ContextApi";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../string";
import API_BASE_URL from "../../../env";
import Cookies from "js-cookie";
import { CloseOutlined } from "@ant-design/icons";

const ClinicAdminNavbar = () => {
  const {
    adminTabActiveKey,
    setAdminTabActiveKey,
    patientUserDetails,
    setPatientUserDetails,
    sets3UrlForClinic,
    s3UrlForClinic,
  } = UserAuth();

  const [openConfirmSignoutModal, setopenConfirmSignoutModal] = useState(false);

  const clinic_admin_id = localStorage.getItem("clinic_id");
  const clinic_user_id = localStorage.getItem("user_id");
  const branch_id = localStorage.getItem("main_branch_id")
    ? localStorage.getItem("main_branch_id")
    : localStorage.getItem("branch_id");
  const Token = localStorage.getItem("Token");
  const Role = localStorage.getItem("role");
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToClinicAdminPersonalDetails = `/clinic-admin/my-account`;

  const patientSignOut = async () => {
    const decodedToken = JSON.parse(atob(Token.split(".")[1]));
    if (decodedToken.exp >= parseInt(Date.now() / 1000)) {
      const payload = {
        token: Token,
      };
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios
        .post(`${API_BASE_URL}/api/v1/user/logout`, payload, config)
        .then((res) => {
          localStorage.clear();
          Cookies.remove("rememberMe");
          navigate(routes_patientlogin);
        });
    } else if (decodedToken.exp <= parseInt(Date.now() / 1000)) {
      localStorage.clear();
      Cookies.remove("rememberMe");
      navigate(routes_patientlogin);
    }
  };

  useEffect(() => {
    const decodedToken = JSON.parse(atob(Token.split(".")[1]));
    if (decodedToken.exp <= parseInt(Date.now() / 1000)) {
      localStorage.clear();
      Cookies.remove("rememberMe");
      navigate(routes_patientlogin);
    }
  }, []);

  const handleGetUserDetails = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (branch_id) {
      await axios
        .get(
          `${API_BASE_URL}/api/v1/clinicBranch/fetchBranchDetails/${branch_id}`,
          config
        )
        .then((res) => {
          setPatientUserDetails(res.data.data);
          sets3UrlForClinic(
            res.data.data.profile_picture_url &&
              res.data.data.profile_picture_url.split(".com/")[1]
          );
        });
    } else if (!branch_id) {
      await axios
        .get(`${API_BASE_URL}/api/v1/clinic/${clinic_admin_id}`, config)
        .then((res) => {
          setPatientUserDetails(res.data.data);
          sets3UrlForClinic(
            res.data.data.profile_picture_url &&
              res.data.data.profile_picture_url.split(".com/")[1]
          );
        });
    }
  };

  const tabsChange = (activeKey) => {
    handleGetUserDetails();
    if (activeKey === tabs_admin_dashboard) {
      navigate(routes_clinic_admin_dashboard);
      setAdminTabActiveKey(tabs_admin_dashboard);
    } else if (activeKey === tabs_admin_mlanalysis) {
      navigate(routes_clinic_admin_ml_analysis);
      setAdminTabActiveKey(tabs_admin_mlanalysis);
    } else if (activeKey === tabs_admin_patients) {
      navigate(routes_clinic_admin_patients);
      setAdminTabActiveKey(tabs_admin_patients);
    } else if (activeKey === tabs_admin_staff) {
      navigate(routes_clinic_admin_staff);
      setAdminTabActiveKey(tabs_admin_staff);
    } else if (activeKey === tabs_admin_appointments) {
      navigate(routes_clinic_appointments);
      setAdminTabActiveKey(tabs_admin_appointments);
    }
  };

  const handleDefaultRoute = () => {
    const key = location.pathname;
    if (key === routes_clinic_admin_dashboard) {
      setAdminTabActiveKey(tabs_admin_dashboard);
    } else if (key === routes_clinic_admin_patients) {
      setAdminTabActiveKey(tabs_admin_patients);
    } else if (key === routes_clinic_admin_staff) {
      setAdminTabActiveKey(tabs_admin_staff);
    } else if (key === routes_clinic_appointments) {
      setAdminTabActiveKey(tabs_admin_appointments);
    } else if (key === "/clinic-admin/view-report") {
      setAdminTabActiveKey(tabs_admin_patients);
    } else {
      setAdminTabActiveKey("");
    }
  };

  useEffect(() => {
    handleGetUserDetails();
    handleDefaultRoute();
  }, []);

  const profileDropdown = (
    <div className="patient-delete-dropdown">
      {Role === "CLINIC_ADMIN" ? (
        <Row
          className="full-profile-row"
          onClick={() => {
            navigate(
              `${navigateToClinicAdminPersonalDetails}/${clinic_user_id}`
            );
            setAdminTabActiveKey("");
          }}
        >
          My Profile
        </Row>
      ) : (
        <></>
      )}
      <Row
        className="delete-patient-row"
        onClick={() => {
          navigate(routes_clinic_my_account);
          setAdminTabActiveKey("");
        }}
      >
        Clinic Profile
      </Row>
    </div>
  );

  const navbartabs = [
    { navigate: routes_clinic_admin_dashboard, tab: tabs_admin_dashboard },
    { navigate: routes_clinic_admin_patients, tab: tabs_admin_patients },
    { navigate: routes_clinic_admin_staff, tab: tabs_admin_staff },
    { navigate: routes_clinic_appointments, tab: tabs_admin_appointments },
  ];

  const [fileContent, setFileContent] = useState("");
  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: s3UrlForClinic,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [s3UrlForClinic]);

  return (
    <div
      style={{
        height: "7.45vh",
        background: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent:
          window.innerWidth > 1050 ? "space-between" : "space-evenly",
        width: "100%",
      }}
      className="navbar-main-div"
    >
      <Col>
        <img
          src={ivoryailogo}
          alt=""
          style={{
            width: window.innerWidth > 950 ? "6.5vw" : "86px",
            margin: "0 0 0 1.25vw",
          }}
        />
      </Col>
      <Col xs={12} sm={16} md={14}>
        <Tabs
          onChange={tabsChange}
          activeKey={adminTabActiveKey}
          style={{ margin: "0 0 0 2.8645vw" }}
        >
          {navbartabs.map((item) => {
            return <TabPane tab={item.tab} key={item.tab} />;
          })}
        </Tabs>
      </Col>
      <Col
        xs={6}
        sm={6}
        md={8}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Row
          className="d-flex ai-center jc-evenly"
          style={{
            height: "100%",
            gap: "1.0417vw",
            margin: "0 20px",
            minWidth: "7vw",
          }}
        >
          <Col>
            <span className="user-name">
              {patientUserDetails &&
                patientUserDetails.referred_by &&
                "Referred By : " + patientUserDetails.referred_by}
            </span>
          </Col>
          <span className="user-name">
            {patientUserDetails && patientUserDetails.branch_name}
          </span>
          <Dropdown overlay={profileDropdown} placement="bottomRight">
            <img
              src={fileContent ? fileContent : ProfilePic}
              alt=""
              style={{
                width: window.innerWidth > 1050 ? "2vw" : "36px",
                height:
                  window.innerWidth > 1050 ? "3.7948717948717947vh" : "36px",
                borderRadius: "13px",
                cursor: "pointer",
              }}
            />
          </Dropdown>
          <img
            onClick={() => setopenConfirmSignoutModal(true)}
            src={logout}
            alt=""
            style={{
              width: window.innerWidth > 1050 ? "1.2vw" : "0.938vw",
              height: window.innerWidth < 1050 && "1.06vw",
              cursor: "pointer",
            }}
          />
        </Row>
      </Col>
      <Modal
        width={568}
        centered
        open={openConfirmSignoutModal}
        footer={false}
        closable={false}
      >
        <Row className="d-flex ai-center jc-between">
          <span
            style={{
              fontWeight: 600,
              fontSize: "1.0417vw",
              color: "#323232",
            }}
          >
            Sign-out Confirmation
          </span>
          <CloseOutlined
            style={{
              fontSize: "1.0417vw",
              color: "red",
            }}
            onClick={() => {
              setopenConfirmSignoutModal(false);
            }}
          />
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            padding: "30px 0",
            gap: 15,
          }}
        >
          <div
            className="d-flex jc-center ai-center"
            style={{
              margin: "0 102px",
              width: 404,
              height: 57,
              background: "#F9F9F9",
              borderRadius: 5,
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 18,
                color: "#323232",
              }}
            >
              Are you sure you want to sign out ?
            </span>
          </div>
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            gap: 15,
          }}
        >
          <Button
            onClick={patientSignOut}
            style={{
              background: "#ECFEFF",
              border: "1px solid #29C3C1",
              borderRadius: 30,
              color: "#29C3C1",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setopenConfirmSignoutModal(false);
            }}
            style={{
              background: "#FFF5F3",
              border: "1px solid #FF0000",
              borderRadius: 30,
              color: "#EA4335",
            }}
          >
            No
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

export default ClinicAdminNavbar;
