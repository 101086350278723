import { Col, Form, Spin, Row, Button } from "antd";
import React, { useEffect, useState, useRef } from "react";
import "./MyAccount.css";
import axios from "axios";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import API_BASE_URL from "../../../../env";
import { Helmet } from "react-helmet";
import leftArrow from "../../../../assets/left-arrow.png";
import PatientProfile from "../../../clinic_admin/modules/admin_patients/admin_patient_profile/patient_profile/PatientProfile";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import invoiceLogo from "../../../../assets/invoice-logo.png";

const MyAccount = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: "2.6045vw" }} spin />;
  const [form] = Form.useForm();
  const patientid = localStorage.getItem("patient_id");
  const Token = localStorage.getItem("Token");
  const [patientUserDetails, setPatientUserDetails] = useState(null);
  const [patientPaymentHistory, setPatientPaymentHistory] = useState();
  const [spinner, setSpinner] = useState(false);
  const [treatmentHistory, setTreatmentHistoryDetails] = useState();
  const [patientTabsData, setPatientTabsData] = useState(
    "Personal Information"
  );
  const [isInvoiceClicked, setInvoiceClicked] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const invoiceRef = useRef(null);

  const getPatientDetails = async () => {
    setSpinner(true);
    setPatientUserDetails(null);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/patient/${patientid}`, config)
      .then((res) => {
        setPatientUserDetails(res.data.data);
        setSpinner(false);
      });
  };

  const getPatientPaymentHistory = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/payment/paymentHistory/${patientid}`, config)
      .then((res) => {
        setPatientPaymentHistory(res.data);
        setSpinner(false);
      });
  };

  useEffect(() => {
    getPatientDetails();
    getPatientPaymentHistory();
    getTreatmentHistory();
  }, []);

  useEffect(() => {
    hadnleInvoiceClick();
  }, [isInvoiceClicked]);

  const hadnleInvoiceClick = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    await axios
      .get(
        `${API_BASE_URL}/api/v1/payment/payment_invoice/${patientid}`,
        config
      )
      .then((res) => {
        const invoice_data = res.data.filter((item) => {
          if (item?.order_id === invoiceNumber) {
            return item;
          }
        });
        setInvoiceDetails(invoice_data);
      });
  };

  const getTreatmentHistory = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/treatments/treatment_payment_history/patient?patient_id=${patientid}`,
        config
      )
      .then((res) => {
        setTreatmentHistoryDetails(res.data.data);
        setSpinner(false);
      });
  };

  const generatePDF = () => {
    const invoice = invoiceRef.current;

    html2canvas(invoice).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice.pdf");
    });
  };

  return (
    <div className="clinic-user-add-patient-main-div d-flex jc-center ai-start">
      <Helmet>
        <title>My Account</title>
      </Helmet>
      <div
        style={{
          margin: "1.25vw 0 0 0",
        }}
      >
        <Spin
          indicator={antIcon}
          size="large"
          spinning={spinner && true}
          delay={500}
        >
          {isInvoiceClicked ? (
            <div className="invoice-data-main-div">
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col
                  className="back-button"
                  onClick={() => {
                    setPatientTabsData("Treatment History");
                    setInvoiceClicked(false);
                  }}
                >
                  <img
                    src={leftArrow}
                    alt=""
                    style={{
                      width: "0.938vw",
                      height: "0.938vw",
                    }}
                  />
                  <span className="back-text">Back</span>
                </Col>
                <Col>
                  <Button className="download-pdf-button" onClick={generatePDF}>
                    Download PDF
                  </Button>
                </Col>
              </Row>
              {invoiceDetails[0] && (
                <Row className="invoice-details-main-row" ref={invoiceRef}>
                  <Row className="invoice-header">
                    <img
                      src={invoiceLogo}
                      alt="Logo"
                      style={{
                        width: "8.34vw",
                        height: "2.0834vw",
                      }}
                    />
                    <div
                      style={{
                        marginTop: "0.63vw",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.834vw",
                      }}
                    >
                      <span className="invoice-number">
                        {invoiceDetails[0].order_id}
                      </span>
                      <span className="invoice-date">
                        {moment(invoiceDetails[0].invoice_date).format(
                          "MMMM DD, YYYY"
                        )}
                      </span>
                    </div>
                  </Row>

                  <Row className="invoice-details">
                    <div className="provider">
                      <span className="provider-text">Provider</span>
                      <span className="provider-name">
                        {invoiceDetails[0].branch_name}
                        <p className="provider-id">
                          ID #{invoiceDetails[0].branch_id}
                        </p>
                      </span>
                      <div className="provider-contact-info">
                        <span className="provider-address">
                          <p>
                            {invoiceDetails[0].branch_address_line_one},{" "}
                            {invoiceDetails[0].branch_city},{" "}
                            {invoiceDetails[0].branch_province},
                          </p>
                          <p>{invoiceDetails[0].branch_zip_code}</p>
                        </span>
                        <span className="provider-email">
                          {invoiceDetails[0].branch_email_id}
                        </span>
                        <span className="provider-phone">
                          {invoiceDetails[0].branch_phone_number}
                        </span>
                      </div>
                    </div>
                    <div className="billing">
                      <span className="billing-to-text">Billing To</span>
                      <span className="biller-name">
                        {invoiceDetails[0].patient_name}
                        <p className="biller-id">
                          ID #{invoiceDetails[0].patient_id}
                        </p>
                      </span>
                      <div className="biller-contact-info">
                        <span className="biller-address">
                          <p>
                            {invoiceDetails[0].patient_address_line_one},{" "}
                            {invoiceDetails[0].patient_city},{" "}
                            {invoiceDetails[0].patient_province},
                          </p>
                          <p>{invoiceDetails[0].patient_zip_code}</p>
                        </span>
                        <span className="biller-email">
                          {invoiceDetails[0].patient_email_id}
                        </span>
                        <span className="biller-phone">
                          {invoiceDetails[0].patient_phone_number}
                        </span>
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <table className="treatment-summary">
                      <thead>
                        <tr>
                          <th>Treatment Summary</th>
                          <th>Date</th>
                          <th className="table-last">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{invoiceDetails[0].treatment_name}</td>
                          <td>
                            {moment(invoiceDetails[0].treatment_date).format(
                              "MMMM DD, YYYY"
                            )}
                          </td>
                          <td className="table-last">
                            Rs. {invoiceDetails[0].treatment_amount}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="total-amount">
                      <span>Total Amount: </span>
                      <span>Rs. {invoiceDetails[0].treatment_amount}</span>
                    </div>
                  </Row>

                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.0417vw",
                    }}
                  >
                    <div className="payment-details">Payment Details</div>
                    <span className="payment-method">
                      Payment Method <p>UPI</p>
                    </span>
                  </Row>
                </Row>
              )}
            </div>
          ) : (
            <PatientProfile
              patientUserDetails={patientUserDetails}
              getPatientDetails={getPatientDetails}
              setPatientUserDetails={setPatientUserDetails}
              setSpinner={setSpinner}
              form={form}
              patientPaymentHistory={patientPaymentHistory}
              treatmentHistory={treatmentHistory}
              setTreatmentHistoryDetails={setTreatmentHistoryDetails}
              route="patient"
              patientTabsData={patientTabsData}
              setPatientTabsData={setPatientTabsData}
              isInvoiceClicked={isInvoiceClicked}
              setInvoiceClicked={setInvoiceClicked}
              invoiceDetails={invoiceDetails}
              setInvoiceDetails={setInvoiceDetails}
              setInvoiceNumber={setInvoiceNumber}
            />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default MyAccount;
