import { Button, Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import StaffProfilePicture from "../../../../clinic_admin/modules/clinic_admin_dashboard/StaffProfilePicture";
import createAppointmentIcon from "../../../../../assets/reschedule-icon.png";
import API_BASE_URL from "../../../../../env";
import axios from "axios";
import GlobalPatientsXrays from "../../../../super_admin/modules/super_admin_patients/patients_xray_&_reports/global_patients_xray/GlobalPatientsXrays";
import ReportsModal from "../../../../clinic_admin/modules/clinic_admin_dashboard/clinic_admin_xrays_and_reports/reports_modal/ReportsModal";
import { useLocation, useNavigate } from "react-router";
import { UserAuth } from "../../../../../context_api/ContextApi";
import { clinic_user_tabs_patients } from "../../../../../utils/clinic_user_constants/ClinicUserConstants";
import { tabs_admin_patients } from "../../../../../utils/clinic_admin_constants/ClinicAdminConstants";

const DashboardPatients = ({ item }) => {
  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");
  const xray = "XRAY";
  const other = "other";
  const [patientDashboardReportsDetails, setPatientDashboardReportsDetails] =
    useState([]);
  const [openXrayModal, setOpenXrayModal] = useState(false);
  const { setTabActiveKey, setAdminTabActiveKey } = UserAuth();
  const [IsOpenFullReportModal, setIsOpenFullReportModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const key = location.pathname;
  const newkey = key.split("/")[1];

  const getXrayImageReport = async (user_id, xrayType) => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/report/clinic/${clinic_id}/patient/${user_id}?report_type=${xrayType}`,
        config
      )
      .then((res) => {
        setPatientDashboardReportsDetails(res.data.data);
      });
  };

  return (
    <div className="patient-data">
      <Row>
        <Col xs={24} md={7} sm={19} xl={11}>
          <Row>
            <Col>
              <StaffProfilePicture
                pro={
                  item.profile_picture_url &&
                  item.profile_picture_url.split(".com/")[1]
                }
              />
            </Col>
            <Col
              xl={15}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 0 0 10px",
              }}
            >
              <Row>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    textDecorationLine: "underline",
                    color: "#2CA3FA",
                    display: "block",
                    width: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/clinic-user/patient-profile/${item.user_id}`);
                    setTabActiveKey(clinic_user_tabs_patients);
                  }}
                >
                  {item.name}
                </span>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000000",
                  }}
                >
                  #PID
                  <span
                    style={{
                      color: "#7D7D7D",
                    }}
                  >
                    &nbsp;{item.user_id}
                  </span>
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={2}
          lg={2}
          xl={5}
          style={{ display: "flex", alignItems: "center" }}
        ></Col>
        <Col
          xs={24}
          md={18}
          lg={2}
          xl={5}
          style={{
            display: "flex",
            justifyContent: window.innerWidth > 992 ? "center" : "flex-end",
            alignItems: "center",
          }}
        >
          {/* <u
            style={{
              color: "#2CA3FA",
              cursor: "pointer",
              margin: "0 0 0 15%",
            }}
            onClick={() => {
              setOpenXrayModal(true);
              getXrayImageReport(item.user_id, xray);
            }}
          >
            X-Rays
          </u>
          <GlobalPatientsXrays
            openXrayModal={openXrayModal}
            setOpenXrayModal={setOpenXrayModal}
            xraysAndReports={patientDashboardReportsDetails}
          /> */}
        </Col>
        <Col
          xs={24}
          md={4}
          lg={3}
          xl={3}
          style={{
            display: "flex",
            justifyContent: window.innerWidth > 992 ? "center" : "flex-end",
            gap: 5,
            alignItems: "center",
          }}
        >
          <Button
            style={{
              background: "#FFFFFF",
              border: "1px solid #97C46F",
              borderRadius: 30,
              fontWeight: 600,
              fontSize: 14,
              color: "#525252",
            }}
            onClick={() => {
              if (newkey === "clinic-user") {
                navigate(`/clinic-user/reports/${item.user_id}`);
                setTabActiveKey(tabs_admin_patients);
              }
              if (newkey === "clinic-admin") {
                navigate(`/clinic-admin/reports/${item.user_id}`);
                setTabActiveKey(tabs_admin_patients);
              }
            }}
          >
            <Row className="d-flex ai-center" style={{ gap: 7 }}>
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "14PX",
                  color: "#525252",
                }}
              >
                View Report
              </span>
            </Row>
          </Button>
          {/* <u
            style={{ color: "#2CA3FA", cursor: "pointer" }}
            onClick={() => {
              setIsOpenFullReportModal(true);
              getXrayImageReport(item.user_id, other);
            }}
          >
            Attachments
          </u>
          <ReportsModal
            IsOpenFullReportModal={IsOpenFullReportModal}
            setIsOpenFullReportModal={setIsOpenFullReportModal}
            xraysAndReports={patientDashboardReportsDetails}
          /> */}
        </Col>
      </Row>
    </div>
  );
};

export default DashboardPatients;
