import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useState } from "react";
import "./ViewPatientById.css";
import { routes_clinic_user_patients } from "../../../../../utils/clinic_user_constants/ClinicUserConstants";
import ProfilePic from "../../../../../assets/default-profile-pic.png";
import axios from "axios";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import { options } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import API_BASE_URL from "../../../../../env";

const ViewPatientById = () => {
  const clinicId = localStorage.getItem("clinic_id");
  const Token = localStorage.getItem("Token");
  const [patientIdForClinic, setPatientIdForClinic] = useState();
  const [patientDataByIdForClinic, setPatientDataByIdForClinic] = useState();
  const [patientReportByIdForClinic, setPatientReportByIdForClinic] =
    useState();
  const [IsOpenFullXrayModal, setIsOpenFullXrayModal] = useState(false);
  const [xrayMimeType, setxrayMimeType] = useState("");
  const [xrayImageUrl, setXrayImageUrl] = useState("");

  const imageJPEG = "image/jpeg";
  const jpegimagedatabaseurl = "data:image/jpeg;base64,/9j/";
  const pngimagedatabaseurl = "data:image/png;base64,";

  const handleGetPatientByIdForClinic = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/clinic/${clinicId}/patient/${patientIdForClinic}`,
        config
      )
      .then((res) => {
        setPatientReportByIdForClinic(
          res.data.data.ml_analysis_report_response_list
        );
        setPatientDataByIdForClinic(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };
  const handleSendingImageUrl = (fileMimeType, imageUrl) => {
    setxrayMimeType(fileMimeType);
    setXrayImageUrl(imageUrl);
  };

  return (
    <div className="view-patient-by-id-main-div">
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "13px 23px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Patient ID# 258794
          </span>
          <Breadcrumb>
            <Breadcrumb.Item
              style={{
                background: "#2381C6",
                padding: "1px 10px",
                borderRadius: "3px",
              }}
            >
              <a href={routes_clinic_user_patients} style={{ color: "#FFF" }}>
                Patients
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{
                background: "#2381C6",
                padding: "1px 10px",
                borderRadius: "3px",
                color: "#FFF",
              }}
            >
              Patient Search By Id
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Row>
      <Row
        style={{
          background: "#FFF",
          margin: "0 23px",
          padding: "32px",
        }}
      >
        <div
          style={{
            background: "#FBFBFB",
            width: "100%",
            padding: "36px 0 56px 0",
            gap: 25,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Row>
            <span
              style={{
                fontWeight: 600,
                fontSize: 20,
                color: "#525252",
              }}
            >
              Patient History Search by ID
            </span>
          </Row>
          <Row
            className="w-100 d-flex jc-center"
            style={{
              gap: 23,
            }}
          >
            <Input
              value={patientIdForClinic}
              onChange={(e) => setPatientIdForClinic(e.target.value)}
              style={{
                maxWidth: "399px",
              }}
            />
            <Button
              className="search-patient-by-id"
              onClick={handleGetPatientByIdForClinic}
            >
              Search
            </Button>
          </Row>
        </div>
      </Row>
      {patientDataByIdForClinic && (
        <Row
          style={{
            margin: "23px",
            display: "flex",
            gap: 23,
          }}
        >
          <Col>
            <div className="profile-pic-col-main-div">
              <div
                style={{
                  padding: "3.3333333333333335vw",
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Col>
                    <div
                      style={{
                        borderRadius: "40%",
                        background: "#D9D9D9",
                        width: "118px",
                        height: "118px",
                      }}
                    >
                      <img
                        style={{
                          height: "118px",
                          width: "118px",
                          borderRadius: 40,
                        }}
                        src={ProfilePic}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "27px 0 0 0",
                    gap: "2.302vh",
                  }}
                >
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Name
                    </span>

                    <Input
                      value={patientDataByIdForClinic.name}
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#525252",
                        height: "40px",
                        borderRadius: "3px",
                        border: "1px solid #E3E3E3",
                      }}
                      disabled={true}
                      bordered={false}
                    />
                  </Row>
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Patient Identification
                    </span>
                    <Input
                      value={"PID# " + patientDataByIdForClinic.user_id}
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#525252",
                        height: "40px",
                        borderRadius: "3px",
                        border: "1px solid #E3E3E3",
                      }}
                      bordered={false}
                      disabled={true}
                    />
                  </Row>
                </Row>
              </div>
              <div
                style={{
                  padding:
                    "3.7151702786377707vh 3.3333333333333335vw 4vh 3.3854166666666665vw",
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#A5A4A4",
                  }}
                >
                  Email
                </span>
                <Input
                  value={patientDataByIdForClinic.email}
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#7D7D7D",
                    height: "40px",
                    borderRadius: "3px",
                    border: "1px solid #E3E3E3",
                  }}
                  disabled={true}
                  bordered={false}
                />
              </div>
              <div
                style={{
                  padding:
                    "3.7151702786377707vh 3.3333333333333335vw 4.615384615384615vh 3.3854166666666665vw",
                }}
              >
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#A5A4A4",
                  }}
                >
                  Phone
                </span>
                <Space.Compact>
                  <Select
                    bordered={false}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: 40,
                      background: "#FFFFFF",
                      border: "1px solid #E3E3E3",
                      margin: "3px 0 0 0",
                    }}
                    defaultValue="Select"
                    options={options}
                    value={patientDataByIdForClinic.phone_country_code}
                  />
                  <Input
                    bordered={false}
                    style={{
                      height: 40,
                      background: "#FFFFFF",
                      borderTop: "1px solid #E3E3E3",
                      borderRight: "1px solid #E3E3E3",
                      borderBottom: "1px solid #E3E3E3",
                      margin: "3px 0 0 0",
                    }}
                    defaultValue=""
                    value={patientDataByIdForClinic.phone_number}
                  />
                </Space.Compact>
              </div>
            </div>
          </Col>
          <Col>
            <div className="d-flex jc-center clinic-user-mlanalysis-main-div">
              <div
                className="w-100"
                style={{
                  padding: "0px 19px",
                }}
              >
                <Row
                  style={{
                    height: "54px",
                    borderBottom: " 1px solid #E3E3E3",
                  }}
                  className="d-flex jc-between ai-center"
                >
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: 18,
                      color: "#525252",
                    }}
                  >
                    Xrays-Reports (
                    {patientReportByIdForClinic &&
                      patientReportByIdForClinic.length}
                    )
                  </span>

                  <u
                    style={{
                      fontWeight: 400,
                      fontSize: 14,
                      color: "#2CA3FA",
                      cursor: "pointer",
                    }}
                  >
                    View All
                  </u>
                </Row>

                <Row
                  style={{
                    padding: "0 13px",
                    margin: "23px 15px 15px 0",
                  }}
                >
                  <Col span={4}>
                    <span className="xray-details-heading">Upload Date</span>
                  </Col>
                  <Col span={11}>
                    <span className="xray-details-heading">
                      Clinic Name / Self
                    </span>
                  </Col>
                  <Col span={5}>
                    <span className="xray-details-heading">Xray Reports</span>
                  </Col>
                  <Col span={4}>
                    <span className="xray-details-heading">Other Reports</span>
                  </Col>
                </Row>

                {patientReportByIdForClinic &&
                  patientReportByIdForClinic.map((item) => {
                    return (
                      <Row
                        style={{
                          width: "100%",
                          background: "#FBFBFB",
                          borderRadius: 5,
                          height: "10vh",
                          padding: "0 13px",
                          margin: "0 0 5px 0",
                        }}
                      >
                        <Col
                          span={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span className="xray-timestamp">
                            {item.uploaded_at &&
                              moment(item.uploaded_at).format(
                                "ddd, DD/MM/YYYY HH:mm A"
                              )}
                          </span>
                        </Col>
                        <Col
                          span={11}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          {item.uploaded_by === "Self" ? (
                            <span className="self-clinic">
                              {item.uploaded_by}
                            </span>
                          ) : (
                            <span className="dashboard-clinic-name">
                              {item.uploaded_by}
                            </span>
                          )}
                          {item.uploaded_by === "Self" ? (
                            <span
                              style={{
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "#7D7D7D",
                              }}
                            >
                              Clid {item.uploader_id}
                            </span>
                          ) : (
                            <span className="dashboard-clinic-name">
                              Clid: #{item.uploader_id}
                            </span>
                          )}
                        </Col>
                        <Col
                          span={5}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item.xray_reports &&
                            item.xray_reports.slice(0, 1).map((xrayimages) => {
                              return (
                                <>
                                  <img
                                    src={
                                      xrayimages.file_mime_type === imageJPEG
                                        ? jpegimagedatabaseurl +
                                          xrayimages.report_image
                                        : pngimagedatabaseurl +
                                          xrayimages.report_image
                                    }
                                    alt=""
                                    style={{
                                      width: "4.21875vw",
                                      height: "5.743589743589744vh",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setIsOpenFullXrayModal(true);
                                      handleGetPatientByIdForClinic();
                                      handleSendingImageUrl(
                                        item.file_mime_type,
                                        item.report_image
                                      );
                                    }}
                                  />
                                  {/* Modal for displaying multiple Xrays */}
                                  <Modal
                                    open={IsOpenFullXrayModal}
                                    centered
                                    footer={false}
                                    closable={false}
                                    width={"51.145833333333336vw"}
                                    mask={false}
                                  >
                                    <div
                                      style={{
                                        height: "75.07692307692308vh",
                                      }}
                                    >
                                      <Row className="d-flex jc-between ai-center">
                                        <span
                                          style={{
                                            fontWeight: 700,
                                            fontSize: 22,
                                            color: "#525252",
                                          }}
                                        >
                                          ML Analysis
                                        </span>
                                        <CloseOutlined
                                          style={{
                                            color: "red",
                                            fontSize: "16px",
                                          }}
                                          onClick={() =>
                                            setIsOpenFullXrayModal(false)
                                          }
                                        />
                                      </Row>
                                      <Row
                                        style={{
                                          width: "100%",
                                          margin: "25px 0 0 0",
                                          gap: "10px",
                                        }}
                                      >
                                        <Col
                                          span={7}
                                          style={{
                                            maxHeight: "270px",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          {patientReportByIdForClinic &&
                                            patientReportByIdForClinic.map(
                                              (item, id) => {
                                                return item.xray_reports.map(
                                                  (item) => {
                                                    return (
                                                      <Row
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          background: "#F1FBFF",
                                                          height: "72px",
                                                          margin: "5px 0 0 0",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleSendingImageUrl(
                                                            item.file_mime_type,
                                                            item.report_image
                                                          )
                                                        }
                                                      >
                                                        <Col
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                          span={9}
                                                        >
                                                          <img
                                                            style={{
                                                              height: "41px",
                                                              width: "59px",
                                                            }}
                                                            src={
                                                              item.file_mime_type ===
                                                              imageJPEG
                                                                ? jpegimagedatabaseurl +
                                                                  item.report_image
                                                                : pngimagedatabaseurl +
                                                                  item.report_image
                                                            }
                                                            alt=""
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <span
                                                            style={{
                                                              fontWeight: "600",
                                                              fontSize: "14px",
                                                              color: "#525252",
                                                            }}
                                                          >
                                                            {item.title}
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                    );
                                                  }
                                                );
                                              }
                                            )}
                                        </Col>
                                        <Col
                                          span={16}
                                          style={{
                                            margin: "5px 0 0 0",
                                          }}
                                        ></Col>
                                      </Row>
                                      <Row
                                        style={{
                                          margin: "18px 0 0 0",
                                          height: "258px",
                                        }}
                                      ></Row>
                                      <Row
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "85px",
                                        }}
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontWeight: "600",
                                            fontSize: "16px",
                                            color: "#D35850",
                                            border: "1px solid #D35850",
                                            borderRadius: "16px",
                                          }}
                                          onClick={() =>
                                            setIsOpenFullXrayModal(false)
                                          }
                                        >
                                          Close
                                        </Button>
                                      </Row>
                                    </div>
                                  </Modal>
                                </>
                              );
                            })}
                          <span
                            style={{
                              margin: "0 0 0 10px",
                              color: "#2CA3FA",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            <u>
                              {item.xray_reports.length > 0 && (
                                <>({item.xray_reports.length})</>
                              )}
                            </u>
                          </span>
                        </Col>
                        <Col
                          span={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ cursor: "pointer" }}>
                            <img
                              alt=""
                              style={{
                                width: "14px",
                              }}
                            />
                            &nbsp;
                            {item.other_reports &&
                              item.other_reports
                                .slice(0, 1)
                                .map((reportImages) => {
                                  return (
                                    <span>
                                      <img
                                        src={
                                          reportImages.file_mime_type ===
                                          imageJPEG
                                            ? jpegimagedatabaseurl +
                                              reportImages.report_image
                                            : pngimagedatabaseurl +
                                              reportImages.report_image
                                        }
                                        alt=""
                                        style={{ display: "none" }}
                                      />
                                      <span>
                                        <u style={{ color: "#2CA3FA" }}>
                                          Attachments
                                        </u>
                                      </span>
                                    </span>
                                  );
                                })}
                            <span>
                              &nbsp;
                              <u style={{ color: "#2CA3FA" }}>
                                {item.other_reports.length > 0 && (
                                  <>({item.other_reports.length})</>
                                )}
                              </u>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ViewPatientById;
