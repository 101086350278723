import { Button, Col, Modal, Row, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useState } from "react";
import ivoryailogo from "../../../assets/ivory-ai-logo.png";
import { routes_patientlogin } from "../../../utils/patient_navbar_constants/PatientNavbarConstants";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import helpicon from "../../../assets/logout.png";
import { UserAuth } from "../../../context_api/ContextApi";
import {
  ADMIN,
  CUSTOMER_CARE,
  DATA_SCIENTIST,
  SUPER_ADMIN,
  SURVEY_UPDATE,
  routes_super_admin_articles,
  routes_super_admin_dashboard,
  routes_super_admin_patients,
  routes_super_admin_sub_users,
  routes_super_admin_surveys,
  tabs_super_admin_articles,
  tabs_super_admin_dashboard,
  tabs_super_admin_patients,
  tabs_super_admin_sub_users,
  tabs_super_admin_surveys,
} from "../../../utils/super_admin_constants/SuperAdminConsts";
import API_BASE_URL from "../../../env";
import Cookies from "js-cookie";
import { CloseOutlined } from "@ant-design/icons";

const SuperAdminNavbar = () => {
  const {
    adminTabActiveKey,
    setAdminTabActiveKey,
    // setFilterAppointmentsByDate,
  } = UserAuth();

  const [openConfirmSignoutModal, setopenConfirmSignoutModal] = useState(false);

  const Token = localStorage.getItem("Token");
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const location = useLocation();

  const patientSignOut = async () => {
    const decodedToken = JSON.parse(atob(Token.split(".")[1]));
    if (decodedToken.exp >= parseInt(Date.now() / 1000)) {
      const payload = {
        token: Token,
      };
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios
        .post(`${API_BASE_URL}/api/v1/user/logout`, payload, config)
        .then((res) => {
          localStorage.clear();
          Cookies.remove("rememberMe");
          navigate(routes_patientlogin);
        });
    } else if (decodedToken.exp <= parseInt(Date.now() / 1000)) {
      localStorage.clear();
      Cookies.remove("rememberMe");
      navigate(routes_patientlogin);
    }
  };

  // Auto Signout
  useEffect(() => {
    const decodedToken = JSON.parse(atob(Token.split(".")[1]));
    if (decodedToken.exp <= parseInt(Date.now() / 1000)) {
      localStorage.clear();
      Cookies.remove("rememberMe");
      navigate(routes_patientlogin);
    }
  }, []);

  const tabsChange = (activeKey) => {
    // setFilterAppointmentsByDate("");
    if (activeKey === tabs_super_admin_dashboard) {
      navigate(routes_super_admin_dashboard);
      setAdminTabActiveKey(tabs_super_admin_dashboard);
    } else if (activeKey === tabs_super_admin_patients) {
      navigate(routes_super_admin_patients);
      setAdminTabActiveKey(tabs_super_admin_patients);
    } else if (activeKey === tabs_super_admin_sub_users) {
      navigate(routes_super_admin_sub_users);
      setAdminTabActiveKey(tabs_super_admin_sub_users);
    } else if (activeKey === tabs_super_admin_surveys) {
      navigate(routes_super_admin_surveys);
      setAdminTabActiveKey(tabs_super_admin_surveys);
    } else if (activeKey === tabs_super_admin_articles) {
      navigate(routes_super_admin_articles);
      setAdminTabActiveKey(tabs_super_admin_articles);
    } else {
    }
  };

  const handleDefaultRoute = () => {
    const key = location.pathname;
    if (key === routes_super_admin_dashboard) {
      setAdminTabActiveKey(tabs_super_admin_dashboard);
    } else if (key === routes_super_admin_patients) {
      setAdminTabActiveKey(tabs_super_admin_patients);
    } else if (key === routes_super_admin_sub_users) {
      setAdminTabActiveKey(tabs_super_admin_sub_users);
    } else if (key === routes_super_admin_surveys) {
      setAdminTabActiveKey(tabs_super_admin_surveys);
    } else if (key === routes_super_admin_articles) {
      setAdminTabActiveKey(tabs_super_admin_articles);
    } else {
      setAdminTabActiveKey("");
    }
  };

  useEffect(() => {
    handleDefaultRoute();
  }, []);

  return (
    <div
      style={{
        height: "7.45vh",
        background: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent:
          window.innerWidth > 1050 ? "space-between" : "space-evenly",
      }}
      className="navbar-main-div"
    >
      <Col>
        <img
          src={ivoryailogo}
          alt=""
          style={{
            width: window.innerWidth > 950 ? "6.5vw" : "86px",
            margin: "0 0 0 1.25vw",
          }}
        />
      </Col>
      <Col xs={12} sm={16} md={14}>
        <Tabs
          onChange={tabsChange}
          activeKey={adminTabActiveKey}
          style={{ margin: "0 0 0 2.8645vw" }}
        >
          {role === SURVEY_UPDATE ? (
            <></>
          ) : (
            <TabPane
              tab={tabs_super_admin_dashboard}
              key={tabs_super_admin_dashboard}
            />
          )}
          {role === SUPER_ADMIN ||
          role === ADMIN ||
          role === DATA_SCIENTIST ||
          role === CUSTOMER_CARE ? (
            <TabPane
              tab={tabs_super_admin_patients}
              key={tabs_super_admin_patients}
            />
          ) : (
            <></>
          )}
          {role === SUPER_ADMIN || role === ADMIN ? (
            <TabPane
              tab={tabs_super_admin_sub_users}
              key={tabs_super_admin_sub_users}
            />
          ) : (
            <></>
          )}
          {role === SUPER_ADMIN ||
          role === ADMIN ||
          role === SURVEY_UPDATE ||
          role === CUSTOMER_CARE ||
          role === DATA_SCIENTIST ? (
            <TabPane
              tab={tabs_super_admin_surveys}
              key={tabs_super_admin_surveys}
            />
          ) : (
            <></>
          )}
          <TabPane
            tab={tabs_super_admin_articles}
            key={tabs_super_admin_articles}
          />
        </Tabs>
      </Col>
      <Col
        xs={6}
        sm={6}
        md={8}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Row
          className="d-flex ai-center jc-evenly"
          style={{
            height: "100%",
            gap: 20,
          }}
        >
          <Col
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              onClick={() => setopenConfirmSignoutModal(true)}
              src={helpicon}
              alt=""
              style={{
                width: window.innerWidth > 1050 ? "1.2vw" : "18px",
                height: window.innerWidth < 1050 && "20.5px",
                margin: "0 2vw 5px 0",
                cursor: "pointer",
              }}
            />
          </Col>
        </Row>
      </Col>

      <Modal
        width={568}
        centered
        open={openConfirmSignoutModal}
        footer={false}
        closable={false}
      >
        <Row className="d-flex ai-center jc-between">
          <span
            style={{
              fontWeight: 600,
              fontSize: 20,
              color: "#323232",
            }}
          >
            Sign-out Confirmation
          </span>
          <CloseOutlined
            style={{
              fontSize: 20,
              color: "red",
            }}
            onClick={() => {
              setopenConfirmSignoutModal(false);
            }}
          />
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            padding: "30px 0",
            gap: 15,
          }}
        >
          <div
            className="d-flex jc-center ai-center"
            style={{
              margin: "0 102px",
              width: 404,
              height: 57,
              background: "#F9F9F9",
              borderRadius: 5,
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 18,
                color: "#323232",
              }}
            >
              Are you sure you want to sign out ?
            </span>
          </div>
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            gap: 15,
          }}
        >
          <Button
            onClick={patientSignOut}
            style={{
              background: "#ECFEFF",
              border: "1px solid #29C3C1",
              borderRadius: 30,
              color: "#29C3C1",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setopenConfirmSignoutModal(false);
            }}
            style={{
              background: "#FFF5F3",
              border: "1px solid #FF0000",
              borderRadius: 30,
              color: "#EA4335",
            }}
          >
            No
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

export default SuperAdminNavbar;
