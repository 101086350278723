import { Button, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import ImageMap from "../../../../../home/modules/ml_analysis/xray_and_report_details/xray_image_component/image_map/ImageMap";

const ReportsModal = (props) => {
  const { IsOpenFullReportModal, setIsOpenFullReportModal, xraysAndReports } =
    props;
  const [reportImageUrl, setReportImageUrl] = useState("");

  const handleSendingImageUrl = (imageUrl) => {
    setReportImageUrl(imageUrl);
  };

  return (
    <div>
      <Modal
        width={"753px"}
        open={IsOpenFullReportModal}
        centered
        footer={false}
        closable={false}
        mask={false}
      >
        <div
          className="download-docs-modal d-flex flex-col ai-center"
          style={{
            background: "#FFF",
            height: "747px",
          }}
        >
          <Row className="d-flex ai-center jc-between w-100">
            <span
              style={{
                fontWeight: 700,
                fontSize: 22,
                color: "#525252",
              }}
            >
              Dental Reports
            </span>
            <CloseOutlined
              style={{ color: "red", fontSize: "19px" }}
              onClick={() => {
                setIsOpenFullReportModal(false);
                setReportImageUrl("");
              }}
            />
          </Row>
          {xraysAndReports && xraysAndReports.length > 0 ? (
            <>
              <Row
                style={{
                  width: "100%",
                  margin: "25px 0 0 0",
                  gap: "10px",
                  height: "57vh",
                }}
              >
                <Col span={7}>
                  {xraysAndReports &&
                    xraysAndReports.map((item, id) => {
                      return item.other_reports.map((item) => {
                        return (
                          <ImageMap
                            s3Url={item.report_image.split(".com/")[1]}
                            file_name={item.file_name}
                            title={item.title}
                            file_mime_type={item.file_mime_type}
                            handleSendingImageUrl={handleSendingImageUrl}
                          />
                        );
                      });
                    })}
                </Col>
                <Col
                  span={16}
                  style={{
                    margin: "5px 0 0 0",
                  }}
                >
                  {reportImageUrl ? (
                    <img
                      src={reportImageUrl}
                      style={{
                        width: "100%",
                        height: "27vh",
                        borderRadius: "5px",
                      }}
                      alt=""
                    />
                  ) : (
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "25vh",
                      }}
                    >
                      <span style={{ fontSize: "25px", fontWeight: "600" }}>
                        Click on Image to display here
                      </span>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                {reportImageUrl ? (
                  <span style={{ fontSize: "25px", fontWeight: "600" }}>
                    Click on Image to display here
                  </span>
                ) : (
                  <span style={{ fontSize: "30px", fontWeight: "700" }}>
                    No Data
                  </span>
                )}
              </Row>
            </>
          )}
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "107px",
            }}
          >
            <Button
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#D35850",
                border: "1px solid #D35850",
                borderRadius: "16px",
              }}
              onClick={() => {
                setIsOpenFullReportModal(false);
                setReportImageUrl("");
              }}
            >
              Close
            </Button>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ReportsModal;
