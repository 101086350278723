import { Button, Col, message, Modal, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "./PatientNavbar.css";
import ivoryailogo from "../../../assets/ivory-ai-logo.png";
import notifred from "../../../assets/notif-icon.png";
import notif from "../../../assets/notificon.png";
import ProfilePic from "../../../assets/default-profile-pic.png";
import helpicon from "../../../assets/logout.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  routes_patientlogin,
  routes_appointments,
  routes_articles,
  routes_clinics,
  routes_dashboard,
  routes_mlanalysis,
  routes_myaccount,
  routes_surveys,
  tabs_appointments,
  tabs_articles,
  tabs_account,
  tabs_clinics,
  tabs_dashboard,
  tabs_mlanalysis,
  tabs_myaccount,
  tabs_surveys,
  routes_account_settings,
  routes_notifications,
  routes_help,
  routes_book_appointment,
} from "../../../utils/patient_navbar_constants/PatientNavbarConstants";
import axios from "axios";
import { UserAuth } from "../../../context_api/ContextApi";
import TabPane from "antd/lib/tabs/TabPane";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../string";
import API_BASE_URL from "../../../env";
import Cookies from "js-cookie";
import { CloseOutlined } from "@ant-design/icons";
import settingsIcon from "../../../assets/settings-icon.png";

const PatientNavbar = () => {
  const patientid = localStorage.getItem("patient_id");
  const Token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const location = useLocation();

  // imports from context api
  const {
    allPatientNotificationsMap,
    handleGetAllPatientNotifications,
    patientUserDetails,
    setPatientUserDetails,
    tabActiveKey,
    setTabActiveKey,
    setInputsEnable,
    setS3Url,
    s3Url,
  } = UserAuth();

  const [openConfirmSignoutModal, setopenConfirmSignoutModal] = useState(false);

  // routings and tabnames for the navabr tabs
  const navbartabs = [
    { navigate: routes_dashboard, tab: tabs_dashboard },
    { navigate: routes_myaccount, tab: tabs_myaccount },
    { navigate: routes_mlanalysis, tab: tabs_mlanalysis },
    { navigate: routes_clinics, tab: tabs_clinics },
    { navigate: routes_surveys, tab: tabs_surveys },
    { navigate: routes_appointments, tab: tabs_appointments },
    // { navigate: routes_articles, tab: tabs_articles },
  ];

  // Function to retrieve the patient details who is logged in
  const handleGetPatientDetails = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/patient/${patientid}`, config)
      .then((res) => {
        setPatientUserDetails(res.data.data);
        setS3Url(
          res.data.data.profile_picture_url &&
            res.data.data.profile_picture_url.split(".com/")[1]
        );
      });
  };

  // Function for signout
  const patientSignOut = async () => {
    const decodedToken = JSON.parse(atob(Token.split(".")[1]));
    if (decodedToken.exp >= parseInt(Date.now() / 1000)) {
      const payload = {
        token: Token,
      };
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios
        .post(`${API_BASE_URL}/api/v1/user/logout`, payload, config)
        .then((res) => {
          localStorage.clear();
          Cookies.remove("rememberMe");
          navigate(routes_patientlogin);
        });
    } else if (decodedToken.exp <= parseInt(Date.now() / 1000)) {
      localStorage.clear();
      Cookies.remove("rememberMe");
      navigate(routes_patientlogin);
    }
  };

  useEffect(() => {
    const decodedToken = JSON.parse(atob(Token.split(".")[1]));
    if (decodedToken.exp <= parseInt(Date.now() / 1000)) {
      localStorage.clear();
      Cookies.remove("rememberMe");
      navigate(routes_patientlogin);
    }
  }, []);

  // Dropdown for the sign out and referral button
  const profileDropdown = (
    <div className="patient-nav-bar-dropdown">
      <Row onClick={patientSignOut} className="patient-nav-bar-dropdown-row">
        Sign Out
      </Row>
    </div>
  );

  // Functions runs when there is any change in the navbar tabs
  const tabsChange = (activeKey) => {
    if (activeKey === tabs_dashboard) {
      navigate(routes_dashboard);
      setTabActiveKey(tabs_dashboard);
      setInputsEnable(true);
    } else if (activeKey === tabs_myaccount) {
      navigate(routes_myaccount);
      setTabActiveKey(tabs_myaccount);
      setInputsEnable(true);
    } else if (activeKey === tabs_mlanalysis) {
      navigate(routes_mlanalysis);
      setTabActiveKey(tabs_mlanalysis);
      setInputsEnable(true);
    } else if (activeKey === tabs_clinics) {
      navigate(routes_clinics);
      setTabActiveKey(tabs_clinics);
      setInputsEnable(true);
    } else if (activeKey === tabs_surveys) {
      navigate(routes_surveys);
      setTabActiveKey(tabs_surveys);
      setInputsEnable(true);
    } else if (activeKey === tabs_appointments) {
      navigate(routes_appointments);
      setTabActiveKey(tabs_appointments);
      setInputsEnable(true);
    } else if (activeKey === tabs_articles) {
      navigate(routes_articles);
      setTabActiveKey(tabs_articles);
      setInputsEnable(true);
    } else {
    }
    handleGetAllPatientNotifications();
  };

  // Function runs in useEffect to match the selected tab and the routing in the url
  const handleDefaultRoute = () => {
    const key = location.pathname;
    if (key === routes_dashboard) {
      setTabActiveKey(tabs_dashboard);
    }
    if (key === routes_myaccount) {
      setTabActiveKey(tabs_myaccount);
    }
    if (key === routes_mlanalysis) {
      setTabActiveKey(tabs_mlanalysis);
    }
    if (key === routes_clinics) {
      setTabActiveKey(tabs_clinics);
    }
    if (key === routes_surveys) {
      setTabActiveKey(tabs_surveys);
    }
    if (key === routes_appointments) {
      setTabActiveKey(tabs_appointments);
    }
    if (key === routes_book_appointment) {
      setTabActiveKey(tabs_appointments);
    }
    if (key === routes_articles) {
      setTabActiveKey(tabs_articles);
    }
    if (key === routes_account_settings) {
      setTabActiveKey(tabs_account);
    }
    if (key === routes_notifications) {
      setTabActiveKey("Notifications");
    }
    if (key === routes_help) {
      setTabActiveKey("Help");
    }
    if (key.split("/")[2] === "view-report") {
      setTabActiveKey(tabs_mlanalysis);
    }
  };

  // To toggle between the notification icon
  const samplearray =
    allPatientNotificationsMap &&
    allPatientNotificationsMap
      .filter((item) => item.viewed === false)
      .map((item) => item.viewed);

  useEffect(() => {
    handleDefaultRoute();
    handleGetAllPatientNotifications();
    handleGetPatientDetails();
  }, []);

  // code for displaying the s3 images
  const [fileContent, setFileContent] = useState("");
  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: s3Url,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [s3Url]);

  return (
    <div
      style={{
        height: "7.45vh",
        background: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent:
          window.innerWidth > 1050 ? "space-between" : "space-evenly",
      }}
      className="navbar-main-div"
    >
      <Col>
        <img
          src={ivoryailogo}
          alt=""
          style={{
            width: window.innerWidth > 950 ? "6.5vw" : "86px",
            margin: "0 0 0 1.25vw",
          }}
        />
      </Col>
      <Col xs={12} sm={16} md={14}>
        <Tabs
          onChange={tabsChange}
          activeKey={tabActiveKey}
          style={{ margin: "0 0 0 2.8645vw" }}
        >
          {navbartabs.map((item) => {
            return <TabPane tab={item.tab} key={item.tab} />;
          })}
        </Tabs>
      </Col>
      <Col
        xs={6}
        sm={6}
        md={8}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Row
          className="d-flex ai-center jc-evenly"
          style={{
            height: "100%",
            gap: "1.0417vw",
            margin: "0 1.0417vw",
            minWidth: "7vw",
          }}
        >
          <Col
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <span className="user-name">
              {patientUserDetails &&
                patientUserDetails.referral_code &&
                "Referred By : " + patientUserDetails.referral_code}
            </span> */}
            <img
              src={settingsIcon}
              style={{
                cursor: "pointer",
                width: "1.0417vw",
                height: "2.0775vh",
              }}
              onClick={() => {
                setTabActiveKey("Account Settings");
                navigate(routes_account_settings);
              }}
            />
          </Col>
          <Col
            style={{
              borderBottom:
                tabActiveKey === "Notifications"
                  ? "5px solid #1890ff"
                  : "1px solid white",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={samplearray && samplearray[0] === false ? notifred : notif}
              alt=""
              style={{
                width: "0.936vw",
                height: "2.19vh",
                cursor: "pointer",
              }}
              onClick={() => {
                setTabActiveKey("Notifications");
                navigate(routes_notifications);
              }}
            />
          </Col>
          <span className="user-name">
            {patientUserDetails && patientUserDetails.name}
          </span>
          <img
            src={fileContent ? fileContent : ProfilePic}
            alt=""
            style={{
              width: window.innerWidth > 1050 ? "2vw" : "36px",
              height:
                window.innerWidth > 1050 ? "3.7948717948717947vh" : "36px",
              borderRadius: "13px",
              cursor: "pointer",
            }}
          />
          <img
            onClick={() => setopenConfirmSignoutModal(true)}
            src={helpicon}
            alt=""
            style={{
              width: window.innerWidth > 1050 ? "1.2vw" : "18px",
              height: window.innerWidth < 1050 && "20.5px",
              cursor: "pointer",
            }}
          />
        </Row>
      </Col>
      <Modal
        width={568}
        centered
        open={openConfirmSignoutModal}
        footer={false}
        closable={false}
      >
        <Row className="d-flex ai-center jc-between">
          <span
            style={{
              fontWeight: 600,
              fontSize: 20,
              color: "#323232",
            }}
          >
            Sign-out Confirmation
          </span>
          <CloseOutlined
            style={{
              fontSize: 20,
              color: "red",
            }}
            onClick={() => {
              setopenConfirmSignoutModal(false);
            }}
          />
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            padding: "30px 0",
            gap: 15,
          }}
        >
          <div
            className="d-flex jc-center ai-center"
            style={{
              margin: "0 102px",
              width: 404,
              height: 57,
              background: "#F9F9F9",
              borderRadius: 5,
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: 18,
                color: "#323232",
              }}
            >
              Are you sure you want to sign out ?
            </span>
          </div>
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            gap: 15,
          }}
        >
          <Button
            onClick={patientSignOut}
            style={{
              background: "#ECFEFF",
              border: "1px solid #29C3C1",
              borderRadius: 30,
              color: "#29C3C1",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setopenConfirmSignoutModal(false);
            }}
            style={{
              background: "#FFF5F3",
              border: "1px solid #FF0000",
              borderRadius: 30,
              color: "#EA4335",
            }}
          >
            No
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

export default PatientNavbar;
