import { Col, Dropdown, Row, message } from "antd";
import React, { useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import { useNavigate } from "react-router";
import report from "../../../../../../../assets/add-report-icon.png";
import ReportsModal from "../../../../../../clinic_admin/modules/clinic_admin_dashboard/clinic_admin_xrays_and_reports/reports_modal/ReportsModal";
import StaffProfilePicture from "../../../../../../clinic_admin/modules/clinic_admin_dashboard/StaffProfilePicture";
import GlobalPatientsXrays from "../../../../super_admin_patients/patients_xray_&_reports/global_patients_xray/GlobalPatientsXrays";
import axios from "axios";
import API_BASE_URL from "../../../../../../../env";

const ClinicPatients = (props) => {
  const Token = localStorage.getItem("Token");
  const {
    patientdetails,
    handleGetXrayAndReportDetails,
    xraysAndReports,
    clinic_id,
    handleGetAllPatientsInSelectedClinic,
  } = props;
  const navigateToPatientProfileinSuperAdmin = `/super-admin/clinic/${clinic_id}/patient-profile`;
  const [IsOpenFullXrayModal, setIsOpenFullXrayModal] = useState(false);
  const [IsOpenFullReportModal, setIsOpenFullReportModal] = useState(false);
  const navigate = useNavigate();
  const xray = "xray";
  const other = "other";

  const handleDeleteClinicPatient = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .delete(
        `${API_BASE_URL}/api/v1/patient/${patientdetails.user_id}`,
        config
      )
      .then((res) => {
        message.success(res.data);
        handleGetAllPatientsInSelectedClinic();
      });
  };

  return (
    <Row
      key={patientdetails.user_id}
      style={{
        borderBottom: "1px solid #DDD",
        padding: "1.25vw",
        width: "100%",
      }}
      className="d-flex ai-center"
    >
      <Col style={{ display: "flex", flexDirection: "column" }} span={3}>
        <span className="patients-data-date">
          {patientdetails.created_at &&
            moment(patientdetails.created_at).format("ddd, DD-MM-YYYY")}
        </span>
        <span className="patients-data-date">
          {patientdetails.created_at &&
            moment(patientdetails.created_at).format("HH:mm")}
        </span>
      </Col>
      <Col span={4}>
        <Row style={{
          display: "flex",
          gap: "0.417vw"
        }}>
          <StaffProfilePicture
            pro={
              patientdetails.profile_picture_url &&
              patientdetails.profile_picture_url.split(".com/")[1]
            }
          />
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.417vw"
          }}>
            <span
              style={{
                fontWeight: 600,
                fontSize: "0.782vw",
                textDecorationLine: "underline",
                color: "#2CA3FA",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(
                  `${navigateToPatientProfileinSuperAdmin}/${patientdetails.user_id}`
                )
              }
            >
              {patientdetails.name}
            </span>
            <span
              style={{
                fontWeight: 600,
                fontSize: "0.73vw",
                color: "#000000",
              }}
            >
              #PID
              <span
                style={{
                  color: "#7D7D7D",
                }}
              >
                &nbsp;{patientdetails.user_id}
              </span>
            </span>
          </div>

        </Row>
      </Col>
      <Col span={5}>
        <span style={{
          fontSize: "0.8vw",
        }}>{patientdetails.email}</span>
      </Col>
      <Col span={4}>
        <span style={{
          fontSize: "0.8vw",
        }}>
          {patientdetails.phone_number}
        </span>
      </Col>
      <Col
        span={3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <u
          style={{ color: "#2CA3FA", cursor: "pointer", fontSize: "0.8vw" }}
          onClick={() => {
            setIsOpenFullXrayModal(true);
            handleGetXrayAndReportDetails(patientdetails.user_id, xray);
          }}
        >
          View
        </u>
        <GlobalPatientsXrays
          openXrayModal={IsOpenFullXrayModal}
          setOpenXrayModal={setIsOpenFullXrayModal}
          xraysAndReports={xraysAndReports}
        />
      </Col>
      <Col
        span={4}
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "0.26vw",
        }}
      >
        <img src={report} alt="" style={{ width: "0.834vw" }} />
        <u
          style={{ color: "#2CA3FA", cursor: "pointer", fontSize: "0.8vw" }}
          onClick={() => {
            setIsOpenFullReportModal(true);
            handleGetXrayAndReportDetails(patientdetails.user_id, other);
          }}
        >
          Attachments
        </u>
        <ReportsModal
          IsOpenFullReportModal={IsOpenFullReportModal}
          setIsOpenFullReportModal={setIsOpenFullReportModal}
          xraysAndReports={xraysAndReports}
        />
      </Col>
      <Col span={1}>
        <Dropdown
          overlay={
            <div className="patient-delete-dropdown">
              <Row
                className="full-profile-row"
                onClick={() =>
                  navigate(
                    `${navigateToPatientProfileinSuperAdmin}/${patientdetails.user_id}`
                  )
                }
              >
                View full profile
              </Row>
              <Row
                className="delete-patient-row"
                onClick={handleDeleteClinicPatient}
              >
                Delete patient
              </Row>
            </div>
          }
          placement="bottomRight"
        >
          <EllipsisOutlined
            rotate={90}
            style={{ fontSize: 20, cursor: "pointer" }}
          />
        </Dropdown>
      </Col>
    </Row>
  );
};

export default ClinicPatients;
