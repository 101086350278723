import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import "./Login.css";
import Ivorylogo from "../../assets/logo.png";
import HorizontalLine from "../../assets/horizontal-line.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  routes_dashboard,
  routes_forgot_password,
  routes_patientsignup,
} from "../../utils/patient_navbar_constants/PatientNavbarConstants";
import { routes_clinic_user_dashboard } from "../../utils/clinic_user_constants/ClinicUserConstants";
import {
  routes_adminsignup,
  routes_clinic_admin_dashboard,
} from "../../utils/clinic_admin_constants/ClinicAdminConstants";
import {
  routes_super_admin_dashboard,
  routes_super_admin_surveys,
  SURVEY_UPDATE,
} from "../../utils/super_admin_constants/SuperAdminConsts";
import API_BASE_URL from "../../env";
import { LoadingOutlined } from "@ant-design/icons";
import { UserAuth } from "../../context_api/ContextApi";
import Cookies from "js-cookie";

const PatientLogin = () => {
  const Token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [reset_form] = Form.useForm();
  const [rememberMe, setRememberMe] = useState(false);
  const [isOpenResetPasswordModal, setIsOpenResetPasswordModal] =
    useState(false);
  const [spinner, setspinner] = useState(false);
  const [loginPendingPage, setloginPendingPage] = useState(false);
  const [userName, setUserName] = useState("");
  const [errorStatusCode, seterrorStatusCode] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 50, margin: "200px 0 0 0" }} spin />
  );
  const { setAllPatientNotificationsMap } = UserAuth();

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  // Runs after enter login details and clicks login button
  const handleSubmitLoginDetails = async (values) => {
    setspinner(true);
    const payload = {
      username: values.name,
      password: values.password,
    };
    setUserName(values.name);
    await axios
      .post(`${API_BASE_URL}/api/v1/auth/login`, payload)
      .then((res) => {
        localStorage.setItem("Token", res.headers.authorization);
        localStorage.setItem("role", res.data.data.role_names[0]);
        localStorage.setItem("email", res.data.data.email);
        if (res.data.data.change_password === true) {
          setIsOpenResetPasswordModal(true);
        } else if (res.data.data.change_password === undefined) {
          setIsOpenResetPasswordModal(false);
          // patient route
          if (res.data.data.role_names[0] === "STANDARD") {
            localStorage.setItem("patient_id", res.data.data.user_id);
            const config = {
              headers: {
                Authorization: `Bearer ${res.headers.authorization}`,
              },
            };
            axios
              .get(
                `${API_BASE_URL}/api/v1/notification/patient/${res.data.data.user_id}`,
                config
              )
              .then((res) => {
                setAllPatientNotificationsMap(res.data.data);
              });
            navigate(routes_dashboard);
          }
          // clinic user route
          if (res.data.data.role_names[0] === "CLINIC_USER") {
            localStorage.setItem("clinic_id", res.data.data.clinic[0].user_id);
            if (res.data.data.clinic[0].branch_id) {
              localStorage.setItem(
                "branch_id",
                res.data.data.clinic[0].branch_id
              );
            }
            localStorage.setItem("user_id", res.data.data.user_id);
            navigate(routes_clinic_user_dashboard);
          }
          // clinic admin route
          if (res.data.data.role_names[0] === "CLINIC_MASTER_ADMIN") {
            localStorage.setItem("clinic_id", res.data.data.user_id);
            localStorage.setItem(
              "main_branch_id",
              res.data.data.main_branch_response[0].branch_id
            );
            localStorage.setItem("user_id", res.data.data.user_id);
            navigate(routes_clinic_admin_dashboard);
          }
          if (res.data.data.role_names[0] === "CLINIC_ADMIN") {
            localStorage.setItem("user_id", res.data.data.user_id);
            localStorage.setItem("clinic_id", res.data.data.clinic[0].user_id);
            if (res.data.data.clinic[0].branch_id) {
              localStorage.setItem(
                "branch_id",
                res.data.data.clinic[0].branch_id
              );
            }
            navigate(routes_clinic_admin_dashboard);
          }
          // super admin route
          if (res.data.data.role_names[0] === "SUPER_ADMIN") {
            localStorage.setItem("super_admin_id", res.data.data.user_id);
            navigate(routes_super_admin_dashboard);
          }
          if (res.headers.authorization) {
            const config = {
              headers: {
                Authorization: `Bearer ${res.headers.authorization}`,
              },
            };
            axios
              .get(`${API_BASE_URL}/api/v1/static/rolesBySuperAdmin`, config)
              .then((response) => {
                const sub_user_roles = response.data.data;
                for (
                  let length_of_sub_user_roles = 0;
                  length_of_sub_user_roles <= sub_user_roles.length;
                  length_of_sub_user_roles++
                ) {
                  if (
                    res.data.data.role_names[0] ===
                    sub_user_roles[length_of_sub_user_roles].name &&
                    res.data.data.role_names[0] !== SURVEY_UPDATE
                  ) {
                    localStorage.setItem("sub_user_id", res.data.data.user_id);
                    navigate(routes_super_admin_dashboard);
                  } else if (
                    res.data.data.role_names[0] ===
                    sub_user_roles[length_of_sub_user_roles].name &&
                    res.data.data.role_names[0] === SURVEY_UPDATE
                  ) {
                    localStorage.setItem("sub_user_id", res.data.data.user_id);
                    navigate(routes_super_admin_surveys);
                  }
                }
              });
          }
          message.success("Successfully Logged in !");
        } else if (res.data.data.change_password === false) {
          setIsOpenResetPasswordModal(false);
          // patient route
          if (res.data.data.role_names[0] === "STANDARD") {
            localStorage.setItem("patient_id", res.data.data.user_id);
            const config = {
              headers: {
                Authorization: `Bearer ${res.headers.authorization}`,
              },
            };
            axios
              .get(
                `${API_BASE_URL}/api/v1/notification/patient/${res.data.data.user_id}`,
                config
              )
              .then((res) => {
                setAllPatientNotificationsMap(res.data.data);
              });
            navigate(routes_dashboard);
          }
          // clinic user route
          if (
            res.data.data.role_names[0] === "CLINIC_USER" &&
            res.data.data.clinic[0].branch_id
          ) {
            localStorage.setItem("clinic_id", res.data.data.clinic[0].user_id);
            if (res.data.data.clinic[0].branch_id) {
              localStorage.setItem(
                "branch_id",
                res.data.data.clinic[0].branch_id
              );
            }
            localStorage.setItem("user_id", res.data.data.user_id);
            // localStorage.setItem(
            //   "branch_id",
            //   res.data.data.clinic[0].branch_id
            // );
            navigate(routes_clinic_user_dashboard);
          }
          if (
            res.data.data.role_names[0] === "CLINIC_USER" &&
            !res.data.data.clinic[0].branch_id
          ) {
            localStorage.setItem("clinic_id", res.data.data.clinic[0].user_id);
            if (res.data.data.clinic[0].branch_id) {
              localStorage.setItem(
                "branch_id",
                res.data.data.clinic[0].branch_id
              );
            }
            localStorage.setItem("user_id", res.data.data.user_id);
            navigate(routes_clinic_user_dashboard);
          }
          // clinic admin route
          if (res.data.data.role_names[0] === "CLINIC_MASTER_ADMIN") {
            localStorage.setItem("clinic_id", res.data.data.user_id);
            localStorage.setItem(
              "main_branch_id",
              res.data.data.main_branch_response[0].branch_id
            );
            navigate(routes_clinic_admin_dashboard);
          }
          if (
            res.data.data.role_names[0] === "CLINIC_ADMIN" &&
            res.data.data.clinic[0].branch_id
          ) {
            localStorage.setItem("user_id", res.data.data.user_id);
            localStorage.setItem("clinic_id", res.data.data.clinic[0].user_id);
            if (res.data.data.clinic[0].branch_id) {
              localStorage.setItem(
                "branch_id",
                res.data.data.clinic[0].branch_id
              );
            }
            // localStorage.setItem(
            //   "branch_id",
            //   res.data.data.clinic[0].branch_id
            // );
            navigate(routes_clinic_admin_dashboard);
          }
          if (
            res.data.data.role_names[0] === "CLINIC_ADMIN" &&
            !res.data.data.clinic[0].branch_id
          ) {
            localStorage.setItem("user_id", res.data.data.user_id);
            localStorage.setItem("clinic_id", res.data.data.clinic[0].user_id);
            if (res.data.data.clinic[0].branch_id) {
              localStorage.setItem(
                "branch_id",
                res.data.data.clinic[0].branch_id
              );
            }
            navigate(routes_clinic_admin_dashboard);
          }
          // super admin route
          if (res.data.data.role_names[0] === "SUPER_ADMIN") {
            localStorage.setItem("super_admin_id", res.data.data.user_id);
            navigate(routes_super_admin_dashboard);
          }
          if (res.headers.authorization) {
            const config = {
              headers: {
                Authorization: `Bearer ${res.headers.authorization}`,
              },
            };
            axios
              .get(`${API_BASE_URL}/api/v1/static/rolesBySuperAdmin`, config)
              .then((response) => {
                const sub_user_roles = response.data.data;
                for (
                  let length_of_sub_user_roles = 0;
                  length_of_sub_user_roles <= sub_user_roles.length;
                  length_of_sub_user_roles++
                ) {
                  if (
                    res.data.data.role_names[0] ===
                    sub_user_roles[length_of_sub_user_roles].name
                  ) {
                    localStorage.setItem("sub_user_id", res.data.data.user_id);
                    navigate(routes_super_admin_dashboard);
                  }
                }
              });
          }
          message.success("Successfully Logged in !");
        }
        if (rememberMe) {
          Cookies.set("rememberMe", true, { expires: 30 }); // Store the preference for 30 days
        } else {
          Cookies.remove("rememberMe");
        }
        form.resetFields();
        setspinner(false);
      })
      .catch((err) => {
        setspinner(false);
        message.error(err.response?.data.data.errors);
        seterrorStatusCode(err.response?.data.data.errors);
        setloginPendingPage(true);
      });
  };

  // Runs after user enter the new password in the Modal which comes after login with temp password given by ivory support
  const handleResetPassword = async (values) => {
    const payload = {
      password: values.confirm_password,
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .post(
        `${API_BASE_URL}/api/v1/auth/change-password?email=${values.email}`,
        payload,
        config
      )
      .then((res) => {
        setIsOpenResetPasswordModal(false);
        reset_form.resetFields();
        message.success(res.data.data.message);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const handleNavigateToSignup = () => {
    navigate(routes_patientsignup);
  };

  const handleNavigateToAdminSignup = () => {
    navigate(routes_adminsignup);
  };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  const handlePasswordOnChange = (e) => {
    const value = e.target.value;
    const repetitionRegex = /(.)\1{4,}/;
    if (repetitionRegex.test(value)) {
      setError("Password must not contain repeated characters or sequences.");
    } else {
      setError("");
      setPassword(value);
    }
  };

  const handleKeyPress = (e) => {
    const value = e.key;
    const lastChar = password.charAt(password.length - 1);
    const secondLastChar = password.charAt(password.length - 2);
    const thirdLastChar = password.charAt(password.length - 3);
    const fourthLastChar = password.charAt(password.length - 4);
    if (
      lastChar &&
      secondLastChar &&
      thirdLastChar &&
      value === lastChar &&
      value === secondLastChar &&
      value === thirdLastChar &&
      value === fourthLastChar
    ) {
      e.preventDefault();
      setError("Password must not contain repeated characters or sequences.");
    }
  };

  const validatePassword = (_, value) => {
    if (/(\w)\1{3,}/.test(value)) {
      return Promise.reject(
        new Error("Password should not have more than 3 sequence characters")
      );
    }
    return Promise.resolve();
  };

  // const validatePasswords = (_, value) => {
  //   const { name, password } = form.getFieldsValue(["name", "password"]);
  //   if (value.includes(name) || value.includes(password)) {
  //     return Promise.reject(
  //       new Error(
  //         "Password must not contain username or parts of the full name"
  //       )
  //     );
  //   }
  //   return Promise.resolve();
  // };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const rememberMePreference = Cookies.get("rememberMe");
    if (rememberMePreference === "true") {
      setRememberMe(true);
    }
  }, []);

  return (
    <Spin
      indicator={antIcon}
      size="large"
      spinning={spinner && true}
      delay={500}
    >
      <Row className="login-page-main-row">
        <Helmet>
          <title>Ivory Login</title>
        </Helmet>
        <Col className="ivory-logo-container" span={14} sm={14} xs={24}>
          <Row className="logo-description">
            <img
              data-testid="Ivory-logo"
              className="Ivory-logo-img-tag"
              src={Ivorylogo}
              alt=""
            />
            <span className="description">
              Your Healthiest Smile Begins Here!
            </span>
          </Row>
        </Col>
        <Col className="login-form-container" span={10} sm={10} xs={24}>
          <Row className="login-form">
            {loginPendingPage ? (
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5vh",
                  height: "80vh",
                  width: "100%",
                }}
              >
                <Row
                  style={{
                    padding: "40px 40px 0px 60px",
                    background: "#F8F8F8",
                    width: "34.96vw",
                    height: "40.68vh",
                    borderRadius: "20px",
                  }}
                >
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "400px",
                        lineHeight: "46.88px",
                        color: "#2CA3FA",
                      }}
                    >
                      Hello!
                    </span>
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "400px",
                        lineHeight: "46.88px",
                        color: "#2CA3FA",
                      }}
                    >
                      {userName}
                    </span>
                  </Row>
                  <Row>
                    <span
                      style={{
                        fontSize: "24px",
                        fontWeight: "500px",
                        lineHeight: "28.88px",
                      }}
                    >
                      {errorStatusCode}
                    </span>
                  </Row>
                  <Row>
                    <span
                      style={{
                        fontSize: "24px",
                        fontWeight: "500px",
                        lineHeight: "28.88px",
                      }}
                    >
                      Thank you for choosing us.
                    </span>
                  </Row>
                </Row>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "39px",
                    background: "#2ca3fa",
                    fontWeight: "500px",
                    fontSize: "16px",
                  }}
                  type="primary"
                  onClick={() => {
                    setloginPendingPage(false);
                  }}
                >
                  Go Back to Login
                </Button>
              </Row>
            ) : (
              <Row className="login-details-filling-row-tag">
                <Form
                  name="basic"
                  form={form}
                  className="login-details-filling-form"
                  initialValues={{
                    remember: true,
                  }}
                  autoComplete="off"
                  onFinish={handleSubmitLoginDetails}
                >
                  <Row className="login-to-youracnt-row-text">
                    <span className="loginto-text-span-tag">Login</span>
                    <span className="loginto-text-span-tag-two">
                      Please provide your login details to access your Ivory AI
                      account.
                    </span>
                  </Row>
                  <Row className="login-form-username">
                    <span className="username-text-span-tag">Username</span>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your username!",
                        },
                        { whitespace: true, message: "No white spaces" },
                        {
                          min: 4,
                          message: "Please enter minimum 4 characters",
                        },
                        {
                          max: 200,
                          message: "Please enter miximum 200 characters",
                        },
                        {
                          pattern:
                            /^[A-Za-z][A-Za-z0-9!@#$%^&*()-_+=<>?/.,;:'"\\[\]{}|]*$/,
                          message:
                            "First character should be a letter and it won't allow white spaces",
                        },
                      ]}
                    >
                      <Input
                        data-testid="user-name-input"
                        label="name"
                        className="patient-signin-input"
                        maxLength={201}
                        onKeyPress={handleUserNameKeyPress}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="login-form-password">
                    <span className="password-text-span-tag">Password</span>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                        {
                          max: 30,
                          message: "Please enter miximum 30 characters only",
                        },
                        { whitespace: true, message: "No white spaces" },
                        {
                          pattern:
                            /^[A-Z](?=.*[A-Za-z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@#$!%*?&_]{7,}$/,
                          message:
                            "Password must have first character capital, mix of alphanumerics and @#$%^&*_",
                        },
                        { validator: validatePassword },
                        // { validator: validatePasswords },
                      ]}
                    >
                      <Input.Password
                        label="password"
                        data-testid="password-input"
                        className="patient-signin-input"
                        onChange={(e) => {
                          handlePasswordOnChange(e);
                        }}
                        onKeyPress={(e) => {
                          handleKeyPress(e);
                        }}
                        onKeyDown={handleKeyDown}
                      />
                    </Form.Item>
                  </Row>

                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    style={{ width: "100%" }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Col className="remember-me-checkbox-col-tag">
                        <Checkbox
                          data-testid="remember-me-check-box"
                          className="remeber-me-checkbox-tag"
                          type="checkbox"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        >
                          Remember me
                        </Checkbox>
                      </Col>
                      <Col>
                        <span
                          className="forgot-password-span-tag"
                          onClick={() => {
                            navigate(routes_forgot_password);
                          }}
                        >
                          <u>Forgot Password?</u>
                        </span>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item className="login-btn-form-item-tag">
                    <Button
                      className="login-button-tag"
                      type="primary"
                      data-testid="login-submit-btn"
                      htmlType="submit"
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
                <Row className="horizontal-line-break">
                  <img
                    data-testid="hr-line"
                    className="hr-line"
                    src={HorizontalLine}
                    alt=""
                  />
                  <span className="line-break">or</span>
                  <img
                    data-testid="hr-line"
                    className="hr-line"
                    src={HorizontalLine}
                    alt=""
                  />
                </Row>
                <Row className="new-user-links">
                  <Row className="new-patient">
                    <span> New Patient? </span>
                    <span
                      className="sign-up-link"
                      onClick={handleNavigateToSignup}
                    >
                      Sign Up Here!
                    </span>
                  </Row>
                  <Row className="new-clinic">
                    <span> New Clinic? </span>
                    <span
                      className="join-network-link"
                      onClick={handleNavigateToAdminSignup}
                    >
                      Join Our Network!
                    </span>
                  </Row>
                </Row>
              </Row>
            )}
          </Row>
        </Col>

        {/* Modal for reset password */}
        <Modal
          centered
          open={isOpenResetPasswordModal}
          footer={false}
          closable={false}
        >
          <Row
            style={{
              minHeight: "55vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form
              name="basic"
              form={reset_form}
              className="login-details-filling-form"
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              onFinish={handleResetPassword}
            >
              <Row
                style={{
                  margin: "0 0 20px 0",
                }}
              >
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: 40,
                    color: "#2CA3FA",
                  }}
                >
                  Reset Password
                </span>
              </Row>
              <div
                style={{
                  padding: 30,
                  background: "#F1FAFF",
                  borderRadius: 5,
                }}
              >
                <span className="username-text-span-tag">Email</span>
                <Form.Item
                  style={{ margin: "0px" }}
                  name="email"
                  rules={[
                    { required: true, message: "Enter email" },
                    {
                      type: "email",
                      message: "Enter valid email address",
                    },
                  ]}
                >
                  <Input label="name" />
                </Form.Item>
              </div>

              <div
                style={{
                  padding: 30,
                  background: "#F1FAFF",
                  borderRadius: 5,
                  margin: "7px 0 0 0",
                }}
              >
                <div
                  className="patient-signup-input-row"
                  style={{ padding: " 0 0 3.89vh 0" }}
                >
                  <Row style={{ width: "70%" }}>
                    <span className="patient-signup-input-title">
                      Password
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  </Row>
                  <Form.Item
                    style={{
                      margin: "0px",
                    }}
                    name="password"
                    rules={[
                      {
                        min: 8,
                        message: "Password must be atleast 8 characters",
                      },
                      { whitespace: true, message: "No white spaces" },
                      { required: true, message: "Enter Password" },
                      {
                        max: 30,
                        message: "Please enter miximum 30 characters only",
                      },
                      {
                        pattern:
                          /^[A-Z](?=.*[A-Za-z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@#$!%*?&_]{7,}$/,
                        message:
                          "Password must contain first letter capital and at least one alphanumeric character and a symbol from @#$%^&*",
                      },
                      { validator: validatePassword },
                      // { validator: validatePasswords },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      name="password"
                      maxLength={31}
                      onChange={(e) => {
                        handlePasswordOnChange(e);
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  </Form.Item>
                </div>
                <div className="patient-signup-input-row">
                  <Row style={{ width: "70%" }}>
                    <span className="patient-signup-input-title">
                      Confirm Password
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  </Row>
                  <Form.Item
                    style={{
                      margin: "0px",
                    }}
                    name="confirm_password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        min: 8,
                        message: "Password must be atleast 8 characters",
                      },
                      { whitespace: true, message: "No white spaces" },
                      { required: true, message: "Re-enter Password" },
                      {
                        pattern:
                          /^[A-Z](?=.*[A-Za-z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@#$!%*?&_]{7,}$/,
                        message:
                          "Password must contain first letter capital and at least one alphanumeric character and a symbol from @#$%^&*",
                      },

                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password name="confirm_password" maxLength={30} />
                  </Form.Item>
                </div>
              </div>

              <Form.Item>
                <Button
                  style={{ borderRadius: "", margin: "27px 0 0 0" }}
                  type="primary"
                  htmlType="submit"
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </Modal>
      </Row>
    </Spin>
    // <div
    //   style={{
    //     width: "100vw",
    //     height: "100vh",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    // >
    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       height: "40vh",
    //       width: "40vw",
    //       background: "#F4F4F4",
    //       borderRadius: "10px",
    //     }}
    //   >
    //     <span style={{ fontSize: "5vh", fontWeight: "500" }}>
    //       Under Maintenance
    //     </span>
    //   </div>
    // </div>
  );
};

export default PatientLogin;
