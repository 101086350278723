import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Select } from "antd";
import axios from "axios";
import API_BASE_URL from "../../../../../env";

const ClinicTreatmentsModal = (props) => {
  const {
    handleAddNewTreatmentType,
    clinicDetails,
    addTreatmentModal,
    setaddTreatmentModal,
    form,
    type,
    item,
    handleGetTreatmentsTypes,
    editTreatmentModal,
    seteditTreatmentModal,
  } = props;
  const Token = localStorage.getItem("Token");
  const [treatments, setTreatments] = useState();
  const [searchValue, setSearchValue] = useState("");
  const treatmentTypePattern = /^[a-zA-Z0-9 ]*$/;

  const handleSearchTreatments = async (value) => {
    if (value) {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios
        .get(
          `${API_BASE_URL}/api/v1/treatments/treatments/search?letter=${value}`,
          config
        )
        .then((res) => {
          setTreatments(
            res.data.data.map((item) => ({
              value: item.value,
              // label: item.value,
            }))
          );
        });
    } else {
      setTreatments([]);
    }
  };

  useEffect(() => {
    handleSearchTreatments(searchValue);
  }, [searchValue]);

  const [selectedValue, setSelectedValue] = useState([]);

  const handleChange = (value) => {
    // Only allow one value to be selected
    if (value.length > 1) {
      value = [value[value.length - 1]];
    }
    setSelectedValue(value);
  };

  const clinic_id = localStorage.getItem("clinic_id");

  const handleEditTreatmentType = async (values) => {
    const payload = {
      clinic_id: clinic_id,
      treatment_name: values.treatment_name,
      description: values.description,
      status: "ACTIVE",
      rate: values.cost,
      duration: values.duration,
      treatment_type: values.treatment_type,
    };

    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .put(
        `${API_BASE_URL}/api/v1/treatments/updateTreatment?treatment_id=${item.id}`,
        payload,
        config
      )
      .then((res) => {
        seteditTreatmentModal(false);
        message.success("Treatment Type updated Successfully");
        handleGetTreatmentsTypes();
        form.resetFields();
      })
      .catch((err) => {
        handleGetTreatmentsTypes();
        message.error(err.response.data.data.errors);
      });
  };

  return (
    <Modal
      width={"39.584vw"}
      centered
      open={type === "edit" ? editTreatmentModal : addTreatmentModal}
      destroyOnClose={true}
      footer={false}
      closable={false}
    >
      <Form
        form={form}
        initialValues={{
          remember: true,
        }}
        onFinish={
          type === "edit" ? handleEditTreatmentType : handleAddNewTreatmentType
        }
        autoComplete="off"
        className="w-100"
      >
        <div style={{ padding: "1.0417vw" }}>
          <Row>
            <span
              style={{
                color: "#3498DB",
                fontSize: "1.25vw",
                fontWeight: 500,
              }}
            >
              {type === "edit"
                ? "Edit Dental Treatment"
                : "Add Dental Treatment"}
            </span>
          </Row>
          <Row>
            <span
              style={{
                color: "#6F6F6F",
                fontSize: "0.73vw",
                fontWeight: 400,
              }}
            >
              {type === "edit"
                ? "Update details for dental treatment."
                : "Enter details for a new dental treatment."}
            </span>
          </Row>
          {/* treatment info */}
          <Row style={{ margin: "1.563vw 0 0 0" }}>
            <span
              style={{
                color: "#333333",
                fontSize: "0.834vw",
                fontWeight: 700,
              }}
            >
              Treatment info
            </span>
          </Row>
          {clinicDetails && (
            <Row className="d-flex jc-between" style={{ margin: "1.25vw 0" }}>
              <Col span={12}>
                <Row style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Treatment Type <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  {item && item.treatment_type ? (
                    <Form.Item
                      style={{ width: "100%" }}
                      name="treatment_type"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Description",
                        },
                        {
                          pattern: /^[a-zA-Z0-9 ]*$/,
                          message: "Special characters are not allowed",
                        },
                      ]}
                      initialValue={item.treatment_type}
                    >
                      <Input
                        placeholder="Enter treatment Type"
                        style={{
                          color: "black",
                          width: "16.771vw",
                          margin: "5px 0 0 0",
                          height: 40,
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      style={{ width: "100%" }}
                      name="treatment_type"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Description",
                        },
                        {
                          pattern: /^[a-zA-Z0-9 ]*$/,
                          message: "Special characters are not allowed",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter treatment Type"
                        style={{
                          color: "black",
                          width: "16.771vw",
                          margin: "5px 0 0 0",
                          height: 40,
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                        }}
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col>
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.73vw",
                      fontWeight: 400,
                      color: "#2F2F2F",
                    }}
                  >
                    Treatment Name <span style={{ color: "#FF0000" }}>*</span>
                  </span>
                  {item && item.treatment_name ? (
                    <Form.Item
                      style={{ width: "100%" }}
                      name="treatment_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Description",
                        },
                        {
                          pattern: /^[a-zA-Z0-9 ]*$/,
                          message: "Special characters are not allowed",
                        },
                      ]}
                      initialValue={item.treatment_name}
                      className="treatment-name-select"
                    >
                      <AutoComplete
                        onSearch={setSearchValue}
                        bordered={false}
                        style={{
                          color: "black",
                          width: "16.771vw",
                          margin: "5px 0 0 0",
                          height: 40,
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          display: "flex",
                          alignItems: "center",
                        }}
                        options={treatments}
                        placeholder="Search to Select"
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      style={{ width: "100%" }}
                      name="treatment_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Description",
                        },
                        {
                          pattern: /^[a-zA-Z0-9 ]*$/,
                          message: "Special characters are not allowed",
                        },
                      ]}
                      className="treatment-name-select"
                    >
                      <AutoComplete
                        onSearch={setSearchValue}
                        bordered={false}
                        style={{
                          color: "black",
                          width: "16.771vw",
                          margin: "5px 0 0 0",
                          height: 40,
                          borderRadius: 3,
                          border: "1px solid #DADADA",
                          display: "flex",
                          alignItems: "center",
                        }}
                        options={treatments}
                        placeholder="Search to Select"
                      />
                    </Form.Item>
                  )}
                </Row>
              </Col>
            </Row>
          )}
          <Row className="d-flex jc-between">
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <span
                style={{
                  fontSize: "0.73vw",
                  fontWeight: 400,
                  color: "#2F2F2F",
                }}
              >
                Description <span style={{ color: "#FF0000" }}>*</span>
              </span>
              {item && item.description ? (
                <Form.Item
                  style={{ width: "100%" }}
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Description",
                    },
                  ]}
                  initialValue={item.description}
                >
                  <TextArea
                    placeholder="Enter Description here"
                    bordered={false}
                    style={{
                      height: "5.2084vw",
                      width: "100%",
                      background: "#FFFFFF",
                      border: "1px solid #E3E3E3",
                    }}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  style={{ width: "100%" }}
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Enter Description here"
                    bordered={false}
                    style={{
                      height: "5.2084vw",
                      width: "100%",
                      background: "#FFFFFF",
                      border: "1px solid #E3E3E3",
                    }}
                  />
                </Form.Item>
              )}
            </Row>
          </Row>
          {/* Cost and duration */}
          <Row style={{ margin: "1.563vw 0 0 0" }}>
            <span
              style={{
                color: "#333333",
                fontSize: "0.834vw",
                fontWeight: 700,
              }}
            >
              Cost & Duration
            </span>
          </Row>
          <Row style={{ margin: "1.25vw 0" }}>
            <Col span={12}>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Cost <span style={{ color: "#FF0000" }}>*</span>
                </span>
                {item && item.rate ? (
                  <Form.Item
                    name="cost"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Cost",
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    ]}
                    initialValue={item.rate}
                  >
                    <Input
                      placeholder="Enter the Cost"
                      suffix="₹"
                      style={{
                        width: "16.771vw",
                        margin: "0.26vw 0 0 0",
                        height: "2.0834vw",
                        borderRadius: "0.15vw",
                        border: "1px solid #DADADA",
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="cost"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Cost",
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter the Cost"
                      suffix="₹"
                      style={{
                        width: "16.771vw",
                        margin: "0.26vw 0 0 0",
                        height: "2.0834vw",
                        borderRadius: "0.15vw",
                        border: "1px solid #DADADA",
                      }}
                    />
                  </Form.Item>
                )}
              </Row>
            </Col>
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Row style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.73vw",
                    fontWeight: 400,
                    color: "#2F2F2F",
                  }}
                >
                  Duration <span style={{ color: "#FF0000" }}>*</span>
                </span>
                {item && item.duration ? (
                  <Form.Item
                    name="duration"
                    rules={[
                      { required: true, message: "Please enter Duration" },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    ]}
                    initialValue={item.duration}
                  >
                    <Input
                      suffix="Hours"
                      placeholder="Enter the Duration"
                      style={{
                        width: "16.771vw",
                        margin: "0.26vw 0 0 0",
                        height: "2.0834vw",
                        borderRadius: "0.15vw",
                        border: "1px solid #DADADA",
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="duration"
                    rules={[
                      { required: true, message: "Please enter Duration" },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    ]}
                  >
                    <Input
                      suffix="Hours"
                      placeholder="Enter the Duration"
                      style={{
                        width: "16.771vw",
                        margin: "0.26vw 0 0 0",
                        height: "2.0834vw",
                        borderRadius: "0.15vw",
                        border: "1px solid #DADADA",
                      }}
                    />
                  </Form.Item>
                )}
              </Row>
            </Col>
          </Row>
          <Row
            className="d-flex jc-end ai-center"
            style={{ gap: 12, margin: "1.25vw 0 0 0" }}
          >
            <Button
              className="cancel-btn"
              onClick={() => {
                setaddTreatmentModal(false);
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                height: "1.67vw",
                border: "1px solid #2CA3FA",
                borderRadius: "1.563vw",
                color: "#2CA3FA",
                background: "#FFF",
                fontSize: "0.73vw",
                fontWeight: 600,
              }}
              htmlType="submit"
            >
              Add
            </Button>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ClinicTreatmentsModal;
