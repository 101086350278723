import React, { useEffect, useRef } from "react";
// import { UserAuth } from "../../../../../../../context_api/ContextApi";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { UserAuth } from "../../../../../../context_api/ContextApi";

const MLimage = ({ xrayImageUrl, report_image }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const { rectangles, reportRefresh } = UserAuth();
  const canvasRef = useRef(null);

  useEffect(() => {
    // handleAddColorToApiRes();
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Load the image
    const image = new Image();
    image.src = xrayImageUrl;

    image.onload = () => {
      // Set the canvas dimensions to match the image size
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image on the canvas
      ctx.drawImage(image, 0, 0);

      // Draw the rectangles
      rectangles.forEach((rect) => {
        const rectWidth = rect.xmax - rect.xmin;
        const rectHeight = rect.ymax - rect.ymin;
        if (rect.name === "dental caries") {
          ctx.strokeStyle = "#01A5E4";
        }
        if (rect.name === "restorations") {
          ctx.strokeStyle = "#8DD7C0";
        }
        if (rect.name === "bone loss") {
          ctx.strokeStyle = "#FF96C6";
        }
        if (rect.name === "prosthetics") {
          ctx.strokeStyle = "#FE5766";
        }
        if (rect.name === "periapical abnormality") {
          ctx.strokeStyle = "#FFBF65";
        }
        if (rect.name === "root stump") {
          ctx.strokeStyle = "#FB6238";
        }
        if (rect.name === "absent_tooth") {
          ctx.strokeStyle = "#FFD871";
        }
        if (rect.name === "fracture") {
          ctx.strokeStyle = "#F2D4CC";
        }
        if (rect.name === "other") {
          ctx.strokeStyle = "#E77477";
        }
        if (rect.name === "microdontia") {
          ctx.strokeStyle = "#6C89C5";
        }
        if (rect.name === "macrodontia") {
          ctx.strokeStyle = "#C05780";
        }
        if (rect.name === "position") {
          ctx.strokeStyle = "#FF828A";
        }
        if (rect.name === "altered morphology") {
          ctx.strokeStyle = "#E8C583";
        }
        if (rect.name === "impacted_tooth") {
          ctx.strokeStyle = "#00B1BB";
        }
        if (rect.name === "implants") {
          ctx.strokeStyle = "#00B1BB";
        }
        if (rect.name === "attrition") {
          ctx.strokeStyle = "#CFF800";
        }
        ctx.lineWidth = 2;
        ctx.strokeRect(rect.xmin, rect.ymin, rectWidth, rectHeight);
      });
    };
  }, [rectangles, xrayImageUrl]);

  return (
    <Spin
      indicator={antIcon}
      size="large"
      spinning={reportRefresh && true}
      delay={500}
    >
      <canvas
        width={400}
        style={{
          width: "100%",
          borderRadius: "5px",
        }}
        height={400}
        ref={canvasRef}
      />
    </Spin>
  );
};

export default MLimage;
