// routes
export const routes_clinic_user_home = "/clinic-user";
export const routes_clinic_user_dashboard = "/clinic-user/dashboard";
export const routes_clinic_user_patients = "/clinic-user/patients";
export const routes_clinic_user_message = "/clinic-user/message";
export const routes_clinic_user_calendar = "/clinic-user/calendar";
export const routes_clinic_user_appointments = "/clinic-user/appointments";
export const routes_clinic_user_add_patient = "/clinic-user/add-patient";
export const routes_clinic_user_view_patient =
  "/clinic-user/patient-profile/:patient_id";
export const routes_clinic_user_view_patient_by_id =
  "/clinic-user/view-patient-by-id";
export const routes_clinic_user_my_account = "/clinic-user/my-account";
export const routes_clinic_user_personal_profile =
  "/clinic-user/my-account/:id";

// tabs
export const clinic_user_tabs_dashboard = "Dashboard";
export const clinic_user_tabs_patients = "Patients";
export const clinic_user_tabs_message = "Time off Requests"
export const clinic_user_tabs_calendar = "Calendar"
export const clinic_user_tabs_appointments = "Appointments";
