// routes
export const routes_patientsignup = "/patient-signup";
export const routes_signup_thanks_message = "/signup-thanks-message";
export const routes_patientlogin = "/login";
export const routes_forgot_password = "/forgot-password";
export const routes_reset_password = "/reset-password";
export const routes_home = "/patient";
export const routes_dashboard = "/patient/dashboard";
export const routes_myaccount = "/patient/my-account";
export const routes_mlanalysis = "/patient/ml-analysis";
export const routes_clinics = "/patient/clinics";
export const routes_surveys = "/patient/surveys";
export const routes_appointments = "/patient/appointments";
export const routes_articles = "/patient/articles";
export const routes_account_settings = "/patient/account-settings";
export const routes_notifications = "/patient/notifications";
export const routes_help = "/patient/help";
export const routes_book_appointment = "/patient/book-appointment";

// tabs
export const tabs_dashboard = "Dashboard";
export const tabs_myaccount = "My Account";
export const tabs_mlanalysis = "Reports";
export const tabs_clinics = "Clinics";
export const tabs_surveys = "Surveys";
export const tabs_appointments = "Appointments";
export const tabs_articles = "Articles";
export const tabs_account = "Account Settings"
