import React, { useState } from "react";
import { useNavigate } from "react-router";
import { routes_super_admin_surveys } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import addicon from "../../../../../assets/addicon.png";
import { Form, Input, Button, Space, message, Row, Col, Spin } from "antd";
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import API_BASE_URL from "../../../../../env";

const CreateSurvey = () => {
  const navigate = useNavigate();
  const Token = localStorage.getItem("Token");
  const [spinner, setSpinner] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  const [form] = Form.useForm();
  const [questions, setQuestions] = useState([{ question: "", options: [""] }]);

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: "", options: [""] }]);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleAddOption = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.push("");
    setQuestions(updatedQuestions);
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(updatedQuestions);
  };

  const handleCreateSurvey = (values) => {
    setSpinner(true);
    let payload;
    payload = {
      text: values.survey_title,
      survey_questions: values.questions.map((item, index) => ({
        text: item.question,
        survey_question_type: {
          name: "RADIO_BUTTON",
        },
        mandatory: true,
        randomize_options: true,
        survey_answers: values.questions[index].options.map((item) => ({
          text: item.label,
          answer_order: 0,
          answered: true,
        })),
        question_order: 0,
      })),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .post(`${API_BASE_URL}/api/v1/survey/v1/addSurvey`, payload, config)
      .then((res) => {
        form.resetFields();
        navigate(routes_super_admin_surveys);
        setSpinner(false);
        message.success("Survey created successfully!");
      })
      .catch((err) => {
        setSpinner(false);
        message.err("error occured");
      });
  };

  return (
    <div className="my-account-main-div">
      <Spin
        indicator={antIcon}
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <Row
          className="d-flex ai-center jc-between"
          style={{ margin: "12px 23px" }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Create New Survey
          </span>
        </Row>
        <div
          style={{
            margin: "0 23px",
            background: "#FFF",
            padding: "23px",
            // maxHeight: "75vh",
            // overflow: "auto",
          }}
        >
          <Form form={form} onFinish={handleCreateSurvey}>
            <Row className="w-100 d-flex" style={{ gap: 20 }}>
              <Col>
                <span
                  style={{
                    color: "#525252",
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                >
                  Enter Survey Title :
                </span>
              </Col>
              <Col>
                <Form.Item
                  name={["survey_title"]}
                  label={`Survey Title`}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a survey title",
                    },
                  ]}
                >
                  <Input placeholder="Survey Title" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="w-100">
              <Form.Item>
                <Button
                  icon={
                    <img
                      alt=""
                      src={addicon}
                      style={{ width: 25, height: 25 }}
                    />
                  }
                  onClick={handleAddQuestion}
                  style={{
                    margin: "36px 0 0 0",
                    borderRadius: 6,
                    background: "#F4F4F4",
                    fontSize: 16,
                    fontWeight: 400,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 7,
                    height: 50,
                  }}
                >
                  Add Question
                </Button>
              </Form.Item>
            </Row>
            <Row
              className="w-100"
              style={{
                maxHeight: "55vh",
                overflow: "auto",
              }}
            >
              {questions.map((question, questionIndex) => (
                <div
                  className="d-flex jc-center"
                  style={{
                    margin: "30px 30px 0 30px",
                    width: "100%",
                    borderRadius: 6,
                    background: "#F4F4F4",
                    padding: "40px",
                  }}
                >
                  <Space
                    key={questionIndex}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 8,
                    }}
                  >
                    <Row style={{ display: "flex", gap: 8 }}>
                      <Form.Item
                        name={["questions", questionIndex, "question"]}
                        label={`Question ${questionIndex + 1}`}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a question",
                          },
                        ]}
                      >
                        <Input style={{ width: "70vw" }} />
                      </Form.Item>
                      <Button
                        type="danger"
                        icon={<MinusCircleOutlined />}
                        onClick={() => handleRemoveQuestion(questionIndex)}
                      />
                    </Row>
                    {question.options.map((option, optionIndex) => (
                      <Space
                        key={optionIndex}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form.Item
                          name={[
                            "questions",
                            questionIndex,
                            "options",
                            optionIndex,
                            "label",
                          ]}
                          label={`Option ${optionIndex + 1}`}
                          rules={[
                            {
                              required: true,
                              message: "Please enter an option",
                            },
                          ]}
                        >
                          <Input style={{ width: "70vw" }} />
                        </Form.Item>
                        <Button
                          type="danger"
                          icon={<MinusCircleOutlined />}
                          onClick={() =>
                            handleRemoveOption(questionIndex, optionIndex)
                          }
                        />
                      </Space>
                    ))}
                    <Button
                      style={{
                        color: "#2CA3FA",
                        fontSize: 14,
                        fontWeight: 600,
                        borderRadius: 30,
                        border: "1px solid #009DF7",
                      }}
                      icon={<PlusCircleOutlined />}
                      onClick={() => handleAddOption(questionIndex)}
                    >
                      Add Option
                    </Button>
                  </Space>
                </div>
              ))}
            </Row>
            {questions && questions.length > 0 && (
              <Row
                className="w-100 d-flex jc-center"
                style={{ gap: 30, margin: "30px 0 0 0" }}
              >
                <Button
                  onClick={() => navigate(routes_super_admin_surveys)}
                  style={{
                    color: "#F7927E",
                    fontSize: 14,
                    fontWeight: 600,
                    borderRadius: 30,
                    border: "1px solid #F7927E",
                    background: "#FFF6F2",
                  }}
                >
                  Cancel
                </Button>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      color: "#2CA3FA",
                      fontSize: 14,
                      fontWeight: 600,
                      borderRadius: 30,
                      border: "1px solid #009DF7",
                      background: "#E0F2FF",
                    }}
                  >
                    Save Survey
                  </Button>
                </Form.Item>
                <Button
                  onClick={() => form.resetFields()}
                  style={{
                    color: "#2CA3FA",
                    fontSize: 14,
                    fontWeight: 600,
                    borderRadius: 30,
                    border: "1px solid #009DF7",
                  }}
                >
                  Clear
                </Button>
              </Row>
            )}
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default CreateSurvey;
