import { Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import StaffProfilePicture from "../../admin_staff/staff_details/StaffProfilePicture";
import { useNavigate } from "react-router";
import XraysModal from "./xrays_modal/XraysModal";
import ReportsModal from "./reports_modal/ReportsModal";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../string";
import API_BASE_URL from '../../../../../env'

const AdminDashboardPatients = (props) => {
  const Token = localStorage.getItem("Token");
  const { patientsdata } = props;
  const navigate = useNavigate();
  const [IsOpenFullXrayModal, setIsOpenFullXrayModal] = useState(false);
  const [IsOpenFullReportModal, setIsOpenFullReportModal] = useState(false);
  const [xraysAndReports, setXraysAndReports] = useState();

  const handleGetXrayAndReportDetails = async (patientID) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/report?patient_id=${patientID}`,
        config
      )
      .then((res) => {
        setXraysAndReports(res.data.data);
      });
  };
  const s3Url =
    patientsdata &&
    patientsdata.profile_picture_url &&
    patientsdata.profile_picture_url.split(".com/")[1];

  // code for displaying the s3 images
  const [fileContent, setFileContent] = useState("");
  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: s3Url,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [s3Url]);

  return (
    <div>
      <Row
        style={{
          background: "#FBFBFB",
          padding: "15px",
          margin: "5px 0 0 0",
        }}
      >
        <Col
          sm={4}
          xl={6}
          xxl={4}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              height: "48px",
              width: "48px",
              borderRadius: "18px",
            }}
          >
            <StaffProfilePicture id={patientsdata.id} pro={fileContent} />
          </div>
        </Col>
        <Col
          sm={10}
          xl={8}
          xxl={10}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#2CA3FA",
            }}
          >
            <u
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/clinic-admin/patient-profile/${patientsdata.user_id}`
                )
              }
            >
              {patientsdata.name}
            </u>
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "19px",
              color: "#000000",
            }}
          >
            PID#{" "}
            <span style={{ color: "#7D7D7D" }}>{patientsdata.user_id}</span>
          </span>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          span={5}
        >
          <span
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#2CA3FA",
            }}
          >
            <u
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsOpenFullXrayModal(true);
                handleGetXrayAndReportDetails(patientsdata.user_id);
              }}
            >
              X-Ray
            </u>
          </span>
          <XraysModal
            IsOpenFullXrayModal={IsOpenFullXrayModal}
            setIsOpenFullXrayModal={setIsOpenFullXrayModal}
            xraysAndReports={xraysAndReports}
            setXraysAndReports={setXraysAndReports}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          span={5}
        >
          <span
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#2CA3FA",
            }}
          >
            <u
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsOpenFullReportModal(true);
                handleGetXrayAndReportDetails();
              }}
            >
              Reports
            </u>
          </span>
          <ReportsModal
            IsOpenFullReportModal={IsOpenFullReportModal}
            setIsOpenFullReportModal={setIsOpenFullReportModal}
            xraysAndReports={xraysAndReports}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboardPatients;
