import React, { useEffect, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './ClinicUserCalendar.css'
import StaffCalendar from './staff_calendar/StaffCalendar';
import axios from 'axios';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import API_BASE_URL from '../../../../env';

const ClinicUserCalendar = () => {
    const clinic_id = localStorage.getItem("clinic_id");
    const staff_id = localStorage.getItem("user_id");
    const Token = localStorage.getItem("Token");
    const [googleEvents, setGoogleEvents] = useState([]);
    const [customEvents, setCustomEvents] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [timeoffReqs, setTimeoffReqs] = useState([]);
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getStaffCalendarEvents("month_year");
    }, []);

    useEffect(() => {
        if (googleEvents.length > 0) {
            setAllCalendarEvents();
        }
    }, [googleEvents]);

    const getStaffCalendarEvents = async (type) => {
        setIsLoading(true);
        let config = {
            headers: {
                Authorization: `Bearer ${Token}`,
            },
        };
        try {
            const res = await axios.get(`${API_BASE_URL}/api/v1/staff_calendar/${type}/clinic/${clinic_id}/${staff_id}`, config);
            setGoogleEvents(res.data.data[0].events_time_off_response.google_event_response);
            setCustomEvents(res.data.data[0].events_time_off_response.custom_event_response);
            setTimeoffReqs(res.data.data[0].events_time_off_response.time_off_response);
            setAppointments(res.data.data[0].appointment_response_list);
        } catch (err) {
            message.error(err);
        }
    }

    const setAllCalendarEvents = () => {
        const allGoogleEvents = googleEvents.map(element => {
            const originalDate = element.event_date;
            const parsedDate = new Date(originalDate);

            const startTime = new Date(
                parsedDate.getFullYear(),
                parsedDate.getMonth(),
                parsedDate.getDate(),
                0,
                0
            );

            const endTime = new Date(
                parsedDate.getFullYear(),
                parsedDate.getMonth(),
                parsedDate.getDate(),
                23,
                59
            );

            return {
                title: 'Holiday',
                start: startTime,
                end: endTime,
                desc: element.event_name
            };
        });

        const allAppointments = appointments?.map(element => {
            const fromTime = element.slots.from_time;
            const toTime = element.slots.to_time;
            const parsedFromTime = new Date(fromTime);
            const parsedToTime = new Date(toTime);

            const startTime = new Date(
                parsedFromTime.getFullYear(),
                parsedFromTime.getMonth(),
                parsedFromTime.getDate(),
                parsedFromTime.getHours(),
                parsedFromTime.getMinutes()
            );

            const endTime = new Date(
                parsedToTime.getFullYear(),
                parsedToTime.getMonth(),
                parsedToTime.getDate(),
                parsedToTime.getHours(),
                parsedToTime.getMinutes()
            );

            return {
                title: element.patient_name,
                start: startTime,
                end: endTime,
                status: element.slots.status.name,
                avatar: element.profile_picture_url,
            };
        });

        const timeoffRequests = timeoffReqs?.map(element => {
            const fromTime = element.from_time;
            const toTime = element.to_time;
            const parsedFromTime = new Date(fromTime);
            const parsedToTime = new Date(toTime);

            const startTime = new Date(
                parsedFromTime.getFullYear(),
                parsedFromTime.getMonth(),
                parsedFromTime.getDate(),
                parsedFromTime.getHours(),
                parsedFromTime.getMinutes()
            );

            const endTime = new Date(
                parsedToTime.getFullYear(),
                parsedToTime.getMonth(),
                parsedToTime.getDate(),
                parsedToTime.getHours(),
                parsedToTime.getMinutes()
            );

            return {
                title: 'On Leave',
                start: startTime,
                end: endTime,
            };
        });

        const allCustomEvents = customEvents?.map(element => {
            const originalDate = element.event_date;
            const parsedDate = new Date(originalDate);

            const startTime = new Date(
                parsedDate.getFullYear(),
                parsedDate.getMonth(),
                parsedDate.getDate(),
                0,
                0
            );

            const endTime = new Date(
                parsedDate.getFullYear(),
                parsedDate.getMonth(),
                parsedDate.getDate(),
                23,
                59
            );

            return {
                title: 'Holiday',
                start: startTime,
                end: endTime,
                desc: element.event_name
            };
        });

        setEvents([...allGoogleEvents, ...allAppointments, ...timeoffRequests, ...allCustomEvents]);
        setIsLoading(false);
    }

    return (
        <Spin
            indicator={
                <LoadingOutlined
                    style={{ fontSize: "2.6045vw" }}
                    spin
                />
            }
            size="large"
            spinning={isLoading && true}
            delay={500}
            className='loader'
        >
            <div className='clinic-user-calendar'>
                <div className='cal-data'>
                    <span className='calendar-header'>Appointment Calendar</span>
                    <span className='calendar-desc'>
                        Effortlessly manage appointments, view availability, and stay organized with our intuitive appointment calendar.
                    </span>
                </div>
                {!isLoading && <StaffCalendar events={events} />}
            </div>
        </Spin>
    );
};

export default ClinicUserCalendar;