import React from "react";
import { Row } from "antd";

function Articles() {
  return (
    <div className="clinics-page-main-div">
      <Row
        className="d-flex ai-center"
        style={{
          height: "6.564102564102564vh",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            fontSize: 22,
            margin: "0 0 0 22px",
            color: "#525252",
          }}
        >
          Articles
        </span>
      </Row>
      <Row
        className="d-flex jc-center"
        style={{
          margin: "0 22px",
          padding: "50px 0",
          background: "#FFF",
        }}
      >
        <span
          style={{
            fontWeight: 600,
            fontSize: 20,
            margin: "0 0 0 22px",
            color: "#525252",
          }}
        >
          Not available
        </span>
        {/* <div className="">
          <h1>Cavity surgery</h1>
          <h2>This Treatment costs 2500</h2>
          <Button className="App-link" onClick={displayRazorpay}>
            Pay ₹2500
          </Button>
        </div> */}
      </Row>
    </div>
  );
}

export default Articles;
