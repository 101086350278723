import { Button, Col, Input, Spin, Row, Select, Space, Form, message, Image, Upload, Modal, notification } from "antd";
import React, { useState } from "react";
import sendmail from "../../../../../assets/send-mail.png";
import axios from "axios";
import uploadIcon from "../../../../../assets/upload.png";
import successLogo from "../../../../../assets/reset-password-success.png";
import { options } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import sendInviteLogo from "../../../../../assets/send-invite.png";
import inviteSuccessLogo from "../../../../../assets/invitation-success.png"
import { FilePdfOutlined, LoadingOutlined } from "@ant-design/icons";
import API_BASE_URL from "../../../../../env";
import "./RegisterNewClinic.css";
import { Document, Page } from 'react-pdf';
import Icon from "@ant-design/icons/lib/components/Icon";

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const RegisterNewClinic = () => {
  const Token = localStorage.getItem("Token");
  const [form] = Form.useForm();
  const [sendEmailToClinic, setSendEmailToClinic] = useState();
  const [spinner, setSpinner] = useState(false);
  const [userName, setUserName] = useState("");
  const [ifsc, setIfsc] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [openClinicRegModal, setClinicRegModal] = useState(false);
  const [openSendInviteModal, setSendInviteModal] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isSendClicked, setSendClicked] = useState(false);

  const handleAdminSignup = async () => {
    if (fileList.length >= 1) {
      setSpinner(true);
      const values = await form.validateFields();
      const finalFormData = { ...form, ...values };
      delete finalFormData.__INTERNAL__;
      delete finalFormData._init;
      const additionalData = {
        country: values.country ? values.country : "India",
        phone_country_code: values.phone_country_code ? values.phone_country_code : "+91",
        is_registor_by: "superAdmin",
        coordinate: {
          latitude: "",
          longitude: "",
        },
        profile_picture_url: "",
        password: "",
        referred_by: "",
        address_line_two: "",
        terms_accepted: true,
      };
      Object.assign(finalFormData, additionalData);
      const payload = finalFormData;

      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      await axios
        .post(`${API_BASE_URL}/api/v1/clinic`, payload, config)
        .then(async (res) => {
          const id = res.data.data["clinic_id"];
          if (id && fileList) {
            await uploadFile(id, fileList[0].originFileObj);
          }
          setClinicRegModal(true);
          setSpinner(false);
          setTimeout(() => {
            setFileList([]);
            form.resetFields();
          }, 1000);
        }).catch(
          (error) => {
            setSpinner(false);
            message.error(error.response.data.data.errors)
          }
        );
    } else {
      message.warning("Please upload a document")
    }
    // const payload = {
    //   name: values.ClinicName,
    //   email: values.Email,
    //   username: values.Username,
    //   password: "",
    //   phone_country_code: values.Code ? values.Code : "+91",
    //   is_registor_by: "superAdmin",
    //   phone_number: values.Phone,
    //   address_line_one: values.Address,
    //   address_line_two: values.Apartment,
    //   city: values.City,
    //   province: values.State,
    //   country: values.Country,
    //   zip_code: values.Zip,
    //   coordinate: {
    //     latitude: 17,
    //     longitude: 78,
    //   },
    //   profile_picture_url: "",
    // };
  };

  const uploadFile = async (id, file) => {
    let formData = new FormData();
    formData.append("file", file);
    await axios
      .post(`${API_BASE_URL}/api/v1/clinic/${id}/upload-certifications`, formData)
      .then((res) => { })
  }

  const handleInvitingClinicToRegister = async () => {
    setSendClicked(true);
    const payload = {};
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/clinic-invite?email=${sendEmailToClinic}`,
        payload,
        config
      )
      .then((res) => {
        setSendClicked(false);
        setSendInviteModal(false);
        openNotification('bottom', 'success');
      });
  };

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter 6-digit zip code!");
  };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handleIFSCChange = (event) => {
    const value = event.target.value.toUpperCase();
    setIfsc(value);
    form.setFieldsValue({ ifsc_code: value });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (file.type === 'application/pdf') {
      const pdfPreview = (
        <Document
          file={file.preview}
          onLoadSuccess={() => console.log('PDF loaded successfully')}
          onLoadError={() => console.log('PDF loading failed')}
        >
          <Page pageNumber={1} />
        </Document>
      );

      // Render the PDF preview
      return (
        <div style={{ width: '100%', height: '500px' }}>
          {pdfPreview}
        </div>
      );
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = async ({ fileList: newFile }) => {
    if (newFile.length > 0) {
      const fileType = newFile[0].type;
      const fileSize = newFile[0].size;
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      const maxSize = 4 * 1024 * 1024; // 4MB

      if (allowedTypes.includes(fileType) && fileSize < maxSize) {
        setFileList(newFile);
        if (newFile[0].status == "error" || newFile[0].status == "done") {
          message.success("File uploaded successfully");
        }
      } else {
        message.warning('Please select a JPEG, PNG, or PDF file with a size less than 4MB.');
      }
    } else {
      setFileList(newFile);
    }

  };

  const openNotification = (placement, type) => {
    api[type]({
      message: `Invitation Sent Successfully`,
      description:
        'Your invitation to register has been successfully sent',
      placement,
      style: {
        width: "28.8548vw",
      },
      // duration: null,
    });
  };

  return (
    <div style={{
      height: "73vh"
    }}>
      {contextHolder}
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: "2.6045vw", margin: "5.2084vw 0 0 0", }}
            spin
          />
        }
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <Button className="invite-button"
          onClick={() => setSendInviteModal(true)}>Send Invitation
        </Button>
        <Form onFinish={handleAdminSignup} form={form} className="new-clinic-form">
          <Row
            className="form-row-tag"
          >
            <Col
              span={14}
              className="new-clinic-form-col"
            >
              <Row className="new-clinic-form-row">
                <Row style={{
                  width: "100%"
                }}>
                  <span className="form-header">Clinic Information</span>
                </Row>
                <Row className="input-fields-container">
                  <Row className="input-fields-row">
                    <Col className="form-field-col">
                      <span
                        className="form-label"
                      >
                        Clinic Name <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter clinic name",
                          },
                          {
                            min: 3,
                            message: "Name must be at least 3 characters long",
                          },
                          {
                            max: 100,
                            message:
                              "Clinic Name must be no more than 100 characters long",
                          },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and Clinic Name must contain only alphabeticals",
                          },
                          { whitespace: true, message: "No white spaces" },
                        ]}
                      >
                        <Input
                          className="input-field"
                          maxLength={101}
                          // placeholder="Dental Care Clinic"
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            const inputValue = e.target.value;
                            if (
                              !(
                                (charCode >= 65 && charCode <= 90) ||
                                (charCode >= 97 && charCode <= 122) ||
                                (charCode === 32 && inputValue.trim() !== "")
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="form-field-col">
                      <span className="form-label">
                        Contact Person <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="contact_person"
                        rules={[
                          { required: true, message: "Please enter Account Name" },
                          {
                            pattern: /^[a-zA-Z ]+$/,
                            message: "Account Name must be letters and spaces only."
                          }
                        ]}
                      >
                        <Input
                          data-testid="jest-input-username"
                          maxLength={101}
                          label="contact-person"
                          className="input-field"
                          onKeyPress={handleUserNameKeyPress}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="form-field-col">
                      <span className="form-label">
                        User Name <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Username!",
                          },
                          {
                            min: 4,
                            message: "Please enter minimum 4 characters",
                          },
                          { whitespace: true, message: "No white spaces" },
                          {
                            pattern: /^[a-zA-Z0-9._]+$/,
                            message:
                              "Username can only contain letters, numbers, fullstops, and underscores.",
                          },
                          {
                            max: 100,
                            message:
                              "Username must be no more than 100 characters long",
                          },
                        ]}
                      >
                        <Input
                          data-testid="jest-input-username"
                          label="Username"
                          className="input-field"
                          maxLength={101}
                          onChange={handleUserNameChange}
                          onKeyPress={handleUserNameKeyPress}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="input-fields-row">
                    <Col className="form-field-col">
                      <span
                        className="form-label"
                      >
                        Email <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: "Please enter email" },
                          {
                            type: "email",
                            message: "Please enter a valid email address",
                          },
                          {
                            max: 320,
                            message:
                              "Email address must be no more than 320 characters long",
                          },
                        ]}
                      >
                        <Input
                          className="input-field"
                          maxLength={321}
                        // placeholder="doctor@dentalcareclinic.com"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="form-field-col">
                      <span
                        className="form-label"
                      >
                        Phone No. <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Space.Compact
                        style={{
                          width: "14.5834vw",
                        }}
                      >
                        <Form.Item name="phone_country_code" className="country-code">
                          <Select
                            bordered={false}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "2.0834vw",
                              background: "#FFFFFF",
                              border: "1px solid #E3E3E3",
                              margin: 0,
                            }}
                            defaultValue="+91"
                            options={options}
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ width: "100%" }}
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter phone number",
                            },
                            { validator: validatePhoneNumber },
                          ]}
                        >
                          <Input
                            bordered={false}
                            style={{
                              height: "2.0834vw",
                              background: "#FFFFFF",
                              borderTop: "1px solid #E3E3E3",
                              borderRight: "1px solid #E3E3E3",
                              borderBottom: "1px solid #E3E3E3",
                              margin: 0,
                            }}
                            onKeyPress={(e) => {
                              const charCode = e.which || e.keyCode;
                              if (!(charCode >= 48 && charCode <= 57)) {
                                e.preventDefault();
                              }
                            }}
                            defaultValue=""
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Col>
                  </Row>
                </Row>
              </Row>
              <Row className="new-clinic-form-row">
                <Row style={{
                  width: "100%"
                }}>
                  <span className="form-header">Address Details</span>
                </Row>
                <Row className="input-fields-container">
                  <Row className="input-fields-row">
                    <Col className="form-field-col">
                      <span
                        className="form-label"
                      >
                        Street Address <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="address_line_one"
                        rules={[
                          { required: true, message: "Please enter address" },
                        ]}>
                        <Input
                          className="input-field"
                        // placeholder="Address"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="form-field-col">
                      <span
                        className="form-label"
                      >
                        City <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="city"
                        rules={[
                          { required: true, message: "Please enter city" },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and City must contain only alphabeticals",
                          },
                        ]}
                      >
                        <Input
                          className="input-field"
                          // placeholder="City"
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            const inputValue = e.target.value;
                            if (
                              !(
                                (charCode >= 65 && charCode <= 90) ||
                                (charCode >= 97 && charCode <= 122) ||
                                (charCode === 32 && inputValue.trim() !== "")
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="form-field-col">
                      <span
                        className="form-label"
                      >
                        State <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="province"
                        rules={[
                          { required: true, message: "Please enter state/province" },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and State/Province must contain only alphabeticals",
                          },
                        ]}
                      >
                        <Input
                          className="input-field"
                          // placeholder="State"
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            const inputValue = e.target.value;
                            if (
                              !(
                                (charCode >= 65 && charCode <= 90) ||
                                (charCode >= 97 && charCode <= 122) ||
                                (charCode === 32 && inputValue.trim() !== "")
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="input-fields-row">
                    <Col className="form-field-col">
                      <span
                        className="form-label"
                      >
                        Country
                      </span>
                      <Form.Item name="country">
                        <Input
                          className="input-field"
                        // placeholder="Country"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="form-field-col">
                      <span
                        className="form-label"
                      >
                        ZIP Code <span style={{ color: "#FF0000" }}>*</span>
                      </span>
                      <Form.Item
                        name="zip_code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Zip code",
                          },
                          { validator: validateZipCode },
                        ]}
                      >
                        <Input
                          data-testid="jest-input-zip"
                          label="Zip"
                          className="input-field"
                          maxLength={30}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Row>
              </Row>
              <Row className="new-clinic-form-row">
                <Row style={{
                  width: "100%"
                }}>
                  <span className="form-header">Bank Details</span>
                </Row>
                <Row className="input-fields-container">
                  <Row className="input-fields-row">
                    <Col className="form-field-col">
                      <span className="form-label">
                        Account Name <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="account_name"
                        rules={[
                          { required: true, message: "Please enter Account Name" },
                          {
                            pattern: /^[a-zA-Z ]+$/,
                            message: "Account Name must be letters and spaces only."
                          }
                        ]}
                      >
                        <Input
                          data-testid="jest-input-account"
                          label="Account-Name"
                          className="input-field"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="form-field-col">
                      <span className="form-label">
                        Account No. <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="account_no"
                        rules={[
                          { required: true, message: "Please enter Account No" },
                          {
                            min: 8,
                            message: "Please enter a valid account number",
                          },
                          { whitespace: true, message: "No white spaces" },
                          {
                            pattern: /^[0-9]+$/,
                            message:
                              "Account number can only contain numbers.",
                          },

                        ]}
                      >
                        <Input
                          data-testid="jest-input-account"
                          label="Account-No"
                          className="input-field"
                          maxLength={16}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="form-field-col">
                      <span className="form-label">
                        Confirm Account No. <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="confirm_account_no"
                        dependencies={["account_no"]}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm account no.",
                          },

                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("account_no") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The account numbers does not match!"
                                )
                              );
                            },
                          })
                        ]}
                      >
                        <Input
                          data-testid="jest-input-account"
                          label="Confirm-Account_no"
                          className="input-field"
                          maxLength={16}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="input-fields-row">
                    <Col className="form-field-col">
                      <span className="form-label">
                        IFSC Code <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="ifsc_code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter IFSC code.",
                          },
                          {
                            min: 8,
                            message: "Please enter minimum 8 characters",
                          },
                          { whitespace: true, message: "No white spaces" },
                          {
                            pattern: /^[a-zA-Z0-9]+$/,
                            message:
                              "IFSC code can only contain letters, numbers.",
                          }
                        ]}
                      >
                        <Input
                          data-testid="jest-input-account"
                          label="ifsc-code"
                          className="input-field"
                          maxLength={11}
                          value={ifsc}
                          onChange={handleIFSCChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Row>


              </Row>
              <Row className="d-flex jc-center" style={{ gap: 10, width: "55vw", marginTop: "-1.15vw" }}>
                <Button
                  htmlType="submit"
                  className="register-button"
                >
                  Register
                </Button>
              </Row>
            </Col>
            <Col span={10} style={{
              gap: "0.73vw"
            }}>
              <span className="upload-certificate">
                Upload Certificate
              </span>
              <div className="upload-certificate-container">
                <Upload.Dragger
                  className={fileList.length >= 1 ? 'upload-area-disabled' : 'upload-area'}
                  fileList={fileList}
                  listType="picture-card"
                  onChange={handleChange}
                  onPreview={handlePreview}
                  showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
                >
                  <Row className="upload-row-tag">
                    <img className="upload-icon" src={uploadIcon} alt="" />
                    <span className="drag-text">Drag your file here.</span>
                  </Row>
                  <span style={{
                    fontSize: " 0.72916vw"
                  }}>Or</span>
                  <Row className="upload-row-tag">
                    <Button className="upload-button">
                      Upload
                    </Button>
                    <span className="document-type">Supported document formats: PNG, JPG, and PDF. Maximum file size: 4 MB.</span>
                  </Row>
                </Upload.Dragger>
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      // afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Form>
        <Modal
          centered
          width={"32.813vw"}
          open={openClinicRegModal}
          footer={false}
          destroyOnClose={true}
          closable={false}
        >
          <div className="clinic-reg-success-modal">
            <Row style={{
              paddingBottom: "1.563vw"
            }}>
              <img src={successLogo} alt="" style={{
                width: "5.417vw",
                height: "10.8vh"
              }} />
            </Row>
            <Row style={{
              paddingBottom: "1.0417vw"
            }}>
              <span style={{
                fontSize: "1.0417vw",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "140.04%" /* 28.008px */
              }}>Clinic Registration Completed!</span>
            </Row>
            <Row style={{
              paddingBottom: "1.8vw"
            }}>
              <span style={{
                fontSize: "0.834vw",
                fontWeight: 400,
                fontStyle: "normal",
                textAlign: "center",
                lineHeight: "157.3%"
              }}>
                The clinic registration process has been successfully completed. A link has been sent to the Clinic's email address for password generation.
              </span>
            </Row>
            <Row>
              <Button className="close-button"
                onClick={() => { setClinicRegModal(false) }}>
                Close
              </Button>
            </Row>
          </div>

        </Modal>

        <Modal
          centered
          width={"46.25vw"}
          open={openSendInviteModal}
          footer={false}
          destroyOnClose={true}
          closable={true}
          onCancel={() => {
            setSendInviteModal(false);
          }}
        >
          <div className="send-invite-modal">
            <Col span={18} style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.872vw",
              padding: "2.396vw 2.0834vw"
            }}>
              <Row style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.52vw"
              }}>
                <span style={{
                  fontSize: "1.0417vw",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal"
                }}>Send an invite to the Clinic to Register!</span>
                <span style={{
                  fontSize: "0.834vw",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal"
                }}>Enter email address below to send invitations.</span>
              </Row>
              <Row style={{
                display: "flex",
                gap: "0.834vw"
              }}>
                <Input className="email-input"
                  onChange={(e) => setSendEmailToClinic(e.target.value)}></Input>
                <Button className="send-button"
                  onClick={handleInvitingClinicToRegister}
                  disabled={isSendClicked}>Send</Button>
              </Row>
            </Col>
            <Col span={6}>
              <img src={sendInviteLogo} className="send-invite-logo" />
            </Col>
          </div>
        </Modal>
      </Spin>
    </div >

  );
};

export default RegisterNewClinic;
