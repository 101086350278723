// routes
export const routes_adminsignup = "/admin-signup";
export const routes_clinic_admin_home = "/clinic-admin";
export const routes_clinic_admin_dashboard = "/clinic-admin/dashboard";
export const routes_clinic_admin_ml_analysis = "/clinic-admin/ml-analysis";
export const routes_clinic_admin_patients = "/clinic-admin/patients";
export const routes_clinic_admin_staff = "/clinic-admin/staff";
export const routes_clinic_appointments = "/clinic-admin/appointments";
export const routes_ading_new_staff = "/clinic-admin/adding-new-staff";
export const routes_clinic_admin_add_patient = "/clinic-admin/add-patient";
export const routes_clinic_admin_view_patient =
  "/clinic-admin/patient-profile/:patient_id";
export const routes_clinic_admin_add_admin = "/clinic-admin/add-admin";
export const routes_clinic_admin_administrator_profile =
  "/clinic-admin/administrator-profile/:id";
export const routes_clinic_admin_personal_profile =
  "/clinic-admin/my-account/:id";
export const routes_clinic_my_account = "/clinic-admin/clinic-profile";
export const routes_clinic_master_admin_add_clinic_branch =
  "/clinic-admin/add-clinic-branch";
export const routes_clinic_master_admin_edit_clinic_branch =
  "/clinic-admin/edit-clinic-branch/:branch_id";

// tabs

export const tabs_admin_dashboard = "Dashboard";
export const tabs_admin_mlanalysis = "ML Analysis";
export const tabs_admin_patients = "Patients";
export const tabs_admin_staff = "Staff";
export const tabs_admin_appointments = "Appointments";

export const phonecode1 = [
  { country: "+93" },
  { country: "+213" },
  { country: "+54" },
  { country: "+61" },
  { country: "+43" },
  { country: "+880" },
  { country: "+32" },
  { country: "+55" },
  { country: "+359" },
  { country: "+1" },
  { country: "+56" },
  { country: "+86" },
  { country: "+57" },
  { country: "+506" },
  { country: "+385" },
  { country: "+357" },
  { country: "+420" },
  { country: "+45" },
  { country: "+20" },
  { country: "+358" },
  { country: "+33" },
  { country: "+49" },
  { country: "+30" },
  { country: "+504" },
  { country: "+852" },
  { country: "+36" },
  { country: "+354" },
  { country: "+91" },
  { country: "+62" },
  { country: "+964" },
  { country: "+353" },
  { country: "+972" },
  { country: "+39" },
  { country: "+1-876" },
  { country: "+81" },
  { country: "+962" },
  { country: "+254" },
  { country: "+82" },
  { country: "+965" },
  { country: "+856" },
  { country: "+961" },
  { country: "+60" },
  { country: "+52" },
  { country: "+212" },
  { country: "+95" },
  { country: "+977" },
  { country: "+31" },
  { country: "+64" },
  { country: " +234" },
  { country: "+47" },
];

export const phonecode = [
  {
    value: "+93",
    label: "+93",
  },
  {
    value: "+213",
    label: "+213",
  },
  {
    value: "+54",
    label: "+54",
  },
  {
    value: "+61",
    label: "+61",
  },
  {
    value: "+43",
    label: "+43",
  },
  {
    value: "+880",
    label: "+880",
  },
  {
    value: "+32",
    label: "+32",
  },
  {
    value: "+55",
    label: "+55",
  },
  {
    value: "+359",
    label: "+359",
  },
  {
    value: "+1",
    label: "+1",
  },
  {
    value: "+56",
    label: "+56",
  },
  {
    value: "+86",
    label: "+86",
  },
  {
    value: "+57",
    label: "+57",
  },
  {
    value: "+506",
    label: "+506",
  },
  {
    value: "+385",
    label: "+385",
  },
  {
    value: "+357",
    label: "+357",
  },
  {
    value: "+420",
    label: "+420",
  },
  {
    value: "+45",
    label: "+45",
  },
  {
    value: "+20",
    label: "+20",
  },
  {
    value: "+358",
    label: "+358",
  },
  {
    value: "+33",
    label: "+33",
  },
  {
    value: "+49",
    label: "+49",
  },
  {
    value: "+30",
    label: "+30",
  },
  {
    value: "+504",
    label: "+504",
  },
  {
    value: "+852",
    label: "+852",
  },
  {
    value: "+36",
    label: "+36",
  },
  {
    value: "+354",
    label: "+354",
  },
  {
    value: "+91",
    label: "+91",
  },
  {
    value: "+62",
    label: "+62",
  },
  {
    value: "+964",
    label: "+964",
  },
  {
    value: "+353",
    label: "+353",
  },
  {
    value: "+972",
    label: "+972",
  },
  {
    value: "+39",
    label: "+39",
  },
  {
    value: "+1-876",
    label: "+1-876",
  },
  {
    value: "+81",
    label: "+81",
  },
  {
    value: "+962",
    label: "+962",
  },
  {
    value: "+254",
    label: "+254",
  },
  {
    value: "+82",
    label: "+82",
  },
  {
    value: "+965",
    label: "+965",
  },
  {
    value: "+856",
    label: "+856",
  },
  {
    value: "+961",
    label: "+961",
  },
  {
    value: "+60",
    label: "+60",
  },
  {
    value: "+52",
    label: "+52",
  },
  {
    value: "+212",
    label: "+212",
  },
  {
    value: "+95",
    label: "+95",
  },
  {
    value: "+977",
    label: "+977",
  },
  {
    value: "+31",
    label: "+31",
  },
  {
    value: "+64",
    label: "+64",
  },
  {
    value: " +234",
    label: " +234",
  },
  {
    value: "+47",
    label: "+47",
  },
];

export const yearsMap = [
  { year: "0" },
  { year: "1" },
  { year: "2" },
  { year: "3" },
  { year: "4" },
  { year: "5" },
  { year: "6" },
  { year: "7" },
  { year: "8" },
  { year: "9" },
  { year: "10" },
  { year: "11" },
  { year: "12" },
  { year: "13" },
  { year: "14" },
  { year: "15" },
  { year: "16" },
  { year: "17" },
  { year: "18" },
  { year: "19" },
  { year: "20" },
  { year: "21" },
  { year: "22" },
  { year: "23" },
  { year: "24" },
  { year: "25" },
  { year: "26" },
  { year: "27" },
  { year: "28" },
  { year: "29" },
  { year: "30" },
  { year: "31" },
  { year: "32" },
  { year: "33" },
  { year: "34" },
  { year: "35" },
  { year: "36" },
  { year: "37" },
  { year: "38" },
  { year: "39" },
  { year: "40" },
];

export const monthsMap = [
  { year: "1" },
  { year: "2" },
  { year: "3" },
  { year: "4" },
  { year: "5" },
  { year: "6" },
  { year: "7" },
  { year: "8" },
  { year: "9" },
  { year: "10" },
  { year: "11" },
  { year: "12" },
];
