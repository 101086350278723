import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button, Input, Calendar, message } from "antd";
import axios from "axios";
import { Helmet } from "react-helmet";
import AddStaffIcon from "../../../../assets/plus-icon-green-col.png";
import { useNavigate } from "react-router";
import {
  routes_ading_new_staff,
  routes_clinic_admin_add_patient,
  routes_clinic_admin_patients,
  routes_clinic_admin_staff,
  routes_clinic_appointments,
  tabs_admin_appointments,
  tabs_admin_patients,
  tabs_admin_staff,
} from "../../../../utils/clinic_admin_constants/ClinicAdminConstants";
import PatientListIcon from "../../../../assets/patients-list-icon.png";
import StaffListIcon from "../../../../assets/staff-list-icon.png";
import AppointmentsListIcon from "../../../../assets/appointment-list-icon.png";
import SheduleAppointmentListIcon from "../../../../assets/shedule-appointment-icon.png";
import PatientDisplayIcon from "../../../../assets/patient-displaying-icon.png";
import StaffDisplayingIcon from "../../../../assets/staff-displaying-icon.png";
import AppointmentsDisplayingListIcon from "../../../../assets/appointments-displaying-icon.png";
import moment from "moment";
import { UserAuth } from "../../../../context_api/ContextApi";
import { DATETIME_FORMAT_WITH_FULL_TIME } from "../../../../utils/appointment_constants/AppointmentConstants";
import AdminDashboardPatients from "./clinic_admin_xrays_and_reports/AdminDashboardPatients";
import StaffProfilePicture from "./StaffProfilePicture";
import API_BASE_URL from "../../../../env";

const ClinicAdminDashboard = () => {
  const navigate = useNavigate();
  const clinic_id = localStorage.getItem("clinic_id");
  const [form] = Form.useForm();
  const Token = localStorage.getItem("Token");
  const branch_id = localStorage.getItem("main_branch_id") ? localStorage.getItem("main_branch_id") : localStorage.getItem("branch_id") ;
  const [staff, setStaff] = useState();
  const [patients, setPatients] = useState();
  const [appointments, setAppointments] = useState();
  const [todaysAppointments, setTodaysAppointments] = useState();
  const { setAdminTabActiveKey } = UserAuth();
  const defaultValue = moment();

  const handleGetAllAdminDetails = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (branch_id) {
      await axios
        .get(
          `${API_BASE_URL}/api/v1/admin/${clinic_id}/adminDashboard?branch_id=${
            branch_id && branch_id
          }`,
          config
        )
        .then((res) => {
          setStaff(res.data.data.staff);
          setPatients(res.data.data.patients);
          setAppointments(res.data.data.appointments);
          setTodaysAppointments(res.data.data.today_appointments);
        });
    } else {
      await axios
        .get(`${API_BASE_URL}/api/v1/admin/${clinic_id}/adminDashboard`, config)
        .then((res) => {
          setStaff(res.data.data.staff);
          setPatients(res.data.data.patients);
          setAppointments(res.data.data.appointments);
          setTodaysAppointments(res.data.data.today_appointments);
        });
    }
  };

  const handleSchedulingAppointments = (values) => {
    const selected_date = moment(values.date._d).format(
      DATETIME_FORMAT_WITH_FULL_TIME
    );
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/appointment/specific?clinic_id=${clinic_id}&from_date=${selected_date}`,
        config
      )
      .then((res) => {
        // setFilterAppointmentsByDate(res.data.data);
        const resp = res.data.data;
        if (resp && resp.length > 0) {
          navigate(routes_clinic_appointments);
          setAdminTabActiveKey(tabs_admin_appointments);
          form.resetFields();
        } else if (resp && resp.length === 0) {
          message.error("No Appointments for selected date");
        }
      });
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const handleNavigateToPatientPage = () => {
    navigate(routes_clinic_admin_patients);
    setAdminTabActiveKey(tabs_admin_patients);
  };

  const handleNavigateToAddPatientPage = () => {
    navigate(routes_clinic_admin_add_patient);
    setAdminTabActiveKey(tabs_admin_patients);
  };

  const handleNavigateToAddStaffPage = () => {
    navigate(routes_ading_new_staff);
    setAdminTabActiveKey(tabs_admin_staff);
  };
  const handleNavigateToStaffPage = () => {
    navigate(routes_clinic_admin_staff);
    setAdminTabActiveKey(tabs_admin_staff);
  };

  const handleNavigateToAppointmentsPage = () => {
    navigate(routes_clinic_appointments);
    setAdminTabActiveKey(tabs_admin_appointments);
  };

  useEffect(() => {
    handleGetAllAdminDetails();
  }, []);

  return (
    <div>
      <Form
        onFinish={handleSchedulingAppointments}
        form={form}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        className="my-account-main-div"
      >
        <Helmet>
          <title>Clinic Admin Dashboard</title>
        </Helmet>
        <Row className="d-flex ai-center" style={{ margin: "12px 23px" }}>
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Admin Dashboard
          </span>
        </Row>
        {/* <Spin indicator={antIcon} size="large" spinning={false} delay={500}> */}
        <Row style={{ padding: "0 23px" }} className="d-flex">
          {/* Patient List Col */}
          <Col xs={24} sm={24} md={12} xl={6}>
            <div
              style={{ padding: "10px" }}
              className="profile-pic-col-main-div"
            >
              <Row>
                <Col span={18}>
                  <Row>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#525252",
                      }}
                    >
                      Patients
                    </span>
                  </Row>
                  <Row>
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "300",
                        color: "#525252",
                      }}
                    >
                      {patients && patients.length}
                    </span>
                  </Row>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={PatientListIcon}
                    alt=""
                    style={{ height: "80px" }}
                  />
                </Col>
              </Row>
            </div>
          </Col>

          {/* Staff Col */}
          <Col xs={24} sm={24} md={12} xl={6}>
            <div
              style={{ padding: "10px" }}
              className="profile-pic-col-main-div"
            >
              <Row>
                <Col span={18}>
                  <Row>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#525252",
                      }}
                    >
                      Staff
                    </span>
                  </Row>
                  <Row>
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "300",
                        color: "#525252",
                      }}
                    >
                      {staff && staff.length}
                    </span>
                  </Row>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={StaffListIcon} alt="" style={{ height: "80px" }} />
                </Col>
              </Row>
            </div>
          </Col>

          {/* Shedule Appointment Col */}
          <Col xs={24} sm={24} md={12} xl={6}>
            <div
              style={{ padding: "10px" }}
              className="profile-pic-col-main-div"
            >
              <Row>
                <Col span={18}>
                  <Row>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#525252",
                      }}
                    >
                      Schedule Appointments
                    </span>
                  </Row>
                  <Row>
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "300",
                        color: "#525252",
                      }}
                    >
                      {appointments && appointments.length}
                    </span>
                  </Row>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={SheduleAppointmentListIcon}
                    alt=""
                    style={{ height: "80px" }}
                  />
                </Col>
              </Row>
            </div>
          </Col>

          {/*Todays Appointment List Col */}
          <Col xs={24} sm={24} md={12} xl={6}>
            <div
              style={{ padding: "10px" }}
              className="profile-pic-col-main-div"
            >
              <Row>
                <Col span={18}>
                  <Row>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#525252",
                      }}
                    >
                      Todays Appointments
                    </span>
                  </Row>
                  <Row>
                    <span
                      style={{
                        fontSize: "40px",
                        fontWeight: "300",
                        color: "#525252",
                      }}
                    >
                      {todaysAppointments &&
                        todaysAppointments.filter(
                          (todaysAppointmentsLength) =>
                            todaysAppointmentsLength.status !== "Cancelled"
                        ).length}
                    </span>
                  </Row>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={AppointmentsListIcon}
                    alt=""
                    style={{ height: "80px" }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row style={{ padding: "23px" }} className="d-flex">
          {/* Patient List Col */}
          <Col xs={24} sm={24} md={12} xl={6}>
            <div
              style={{ padding: "12px" }}
              className="profile-pic-col-main-div"
            >
              <Row>
                <Col span={15}>
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      color: "#525252",
                    }}
                  >
                    Patients
                  </span>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  span={3}
                  onClick={handleNavigateToAddPatientPage}
                >
                  <img style={{ height: "20px" }} src={AddStaffIcon} alt="" />
                  <span>
                    <u
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#525252",
                      }}
                    >
                      Add
                    </u>
                  </span>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  span={6}
                >
                  <span>
                    <u
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#2CA3FA",
                        cursor: "pointer",
                      }}
                      onClick={handleNavigateToPatientPage}
                    >
                      View All
                    </u>
                  </span>
                </Col>
              </Row>

              {patients ? (
                patients &&
                patients.slice(0, 7).map((patientsdata) => {
                  return (
                    <AdminDashboardPatients
                      key={patientsdata.user_id}
                      handleNavigateToAddPatientPage={
                        handleNavigateToAddPatientPage
                      }
                      patientsdata={patientsdata}
                      patientid={patientsdata.user_id}
                    />
                  );
                })
              ) : (
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#FBFBFB",
                    gap: "15px",
                    padding: "35px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      height: "140px",
                      width: "140px",
                      background: "#E0F2FF",
                    }}
                  >
                    <img
                      style={{ height: "44px" }}
                      src={PatientDisplayIcon}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#525252",
                      }}
                    >
                      Currently there is no data
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#525252",
                      }}
                    >
                      to display. You can add a{" "}
                      <u style={{ color: "#009DF7", fontWeight: "600" }}>
                        Patient...
                      </u>
                    </span>
                  </div>
                </Row>
              )}
            </div>
          </Col>

          {/* staff list Col */}
          <Col xs={24} sm={24} md={12} xl={6}>
            <div
              style={{ padding: "12px" }}
              className="profile-pic-col-main-div"
            >
              <Row>
                <Col span={15}>
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      color: "#525252",
                    }}
                  >
                    Staff
                  </span>
                </Col>
                <Col span={3}>
                  <span>
                    <u
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#525252",
                      }}
                      onClick={handleNavigateToAddStaffPage}
                    >
                      <img
                        style={{ height: "20px" }}
                        src={AddStaffIcon}
                        alt=""
                      />
                      Add
                    </u>
                  </span>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  span={6}
                >
                  <span>
                    <u
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#2CA3FA",
                        cursor: "pointer",
                      }}
                      onClick={handleNavigateToStaffPage}
                    >
                      View All
                    </u>
                  </span>
                </Col>
              </Row>

              {staff ? (
                staff &&
                staff.slice(0, 7).map((staffdata) => {
                  return (
                    <Row
                      style={{
                        background: "#FBFBFB",
                        padding: "15px",
                        margin: "5px 0 0 0",
                      }}
                    >
                      <Col
                        sm={4}
                        xl={6}
                        xxl={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            height: "48px",
                            width: "48px",
                            borderRadius: "18px",
                          }}
                        >
                          <StaffProfilePicture
                            id={staffdata.id}
                            pro={
                              staffdata.profile_picture_url &&
                              staffdata.profile_picture_url.split(".com/")[1]
                            }
                          />
                        </div>
                      </Col>
                      <Col
                        sm={20}
                        xl={18}
                        xxl={20}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#2CA3FA",
                          }}
                          onClick={() => {
                            navigate(
                              `/clinic-admin/administrator-profile/${staffdata.id}`
                            );
                            setAdminTabActiveKey(tabs_admin_staff);
                          }}
                        >
                          <u style={{ cursor: "pointer" }}>{staffdata.name}</u>
                        </span>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "19px",
                            color: "#000000",
                          }}
                        >
                          SID#{" "}
                          <span style={{ color: "#7D7D7D" }}>
                            {staffdata.id}
                          </span>
                        </span>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#FBFBFB",
                    gap: "15px",
                    padding: "35px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      height: "140px",
                      width: "140px",
                      background: "#E0F2FF",
                    }}
                  >
                    <img
                      style={{ height: "31.6px" }}
                      src={StaffDisplayingIcon}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#525252",
                      }}
                    >
                      Currently there is no data
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#525252",
                      }}
                    >
                      to display. You can add a{" "}
                      <u style={{ color: "#009DF7", fontWeight: "600" }}>
                        Staff...
                      </u>
                    </span>
                  </div>
                </Row>
              )}
            </div>
          </Col>

          {/* Appointments Displaying Col */}
          <Col xs={24} sm={24} md={12} xl={6}>
            <div
              style={{ padding: "12px" }}
              className="profile-pic-col-main-div"
            >
              <Row>
                <Col span={18}>
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      color: "#525252",
                    }}
                  >
                    Appointments
                  </span>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  span={6}
                >
                  <span>
                    <u
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#2CA3FA",
                        cursor: "pointer",
                      }}
                      onClick={handleNavigateToAppointmentsPage}
                    >
                      View All
                    </u>
                  </span>
                </Col>
              </Row>

              {appointments ? (
                appointments &&
                appointments.slice(0, 7).map((appointmentsdata) => {
                  return (
                    <Row
                      style={{
                        background: "#FBFBFB",
                        padding: "15px",
                        margin: "5px 0 0 0",
                      }}
                    >
                      <Col
                        sm={4}
                        xl={6}
                        xxl={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            height: "48px",
                            width: "48px",
                            borderRadius: "18px",
                          }}
                        >
                          <StaffProfilePicture
                            id={appointments.id}
                            pro={appointments.profile_picture_url}
                          />
                        </div>
                      </Col>
                      <Col
                        sm={14}
                        xl={10}
                        xxl={14}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#2CA3FA",
                          }}
                          onClick={() =>
                            navigate(
                              `/clinic-admin/patient-profile/${appointmentsdata.patient_id}`
                            )
                          }
                        >
                          <u style={{ cursor: "pointer" }}>
                            {appointmentsdata.patient_name}
                          </u>
                        </span>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "19px",
                            color: "#000000",
                          }}
                        >
                          PID#{" "}
                          <span style={{ color: "#7D7D7D" }}>
                            {appointmentsdata.id}
                          </span>
                        </span>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                        sm={6}
                        xl={4}
                        xxl={6}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            background: "#F2F2F2",
                            lineHeight: "16px",
                            borderRadius: "5px",
                            width: 91,
                            height: 46,
                            padding: "6px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#7D7D7D",
                            }}
                          >
                            Consultation
                          </span>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16px",
                              color: "#7D7D7D",
                            }}
                          >
                            {moment(appointmentsdata.from_date).format(
                              "HH:mm A"
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#FBFBFB",
                    gap: "15px",
                    padding: "35px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      height: "140px",
                      width: "140px",
                      background: "#E0F2FF",
                    }}
                  >
                    <img
                      style={{ height: "39px" }}
                      src={AppointmentsDisplayingListIcon}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#525252",
                      }}
                    >
                      Currently there is no data
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#525252",
                      }}
                    >
                      to display.
                    </span>
                  </div>
                </Row>
              )}
            </div>
          </Col>

          {/* Shedule Appointments Col */}
          <Col xs={24} sm={24} md={12} xl={6}>
            <div
              style={{ padding: "12px" }}
              className="profile-pic-col-main-div"
            >
              <Row>
                <Col span={18}>
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      color: "#525252",
                    }}
                  >
                    Schedule
                  </span>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  span={6}
                >
                  <span>
                    <u
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#2CA3FA",
                        cursor: "pointer",
                      }}
                      onClick={handleNavigateToAppointmentsPage}
                    >
                      View All
                    </u>
                  </span>
                </Col>
              </Row>
              <Row>
                <Form.Item
                  name="date"
                  rules={[{ required: true, message: "Select Date" }]}
                >
                  <Calendar
                    defaultValue={defaultValue}
                    disabledDate={disabledDate}
                    fullscreen={false}
                  />
                </Form.Item>
              </Row>
              <Row>
                <Col
                  style={{ display: "flex", justifyContent: "end" }}
                  span={23}
                >
                  <Button type="primary" htmlType="submit">
                    ok
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* </Spin> */}
      </Form>
    </div>
  );
};

export default ClinicAdminDashboard;
