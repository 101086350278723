import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../../string";

const DashboardReportThumbnail = (props) => {
  const {
    s3Url,
    setXrayArrayId,
    arrayId,
    openReportModalAndSendingImageUrl,
    setDownloadOption,
  } = props;

  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: s3Url,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [s3Url]);

  return (
    <>
      <img
        src={fileContent}
        alt=""
        style={{
          width: "81px",
          height: "56px",
          margin: window.innerWidth < 576 && "10px",
          cursor: "pointer",
          borderRadius: 5,
          display: "none",
        }}
      />
      <span
        onClick={() => {
          openReportModalAndSendingImageUrl(fileContent);
          setXrayArrayId(arrayId);
          setDownloadOption(true);
        }}
      >
        <u style={{ color: "#2CA3FA" }}>Attachments</u>
      </span>
    </>
  );
};

export default DashboardReportThumbnail;
