import { Helmet } from "react-helmet";
import {
  Row,
  Input,
  Form,
  Col,
  Button,
  Select,
  DatePicker,
  message,
  Spin,
  Space,
  Tabs,
  TimePicker,
  Menu,
  Checkbox,
  Divider,
} from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useRef, useState } from "react";
import ProfilePic from "../../../../../assets/default-profile-pic.png";
import axios from "axios";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import AWS from "aws-sdk";
import {
  monthsMap,
  yearsMap,
} from "../../../../../utils/clinic_admin_constants/ClinicAdminConstants";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../string";
import { options } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import API_BASE_URL from "../../../../../env";
import TabPane from "antd/lib/tabs/TabPane";
import Slots from "./Slots";
import AppointmentProfilePic from "../../admin_appointments/AppointmentProfilePic";

const AdminMyAccount = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const Token = localStorage.getItem("Token");
  const branch_id = localStorage.getItem("main_branch_id");
  const clinicid = localStorage.getItem("clinic_id");
  const role = localStorage.getItem("role");
  const clinic_user_id = localStorage.getItem("user_id");
  const { id, clinic_id } = useParams();
  const [form] = Form.useForm();
  const [inputsEnable, setInputsEnable] = useState(true);
  const [slotsEnable, setSlotsEnable] = useState(true);
  const [adminUserDetails, setAdminUserDetails] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [adminProfilePicUrl, setAdminProfilePicUrl] = useState();
  const [timeRange, setTimeRange] = useState([]);
  const [fileContent, setFileContent] = useState("");
  const [activeTab, setActiveTab] = useState("0");
  const [slots, setSlots] = useState();
  const today = moment();
  const [selectedDate, setselectedDate] = useState(
    today && today.format("YYYY-MM-DD")
  );

  const dateTabs = [
    { label: "Today", date: today.format("YYYY-MM-DD") },
    {
      label: "Tomorrow",
      date: today.clone().add(1, "days").format("YYYY-MM-DD"),
    },
  ];

  for (let i = 2; i < 14; i++) {
    const date = today.clone().add(i, "days");
    dateTabs.push({
      date: date.format("YYYY-MM-DD"),
      label: date.format("ddd, DD MMM"),
    });
  }

  const handleGetCreatedSlotsForDoctor = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/slots/getSlots/${clinic_user_id}?date=${selectedDate}`,
        config
      )
      .then((res) => {
        setSpinner(false);
        setSlots(res.data.data);
      })
      .catch((err) => {
        setSpinner(false);
        message.error(err.response.data.data.errors);
      });
  };

  const handleGetAdminDetails = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (role === "CLINIC_ADMIN") {
      await axios
        .get(
          `${API_BASE_URL}/api/v1/admin/${id}/clinic/${
            clinic_id ? clinic_id : clinicid
          }`,
          config
        )
        .then((res) => {
          setAdminUserDetails(res.data.data);
          setSpinner(false);
          setAdminProfilePicUrl(
            res.data.data.profile_picture_url &&
              res.data.data.profile_picture_url.split(".com/")[1]
          );
        })
        .catch((err) => {
          setSpinner(false);
          message.error(err.response.data.data.errors);
        });
    }
    if (role === "CLINIC_USER") {
      await axios
        .get(`${API_BASE_URL}/api/v1/adminStaff/${id}`, config)
        .then((res) => {
          setAdminUserDetails(res.data.data);
          setSpinner(false);
          setAdminProfilePicUrl(
            res.data.data.profile_picture_url &&
              res.data.data.profile_picture_url.split(".com/")[1]
          );
        })
        .catch((err) => {
          setSpinner(false);
          message.error(err.response.data.data.errors);
        });
    }
  };

  const handleCreateSlotsForDoctor = () => {
    setSpinner(true);
    const payload = {
      from_time: selectedDate + timeRange[0],
      to_time: selectedDate + timeRange[1],
      date: selectedDate,
      doc_id: clinic_user_id,
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .post(`${API_BASE_URL}/api/v1/slots/createSlots`, payload, config)
      .then((res) => {
        handleGetCreatedSlotsForDoctor();
        form.resetFields();
        setSpinner(false);
        setSlotsEnable(true);
        message.success(res.data.status_message);
      })
      .catch((err) => {
        setSpinner(false);
        message.error(err.response.data.data.errors);
      });
  };

  const handleUpdateAdminDetails = async (values) => {
    setSpinner(true);
    let payload = {
      name: values.name,
      username: values.username,
      email: values.email,
      phone_country_code: values.phone_country_code,
      phone_number: values.phone_number,
      gender: values.gender,
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
      qualification: values.qualification,
      total_experience: {
        years: values.years,
        months: values.months,
      },
      designation: values.designation,
      access_type: adminUserDetails.access_type_name,
      branch_id: adminUserDetails.branch_id,
      specialization: JSON.stringify(values.specialization),
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (role === "CLINIC_ADMIN") {
      await axios
        .put(
          `${API_BASE_URL}/api/v1/admin/${id}/clinic/${
            clinic_id ? clinic_id : clinicid
          }`,
          payload,
          config
        )
        .then((res) => {
          setSpinner(false);
          setInputsEnable(true);
          handleGetAdminDetails();
          message.success("Admin Updated SuccessFully");
        })
        .catch((err) => {
          setSpinner(false);
          message.error(err.response.data.data.errors);
        });
    } else if (role === "CLINIC_USER") {
      await axios
        .put(`${API_BASE_URL}/api/v1/adminStaff/${id}`, payload, config)
        .then((res) => {
          setSpinner(false);
          setInputsEnable(true);
          handleGetAdminDetails();
          message.success("User Updated SuccessFully");
        })
        .catch((err) => {
          setSpinner(false);
          message.error(err.response.data.data.errors);
        });
    }
  };

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    handleGetAdminDetails();
    handleGetCreatedSlotsForDoctor();
  }, []);

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: adminProfilePicUrl,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [adminProfilePicUrl]);

  const handleTabChange = (key) => {
    setSpinner(true);
    setActiveTab(key);
    setselectedDate(dateTabs[key].date);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/slots/getSlots/${clinic_user_id}?date=${dateTabs[key].date}`,
        config
      )
      .then((res) => {
        setSpinner(false);
        setSlots(res.data.data);
      })
      .catch((err) => {
        setSpinner(false);
        message.error(err.response.data.data.errors);
      });
  };

  const handlePrevClick = () => {
    const prevTab = parseInt(activeTab) - 1;
    if (prevTab >= 0) {
      setActiveTab(prevTab.toString());
    }
  };

  const handleNextClick = () => {
    const nextTab = parseInt(activeTab) + 1;
    if (nextTab < dateTabs.length) {
      setActiveTab(nextTab.toString());
    }
  };

  const handleTimeChange = (value) => {
    setTimeRange([
      value[0]?.format("THH:mm:ss"),
      value[1]?.format("THH:mm:ss"),
    ]);
  };

  // For uploading Profile pic
  const hiddenFilesInput = useRef(null);

  const uploadClinicUserProfilePic = async (e) => {
    setSpinner(true);
    const propic = e.target.files[0];
    let formData = new FormData();
    formData.append("file", propic);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (role === "CLINIC_USER") {
      await axios
        .post(
          `${API_BASE_URL}/api/v1/adminStaff/${id}/upload-profile-picture`,
          formData,
          config
        )
        .then((res) => {
          setSpinner(false);
          handleGetAdminDetails();
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
          setSpinner(false);
        });
    }
    if (role === "CLINIC_ADMIN") {
      await axios
        .post(
          `${API_BASE_URL}/api/v1/admin/${id}/upload-profile-picture`,
          formData,
          config
        )
        .then((res) => {
          setSpinner(false);
          handleGetAdminDetails();
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
          setSpinner(false);
        });
    }
  };

  // browse button click
  const handleProfilePicUpload = () => {
    hiddenFilesInput.current.click();
  };

  const navigate = useNavigate();

  const items = [
    {
      type: "group",
      label: (
        <Row
          style={{
            display: "flex",
            gap: "0.417vw",
          }}
        >
          {/* <AppointmentProfilePic
            pro={
              // item &&
              adminProfilePicUrl && adminProfilePicUrl
              // item.profile_picture_url &&
              // item.profile_picture_url.split(".com/")[1]
            }
          /> */}
          <img
            style={{
              height: "72px",
              width: "72px",
              borderRadius: 10,
            }}
            src={fileContent ? fileContent : ProfilePic}
            alt=""
          />
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.417vw",
            }}
          >
            <span
              // onClick={() => {
              //   navigate(`${navigateTo}/${item.id}`);
              //   setAdminTabActiveKey("");
              // }}
              style={{
                color: "#333",
                fontSize: "24px",
                fontWeight: 700,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {adminUserDetails.name}
            </span>

            <span
              style={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#000000",
              }}
            >
              SID #
              <span
                style={{
                  color: "#7D7D7D",
                }}
              >
                {adminUserDetails.id}
              </span>
            </span>
          </Row>
        </Row>
      ),
      children: [
        {
          label: (
            <span
              style={{
                fontSize: "0.9375vw",
                fontWeight: 400,
              }}
            >
              Staff Information
            </span>
          ),
          key: "1",
        },
        {
          label: (
            <span
              style={{
                fontSize: "0.9375vw",
                fontWeight: 400,
              }}
            >
              Treatment Information
            </span>
          ),
          key: "2",
        },
      ],
    },
  ];

  const [selectTab, setselectTab] = useState("1");
  const onClick = (e) => {
    setselectTab(e.key);
  };

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current > moment().startOf("day");
  };

  // const [designationValue, setdesignationValue] = useState();
  // const onChange = (checkedValues) => {
  //   setdesignationValue(checkedValues);
  // };

  const handleImageClick = () => {
    // if (route === "patient") {
    document.getElementById("fileInput").click();
    // }
  };

  const handleFileChange = async (event) => {
    const propic = event.target.files[0];
    // let formData = new FormData();
    // formData.append("file", propic);
    // let config = {
    //   headers: {
    //     Authorization: `Bearer ${Token}`,
    //   },
    // };
    // await axios
    //   .post(
    //     `${API_BASE_URL}/api/v1/patient/${patientid}/upload-profile-picture`,
    //     formData,
    //     config
    //   )
    //   .then((res) => {
    //     handleGetPatientDetails();
    //     getPatientDetails();
    //     message.success("Updated Profile Picture");
    //     setSpinner(false);
    //   });

    setSpinner(true);
    let formData = new FormData();
    formData.append("file", propic);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (role === "CLINIC_USER") {
      await axios
        .post(
          `${API_BASE_URL}/api/v1/adminStaff/${id}/upload-profile-picture`,
          formData,
          config
        )
        .then((res) => {
          setSpinner(false);
          handleGetAdminDetails();
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
          setSpinner(false);
        });
    }
    if (role === "CLINIC_ADMIN") {
      await axios
        .post(
          `${API_BASE_URL}/api/v1/admin/${id}/upload-profile-picture`,
          formData,
          config
        )
        .then((res) => {
          setSpinner(false);
          handleGetAdminDetails();
        })
        .catch((err) => {
          message.error(err.response.data.data.errors);
          setSpinner(false);
        });
    }
  };

  return (
    // <div className="add-staff-main-div">
    //   <Helmet>
    //     {role === "CLINIC_ADMIN" ? (
    //       <title>Administrator Profile</title>
    //     ) : (
    //       <title>Clinic User Profile</title>
    //     )}
    //   </Helmet>
    //   <Row
    //     style={{
    //       display: "flex",
    //       flexDirection: "column",
    //       margin: "12px 23px",
    //     }}
    //   >
    //     <span
    //       style={{
    //         fontWeight: 700,
    //         fontSize: 22,
    //         color: "#525252",
    //       }}
    //     >
    //       My Profile
    //     </span>
    //   </Row>
    //   <Row className="w-100" style={{ padding: "0 0 0 23px" }}>
    //     <Form
    //       form={form}
    //       initialValues={{
    //         remember: true,
    //       }}
    //       autoComplete="off"
    //       className="w-100"
    //       onFinish={handleUpdateAdminDetails}
    //     >
    //       <Spin
    //         indicator={antIcon}
    //         size="large"
    //         spinning={spinner && true}
    //         delay={500}
    //       >
    //         <Row className="d-flex">
    //           {/* profile col */}
    //           <Col xs={24} sm={24} md={12} xl={6}>
    //             <div className="profile-pic-col-main-div">
    //               <div
    //                 style={{
    //                   padding: "3vh 3.33vw",
    //                   borderBottom: "1px solid #D9D9D9",
    //                 }}
    //               >
    //                 <Row className="d-flex jc-center ai-center">
    //                   <Col>
    //                     <div
    //                       style={{
    //                         borderRadius: "40%",
    //                         background: "#D9D9D9",
    //                         width: "118px",
    //                         height: "100px",
    //                       }}
    //                     >
    //                       <Form.Item>
    //                         <img
    //                           style={{
    //                             height: "118px",
    //                             width: "118px",
    //                             borderRadius: 40,
    //                           }}
    //                           src={fileContent ? fileContent : ProfilePic}
    //                           alt=""
    //                         />
    //                       </Form.Item>
    //                     </div>
    //                   </Col>
    //                   <Col>
    //                     <span>
    //                       <u
    //                         style={{
    //                           fontWeight: "400",
    //                           fontSize: "16px",
    //                           color: "#2CA3FA",
    //                           padding: "0 12px",
    //                           cursor: "pointer",
    //                         }}
    //                         onClick={handleProfilePicUpload}
    //                       >
    //                         <input
    //                           type="file"
    //                           id="file"
    //                           accept="image/*"
    //                           ref={hiddenFilesInput}
    //                           style={{ display: "none" }}
    //                           onChange={uploadClinicUserProfilePic}
    //                         />
    //                         Add / Edit Picture
    //                       </u>
    //                     </span>
    //                   </Col>
    //                 </Row>
    //                 <Row
    //                   style={{
    //                     display: "flex",
    //                     flexDirection: "column",
    //                     margin: "27px 0 0 0",
    //                     gap: "2.302vh",
    //                   }}
    //                 >
    //                   <Row style={{ display: "flex", flexDirection: "column" }}>
    //                     <span
    //                       style={{
    //                         fontWeight: "400",
    //                         fontSize: "12px",
    //                         color: "#A5A4A4",
    //                       }}
    //                     >
    //                       Name
    //                     </span>
    //                     {adminUserDetails && (
    //                       <Form.Item
    //                         name="admin_name"
    //                         initialValue={adminUserDetails.name}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please Enter name",
    //                           },
    //                           {
    //                             min: 3,
    //                             message:
    //                               "Name must be at least 3 characters long",
    //                           },
    //                           {
    //                             max: 100,
    //                             message:
    //                               "Name must be no more than 100 characters long",
    //                           },
    //                           {
    //                             pattern: /^[A-Za-z][A-Za-z\s]*$/,
    //                             message:
    //                               "First character should be alphabetical and Name must contain only alphabeticals",
    //                           },
    //                           { whitespace: true, message: "No white spaces" },
    //                         ]}
    //                       >
    //                         <Input
    //                           name="admin_name"
    //                           style={{
    //                             fontWeight: "400",
    //                             fontSize: "16px",
    //                             color: "#525252",
    //                             height: "40px",
    //                             borderRadius: "3px",
    //                             border: "1px solid #E3E3E3",
    //                           }}
    //                           bordered={false}
    //                           disabled={inputsEnable}
    //                           maxLength={101}
    //                           onKeyPress={(e) => {
    //                             const charCode = e.which || e.keyCode;
    //                             const inputValue = e.target.value;
    //                             if (
    //                               !(
    //                                 (charCode >= 65 && charCode <= 90) ||
    //                                 (charCode >= 97 && charCode <= 122) ||
    //                                 (charCode === 32 &&
    //                                   inputValue.trim() !== "")
    //                               )
    //                             ) {
    //                               e.preventDefault();
    //                             }
    //                           }}
    //                         />
    //                       </Form.Item>
    //                     )}
    //                   </Row>

    //                   <Row style={{ display: "flex", flexDirection: "column" }}>
    //                     <span
    //                       style={{
    //                         fontWeight: "400",
    //                         fontSize: "12px",
    //                         color: "#A5A4A4",
    //                       }}
    //                     >
    //                       User Identification
    //                     </span>
    //                     {adminUserDetails && (
    //                       <Form.Item
    //                         name="patientID"
    //                         initialValue={"ID# " + adminUserDetails.id}
    //                       >
    //                         <Input
    //                           name="patientID"
    //                           label="patientID"
    //                           style={{
    //                             fontWeight: "400",
    //                             fontSize: "16px",
    //                             color: "#525252",
    //                             height: "40px",
    //                             borderRadius: "3px",
    //                             border: "1px solid #E3E3E3",
    //                           }}
    //                           bordered={false}
    //                           disabled={true}
    //                         />
    //                       </Form.Item>
    //                     )}
    //                   </Row>

    //                   <Row style={{ display: "flex", flexDirection: "column" }}>
    //                     <span
    //                       style={{
    //                         fontWeight: "400",
    //                         fontSize: "12px",
    //                         color: "#A5A4A4",
    //                       }}
    //                     >
    //                       Gender
    //                     </span>
    //                     {adminUserDetails && (
    //                       <Form.Item
    //                         name="gender"
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please Select Gender",
    //                           },
    //                         ]}
    //                         initialValue={adminUserDetails.gender}
    //                       >
    //                         <Select
    //                           name="gender"
    //                           disabled={inputsEnable}
    //                           bordered={false}
    //                           style={{
    //                             fontWeight: "400",
    //                             fontSize: "16px",
    //                             color: "#525252",
    //                             height: "40px",
    //                             borderRadius: "3px",
    //                             border: "1px solid #E3E3E3",
    //                           }}
    //                         >
    //                           <Option value="Male">Male</Option>
    //                           <Option value="Female">Female</Option>
    //                           <Option value="Others">Others</Option>
    //                         </Select>
    //                       </Form.Item>
    //                     )}
    //                   </Row>
    //                 </Row>
    //               </div>
    //               <div
    //                 style={{
    //                   padding:
    //                     "3.7151702786377707vh 3.3333333333333335vw 4vh 3.3854166666666665vw",
    //                   borderBottom: "1px solid #D9D9D9",
    //                 }}
    //               >
    //                 <span
    //                   style={{
    //                     fontWeight: "400",
    //                     fontSize: "12px",
    //                     color: "#A5A4A4",
    //                   }}
    //                 >
    //                   Email
    //                 </span>
    //                 {adminUserDetails && (
    //                   <Form.Item
    //                     name="email"
    //                     initialValue={adminUserDetails.email}
    //                     rules={[
    //                       { required: true, message: "Please enter email" },
    //                       {
    //                         type: "email",
    //                         message: "Please enter a valid email address",
    //                       },
    //                       {
    //                         max: 320,
    //                         message:
    //                           "Email address must be no more than 320 characters long",
    //                       },
    //                     ]}
    //                   >
    //                     <Input
    //                       name="email"
    //                       label="email"
    //                       style={{
    //                         fontWeight: "400",
    //                         fontSize: "16px",
    //                         color: "#7D7D7D",
    //                         height: "40px",
    //                         borderRadius: "3px",
    //                         border: "1px solid #E3E3E3",
    //                       }}
    //                       disabled={inputsEnable}
    //                       bordered={false}
    //                       maxLength={321}
    //                     />
    //                   </Form.Item>
    //                 )}
    //               </div>
    //               <div
    //                 style={{
    //                   padding:
    //                     "3.7151702786377707vh 3.3333333333333335vw 4.615384615384615vh 3.3854166666666665vw",
    //                 }}
    //               >
    //                 <Row>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Phone
    //                   </span>
    //                 </Row>
    //                 {adminUserDetails && (
    //                   <Space.Compact>
    //                     <Form.Item
    //                       name="phone_country_code"
    //                       initialValue={adminUserDetails.phone_country_code}
    //                     >
    //                       <Select
    //                         bordered={false}
    //                         disabled={inputsEnable}
    //                         style={{
    //                           display: "flex",
    //                           alignItems: "center",
    //                           height: 40,
    //                           background: "#FFFFFF",
    //                           border: "1px solid #E3E3E3",
    //                           margin: "3px 0 0 0",
    //                         }}
    //                         defaultValue="Select"
    //                         options={options}
    //                       />
    //                     </Form.Item>
    //                     <Form.Item
    //                       style={{ width: "100%" }}
    //                       name="phone_number"
    //                       initialValue={adminUserDetails.phone_number}
    //                       rules={[
    //                         {
    //                           required: true,
    //                           message: "Please enter phone number",
    //                         },
    //                         { validator: validatePhoneNumber },
    //                       ]}
    //                     >
    //                       <Input
    //                         bordered={false}
    //                         disabled={inputsEnable}
    //                         style={{
    //                           height: 40,
    //                           background: "#FFFFFF",
    //                           borderTop: "1px solid #E3E3E3",
    //                           borderRight: "1px solid #E3E3E3",
    //                           borderBottom: "1px solid #E3E3E3",
    //                           margin: "3px 0 0 0",
    //                         }}
    //                         defaultValue=""
    //                         onKeyPress={(e) => {
    //                           const charCode = e.which || e.keyCode;
    //                           if (!(charCode >= 48 && charCode <= 57)) {
    //                             e.preventDefault();
    //                           }
    //                         }}
    //                       />
    //                     </Form.Item>
    //                   </Space.Compact>
    //                 )}
    //               </div>
    //             </div>
    //           </Col>
    //           {/* address col */}
    //           <Col xs={24} sm={24} md={12} xl={6}>
    //             <div className="profile-pic-col-main-div">
    //               <Row
    //                 style={{
    //                   display: "flex",
    //                   flexDirection: "column",
    //                   gap: "5vh",
    //                   padding: "53px 23px",
    //                 }}
    //               >
    //                 <Row style={{ display: "flex", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Username
    //                   </span>
    //                   {adminUserDetails && (
    //                     <Form.Item
    //                       name="admin_username"
    //                       initialValue={adminUserDetails.username}
    //                       rules={[
    //                         {
    //                           required: true,
    //                           message: "Please enter username",
    //                         },
    //                         {
    //                           min: 4,
    //                           message:
    //                             "Username must be at least 4 characters long",
    //                         },
    //                         {
    //                           max: 100,
    //                           message:
    //                             "Username must be no more than 100 characters long",
    //                         },
    //                         { whitespace: true, message: "No white spaces" },
    //                         {
    //                           pattern: /^[a-zA-Z0-9._]+$/,
    //                           message:
    //                             "Username can only contain letters, numbers, fullstops, and underscores.",
    //                         },
    //                       ]}
    //                     >
    //                       <Input
    //                         name="admin_username"
    //                         style={{
    //                           fontWeight: "400",
    //                           fontSize: "16px",
    //                           color: "#7D7D7D",
    //                           height: "40px",
    //                           border: "1px solid #E3E3E3",
    //                         }}
    //                         bordered={false}
    //                         disabled={true}
    //                         onKeyPress={handleUserNameKeyPress}
    //                       />
    //                     </Form.Item>
    //                   )}
    //                 </Row>
    //                 <Row style={{ display: "flex", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Designation
    //                   </span>
    //                   {adminUserDetails && (
    //                     <Form.Item
    //                       name="designation"
    //                       initialValue={adminUserDetails.designation_name}
    //                       rules={[
    //                         {
    //                           required: true,
    //                           message: "Please Enter Designation",
    //                         },
    //                       ]}
    //                     >
    //                       <Select
    //                         name="designation"
    //                         bordered={false}
    //                         style={{
    //                           fontWeight: "400",
    //                           fontSize: "16px",
    //                           color: "#525252",
    //                           height: "40px",
    //                           borderRadius: "3px",
    //                           border: "1px solid #E3E3E3",
    //                         }}
    //                         disabled={inputsEnable}
    //                       >
    //                         <Option value="Doctor">Doctor</Option>
    //                         <Option value="Nurse">Nurse</Option>
    //                       </Select>
    //                     </Form.Item>
    //                   )}
    //                 </Row>
    //                 <Row style={{ display: "flex", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Date of Birth*
    //                   </span>
    //                   {adminUserDetails && (
    //                     <Form.Item
    //                       name="date_of_birth"
    //                       rules={[
    //                         {
    //                           required: true,
    //                           message: "Please Enter Date of Birth",
    //                         },
    //                       ]}
    //                       initialValue={moment(adminUserDetails.date_of_birth)}
    //                     >
    //                       <DatePicker
    //                         name="date_of_birth"
    //                         style={{
    //                           fontWeight: "400",
    //                           width: "70%",
    //                           fontSize: "16px",
    //                           color: "#525252",
    //                           height: "40px",
    //                           borderRadius: "3px",
    //                           border: "1px solid #E3E3E3",
    //                         }}
    //                         disabled={inputsEnable}
    //                       />
    //                     </Form.Item>
    //                   )}
    //                 </Row>
    //                 <Row style={{ display: "flex", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Qualification*
    //                   </span>
    //                   {adminUserDetails && (
    //                     <Form.Item
    //                       name="qualification"
    //                       initialValue={adminUserDetails.qualification}
    //                       rules={[
    //                         {
    //                           required: true,
    //                           message: "Please Enter Qualification",
    //                         },
    //                       ]}
    //                     >
    //                       <Input
    //                         name="qualification"
    //                         label="qualification"
    //                         style={{
    //                           fontWeight: "400",
    //                           fontSize: "16px",
    //                           color: "#7D7D7D",
    //                           height: "40px",
    //                           border: "1px solid #E3E3E3",
    //                         }}
    //                         bordered={false}
    //                         disabled={inputsEnable}
    //                       />
    //                     </Form.Item>
    //                   )}
    //                 </Row>
    //                 <Row style={{ display: "flex", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Total Experience*
    //                   </span>
    //                   <Row>
    //                     <Col span={12}>
    //                       {adminUserDetails && (
    //                         <Form.Item
    //                           name="years"
    //                           initialValue={
    //                             adminUserDetails.total_experience.years
    //                           }
    //                           rules={[
    //                             {
    //                               required: true,
    //                               message: "Please Enter years of experience",
    //                             },
    //                           ]}
    //                         >
    //                           <Select
    //                             name="years"
    //                             disabled={inputsEnable}
    //                             bordered={false}
    //                             style={{
    //                               fontWeight: "400",
    //                               width: "90%",
    //                               fontSize: "16px",
    //                               color: "#525252",
    //                               height: "40px",
    //                               borderRadius: "3px",
    //                               border: "1px solid #E3E3E3",
    //                             }}
    //                           >
    //                             {yearsMap.map((item) => {
    //                               return (
    //                                 <Option value={item.year}>
    //                                   {item.year}
    //                                 </Option>
    //                               );
    //                             })}
    //                           </Select>
    //                         </Form.Item>
    //                       )}
    //                     </Col>
    //                     <Col span={12}>
    //                       {adminUserDetails && (
    //                         <Form.Item
    //                           name="months"
    //                           initialValue={
    //                             adminUserDetails.total_experience.months
    //                           }
    //                           rules={[
    //                             {
    //                               required: true,
    //                               message: "Please Enter months experience",
    //                             },
    //                           ]}
    //                         >
    //                           <Select
    //                             name="months"
    //                             disabled={inputsEnable}
    //                             bordered={false}
    //                             style={{
    //                               width: "90%",
    //                               fontWeight: "400",
    //                               fontSize: "16px",
    //                               color: "#525252",
    //                               height: "40px",
    //                               borderRadius: "3px",
    //                               border: "1px solid #E3E3E3",
    //                             }}
    //                           >
    //                             {monthsMap.map((item) => {
    //                               return (
    //                                 <Option value={item.year}>
    //                                   {item.year}
    //                                 </Option>
    //                               );
    //                             })}
    //                           </Select>
    //                         </Form.Item>
    //                       )}
    //                     </Col>
    //                   </Row>
    //                 </Row>
    //                 <Row style={{ display: "none", flexDirection: "column" }}>
    //                   <span
    //                     style={{
    //                       fontWeight: "400",
    //                       fontSize: "12px",
    //                       color: "#A5A4A4",
    //                     }}
    //                   >
    //                     Access Type*
    //                   </span>
    //                   <Row>
    //                     <Col span={12}>
    //                       {adminUserDetails && (
    //                         <Form.Item
    //                           name="access_type"
    //                           initialValue={adminUserDetails.access_type_name}
    //                           rules={[
    //                             {
    //                               required: true,
    //                               message: "Select Access Type",
    //                             },
    //                           ]}
    //                         >
    //                           {adminUserDetails.access_type_name === "Admin" ||
    //                           adminUserDetails.access_type_name ===
    //                             "Master Admin" ? (
    //                             <Select
    //                               name="access_type"
    //                               disabled={true}
    //                               bordered={false}
    //                               style={{
    //                                 fontWeight: "400",
    //                                 fontSize: "16px",
    //                                 color: "#525252",
    //                                 height: "40px",
    //                                 borderRadius: "3px",
    //                                 border: "1px solid #E3E3E3",
    //                               }}
    //                             >
    //                               <Option value="Master Admin">
    //                                 Master Admin
    //                               </Option>
    //                               <Option value="Admin">Admin</Option>
    //                             </Select>
    //                           ) : (
    //                             <Select
    //                               name="access_type"
    //                               disabled={true}
    //                               bordered={false}
    //                               style={{
    //                                 fontWeight: "400",
    //                                 fontSize: "16px",
    //                                 color: "#525252",
    //                                 height: "40px",
    //                                 borderRadius: "3px",
    //                                 border: "1px solid #E3E3E3",
    //                               }}
    //                             >
    //                               <Option value="Can Edit">Can Edit</Option>
    //                               <Option value="Can View">Can View</Option>
    //                             </Select>
    //                           )}
    //                         </Form.Item>
    //                       )}
    //                     </Col>
    //                   </Row>
    //                 </Row>
    //               </Row>
    //             </div>
    //           </Col>
    //           {/* create slots col */}
    //           {adminUserDetails &&
    //             adminUserDetails.designation_name === "Doctor" && (
    //               <Col xs={24} sm={24} md={12} xl={12}>
    //                 <div className="set-slot-col">
    //                   <Row>
    //                     <div
    //                       style={{
    //                         padding: "0 14px",
    //                         display: "flex",
    //                         flexDirection: "column",
    //                       }}
    //                     >
    //                       <span
    //                         style={{
    //                           color: "#525252",
    //                           fontSize: 18,
    //                           fontWeight: 700,
    //                         }}
    //                       >
    //                         Set Appointment Slots
    //                       </span>
    //                     </div>
    //                     <div
    //                       style={{
    //                         display: "flex",
    //                         alignItems: "center",
    //                         gap: "30px",
    //                         width: "100%",
    //                       }}
    //                     >
    //                       <Button
    //                         onClick={handlePrevClick}
    //                         disabled={activeTab === "0"}
    //                         icon={<LeftOutlined />}
    //                         size="large"
    //                         style={{ borderRadius: "50%" }}
    //                       ></Button>
    //                       <Tabs
    //                         activeKey={activeTab}
    //                         onChange={handleTabChange}
    //                         style={{ width: "38.020833333333336vw" }}
    //                       >
    //                         {dateTabs &&
    //                           dateTabs.map((tab, index) => (
    //                             <TabPane
    //                               tab={tab.label}
    //                               key={index.toString()}
    //                             ></TabPane>
    //                           ))}
    //                       </Tabs>
    //                       <Button
    //                         onClick={handleNextClick}
    //                         icon={<RightOutlined />}
    //                         disabled={
    //                           activeTab === (dateTabs.length - 1).toString()
    //                         }
    //                         size="large"
    //                         style={{ borderRadius: "50%" }}
    //                       ></Button>
    //                     </div>
    //                   </Row>
    //                   <Row className="w-100">
    //                     <div
    //                       style={{
    //                         width: "100%",
    //                         padding: "16px 18px",
    //                         background: "#F3F4F5",
    //                         margin: "20px 0 0 0",
    //                         display: "flex",
    //                         alignItems: "center",
    //                         gap: 19,
    //                       }}
    //                     >
    //                       <Form.Item name="time_picker">
    //                         <TimePicker.RangePicker
    //                           disabled={slotsEnable}
    //                           minuteStep={15}
    //                           format="hh:mm a"
    //                           onChange={handleTimeChange}
    //                         />
    //                       </Form.Item>
    //                       {slotsEnable ? (
    //                         <Button
    //                           style={{
    //                             height: "30px",
    //                             border: "1px solid #009DF7",
    //                             borderRadius: "30px",
    //                             color: "#009DF7",
    //                             background: "#FFF",
    //                           }}
    //                           onClick={() => {
    //                             setSlotsEnable(false);
    //                           }}
    //                         >
    //                           {slots && slots.length > 0
    //                             ? "Manage Slots"
    //                             : "Create Slots"}
    //                         </Button>
    //                       ) : (
    //                         <Button
    //                           style={{
    //                             height: "30px",
    //                             border: "1px solid red",
    //                             borderRadius: "30px",
    //                             color: "red",
    //                             background: "#red",
    //                           }}
    //                           onClick={() => {
    //                             setSlotsEnable(true);
    //                             form.resetFields();
    //                           }}
    //                         >
    //                           Cancel
    //                         </Button>
    //                       )}
    //                       {slotsEnable === false && (
    //                         <Button
    //                           style={{
    //                             height: "30px",
    //                             width: "64px",
    //                             border: "1px solid #29C3C1",
    //                             borderRadius: "30px",
    //                             color: "#29C3C1",
    //                             background: "#ECFEFF",
    //                           }}
    //                           onClick={() => {
    //                             handleCreateSlotsForDoctor();
    //                           }}
    //                         >
    //                           Save
    //                         </Button>
    //                       )}
    //                     </div>
    //                     {/* </div> */}
    //                   </Row>
    //                   <Row
    //                     style={{
    //                       height: "41vh",
    //                       overflow: "auto",
    //                       padding: "30px 0",
    //                     }}
    //                   >
    //                     <div
    //                       style={{
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         width: "100%",
    //                         gap: 21,
    //                       }}
    //                     >
    //                       <Row style={{ display: "flex", gap: 11 }}>
    //                         {slots && slots.length > 0 ? (
    //                           slots.map((item) => {
    //                             return <Slots item={item} />;
    //                           })
    //                         ) : (
    //                           <div
    //                             style={{
    //                               width: "100%",
    //                               fontSize: 22,
    //                               fontWeight: 500,
    //                             }}
    //                             className="d-flex jc-center"
    //                           >
    //                             Select your available time
    //                           </div>
    //                         )}
    //                       </Row>
    //                     </div>
    //                   </Row>
    //                 </div>
    //               </Col>
    //             )}
    //         </Row>
    //         <Row
    //           style={{
    //             display: "flex",
    //             alignItems: "center",
    //             padding: "20px 0 50px 0",
    //           }}
    //         >
    //           <Col
    //             span={12}
    //             style={{
    //               display: "flex",
    //               justifyContent: "center",
    //               alignItems: "center",
    //               gap: 19,
    //             }}
    //           >
    //             {inputsEnable ? (
    //               <Button
    //                 style={{
    //                   height: "30px",
    //                   border: "1px solid #009DF7",
    //                   borderRadius: "30px",
    //                   color: "#009DF7",
    //                   background: "#FFF",
    //                 }}
    //                 onClick={() => {
    //                   setInputsEnable(false);
    //                 }}
    //               >
    //                 Edit
    //               </Button>
    //             ) : (
    //               <Button
    //                 style={{
    //                   height: "30px",
    //                   border: "1px solid red",
    //                   borderRadius: "30px",
    //                   color: "red",
    //                   background: "#red",
    //                 }}
    //                 onClick={() => {
    //                   setInputsEnable(true);
    //                 }}
    //               >
    //                 Cancel
    //               </Button>
    //             )}
    //             {inputsEnable === false && (
    //               <Button
    //                 style={{
    //                   height: "30px",
    //                   width: "64px",
    //                   border: "1px solid #29C3C1",
    //                   borderRadius: "30px",
    //                   color: "#29C3C1",
    //                   background: "#ECFEFF",
    //                 }}
    //                 htmlType="submit"
    //               >
    //                 Save
    //               </Button>
    //             )}
    //           </Col>
    //         </Row>
    //       </Spin>
    //     </Form>
    //   </Row>
    // </div>
    <div className="add-staff-main-div">
      <Helmet>
        {role === "CLINIC_ADMIN" ? (
          <title>Administrator Profile</title>
        ) : (
          <title>Clinic User Profile</title>
        )}
      </Helmet>
      {/* <Row
        style={{
          // display: "flex",
          // flexDirection: "column",
          margin: "12px 23px",
        }}
      >
        {/* <span
          style={{
            fontWeight: 700,
            fontSize: 22,
            color: "#525252",
          }}
        >
          My Profile
        </span>
    <Button onClick={() => navigate(-1)}>Back</Button> 
    </Row> */}
      <div
        style={{
          margin: "50px",
          minWidth: "79.6875vw",
          maxWidth: "89.6875vw",
          background: "#fff",
          padding: "4.1710114702815435vh 1.0416666666666667vw",
          borderRadius: "10px",
        }}
      >
        <Row>
          <Col
            span={6}
            style={{
              borderRight: "1px solid #C2C2C2",
              padding: "0 20px 0 0",
            }}
          >
            <Menu
              onClick={onClick}
              style={{
                width: "100%",
              }}
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["1"]}
              mode="inline"
              items={items}
            />
          </Col>
          <Col span={18}>
            {/* {selectTab == 1 && (
              <PatientProfile
                patientUserDetails={patientUserDetails}
                getPatientDetails={getPatientDetails}
                setPatientUserDetails={setPatientUserDetails}
                setSpinner={setSpinner}
                form={form}
                patient_id={patient_id}
                patientTabsData={patientTabsData}
                setPatientTabsData={setPatientTabsData}
              />
            )} */}
            {selectTab == 1 && (
              <div
                style={{
                  padding: "0 50px",
                }}
              >
                {adminUserDetails && (
                  <Form
                    form={form}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={handleUpdateAdminDetails}
                    autoComplete="off"
                    className="w-100"
                  >
                    <div style={{ padding: "1.0417vw" }}>
                      <Row
                        style={{
                          // margin: "0 1.5625vw",
                          gap: "1.563vw",
                          // borderBottom: "1px solid #A8A8A8",
                          // padding: "0 0 1.25vw 0",
                        }}
                        className="d-flex ai-center"
                      >
                        <Col>
                          <img
                            preview={fileContent && true}
                            style={{
                              height: "5.313vw",
                              width: "5.313vw",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                            src={fileContent ? fileContent : ProfilePic}
                            alt=""
                            onClick={handleImageClick}
                            // src={previewSrc}
                            // alt="Profile"
                          />
                          <input
                            id="fileInput"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </Col>
                        <Col>
                          <Row>
                            <span
                              style={{
                                fontSize: "1.25vw",
                                fontWeight: 700,
                                color: "#333333",
                              }}
                            >
                              Staff Information
                            </span>
                          </Row>
                          <Row>
                            <span
                              style={{
                                fontSize: "0.9375vw",
                                fontWeight: 400,
                                color: "#717171",
                              }}
                            >
                              Add information about the staff, including
                              personal and professional details.
                            </span>
                          </Row>
                        </Col>
                      </Row>

                      {/* <Row>
                      <span
                        style={{
                          color: "#3498DB",
                          fontSize: "1.25vw",
                          fontWeight: 500,
                        }}
                      >
                        Add New Staff
                      </span>
                    </Row>
                    <Row>
                      <span
                        style={{
                          color: "#6F6F6F",
                          fontSize: "0.73vw",
                          fontWeight: 400,
                        }}
                      >
                        Add information about the staff, including personal and
                        professional details.
                      </span>
                    </Row> */}
                      <Divider />
                      <Row
                        className="d-flex jc-between"
                        style={{ margin: "1.25vw 0" }}
                      >
                        <Col span={8}>
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Name <span style={{ color: "#FF0000" }}>*</span>
                            </span>
                            <Form.Item
                              name="name"
                              initialValue={adminUserDetails.name}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Patient name",
                                },
                                {
                                  min: 3,
                                  message:
                                    "Name must be at least 3 characters long",
                                },
                                {
                                  max: 100,
                                  message:
                                    "Name must be no more than 100 characters long",
                                },
                                {
                                  pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                  message:
                                    "First character should be alphabetical and Name must contain only alphabeticals",
                                },
                                {
                                  whitespace: true,
                                  message: "No white spaces",
                                },
                              ]}
                            >
                              <Input
                                style={{
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                  color: "black",
                                }}
                                maxLength={101}
                                bordered={false}
                                disabled={inputsEnable}
                                onKeyPress={(e) => {
                                  const charCode = e.which || e.keyCode;
                                  const inputValue = e.target.value;
                                  if (
                                    !(
                                      (charCode >= 65 && charCode <= 90) ||
                                      (charCode >= 97 && charCode <= 122) ||
                                      (charCode === 32 &&
                                        inputValue.trim() !== "")
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Username{" "}
                              <span style={{ color: "#FF0000" }}>*</span>
                            </span>
                            <Form.Item
                              className="w-100"
                              name="username"
                              initialValue={adminUserDetails.username}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Username!",
                                },
                                {
                                  min: 4,
                                  message: "Please enter minimum 4 characters",
                                },
                                {
                                  whitespace: true,
                                  message: "No white spaces",
                                },
                                {
                                  pattern: /^[a-zA-Z0-9._]+$/,
                                  message:
                                    "Username can only contain letters, numbers, fullstops, and underscores.",
                                },
                                {
                                  max: 100,
                                  message:
                                    "Username should not be more than 100 characters long",
                                },
                              ]}
                            >
                              <Input
                                disabled={true}
                                style={{
                                  color: "black",
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                }}
                                maxLength={101}
                                onKeyPress={handleUserNameKeyPress}
                              />
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Gender <span style={{ color: "#FF0000" }}></span>
                            </span>
                            <Form.Item
                              name="gender"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select Gender",
                                },
                              ]}
                              initialValue={adminUserDetails.gender}
                            >
                              <Select
                                disabled={inputsEnable}
                                bordered={false}
                                style={{
                                  color: "black",
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                }}
                              >
                                <Option value="Male">Male</Option>
                                <Option value="Female">Female</Option>
                                <Option value="Others">Others</Option>
                              </Select>
                            </Form.Item>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        className="d-flex jc-between"
                        style={{ margin: "1.25vw 0" }}
                      >
                        <Col span={8}>
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Email <span style={{ color: "#FF0000" }}>*</span>
                            </span>
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter email",
                                },
                                {
                                  type: "email",
                                  message: "Please Enter valid email address",
                                },
                                {
                                  max: 320,
                                  message:
                                    "Email address must be no more than 320 characters long",
                                },
                              ]}
                              initialValue={adminUserDetails.email}
                            >
                              <Input
                                style={{
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                  color: "black",
                                }}
                                bordered={false}
                                disabled={inputsEnable}
                                maxLength={321}
                              />
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Phone No.{" "}
                              <span style={{ color: "#FF0000" }}>*</span>
                            </span>
                            <Space.Compact
                              className="w-100"
                              style={{
                                width: "14.583333333333334vw",
                              }}
                            >
                              <Form.Item
                                name="phone_country_code"
                                // initialValue="+91"
                                initialValue={
                                  adminUserDetails.phone_country_code
                                    ? adminUserDetails.phone_country_code
                                    : "+91"
                                }
                              >
                                <Select
                                  bordered={false}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "2.0834vw",
                                    background: "#FFFFFF",
                                    border: "1px solid #E3E3E3",
                                    margin: "3px 0 0 0",
                                  }}
                                  options={options}
                                  disabled={inputsEnable}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: "100%" }}
                                name="phone_number"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter phone number",
                                  },
                                  { validator: validatePhoneNumber },
                                ]}
                                initialValue={adminUserDetails.phone_number}
                              >
                                <Input
                                  bordered={false}
                                  disabled={inputsEnable}
                                  style={{
                                    height: "2.0834vw",
                                    background: "#FFFFFF",
                                    borderTop: "1px solid #E3E3E3",
                                    borderRight: "1px solid #E3E3E3",
                                    borderBottom: "1px solid #E3E3E3",
                                    margin: "3px 0 0 0",
                                  }}
                                  onKeyPress={(e) => {
                                    const charCode = e.which || e.keyCode;
                                    if (!(charCode >= 48 && charCode <= 57)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Space.Compact>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Date of Birth{" "}
                              <span style={{ color: "#FF0000" }}>*</span>
                            </span>
                            <Form.Item
                              initialValue={moment(
                                adminUserDetails.date_of_birth
                              )}
                              name="date_of_birth"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select Date of birth",
                                },
                              ]}
                            >
                              <DatePicker
                                disabled={inputsEnable}
                                disabledDate={disabledDate}
                                style={{
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                  color: "black",
                                }}
                              />
                            </Form.Item>
                          </Row>
                        </Col>
                      </Row>
                      {/* <Row className="d-flex jc-between">
                      <Col span={8}>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.73vw",
                              fontWeight: 400,
                              color: "#2F2F2F",
                            }}
                          >
                            Access <span style={{ color: "#FF0000" }}>*</span>
                          </span>
                          <Form.Item
                            className="w-100"
                            name="access_type"
                            rules={[
                              {
                                required: true,
                                message: "Please select access",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Access"
                              bordered={false}
                              style={{
                                width: "14.583333333333334vw",
                                margin: "5px 0 0 0",
                                height: "2.0834vw",
                                borderRadius: 3,
                                border: "1px solid #DADADA",
                                color: "black",
                              }}
                            >
                              <Option value="Admin">Admin</Option>
                              <Option value="Can Edit">Staff</Option>
                            </Select>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Row> */}

                      <Row style={{ margin: "1.563vw 0 0 0" }}>
                        <span
                          style={{
                            color: "#333333",
                            fontSize: "0.834vw",
                            fontWeight: 700,
                          }}
                        >
                          Professional Details
                        </span>
                      </Row>
                      <div className="antdividerhorizontal">
                        <Divider />
                      </div>
                      <Row style={{ margin: "1.25vw 0" }}>
                        <Col span={8}>
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Designation{" "}
                              <span style={{ color: "#FF0000" }}>*</span>
                            </span>

                            <Form.Item
                              className="w-100"
                              name="designation"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select designation",
                                },
                              ]}
                              initialValue={adminUserDetails.designation_name}
                            >
                              <Select
                                placeholder="Select Designation"
                                // onChange={onChange}
                                allowClear
                                bordered={false}
                                style={{
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                  color: "black",
                                }}
                                disabled={inputsEnable}
                              >
                                <Option value="Dentist">Dentist</Option>
                                <Option value="DentalAssistant">
                                  Dental Assistant
                                </Option>
                                <Option value="DentalHygienist">
                                  Dental Hygienist
                                </Option>
                                <Option value="Receptionist">
                                  Receptionist
                                </Option>
                                <Option value="Doctor">Doctor</Option>
                                <Option value="Nurse">Nurse</Option>
                              </Select>
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Row
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Qualification
                            </span>
                            <Form.Item
                              name="qualification"
                              initialValue={adminUserDetails.qualification}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Qualification",
                                },
                              ]}
                            >
                              <Input
                                disabled={inputsEnable}
                                placeholder="E.g: BDS, MDS"
                                style={{
                                  width: "14.583333333333334vw",
                                  margin: "5px 0 0 0",
                                  height: "2.0834vw",
                                  borderRadius: 3,
                                  border: "1px solid #DADADA",
                                  color: "black",
                                }}
                                bordered={false}
                              />
                            </Form.Item>
                          </Row>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Row
                            style={{ display: "flex", flexDirection: "column" }}
                            // className="w-100"`
                          >
                            <span
                              style={{
                                fontSize: "0.73vw",
                                fontWeight: 400,
                                color: "#2F2F2F",
                              }}
                            >
                              Total Experience
                            </span>
                            <Row
                              className="w-100 d-flex jc-end"
                              style={{ gap: 16, margin: "5px 0 0 0" }}
                            >
                              <Col>
                                <Form.Item
                                  name="years"
                                  initialValue={
                                    adminUserDetails.total_experience.years
                                  }
                                  // rules={[{ required: true, message: "Please enter year" }]}
                                >
                                  <Select
                                    placeholder="0 Years"
                                    bordered={false}
                                    allowClear
                                    style={{
                                      fontWeight: "400",
                                      width: "6.875vw",
                                      fontSize: "16px",
                                      color: "#525252",
                                      height: "40px",
                                      borderRadius: "3px",
                                      border: "1px solid #E3E3E3",
                                    }}
                                    disabled={inputsEnable}
                                  >
                                    {yearsMap.map((item) => {
                                      return (
                                        <Option value={item.year}>
                                          {item.year}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item
                                  name="months"
                                  initialValue={
                                    adminUserDetails.total_experience.months
                                  }
                                  //   rules={[
                                  //     { required: true, message: "Please enter month" },
                                  //   ]}
                                >
                                  <Select
                                    placeholder="0 Months"
                                    allowClear
                                    bordered={false}
                                    style={{
                                      width: "6.875vw",
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      color: "#525252",
                                      height: "40px",
                                      borderRadius: "3px",
                                      border: "1px solid #E3E3E3",
                                    }}
                                    disabled={inputsEnable}
                                  >
                                    {monthsMap.map((item) => {
                                      return (
                                        <Option value={item.year}>
                                          {item.year}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        style={{ margin: "1.25vw 0" }}
                        className="d-flex flex-col"
                      >
                        <span
                          className="w-100"
                          style={{
                            fontSize: "0.73vw",
                            fontWeight: 400,
                            color: "#2F2F2F",
                          }}
                        >
                          Specialisation{" "}
                          <span style={{ color: "#FF0000" }}></span>
                        </span>
                        <Form.Item
                          className="w-100"
                          name="specialization"
                          //   rules={[
                          //     { required: true, message: "Please enter month" },
                          //   ]}
                        >
                          {inputsEnable ? (
                            <Row className="w-100">
                              {JSON.parse(adminUserDetails.specialization)
                                .length > 0 ? (
                                JSON.parse(adminUserDetails.specialization).map(
                                  (data) => {
                                    return (
                                      <>
                                        <Col
                                          span={6}
                                          style={{ padding: "10px 0" }}
                                        >
                                          <Checkbox
                                            value="General Dentistry"
                                            style={{
                                              display: "flex",
                                              alignItems: "",
                                            }}
                                            checked
                                          >
                                            <span
                                              style={{
                                                color: "#333",
                                                fontSsize: 16,
                                                fontWeight: 500,
                                                padding: "0 0 0 12px",
                                              }}
                                            >
                                              {data}
                                            </span>
                                          </Checkbox>
                                        </Col>
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </Row>
                          ) : (
                            <Checkbox.Group
                              // disabled={!designationValue}
                              className="w-100 specialisation-checkbox"
                            >
                              <Row className="w-100">
                                <Col span={6} style={{ padding: "10px 0" }}>
                                  <Checkbox
                                    value="General Dentistry"
                                    style={{ display: "flex", alignItems: "" }}
                                  >
                                    <span
                                      style={{
                                        color: "#333",
                                        fontSsize: 16,
                                        fontWeight: 500,
                                        padding: "0 0 0 12px",
                                      }}
                                    >
                                      General Dentistry
                                    </span>
                                  </Checkbox>
                                </Col>
                                <Col span={6} style={{ padding: "10px 0" }}>
                                  <Checkbox
                                    value="Surgeon"
                                    style={{ display: "flex", alignItems: "" }}
                                  >
                                    <span
                                      style={{
                                        color: "#333",
                                        fontSsize: 16,
                                        fontWeight: 500,
                                        padding: "0 0 0 12px",
                                      }}
                                    >
                                      Surgeon
                                    </span>
                                  </Checkbox>
                                </Col>
                                <Col span={6} style={{ padding: "10px 0" }}>
                                  <Checkbox
                                    value="Pediatric Dentistry"
                                    style={{ display: "flex", alignItems: "" }}
                                  >
                                    <span
                                      style={{
                                        color: "#333",
                                        fontSsize: 16,
                                        fontWeight: 500,
                                        padding: "0 0 0 12px",
                                      }}
                                    >
                                      Pediatric Dentistry
                                    </span>
                                  </Checkbox>
                                </Col>
                                <Col span={6} style={{ padding: "10px 0" }}>
                                  <Checkbox
                                    value="Ortho dentistry"
                                    style={{ display: "flex", alignItems: "" }}
                                  >
                                    <span
                                      style={{
                                        color: "#333",
                                        fontSsize: 16,
                                        fontWeight: 500,
                                        padding: "0 0 0 12px",
                                      }}
                                    >
                                      Ortho dentistry
                                    </span>
                                  </Checkbox>
                                </Col>
                                <Col span={6} style={{ padding: "10px 0" }}>
                                  <Checkbox
                                    value="Periodontics"
                                    style={{ display: "flex", alignItems: "" }}
                                  >
                                    <span
                                      style={{
                                        color: "#333",
                                        fontSsize: 16,
                                        fontWeight: 500,
                                        padding: "0 0 0 12px",
                                      }}
                                    >
                                      Periodontics
                                    </span>
                                  </Checkbox>
                                </Col>
                                <Col
                                  span={6}
                                  style={{ padding: "10px 0" }}
                                ></Col>
                                <Col
                                  span={6}
                                  style={{ padding: "10px 0" }}
                                ></Col>
                                <Col
                                  span={6}
                                  style={{ padding: "10px 0" }}
                                ></Col>
                              </Row>
                            </Checkbox.Group>
                          )}
                        </Form.Item>
                      </Row>

                      <Row
                        className="w-100"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "20px 0",
                        }}
                      >
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 19,
                          }}
                        >
                          {inputsEnable ? (
                            <Button
                              style={{
                                height: "30px",
                                border: "1px solid #009DF7",
                                borderRadius: "30px",
                                color: "#009DF7",
                                background: "#FFF",
                              }}
                              onClick={() => {
                                setInputsEnable(false);
                              }}
                            >
                              Edit
                            </Button>
                          ) : (
                            <Button
                              style={{
                                height: "30px",
                                border: "1px solid red",
                                borderRadius: "30px",
                                color: "red",
                                background: "#red",
                              }}
                              onClick={() => {
                                setInputsEnable(true);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                          {inputsEnable === false && (
                            <Button
                              style={{
                                height: "30px",
                                width: "64px",
                                border: "1px solid #29C3C1",
                                borderRadius: "30px",
                                color: "#29C3C1",
                                background: "#ECFEFF",
                              }}
                              htmlType="submit"
                            >
                              Save
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                )}
              </div>
            )}
            {selectTab == 2 && <div>Insurance in progress</div>}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminMyAccount;
