import React from "react";
import "./Clinics.css";
import { useEffect } from "react";
import {
  Button,
  Modal,
  Collapse,
  Col,
  Input,
  Row,
  message,
  DatePicker,
  Select,
  Form,
  notification,
  Alert,
  Spin,
} from "antd";
import { useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import moment from "moment";
import ClinicDetails from "./clinicdetails/ClinicDetails";
import API_BASE_URL from "../../../../env";
import {
  SearchOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { UserAuth } from "../../../../context_api/ContextApi";
import defpropic from "../../../../assets/ssdental.png";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import {
  _scheduled_by_patient,
  _status_scheduled,
} from "../../../../utils/appointment_constants/AppointmentConstants";
import { useNavigate } from "react-router";
import {
  routes_appointments,
  tabs_appointments,
} from "../../../../utils/patient_navbar_constants/PatientNavbarConstants";
import location_icon from "../../../../assets/fluent_location-24-filled.png";
import vectorimage from "../../../../assets/vectorimage.png";

const Clinics = () => {
  const Token = localStorage.getItem("Token");
  const branch_id = localStorage.getItem("main_branch_id")
    ? localStorage.getItem("main_branch_id")
    : localStorage.getItem("branch_id");
  const [searchInput, setSearchInput] = useState("");
  const [treatmentTypes, setTreatmentTypes] = useState();
  const [clinicDetails, setClinicDetails] = useState();
  const [clinicSearchInvisible, setClinicSearchInvisible] = useState(false);
  const [clinicId, setClinicId] = useState(null);
  const [treatmentRate, setTreatmentRate] = useState();
  const [branchId, setbranchId] = useState(false);
  const [displaySlots, setDisplaySlots] = useState(false);
  const [selectedDate, setselectedDate] = useState();
  const [allDoctorsSlots, setallDoctorsSlots] = useState();
  const [form] = Form.useForm();

  const { setTabActiveKey } = UserAuth();

  const onslotdatechange = (value) => {
    setselectedDate(moment(value).format("YYYY-MM-DD"));
  };

  // const handleGetAllSlotDetails = (clinic_id, selected_date, branch_id) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   };
  //   if (branch_id) {
  //     axios
  //       .get(
  //         `${API_BASE_URL}/api/v1/slots/getAvailableSlots/${clinic_id}?date=${selected_date}&branchId=${branch_id}`,
  //         config
  //       )
  //       .then((res) => {
  //         setSpinner(false);
  //         setallDoctorsSlots(res.data.data);
  //       });
  //   } else {
  //     axios
  //       .get(
  //         `${API_BASE_URL}/api/v1/slots/getAvailableSlots/${clinic_id}?date=${selected_date}`,
  //         config
  //       )
  //       .then((res) => {
  //         setSpinner(false);
  //         setallDoctorsSlots(res.data.data);
  //       })
  //       .catch((err) => {
  //         message.error(err.response.data.data.errors);
  //       });
  //   }
  // };

  useEffect(() => {
    // handleGetAllSlotDetails();
  }, [clinicId !== null]);

  const handleGetAllClinicDetails = () => {
    setSpinner(true);
    setSearchInput("");
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/clinic`, config)
      .then((res) => {
        setClinicDetails(res.data.data);
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const handleGetClinicsBySearch = (e) => {
    setSpinner(true);
    const search_clinic = e.target.value;
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (search_clinic) {
      axios
        .get(
          `${API_BASE_URL}/api/v1/clinic/searchClinic?clinicIdOrName=${search_clinic}`,
          config
        )
        .then((res) => {
          setClinicDetails(res.data.data);
          setSpinner(false);
        })
        .catch((err) => {
          setSpinner(false);
          message.error(err.response.data.data.errors);
        });
    } else {
      handleGetAllClinicDetails();
    }
  };

  const handleGetAllTreatmentTypes = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/static/treatment-type`, config)
      .then((res) => {
        setTreatmentTypes(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const handleGetDirections = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = `${position.coords.latitude}`;
        const longitude = `${position.coords.longitude}`;
        const config = {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        };
        axios
          .get(
            `${API_BASE_URL}/api/v1/clinic/?latitude=${latitude}&longitude=${longitude}`,
            config
          )
          .then((res) => {
            setClinicDetails(res.data.data);
          })
          .catch((err) => {
            handleGetAllClinicDetails();
            message.error("Your device not able to get your current location");
          });
      });
    }
  };

  useEffect(() => {
    handleGetAllClinicDetails();
    handleGetAllTreatmentTypes();
  }, []);

  const handleGetClinicDetails = (clinic_id, branch_id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/clinic/${clinic_id}/{branchId}${
          branch_id && `?branchId=${branch_id}`
        }`,
        config
      )
      .then((res) => {
        setClinicDetails(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const handleGetTreatmentsTypes = async (value) => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/treatments?clinic_id=${value}`, config)
      .then((res) => {
        setTreatmentTypes(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
  };

  const [openBookAppointmentModal, setopenBookAppointmentModal] =
    useState(false);

  const [selectedSlot, setSelectedSlot] = useState();
  const [slotId, setslotId] = useState();
  const [docId, setDocId] = useState();
  const [treatmentTypeId, setTreatmentTypeID] = useState();
  const patientid = localStorage.getItem("patient_id");

  const handleOpenBookAppointmentModal = (slot_id, doc_id, selected_slot) => {
    setopenBookAppointmentModal(true);
    setslotId(slot_id);
    setDocId(doc_id);
    setSelectedSlot(selected_slot);
  };
  const navigate = useNavigate();

  const [first, setfirst] = useState();

  const handleCreateAppoinment = () => {
    setSpinner(true);
    let payload;
    if (branchId) {
      payload = {
        patient_id: patientid,
        clinic_id: clinicId,
        treatment_type_id: treatmentTypeId,
        from_date: selectedSlot,
        scheduled_by: _scheduled_by_patient,
        status: _status_scheduled,
        doc_id: docId,
        doc_slot: slotId,
        notes: "",
        branch_id: branchId,
      };
    } else {
      payload = {
        patient_id: patientid,
        clinic_id: clinicId,
        treatment_type_id: treatmentTypeId,
        from_date: selectedSlot,
        scheduled_by: _scheduled_by_patient,
        status: _status_scheduled,
        doc_id: docId,
        doc_slot: slotId,
        notes: "",
      };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .post(`${API_BASE_URL}/api/v1/appointment`, payload, config)
      .then((res) => {
        message.success(res.data.data);
        setopenBookAppointmentModal(false);
        setTabActiveKey(tabs_appointments);
        setselectedDate("");
        setallDoctorsSlots("");
        navigate(routes_appointments);
        setTreatmentTypeID("");
        setSpinner(false);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const disabledDate = (current) => {
    const yesterday = moment().subtract(1, "day");
    const nextWeek = moment().add(6, "days").endOf("day");
    return current && (current < yesterday || current > nextWeek);
  };

  const [spinner, setSpinner] = useState(false);

  const handlesearchslotsfordate = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (clinicId) {
      if (branchId) {
        axios
          .get(
            `${API_BASE_URL}/api/v1/slots/getAvailableSlots/${clinicId}?date=${selectedDate}&branchId=${branchId}`,
            config
          )
          .then((res) => {
            setSpinner(false);
            setallDoctorsSlots(res.data.data);
          })
          .catch((err) => {
            message.error(err.response.data.data.errors);
          });
      } else {
        axios
          .get(
            `${API_BASE_URL}/api/v1/slots/getAvailableSlots/${clinicId}?date=${selectedDate}`,
            config
          )
          .then((res) => {
            setSpinner(false);
            setallDoctorsSlots(res.data.data);
          })
          .catch((err) => {
            message.error(err.response.data.data.errors);
          });
      }
    }
  };

  return (
    <div className="clinics-page-main-div">
      <Helmet>
        <title>Clinics</title>
      </Helmet>
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: "2.6045vw", margin: "14.588vw 0 0 0" }}
            spin
          />
        }
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <Row className="d-flex jc-evenly">
          <Form form={form} onFinish={handlesearchslotsfordate}>
            <div
              style={{
                width: "78.90625vw",
                borderRadius: "10px",
                margin: "23px",
                minHeight: "80vh",
                background: "#FFF",
                boxShadow: "2px 2px 30px 0px #0000000D",
              }}
            >
              <Row>
                {displaySlots ? (
                  clinicDetails && (
                    <div
                      style={{
                        borderBottom: "1px solid #C9C9C9",
                      }}
                    >
                      <Row
                        style={{
                          padding: "4.179728317659352vh 5.572916666666667vw",
                        }}
                        className="w-100"
                      >
                        <Row
                          style={{
                            display: "flex",
                            gap: 60,
                          }}
                          className="w-100"
                        >
                          <Col>
                            <Row className="w-100 d-flex ai-end">
                              <span
                                style={{
                                  color: "#333",
                                  fontSize: "1.0416666666666667vw",
                                  fontWeight: 500,
                                }}
                              >
                                {clinicDetails.name}{" "}
                                <span
                                  style={{
                                    color: "#B3B9BD",
                                    fontSize: "0.8333333333333334vw",
                                    fontWeight: 500,
                                  }}
                                >
                                  ID #{clinicDetails.clinic_id}
                                </span>
                              </span>
                            </Row>
                            <Row style={{ gap: 5 }}>
                              <img
                                src={location_icon}
                                alt=""
                                style={{
                                  width: 24,
                                }}
                              />
                              <span className="address">
                                {clinicDetails.address_line_one}
                              </span>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <span
                                style={{
                                  color: "#B3B9BD",
                                  fontSize: "0.8333333333333334vw",
                                  fontWeight: 500,
                                }}
                              >
                                Phone No.
                              </span>
                            </Row>
                            <Row style={{ gap: 5 }}>
                              <span
                                style={{
                                  color: "#333",
                                  fontSize: "0.9375vw",
                                  fontWeight: 500,
                                }}
                              >
                                {clinicDetails.phone_number}
                              </span>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <span
                                style={{
                                  color: "#B3B9BD",
                                  fontSize: "0.8333333333333334vw",
                                  fontWeight: 500,
                                }}
                              >
                                Email
                              </span>
                            </Row>
                            <Row style={{ gap: 5 }}>
                              <span
                                style={{
                                  color: "#333",
                                  fontSize: "0.9375vw",
                                  fontWeight: 500,
                                }}
                              >
                                {clinicDetails.email}
                              </span>
                            </Row>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            padding: "4.179728317659352vh 0 0 0",
                          }}
                          className="w-100 d-flex jc-between ai-end"
                        >
                          <Col>
                            <Row>
                              <span
                                style={{
                                  color: "#B3B9BD",
                                  fontSize: "0.8333333333333334vw",
                                  fontWeight: 500,
                                }}
                              >
                                Select Date
                                <span style={{ color: "#F00" }}>*</span>
                              </span>
                            </Row>
                            <Row>
                              <Form.Item
                                name="date_of_birth"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select date for slots",
                                  },
                                ]}
                              >
                                <DatePicker
                                  disabledDate={disabledDate}
                                  className="datepicker-slots"
                                  onChange={onslotdatechange}
                                />
                              </Form.Item>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <span
                                style={{
                                  color: "#B3B9BD",
                                  fontSize: "0.8333333333333334vw",
                                  fontWeight: 500,
                                }}
                              >
                                List of Treatements
                                <span style={{ color: "#F00" }}>*</span>
                              </span>
                            </Row>
                            <Row>
                              <Form.Item
                                className="w-100 d-flex"
                                name="treatment"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select treatment",
                                  },
                                ]}
                              >
                                <Select
                                  onChange={(value) => {
                                    setTreatmentTypeID(JSON.parse(value).id);
                                    setTreatmentRate(JSON.parse(value).rate);
                                  }}
                                  defaultValue="Select Treatment"
                                  bordered={false}
                                  className="treatment-slots"
                                >
                                  {treatmentTypes &&
                                    treatmentTypes
                                      .filter(
                                        (item) => item.status === "ACTIVE"
                                      )
                                      .map((item) => {
                                        return (
                                          <Select.Option
                                            value={JSON.stringify(item)}
                                          >
                                            <Row className="d-flex jc-between w-100">
                                              <span>{item.treatment_name}</span>
                                              <span>{item.rate}</span>
                                            </Row>
                                          </Select.Option>
                                        );
                                      })}
                                </Select>
                              </Form.Item>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <span
                                style={{
                                  color: "#B3B9BD",
                                  fontSize: "0.8333333333333334vw",
                                  fontWeight: 500,
                                }}
                              >
                                Dental Concern
                              </span>
                            </Row>
                            <Row>
                              <Input className="treatment-slots" />
                            </Row>
                          </Col>
                          <Col>
                            <Form.Item>
                              <Button
                                htmlType="submit"
                                style={{
                                  fontSize: "0.7291666666666666vw",
                                  fontWeight: 600,
                                  height: "5.015673981191223vh",
                                  color: "#FFF",
                                  border: "1px solid #3498DB",
                                  borderRadius: 5,
                                  padding: "0 1.3020833333333333vw",
                                  background: "#3498DB",
                                  opacity:
                                    treatmentTypeId && selectedDate ? "" : 0.5,
                                }}
                                disabled={
                                  treatmentTypeId && selectedDate ? false : true
                                }
                              >
                                Search Slots
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </div>
                  )
                ) : (
                  <Row
                    className="d-flex ai-center jc-between w-100"
                    style={{
                      padding: "23px 35px",
                    }}
                  >
                    <Col>
                      <Row>
                        <span
                          style={{
                            fontSize: "1.0416666666666667vw",
                            fontWeight: 500,
                            color: "#000000",
                          }}
                        >
                          Find Clinic
                        </span>
                      </Row>
                      <Row>
                        <span
                          style={{
                            fontSize: "0.9375vw",
                            fontWeight: 400,
                            color: "#333333",
                          }}
                        >
                          Locate your nearest clinic and seamlessly book your
                          appointment
                        </span>
                      </Row>
                    </Col>
                    <Input
                      style={{
                        width: "23.072916666666668vw",
                        fontSize: "0.8333333333333334vw",
                        height: "5.015673981191223vh",
                        fontWeight: 400,
                        borderRadius: 5,
                        border: "1px solid #878787",
                      }}
                      className="d-flex ai-center"
                      placeholder="Search Clinics by Name or ID"
                      prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
                      value={searchInput}
                      onChange={(e) => {
                        handleGetClinicsBySearch(e);
                        setSearchInput(e.target.value);
                        setClinicSearchInvisible(true);
                      }}
                    />
                  </Row>
                )}

                <div
                  style={{
                    width: "100%",
                    height: !displaySlots && "73vh",
                    padding: "0 5.2084vw",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.936vw",
                  }}
                >
                  {allDoctorsSlots && allDoctorsSlots.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.52vw",
                      }}
                    >
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "1.563vw",
                          gap: "0.20vw",
                        }}
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSize: "1.0417vw",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "140.04%" /* 28.008px */,
                          }}
                        >
                          Available Doctors
                        </span>
                        <span
                          style={{
                            color: "#333",
                            fontSize: "0.834vw",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "140.04%" /* 28.008px */,
                          }}
                        >
                          Explore available doctors' schedules and select a
                          convenient time slot to book your appointment.
                        </span>
                      </Row>
                      <Row>
                        <span
                          style={{
                            color: "#A3A3A3",
                            fontSize: "0.834vw",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "140.04%" /* 28.008px */,
                          }}
                        >
                          Found {allDoctorsSlots.length} results
                        </span>
                      </Row>
                    </div>
                  )}
                  {displaySlots ? (
                    allDoctorsSlots && allDoctorsSlots.length > 0 ? (
                      allDoctorsSlots.map((item) => {
                        return (
                          <div
                            style={{
                              border: "1px solid #D9D9D9",
                              margin: "0 0 5px 0",
                              borderRadius: 3,
                            }}
                          >
                            <Collapse ghost className="w-100">
                              <CollapsePanel
                                showArrow={false}
                                header={
                                  <div
                                    style={{
                                      padding:
                                        "1.0416666666666667vw 2.089864158829676vh",
                                      display: "flex",
                                      width: "100%",
                                    }}
                                  >
                                    <Col span={4}>
                                      <Row>
                                        <Col>
                                          <img
                                            alt=""
                                            src={defpropic}
                                            style={{
                                              width: "4.791666666666667vw",
                                              // height: 92,
                                              borderRadius:
                                                "0.2604166666666667vw",
                                            }}
                                          ></img>
                                        </Col>
                                        <Col
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            margin: "0 0 0 10px",
                                          }}
                                        >
                                          <Row>
                                            <span
                                              style={{
                                                fontWeight: 600,
                                                fontSize:
                                                  "0.7291666666666666vw",
                                                textDecorationLine: "underline",
                                                color: "#2CA3FA",
                                                display: "block",
                                                width: "100%",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {item.staff_entity.name}
                                            </span>
                                          </Row>
                                          <Row>
                                            <span
                                              style={{
                                                fontWeight: 600,
                                                fontSize:
                                                  " 0.7291666666666666vw",
                                                color: "#000000",
                                              }}
                                            >
                                              #ID
                                              <span
                                                style={{
                                                  color: "#7D7D7D",
                                                }}
                                              >
                                                &nbsp;{item.staff_entity.id}
                                              </span>
                                            </span>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col span={16}></Col>
                                    <Col
                                      span={4}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Button
                                        style={{
                                          fontSize: " 0.7291666666666666vw",
                                          fontWeight: 600,
                                          height: "4.179728317659352vh",
                                          color: "#FFF",
                                          border: "1px solid #3498DB",
                                          borderRadius: "0.2604166666666667vw",
                                          padding: "0 1.3020833333333333vw",
                                          background: "#3498DB",
                                        }}
                                      >
                                        Book Appointment
                                      </Button>
                                    </Col>
                                  </div>
                                }
                              >
                                <Row
                                  style={{ display: "flex", gap: "0.9375vw" }}
                                >
                                  {item.slots &&
                                    item.slots.map((data) => {
                                      const currentDate = moment().format(
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      );
                                      const inputDateMoment = moment(
                                        data.from_time
                                      ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                                      const date1 = moment(inputDateMoment);
                                      const date2 = moment(currentDate);
                                      return (
                                        <>
                                          {
                                            <Button
                                              className={
                                                data.status.name ===
                                                  "Scheduled" ||
                                                data.status.name ===
                                                  "Rescheduled" ||
                                                data.status.name === "Confirmed"
                                                  ? "doctor-slot-div-booked"
                                                  : "slot-div"
                                              }
                                              onClick={() => {
                                                if (
                                                  data.status.name ===
                                                    "Scheduled" ||
                                                  data.status.name ===
                                                    "Rescheduled" ||
                                                  data.status.name ===
                                                    "Confirmed"
                                                ) {
                                                  message.warning(
                                                    "Slot is booked"
                                                  );
                                                } else {
                                                  handleOpenBookAppointmentModal(
                                                    data.id,
                                                    item.staff_entity.id,
                                                    data.from_time
                                                  );
                                                }
                                              }}
                                              disabled={
                                                date1.isBefore(date2)
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <span>
                                                {moment(data.from_time).format(
                                                  "hh:mm a"
                                                )}
                                              </span>
                                            </Button>
                                          }
                                        </>
                                      );
                                    })}
                                </Row>
                              </CollapsePanel>
                            </Collapse>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className="w-100 d-flex flex-col jc-center ai-center"
                        style={{
                          fontSize: "1.0416666666666667vw",
                          padding: 100,
                          fontWeight: 600,
                          gap: 20,
                        }}
                      >
                        <img alt="" src={vectorimage} style={{ width: 300 }} />
                        <span>
                          Please select a date to view available doctors for
                          that day.
                        </span>
                      </div>
                    )
                  ) : (
                    <>
                      {clinicDetails && clinicDetails.length > 0 ? (
                        clinicDetails.map((item) => {
                          return (
                            <ClinicDetails
                              // handleGetAllSlotDetails={handleGetAllSlotDetails}
                              item={item}
                              name={item.name}
                              email={item.email}
                              username={item.username}
                              clinic_user_id={item.clinic_id}
                              clinic_branch_id={
                                item.branch_id ? item.branch_id : "NA"
                              }
                              zip_code={item.zip_code}
                              branch_id={item.branch_id && item.branch_id}
                              address_line_one={item.address_line_one}
                              address_line_two={item.address_line_two}
                              latitude={
                                item.coordinate && item.coordinate.latitude
                              }
                              longitude={
                                item.coordinate && item.coordinate.longitude
                              }
                              city={item.city}
                              country={item.country}
                              phone_number={item.phone_number}
                              province={item.province}
                              treatmentTypes={treatmentTypes}
                              clinic_profile_pic={
                                item.profile_picture_url &&
                                item.profile_picture_url.split(".com/")[1]
                              }
                              handleGetClinicDetails={handleGetClinicDetails}
                              setClinicId={setClinicId}
                              setbranchId={setbranchId}
                              handleGetTreatmentsTypes={
                                handleGetTreatmentsTypes
                              }
                              displaySlots={displaySlots}
                              setDisplaySlots={setDisplaySlots}
                              selectedDate={selectedDate}
                            />
                          );
                        })
                      ) : (
                        <div className="w-100 d-flex jc-center ai-center h-100">
                          <span style={{ fontSize: 22, fontWeight: 700 }}>
                            No Clinics
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Row>
            </div>
          </Form>
          <Modal
            open={openBookAppointmentModal}
            width={"450px"}
            closable={false}
            footer={false}
            centered
            destroyOnClose={true}
            style={{
              borderRadius: 5,
              background: "#FFF",
              boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            {/* {togglePayment === false ? (
          <> */}
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "18px",
                  color: "#525252",
                }}
              >
                Confirm Slot
              </span>
              <CloseOutlined
                style={{ color: "#FF0000", fontSize: 20 }}
                onClick={() => {
                  setopenBookAppointmentModal(false);
                  setfirst("");
                }}
              />
            </Row>
            <Row
              style={{
                padding: "35px 0 35px 55px",
                background: "#EEFEFF",
                border: "1px dashed #3CC9D2",
                margin: "20px 0",
                gap: 30,
              }}
            >
              <Row
                style={{
                  width: "100%",
                }}
                className="d-flex ai-center"
              >
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "#525252",
                  }}
                >
                  You have selected the slot at:&nbsp;
                </span>
                <Button
                  className="slot-div-active"
                  onClick={() => setopenBookAppointmentModal(true)}
                >
                  <span>{moment(selectedSlot).format("hh:mm a")}</span>
                </Button>
              </Row>
            </Row>
            <Row className="w-100">
              {first && (
                <Alert
                  className="w-100"
                  message="Error"
                  description={first}
                  type="error"
                />
              )}
            </Row>
            <Row
              className="w-100 d-flex jc-center"
              style={{ gap: 17, margin: "20px 0 0 0" }}
            >
              <Button
                onClick={() => {
                  setopenBookAppointmentModal(false);
                  setfirst("");
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleCreateAppoinment();
                }}
              >
                Confirm
              </Button>
            </Row>
          </Modal>
        </Row>
      </Spin>
    </div>
  );
};

export default Clinics;
