import {
  Button,
  Calendar,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import moment from "moment";
import { DATETIME_FORMAT_FULLDATE } from "../../../../../utils/appointment_constants/AppointmentConstants";
import axios from "axios";
import API_BASE_URL from "../../../../../env";
import { LoadingOutlined } from "@ant-design/icons";
import { UserAuth } from "../../../../../context_api/ContextApi";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const ClinicalReportForm = (props) => {
  const {
    handleGetSelectedPatientReports,
    setDisableFormItems,
    allReportDetails,
    allClinicalReports,
    handleGetAllClinicalReportForms,
    appointmentData,
  } = props;
  const [form] = Form.useForm();
  const { patient_id, index } = useParams();
  const clinic_id = localStorage.getItem("clinic_id");
  const Token = localStorage.getItem("Token");
  const { spinner, setSpinner } = UserAuth();
  const [previousDentalTreatments, setPreviousDentalTreatments] = useState();
  const [softTissueExamination, setSoftTissueExamination] = useState();
  const [radiographicFindings, setRadiographicFindings] = useState();
  const [periodontal, setPeriodontal] = useState();
  const [occlusion, setOcclusion] = useState();
  const [cln_report_inp_enables, setClinicReportInputEnb] = useState(true);

  const uploadclinicReports =
    allClinicalReports &&
    allClinicalReports
      .filter((item) => item.created_at == moment().format("YYYY-MM-DD"))
      .filter((item) => item.clinic_id == localStorage.getItem("clinic_id"));

  const viewclinicReports =
    allClinicalReports &&
    allReportDetails &&
    index &&
    allClinicalReports
      .filter((item) =>
        item.clinic_id == allReportDetails[index].key.clinicId
          ? allReportDetails[index].key.clinicId
          : localStorage.getItem("clinic_id")
      )
      .filter((item) => item.created_at == allReportDetails[index].key.date);

  let selectedPatientReports = viewclinicReports && viewclinicReports[0];

  const handleSubmitClinicalReport = (value) => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    const payload = {
      clinic_id: clinic_id,
      patient_id: patient_id,
      oral_hygiene_status: value.oral_hygiene_status
        ? value.oral_hygiene_status
        : "",
      missing_teeth: value.missing_teeth ? value.missing_teeth : "",
      carious_teeth: value.carious_teeth ? value.carious_teeth : "",
      filled_teeth: value.filled_teeth ? value.filled_teeth : "",
      other_observations: value.other_observations
        ? value.other_observations
        : "",
      primary_diagnosis: value.primary_diagnosis ? value.primary_diagnosis : "",
      secondary_diagnosis: value.secondary_diagnosis
        ? value.secondary_diagnosis
        : "",
      last_visited_date: value.date
        ? moment(value.date).format(DATETIME_FORMAT_FULLDATE)
        : moment().format(DATETIME_FORMAT_FULLDATE),
      reason_for_last_visit: value.reason_for_last_visit
        ? value.reason_for_last_visit
        : "",
      soft_tissue_examination: softTissueExamination
        ? softTissueExamination
        : value.soft_tissue_examination
        ? value.soft_tissue_examination
        : "",
      previous_dental_treatments: previousDentalTreatments
        ? previousDentalTreatments
        : value.previous_dental_treatments
        ? JSON.stringify(value.previous_dental_treatments)
        : "",
      immediate_care: value.immediate_care ? value.immediate_care : "",
      short_term_treatment: value.short_term_treatment
        ? value.short_term_treatment
        : "",
      long_term_treatment: value.long_term_treatment
        ? value.long_term_treatment
        : "",
      gingival_and_periodontal_status: periodontal
        ? periodontal
        : value.gingival_and_periodontal_status
        ? value.gingival_and_periodontal_status
        : "",
      radiographic_findings: radiographicFindings
        ? radiographicFindings
        : value.radiographic_findings
        ? value.radiographic_findings
        : "",
      occlusion: occlusion ? occlusion : value.occlusion ? value.occlusion : "",
      medical_history: value.medical_history ? value.medical_history : "",
      xrays_attached: value.xrays_attached ? value.xrays_attached : "",
    };
    if (
      currentUrl.includes("/add-report") &&
      currentUrl.includes("clinic-") &&
      uploadclinicReports &&
      uploadclinicReports.length > 0
    ) {
      message.error("report already exists for this visit");
      form.resetFields();
      setPreviousDentalTreatments("");
      setSoftTissueExamination("");
      setRadiographicFindings("");
      setPeriodontal("");
      setOcclusion("");
      setSpinner(false);
      handleGetAllClinicalReportForms();
    } else {
      if (selectedPatientReports) {
        axios
          .put(
            `${API_BASE_URL}/api/v1/report/updateForm/${selectedPatientReports.id}`,
            payload,
            config
          )
          .then((res) => {
            handleGetAllClinicalReportForms();
            setSpinner(false);
            // setDisableFormItems(true);
            // form.resetFields();
            setPreviousDentalTreatments("");
            setSoftTissueExamination("");
            setRadiographicFindings("");
            setPeriodontal("");
            setOcclusion("");
            setClinicReportInputEnb(true);
            // navigate("/clinic-admin/patients");
          })
          .err((res) => {
            setTimeout(() => {
              setSpinner(false);
              handleGetAllClinicalReportForms();
            }, 1000);
          });
      } else {
        axios
          .post(
            `${API_BASE_URL}/api/v1/report/treatment-type-id/createForm?treatmentTypeId=${appointmentData.treatment_type.id}`,
            payload,
            config
          )
          .then((res) => {
            setTimeout(() => {
              setSpinner(false);
              handleGetAllClinicalReportForms();
              form.resetFields();
              setPreviousDentalTreatments("");
              setSoftTissueExamination("");
              setRadiographicFindings("");
              setPeriodontal("");
              setOcclusion("");
              handleGetSelectedPatientReports();
            }, 1000);
          })
          .err((res) => {
            setTimeout(() => {
              setSpinner(false);
              handleGetAllClinicalReportForms();
            }, 1000);
          });
      }
    }
  };

  const handleCancelUpdate = () => {
    form.resetFields();
    handleGetAllClinicalReportForms();
    setClinicReportInputEnb(true);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const currentUrl = window.location.href;

  useEffect(() => {
    handleGetAllClinicalReportForms();
  }, []);

  return (
    <div>
      <Form onFinish={handleSubmitClinicalReport} form={form}>
        <Row
          className="d-flex ai-center jc-between"
          style={{
            background: "#F4F4F4",
            width: "100%",
            padding: "10px 20px",
            margin: "10px 0 5px 0",
            gap: 10,
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 18,
              color: "#525252",
            }}
          >
            {selectedPatientReports ? (
              <>
                {cln_report_inp_enables
                  ? "View Clinical report"
                  : "Edit Clinical report"}
              </>
            ) : (
              "Add Clinical report"
            )}
          </span>
          {currentUrl.includes("/view-report") &&
            currentUrl.includes("clinic-") && (
              <Row className="d-flex jc-center ai-center" style={{ gap: 10 }}>
                {cln_report_inp_enables ? (
                  <Button
                    className="go-back-btn"
                    onClick={() => setClinicReportInputEnb(false)}
                  >
                    {selectedPatientReports && "Edit"}
                  </Button>
                ) : (
                  <>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className="add-clinical-report-btn"
                      >
                        {selectedPatientReports && "Save"}
                      </Button>
                    </Form.Item>
                    <Button
                      className="cancel-clinical-report-btn"
                      onClick={() => handleCancelUpdate()}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Row>
            )}
          <div></div>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12} xl={6}>
            <div className="profile-pic-col-main-div">
              <div
                style={{
                  padding: "10px 0 10px 10px",
                  margin: "0 25px 0 0",
                  background: "#EBF5FD",
                  color: "#2CA3FA",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Dental History:
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} xl={18}>
            <div
              style={{
                padding: "10px 0 10px 10px",
                background: "#EBF5FD",
                color: "#2CA3FA",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Current Dental Health Assessment:
            </div>
          </Col>
        </Row>
        <Spin
          indicator={antIcon}
          size="large"
          spinning={spinner && true}
          delay={500}
        >
          {currentUrl.includes("patient/view-report") ? (
            <>
              {selectedPatientReports ? (
                <Row style={{ margin: "15px 0 30px 0" }}>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <div
                      className="profile-pic-col-main-div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 35,
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Last Dental Visit
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.last_visited_date ? (
                            <Input
                              value={
                                selectedPatientReports.last_visited_date
                                  ? selectedPatientReports.last_visited_date
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Reason for Last Visit
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.reason_for_last_visit ? (
                            <Input
                              value={
                                selectedPatientReports.reason_for_last_visit
                                  ? selectedPatientReports.reason_for_last_visit
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     defaultValue="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Previous Dental Treatments:
                        </span>
                        {selectedPatientReports &&
                        selectedPatientReports.previous_dental_treatments ? (
                          JSON.parse(
                            selectedPatientReports.previous_dental_treatments
                          ).map((item) => {
                            return (
                              <Space direction="vertical">
                                <Checkbox
                                  checked={true}
                                  style={{ width: "100%" }}
                                  disabled
                                >
                                  {item}
                                </Checkbox>
                              </Space>
                            );
                          })
                        ) : (
                          <Input
                            value="NA"
                            disabled
                            style={{
                              fontWeight: "400",
                              width: "80%",
                              fontSize: "16px",
                              height: "40px",
                              borderRadius: "3px",
                              border: "1px solid #E3E3E3",
                            }}
                          />
                        )}

                        {/* <Space direction="vertical">
                          <Checkbox checked={true} style={{ width: "100%" }}>
                            {selectedPatientReports &&
                              selectedPatientReports.previous_dental_treatments}
                          </Checkbox>
                        </Space> */}
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <div
                      className="profile-pic-col-main-div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 35,
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Oral Hygiene Status:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.oral_hygiene_status ? (
                            <Input
                              value={
                                selectedPatientReports.oral_hygiene_status
                                  ? selectedPatientReports.oral_hygiene_status
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Soft Tissue Examination (Oral Mucosa):
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.soft_tissue_examination ? (
                            <Input
                              defaultValue={
                                selectedPatientReports.soft_tissue_examination
                                  ? selectedPatientReports.soft_tissue_examination
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     defaultValue="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Gingival & Periodontal Status:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.gingival_and_periodontal_status ? (
                            <Input
                              defaultValue={
                                selectedPatientReports.gingival_and_periodontal_status
                                  ? selectedPatientReports.gingival_and_periodontal_status
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     defaultValue="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Radiographic Findings: (If applicable)
                        </span>
                        <Form.Item name="xrays_attached">
                          <Radio.Group style={{ margin: "10px 0 0 0" }}>
                            <Space direction="vertical">
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  color: "#494949",
                                }}
                              >
                                X-rays Attached:
                              </span>
                              {
                                selectedPatientReports && (
                                  // selectedPatientReports.xrays_attached ? (
                                  <Input
                                    defaultValue={
                                      selectedPatientReports.xrays_attached
                                        ? selectedPatientReports.xrays_attached
                                        : "NA"
                                    }
                                    disabled
                                    style={{
                                      fontWeight: "400",
                                      width: "80%",
                                      fontSize: "16px",
                                      height: "40px",
                                      borderRadius: "3px",
                                      border: "1px solid #E3E3E3",
                                    }}
                                  />
                                )
                                // ) : (
                                //   <Input
                                //     defaultValue="NA"
                                //     disabled
                                //     style={{
                                //       fontWeight: "400",
                                //       width: "80%",
                                //       fontSize: "16px",
                                //       height: "40px",
                                //       borderRadius: "3px",
                                //       border: "1px solid #E3E3E3",
                                //     }}
                                //   />
                                // )
                              }
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                        <Space direction="vertical">
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#494949",
                            }}
                          >
                            Findings:
                          </span>
                          {
                            selectedPatientReports && (
                              // selectedPatientReports.radiographic_findings ? (
                              <Input
                                defaultValue={
                                  selectedPatientReports.radiographic_findings
                                    ? selectedPatientReports.radiographic_findings
                                    : "NA"
                                }
                                disabled
                                style={{
                                  fontWeight: "400",
                                  width: "80%",
                                  fontSize: "16px",
                                  height: "40px",
                                  borderRadius: "3px",
                                  border: "1px solid #E3E3E3",
                                }}
                              />
                            )
                            // ) : (
                            //   <Input
                            //     defaultValue="NA"
                            //     disabled
                            //     style={{
                            //       fontWeight: "400",
                            //       width: "80%",
                            //       fontSize: "16px",
                            //       height: "40px",
                            //       borderRadius: "3px",
                            //       border: "1px solid #E3E3E3",
                            //     }}
                            //   />
                            // )
                          }
                        </Space>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <div
                      className="profile-pic-col-main-div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 35,
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#494949",
                          }}
                        >
                          Teeth Status (Use Universal Numbering System)
                        </span>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Missing Teeth:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.missing_teeth ? (
                            <Input
                              value={
                                selectedPatientReports.missing_teeth
                                  ? selectedPatientReports.missing_teeth
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Carious Teeth:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.carious_teeth ? (
                            <Input
                              value={
                                selectedPatientReports.carious_teeth
                                  ? selectedPatientReports.carious_teeth
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Filled Teeth:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.filled_teeth ? (
                            <Input
                              value={
                                selectedPatientReports.filled_teeth
                                  ? selectedPatientReports.filled_teeth
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Other Observations:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.other_observations ? (
                            <Input
                              value={
                                selectedPatientReports.other_observations
                                  ? selectedPatientReports.other_observations
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Occlusion:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.occlusion ? (
                            <Input
                              value={
                                selectedPatientReports.occlusion
                                  ? selectedPatientReports.occlusion
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <div
                      className="profile-pic-col-main-div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 35,
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#494949",
                          }}
                        >
                          Diagnosis:
                        </span>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Provisional Diagnosis
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.primary_diagnosis ? (
                            <Input
                              value={
                                selectedPatientReports.primary_diagnosis
                                  ? selectedPatientReports.primary_diagnosis
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Final Diagnosis:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.secondary_diagnosis ? (
                            <Input
                              value={
                                selectedPatientReports.secondary_diagnosis
                                  ? selectedPatientReports.secondary_diagnosis
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#494949",
                          }}
                        >
                          Recommendations & Treatment Plan:
                        </span>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Immediate Care:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.immediate_care ? (
                            <Input
                              value={
                                selectedPatientReports.immediate_care
                                  ? selectedPatientReports.immediate_care
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Short Term Treatment:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.short_term_treatment ? (
                            <Input
                              value={
                                selectedPatientReports.short_term_treatment
                                  ? selectedPatientReports.short_term_treatment
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Long Term Treatment:
                        </span>
                        {
                          selectedPatientReports && (
                            // selectedPatientReports.long_term_treatment ? (
                            <Input
                              value={
                                selectedPatientReports.long_term_treatment
                                  ? selectedPatientReports.long_term_treatment
                                  : "NA"
                              }
                              disabled
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          )
                          // ) : (
                          //   <Input
                          //     value="NA"
                          //     disabled
                          //     style={{
                          //       fontWeight: "400",
                          //       width: "80%",
                          //       fontSize: "16px",
                          //       height: "40px",
                          //       borderRadius: "3px",
                          //       border: "1px solid #E3E3E3",
                          //     }}
                          //   />
                          // )
                        }
                      </Row>
                    </div>
                  </Col>
                </Row>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "30vh",
                    fontSize: 22,
                    fontWeight: 500,
                  }}
                  className="d-flex jc-center ai-center"
                >
                  you have no clinical reports
                </div>
              )}
            </>
          ) : (
            <>
              {selectedPatientReports &&
              currentUrl.includes("/view-report") &&
              currentUrl.includes("clinic-") ? (
                <Row style={{ margin: "15px 0 0 0" }}>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <div
                      className="profile-pic-col-main-div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 35,
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Last Dental Visit
                        </span>
                        {selectedPatientReports && (
                          <>
                            {cln_report_inp_enables ? (
                              <Input
                                disabled
                                value={
                                  selectedPatientReports.last_visited_date
                                    ? moment(
                                        selectedPatientReports.last_visited_date
                                      ).format("YYYY-MM-DD")
                                    : "NA"
                                }
                                style={{
                                  fontWeight: "400",
                                  width: "80%",
                                  fontSize: "16px",
                                  height: "40px",
                                  borderRadius: "3px",
                                  border: "1px solid #E3E3E3",
                                }}
                              />
                            ) : (
                              <Form.Item
                                initialValue={
                                  selectedPatientReports.last_visited_date &&
                                  moment(
                                    selectedPatientReports.last_visited_date
                                  )
                                }
                                name="date"
                              >
                                <Calendar
                                  fullscreen={false}
                                  disabled={cln_report_inp_enables}
                                  disabledDate={disabledDate}
                                  style={{
                                    width: "80%",
                                    borderRadius: "3px",
                                    border: "1px solid #E3E3E3",
                                  }}
                                />
                              </Form.Item>
                            )}
                          </>
                          // ) : (
                          //   <Form.Item name="date">
                          //     <Calendar
                          //       fullscreen={false}
                          //       disabledDate={disabledDate}
                          //       style={{
                          //         width: "80%",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Reason for Last Visit
                        </span>

                        {selectedPatientReports && (
                          <Form.Item
                            name="reason_for_last_visit"
                            initialValue={
                              selectedPatientReports.reason_for_last_visit
                                ? selectedPatientReports.reason_for_last_visit
                                : "NA"
                            }
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="reason_for_last_visit">
                          //     <Input
                          //       placeholder="Enter reason"
                          //       style={{
                          //         fontWeight: "400",
                          //         width: "80%",
                          //         fontSize: "16px",
                          //         height: "40px",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Medical History
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            name="medical_history"
                            initialValue={
                              selectedPatientReports.medical_history
                                ? selectedPatientReports.medical_history
                                : "NA"
                            }
                          >
                            <Input
                              // placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Previous Dental Treatments:
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.previous_dental_treatments &&
                              selectedPatientReports.previous_dental_treatments.includes(
                                "/"
                              )
                                ? JSON.parse(
                                    selectedPatientReports.previous_dental_treatments
                                  )
                                : selectedPatientReports.previous_dental_treatments
                            }
                            name="previous_dental_treatments"
                          >
                            <Checkbox.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <Checkbox value="Fillings">Fillings</Checkbox>
                                <Checkbox value="Extractions">
                                  Extractions
                                </Checkbox>
                                <Checkbox value="Crowns">Crowns</Checkbox>
                                <Checkbox value="Root Canal">
                                  Root Canal
                                </Checkbox>
                                <Checkbox value="Dentures/Partial Dentures">
                                  Dentures/Partial Dentures
                                </Checkbox>
                                <Checkbox value="Implants">Implants</Checkbox>
                                <Checkbox value="Scaling">Scaling</Checkbox>
                                <Checkbox value="Orthodontic Treatment">
                                  Orthodontic Treatment
                                </Checkbox>
                                {/* <Checkbox value="others">
                                  More...
                                  <Input
                                    onChange={(e) =>
                                      setPreviousDentalTreatments(
                                        e.target.value
                                      )
                                    }
                                    placeholder="Type here"
                                    disabled={cln_report_inp_enables}
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  />
                                </Checkbox> */}
                              </Space>
                            </Checkbox.Group>
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="previous_dental_treatments">
                          //     <Checkbox.Group style={{ margin: "10px 0 0 0" }}>
                          //       <Space direction="vertical">
                          //         <Checkbox value="Fillings">Fillings</Checkbox>
                          //         <Checkbox value="Extractions">
                          //           Extractions
                          //         </Checkbox>
                          //         <Checkbox value="Crowns">Crowns</Checkbox>
                          //         <Checkbox value="Root Canal">
                          //           Root Canal
                          //         </Checkbox>
                          //         <Checkbox value="Dentures/Partial Dentures">
                          //           Dentures/Partial Dentures
                          //         </Checkbox>
                          //         <Checkbox value="Implants">Implants</Checkbox>
                          //         <Checkbox value="Orthodontic Treatment">
                          //           Orthodontic Treatment
                          //         </Checkbox>
                          //         <Checkbox value="others">
                          //           More...
                          //           <Input
                          //             onChange={(e) =>
                          //               setPreviousDentalTreatments(
                          //                 e.target.value
                          //               )
                          //             }
                          //             placeholder="Type here"
                          //             style={{
                          //               marginLeft: 10,
                          //             }}
                          //           />
                          //         </Checkbox>
                          //       </Space>
                          //     </Checkbox.Group>
                          //   </Form.Item>
                        )}
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <div
                      className="profile-pic-col-main-div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 35,
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Oral Hygiene Status:
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.oral_hygiene_status &&
                              selectedPatientReports.oral_hygiene_status
                            }
                            name="oral_hygiene_status"
                          >
                            <Radio.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <Radio value="Excellent">Excellent</Radio>
                                <Radio value="Good">Good</Radio>
                                <Radio value="Fair">Fair</Radio>
                                <Radio value="Poor">Poor</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="oral_hygiene_status">
                          //     <Radio.Group style={{ margin: "10px 0 0 0" }}>
                          //       <Space direction="vertical">
                          //         <Radio value="Excellent">Excellent</Radio>
                          //         <Radio value="Good">Good</Radio>
                          //         <Radio value="Fair">Fair</Radio>
                          //         <Radio value="Poor">Poor</Radio>
                          //       </Space>
                          //     </Radio.Group>
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Soft Tissue Examination (Oral Mucosa):
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            name="soft_tissue_examination"
                            initialValue={
                              selectedPatientReports.soft_tissue_examination &&
                              selectedPatientReports.soft_tissue_examination
                            }
                          >
                            <Radio.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <Radio value="Normal">Normal</Radio>
                                <Radio value="Abnormal">
                                  Abnormal:
                                  <Input
                                    onChange={(e) =>
                                      setSoftTissueExamination(e.target.value)
                                    }
                                    placeholder={
                                      selectedPatientReports.soft_tissue_examination !==
                                        "Normal" &&
                                      selectedPatientReports.soft_tissue_examination !==
                                        "Abnormal"
                                        ? selectedPatientReports.soft_tissue_examination
                                        : "Describe"
                                    }
                                    disabled={cln_report_inp_enables}
                                    style={{
                                      width: 100,
                                      marginLeft: 10,
                                    }}
                                  />
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="soft_tissue_examination">
                          //     <Radio.Group style={{ margin: "10px 0 0 0" }}>
                          //       <Space direction="vertical">
                          //         <Radio value="Normal">Normal</Radio>
                          //         <Radio value="Abnormal">
                          //           Abnormal:
                          //           <Input
                          //             onChange={(e) =>
                          //               setSoftTissueExamination(e.target.value)
                          //             }
                          //             placeholder="Describe"
                          //             style={{
                          //               width: 100,
                          //               marginLeft: 10,
                          //             }}
                          //           />
                          //         </Radio>
                          //       </Space>
                          //     </Radio.Group>
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Gingival & Periodontal Status:
                        </span>

                        {selectedPatientReports && (
                          <Form.Item
                            name="gingival_and_periodontal_status"
                            initialValue={
                              selectedPatientReports.gingival_and_periodontal_status &&
                              selectedPatientReports.gingival_and_periodontal_status
                            }
                          >
                            <Radio.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <Radio value="Healthy">Healthy</Radio>
                                <Radio value="Gingivitis">Gingivitis</Radio>
                                <Radio value="Periodontitis">
                                  Periodontitis :
                                  <Input
                                    onChange={(e) =>
                                      setPeriodontal(e.target.value)
                                    }
                                    placeholder={
                                      selectedPatientReports.gingival_and_periodontal_status !==
                                        "Healthy" &&
                                      selectedPatientReports.gingival_and_periodontal_status !==
                                        "Gingivitis" &&
                                      selectedPatientReports.gingival_and_periodontal_status !==
                                        "Periodontitis"
                                        ? selectedPatientReports.gingival_and_periodontal_status
                                        : "stages"
                                    }
                                    disabled={cln_report_inp_enables}
                                    style={{
                                      width: 100,
                                      marginLeft: 10,
                                    }}
                                  />
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="gingival_and_periodontal_status">
                          //     <Radio.Group style={{ margin: "10px 0 0 0" }}>
                          //       <Space direction="vertical">
                          //         <Radio value="Healthy">Healthy</Radio>
                          //         <Radio value="Gingivitis">Gingivitis</Radio>
                          //         <Radio value="Periodontitis">
                          //           Periodontitis :
                          //           <Input
                          //             onChange={(e) =>
                          //               setPeriodontal(e.target.value)
                          //             }
                          //             placeholder="stages"
                          //             style={{
                          //               width: 100,
                          //               marginLeft: 10,
                          //             }}
                          //           />
                          //         </Radio>
                          //       </Space>
                          //     </Radio.Group>
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Radiographic Findings: (If applicable)
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            name="xrays_attached"
                            initialValue={
                              selectedPatientReports.xrays_attached &&
                              selectedPatientReports.xrays_attached
                            }
                          >
                            <Radio.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#494949",
                                  }}
                                >
                                  X-rays Attached:
                                </span>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="xrays_attached">
                          //     <Radio.Group style={{ margin: "10px 0 0 0" }}>
                          //       <Space direction="vertical">
                          //         <span
                          //           style={{
                          //             fontWeight: "400",
                          //             fontSize: "13px",
                          //           }}
                          //         >
                          //           X-rays Attached:
                          //         </span>
                          //         <Radio value="Yes">Yes</Radio>
                          //         <Radio value="No">No</Radio>
                          //       </Space>
                          //     </Radio.Group>
                          //   </Form.Item>
                        )}
                        <Space direction="vertical">
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#494949",
                            }}
                          >
                            Findings:
                          </span>
                          {selectedPatientReports && (
                            <Form.Item
                              name="radiographic_findings"
                              initialValue={
                                selectedPatientReports.radiographic_findings
                                  ? selectedPatientReports.radiographic_findings
                                  : "NA"
                              }
                            >
                              <Input
                                onChange={(e) =>
                                  setRadiographicFindings(e.target.value)
                                }
                                placeholder="Type here"
                                disabled={cln_report_inp_enables}
                                style={{
                                  width: 200,
                                  marginLeft: 10,
                                }}
                              />
                            </Form.Item>
                            // ) : (
                            //   <Form.Item name="radiographic_findings">
                            //     <Input
                            //       onChange={(e) =>
                            //         setRadiographicFindings(e.target.value)
                            //       }
                            //       placeholder="Type here"
                            //       style={{
                            //         width: 200,
                            //         marginLeft: 10,
                            //       }}
                            //     />
                            //   </Form.Item>
                          )}
                        </Space>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <div
                      className="profile-pic-col-main-div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 35,
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#494949",
                          }}
                        >
                          Teeth Status (Use Universal Numbering System)
                        </span>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Missing Teeth:
                        </span>

                        {selectedPatientReports && (
                          <Form.Item
                            name="missing_teeth"
                            initialValue={
                              selectedPatientReports.missing_teeth
                                ? selectedPatientReports.missing_teeth
                                : "NA"
                            }
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="missing_teeth">
                          //     <Input
                          //       placeholder="Enter reason"
                          //       style={{
                          //         fontWeight: "400",
                          //         width: "80%",
                          //         fontSize: "16px",
                          //         height: "40px",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Carious Teeth:
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.carious_teeth
                                ? selectedPatientReports.carious_teeth
                                : "NA"
                            }
                            name="carious_teeth"
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="carious_teeth">
                          //     <Input
                          //       placeholder="Enter reason"
                          //       style={{
                          //         fontWeight: "400",
                          //         width: "80%",
                          //         fontSize: "16px",
                          //         height: "40px",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Filled Teeth:
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.filled_teeth
                                ? selectedPatientReports.filled_teeth
                                : "NA"
                            }
                            name="filled_teeth"
                          >
                            <Input
                              name=""
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="filled_teeth">
                          //     <Input
                          //       name=""
                          //       placeholder="Enter reason"
                          //       style={{
                          //         fontWeight: "400",
                          //         width: "80%",
                          //         fontSize: "16px",
                          //         height: "40px",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Other Observations:
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.other_observations
                                ? selectedPatientReports.other_observations
                                : "NA"
                            }
                            name="other_observations"
                          >
                            <Input
                              name=""
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="other_observations">
                          //     <Input
                          //       name=""
                          //       placeholder="Enter reason"
                          //       style={{
                          //         fontWeight: "400",
                          //         width: "80%",
                          //         fontSize: "16px",
                          //         height: "40px",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Occlusion:
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.occlusion &&
                              selectedPatientReports.occlusion
                            }
                            name="occlusion"
                          >
                            <Radio.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <Radio value="Normal">Normal</Radio>
                                <Radio value="Malocclussion">
                                  Malocclussion
                                  <Input
                                    onChange={(e) =>
                                      setOcclusion(e.target.value)
                                    }
                                    placeholder={
                                      selectedPatientReports.occlusion !==
                                        "Normal" &&
                                      selectedPatientReports.occlusion !==
                                        "Malocclussion"
                                        ? selectedPatientReports.occlusion
                                        : "Type here"
                                    }
                                    disabled={cln_report_inp_enables}
                                    style={{
                                      width: 100,
                                      marginLeft: 10,
                                    }}
                                  />
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="occlusion">
                          //     <Radio.Group style={{ margin: "10px 0 0 0" }}>
                          //       <Space direction="vertical">
                          //         <Radio value="Normal">Normal</Radio>
                          //         <Radio value="Malocclussion">
                          //           Malocclussion
                          //           <Input
                          //             onChange={(e) =>
                          //               setOcclusion(e.target.value)
                          //             }
                          //             placeholder="Type here"
                          //             style={{
                          //               width: 100,
                          //               marginLeft: 10,
                          //             }}
                          //           />
                          //         </Radio>
                          //       </Space>
                          //     </Radio.Group>
                          //   </Form.Item>
                        )}
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={6}>
                    <div
                      className="profile-pic-col-main-div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 35,
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#494949",
                          }}
                        >
                          Diagnosis:
                        </span>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Provisional Diagnosis
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            name="primary_diagnosis"
                            initialValue={
                              selectedPatientReports.primary_diagnosis
                                ? selectedPatientReports.primary_diagnosis
                                : "NA"
                            }
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="primary_diagnosis">
                          //     <Input
                          //       placeholder="Enter reason"
                          //       style={{
                          //         fontWeight: "400",
                          //         width: "80%",
                          //         fontSize: "16px",
                          //         height: "40px",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Final Diagnosis:
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.secondary_diagnosis
                                ? selectedPatientReports.secondary_diagnosis
                                : "NA"
                            }
                            name="secondary_diagnosis"
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="secondary_diagnosis">
                          //     <Input
                          //       placeholder="Enter reason"
                          //       style={{
                          //         fontWeight: "400",
                          //         width: "80%",
                          //         fontSize: "16px",
                          //         height: "40px",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#494949",
                          }}
                        >
                          Recommendations & Treatment Plan:
                        </span>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Immediate Care:
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.immediate_care
                                ? selectedPatientReports.immediate_care
                                : "NA"
                            }
                            name="immediate_care"
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="immediate_care">
                          //     <Input
                          //       placeholder="Enter reason"
                          //       style={{
                          //         fontWeight: "400",
                          //         width: "80%",
                          //         fontSize: "16px",
                          //         height: "40px",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Short Term Treatment:
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.short_term_treatment
                                ? selectedPatientReports.short_term_treatment
                                : "NA"
                            }
                            name="short_term_treatment"
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="short_term_treatment">
                          //     <Input
                          //       placeholder="Enter reason"
                          //       style={{
                          //         fontWeight: "400",
                          //         width: "80%",
                          //         fontSize: "16px",
                          //         height: "40px",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            margin: "10px 0 0 0",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "#494949",
                          }}
                        >
                          Long Term Treatment:
                        </span>
                        {selectedPatientReports && (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.long_term_treatment
                                ? selectedPatientReports.long_term_treatment
                                : "NA"
                            }
                            name="long_term_treatment"
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          // ) : (
                          //   <Form.Item name="long_term_treatment">
                          //     <Input
                          //       placeholder="Enter reason"
                          //       style={{
                          //         fontWeight: "400",
                          //         width: "80%",
                          //         fontSize: "16px",
                          //         height: "40px",
                          //         borderRadius: "3px",
                          //         border: "1px solid #E3E3E3",
                          //       }}
                          //     />
                          //   </Form.Item>
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              ) : (
                <>
                  {currentUrl.includes("/view-report") &&
                    currentUrl.includes("clinic-") && (
                      <div
                        style={{
                          width: "100%",
                          height: "30vh",
                          fontSize: 22,
                          fontWeight: 500,
                        }}
                        className="d-flex jc-center ai-center"
                      >
                        you have no clinical reports
                      </div>
                    )}
                </>
              )}
              {currentUrl.includes("/add-report") &&
                currentUrl.includes("clinic-") && (
                  <Row style={{ margin: "15px 0 0 0" }}>
                    <Col xs={24} sm={24} md={12} xl={6}>
                      <div
                        className="profile-pic-col-main-div"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 35,
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Last Dental Visit
                          </span>
                          {/* {selectedPatientReports &&
                        selectedPatientReports.last_visited_date ? (
                          <>
                            {cln_report_inp_enables ? (
                              <Input
                                disabled
                                value={moment(
                                  selectedPatientReports.last_visited_date
                                ).format("YYYY-MM-DD")}
                                style={{
                                  fontWeight: "400",
                                  width: "80%",
                                  fontSize: "16px",
                                  height: "40px",
                                  borderRadius: "3px",
                                  border: "1px solid #E3E3E3",
                                }}
                              />
                            ) : (
                              <Form.Item
                                initialValue={moment(
                                  selectedPatientReports.last_visited_date
                                )}
                                name="date"
                              >
                                <Calendar
                                  fullscreen={false}
                                  disabled={cln_report_inp_enables}
                                  disabledDate={disabledDate}
                                  style={{
                                    width: "80%",
                                    borderRadius: "3px",
                                    border: "1px solid #E3E3E3",
                                  }}
                                />
                              </Form.Item>
                            )}
                          </>
                        ) : ( */}
                          <Form.Item name="date">
                            <Calendar
                              fullscreen={false}
                              disabledDate={disabledDate}
                              style={{
                                width: "80%",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Reason for Last Visit
                          </span>

                          {/* {selectedPatientReports ? (
                          <Form.Item
                            name="reason_for_last_visit"
                            initialValue={
                              selectedPatientReports.reason_for_last_visit
                            }
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="reason_for_last_visit">
                            <Input
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Medical History
                          </span>

                          {/* {selectedPatientReports ? (
                          <Form.Item
                            name="reason_for_last_visit"
                            initialValue={
                              selectedPatientReports.reason_for_last_visit
                            }
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="medical_history">
                            <Input
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Previous Dental Treatments:
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.previous_dental_treatments.includes(
                                "/"
                              )
                                ? JSON.parse(
                                    selectedPatientReports.previous_dental_treatments
                                  )
                                : selectedPatientReports.previous_dental_treatments
                            }
                            name="previous_dental_treatments"
                          >
                            <Checkbox.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <Checkbox value="Fillings">Fillings</Checkbox>
                                <Checkbox value="Extractions">
                                  Extractions
                                </Checkbox>
                                <Checkbox value="Crowns">Crowns</Checkbox>
                                <Checkbox value="Root Canal">
                                  Root Canal
                                </Checkbox>
                                <Checkbox value="Dentures/Partial Dentures">
                                  Dentures/Partial Dentures
                                </Checkbox>
                                <Checkbox value="Implants">Implants</Checkbox>
                                <Checkbox value="Orthodontic Treatment">
                                  Orthodontic Treatment
                                </Checkbox>
                                <Checkbox value="others">
                                  More...
                                  <Input
                                    onChange={(e) =>
                                      setPreviousDentalTreatments(
                                        e.target.value
                                      )
                                    }
                                    placeholder="Type here"
                                    disabled={cln_report_inp_enables}
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  />
                                </Checkbox>
                              </Space>
                            </Checkbox.Group>
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="previous_dental_treatments">
                            <Checkbox.Group style={{ margin: "10px 0 0 0" }}>
                              <Space direction="vertical">
                                <Checkbox value="Fillings">Fillings</Checkbox>
                                <Checkbox value="Extractions">
                                  Extractions
                                </Checkbox>
                                <Checkbox value="Crowns">Crowns</Checkbox>
                                <Checkbox value="Root Canal">
                                  Root Canal
                                </Checkbox>
                                <Checkbox value="Dentures/Partial Dentures">
                                  Dentures/Partial Dentures
                                </Checkbox>
                                <Checkbox value="Implants">Implants</Checkbox>
                                <Checkbox value="Scaling">Scaling</Checkbox>
                                <Checkbox value="Orthodontic Treatment">
                                  Orthodontic Treatment
                                </Checkbox>
                                {/* <Checkbox value="others">
                                  More...
                                  <Input
                                    onChange={(e) =>
                                      setPreviousDentalTreatments(
                                        e.target.value
                                      )
                                    }
                                    placeholder="Type here"
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  />
                                </Checkbox> */}
                              </Space>
                            </Checkbox.Group>
                          </Form.Item>
                          {/* )} */}
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={6}>
                      <div
                        className="profile-pic-col-main-div"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 35,
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Oral Hygiene Status:
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.oral_hygiene_status
                            }
                            name="oral_hygiene_status"
                          >
                            <Radio.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <Radio value="Excellent">Excellent</Radio>
                                <Radio value="Good">Good</Radio>
                                <Radio value="Fair">Fair</Radio>
                                <Radio value="Poor">Poor</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="oral_hygiene_status">
                            <Radio.Group style={{ margin: "10px 0 0 0" }}>
                              <Space direction="vertical">
                                <Radio value="Excellent">Excellent</Radio>
                                <Radio value="Good">Good</Radio>
                                <Radio value="Fair">Fair</Radio>
                                <Radio value="Poor">Poor</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Soft Tissue Examination (Oral Mucosa):
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            name="soft_tissue_examination"
                            initialValue={
                              selectedPatientReports.soft_tissue_examination
                            }
                          >
                            <Radio.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <Radio value="Normal">Normal</Radio>
                                <Radio value="Abnormal">
                                  Abnormal:
                                  <Input
                                    onChange={(e) =>
                                      setSoftTissueExamination(e.target.value)
                                    }
                                    placeholder="Describe"
                                    disabled={cln_report_inp_enables}
                                    style={{
                                      width: 100,
                                      marginLeft: 10,
                                    }}
                                  />
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="soft_tissue_examination">
                            <Radio.Group style={{ margin: "10px 0 0 0" }}>
                              <Space direction="vertical">
                                <Radio value="Normal">Normal</Radio>
                                <Radio value="Abnormal">
                                  Abnormal:
                                  <Input
                                    onChange={(e) =>
                                      setSoftTissueExamination(e.target.value)
                                    }
                                    placeholder="Describe"
                                    style={{
                                      width: 100,
                                      marginLeft: 10,
                                    }}
                                  />
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Gingival & Periodontal Status:
                          </span>

                          {/* {selectedPatientReports ? (
                          <Form.Item
                            name="gingival_and_periodontal_status"
                            initialValue={
                              selectedPatientReports.gingival_and_periodontal_status
                            }
                          >
                            <Radio.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <Radio value="Healthy">Healthy</Radio>
                                <Radio value="Gingivitis">Gingivitis</Radio>
                                <Radio value="Periodontitis">
                                  Periodontitis :
                                  <Input
                                    onChange={(e) =>
                                      setPeriodontal(e.target.value)
                                    }
                                    placeholder="stages"
                                    disabled={cln_report_inp_enables}
                                    style={{
                                      width: 100,
                                      marginLeft: 10,
                                    }}
                                  />
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="gingival_and_periodontal_status">
                            <Radio.Group style={{ margin: "10px 0 0 0" }}>
                              <Space direction="vertical">
                                <Radio value="Healthy">Healthy</Radio>
                                <Radio value="Gingivitis">Gingivitis</Radio>
                                <Radio value="Periodontitis">
                                  Periodontitis :
                                  <Input
                                    onChange={(e) =>
                                      setPeriodontal(e.target.value)
                                    }
                                    placeholder="stages"
                                    style={{
                                      width: 100,
                                      marginLeft: 10,
                                    }}
                                  />
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Radiographic Findings: (If applicable)
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            name="xrays_attached"
                            initialValue={
                              selectedPatientReports.soft_tissue_examination
                            }
                          >
                            <Radio.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#494949",
                                  }}
                                >
                                  X-rays Attached:
                                </span>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="xrays_attached">
                            <Radio.Group style={{ margin: "10px 0 0 0" }}>
                              <Space direction="vertical">
                                <span
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "13px",
                                  }}
                                >
                                  X-rays Attached:
                                </span>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          {/* )} */}
                          <Space direction="vertical">
                            <span
                              style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "#494949",
                              }}
                            >
                              Findings:
                            </span>
                            {/* {selectedPatientReports ? (
                            <Form.Item
                              name="radiographic_findings"
                              initialValue={
                                selectedPatientReports.radiographic_findings
                              }
                            >
                              <Input
                                onChange={(e) =>
                                  setRadiographicFindings(e.target.value)
                                }
                                placeholder="Type here"
                                disabled={cln_report_inp_enables}
                                style={{
                                  width: 200,
                                  marginLeft: 10,
                                }}
                              />
                            </Form.Item>
                          ) : ( */}
                            <Form.Item name="radiographic_findings">
                              <Input
                                onChange={(e) =>
                                  setRadiographicFindings(e.target.value)
                                }
                                placeholder="Type here"
                                style={{
                                  width: 200,
                                  marginLeft: 10,
                                }}
                              />
                            </Form.Item>
                            {/* )} */}
                          </Space>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={6}>
                      <div
                        className="profile-pic-col-main-div"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 35,
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "#494949",
                            }}
                          >
                            Teeth Status (Use Universal Numbering System)
                          </span>
                          <span
                            style={{
                              margin: "10px 0 0 0",
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Missing Teeth:
                          </span>

                          {/* {selectedPatientReports ? (
                          <Form.Item
                            name="missing_teeth"
                            initialValue={selectedPatientReports.missing_teeth}
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="missing_teeth">
                            <Input
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              margin: "10px 0 0 0",
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Carious Teeth:
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            initialValue={selectedPatientReports.carious_teeth}
                            name="carious_teeth"
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="carious_teeth">
                            <Input
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              margin: "10px 0 0 0",
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Filled Teeth:
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            initialValue={selectedPatientReports.filled_teeth}
                            name="filled_teeth"
                          >
                            <Input
                              name=""
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="filled_teeth">
                            <Input
                              name=""
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              margin: "10px 0 0 0",
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Other Observations:
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.other_observations
                            }
                            name="other_observations"
                          >
                            <Input
                              name=""
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="other_observations">
                            <Input
                              name=""
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Occlusion:
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            initialValue={selectedPatientReports.occlusion}
                            name="occlusion"
                          >
                            <Radio.Group
                              style={{ margin: "10px 0 0 0" }}
                              disabled={cln_report_inp_enables}
                            >
                              <Space direction="vertical">
                                <Radio value="Normal">Normal</Radio>
                                <Radio value="Malocclussion">
                                  Malocclussion
                                  <Input
                                    onChange={(e) =>
                                      setOcclusion(e.target.value)
                                    }
                                    placeholder="Type here"
                                    disabled={cln_report_inp_enables}
                                    style={{
                                      width: 100,
                                      marginLeft: 10,
                                    }}
                                  />
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="occlusion">
                            <Radio.Group style={{ margin: "10px 0 0 0" }}>
                              <Space direction="vertical">
                                <Radio value="Normal">Normal</Radio>
                                <Radio value="Malocclussion">
                                  Malocclussion
                                  <Input
                                    onChange={(e) =>
                                      setOcclusion(e.target.value)
                                    }
                                    placeholder="Type here"
                                    style={{
                                      width: 100,
                                      marginLeft: 10,
                                    }}
                                  />
                                </Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          {/* )} */}
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={6}>
                      <div
                        className="profile-pic-col-main-div"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 35,
                        }}
                      >
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "#494949",
                            }}
                          >
                            Diagnosis:
                          </span>
                          <span
                            style={{
                              margin: "10px 0 0 0",
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Provisional Diagnosis
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            name="primary_diagnosis"
                            initialValue={
                              selectedPatientReports.primary_diagnosis
                            }
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="primary_diagnosis">
                            <Input
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              margin: "10px 0 0 0",
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Final Diagnosis:
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.secondary_diagnosis
                            }
                            name="secondary_diagnosis"
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="secondary_diagnosis">
                            <Input
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "#494949",
                            }}
                          >
                            Recommendations & Treatment Plan:
                          </span>
                          <span
                            style={{
                              margin: "10px 0 0 0",
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Immediate Care:
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            initialValue={selectedPatientReports.immediate_care}
                            name="immediate_care"
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="immediate_care">
                            <Input
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              margin: "10px 0 0 0",
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Short Term Treatment:
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.short_term_treatment
                            }
                            name="short_term_treatment"
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="short_term_treatment">
                            <Input
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                        <Row
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              margin: "10px 0 0 0",
                              fontWeight: "500",
                              fontSize: "15px",
                              color: "#494949",
                            }}
                          >
                            Long Term Treatment:
                          </span>
                          {/* {selectedPatientReports ? (
                          <Form.Item
                            initialValue={
                              selectedPatientReports.long_term_treatment
                            }
                            name="long_term_treatment"
                          >
                            <Input
                              placeholder="Enter reason"
                              disabled={cln_report_inp_enables}
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                        ) : ( */}
                          <Form.Item name="long_term_treatment">
                            <Input
                              placeholder="Enter reason"
                              style={{
                                fontWeight: "400",
                                width: "80%",
                                fontSize: "16px",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            />
                          </Form.Item>
                          {/* )} */}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )}
            </>
          )}
          {currentUrl.includes("/add-report") &&
            currentUrl.includes("clinic-") && (
              <Row
                className="w-100 d-flex jc-center ai-center"
                style={{ margin: "4vh 0", gap: 10 }}
              >
                <Form.Item>
                  <Button htmlType="submit" className="add-clinical-report-btn">
                    Add
                  </Button>
                </Form.Item>
                <Button
                  className="cancel-clinical-report-btn"
                  onClick={() => setDisableFormItems(true)}
                >
                  Cancel
                </Button>
              </Row>
            )}
        </Spin>
      </Form>
    </div>
  );
};

export default ClinicalReportForm;
