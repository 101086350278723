import { Button, Col, Modal, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import MLimage from "./MLimage";
import ImageMap from "../../../../../home/modules/ml_analysis/xray_and_report_details/xray_image_component/image_map/ImageMap";
import API_BASE_URL from "../../../../../../env";
import axios from "axios";
import { UserAuth } from "../../../../../../context_api/ContextApi";
import TabPane from "antd/lib/tabs/TabPane";

const GlobalPatientsXrays = (props) => {
  const { openXrayModal, setOpenXrayModal, xraysAndReports, report_image } =
    props;
  const [xrayImageUrl, setXrayImageUrl] = useState("");

  const handleSendingImageUrl = (imageUrl) => {
    setXrayImageUrl(imageUrl);
  };

  const Token = localStorage.getItem("Token");
  const { setRectangles, setReportRefresh } = UserAuth();

  const handleGettingMLDetails = async (rid, report_image) => {
    setReportRefresh(true);
    const payload = {
      path: report_image,
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .put(`${API_BASE_URL}/api/v1/report/ml-analysis/${rid}`, payload, config)
      .then((res) => {
        setRectangles(JSON.parse(res.data.data).results);
        setReportRefresh(false);
      });
  };

  const { rectangles, commonArray, setcommonArray } = UserAuth();

  const handleCommonArray = () => {
    const uniqueArray = [];
    const uniqueNames = {};
    rectangles &&
      rectangles.forEach((obj) => {
        const name = obj.name;
        if (!uniqueNames[name]) {
          uniqueNames[name] = true;
          uniqueArray.push(obj);
        }
      });

    setcommonArray(uniqueArray);
  };
  
  useEffect(() => {
    handleCommonArray();
  }, [rectangles]);

  return (
    <div>
      <Modal
        open={openXrayModal}
        centered
        footer={false}
        closable={false}
        width={"51.145833333333336vw"}
        mask={false}
      >
        <div
          style={{
            height: "75.07692307692308vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Row className="w-100">
            <Row className="w-100 d-flex jc-between ai-center">
              <span
                style={{
                  fontWeight: 700,
                  fontSize: 22,
                  color: "#525252",
                }}
              >
                ML Analysis
              </span>
              <CloseOutlined
                style={{
                  color: "red",
                  fontSize: "20px",
                }}
                onClick={() => {
                  setOpenXrayModal(false);
                  setXrayImageUrl("");
                  setcommonArray([]);
                }}
              />
            </Row>
            {xraysAndReports && xraysAndReports.length > 0 ? (
              <>
                <Row
                  style={{
                    width: "100%",
                    margin: "25px 0 0 0",
                    gap: "10px",
                  }}
                >
                  <Col span={8}>
                    <div style={{ height: "300px", overflow: "auto" }}>
                      {xraysAndReports &&
                        xraysAndReports.map((item, id) => {
                          return item.xray_reports.map((item) => {
                            return (
                              <ImageMap
                                id={item.id}
                                report_image={item.report_image}
                                s3Url={item.report_image.split(".com/")[1]}
                                file_name={item.file_name}
                                title={item.title}
                                file_mime_type={item.file_mime_type}
                                handleGettingMLDetails={handleGettingMLDetails}
                                handleSendingImageUrl={handleSendingImageUrl}
                              />
                            );
                          });
                        })}
                    </div>
                  </Col>
                  <Col
                    span={15}
                    style={{
                      margin: "5px 0 0 0",
                    }}
                  >
                    {xrayImageUrl ? (
                      <MLimage
                        xrayImageUrl={xrayImageUrl}
                        report_image={report_image}
                      />
                    ) : (
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "25vh",
                        }}
                      >
                        <span style={{ fontSize: "25px", fontWeight: "600" }}>
                          Click on Image to display here
                        </span>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row className="w-100">
                  <Tabs style={{ margin: "20px 23px", width: "100%" }}>
                    <TabPane tab="Report" key="1">
                      <Row
                        className="xray-details"
                        style={{
                          width: "100%",
                          padding: "13px",
                          background: "#F2F2F2",
                        }}
                      >
                        <Col span={4}>
                          <span className="xray-details-heading">
                            Issues Identified :
                          </span>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          maxHeight: "150px",
                          overflow: "auto",
                        }}
                      >
                        {commonArray &&
                          commonArray.map((item, id) => {
                            return (
                              <Row
                                className="xray-details"
                                style={{
                                  width: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 10,
                                  padding: "13px",
                                }}
                              >
                                <Col>
                                  <span
                                    className="xray-details-heading d-flex ai-center"
                                    style={{ gap: 10 }}
                                  >
                                    {item.name === "dental caries" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#01A5E4",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "restorations" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#8DD7C0",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "bone loss" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#FF96C6",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "prosthetics" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#FE5766",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "periapical abnormality" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#FFBF65",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "root stump" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#FB6238",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "absent_tooth" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#FFD871",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "fracture" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#F2D4CC",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "other" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#E77477",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "microdontia" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#6C89C5",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "macrodontia" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#C05780",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "position" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#FF828A",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "altered morphology" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#E8C583",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "impacted_tooth" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#00B1BB",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "implants" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#00B1BB",
                                        }}
                                      ></div>
                                    )}
                                    {item.name === "attrition" && (
                                      <div
                                        style={{
                                          width: 16,
                                          height: 16,
                                          background: "#CFF800",
                                        }}
                                      ></div>
                                    )}
                                  </span>
                                </Col>
                                <Col span={10}>
                                  <span className="xray-details-heading">
                                    {item.name}
                                  </span>
                                </Col>
                              </Row>
                            );
                          })}
                      </Row>
                    </TabPane>
                    <TabPane tab="Suggested Treatment" key="2"></TabPane>
                  </Tabs>
                </Row>
              </>
            ) : (
              <>
                <Row
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <span style={{ fontSize: "30px", fontWeight: "700" }}>
                    No Data
                  </span>
                </Row>
              </>
            )}
          </Row>
        </div>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85px",
          }}
        >
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
              fontSize: "16px",
              color: "#D35850",
              border: "1px solid #D35850",
              borderRadius: "16px",
            }}
            onClick={() => {
              setOpenXrayModal(false);
              setXrayImageUrl("");
              setcommonArray([]);
            }}
          >
            Close
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

export default GlobalPatientsXrays;
