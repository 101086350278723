export const routes_super_admin_home = "/super-admin";
export const routes_super_admin_dashboard = "/super-admin/dashboard";
export const routes_super_admin_dashboard_register_requests =
  "/super-admin/clinics/register-requests";
export const routes_super_admin_patients = "/super-admin/patients";
export const routes_super_admin_surveys = "/super-admin/surveys";
export const routes_super_admin_articles = "/super-admin/articles";
export const routes_super_admin_sub_users = "/super-admin/sub-users";
export const routes_super_admin_clinic_profile =
  "/super-admin/clinic-profile/:clinic_id";
export const routes_super_admin_patient_profile =
  "/super-admin/patient-profile/:id";
export const routes_super_admin_create_surveys =
  "/super-admin/create-new-survey";
export const routes_super_admin_add_patient = "/super-admin/add-patient";
export const routes_super_admin_add_sub_user = "/super-admin/add-sub-user";
export const routes_super_admin_sub_user_profile =
  "/super-admin/sub-user-profile/:user_id";

export const tabs_super_admin_dashboard = "Clinics";
export const tabs_super_admin_dashboard_register_requests =
  "Clinic Registration Requests";
export const tabs_super_admin_patients = "Patients";
export const tabs_super_admin_surveys = "Surveys";
export const tabs_super_admin_articles = "Articles";
export const tabs_super_admin_sub_users = "Sub Users";
export const xrays = "XRAY";
export const other_reports = "INTRA_ORAL_REPORTS";
export const xray_images = "MISCELLANEOUS_REPORTS";
export const intra_oral = "INTRA_ORAL_REPORTS";
export const misc_files = "MISCELLANEOUS_REPORTS";

export const SUPER_ADMIN = "SUPER_ADMIN";
export const ADMIN = "ADMIN";
export const SALES = "SALES";
export const CUSTOMER_CARE = "CUSTOMER_CARE";
export const DATA_SCIENTIST = "DATA_SCIENTIST";
export const SURVEY_UPDATE = "SURVEY_UPDATE";

export const options = [
  {
    value: "+93",
    label: "+93",
  },
  {
    value: "+213",
    label: "+213",
  },
  {
    value: "+54",
    label: "+54",
  },
  {
    value: "+61",
    label: "+61",
  },
  {
    value: "+43",
    label: "+43",
  },
  {
    value: "+880",
    label: "+880",
  },
  {
    value: "+32",
    label: "+32",
  },
  {
    value: "+55",
    label: "+55",
  },
  {
    value: "+359",
    label: "+359",
  },
  {
    value: "+1",
    label: "+1",
  },
  {
    value: "+56",
    label: "+56",
  },
  {
    value: "+86",
    label: "+86",
  },
  {
    value: "+57",
    label: "+57",
  },
  {
    value: "+506",
    label: "+506",
  },
  {
    value: "+385",
    label: "+385",
  },
  {
    value: "+357",
    label: "+357",
  },
  {
    value: "+420",
    label: "+420",
  },
  {
    value: "+45",
    label: "+45",
  },
  {
    value: "+20",
    label: "+20",
  },
  {
    value: "+358",
    label: "+358",
  },
  {
    value: "+33",
    label: "+33",
  },
  {
    value: "+49",
    label: "+49",
  },
  {
    value: "+30",
    label: "+30",
  },
  {
    value: "+504",
    label: "+504",
  },
  {
    value: "+852",
    label: "+852",
  },
  {
    value: "+36",
    label: "+36",
  },
  {
    value: "+354",
    label: "+354",
  },
  {
    value: "+91",
    label: "+91",
  },
  {
    value: "+62",
    label: "+62",
  },
  {
    value: "+964",
    label: "+964",
  },
  {
    value: "+353",
    label: "+353",
  },
  {
    value: "+972",
    label: "+972",
  },
  {
    value: "+39",
    label: "+39",
  },
  {
    value: "+1-876",
    label: "+1-876",
  },
  {
    value: "+81",
    label: "+81",
  },
  {
    value: "+962",
    label: "+962",
  },
  {
    value: "+254",
    label: "+254",
  },
  {
    value: "+82",
    label: "+82",
  },
  {
    value: "+965",
    label: "+965",
  },
  {
    value: "+856",
    label: "+856",
  },
  {
    value: "+961",
    label: "+961",
  },
  {
    value: "+60",
    label: "+60",
  },
  {
    value: "+52",
    label: "+52",
  },
  {
    value: "+212",
    label: "+212",
  },
  {
    value: "+95",
    label: "+95",
  },
  {
    value: "+977",
    label: "+977",
  },
  {
    value: "+31",
    label: "+31",
  },
  {
    value: "+64",
    label: "+64",
  },
  {
    value: " +234",
    label: " +234",
  },
  {
    value: "+47",
    label: "+47",
  },
];
