import { LoadingOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
  Spin,
  Tabs,
  message
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router";
import AddStaffIcon from "../../../../../../assets/plus-icon-green-col.png";
import API_BASE_URL from "../../../../../../env";
import { ADMIN, SUPER_ADMIN, routes_super_admin_dashboard } from "../../../../../../utils/super_admin_constants/SuperAdminConsts";
import StaffDetails from "../../../../../clinic_admin/modules/admin_staff/staff_details/StaffDetails";
import "./SuperAdminClinicDetails.css";
import ClinicPatients from "./clinic_patients/ClinicPatients";
import ViewClinicData from "./ViewClinicData";

const SuperAdminClinicDetails = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const navigate = useNavigate();
  const Token = localStorage.getItem("Token");
  const role = localStorage.getItem("role");
  const location = useLocation();
  const { status } = location.state || {};
  const [clinicPatients, setClinicPatients] = useState();
  const [clinicStaff, setClinicStaff] = useState();
  const [selectedClinic, setSelectedClinic] = useState();
  const [InputsEnable, setInputsEnable] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [xraysAndReports, setXraysAndReports] = useState();
  const { clinic_id } = useParams();
  const navigateToStaffProfileinSuperAdmin = `/super-admin/clinic/${clinic_id}/staff-profile`;

  // Patients in clinic
  const handleGetAllPatientsInSelectedClinic = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/clinic/${clinic_id}/patient`, config)
      .then((res) => {
        setClinicPatients(res.data.data);
      });
  };

  // Staff in clinic
  const handleGetAllStaffInSelectedClinic = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/adminStaff/clinic/${clinic_id}`, config)
      .then((res) => {
        setClinicStaff(res.data.data);
      });
  };

  // Clinic details
  const handleGetSelectedClinicDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/clinic/${clinic_id}`, config)
      .then((res) => {
        setSelectedClinic(res.data.data);
      });
  };

  // Function runs when patient user enter details and clicks sign up button
  const handleSubmitPatientSignUpDetails = (values) => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    const payload = {
      name: values.name,
      email: values.email,
      username: values.username,
      phone_country_code: values.phone_country_code,
      phone_number: values.phone_number,
      address_line_one: values.address_line_one,
      address_line_two: values.address_line_two,
      city: values.city,
      province: values.province,
      country: values.country,
      zip_code: values.zip_code,
      coordinate: {
        latitude: 0,
        longitude: 0,
      },
      profile_picture_url: "",
    };
    axios
      .put(`${API_BASE_URL}/api/v1/clinic/${clinic_id}`, payload, config)
      .then((res) => {
        message.success("success");
        setInputsEnable(true);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
    setTimeout(() => {
      setSpinner(false);
    }, 700);
  };

  const handleGetXrayAndReportDetails = async (patientID, xrayType) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        `${API_BASE_URL}/api/v1/report?patient_id=${patientID}&report_type=${xrayType}`,
        config
      )
      .then((res) => {
        setXraysAndReports(res.data.data);
      });
  };

  useEffect(() => {
    handleGetAllPatientsInSelectedClinic();
    handleGetAllStaffInSelectedClinic();
    handleGetSelectedClinicDetails();
  }, [clinic_id]);

  const [activeTab, setActiveTab] = useState("1");

  const handleChangeTab = (tabKey) => {
    setActiveTab(tabKey);
  };

  const renderTabBarExtraContent = () => {
    // Custom rendering for tabBarExtraContent based on the activeTab
    if (activeTab === "1") {
      return <div></div>;
    } else if (activeTab === "2") {
      return (
        <Button
          style={{
            background: "#FFFFFF",
            border: "1px solid #97C46F",
            borderRadius: "1.563vw",
            color: "#525252",
            padding: "0.208vw 0.8vw",
            height: "1.67vw"
          }}
          onClick={() => navigate(`/super-admin/clinic/${clinic_id}/add-staff`)}
        >
          <Row className="d-flex ai-center" style={{ gap: "0.4vw" }}>
            <img style={{ height: "1.0417vw" }} src={AddStaffIcon} alt="" />
            <span
              style={{
                fontWeight: "600",
                fontSize: "0.73vw",
                color: "#525252",
              }}
            >
              Add Staff
            </span>
          </Row>
        </Button>
      );
    } else if (activeTab === "3") {
      return (
        <Button
          style={{
            background: "#FFFFFF",
            border: "1px solid #97C46F",
            borderRadius: "1.563vw",
            color: "#525252",
            padding: "0.208vw 0.8vw",
            height: "1.67vw"
          }}
          onClick={() =>
            navigate(`/super-admin/clinic/${clinic_id}/add-patient`)
          }
        >
          <Row className="d-flex ai-center" style={{ gap: "0.4vw" }}>
            <img style={{ height: "1.0417vw" }} src={AddStaffIcon} alt="" />
            <span
              style={{
                fontWeight: "600",
                fontSize: "0.73vw",
                color: "#525252",
              }}
            >
              Add Patient
            </span>
          </Row>
        </Button>
      );
    }

    return null;
  };

  return (
    <div className="super-admin-clinic-details-main-div">
      <Helmet>
        <title>Clinic details</title>
      </Helmet>
      <Row
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0.63vw 1.25vw",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            fontSize: "1.18vw",
            color: "#525252",
          }}
        >
          {selectedClinic?.name} (Profile)
        </span>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={routes_super_admin_dashboard}>Clinics</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Clinic Profile</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row
        style={{
          background: "#fff",
          margin: "0 1.25vw",
          padding: "1.25vw 2.0834vw",
        }}
      >
        <Tabs
          type="card"
          activeKey={activeTab}
          defaultActiveKey="1"
          style={{ width: "100%" }}
          onChange={handleChangeTab}
          tabBarExtraContent={renderTabBarExtraContent()}
        >
          <Tabs.TabPane tab="Clinic Profile" key="1">
            <Spin
              indicator={antIcon}
              size="large"
              spinning={spinner && true}
              delay={500}
            >
              <ViewClinicData
                clinicData={selectedClinic}
                status={status}
              />
            </Spin>
          </Tabs.TabPane>
          {role === SUPER_ADMIN || role === ADMIN ? (
            <Tabs.TabPane
              tab={`Clinic Staff (${clinicStaff && clinicStaff.length})`}
              key="2"
            >
              <Row
                style={{
                  background: "#FFF",
                }}
              >
                <Row
                  className="xray-details"
                  style={{
                    width: "100%",
                    padding: "0.834vw 1.25vw",
                    // padding: "21px 30px",
                    borderBottom: "1px solid #d9d9d9"
                  }}
                >
                    <Col span={1}>
                {/* <Checkbox className="custom-checkbox" /> */}
              </Col>
              <Col span={5}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  NAME
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Designation
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Specialization
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Contact information
                </span>
              </Col>
              <Col span={4}>
                <span
                  style={{
                    fontSize: "0.8333333333333334vw",
                    fontWeight: 500,
                  }}
                >
                  Next Appointment
                </span>
              </Col>
              <Col span={2}>
                {/* 0 */}
                {/* {checkedDoctorValues && checkedDoctorValues.length > 0 && <></>} */}
              </Col>
                  {/* <Col span={1}></Col>
                  <Col span={3}>
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "0.73vw",
                        color: "#A8A8A8",
                      }}
                    >
                      Upload Date
                    </span>
                  </Col>
                  <Col span={4}>
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "0.73vw",
                        color: "#A8A8A8",
                      }}
                    >
                      Staff Name / ID
                    </span>
                  </Col>
                  <Col span={3}>
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "0.73vw",
                        color: "#A8A8A8",
                      }}
                    >
                      Designation
                    </span>
                  </Col>
                  <Col span={5}>
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "0.73vw",
                        color: "#A8A8A8",
                      }}
                    >
                      Qualification
                    </span>
                  </Col>
                  <Col span={4}>
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "0.73vw",
                        color: "#A8A8A8",
                      }}
                    >
                      Email
                    </span>
                  </Col>
                  <Col span={3}>
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "0.73vw",
                        color: "#A8A8A8",
                      }}
                    >
                      Access Type
                    </span>
                  </Col>
                  <Col span={1}></Col> */}
                </Row>
                <Row
                  style={{
                    maxHeight: "60vh",
                    overflow: "auto",
                  }}
                >
                  {clinicStaff &&
                    clinicStaff.map((item) => {
                      return (
                        <StaffDetails
                          navigateTo={navigateToStaffProfileinSuperAdmin}
                          key={item.id}
                          item={item}
                          handleGetAllAdminDetails={
                            handleGetAllStaffInSelectedClinic
                          }
                        />
                      );
                    })}
                </Row>
              </Row>
            </Tabs.TabPane>
          ) : (
            <></>
          )}

          {role === SUPER_ADMIN || role === ADMIN ? (
            <Tabs.TabPane
              tab={`Clinic Patients (${clinicPatients && clinicPatients.length
                })`}
              key="3"
            >
              <Row
                style={{
                  width: "100%",
                  padding: "0.834vw 1.25vw",
                  borderBottom: "1px solid #d9d9d9"
                }}
              >
                <Col span={3}>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "0.73vw",
                      color: "#A8A8A8",
                    }}
                  >
                    Upload Date
                  </span>
                </Col>
                <Col span={4}>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "0.73vw",
                      color: "#A8A8A8",
                    }}
                  >
                    Patient Name / ID
                  </span>
                </Col>
                <Col span={5}>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "0.73vw",
                      color: "#A8A8A8",
                    }}
                  >
                    Email
                  </span>
                </Col>
                <Col span={4}>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "0.73vw",
                      color: "#A8A8A8",
                    }}
                  >
                    Phone
                  </span>
                </Col>
                <Col span={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "0.73vw",
                      color: "#A8A8A8",
                    }}
                  >
                    X-Ray Reports
                  </span>
                </Col>
                <Col span={4}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "0.73vw",
                      color: "#A8A8A8",
                    }}
                  >
                    Other Reports
                  </span>
                </Col>
                <Col span={1}></Col>
              </Row>
              <Row
                style={{
                  background: "#FFF",
                  maxHeight: "60vh",
                  overflow: "auto",
                }}
              >
                {clinicPatients &&
                  clinicPatients.map((patientdetails) => {
                    return (
                      <ClinicPatients
                        clinic_id={clinic_id}
                        patientdetails={patientdetails}
                        handleGetXrayAndReportDetails={
                          handleGetXrayAndReportDetails
                        }
                        xraysAndReports={xraysAndReports}
                        handleGetAllPatientsInSelectedClinic={
                          handleGetAllPatientsInSelectedClinic
                        }
                      />
                    );
                  })}
              </Row>
            </Tabs.TabPane>
          ) : (
            <></>
          )}
        </Tabs>
      </Row>
    </div>
  );
};

export default SuperAdminClinicDetails;
