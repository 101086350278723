import { Helmet } from "react-helmet";
import {
  Row,
  Input,
  Form,
  Col,
  Button,
  Select,
  Breadcrumb,
  DatePicker,
  message,
  Spin,
  Space,
  Radio,
} from "antd";
import { Option } from "antd/lib/mentions";
import React, { useState } from "react";
import ProfilePic from "../../../../../assets/default-profile-pic.png";
import {
  routes_clinic_admin_staff,
  yearsMap,
} from "../../../../../utils/clinic_admin_constants/ClinicAdminConstants";
import axios from "axios";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  CloseOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { options } from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import API_BASE_URL from "../../../../../env";
import { useEffect } from "react";

const AddingNewStaff = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const navigate = useNavigate();
  const admin_id = localStorage.getItem("clinic_id");
  const clinicId = localStorage.getItem("clinic_id");
  const role = localStorage.getItem("role");
  const Token = localStorage.getItem("Token");
  const [form] = Form.useForm();
  const [spinner, setSpinner] = useState(false);
  const [clinic_branches, setClinicBranches] = useState();
  const { clinic_id } = useParams();

  const handleAddNewStaff = async (values) => {
    setSpinner(true);
    const payload = {
      name: values.admin_name,
      username: values.admin_username,
      email: values.email,
      phone_country_code: values.country_code ? values.country_code : "+91",
      phone_number: values.phone,
      gender: values.gender,
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
      qualification: values.qualification,
      total_experience: {
        years: values.years,
        months: values.months,
      },
      designation: values.designation,
      access_type: "Can Edit",
      branch_id: values.branch_id,
    };
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .post(
        `${API_BASE_URL}/api/v1/adminStaff/${clinic_id ? clinic_id : admin_id}`,
        payload,
        config
      )
      .then((res) => {
        setSpinner(false);
        form.resetFields();
        navigate(
          clinic_id
            ? `/super-admin/clinic-profile/${clinic_id}`
            : routes_clinic_admin_staff
        );
      })
      .catch((err) => {
        message.warning(err.response.data.data.errors);
        setSpinner(false);
      });
  };

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  const handleGetAllclinicBranches = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(
        `${API_BASE_URL}/api/v1/clinicBranch/getAllBranchDetails/${clinicId}`,
        config
      )
      .then((res) => {
        setClinicBranches(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleGetAllclinicBranches();
  }, []);

  return (
    <div className="add-staff-main-div">
      <Helmet>
        <title>Add New Staff</title>
      </Helmet>
      <Row
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "12px 23px",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            fontSize: 22,
            color: "#525252",
          }}
        >
          Add New Staff
        </span>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href={routes_clinic_admin_staff}>Staff</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Add New Staff</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row className="w-100" style={{ padding: "0 0 0 23px" }}>
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          className="w-100"
          onFinish={handleAddNewStaff}
        >
          <Spin
            indicator={antIcon}
            size="large"
            spinning={spinner && true}
            delay={500}
          >
            <Row className="d-flex">
              {/* profile col */}
              <Col xs={24} sm={24} md={12} xl={6}>
                <div className="profile-pic-col-main-div">
                  <div
                    style={{
                      padding: "4.3333333333333335vh 3.33vw",
                      borderBottom: "1px solid #D9D9D9",
                    }}
                  >
                    <Row className="d-flex jc-center ai-center">
                      <div
                        style={{
                          borderRadius: "40%",
                          background: "#D9D9D9",
                          width: "118px",
                          height: "100px",
                        }}
                      >
                        <Form.Item>
                          <img
                            style={{
                              height: "118px",
                              width: "118px",
                              borderRadius: 40,
                            }}
                            src={ProfilePic}
                            alt=""
                          />
                        </Form.Item>
                      </div>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "27px 0 0 0",
                        gap: "2.302vh",
                      }}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Name
                        </span>

                        <Form.Item
                          name="admin_name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter name",
                            },
                            {
                              min: 3,
                              message:
                                "Name must be at least 3 characters long",
                            },
                            {
                              max: 100,
                              message:
                                "Name must be no more than 100 characters long",
                            },
                            {
                              pattern: /^[A-Za-z][A-Za-z\s]*$/,
                              message:
                                "First character should be alphabetical and Name must contain only alphabeticals",
                            },
                            { whitespace: true, message: "No white spaces" },
                          ]}
                        >
                          <Input
                            name="admin_name"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#525252",
                              height: "40px",
                              borderRadius: "3px",
                              border: "1px solid #E3E3E3",
                            }}
                            bordered={false}
                            maxLength={101}
                            onKeyPress={(e) => {
                              const charCode = e.which || e.keyCode;
                              const inputValue = e.target.value;
                              if (
                                !(
                                  (charCode >= 65 && charCode <= 90) ||
                                  (charCode >= 97 && charCode <= 122) ||
                                  (charCode === 32 && inputValue.trim() !== "")
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Staff Identification
                        </span>
                        <Form.Item name="staff_id" initialValue={"SID#"}>
                          <Input
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#525252",
                              height: "40px",
                              borderRadius: "3px",
                              background: "#FFF8E7",
                              border: "1px solid #E3E3E3",
                            }}
                            bordered={false}
                            disabled={true}
                          />
                        </Form.Item>
                      </Row>
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#A5A4A4",
                          }}
                        >
                          Gender
                        </span>
                        <Form.Item
                          name="gender"
                          rules={[
                            { required: true, message: "Please Select Gender" },
                          ]}
                        >
                          <Select
                            name="gender"
                            bordered={false}
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#525252",
                              height: "40px",
                              borderRadius: "3px",
                              border: "1px solid #E3E3E3",
                            }}
                          >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Others">Others</Option>
                          </Select>
                        </Form.Item>
                      </Row>
                    </Row>
                  </div>
                  <div
                    style={{
                      padding:
                        "3.7151702786377707vh 3.3333333333333335vw 4vh 3.3854166666666665vw",
                      borderBottom: "1px solid #D9D9D9",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Email
                    </span>

                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Please enter email" },
                        {
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                        {
                          max: 320,
                          message:
                            "Email address must be no more than 320 characters long",
                        },
                      ]}
                    >
                      <Input
                        name="email"
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#7D7D7D",
                          height: "40px",
                          borderRadius: "3px",
                          border: "1px solid #E3E3E3",
                        }}
                        bordered={false}
                        maxLength={321}
                      />
                    </Form.Item>
                  </div>
                  <div
                    style={{
                      padding:
                        "3.7151702786377707vh 3.3333333333333335vw 4.615384615384615vh 3.3854166666666665vw",
                    }}
                  >
                    <Row>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Phone
                      </span>
                    </Row>
                    <Space.Compact>
                      <Form.Item name="country_code">
                        <Select
                          bordered={false}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: 40,
                            background: "#FFFFFF",
                            border: "1px solid #E3E3E3",
                            margin: "3px 0 0 0",
                          }}
                          defaultValue="+91"
                          options={options}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "100%" }}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter phone number",
                          },
                          { validator: validatePhoneNumber },
                        ]}
                      >
                        <Input
                          bordered={false}
                          style={{
                            height: 40,
                            background: "#FFFFFF",
                            borderTop: "1px solid #E3E3E3",
                            borderRight: "1px solid #E3E3E3",
                            borderBottom: "1px solid #E3E3E3",
                            margin: "3px 0 0 0",
                          }}
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            if (!(charCode >= 48 && charCode <= 57)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Space.Compact>
                  </div>
                </div>
              </Col>
              {/* address col */}
              <Col xs={24} sm={24} md={12} xl={6}>
                <div className="profile-pic-col-main-div">
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5vh",
                      padding: "43px 23px",
                    }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Username*
                      </span>
                      <Form.Item
                        name="admin_username"
                        rules={[
                          {
                            required: true,
                            message: "Please enter username",
                          },
                          {
                            min: 4,
                            message:
                              "Username must be at least 4 characters long",
                          },
                          {
                            max: 100,
                            message:
                              "Username must be no more than 100 characters long",
                          },
                          { whitespace: true, message: "No white spaces" },
                          {
                            pattern: /^[a-zA-Z0-9._]+$/,
                            message:
                              "Username can only contain letters, numbers, fullstops, and underscores.",
                          },
                        ]}
                      >
                        <Input
                          name="admin_username"
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#7D7D7D",
                            height: "40px",
                            border: "1px solid #E3E3E3",
                          }}
                          bordered={false}
                          maxLength={101}
                          onKeyPress={handleUserNameKeyPress}
                        />
                      </Form.Item>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Designation*
                      </span>
                      <Form.Item
                        name="designation"
                        rules={[
                          {
                            required: true,
                            message: "Please enter designation",
                          },
                        ]}
                      >
                        <Select
                          name="designation"
                          bordered={false}
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#525252",
                            height: "40px",
                            borderRadius: "3px",
                            border: "1px solid #E3E3E3",
                          }}
                        >
                          <Option value="Doctor">Doctor</Option>
                          <Option value="Nurse">Nurse</Option>
                        </Select>
                      </Form.Item>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Date of Birth*
                      </span>
                      <Form.Item
                        name="date_of_birth"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Date of Birth",
                          },
                        ]}
                      >
                        <DatePicker
                          name="date_of_birth"
                          style={{
                            fontWeight: "400",
                            width: "70%",
                            fontSize: "16px",
                            color: "#525252",
                            height: "40px",
                            borderRadius: "3px",
                            border: "1px solid #E3E3E3",
                          }}
                        />
                      </Form.Item>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Qualification*
                      </span>
                      <Form.Item
                        name="qualification"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Qualification",
                          },
                        ]}
                      >
                        <Input
                          name="qualification"
                          label="qualification"
                          style={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "#7D7D7D",
                            height: "40px",
                            border: "1px solid #E3E3E3",
                          }}
                          bordered={false}
                        />
                      </Form.Item>
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Total Experience*
                      </span>
                      <Row>
                        <Col span={12}>
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#A5A4A4",
                            }}
                          >
                            Years
                          </span>
                          <Form.Item
                            name="years"
                            rules={[
                              { required: true, message: "Please enter year" },
                            ]}
                          >
                            <Select
                              name="years"
                              bordered={false}
                              style={{
                                fontWeight: "400",
                                width: "90%",
                                fontSize: "16px",
                                color: "#525252",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            >
                              {yearsMap.map((item) => {
                                return (
                                  <Option value={item.year}>{item.year}</Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#A5A4A4",
                            }}
                          >
                            Months
                          </span>
                          <Form.Item
                            name="months"
                            rules={[
                              { required: true, message: "Please enter month" },
                            ]}
                          >
                            <Select
                              name="months"
                              bordered={false}
                              style={{
                                width: "90%",
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#525252",
                                height: "40px",
                                borderRadius: "3px",
                                border: "1px solid #E3E3E3",
                              }}
                            >
                              <Option value="1">0</Option>
                              <Option value="1">1</Option>
                              <Option value="2">2</Option>
                              <Option value="3">3</Option>
                              <Option value="4">4</Option>
                              <Option value="5">5</Option>
                              <Option value="6">6</Option>
                              <Option value="7">7</Option>
                              <Option value="8">8</Option>
                              <Option value="9">9</Option>
                              <Option value="10">10</Option>
                              <Option value="11">11</Option>
                              <Option value="12">12</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Row>
                  </Row>
                </div>
              </Col>

              {/* Branches col */}

              {(role === "CLINIC_ADMIN" || role === "CLINIC_MASTER_ADMIN") && (
                <Col xs={24} sm={24} md={12} xl={12}>
                  <div
                    style={{
                      width: "48.2vw",
                      background: "#fff",
                      borderRadius: "5px",
                    }}
                  >
                    <Row
                      style={{
                        gap: "1vh",
                        padding: "43px 23px",
                      }}
                    >
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Col
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontSize: "20px", fontWeight: "700" }}>
                            Assign Staff to Branch
                          </span>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "rgba(122, 122, 122, 1)",
                            }}
                          >
                            Branches(
                            {clinic_branches ? clinic_branches.length : "0"})
                          </span>
                        </Col>
                        <Col>
                          <Input
                            style={{
                              width: 300,
                              height: 40,
                              borderRadius: 3,
                            }}
                            className="d-flex ai-center"
                            placeholder="Search by Clinic Name or ID"
                            prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
                            // value={searchPatients}
                            // onChange={(e) => {
                            //   handleGetPatientsBySearch(e);
                            //   setSearchPatients(e.target.value);
                            // }}
                            suffix={
                              <CloseOutlined
                                style={{ height: "10px", color: "#A5A5A5" }}
                                // onClick={() => {
                                //   setSearchedGlobalPatients("");
                                //   setSearchPatients("");
                                //   handleGetAllPatientsForClinic();
                                // }}
                              />
                            }
                          />
                        </Col>
                      </Row>
                      <Row style={{ width: "100%", maxHeight: "60vh" }}>
                        {clinic_branches ? (
                          clinic_branches.map((item_branches) => {
                            return (
                              <Col span={12}>
                                <Row>
                                  <Col span={24}>
                                    <Form.Item name="branch_id">
                                      <Radio.Group
                                        style={{ margin: "10px 0 0 0" }}
                                      >
                                        <Space direction="vertical">
                                          <Radio
                                            value={item_branches.branch_id}
                                          >
                                            <div>
                                              <span
                                                style={{
                                                  fontSize: "18px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                {item_branches.branch_name}
                                              </span>
                                              <span
                                                style={{
                                                  fontSize: "18px",
                                                  fontWeight: "400",
                                                  color: "#3498DB",
                                                }}
                                              >
                                                {item_branches &&
                                                  "#" + item_branches.branch_id}
                                              </span>
                                            </div>
                                            <div style={{ width: "100%" }}>
                                              <span
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  color: "#626262",
                                                }}
                                              >
                                                {item_branches &&
                                                  item_branches.address_line_one +
                                                    ", " +
                                                    item_branches.address_line_two +
                                                    ", " +
                                                    item_branches.city +
                                                    ", " +
                                                    item_branches.province +
                                                    ", " +
                                                    item_branches.zip_code}
                                              </span>
                                            </div>
                                            <div style={{ width: "100%" }}>
                                              <span
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  color: "#626262",
                                                }}
                                              >
                                                {item_branches.phone_country_code +
                                                  " " +
                                                  item_branches.phone_number}
                                              </span>
                                            </div>
                                            <div style={{ width: "100%" }}>
                                              <span
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  color: "#3498DB",
                                                }}
                                              >
                                                {item_branches.email}
                                              </span>
                                            </div>
                                          </Radio>
                                        </Space>
                                      </Radio.Group>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                            );
                          })
                        ) : (
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "10vh",
                            }}
                          >
                            <span
                              style={{ fontSize: "24px", fontWeight: "500" }}
                            >
                              You don't have clinic branches
                            </span>
                          </Row>
                        )}
                      </Row>

                      {/* <Row style={{ width: "100%", maxHeight: "60vh" }}>
                      <Form.Item name="branch_id">
                        <Radio.Group style={{ margin: "10px 0 0 0" }}>
                          <Space direction="vertical" style={{ width: "100%" }}>
                            <Row>
                              {clinic_branches &&
                                clinic_branches.map((item_branches) => {
                                  return (
                                    <Col span={12}>
                                      <Radio value={item_branches.branch_id}>
                                        <div>
                                          <span
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {item_branches.branch_name}
                                          </span>
                                          <span
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "400",
                                              color: "#3498DB",
                                            }}
                                          >
                                            {item_branches &&
                                              "#" + item_branches.branch_id}
                                          </span>
                                        </div>
                                        <div style={{ width: "100%" }}>
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              color: "#626262",
                                            }}
                                          >
                                            {item_branches &&
                                              item_branches.address_line_one +
                                                ", " +
                                                item_branches.address_line_two +
                                                ", " +
                                                item_branches.city +
                                                ", " +
                                                item_branches.province +
                                                ", " +
                                                item_branches.zip_code}
                                          </span>
                                        </div>
                                        <div style={{ width: "100%" }}>
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              color: "#626262",
                                            }}
                                          >
                                            {item_branches.phone_country_code +
                                              " " +
                                              item_branches.phone_number}
                                          </span>
                                        </div>
                                        <div style={{ width: "100%" }}>
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              color: "#3498DB",
                                            }}
                                          >
                                            {item_branches.email}
                                          </span>
                                        </div>
                                      </Radio>
                                    </Col>
                                  );
                                })}
                            </Row>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Row> */}
                    </Row>
                  </div>
                </Col>
              )}
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "19px",
                margin: "20px 0 50px 0",
              }}
            >
              <Button
                style={{
                  height: "30px",
                  border: "1px solid #F7927E",
                  borderRadius: "30px",
                  color: "#F7927E",
                  background: "#FFF6F2",
                }}
              >
                <a
                  href={
                    clinic_id
                      ? `/super-admin/clinic-profile/${clinic_id}`
                      : routes_clinic_admin_staff
                  }
                >
                  Cancel
                </a>
              </Button>
              <Form.Item>
                <Button
                  style={{
                    height: "30px",
                    width: "64px",
                    border: "1px solid #29C3C1",
                    borderRadius: "30px",
                    color: "#29C3C1",
                    background: "#ECFEFF",
                  }}
                  htmlType="submit"
                >
                  Add
                </Button>
              </Form.Item>
            </Row>
          </Spin>
        </Form>
      </Row>
    </div>
  );
};

export default AddingNewStaff;
