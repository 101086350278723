import { Button, Col, Modal, Row, message } from "antd";
import React, { useState, useEffect } from "react";
import location_icon from "../../../../../assets/fluent_location-24-filled.png";
import { CloseOutlined } from "@ant-design/icons";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../string";
import "mapbox-gl/dist/mapbox-gl.css";
import LocateOnMap from "./locate_on_map/LocateOnMap";
import ProfilePic from "../../../../../assets/ssdental.png";
import { useLocation } from "react-router";
import moment from "moment";
import { UserAuth } from "../../../../../context_api/ContextApi";

const ClinicDetails = (props) => {
  const {
    name,
    clinic_branch_id,
    email,
    clinic_user_id,
    zip_code,
    address_line_one,
    address_line_two,
    city,
    country,
    phone_number,
    province,
    clinic_profile_pic,
    latitude,
    longitude,
    selectedDate,
    setDisplaySlots,
    handleGetTreatmentsTypes,
    setClinicId,
    setbranchId,
    branch_id,
    handleGetClinicDetails,
    // handleGetAllSlotDetails,
    item,
  } = props;
  const mapStyles = {
    width: "585px",
    height: "400px",
  };
  const [fileContent, setFileContent] = useState("");
  const [locateOnMap, setLocateOnMap] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const mailtoLink = `mailto:${email}`;

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: clinic_profile_pic,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [clinic_profile_pic]);

  const handleGetDirections = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const origin = `${position.coords.latitude},${position.coords.longitude}`;
          const destination = `${latitude},${longitude}`;

          const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;

          window.open(url, "_blank");
        },
        (error) => {
          message.error("Error getting current location:", error);
        }
      );
    } else {
      message.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <Row className="clinics-data-row">
      <Col span={16}>
        <Row
          className="d-flex ai-center h-100"
          // style={{ gap: "1.0416666666666667vw" }}
        >
          <Col span={4}>
            <img
              src={clinic_profile_pic ? fileContent : ProfilePic}
              alt=""
              style={{ width: "7.03125vw", borderRadius: 10 }}
            />
          </Col>
          <Col
            span={20}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              gap: "0.6123301985370952vh",
              width: "100%",
            }}
          >
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.522466039707419vh",
              }}
            >
              <Row className="d-flex ai-end">
                <span className="clinic-name">
                  {name}
                  {"  "}
                  <span
                    style={{
                      fontSize: "0.8333333333333334vw",
                      fontWeight: 500,
                      color: "#B3B9BD",
                    }}
                  >
                    ID #{clinic_user_id}{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "0.8333333333333334vw",
                      fontWeight: 500,
                      color: "#B3B9BD",
                    }}
                  >
                    Bid #{clinic_branch_id}
                  </span>
                </span>
              </Row>
              <Row style={{ gap: 5 }}>
                <img
                  src={location_icon}
                  alt=""
                  style={{
                    width: "1.25vw",
                  }}
                />
                <span className="address">
                  {address_line_one},&nbsp;
                  {address_line_two},&nbsp;
                  {city},&nbsp;
                  {province},&nbsp;
                  {country},&nbsp;
                  {zip_code}
                </span>
              </Row>
            </Row>
            <Row
              style={{
                display: "flex",
                // flexDirection: "column",
                gap: "0.522466039707419vh",
              }}
            >
              <Col>
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: 600,
                    fontSize: "0.8333333333333334vw",
                  }}
                >
                  {phone_number}
                </span>
              </Col>
              <Col>
                <span>
                  <a
                    style={{
                      cursor: "pointer",
                      color: "#333333",
                      fontWeight: 600,
                      fontSize: "0.8333333333333334vw",
                    }}
                    href={mailtoLink}
                  >
                    {email}
                  </a>
                </span>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                // flexDirection: "column",
                gap: "0.522466039707419vh",
              }}
            >
              {item && item.open_hours && item.open_hours.length > 0 ? (
                item.open_hours.map((data) => {
                  return (
                    <Row
                      style={{
                        display: "flex",
                        // flexDirection: "column",
                        gap: "0.522466039707419vh",
                      }}
                    >
                      <Col>
                        {data.day && (
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#333",
                              fontWeight: 500,
                              fontSize: "14px",
                            }}
                          >
                            {data.day.slice(0, 3)}
                          </span>
                        )}
                      </Col>
                      <Col>
                        {data.to_time && (
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#333",
                              fontWeight: 400,
                              fontSize: "14px",
                            }}
                          >
                            {/* {moment(data.from_time).format("HH:mm A")} - {moment(data.to_time).format("HH:mm A")} */}
                            {data.from_time.slice(0, 5)} -{" "}
                            {data.to_time.slice(0, 5)}
                          </span>
                        )}
                        ,
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <span
                  style={{ color: "#EC7063", fontSize: 14, fontWeight: 500 }}
                >
                  Closed
                </span>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row
          className="d-flex jc-end"
          style={{ gap: "1.1458333333333333vw", padding: "0 1.71875vw" }}
        >
          <Button
            style={{
              background: "#FFF",
              fontSize: "0.7291666666666666vw",
              fontWeight: 600,
              height: "4.179728317659352vh",
              color: "#3498DB",
              border: "1px solid #3498DB",
              borderRadius: "0.2604166666666667vw",
              padding: "0 1.3020833333333333vw",
            }}
            onClick={() => {
              setLocateOnMap(true);
            }}
          >
            See Directions
          </Button>
          <Button
            style={{
              fontSize: "0.7291666666666666vw",
              fontWeight: 600,
              height: "4.179728317659352vh",
              color: "#FFF",
              border: "1px solid #3498DB",
              borderRadius: "0.2604166666666667vw",
              padding: "0 1.3020833333333333vw",
              background: "#3498DB",
            }}
            onClick={() => {
              setDisplaySlots(true);
              setClinicId(clinic_user_id);
              handleGetClinicDetails(clinic_user_id, item.branch_id);
              setbranchId(branch_id);
              // handleGetAllSlotDetails(clinic_user_id, selectedDate, branch_id);
              handleGetTreatmentsTypes(clinic_user_id);
            }}
          >
            Book Appointment
          </Button>
        </Row>
      </Col>

      <Modal
        centered
        width={"647px"}
        open={locateOnMap}
        closable={false}
        footer={false}
        className="clinic-details-modal"
        style={{
          borderRadius: "5px",
        }}
      >
        <div style={{ height: "480px" }}>
          <Row
            className="d-flex jc-between ai-center"
            style={{
              padding: "0 0 30px 0",
            }}
          >
            <span style={{ fontWeight: 600, fontSize: 14, color: "#7D7D7D" }}>
              Cln ID: # {clinic_user_id}
            </span>
            <CloseOutlined
              onClick={() => setLocateOnMap(false)}
              style={{ fontSize: "20px", color: "red" }}
            />
          </Row>
          <Row>
            <LocateOnMap
              latitude={latitude}
              longitude={longitude}
              initialCenter={{
                lat: latitude,
                lng: longitude,
              }}
              address={address_line_one}
              mapStyles={mapStyles}
            />
          </Row>
        </div>
        <span className="address">
          {address_line_one},&nbsp;
          {address_line_two},&nbsp;
          {city},&nbsp;
          {province},&nbsp;
          {country},&nbsp;
          {zip_code}
        </span>
        <Row className="d-flex jc-between" style={{ margin: "20px 0 0 0" }}>
          <Button
            onClick={handleGetDirections}
            style={{
              borderRadius: 5,
              border: "1px solid #3CC9D2",
              background: "#FFF",
              color: "#3CC9D2",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            Get Directions
          </Button>
          <Button
            style={{
              borderRadius: 5,
              border: "1px solid #D35850",
              background: "#FFF",
              color: "#D35850",
              fontSize: 14,
              fontWeight: 600,
            }}
            onClick={() => setLocateOnMap(false)}
          >
            Close
          </Button>
        </Row>
      </Modal>
    </Row>
  );
};

export default ClinicDetails;
