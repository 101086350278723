import { Button, Col, Modal, Row, message } from "antd";
import React, { useEffect } from "react";
import DentalCareClinicLogo from "../../../../../../assets/default-profile-pic.png";
import accepticon from "../../../../../../assets/accept-icon.png";
import declineicon from "../../../../../../assets/decline-icon.png";
import { useNavigate } from "react-router";
import axios from "axios";
import { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import API_BASE_URL from "../../../../../../env";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../../string";
import AWS from "aws-sdk";
import {
  ADMIN,
  CUSTOMER_CARE,
  DATA_SCIENTIST,
  SALES,
  SUPER_ADMIN,
} from "../../../../../../utils/super_admin_constants/SuperAdminConsts";

const ClinicData = ({
  item,
  status,
  handleGetAllClinics,
  clinic_id,
  s3Url,
}) => {
  const Token = localStorage.getItem("Token");
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const status_active = "ACTIVE";
  const status_inactive = "INACTIVE";
  const status_pending = "PENDING_APPROVAL";
  const [isOpenAcceptModal, setisOpenAcceptModal] = useState(false);
  const [isOpenDeclineModal, setisOpenDeclineModal] = useState(false);
  const [isOpenActivateModal, setisOpenActivateModal] = useState(false);

  const handleAcceptClinicRequest = () => {
    const payload = {};
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/update-clinic-status?id=${clinic_id}&status=${status_active}`,
        payload,
        config
      )
      .then((res) => {
        message.success("Clinic Successfully Activated");
        setisOpenAcceptModal(true);
      })
      .catch((err) => {
        message.error("Error occured");
      });
  };

  const handleRejectClinicRequest = () => {
    const payload = {};
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/update-clinic-status?id=${clinic_id}&status=${status_inactive}`,
        payload,
        config
      )
      .then((res) => {
        message.success("Clinic Successfully DeActivated");
        handleGetAllClinics();
        setisOpenDeclineModal(false);
      })
      .catch((err) => {
        message.error("Error occured");
        setisOpenDeclineModal(false);
      });
  };

  const handleActivateClinic = () => {
    const payload = {};
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/update-clinic-status?id=${clinic_id}&status=${status_active}`,
        payload,
        config
      )
      .then((res) => {
        message.success("Clinic Successfully Activated");
        handleGetAllClinics();
        setisOpenActivateModal(false);
      })
      .catch((err) => {
        message.error("Error occured");
        setisOpenActivateModal(false);
      });
  };

  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: s3Url,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [s3Url]);

  return (
    <Row
      style={{
        // minHeight: 93,
        background: "#FBFBFB",
        margin: "0 0 5px 0",
        padding: "23px",
      }}
      className="d-flex ai-center"
    >
      <Col style={{ display: "flex", justifyContent: "center" }} span={1}>
        <img
          src={s3Url ? fileContent : DentalCareClinicLogo}
          alt=""
          style={{
            margin: window.innerWidth < 576 && "10px",
            maxWidth: "2.92vw",
            height: "2.92vw",
            cursor: "pointer",
            borderRadius: "0.26vw",
          }}
        />
      </Col>
      <Col style={{ display: "flex", flexDirection: "column" }} span={4}>
        <span
          style={{
            fontWeight: "600",
            fontSize: "0.834vw",
            opacity: status === status_pending && "0.8",
            color: status === status_active && "#2CA3FA",
            cursor: status === status_active && "pointer",
          }}
          onClick={() => {
            status === status_active &&
              navigate(`/super-admin/clinic-profile/${item.clinic_id}`);
          }}
        >
          {status === status_active ? (
            <u>{item.name}</u>
          ) : (
            <span
              style={{
                cursor: status === status_inactive && "pointer",
              }}
            >
              {item.name}
            </span>
          )}
        </span>
        <span
          style={{
            fontWeight: "600",
            fontSize: "0.834vw",
            color: "#000000",
            opacity: status !== status_active && "0.7",
          }}
        >
          CID# {item.clinic_id}
        </span>
      </Col>
      <Col span={7} style={{ padding: "0 1.563vw 0 0" }}>
        <span
          style={{
            fontWeight: "400",
            fontSize: "0.834vw",
            color: "#7D7D7D",
          }}
        >
          {item.address_line_one}, {item.city}, {item.province}, {item.zip_code}
        </span>
      </Col>
      <Col span={3}>
        <span
          style={{
            fontWeight: "400",
            fontSize: "0.834vw",
            color: "#7D7D7D",
          }}
        >
          {item.phone_number}
        </span>
      </Col>
      <Col span={4}>
        <span
          style={{
            fontWeight: "400",
            fontSize: "0.834vw",
            color: "#7D7D7D",
            display: "block",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {item.email}
        </span>
      </Col>
      <Col
        span={5}
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "25px",
        }}
      >
        {status === status_pending && (
          <Button
            style={{
              borderRadius: "1.563vw",
              color: "#3CC9D2",
              border: "1px solid #3CC9D2",
              background: "#ECFEFF",
            }}
            onClick={handleAcceptClinicRequest}
          >
            Accept
          </Button>
        )}
        {((status === status_active || status === status_pending) && role === SUPER_ADMIN) ||
          (status === status_active && role === ADMIN) ||
          ((status === status_active || status === status_pending) && role === SALES) ||
          (status === status_active && role === DATA_SCIENTIST) ||
          (status === status_active && role === CUSTOMER_CARE) ? (
          <Button
            style={{
              border: "1px solid #009DF7",
              borderRadius: "1.563vw",
              color: "#2CA3FA",
            }}
            onClick={() => {
              (status === status_active || status === status_pending) &&
                navigate(`/super-admin/clinic-profile/${item.clinic_id}`, { state: { status } });
            }}
          >
            View
          </Button>
        ) : (
          <></>
        )}
        {role === SUPER_ADMIN || role === ADMIN || role === CUSTOMER_CARE ? (
          <Button
            onClick={() => setisOpenDeclineModal(true)}
            style={{
              border: "1px solid #FF0000",
              borderRadius: "1.563vw",
              background: "#FFF5F3",
              color: "#EA4335",
            }}
          >
            {status === status_active && "Delete"}
            {status === status_pending && "Decline"}
          </Button>
        ) : (
          <></>
        )}

        {status === status_inactive && (
          <Button
            onClick={() => setisOpenActivateModal(true)}
            style={{
              border: "1px solid #009DF7",
              borderRadius: "1.563vw",
              color: "#2CA3FA",
            }}
          >
            Activate
          </Button>
        )}
      </Col>
      <Modal
        width={568}
        centered
        open={isOpenAcceptModal}
        footer={false}
        closable={false}
      >
        <Row className="d-flex ai-center jc-between">
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
              color: "#323232",
            }}
          >
            Clinic Registration Confirmation
          </span>
          <CloseOutlined
            style={{
              fontSize: 20,
              color: "red",
            }}
            onClick={() => {
              handleGetAllClinics();
              setisOpenAcceptModal(false);
            }}
          />
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            padding: "1.563vw 0",
            gap: 15,
          }}
        >
          <img
            src={accepticon}
            alt=""
            style={{
              width: 65,
              height: 65,
            }}
          />
          <span
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#323232",
            }}
          >
            You have successfully added clinic to Ivory.Ai.
          </span>
        </Row>
      </Modal>
      <Modal
        width={568}
        centered
        open={isOpenDeclineModal}
        footer={false}
        closable={false}
      >
        <Row className="d-flex ai-center jc-between">
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
              color: "#323232",
            }}
          >
            Clinic Registration Confirmation
          </span>
          <CloseOutlined
            style={{
              fontSize: 20,
              color: "red",
            }}
            onClick={() => {
              handleGetAllClinics();
              setisOpenAcceptModal(false);
              setisOpenDeclineModal(false);
            }}
          />
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            padding: "1.563vw 0",
            gap: 15,
          }}
        >
          <img
            src={declineicon}
            alt=""
            style={{
              width: 65,
              height: 65,
            }}
          />
          <span
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#323232",
              width: 422,
            }}
          >
            {status === status_active &&
              `Are you sure you want to De-Activate ${item.name}`}
            {status === status_pending &&
              `Are you sure you want to Decline Join Request of ${item.name}`}
          </span>
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            gap: 15,
          }}
        >
          <Button
            onClick={handleRejectClinicRequest}
            style={{
              background: "#FFF5F3",
              border: "1px solid #FF0000",
              borderRadius: 30,
              color: "#EA4335",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setisOpenDeclineModal(false);
            }}
            style={{
              background: "#ECFEFF",
              border: "1px solid #29C3C1",
              borderRadius: 30,
              color: "#29C3C1",
            }}
          >
            No
          </Button>
        </Row>
      </Modal>

      <Modal
        width={568}
        centered
        open={isOpenActivateModal}
        footer={false}
        closable={false}
      >
        <Row className="d-flex ai-center jc-between">
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
              color: "#323232",
            }}
          >
            Clinic Active Confirmation
          </span>
          <CloseOutlined
            style={{
              fontSize: 20,
              color: "red",
            }}
            onClick={() => {
              handleGetAllClinics();
              setisOpenActivateModal(false);
            }}
          />
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            padding: "1.563vw 0",
            gap: 15,
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#323232",
              width: 422,
            }}
          >
            {status === status_inactive &&
              `Are you sure you want to Activate the Clinic ${item.name}`}
          </span>
        </Row>
        <Row
          className="d-flex ai-center jc-center"
          style={{
            gap: 15,
          }}
        >
          <Button
            onClick={handleActivateClinic}
            style={{
              background: "#FFF5F3",
              border: "1px solid #FF0000",
              borderRadius: 30,
              color: "#EA4335",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setisOpenActivateModal(false);
            }}
            style={{
              background: "#ECFEFF",
              border: "1px solid #29C3C1",
              borderRadius: 30,
              color: "#29C3C1",
            }}
          >
            No
          </Button>
        </Row>
      </Modal>
    </Row>
  );
};

export default ClinicData;
