import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../../../../string";
import { DownloadOutlined } from "@ant-design/icons";
import { useLocation } from "react-router";

const ImageMap = (props) => {
  const {
    handleSendingImageUrl,
    s3Url,
    handleGettingMLDetails,
    id,
    report_image,
    downloadOption,
  } = props;

  const location = useLocation();
  const key = location.pathname;

  const [fileContent, setFileContent] = useState("");
  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: s3Url,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [s3Url]);

  const downloadFromS3Url = async (blobedURL) => {
    const anchorTag = document.createElement("a");
    anchorTag.href = blobedURL;
    anchorTag.download = "image.png";
    anchorTag.click();
    message.success("Image Downloaded");
  };

  return (
    <Row
      style={{
        display: "flex",
        alignItems: "center",
        background: "#F1FBFF",
        margin: "5px 0 0 0",
        padding: "10px",
      }}
    >
      <Col
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        span={9}
      >
        <img
          style={{
            height: "41px",
            width: "59px",
            cursor: "pointer",
          }}
          src={fileContent}
          alt=""
          onClick={() => {
            handleSendingImageUrl(fileContent);
            handleGettingMLDetails(id, report_image);
          }}
        />
      </Col>
      <Col
        span={downloadOption && key === "/patient/ml-analysis" ? 13 : 15}
        style={{ display: "flex", alignItems: "center" }}
      >
        <span
          style={{
            fontWeight: "600",
            fontSize: "14px",
            color: "#525252",
          }}
        >
          {props.title}
        </span>
      </Col>
      {downloadOption && key === "/patient/ml-analysis" && (
        <Col span={2} style={{ display: "flex", alignItems: "center" }}>
          <DownloadOutlined
            style={{ color: "#009DF7", fontSize: "18px" }}
            onClick={() => downloadFromS3Url(fileContent)}
          />
        </Col>
      )}
    </Row>
  );
};

export default ImageMap;
