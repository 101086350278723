import React, { useState } from "react";
import "./PatientSignup.css";
import signupbgimage from "../../../assets/signup-bg-image.png";
import Ivorylogo from "../../../assets/logo.png";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  Select,
  message,
  Space,
  Spin,
  Modal,
  Checkbox,
  DatePicker,
} from "antd";
import { Option } from "antd/lib/mentions";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  routes_patientlogin,
  routes_signup_thanks_message,
} from "../../../utils/patient_navbar_constants/PatientNavbarConstants";
import { phonecode } from "../../../utils/clinic_admin_constants/ClinicAdminConstants";
import API_BASE_URL from "../../../env";
import { LoadingOutlined } from "@ant-design/icons";
import { UserAuth } from "../../../context_api/ContextApi";

const PatientSignup = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { setSignupUserName } = UserAuth();
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 50, margin: "200px 0 0 0" }} spin />
  );
  const [spinner, setspinner] = useState(false);
  const [error, setError] = useState("");
  const [selectedDate, setDate] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [req, setReq] = useState(true);

  const validatePhoneNumber = (_, value) => {
    const numericRegex = /^[0-9]+$/;
    if (!value || (numericRegex.test(value) && value.length === 10)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Please enter exactly 10 numeric characters for the phone number."
    );
  };

  const disabledFutureDate = (currentDate) => {
    // Check if the current date is greater than the present date
    return currentDate.isAfter(moment(), "day");
  };

  const onDateChange = (dateString) => {
    setDate(dateString);
  };

  const validateZipCode = (_, value) => {
    const phoneRegex = /^\d{6}$/;

    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject("Please enter 6-digit zip code!");
  };

  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [terms, setTerms] = useState(false);

  const handleNextStep = async () => {
    try {
      const values = await form.validateFields();
      setFormData({ ...formData, ...values });
      // if (step == 1) {
      //   if (selectedDate) {
      //     setReq(false);
      //     setStep(step + 1);
      //     setDate("");
      //   } else setReq(true);
      // } else {
      // setStep(step + 1);
      // }
      setStep(step + 1);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleTermsAcceptChange = (event) => {
    setTerms(event.target.checked);
  };

  const handleTermsAndConditionOpen = () => {
    setOpenTermsModal(true);
  };

  // Function runs when patient user enter details and clicks sign up button
  const handleSubmitPatientSignUpDetails = async () => {
    try {
      const values = await form.validateFields();
      const finalFormData = { ...formData, ...values };
      finalFormData.date_of_birth = selectedDate.toISOString().split('T')[0];
      delete finalFormData.confirm;
      const additionalData = {
        country: "India",
        phone_country_code: values.phone_country_code
          ? values.phone_country_code
          : "+91",
        terms_accepted: terms,
      };
      Object.assign(finalFormData, additionalData);

      // Submit finalFormData

      const payload = finalFormData;

      if (terms === false) {
        // setOpenTermsModal(true);
        message.warning("Please accept the Terms and Conditions.");
      }
      if (terms === true) {
        setSignupUserName(values.Name);
        setspinner(true);
        axios
          .post(`${API_BASE_URL}/api/v1/auth/sign-up`, payload)
          .then((res) => {
            navigate(routes_signup_thanks_message);
            setTimeout(() => {
              navigate(routes_patientlogin);
              setspinner(false);
              form.resetFields();
              message.success("Signup success");
            }, 2000);
          })
          .catch((err) => {
            setspinner(false);
            message.error(err.response.data.data.errors);
          });
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleUserNameKeyPress = (e) => {
    if (
      (e.key === " " && e.target.selectionStart === 0) ||
      (e.key === " " &&
        !/^[a-zA-Z]$/.test(e.key) &&
        e.target.selectionStart === 0)
    ) {
      e.preventDefault();
    }
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handlePasswordOnChange = (e) => {
    const value = e.target.value;
    const repetitionRegex = /(.)\1{4,}/;
    if (repetitionRegex.test(value)) {
      setError("Password must not contain repeated characters or sequences.");
    } else {
      setError("");
      setPassword(value);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleKeyPress = (e) => {
    const value = e.key;
    const lastChar = password.charAt(password.length - 1);
    const secondLastChar = password.charAt(password.length - 2);
    const thirdLastChar = password.charAt(password.length - 3);
    const fourthLastChar = password.charAt(password.length - 4);
    if (
      lastChar &&
      secondLastChar &&
      thirdLastChar &&
      value === lastChar &&
      value === secondLastChar &&
      value === thirdLastChar &&
      value === fourthLastChar
    ) {
      e.preventDefault();
      setError("Password must not contain repeated characters or sequences.");
    }
  };

  const validatePassword = (_, value) => {
    if (/(\w)\1{3,}/.test(value)) {
      return Promise.reject(
        new Error("Password should not have more than 3 sequence characters")
      );
    }
    return Promise.resolve();
  };

  const validatePasswords = (_, value) => {
    const { Username, Name } = form.getFieldsValue(["Username", "Name"]);
    if (value.includes(Username) || value.includes(Name)) {
      return Promise.reject(
        new Error("Password must not contain username or parts of the name")
      );
    }
    return Promise.resolve();
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const allFieldsFilled = () => {
    return userName !== "" && password !== "" && confirmPassword !== "";
  };

  return (
    <div className="patient-signup-main-div">
      <Spin
        indicator={antIcon}
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <Row className="patient-signup-main-div">
          <Helmet>
            <title>Patient Signup</title>
          </Helmet>
          <Col className="ivory-logo-container" span={14} sm={14} xs={24}>
            <Row className="logo-description">
              <img
                data-testid="Ivory-logo"
                className="ivory-logo-img-tag"
                src={Ivorylogo}
                alt=""
              />
              <span className="description">
                Your Healthiest Smile Begins Here!
              </span>
            </Row>
          </Col>
          <Col
            span={10}
            sm={10}
            xs={24}
            className="patient-signup-form-container"
          >
            <Row className="signup-form">
              <Form
                className="signup-details-filling-form"
                onFinish={handleSubmitPatientSignUpDetails}
                form={form}
              >
                <Row>
                  <span className="patient-signup-text">Patient Sign Up</span>
                </Row>
                {step == 1 && (
                  <Row className="signup-details-filling-step">
                    <Row className="stepper">
                      <span className="step-number">Step 1 of 3</span>
                      <span className="personal-info">Personal Info</span>
                      <span className="step-description">
                        Let's get started! Just a few details for your
                        personalized account.
                      </span>
                    </Row>
                    <Row className="full-name-field">
                      <span className="patient-signup-input-title">
                        Full Name
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Full name!",
                          },
                          {
                            min: 4,
                            message: "Please enter minimum 4 characters",
                          },
                          {
                            max: 100,
                            message:
                              "Name should not be more than 100 characters long",
                          },
                        ]}
                      >
                        <Input
                          data-testid="jest-input-username"
                          maxLength={101}
                          label="Name"
                          className="patient-signup-input"
                          onKeyPress={handleUserNameKeyPress}
                        />
                      </Form.Item>
                    </Row>
                    <Row className="dob-field" style={{ gap: "0.312vw" }}>
                      <span className="patient-signup-input-title">
                        Date of Birth
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <Space
                        direction="vertical"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Form.Item
                          name="date_of_birth"
                          rules={[
                            {
                              required: selectedDate ? false : true,
                              message: "Please enter your Date of Birth",
                            },
                          ]}
                        >
                          <DatePicker
                            onChange={onDateChange}
                            style={{
                              width: "100%",
                              borderBottom: "1px solid #000",
                              borderWidth: "0 0 1px 0",
                            }}
                            disabledDate={disabledFutureDate}
                          />
                        </Form.Item>
                      </Space>
                    </Row>
                    <Row className="email-field">
                      <span className="patient-signup-input-title">
                        Email
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address",
                          },
                          {
                            max: 320,
                            message:
                              "Email address must be no more than 320 characters long",
                          },
                        ]}
                      >
                        <Input
                          data-testid="jest-input-email"
                          label="Email"
                          className="patient-signup-input"
                          maxLength={321}
                        />
                      </Form.Item>
                    </Row>
                    <Row className="phone-field">
                      <span className="patient-signup-input-title">
                        Phone No.
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <Space.Compact className="patient-signup-phone-input-row">
                      <Form.Item name="phone_country_code">
                        <Select
                          bordered={false}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "5.2084vw",
                            background: "#FFFFFF",
                            margin: "0.834vw 0 0 0",
                            borderBottom: "1px solid #000",
                          }}
                          defaultValue="+91"
                          options={phonecode}
                        />
                      </Form.Item>
                        <Form.Item
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter phone number",
                            },
                            { validator: validatePhoneNumber },
                          ]}
                        >
                          <Input
                            onKeyPress={(e) => {
                              const charCode = e.which || e.keyCode;
                              if (!(charCode >= 48 && charCode <= 57)) {
                                e.preventDefault();
                              }
                            }}
                            className="patient-signup-input"
                            style={{ width: "19.38vw" }}
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Row>
                    <Row className="button-field">
                      <Button className="next-button" onClick={handleNextStep}>
                        Next
                      </Button>
                    </Row>
                    <Row className="existing-user-link">
                      <span className="">Already Have Account?</span>
                      <span
                        className="login-link"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        Login
                      </span>
                    </Row>
                  </Row>
                )}
                {step == 2 && (
                  <Row className="signup-details-filling-step">
                    <Row className="stepper">
                      <span className="step-number">Step 2 of 3</span>
                      <span className="address-details">Address Details</span>
                      <span className="step-description">
                        Just a bit more to ensure we can reach you.
                      </span>
                    </Row>
                    <Row className="address-field">
                      <span className="patient-signup-input-title">
                        Address
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="address_line_one"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Address",
                          },
                        ]}
                      >
                        <Input
                          data-testid="jest-input-address"
                          label="Address"
                          className="patient-signup-input"
                        />
                      </Form.Item>
                    </Row>
                    <Row className="city-field">
                      <span className="patient-signup-input-title">
                        City
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the city"
                          },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and City must contain only alphabeticals",
                          },
                        ]}
                      >
                        <Input
                          data-testid="jest-input-city"
                          label="City"
                          type="text"
                          className="patient-signup-input"
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            const inputValue = e.target.value;
                            if (
                              !(
                                (charCode >= 65 && charCode <= 90) ||
                                (charCode >= 97 && charCode <= 122) ||
                                (charCode === 32 && inputValue.trim() !== "")
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Row>
                    <Row className="state-field">
                      <span className="patient-signup-input-title">
                        State<span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="province"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the state/province",
                          },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message:
                              "First character should be alphabetical and State/Province must contain only alphabeticals",
                          },
                        ]}
                      >
                        <Input
                          data-testid="jest-input-state"
                          label="State"
                          className="patient-signup-input"
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            const inputValue = e.target.value;
                            if (
                              !(
                                (charCode >= 65 && charCode <= 90) ||
                                (charCode >= 97 && charCode <= 122) ||
                                (charCode === 32 && inputValue.trim() !== "")
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Row>
                    <Row className="zipcode-field">
                      <span className="patient-signup-input-title">
                        Zip Code<span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="zip_code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the zip code",
                          },
                          { validator: validateZipCode }
                        ]}
                      >
                        <Input
                          data-testid="jest-input-zip"
                          label="Zip"
                          className="patient-signup-input"
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            if (!(charCode >= 48 && charCode <= 57)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Row>

                    <Row className="buttons-container">
                      <span className="go-back" onClick={handlePreviousStep}>
                        Go Back
                      </span>
                      <Button className="next-button" onClick={handleNextStep}>
                        Next
                      </Button>
                    </Row>
                    <Row className="existing-user-link">
                      <span className="">Already Have Account?</span>
                      <span
                        className="login-link"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        Login
                      </span>
                    </Row>
                  </Row>
                )}
                {step == 3 && (
                  <Row className="signup-details-filling-step">
                    <Row className="stepper">
                      <span className="step-number">Step 3 of 3</span>
                      <span className="credentials">Create Credentials</span>
                      <span className="step-description">
                        Almost done! Let's set up your secure login.
                      </span>
                    </Row>
                    <Row className="username-field">
                      <span className="patient-signup-input-title">
                        Username
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Username!",
                          },
                          {
                            min: 4,
                            message: "Please enter minimum 4 characters",
                          },
                          { whitespace: true, message: "No white spaces" },
                          {
                            pattern: /^[a-zA-Z0-9._]+$/,
                            message:
                              "Username can only contain characters, numbers, fullstops, and underscores.",
                          },
                          {
                            max: 100,
                            message:
                              "Username should not be more than 100 characters long",
                          },
                        ]}
                      >
                        <Input
                          data-testid="jest-input-username"
                          maxLength={101}
                          label="Username"
                          className="patient-signup-input"
                          onChange={handleUserNameChange}
                          onKeyPress={handleUserNameKeyPress}
                        />
                      </Form.Item>
                    </Row>
                    <Row className="password-field">
                      <span className="patient-signup-input-title">
                        Password
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            min: 8,
                            message: "Password should be atleast 8 characters",
                          },
                          { whitespace: true, message: "No white spaces" },
                          { required: true, message: "Please enter Password" },
                          {
                            pattern:
                              /^[A-Z](?=.*[A-Za-z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@#$!%*?&_]{7,}$/,
                            message:
                              "Password must have first character capital, mix of alphanumerics and @#$%^&*_",
                          },
                          {
                            max: 30,
                            message:
                              "Password should not be more than 30 characters long",
                          },
                          { validator: validatePassword },
                          { validator: validatePasswords },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          data-testid="jest-input-password"
                          maxLength={31}
                          className="patient-signup-input"
                          onChange={(e) => {
                            handlePasswordOnChange(e);
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e);
                          }}
                          onKeyDown={handleKeyDown}
                        />
                      </Form.Item>
                    </Row>
                    <Row className="confirm-password-field">
                      <span className="patient-signup-input-title">
                        Confirm Password
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <Form.Item
                        name="confirm"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The passwords does not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          data-testid="jest-input-confirm-password"
                          maxLength={31}
                          className="patient-signup-input"
                          onChange={handleConfirmPasswordChange}
                        />
                      </Form.Item>
                    </Row>
                    <Row className="terms-and-condition-row">
                      <Form.Item
                        name="terms_checked"
                        valuePropName="checked"
                        style={{ width: "100%" }}
                      >
                        <Checkbox
                          data-testid="agree-terms-checkbox"
                          className="agree-terms-checkbox"
                          type="checkbox"
                          onChange={handleTermsAcceptChange}
                        >
                          <span className="agree-text">Agree</span>
                        </Checkbox>
                        <span
                          className="terms-conditions"
                          onClick={handleTermsAndConditionOpen}
                        >
                          Terms and Condition
                        </span>
                      </Form.Item>
                    </Row>
                    <Row className="buttons-container">
                      <span className="go-back" onClick={handlePreviousStep}>
                        Go Back
                      </span>
                      <Button
                        className="signup-button"
                        type="primary"
                        htmlType="submit"
                        disabled={!allFieldsFilled()}
                      >
                        Sign Up
                      </Button>
                    </Row>
                    <Row className="existing-user-link">
                      <span className="">Already Have Account?</span>
                      <span
                        className="login-link"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        Login
                      </span>
                    </Row>
                  </Row>
                )}
              </Form>
            </Row>
          </Col>
        </Row>
      </Spin>
      <Modal
        centered
        width={"647px"}
        open={openTermsModal}
        closable={false}
        footer={false}
        className="clinic-details-modal"
        style={{
          borderRadius: "5px",
        }}
        destroyOnClose={true}
      >
        <div style={{ height: "50vh", overflow: "auto" }}>
          <Row>
            <h1>TERMS & CONDITIONS</h1>
            <section>
              <h3>Last Updated: 12-11-2023</h3>
              <p>
                These Terms and Conditions ("Terms") govern your use of Ivory.ai
                ("the App"), provided by [Your Company Name], an Indian company.
                By using the App, you agree to these Terms. If you do not agree
                with these Terms, you are advised to not use the App.
              </p>
            </section>
            <section>
              <h2>1. Acceptance of Terms</h2>
              <p>
                1.1 By accessing or using the App, you confirm that you are of
                legal age to enter into a binding contract in your jurisdiction.
              </p>
              <div>
                <p>
                  1.2 Ivory.ai is an online service offered by the Company. By
                  accessing or using any website with an authorized link to the
                  Website and/or the App, registering an account, or accessing
                  or using any content, information, services, features, or
                  resources available or enabled via the Website and/or the App
                  (collectively, the "Services"), clicking on a button, or
                  taking another action to signify your acceptance of this
                  Agreement, you
                </p>
                <p>
                  (a) agree to be bound by this Agreement and any future
                  amendments and additions to this Agreement as published
                  through the Services.
                </p>
                <p>
                  (b) represent you are of legal age in your jurisdiction of
                  residence to form a binding contract.
                </p>
                <p>
                  (c) represent that you have the authority to enter into this
                  Agreement personally and, if applicable, on behalf of any
                  company, organization, or other legal entity on whose behalf
                  you use the Services. If you are using the App on behalf of an
                  organization or entity, you represent and warrant that you
                  have the authority to bind that entity to these Terms, and
                  "you" refers to both you and the entity.
                </p>
                <p>
                  Except as otherwise provided herein, if you do not agree to be
                  bound by this Agreement, you may not access or use the
                  Services.
                </p>
              </div>
            </section>
            <section>
              <h2>2. App Purpose</h2>
              <p>
                2.1 The App provides dental information and self-diagnosis tools
                to assist users in assessing their dental health by way of (i)
                Patient Record Management System including managing contact
                details, treatment history, medical reports, progress notes and
                assessing health charts (ii) managing and processing the
                Information (defined below) of the patient in electronic format
                (iii) patient referrals through the mobile application (iv).
                Medical diagnosis of patient data such as X-rays by using AI
                Diagnosis tool and (v). patient appointment scheduling for
                meeting with the Client. It is not a substitute for professional
                dental advice, diagnosis, or treatment. The app shall refer you
                to consult with a qualified dental professional around your
                location for any dental health concerns.
              </p>
            </section>
            <section>
              <h3>3. User Responsibilities</h3>
              <p>
                3.1 You are solely responsible for the accuracy and completeness
                of any information you provide when using the App.
              </p>
              <p>
                3.2 You agree to use the App in accordance with these Terms and
                applicable laws and regulations. You will not violate any
                applicable law contract, intellectual property or other third
                party right or commit a tort, and you are solely responsible for
                your conduct while accessing or using the App. In connection
                with your use of the App and the Services, you will not
              </p>
              <ul>
                <li>
                  Engage in any harassing, threatening, intimidating, predatory,
                  or stalking conduct.
                </li>
                <li>
                  Impersonate another person or log into an account which you
                  are not authorized to access.
                </li>
                <li>
                  Use or attempt to use another User's account without
                  authorization from that User.
                </li>
                <li>
                  Use the App in any manner that could interfere with, disrupt
                  negatively affect or inhibit other users from fully enjoying
                  use of the App and the Services or that could damage, disable,
                  or impair the functioning of the App and the Services in any
                  manner or network security. Interfere or attempt to interfere
                  with service or any user, host or network, including by way of
                  introducing a virus, overloading.
                </li>
                <li>
                  Develop or use any third-party applications that Interact with
                  the App or Services without our prior written consent,
                  including any scripts designed to scrape or extract data from
                  the App.
                </li>
              </ul>
            </section>
            <section>
              <h3>4. Privacy Policy</h3>
              <p>
                4.1 This Privacy Policy has last been updated on 12-11-2023.
                Your use of the App is also governed by our Privacy Policy. By
                using the App, you consent to the collection, use, and sharing
                of your information as described in the Privacy Policy.
              </p>
              <p>
                While using Our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you. Personally identifiable information may
                include, but is not limited to:
              </p>
              <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Date of birth, Gender</li>
              </ul>
              <p>
                We also use information regarding your location to provide
                features of Our Service, to improve and customize Our Service.
                The information may be uploaded to the Company's servers and/or
                a Service Provider's server or it may be simply stored on Your
                device.
              </p>
              <ul>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>
                  Usage Data such as IP address, the time and date of Your
                  visit, the time spent on the platform, unique device
                  identifiers and other diagnostic data. (If not required can be
                  removed)
                </li>
              </ul>
              <p>The Personal Data shall be used for:</p>
              <ul>
                <li>To provide dental assessment and diagnostic services</li>
                <li>To manage your account on the app</li>
                <li>To manage your requests</li>
                <li>To contact you</li>
              </ul>
            </section>
            <section>
              <h3>5. No Medical Advice</h3>
              <p>
                5.1 The App does not provide medical or dental advice. The
                information provided through the App is for informational
                purposes only and is not a substitute for professional dental or
                medical advice, diagnosis, or treatment. Users are advised to
                use the dentist referral option on the app in case they need a
                professional dental diagnosis.
              </p>
            </section>
            <section>
              <h3>6. Limitation of Liability</h3>
              <p>
                6.1. Ivory.ai shall not be liable for any direct, indirect,
                incidental, special, consequential, or punitive damages, or any
                loss of profits, data, use, goodwill, or other intangible
                losses, resulting from your use of the App or reliance on the
                information provided by the App.
              </p>
            </section>
            <section>
              <h3>7. App Updates</h3>
              <p>
                7.1. Ivory.ai may release updates to the App from time to time.
                You are encouraged to use the latest version of the App for the
                most accurate information and functionality.
              </p>
            </section>
            <section>
              <h3>8. Termination</h3>
              <p>
                8.1. Ivory.ai may, at its sole discretion, terminate or suspend
                your access to the App at any time, with or without cause and
                without notice.
              </p>
            </section>
            <section>
              <h3>9. Changes to Terms</h3>
              <p>
                9.1. Ivory.ai reserves the right to modify or revise these Terms
                at any time. We will provide notice of any material changes.
                Your continued use of the App after the effective date of the
                updated Terms constitutes your acceptance of the changes.
              </p>
            </section>
            <section>
              <h3>10. Governing Law and Jurisdiction</h3>
              <p>
                10.1. These Terms shall be governed by and construed in
                accordance with the laws of India, without regard to its
                conflict of law principles. Any disputes arising under or in
                connection with these Terms shall be subject to the exclusive
                jurisdiction of the courts of Hyderabad.
              </p>
            </section>
            <section>
              <h3>
                11. Contact Information (If not required we can try to remove
                this part)
              </h3>
              <p>
                11.1. For questions or concerns regarding these Terms, please
                contact us at business@ivory.ai
              </p>
              <p>
                By accepting these Terms, you acknowledge that you have read,
                understood, and agree to be bound by them. These Terms are a
                legally binding agreement between you and Ivory.ai.
              </p>
            </section>
          </Row>
          <Row style={{ padding: "0 0 20px 0", gap: 15 }}>
            {/* <Button
              style={{
                borderRadius: 30,
                border: "1px solid #29c3c1",
                background: "#fff",
                color: "#29c3c1",
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() => {
                setTerms(true);
                setOpenTermsModal(false);
              }}
            >
              Accept
            </Button> */}
            <Button
              style={{
                borderRadius: 30,
                border: "1px solid #F7927E",
                background: "#fff",
                color: "#F7927E",
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={() => {
                setOpenTermsModal(false);
              }}
            // onClick={() => {
            //   setTimeout(() => {
            //     form.resetFields();
            //     setTerms(false);
            //     setOpenTermsModal(false);
            //   }, 700);
            // }}
            >
              Close
            </Button>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default PatientSignup;
