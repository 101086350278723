import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  Row,
  TimePicker,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import ssdental from "./../../../../assets/ssdental.png";
import { EllipsisOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import API_BASE_URL from "../../../../env";
import { UserAuth } from "../../../../context_api/ContextApi";

const ClinicDoctors = ({
  item,
  checkedDoctorValues,
  handleGetAllDoctorinClinic,
}) => {
  const Token = localStorage.getItem("Token");
  const [slotsEnable, setSlotsEnable] = useState(false);
  const [checkedSlotValues, setcheckedSlotValues] = useState(false);
  const [timeRange, setTimeRange] = useState([]);
  const [selectedDate, setselectedDate] = useState();
  const [form] = Form.useForm();

  const { setSpinner } = UserAuth();

  const handleSelectSlotDay = (values) => {
    setcheckedSlotValues(values);
    setselectedDate(values);
  };

  const handleTimeChange = (value) => {
    setTimeRange([
      value[0]?.format("THH:mm:ss"),
      value[1]?.format("THH:mm:ss"),
    ]);
  };

  const handleCreateSlotsForDoctor = (values) => {
    setSpinner(true);
    let i;
    if (selectedDate.length > 0) {
      for (i = 0; i < selectedDate.length; i++) {
        const payload = {
          from_time: selectedDate[i] + timeRange[0],
          to_time: selectedDate[i] + timeRange[1],
          date: selectedDate[i],
          doc_id: item.id,
        };
        let config = {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        };
        axios
          .post(`${API_BASE_URL}/api/v1/slots/createSlots`, payload, config)
          .then((res) => {
            message.success(res.data.status_message);
            setSpinner(false);
            setSlotsEnable(false);
            handleGetAllDoctorinClinic();
            // handleGetCreatedSlotsForDoctor();
            // form.resetFields();
            // setSlotsEnable(true);
          })
          .catch((err) => {
            message.error(err.response.data.data.errors);
            handleGetAllDoctorinClinic();
          });
      }
    } else message.warning("Please select which days to create slots");
  };

  const today = moment();
  const dateTabs = [];

  for (let i = 0; i < 7; i++) {
    const date = today.clone().add(i, "days");
    dateTabs.push({
      date: date.format("YYYY-MM-DD"),
      label: date.format("ddd"),
    });
  }

  const [commonArray, setcommonArray] = useState();

  const handleCommonArray = () => {
    const uniqueArray = [];
    const uniqueNames = {};
    item.slots_entity_list &&
      item.slots_entity_list.forEach((obj) => {
        const name = moment(obj.from_time).format("YYYY-MM-DD");
        if (!uniqueNames[name]) {
          uniqueNames[name] = true;
          uniqueArray.push(obj);
        }
      });
    setcommonArray(uniqueArray);
  };

  useEffect(() => {
    handleCommonArray();
  }, [item.slots_entity_list]);

  return (
    <div>
      <Row
        className={
          checkedDoctorValues && checkedDoctorValues.includes(item.id)
            ? "checked-doctor-details-row"
            : "doctor-details-row"
        }
      >
        <Row
          className="d-flex w-100 ai-center"
          style={{
            padding: "1.0417vw 2.0834vw",
          }}
        >
          <Col span={1}>
            <Checkbox value={item.id} className="custom-checkbox" />
          </Col>
          <Col span={5}>
            <Row className="d-flex ai-center" style={{ gap: 5 }}>
              <Col>
                <img
                  alt=""
                  src={ssdental}
                  style={{
                    width: "3.386vw",
                    height: "3.386vw",
                    borderRadius: "0.521vw",
                  }}
                />
              </Col>
              <Col>
                <span
                  style={{
                    fontSize: "0.834vw",
                    fontWeight: 500,
                  }}
                >
                  {item.name}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <span
              style={{
                fontSize: "0.834vw",
                fontWeight: 500,
              }}
            >
              {item.qualification}
            </span>
          </Col>
          <Col span={8}>
            <Row style={{ display: "flex", gap: "0.63vw" }}>
              {dateTabs &&
                dateTabs.map((tab, index) => {
                  return (
                    <Col>
                      {/* {commonArray &&
                        commonArray.forEach((obj) => { */}
                      <div
                        style={{
                          opacity:
                            // tab.label ===
                            // moment(obj.from_time).format("YYYY-MM-DD")
                              // ? 
                              // 1
                              // :
                               0.5,
                        }}
                        className={
                          checkedDoctorValues &&
                          checkedDoctorValues.includes(item.id)
                            ? "checked-availability-btn"
                            : "availability-btn"
                        }
                      >
                        <span
                          style={{
                            fontSize: "0.834vw",
                            fontWeight: 500,
                          }}
                        >
                          {tab.label}
                        </span>
                      </div>
                      {/* })} */}
                    </Col>
                  );
                })}
            </Row>
          </Col>
          <Col span={4} style={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{
                height: "2vw",
                color: "#FFF",
                background: "#3498DB",
                borderRadius: 5,
                fontSize: "0.73vw",
                fontWeight: 600,
                padding: "0.63vw",
              }}
              type="primary"
              onClick={() => {
                setSlotsEnable(true);
              }}
            >
              Create Slots
            </Button>
          </Col>
          <Col
            span={2}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <EllipsisOutlined
              style={{ fontSize: "1.25vw", cursor: "pointer" }}
            />
          </Col>
        </Row>
      </Row>
      <Modal
        width={"48.282vw"}
        centered
        open={slotsEnable}
        footer={false}
        closable={false}
        destroyOnClose={true}
      >
        <div>
          <Form
            form={form}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            className="w-100"
            onFinish={handleCreateSlotsForDoctor}
          >
            <Row>
              <span
                style={{
                  fontSize: "1.0417vw",
                  fontWeight: 600,
                }}
              >
                Manage Doctor Availability
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontSize: "0.834vw",
                  fontWeight: 400,
                }}
              >
                Edit the availability schedule for
              </span>
            </Row>
            <Row
              style={{
                padding: "25px 0",
                display: "flex",
                alignItems: "center",
                gap: 35,
              }}
            >
              <Col>
                <img
                  alt=""
                  src={ssdental}
                  style={{
                    width: "3.386vw",
                    height: "3.386vw",
                    borderRadius: "0.521vw",
                  }}
                />
              </Col>
              <Col>
                <Row>
                  <span
                    style={{
                      fontSize: "0.834vw",
                      fontWeight: 400,
                      color: "#B3B9BD",
                    }}
                  >
                    Doctor Name
                  </span>
                </Row>
                <Row>
                  <span
                    style={{
                      fontSize: "0.936vw",
                      fontWeight: 500,
                    }}
                  >
                    {item.name}
                  </span>
                </Row>
              </Col>
              <Col>
                <Row>
                  <span
                    style={{
                      fontSize: "0.834vw",
                      fontWeight: 400,
                      color: "#B3B9BD",
                    }}
                  >
                    Speciality
                  </span>
                </Row>
                <Row>
                  <span
                    style={{
                      fontSize: "0.936vw",
                      fontWeight: 500,
                    }}
                  >
                    {item.qualification}
                  </span>
                </Row>
              </Col>
            </Row>
            <Row style={{ diaply: "flex", flexDirection: "column" }}>
              <Row>
                <span
                  style={{
                    fontSize: "1.0417vw",
                    fontWeight: 500,
                  }}
                >
                  Select the Timing
                </span>
              </Row>
              <Row
                className="d-flex ai-center"
                style={{ gap: "1.563vw", padding: "5px 0 0 0" }}
              >
                <Form.Item
                  name="time_picker"
                  rules={[
                    {
                      required: true,
                      message: "Please select time slot!",
                    },
                  ]}
                >
                  <TimePicker.RangePicker
                    // disabled={slotsEnable}
                    minuteStep={15}
                    format="hh:mm a"
                    // style={{ width: 300, height: 45 }}
                    onChange={handleTimeChange}
                  />
                </Form.Item>
              </Row>
            </Row>
            <Row
              style={{
                display: "flex",
                gap: 5,
                flexDirection: "column",
                padding: "25px 0",
              }}
            >
              <Row>
                <span
                  style={{
                    fontSize: "1.0417vw",
                    fontWeight: 500,
                  }}
                >
                  Day Selection for Selected Time
                </span>
              </Row>
              <Row className="w-100">
                <Checkbox.Group
                  style={{
                    width: "100%",
                  }}
                  onChange={handleSelectSlotDay}
                >
                  <Row
                    style={{
                      display: "flex",
                      gap: "1.0417vw",
                    }}
                  >
                    {dateTabs &&
                      dateTabs.map((tab, index) => (
                        <Col>
                          <Checkbox
                            value={tab.date}
                            className="custom-checkbox-slots-days"
                          >
                            <div
                              className={
                                checkedSlotValues &&
                                checkedSlotValues.includes(tab.date)
                                  ? "checked-slot-weeks-div"
                                  : "slot-weeks-div"
                              }
                            >
                              <span
                                style={{
                                  fontSize: "0.834vw",
                                  fontWeight: 500,
                                }}
                              >
                                {tab.label}
                              </span>
                            </div>
                          </Checkbox>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              </Row>
            </Row>
            <Row
              className="d-flex jc-end ai-center"
              style={{ gap: 19, margin: "25px 25px 0 25px" }}
            >
              <Form.Item>
                <Button
                  style={{
                    height: "2vw",
                    border: "1px solid #EC7063",
                    borderRadius: 5,
                    fontSize: "0.73vw",
                    fontWeight: 600,
                    padding: "0 1.25vw",
                    background: "#FFF",
                    color: "#EC7063",
                  }}
                  type="danger"
                  onClick={() => {
                    form.resetFields();
                    setSlotsEnable(false);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    background: "#FFF",
                    fontSize: "0.73vw",
                    fontWeight: 600,
                    height: "2vw",
                    color: "#3498DB",
                    border: "1px solid #3498DB",
                    borderRadius: 5,
                    padding: "0 1.25vw",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ClinicDoctors;
