import React, { useEffect, useState } from "react";
import StaffProfilePic from "../../../../assets/default-profile-pic.png";
import AWS from "aws-sdk";
import {
  ACCESS_KEY,
  REGION,
  S3_BUCKET,
  SECRET_ACCESS_KEY,
} from "../../../../string";
import { message } from "antd";

const StaffProfilePicture = ({ pro }) => {
  const [fileContent, setFileContent] = useState("");
  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: S3_BUCKET,
        Key: pro,
      },
      (err, url) => {
        if (err) {
          message.error(err);
        } else {
          fetch(url)
            .then((response) => response.blob())
            .then((data) => {
              const url = URL.createObjectURL(data);
              setFileContent(url);
            })
            .catch((err) => message.error(err));
        }
      }
    );
  }, [pro]);
  return (
    <img
      style={{
        height: "3vw",
        width: "3vw",
        borderRadius: "0.52vw",
      }}
      src={pro ? fileContent : StaffProfilePic}
      alt=""
    />
  );
};

export default StaffProfilePicture;
