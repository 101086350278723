import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Spin,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./Surveys.css";
import API_BASE_URL from "../../../../env";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ADMIN,
  CUSTOMER_CARE,
  SUPER_ADMIN,
  SURVEY_UPDATE,
  routes_super_admin_create_surveys,
} from "../../../../utils/super_admin_constants/SuperAdminConsts";
import AddStaffIcon from "../../../../assets/plus-icon-green-col.png";
import { UserAuth } from "../../../../context_api/ContextApi";

const Surveys = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const location = useLocation();
  const tabUrl = location.pathname;
  const navigate = useNavigate();
  const [superAdminTabUrl] = useState(
    tabUrl === "/super-admin/surveys" ? true : false
  );
  const [form] = Form.useForm();
  const patient_id = localStorage.getItem("patient_id");
  const Token = localStorage.getItem("Token");
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const [surveyTopicText, setSurveyTopicText] = useState(1);
  const [patientSurveyDataMap, setPatientSurveyDataMap] = useState([]);
  const [defaultSurveyData, setDefaultSurveyData] = useState();
  const [allSurveyDataMap, setAllSurveyDataMap] = useState([]);
  const [surveyTopicId, setSurveyTopicId] = useState();
  const [participate, setParticipate] = useState(false);
  const [isOpenSurveySuccessModal, setIsOpenSurveySuccessModal] =
    useState(false);
  const [searchInput, setSearchInput] = useState("");
  let SurveyDefaultData;
  const { getSubUuserPpermissions } = UserAuth();

  // submits the patients survey
  const handleSubmitPatientSurveyDetails = (values) => {
    let payload;
    payload = {
      user_id: patient_id,
      survey_details: [
        {
          id: surveyTopicId,
          text: surveyTopicText,
          participate: true,
          survey_questions:
            patientSurveyDataMap &&
            patientSurveyDataMap.map((questionItem, index) => ({
              id: questionItem.id,
              text: questionItem.text,
              survey_question_type: questionItem.survey_question_type,
              randomize_options: questionItem.randomize_options,
              survey_answers: questionItem.survey_answers.map((answerItem) => ({
                id: answerItem.id,
                text: answerItem.text,
                answer_order: answerItem.answer_order,
                answered:
                  values[index] === undefined
                    ? answerItem.answered === true
                      ? true
                      : false
                    : values[index] === answerItem.text
                    ? true
                    : false,
              })),
              question_order: questionItem.question_order,
              answered_text: "string",
            })),
        },
      ],
    };
    if (defaultSurveyData[0] === "" || defaultSurveyData[0] === null) {
      // runs on initial signup
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      axios
        .post(`${API_BASE_URL}/api/v1/survey`, payload, config)
        .then((res) => {
          message.success(res.data.data);
          setIsOpenSurveySuccessModal(true);
        });
    } else {
      // updates the existing values
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      axios
        .put(`${API_BASE_URL}/api/v1/survey`, payload, config)
        .then((res) => {
          message.success(res.data.data);
          setIsOpenSurveySuccessModal(true);
        });
    }
  };
  //

  // Patient specific survey details on opening the survey module
  const handleGetPatientSurveyDetails = () => {
    form.resetFields();
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        superAdminTabUrl === true
          ? `${API_BASE_URL}/api/v1/survey/${surveyTopicId}`
          : `${API_BASE_URL}/api/v1/survey/${surveyTopicId}?user_id=${patient_id}`,
        config
      )
      .then((res) => {
        if (res.data.data.participate === true) {
          setParticipate(true);
        }
        setPatientSurveyDataMap(res.data.data.survey_questions);
        SurveyDefaultData = res.data.data.survey_questions.map((item) =>
          item.survey_answers
            .filter((item) => item.answered === true)
            .map((item) => item.text)
            .toString()
        );
        setDefaultSurveyData(SurveyDefaultData);
      });
  };
  //

  // Retireves the survey details based on the selected survey topic
  const handleSelectTopicGetPatientSurveyDetails = (id) => {
    setParticipate(false);
    form.resetFields();
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(
        superAdminTabUrl === true
          ? `${API_BASE_URL}/api/v1/survey/${id}`
          : `${API_BASE_URL}/api/v1/survey/${id}?user_id=${patient_id}`,
        config
      )
      .then((res) => {
        if (res.data.data.participate === true) {
          setParticipate(true);
        }
        setPatientSurveyDataMap(res.data.data.survey_questions);
        SurveyDefaultData = res.data.data.survey_questions.map((item) =>
          item.survey_answers
            .filter((item) => item.answered === true)
            .map((item) => item.text)
            .toString()
        );
        setDefaultSurveyData(SurveyDefaultData);
        setLoading(false);
      });
  };
  //

  // Initaial default / All survey details when user signs up
  const handleGetAllSurveyDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios.get(`${API_BASE_URL}/api/v1/survey`, config).then((res) => {
      setAllSurveyDataMap(res.data.data);
      setSurveyTopicId(res.data.data[0].id);
      handleSelectTopicGetPatientSurveyDetails(res.data.data[0].id);
    });
  };
  //

  // Function to delete the survey from UI
  const handleDeleteSurvey = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios.delete(`${API_BASE_URL}/api/v1/survey/${id}`, config).then((res) => {
      handleGetAllSurveyDetails();
    });
  };
  //

  useEffect(() => {
    handleGetAllSurveyDetails();
  }, []);

  useEffect(() => {
    if (!superAdminTabUrl) {
      handleGetPatientSurveyDetails();
    }
  }, []);

  return (
    <div className="survey-page-main-div">
      <Helmet>
        <title>{superAdminTabUrl ? "Surveys" : "Attend Survey"}</title>
      </Helmet>
      {superAdminTabUrl === false ? (
        <Row
          style={{
            height: "6.564102564102564vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              margin: "0 0 0 22px",
              color: "#525252",
            }}
          >
            Surveys
          </span>
        </Row>
      ) : (
        <Row
          className="d-flex ai-center jc-between"
          style={{ margin: "12px 23px" }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Surveys
          </span>
          {role === SUPER_ADMIN || role === ADMIN || role === SURVEY_UPDATE ? (
            <Button
              icon={
                <img style={{ height: "20px" }} src={AddStaffIcon} alt="" />
              }
              onClick={() => navigate(routes_super_admin_create_surveys)}
              style={{
                borderRadius: 40,
                border: "1px solid #97C46F",
                background: "#FFF",
                color: "#525252",
                fontSize: 14,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 5,
              }}
            >
              Create New Survey
            </Button>
          ) : (
            ""
          )}
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "transparent",
            }}
          >
            Surveys (2)
          </span>
        </Row>
      )}
      {
        allSurveyDataMap && allSurveyDataMap.length > 0 ? (
          <Row className="d-flex jc-evenly">
            <Col
              sm={10}
              lg={6}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div className="survey-topic-div">
                <div className="survey-sidebar">
                  <Row
                    style={{
                      height: "82px",
                    }}
                    className="d-flex jc-center ai-center"
                  >
                    <Input
                      type="search"
                      placeholder="input search text"
                      style={{
                        width: "100%",
                        height: 40,
                        background: "#FFFFFF",
                        border: "1px solid #E3E3E3",
                        borderRadius: 3,
                      }}
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Row>
                  <Row
                    className="w-100 d-flex"
                    style={{
                      gap: "8px",
                    }}
                  >
                    {allSurveyDataMap &&
                      allSurveyDataMap
                        .filter((item) =>
                          item.text
                            .toLowerCase()
                            .includes(searchInput.toLowerCase())
                        )
                        .map((item) => {
                          return (
                            <>
                              {surveyTopicId === item.id ? (
                                <Row className="survey-tabs-selected">
                                  <Col>
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        color: "#525252",
                                        padding: "0 0 0 16px",
                                      }}
                                    >
                                      {item.text}
                                    </span>
                                  </Col>
                                </Row>
                              ) : (
                                <Row className="survey-tabs">
                                  <Col>
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: 16,
                                        color: "#525252",
                                        padding: "0 0 0 16px",
                                      }}
                                    >
                                      {item.text}
                                    </span>
                                  </Col>
                                  <Col>
                                    <Button
                                      className="survey-participate-btn"
                                      onClick={() => {
                                        setSurveyTopicId(item.id);
                                        setSurveyTopicText(item.text);
                                        setLoading(true);
                                        setTimeout(() => {
                                          handleSelectTopicGetPatientSurveyDetails(
                                            item.id
                                          );
                                        }, 500);
                                      }}
                                    >
                                      View
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                            </>
                          );
                        })}
                  </Row>
                </div>
              </div>
            </Col>
            <Col
              sm={14}
              lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="survey-questions-div">
                <Spin indicator={antIcon} spinning={loading}>
                  <Form form={form} onFinish={handleSubmitPatientSurveyDetails}>
                    {allSurveyDataMap.map((item) => {
                      return (
                        surveyTopicId === item.id && (
                          <div
                            className="survey-content"
                            key={item.id}
                            style={{
                              padding: "0 0 50px 0",
                            }}
                          >
                            <Row className="w-100 d-flex ai-center">
                              <span className="survey-topic-name-text">
                                {item.text}
                              </span>
                              {(superAdminTabUrl &&
                                tabUrl &&
                                role === SUPER_ADMIN) ||
                              (superAdminTabUrl && tabUrl && role === ADMIN) ||
                              (superAdminTabUrl &&
                                tabUrl &&
                                role === CUSTOMER_CARE) ||
                              (superAdminTabUrl &&
                                tabUrl &&
                                role === SURVEY_UPDATE) ? (
                                <Button
                                  className="survey-participate-btn"
                                  onClick={() =>
                                    navigate(
                                      `/super-admin/edit-surveys/${item.id}`
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                              ) : (
                                ""
                              )}
                              {(superAdminTabUrl &&
                                tabUrl &&
                                role === SUPER_ADMIN) ||
                              (superAdminTabUrl && tabUrl && role === ADMIN) ||
                              (superAdminTabUrl &&
                                tabUrl &&
                                role === SURVEY_UPDATE) ? (
                                <Button
                                  className="survey-participate-btn"
                                  onClick={() => handleDeleteSurvey(item.id)}
                                  style={{
                                    margin: "0 0 0 20px",
                                  }}
                                >
                                  Delete
                                </Button>
                              ) : (
                                ""
                              )}
                            </Row>
                            {patientSurveyDataMap.length > 0 &&
                              patientSurveyDataMap
                                .sort(
                                  (a, b) => a.question_order - b.question_order
                                )
                                .map((item, index) => {
                                  return (
                                    <>
                                      <Form.Item
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please answer the question",
                                          },
                                        ]}
                                        name={index}
                                        key={item.id}
                                      >
                                        <Radio.Group key={item.id}>
                                          <>
                                            <Row>
                                              <span className="survey-question-text">
                                                {item.text}
                                              </span>
                                            </Row>
                                            {item.survey_answers
                                              .sort(
                                                (a, b) =>
                                                  a.answer_order -
                                                  b.answer_order
                                              )
                                              .map((it) => {
                                                return (
                                                  <Row>
                                                    <div className="d-flex ai-center survey-answers-options-div">
                                                      {superAdminTabUrl ? (
                                                        <span
                                                          style={{
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#7D7D7D",
                                                            padding: "0 20px",
                                                          }}
                                                          className="survey-answer-options-text"
                                                        >
                                                          {it.text}
                                                        </span>
                                                      ) : (
                                                        <Radio
                                                          value={
                                                            it.answered === true
                                                              ? item
                                                                  .survey_answers
                                                                  .text
                                                              : it.text
                                                          }
                                                          style={{
                                                            margin: "0 12px",
                                                          }}
                                                          disabled={
                                                            participate && true
                                                          }
                                                        >
                                                          <span
                                                            style={{
                                                              fontWeight: 400,
                                                              fontSize: 16,
                                                              color: "#7D7D7D",
                                                            }}
                                                            className="survey-answer-options-text"
                                                          >
                                                            {it.text}
                                                          </span>
                                                        </Radio>
                                                      )}
                                                    </div>
                                                  </Row>
                                                );
                                              })}
                                          </>
                                        </Radio.Group>
                                      </Form.Item>
                                      {item.survey_question_type ===
                                        "OPEN_RESPONSE" && (
                                        <Form.Item
                                          initialValue={item.answered_text}
                                          style={{ margin: 0 }}
                                          name="oral_condition"
                                        >
                                          <TextArea
                                            style={{
                                              margin: "0 45px",
                                              width: "50%",
                                              height: "10vh",
                                              borderRadius: "5px",
                                              background: "#F9F9F9",
                                              border: "1px solid #ccc",
                                            }}
                                            bordered={false}
                                          />
                                        </Form.Item>
                                      )}
                                    </>
                                  );
                                })}
                            {!participate ? (
                              <Form.Item
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "20px 0 0 0",
                                }}
                              >
                                {!superAdminTabUrl && (
                                  <Button
                                    style={{
                                      width: 85,
                                      height: 30,
                                      background: "#E0F2FF",
                                      border: "1px solid #009DF7",
                                      borderRadius: 30,
                                      fontWeight: 600,
                                      fontSize: 14,
                                      color: "#2CA3FA",
                                    }}
                                    type="primary"
                                    htmlType="submit"
                                  >
                                    Submit
                                  </Button>
                                )}
                              </Form.Item>
                            ) : (
                              <Row
                                className="w-100 d-flex jc-center"
                                style={{
                                  fontSize: 22,
                                  fontWeight: 600,
                                  padding: "20px 0",
                                  color: "#7D7D7D",
                                }}
                              >
                                Thanks for your Responses
                              </Row>
                            )}
                          </div>
                        )
                      );
                    })}
                  </Form>
                </Spin>
              </div>
            </Col>
          </Row>
        ) : (
          <Row
            className="d-flex jc-center"
            style={{
              margin: "0 22px",
              padding: "50px 0",
              background: "#FFF",
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: 20,
                margin: "0 0 0 22px",
                color: "#525252",
              }}
            >
              No Survey available at the moment
            </span>
          </Row>
        )
      }
      <Modal
        width={"647px"}
        open={isOpenSurveySuccessModal}
        closable={false}
        footer={false}
        centered
        destroyOnClose={true}
      >
        <div
          style={{
            height: 305,
            background: "#FFFFFF",
            borderRadius: 5,
          }}
        >
          <Row className="d-flex jc-between ai-center">
            <span
              style={{
                fontWeight: 700,
                fontSize: 22,
                color: "#525252",
              }}
            >
              Survey
            </span>
            <CloseOutlined
              style={{
                fontSize: "20px",
                color: "red",
              }}
            />
          </Row>
          <Row
            style={{
              margin: "3.64vw 0",
            }}
          >
            <div
              className="d-flex jc-center ai-center"
              style={{
                margin: "0 102px",
                width: 404,
                height: 57,
                background: "#F9F9F9",
                borderRadius: 5,
              }}
            >
              <span
                style={{
                  fontWeight: 400,
                  fontSize: 20,
                  color: "#7D7D7D",
                }}
              >
                Thank you for Participating in the Survey
              </span>
            </div>
          </Row>
          <Row className="d-flex jc-center">
            <Button
              className="survey-close-btn"
              onClick={() => {
                setIsOpenSurveySuccessModal(false);
                handleGetPatientSurveyDetails();
              }}
            >
              Close
            </Button>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default Surveys;
