import {
  Row,
  Form,
  Col,
  Menu,
  Spin,
  Button,
  Input,
  Select,
  Calendar,
  Modal,
  Tabs,
} from "antd";
import { Helmet } from "react-helmet";
import "./AdminPatientProfile.css";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import API_BASE_URL from "../../../../../env";
import calIcon from "../../../../../assets/cal-icon.png";
import PatientProfile, {
  statusOptions,
} from "./patient_profile/PatientProfile";
import {
  _status_cancelled,
  _status_completed,
  _status_confirmed,
  _status_in_progress,
  _status_rescheduled,
  _status_scheduled,
} from "../../../../../utils/appointment_constants/AppointmentConstants";

const AdminPatientProfile = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const { patient_id } = useParams();
  const [form] = Form.useForm();
  const Token = localStorage.getItem("Token");
  const [spinner, setSpinner] = useState(false);
  const [patientUserDetails, setPatientUserDetails] = useState("");

  const getPatientDetails = async () => {
    setSpinner(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/patient/${patient_id}`, config)
      .then((res) => {
        setPatientUserDetails(res.data.data);
        setSpinner(false);
      });
  };

  useEffect(() => {
    getPatientDetails();
  }, []);

  const [patientTabsData, setPatientTabsData] = useState(
    "Personal Information"
  );

  const items = [
    {
      type: "group",
      label: (
        <span
          style={{
            fontSize: "1.25vw",
            fontWeight: 700,
            color: "#333333",
          }}
        >
          Manage Patient and Insurance Information
        </span>
      ),
      children: [
        {
          label: (
            <span
              style={{
                fontSize: "0.9375vw",
                fontWeight: 400,
              }}
            >
              Patient Information
            </span>
          ),
          key: "1",
        },
        {
          label: (
            <span
              style={{
                fontSize: "0.9375vw",
                fontWeight: 400,
              }}
            >
              Insurance Request Details
            </span>
          ),
          key: "2",
        },
        {
          label: (
            <span
              style={{
                fontSize: "0.9375vw",
                fontWeight: 400,
              }}
            >
              View Reports
            </span>
          ),
          key: "3",
        },
        {
          label: (
            <span
              style={{
                fontSize: "0.9375vw",
                fontWeight: 400,
              }}
            >
              Treatment Information
            </span>
          ),
          key: "4",
        },
      ],
    },
  ];

  const [treatmentHistory, setTreatmentHistoryDetails] = useState();
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [isInvoiceClicked, setInvoiceClicked] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const invoiceRef = useRef(null);

  const [inputsEnable, setInputsEnable] = useState(true);
  const currentUrl = window.location.href;
  const [displayDate, setDisplayDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState({
    year: moment().format("yyyy"),
    month: moment().format("MM"),
  });

  const onPanelChange = (date, mode) => {
    setCurrentDate({ year: date.year(), month: date.month() + 1 });
    setDisplayDate(date); // Update display date
  };

  const onDateSelect = (date) => {
    setSelectedDate(null);
    setDisplayDate(date);
    setSelectedDate(date);
  };

  const handleApplyButton = () => {
    setOpenCalendarModal(false);
    let date = moment(selectedDate).format("yyyy-MM-DD");
    setDisplayDate(date);
    handleStatusChange("created_at", date);
  };

  const [selectTab, setselectTab] = useState("1");
  const onClick = (e) => {
    setselectTab(e.key);
  };

  const [params, setParams] = useState({});
  const handleStatusChange = (key, value) => {
    if (value === "Paid") {
      setParams({
        ...params,
        [key]: "captured",
      });
    } else if (value === "Pending") {
      setParams({
        ...params,
        [key]: "failed",
      });
    } else if (
      key === "created_at" ||
      key === "clinicNameOrDoctorNameOrTreatmentName"
    ) {
      setParams({
        ...params,
        [key]: value,
      });
    } else {
      setParams({
        ...params,
        [key]: "",
      });
    }
  };

  // Function to construct new URL with updated query params
  const constructUrl = (baseUrl, paramsToAdd) => {
    const url = new URL(baseUrl);
    const queryParams = new URLSearchParams(url.search);
    for (const key in paramsToAdd) {
      if (paramsToAdd[key]) {
        queryParams.set(key, paramsToAdd[key]);
      } else {
        queryParams.delete(key);
      }
    }
    url.search = queryParams.toString();
    return url.toString();
  };

  const baseUrl = `${API_BASE_URL}/api/v1/treatments/treatment_payment_history/patient?patient_id=${patient_id}`;
  const newUrl = constructUrl(baseUrl, params);
  useEffect(() => {
    getTreatmentHistoryByFilters();
  }, [newUrl]);

  const getTreatmentHistoryByFilters = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios.get(newUrl, config).then((res) => {
      setTreatmentHistoryDetails(res.data.data);
      setSpinner(false);
    });
  };

  const handleByDateClick = () => {
    setOpenCalendarModal(true);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(item) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const payload = {
      type: "invoice",
      description: `Invoice for ${patientUserDetails.name}`,
      partialPayment: true,
      customer: {
        name: patientUserDetails.name,
        contact: patientUserDetails.phone_number,
        email: patientUserDetails.email,
        billingAddress: {
          // line1: patientUserDetails.address_line_one,
          line2: patientUserDetails.address_line_one,
          zipcode: patientUserDetails.zip_code,
          city: patientUserDetails.city,
          state: patientUserDetails.province,
          country: patientUserDetails.country,
        },
      },
      line_items: [
        {
          name: item.treatment_name,
          description: item.description,
          // amount: item.treatment_type.rate,
          amount: item.rate ? item.rate : 10,
          currency: "INR",
          quantity: 1,
        },
      ],
      smsNotify: 1,
      emailNotify: 1,
      currency: "INR",
      expireBy: 1765046400,
    };

    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    const response = await axios.post(
      `http://ec2-43-205-173-70.ap-south-1.compute.amazonaws.com:8080/api/v1/payment/generateInvoice`,
      payload,
      config
    );

    window.location.href = response.data;
  }

  return (
    <div className="clinic-patient-profile-div d-flex jc-center ai-start">
      <Helmet>
        <title>Patient Account</title>
      </Helmet>
      <Spin
        indicator={antIcon}
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <div
          style={{
            margin: "50px",
            minWidth: "79.6875vw",
            maxWidth: "89.6875vw",
            background: "#fff",
            padding: "4.1710114702815435vh 1.0416666666666667vw",
            borderRadius: "10px",
          }}
        >
          <Row>
            <Col
              span={6}
              style={{
                borderRight: "1px solid #C2C2C2",
                padding: "0 20px 0 0",
              }}
            >
              <Menu
                onClick={onClick}
                style={{
                  width: "100%",
                }}
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["1"]}
                mode="inline"
                items={items}
              />
            </Col>
            <Col span={18}>
              {selectTab == 1 && (
                <PatientProfile
                  patientUserDetails={patientUserDetails}
                  getPatientDetails={getPatientDetails}
                  setPatientUserDetails={setPatientUserDetails}
                  setSpinner={setSpinner}
                  form={form}
                  patient_id={patient_id}
                  patientTabsData={patientTabsData}
                  setPatientTabsData={setPatientTabsData}
                />
              )}
              {selectTab == 2 && <div>Insurance in progress</div>}
              {selectTab == 3 && <div>Insurance in progress</div>}
              {selectTab == 4 && (
                <div>
                  <div
                    style={{
                      padding: "1.0417vw 2.0834vw 0 2.0834vw",
                      minHeight: "65vh",
                    }}
                  >
                    <Row>
                      <span
                        style={{
                          fontSize: "1.25vw",
                          fontWeight: 700,
                          color: "#333333",
                        }}
                      >
                        Treatment Information
                      </span>
                    </Row>
                    <Row>
                      <span
                        style={{
                          fontSize: "0.9375vw",
                          fontWeight: 400,
                          color: "#717171",
                        }}
                      >
                        View the upcoming and completed treatments for the
                        selected patient
                      </span>
                    </Row>
                    <div
                      className="d-flex flex-col"
                      style={{ margin: "1.25vw 1.563vw 0", gap: "1.3023vw" }}
                    >
                      <Row
                        className="d-flex jc-between"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Col>
                          <Input
                            prefix={<SearchOutlined style={{ opacity: 0.5 }} />}
                            className="search-treatment"
                            placeholder="Search by Treatment, Doctor..."
                            onPressEnter={(value) =>
                              handleStatusChange(
                                "clinicNameOrDoctorNameOrTreatmentName",
                                value.target.value
                              )
                            }
                          />
                        </Col>
                        <Col
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "0.52vw",
                          }}
                        >
                          <span
                            style={{
                              leadingTrim: "both",
                              textEdge: "cap",
                              fontSize: "0.834vw",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "140.04 %" /* 22.406px */,
                            }}
                          >
                            Filters:
                          </span>
                          <Select
                            className="status-select"
                            placeholder="By Status"
                            options={statusOptions}
                            onChange={(value) =>
                              handleStatusChange("status", value)
                            }
                          ></Select>
                          <Button
                            style={{
                              display: "flex",
                              width: "8.46vw",
                              height: "2.292vw",
                              padding: "0.63vw",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderRadius: "5px",
                              border: "1px solid #AEAEAE",
                            }}
                            onClick={handleByDateClick}
                          >
                            <span
                              style={{
                                color: "#6D6D6D",
                                fontSize: "0.834vw",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: " 140.04%",
                              }}
                            >
                              By Date
                            </span>
                            <img
                              alt=""
                              style={{
                                height: "1.25vw",
                                width: "1.25vw",
                              }}
                              src={calIcon}
                            />
                          </Button>
                        </Col>
                      </Row>

                      <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Upcoming" key="1">
                          <Row className="">
                            {treatmentHistory ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.938vw",
                                  height: "52vh",
                                  overflowY: "scroll",
                                }}
                              >
                                {treatmentHistory
                                  .filter(
                                    (item) =>
                                      item.appointment_status !== "Complete"
                                  )
                                  .map((item) => {
                                    return (
                                      <div
                                        style={{
                                          width: "49.375vw",
                                          height: "15.938vw",
                                          padding: "1.834vw 2.5vw",
                                          borderRadius: "0.52vw",
                                          border: "1px solid #EBEBEB",
                                          background:
                                            "var(--Button-Text-color, #FFF)",
                                          boxShadow:
                                            "0.10425vw 0.10425vw 1.0417vw 0px rgba(0, 0, 0, 0.05)",
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "1.872vw",
                                        }}
                                      >
                                        <Row>
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Treatment Name
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {item.treatment_name}
                                              </span>
                                            </Row>
                                          </Col>
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Treatment Type
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {item.treatment_type}
                                              </span>
                                            </Row>
                                          </Col>
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Date
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {moment(
                                                  item.appointment_date
                                                ).format("MMMM DD, YYYY")}
                                              </span>
                                            </Row>
                                          </Col>
                                        </Row>
                                        <Row>
                                          {/* <Col
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "0.26vw",
                                      }}
                                      span={6}
                                    >
                                      <Row>
                                        <span
                                          style={{
                                            fontSize: "0.73vw",
                                            color: "#B3B9BD",
                                            fontWeight: 400,
                                          }}
                                        >
                                          Clinic Name
                                        </span>
                                      </Row>
                                      <Row>
                                        <span
                                          style={{
                                            fontSize: "0.834vw",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {item.clinic_name}
                                        </span>
                                      </Row>
                                    </Col> */}
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Doctor
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {item.doc_name}
                                              </span>
                                            </Row>
                                          </Col>
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Fees
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                Rs.{item.rate ? item.rate : 0}
                                              </span>
                                            </Row>
                                          </Col>
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Appointment Status
                                              </span>
                                            </Row>
                                            <Row>
                                              {item.appointment_status ===
                                                _status_scheduled && (
                                                <span
                                                  style={{
                                                    color: "#E67E22",
                                                    fontSize: "0.834vw",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {item.appointment_status}
                                                </span>
                                              )}
                                              {item.appointment_status ===
                                                _status_confirmed && (
                                                <span
                                                  style={{
                                                    color: "#27AE60",
                                                    fontSize: "0.834vw",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {item.appointment_status}
                                                </span>
                                              )}
                                              {item.appointment_status ===
                                                _status_in_progress && (
                                                <span
                                                  style={{
                                                    color: "#3498DB",
                                                    fontSize: "0.834vw",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {item.appointment_status}
                                                </span>
                                              )}
                                              {item.appointment_status ===
                                                _status_completed && (
                                                <span
                                                  style={{
                                                    color: "#333",
                                                    fontSize: "0.834vw",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {item.appointment_status}
                                                </span>
                                              )}
                                              {item.appointment_status ===
                                                _status_cancelled && (
                                                <span
                                                  style={{
                                                    color: "#EC7063",
                                                    fontSize: "0.834vw",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {item.appointment_status}
                                                </span>
                                              )}
                                              {item.appointment_status ===
                                                _status_rescheduled && (
                                                <span
                                                  style={{
                                                    color: "#E67E22",
                                                    fontSize: "0.834vw",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {/* {item.appointment_status} */}
                                                  Pending
                                                </span>
                                              )}
                                            </Row>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  })}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Completed" key="2">
                          <Row className="">
                            {treatmentHistory ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.938vw",
                                  height: "52vh",
                                  overflowY: "scroll",
                                }}
                              >
                                {treatmentHistory
                                  .filter(
                                    (item) =>
                                      item.appointment_status === "Complete"
                                  )
                                  .map((item) => {
                                    return (
                                      <div
                                        style={{
                                          width: "49.375vw",
                                          height: "15.938vw",
                                          padding: "1.834vw 2.5vw",
                                          borderRadius: "0.52vw",
                                          border: "1px solid #EBEBEB",
                                          background:
                                            "var(--Button-Text-color, #FFF)",
                                          boxShadow:
                                            "0.10425vw 0.10425vw 1.0417vw 0px rgba(0, 0, 0, 0.05)",
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "1.872vw",
                                        }}
                                      >
                                        <Row>
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Treatment Name
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {item.treatment_name}
                                              </span>
                                            </Row>
                                          </Col>
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Treatment Type
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {item.treatment_type}
                                              </span>
                                            </Row>
                                          </Col>
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Date
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {moment(
                                                  item.appointment_date
                                                ).format("MMMM DD, YYYY")}
                                              </span>
                                            </Row>
                                          </Col>
                                        </Row>
                                        <Row>
                                          {/* <Col
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "0.26vw",
                                      }}
                                      span={6}
                                    >
                                      <Row>
                                        <span
                                          style={{
                                            fontSize: "0.73vw",
                                            color: "#B3B9BD",
                                            fontWeight: 400,
                                          }}
                                        >
                                          Clinic Name
                                        </span>
                                      </Row>
                                      <Row>
                                        <span
                                          style={{
                                            fontSize: "0.834vw",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {item.clinic_name}
                                        </span>
                                      </Row>
                                    </Col> */}
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Doctor
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {item.doc_name}
                                              </span>
                                            </Row>
                                          </Col>
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Cost of Treatment
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                Rs.{item.rate ? item.rate : 0}
                                              </span>
                                            </Row>
                                          </Col>
                                          <Col
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.26vw",
                                            }}
                                            span={6}
                                          >
                                            <Row>
                                              <span
                                                style={{
                                                  fontSize: "0.73vw",
                                                  color: "#B3B9BD",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                Paynment Status
                                              </span>
                                            </Row>
                                            <Row>
                                              <span
                                                className={
                                                  item.payment_status === true
                                                    ? "paid"
                                                    : "pending"
                                                }
                                                style={{
                                                  fontSize: "0.834vw",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {item.payment_status === true
                                                  ? "Paid"
                                                  : "Pending"}
                                              </span>
                                            </Row>
                                          </Col>
                                          <Col
                                            span={6}
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            {item.payment_status === true ? (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  gap: "0.5vw",
                                                }}
                                              >
                                                <Button
                                                  className="invoice-button"
                                                  onClick={() => {
                                                    setInvoiceClicked(true);
                                                    setInvoiceNumber(
                                                      item.invoice_number
                                                    );
                                                  }}
                                                >
                                                  Invoice
                                                </Button>
                                                <span
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    color: "#3498DB",
                                                    fontSize: "0.73vw",
                                                    fontStyle: "normal",
                                                    fontWeight: 500,
                                                    lineHeight:
                                                      "148.59%" /* 20.803px */,
                                                  }}
                                                >
                                                  Give Feedback
                                                </span>
                                              </div>
                                            ) : (
                                              <Button
                                                disabled
                                                className="pay-now-button"
                                                onClick={() => {
                                                  displayRazorpay(item);
                                                  localStorage.setItem(
                                                    "treatment_id",
                                                    item.id
                                                  );
                                                }}
                                              >
                                                Invoice
                                              </Button>
                                            )}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  })}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </Row>
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <Modal
          centered
          width={"26.46vw"}
          height={"33.85vw"}
          open={openCalendarModal}
          closable={false}
          footer={false}
          className="open-calendar-modal"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.0417vw",
            }}
          >
            <Row>
              <span
                style={{
                  fontSize: "1.0417vw",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "140.04%",
                }}
              >
                Choose Date from the Calendar
              </span>
            </Row>
            <Row>
              {/* <Calendar
              value={displayDate}
              className="treatment-calendar"
              onSelect={onSelect}
              dateCellRender={dateCellRender}
              mode="month"
              onPanelChange={onPanelChange}
              defaultValue={moment()}

            /> */}
              <Calendar
                className="treatment-calendar"
                fullscreen={false}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                  const start = 0;
                  const end = 12;
                  const monthOptions = [];
                  let current = value.clone();
                  const localeData = value.localeData();
                  const months = [];
                  for (let i = 0; i < 12; i++) {
                    current = current.month(i);
                    months.push(localeData.monthsShort(current));
                  }
                  for (let i = start; i < end; i++) {
                    monthOptions.push(
                      <Select.Option key={i} value={i} className="month-item">
                        {months[i]}
                      </Select.Option>
                    );
                  }
                  const year = value.year();
                  const month = value.month();
                  const options = [];
                  for (let i = year - 10; i < year + 10; i += 1) {
                    options.push(
                      <Select.Option key={i} value={i} className="year-item">
                        {i}
                      </Select.Option>
                    );
                  }
                  return (
                    <div
                      style={{
                        padding: "0.417vw",
                      }}
                    >
                      <Row gutter={8}>
                        <Col>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            className="my-year-select"
                            value={year}
                            onChange={(newYear) => {
                              const now = value.clone().year(newYear);
                              onChange(now);
                            }}
                          >
                            {options}
                          </Select>
                        </Col>
                        <Col>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            value={month}
                            onChange={(newMonth) => {
                              const now = value.clone().month(newMonth);
                              onChange(now);
                            }}
                          >
                            {monthOptions}
                          </Select>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
                onSelect={onDateSelect}
                onPanelChange={onPanelChange}
              />
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                className="cal-cancel-button"
                onClick={() => {
                  setOpenCalendarModal(false);
                  handleStatusChange("created_at", "");
                }}
              >
                Cancel
              </Button>
              <Button className="cal-apply-button" onClick={handleApplyButton}>
                Apply
              </Button>
            </Row>
          </div>
        </Modal>
      </Spin>
    </div>
  );
};

export default AdminPatientProfile;
