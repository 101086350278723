import { Button, Col, Form, Input, message, Row } from "antd";
import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Ivorylogo from "../../assets/logo.png";
import resetSuccessLogo from "../../assets/reset-password-success.png";
import otpExpiredLogo from "../../assets/otp-exp.png";
import API_BASE_URL from '../../env';
import {
  routes_patientlogin,
  routes_patientsignup
} from "../../utils/patient_navbar_constants/PatientNavbarConstants";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otpCode, setOtpCode] = useState(['', '', '', '']);
  const inputRefs = useRef([]);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const [otpExpTimer, setOtpExpTimer] = useState(600);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendInProgress, setIsResendInProgress] = useState(false);
  const [isPasswordReset, setPasswordReset] = useState(false);

  const handleLoginButton = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${API_BASE_URL}/api/v1/auth/generateOTP?email=${email}`
      )
      .then((res) => {
        message.success(res.data.data.message);
        setStep(step + 1);
        startResendTimer();
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
        setIsLoading(false);
      });
    setIsLoading(false);
    setTimeout(() => {
      form.resetFields();
    }, 1500);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleKeyClick = (event) => {
    const { key, target, currentTarget: inputFields } = event;
    const inputs = Array.from(inputFields.querySelectorAll('input'));
    const currentIndex = inputs.indexOf(target);

    if (key === 'ArrowRight') {
      if (currentIndex < inputs.length - 1) {
        inputs[currentIndex + 1].focus();
      }
    } else if (key === 'ArrowLeft') {
      if (currentIndex > 0) {
        inputs[currentIndex - 1].focus();
      }
    }
  };

  useEffect(() => {
    // Focus on the first input field when the component mounts
    inputRefs.current[0]?.focus();

    // Add event listener for keydown event
    document.addEventListener('keydown', handleKeyClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyClick);
    };
  }, []);

  const handleOTPChange = (index, value) => {
    const updatedOtpCode = [...otpCode];
    updatedOtpCode[index] = value;
    setOtpCode(updatedOtpCode);

    if (value && index < otpCode.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    if (!value && index > 0 && updatedOtpCode[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleOTPValidateButton = async () => {
    let otp = otpCode.join("")
    await axios
      .post(
        `${API_BASE_URL}/api/v1/auth/validateOTP?email=${email}&enteredOtp=${otp}`
      )
      .then((res) => {
        message.success(res.data.data.message);
        startOtpExpTimer();
        setStep(step + 1);
      })
      .catch((err) => {
        message.error(err.response.data.data.errors);
      });
    setTimeout(() => {
      setOtpCode(['', '', '', '']);
    }, 1500);
  }

  const startResendTimer = () => {
    setIsResendEnabled(false);
    const timer = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setIsResendEnabled(true);
      setResendTimer(30);
    }, 60000);
  };

  const startOtpExpTimer = () => {
    const timer = setInterval(() => {
      setOtpExpTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setStep(4);
      setOtpExpTimer(600);
    }, 600000);
  };

  const minutes = Math.floor(otpExpTimer / 60);
  const remainingSeconds = otpExpTimer % 60;


  const handleResendOTP = async () => {
    if (isResendInProgress) return; // If resend is already in progress, do nothing

    setIsResendInProgress(true); // Set the resend in progress flag

    try {
      const res = await axios.post(
        `${API_BASE_URL}/api/v1/auth/resendOTP?email=${email}`
      );
      message.success(res.data.data.message);
      startResendTimer();
    } catch (err) {
      message.error(err.response.data.data.errors);
    } finally {
      setIsResendInProgress(false); // Reset the resend in progress flag
    }

    setTimeout(() => {
      form.resetFields();
    }, 1500);
  };

  const handlePasswordOnChange = (e) => {
    const value = e.target.value;
    const repetitionRegex = /(.)\1{4,}/;
    if (repetitionRegex.test(value)) {
      setError("Password must not contain repeated characters or sequences.");
    } else {
      setError("");
      setPassword(value);
    }
  };

  const handleKeyPress = (e) => {
    const value = e.key;
    const lastChar = password.charAt(password.length - 1);
    const secondLastChar = password.charAt(password.length - 2);
    const thirdLastChar = password.charAt(password.length - 3);
    const fourthLastChar = password.charAt(password.length - 4);
    if (
      lastChar &&
      secondLastChar &&
      thirdLastChar &&
      value === lastChar &&
      value === secondLastChar &&
      value === thirdLastChar &&
      value === fourthLastChar
    ) {
      e.preventDefault();
      setError("Password must not contain repeated characters or sequences.");
    }
  };

  const validatePassword = (_, value) => {
    if (/(\w)\1{3,}/.test(value)) {
      return Promise.reject(
        new Error("Password should not have more than 3 sequence characters")
      );
    }
    return Promise.resolve();
  };

  const validatePasswords = (_, value) => {
    const { Username, Name } = form.getFieldsValue(["Username", "Name"]);
    if (value.includes(Username) || value.includes(Name)) {
      return Promise.reject(
        new Error("Password must not contain username or parts of the name")
      );
    }
    return Promise.resolve();
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleResetPassword = async (values) => {
    const payload = {
      password: password,
    };
    // let config = {
    //   headers: {
    //     Authorization: `Bearer ${Token}`,
    //   },
    // };
    await axios
      .post(
        `${API_BASE_URL}/api/v1/auth/change-password?email=${email}`,
        payload,
        // config
      )
      .then((res) => {
        setPasswordReset(true);
        form.resetFields();
        message.success(res.data.data.message);
        setStep(step + 1);
        clearInterval();
        setOtpExpTimer(600);
      })
      .catch((err) => {
        message.error(err.response.data);
      });
  };

  const handleNavigateToSignup = () => {
    navigate(routes_patientsignup);
  };

  return (
    <Row className="login-page-main-row">
      <Helmet>
        <title>Patient Login</title>
      </Helmet>
      <Col
        className="ivory-logo-container"
        span={14}
        sm={14}
        xs={24}
      >
        <Row className="logo-description">
          <img
            data-testid="Ivory-logo"
            className="Ivory-logo-img-tag"
            src={Ivorylogo}
            alt=""
          />
          <span className="description">Your Healthiest Smile Begins Here!</span>
        </Row>
      </Col>
      <Col className="forgot-password-form-container" span={10} sm={10} xs={24}>
        <Row className="forgot-password-form">
          <Form
            name="basic"
            form={form}
            className="forgot-password-details-filling-form"
            initialValues={{
              remember: true,
            }}
            autoComplete="on"
          >
            {step != 4 && step != 5 && (
              <Row style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <span className="forgot-password-text">Forgot Password?</span>
                {step == 3 && (
                  <span className="otp-exp">
                    {minutes < 10 ? '0' : ''}{minutes}:{remainingSeconds < 10 ? '0' : ''}{remainingSeconds} left
                  </span>
                )}
              </Row>
            )}
            {step == 1 && (<Row className="forgot-password-filling-step">
              <Row className="stepper">
                <span className="step-number">Step 1 of 3</span>
                <span className="enter-email">Enter Email</span>
                <span className="step-description">Please enter the email address associated with your account below.</span>
              </Row>
              <Row className="email-field">
                <span className="forgot-password-input-title">
                  Email
                  <span style={{ color: "red" }}> *</span>
                </span>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please enter your email" },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                    {
                      max: 320,
                      message:
                        "Email address must be no more than 320 characters long",
                    },
                  ]}
                >
                  <Input
                    data-testid="jest-input-email"
                    label="Email"
                    className="user-email-input"
                    maxLength={321}
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Form.Item>
              </Row>
              <Row className="buttons-container">
                <span className="go-back" onClick={() => {
                  navigate("/");
                }}>Back to Log in</span>
                <Button
                  className="send-button"
                  type="primary"
                  onClick={handleLoginButton}
                  disabled={isLoading}
                >
                  {isLoading ? 'Sending...' : 'Send'}
                </Button>
              </Row>
            </Row>
            )}
            {step == 2 && (<Row className="forgot-password-otp-filling-step">
              <Row className="stepper">
                <span className="step-number">Step 2 of 3</span>
                <span className="verify-otp">OTP Verification</span>
                <span className="step-description">We’ve sent OTP to your email at <b>{email}</b>. Please enter 4 digit code you receive.</span>
              </Row>
              <Row className="otp-field">
                <span className="forgot-password-input-title">
                  OTP
                  <span style={{ color: "red" }}> *</span>
                </span>
                <div className="otp-input-group">
                  {otpCode.map((digit, index) => (
                    <input
                      className="otp-input"
                      key={index}
                      type="text"
                      maxLength={1}
                      value={digit}
                      onChange={(e) => handleOTPChange(index, e.target.value)}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                    />
                  ))}
                </div>
              </Row>
              <Row className="code-not-received">
                <span>Didn't receive code?</span>
                {isResendEnabled && !isResendInProgress && (
                  <span className="resend-otp" onClick={handleResendOTP}>
                    Resend OTP
                  </span>
                )}
                {(!isResendEnabled || isResendInProgress) && (
                  <span className="resend-code">
                    {isResendInProgress ? "Resending..." : ` Resend in ${resendTimer}s`}
                  </span>
                )}
              </Row>
              <Row className="buttons-container">
                <span className="go-back" onClick={() => {
                  navigate("/");
                }}>Back to Log in</span>
                <Button
                  className="send-button"
                  type="primary"
                  onClick={handleOTPValidateButton}
                >
                  Validate OTP
                </Button>
              </Row>
            </Row>)}
            {step == 3 && (<Row className="forgot-password-credentials-filling-step">
              <Row className="stepper">
                <span className="step-number">Step 3 of 3</span>
                <span className="credentials">Reset Password</span>
                <span className="step-description">Create a new password to secure your account.</span>
              </Row>
              <Row className="password-field">
                <span className="forgot-password-input-title">
                  New Password
                  <span style={{ color: "red" }}> *</span>
                </span>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      min: 8,
                      message: "Password must be atleast 8 characters",
                    },
                    { whitespace: true, message: "No white spaces" },
                    {
                      required: true,
                      message: "Please Enter Password",
                    },
                    {
                      pattern:
                        /^[A-Z](?=.*[A-Za-z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@#$!%*?&_]{7,}$/,
                      message:
                        "must contain first letter capital and at least one alphanumeric character and a symbol from @#$%^&*",
                    },
                    {
                      max: 30,
                      message:
                        "Password must not be more than 30 characters long",
                    },
                    { validator: validatePassword },
                    { validator: validatePasswords },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    data-testid="jest-input-password"
                    maxLength={31}
                    className="admin-register-input-password"
                    onChange={(e) => {
                      handlePasswordOnChange(e);
                    }}
                    onKeyPress={(e) => {
                      handleKeyPress(e);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Row>
              <Row className="confirm-password-and-tip">
                <Row className="confirm-password-field">
                  <span className="forgot-password-input-title">
                    Confirm Password
                    <span style={{ color: "red" }}> *</span>
                  </span>
                  <Form.Item
                    name="confirm_password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      { whitespace: true, message: "No white spaces" },
                      { required: true, message: "Re-enter Password" },

                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("password") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      data-testid="jest-input-confirm-password"
                      maxLength={30}
                      className="admin-register-input-password"
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <span className="password-tip">Strong password tip:</span>
                  <span className="password-tip-desc">Use 8+ characters with mix of upper/lowercase letters, numbers, and symbols.</span>
                </Row>
              </Row>
              <Row className="buttons-container">
                <span className="go-back" onClick={() => {
                  navigate("/");
                }}>Back to Log in</span>
                <Button
                  className="send-button"
                  type="primary"
                  onClick={handleResetPassword}
                >
                  Confirm
                </Button>
              </Row>
            </Row>)}
            {step == 4 && (isPasswordReset ? (
              <Row className="reset-success">
                <img src={resetSuccessLogo} alt="" style={{
                  width: "5.417vw",
                  height: "10.8vh"
                }} />
                <Row style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "19.4275vw"
                }}>
                  <span style={{
                    color: "#333",
                    textAlign: "center",
                    fontSize: "1.354165vw",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "148.59%"/* 38.633px */
                  }}>Password Reset Successful!</span>
                  <span style={{
                    color: "#333",
                    fontSize: "0.833333vw",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "148.59%",
                    textAlign: "center"
                  }}>Your password has been successfully updated. You can now log in with your new password.</span>
                </Row>
                <Button className="login-button" onClick={() => {
                  navigate("/");
                }}>
                  Login
                </Button>
              </Row>) : (<Row className="reset-success">
                <img src={otpExpiredLogo} alt="" style={{
                  width: "5.417vw",
                  height: "10.8vh"
                }} />
                <Row style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "19.4275vw"
                }}>
                  <span style={{
                    color: "#EC7063",
                    textAlign: "center",
                    fontSize: "1.354165vw",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "148.59%"/* 38.633px */
                  }}>Password Reset OTP Expired</span>
                  <span style={{
                    color: "#333",
                    fontSize: "0.833333vw",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "148.59%",
                    textAlign: "center"
                  }}>Please request a new OTP to reset your password. Password reset links expire after 10 minutes for security purposes.</span>
                </Row>
                <Button className="login-button" onClick={() => {
                  setStep(2);
                  handleResendOTP();
                }}>
                  Send Again
                </Button>
              </Row>)
            )}
          </Form>
        </Row>
      </Col >
    </Row >

  );
};

export default ForgotPassword;
