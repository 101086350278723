import React from "react";
import "./GlobalErrorPage.css";
import { Button, Col, Row } from "antd";
import error_logo from "../../assets/error_img.png";
import { useNavigate } from "react-router";

const GlobalErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className="global-error-page-main-div">
      <Row
        className="w-100 d-flex jc-center ai-center h-100"
        style={{
          gap: 70,
        }}
      >
        <Col>
          <img
            style={{
              width: 468,
              height: 465,
            }}
            src={error_logo}
            alt=""
          />
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span className="error-text">404</span>
          <div className="d-flex ai-center" style={{ gap: 11 }}>
            <div class="line-1"></div>
            <span
              style={{
                fontWeight: 900,
                fontSize: 31,
                color: "#2E82BC",
              }}
            >
              ERROR PAGE
            </span>
            <div class="line-1"></div>
          </div>
          <span
            style={{
              fontWeight: 500,
              fontSize: 24,
              color: "#525252",
            }}
          >
            The page you requested was not found!
          </span>
          <Button className="backtohome-btn" onClick={() => navigate("/")}>
            Back to HomePage
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default GlobalErrorPage;
