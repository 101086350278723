import { Button, Col, Input, Spin, Row, Select, Space, Form, message, Image, Upload, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import uploadIcon from "../../../../../../assets/upload.png";
import successLogo from "../../../../../../assets/reset-password-success.png";
import { options } from "../../../../../../utils/super_admin_constants/SuperAdminConsts";
import { LoadingOutlined } from "@ant-design/icons";
import API_BASE_URL from "../../../../../../env";
import { useAsyncError } from "react-router";
import CertificatePreview from "./CertificatePreview";
import { Document, Page } from 'react-pdf';


const getBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

const ViewClinicData = (props) => {
    const Token = localStorage.getItem("Token");
    const [form] = Form.useForm();
    const [spinner, setSpinner] = useState(false);
    const [ifsc, setIfsc] = useState('');
    const [openClinicRegModal, setClinicRegModal] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [isHideUpdateButton, setHideUpdateButton] = useState(true);
    const { clinicData, status } = props;
    const [InputsEnable, setInputsEnable] = useState(true);

    const handleAdminSignup = async (values) => {
        setSpinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${Token}`,
            },
        };
        const payload = {
            name: values.name,
            email: values.email,
            username: values.username,
            phone_country_code: values.phone_country_code ? values.phone_country_code : "+91",
            phone_number: values.phone_number,
            address_line_one: values.address_line_one,
            city: values.city,
            province: values.province,
            country: values.country,
            zip_code: values.zip_code,
            coordinate: {
                latitude: 0,
                longitude: 0,
            },
            profile_picture_url: "",
            account_name: values.account_name,
            account_no: values.account_no,
            confirm_account_no: values.confirm_account_no,
            ifsc_code: values.ifsc_code
        };
        axios
            .put(`${API_BASE_URL}/api/v1/clinic/${clinicData.clinic_id}`, payload, config)
            .then((res) => {
                setInputsEnable(true);
                setHideUpdateButton(true);
                message.success(res.data.status_message);
                setSpinner(false);
            })
            .catch((err) => {
                message.error(err.response.data.data.errors);
                setSpinner(false);
            });
    };

    const validatePhoneNumber = (_, value) => {
        const phoneRegex = /^\d{10}$/;
        if (!value || phoneRegex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject("Please enter a valid 10-digit phone number!");
    };

    const validateZipCode = (_, value) => {
        const phoneRegex = /^\d{6}$/;
        if (!value || phoneRegex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject("Please enter 6-digit zip code!");
    };

    const handleUserNameKeyPress = (e) => {
        if (
            (e.key === " " && e.target.selectionStart === 0) ||
            (!/^[a-zA-Z]$/.test(e.key) && e.target.selectionStart === 0)
        ) {
            e.preventDefault();
        }
    };

    const handleUserNameChange = (e) => {
    };

    const handleIFSCChange = (event) => {
        const value = event.target.value.toUpperCase();
        setIfsc(value);
        form.setFieldsValue({ ifsc_code: value });
    };

    useEffect(() => {
        setSpinner(true);
    }, [!clinicData])

    useEffect(() => {
        setSpinner(false);
    }, [clinicData])

    return (
        <div style={{
            height: "73vh"
        }}>
            {contextHolder}
            <Spin
                indicator={
                    <LoadingOutlined
                        style={{ fontSize: "2.6045vw", margin: "5.2084vw 0 0 0", }}
                        spin
                    />
                }
                size="large"
                spinning={spinner && true}
                delay={500}
            >
                <Form onFinish={handleAdminSignup} form={form} className="new-clinic-form" style={{ padding: "1.25vw 0 0" }}>
                    <Row
                        className="form-row-tag"
                    >
                        <Col
                            span={14}
                            className="new-clinic-form-col"
                        >
                            <Row className="new-clinic-form-row">
                                <Row style={{
                                    width: "100%"
                                }}>
                                    <span className="form-header">Clinic Information</span>
                                </Row>
                                <Row className="input-fields-container">
                                    <Row className="input-fields-row">
                                        <Col className="form-field-col">
                                            <span
                                                className="form-label"
                                            >
                                                Clinic Name <span style={{ color: "#FF0000" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter clinic name",
                                                        },
                                                        {
                                                            min: 3,
                                                            message:
                                                                "Clinic Name must be at least 3 characters long",
                                                        },
                                                        {
                                                            max: 100,
                                                            message:
                                                                "Clinic Name must be no more than 100 characters long",
                                                        },
                                                        {
                                                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                                            message:
                                                                "First character should be alphabetical and Clinic Name must contain only alphabeticals",
                                                        },
                                                        { whitespace: true, message: "No white spaces" },
                                                    ]}
                                                    initialValue={clinicData?.name}
                                                >
                                                    <Input
                                                        disabled={InputsEnable}
                                                        className="input-field"
                                                        maxLength={101}
                                                        onKeyPress={(e) => {
                                                            const charCode = e.which || e.keyCode;
                                                            const inputValue = e.target.value;
                                                            if (
                                                                !(
                                                                    (charCode >= 65 && charCode <= 90) ||
                                                                    (charCode >= 97 && charCode <= 122) ||
                                                                    (charCode === 32 && inputValue.trim() !== "")
                                                                )
                                                            ) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                        <Col className="form-field-col">
                                            <span className="form-label">
                                                Contact Person <span style={{ color: "red" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="contact_person"
                                                    rules={[
                                                        { required: true, message: "Please enter Account Name" },
                                                        {
                                                            pattern: /^[a-zA-Z ]+$/,
                                                            message: "Account Name must be letters and spaces only."
                                                        }
                                                    ]}
                                                    initialValue={clinicData?.contact_person}
                                                >
                                                    <Input
                                                        disabled={InputsEnable}
                                                        data-testid="jest-input-username"
                                                        maxLength={101}
                                                        label="contact-person"
                                                        className="input-field"
                                                        onKeyPress={handleUserNameKeyPress}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                        <Col className="form-field-col">
                                            <span className="form-label">
                                                User Name <span style={{ color: "red" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="username"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter your Username!",
                                                        },
                                                        {
                                                            min: 4,
                                                            message: "Please enter minimum 4 characters",
                                                        },
                                                        { whitespace: true, message: "No white spaces" },
                                                        {
                                                            pattern: /^[a-zA-Z0-9._]+$/,
                                                            message:
                                                                "Username can only contain letters, numbers, fullstops, and underscores.",
                                                        },
                                                        {
                                                            max: 100,
                                                            message:
                                                                "Username must be no more than 100 characters long",
                                                        },
                                                    ]}
                                                    initialValue={clinicData?.username}
                                                >
                                                    <Input
                                                        disabled={InputsEnable}
                                                        data-testid="jest-input-username"
                                                        label="Username"
                                                        className="input-field"
                                                        maxLength={101}
                                                        onChange={handleUserNameChange}
                                                        onKeyPress={handleUserNameKeyPress}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="input-fields-row">
                                        <Col className="form-field-col">
                                            <span
                                                className="form-label"
                                            >
                                                Email <span style={{ color: "#FF0000" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="email"
                                                    rules={[
                                                        { required: true, message: "Please enter email" },
                                                        {
                                                            type: "email",
                                                            message: "Please enter a valid email address",
                                                        },
                                                        {
                                                            max: 320,
                                                            message:
                                                                "Email address must be no more than 320 characters long",
                                                        },
                                                    ]}
                                                    initialValue={clinicData?.email}
                                                >
                                                    <Input
                                                        disabled={InputsEnable}
                                                        className="input-field"
                                                        maxLength={321}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                        <Col className="form-field-col">
                                            <span
                                                className="form-label"
                                            >
                                                Phone No. <span style={{ color: "#FF0000" }}>*</span>
                                            </span>
                                            <Space.Compact
                                                style={{
                                                    width: "14.5834vw",
                                                }}
                                            >
                                                <Form.Item name="phone_country_code" className="country-code">
                                                    <Select
                                                        disabled={InputsEnable}
                                                        bordered={false}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            height: "2.0834vw",
                                                            background: "#FFFFFF",
                                                            border: "1px solid #E3E3E3",
                                                            margin: 0,
                                                        }}
                                                        defaultValue="+91"
                                                        options={options}
                                                    />
                                                </Form.Item>
                                                {clinicData && (
                                                    <Form.Item
                                                        style={{ width: "100%" }}
                                                        name="phone_number"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter phone number",
                                                            },
                                                            { validator: validatePhoneNumber },
                                                        ]}
                                                        initialValue={clinicData?.phone_number}
                                                    >
                                                        <Input
                                                            disabled={InputsEnable}
                                                            bordered={false}
                                                            style={{
                                                                height: "2.0834vw",
                                                                background: "#FFFFFF",
                                                                borderTop: "1px solid #E3E3E3",
                                                                borderRight: "1px solid #E3E3E3",
                                                                borderBottom: "1px solid #E3E3E3",
                                                                margin: 0,
                                                            }}
                                                            onKeyPress={(e) => {
                                                                const charCode = e.which || e.keyCode;
                                                                if (!(charCode >= 48 && charCode <= 57)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Space.Compact>
                                        </Col>
                                    </Row>
                                </Row>
                            </Row>
                            <Row className="new-clinic-form-row">
                                <Row style={{
                                    width: "100%"
                                }}>
                                    <span className="form-header">Address Details</span>
                                </Row>
                                <Row className="input-fields-container">
                                    <Row className="input-fields-row">
                                        <Col className="form-field-col">
                                            <span
                                                className="form-label"
                                            >
                                                Street Address <span style={{ color: "#FF0000" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="address_line_one"
                                                    rules={[
                                                        { required: true, message: "Please enter address" },
                                                    ]}
                                                    initialValue={clinicData?.address_line_one}
                                                >
                                                    <Input
                                                        disabled={InputsEnable}
                                                        className="input-field"
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                        <Col className="form-field-col">
                                            <span
                                                className="form-label"
                                            >
                                                City <span style={{ color: "#FF0000" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="city"
                                                    rules={[
                                                        { required: true, message: "Please enter city" },
                                                        {
                                                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                                            message:
                                                                "First character should be alphabetical and City must contain only alphabeticals",
                                                        },
                                                    ]}
                                                    initialValue={clinicData?.city}
                                                >
                                                    <Input
                                                        disabled={InputsEnable}
                                                        className="input-field"
                                                        onKeyPress={(e) => {
                                                            const charCode = e.which || e.keyCode;
                                                            const inputValue = e.target.value;
                                                            if (
                                                                !(
                                                                    (charCode >= 65 && charCode <= 90) ||
                                                                    (charCode >= 97 && charCode <= 122) ||
                                                                    (charCode === 32 && inputValue.trim() !== "")
                                                                )
                                                            ) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                        <Col className="form-field-col">
                                            <span
                                                className="form-label"
                                            >
                                                State <span style={{ color: "#FF0000" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="province"
                                                    rules={[
                                                        { required: true, message: "Please enter state/province" },
                                                        {
                                                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                                                            message:
                                                                "First character should be alphabetical and State/Province must contain only alphabeticals",
                                                        },
                                                    ]}
                                                    initialValue={clinicData?.province}
                                                >
                                                    <Input
                                                        disabled={InputsEnable}
                                                        className="input-field"
                                                        onKeyPress={(e) => {
                                                            const charCode = e.which || e.keyCode;
                                                            const inputValue = e.target.value;
                                                            if (
                                                                !(
                                                                    (charCode >= 65 && charCode <= 90) ||
                                                                    (charCode >= 97 && charCode <= 122) ||
                                                                    (charCode === 32 && inputValue.trim() !== "")
                                                                )
                                                            ) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="input-fields-row">
                                        <Col className="form-field-col">
                                            <span
                                                className="form-label"
                                            >
                                                Country
                                            </span>
                                            {clinicData && (
                                                <Form.Item name="country"
                                                    initialValue={clinicData?.country}
                                                >
                                                    <Input
                                                        disabled={InputsEnable}
                                                        className="input-field"
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                        <Col className="form-field-col">
                                            <span
                                                className="form-label"
                                            >
                                                ZIP Code <span style={{ color: "#FF0000" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="zip_code"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter Zip code",
                                                        },
                                                        { validator: validateZipCode },
                                                    ]}
                                                    initialValue={clinicData?.zip_code}
                                                >
                                                    <Input
                                                        disabled={InputsEnable}
                                                        data-testid="jest-input-zip"
                                                        label="Zip"
                                                        className="input-field"
                                                        maxLength={30}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                    </Row>
                                </Row>
                            </Row>
                            <Row className="new-clinic-form-row">
                                <Row style={{
                                    width: "100%"
                                }}>
                                    <span className="form-header">Bank Details</span>
                                </Row>
                                <Row className="input-fields-container">
                                    <Row className="input-fields-row">
                                        <Col className="form-field-col">
                                            <span className="form-label">
                                                Account Name <span style={{ color: "red" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="account_name"
                                                    rules={[
                                                        { required: true, message: "Please enter Account Name" },
                                                        {
                                                            pattern: /^[a-zA-Z ]+$/,
                                                            message: "Account Name must be letters and spaces only."
                                                        }
                                                    ]}
                                                    initialValue={clinicData?.account_name}
                                                >
                                                    <Input
                                                        disabled={true}
                                                        data-testid="jest-input-account"
                                                        label="Account-Name"
                                                        className="input-field"
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                        <Col className="form-field-col">
                                            <span className="form-label">
                                                Account No. <span style={{ color: "red" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="account_no"
                                                    rules={[
                                                        { required: true, message: "Please enter Account No" },
                                                        {
                                                            min: 8,
                                                            message: "Please enter a valid account number",
                                                        },
                                                        { whitespace: true, message: "No white spaces" },
                                                        {
                                                            pattern: /^[0-9]+$/,
                                                            message:
                                                                "Account number can only contain numbers.",
                                                        },
                                                    ]}
                                                    initialValue={clinicData?.account_no}
                                                >
                                                    <Input
                                                        disabled={true}
                                                        data-testid="jest-input-account"
                                                        label="Account-No"
                                                        className="input-field"
                                                        maxLength={16}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                        <Col className="form-field-col">
                                            <span className="form-label">
                                                Confirm Account No. <span style={{ color: "red" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="confirm_account_no"
                                                    dependencies={["account_no"]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please confirm account no.",
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (
                                                                    !value ||
                                                                    getFieldValue("account_no") === value
                                                                ) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(
                                                                    new Error(
                                                                        "The account numbers does not match!"
                                                                    )
                                                                );
                                                            },
                                                        })
                                                    ]}
                                                    initialValue={clinicData?.confirm_account_no}
                                                >
                                                    <Input
                                                        disabled={true}
                                                        data-testid="jest-input-account"
                                                        label="Confirm-Account_no"
                                                        className="input-field"
                                                        maxLength={16}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="input-fields-row">
                                        <Col className="form-field-col">
                                            <span className="form-label">
                                                IFSC Code <span style={{ color: "red" }}>*</span>
                                            </span>
                                            {clinicData && (
                                                <Form.Item
                                                    name="ifsc_code"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter IFSC code.",
                                                        },
                                                        {
                                                            min: 8,
                                                            message: "Please enter minimum 8 characters",
                                                        },
                                                        { whitespace: true, message: "No white spaces" },
                                                        {
                                                            pattern: /^[a-zA-Z0-9]+$/,
                                                            message:
                                                                "IFSC code can only contain letters, numbers.",
                                                        }
                                                    ]}
                                                    initialValue={clinicData?.ifsc_code}
                                                >
                                                    <Input
                                                        disabled={true}
                                                        data-testid="jest-input-account"
                                                        label="ifsc-code"
                                                        className="input-field"
                                                        maxLength={11}
                                                        value={ifsc}
                                                        onChange={handleIFSCChange}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>
                                    </Row>
                                </Row>
                            </Row>
                            <Row className="d-flex jc-center" style={{ gap: 10, width: "55vw", marginTop: "-1.15vw" }}>
                                {status !== "PENDING_APPROVAL" && (
                                    <Button
                                        className="register-button"
                                        onClick={() => {
                                            setHideUpdateButton(false);
                                            setInputsEnable(false);
                                        }}
                                        hidden={!isHideUpdateButton}
                                    >
                                        Edit
                                    </Button>
                                )}
                                <Button
                                    className="cancel-button"
                                    onClick={() => {
                                        setHideUpdateButton(true);
                                        setInputsEnable(true);
                                    }}
                                    hidden={isHideUpdateButton}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    className="register-button"
                                    hidden={isHideUpdateButton}
                                >
                                    Update
                                </Button>
                            </Row>
                        </Col>
                        <Col span={10} style={{
                            gap: "0.73vw"
                        }}>
                            <div className="certificate-preview">
                                {clinicData?.certificate_url && (
                                    <CertificatePreview
                                        filePath={clinicData?.certificate_url?.replace(/\[|\]/g, '').split(".com/")[1]}
                                    />
                                )}
                                {/* <Button className="upload-button"
                                    hidden={isHideUpdateButton}
                                    onClick={() => setIsReuploadFile(true)}>
                                    Re Upload
                                </Button> */}
                            </div>
                        </Col>
                    </Row>
                </Form>
                <Modal
                    centered
                    width={"32.813vw"}
                    open={openClinicRegModal}
                    footer={false}
                    destroyOnClose={true}
                    closable={false}
                >
                    <div className="clinic-reg-success-modal">
                        <Row style={{
                            paddingBottom: "1.563vw"
                        }}>
                            <img src={successLogo} alt="" style={{
                                width: "5.417vw",
                                height: "10.8vh"
                            }} />
                        </Row>
                        <Row style={{
                            paddingBottom: "1.0417vw"
                        }}>
                            <span style={{
                                fontSize: "1.0417vw",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "140.04%" /* 28.008px */
                            }}>Clinic Registration Completed!</span>
                        </Row>
                        <Row style={{
                            paddingBottom: "1.8vw"
                        }}>
                            <span style={{
                                fontSize: "0.834vw",
                                fontWeight: 400,
                                fontStyle: "normal",
                                textAlign: "center",
                                lineHeight: "157.3%"
                            }}>
                                The clinic registration process has been successfully completed. A link has been sent to the Clinic's email address for password generation.
                            </span>
                        </Row>
                        <Row>
                            <Button className="close-button"
                                onClick={() => { setClinicRegModal(false) }}>
                                Close
                            </Button>
                        </Row>
                    </div>

                </Modal>
            </Spin>
        </div >

    );
};

export default ViewClinicData;
