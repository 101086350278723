import { Button, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import xray from "../../../../../assets/xray.png";
import DashboardXrayThumbnail from "./dahboard_xray_thumbnail/DashboardXrayThumbnail";
import ImageMap from "../../ml_analysis/xray_and_report_details/xray_image_component/image_map/ImageMap";
import DashboardReportThumbnail from "./dahboard_xray_thumbnail/DashboardReportThumbnail";

export const XrayAndReportComponent = (props) => {
  const {
    patientDashboardReportsDetails,
    xrayAndReportData,
    arrayId,
    xrayArrayId,
    setXrayArrayId,
  } = props;

  const [IsOpenFullXrayModal, setIsOpenFullXrayModal] = useState(false);
  const [downloadDocModal, setDownloadDocModal] = useState(false);
  const [xrayImageUrl, setXrayImageUrl] = useState("");

  const openXrayModalAndSendingImageUrl = (imageUrl) => {
    setIsOpenFullXrayModal(true);
    setXrayImageUrl(imageUrl);
  };

  const handleSendingImageUrl = (imageUrl) => {
    setXrayImageUrl(imageUrl);
  };

  const openReportModalAndSendingImageUrl = (file_name) => {
    setDownloadDocModal(true);
    setXrayImageUrl(file_name);
  };

  const handleSendingReportImageUrl = (imageUrl) => {
    setXrayImageUrl(imageUrl);
  };

  return (
    <>
      <Row
        style={{
          width: "100%",
          background: "#FBFBFB",
          borderRadius: 5,
          padding: "16px 13px",
          margin: "0 0 5px 0",
        }}
      >
        <Col
          lg={5}
          xl={4}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="xray-timestamp">
            {xrayAndReportData.uploaded_at &&
              moment(xrayAndReportData.uploaded_at).format(
                "ddd, DD/MM/YYYY HH:mm A"
              )}
          </span>
        </Col>
        <Col
          xs={5}
          lg={6}
          xl={10}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          {xrayAndReportData.uploaded_by === "Self" ? (
            <span className="self-clinic">{xrayAndReportData.uploaded_by}</span>
          ) : (
            <span className="dashboard-clinic-name">
              {xrayAndReportData.uploaded_by}
            </span>
          )}
          {xrayAndReportData.uploaded_by === "Self" ? (
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#7D7D7D",
              }}
            >
              Pid #{xrayAndReportData.uploader_id}
            </span>
          ) : (
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#7D7D7D",
              }}
            >
              Clid #{xrayAndReportData.uploader_id}
            </span>
          )}
        </Col>
        <Col lg={7} xl={6}>
          {xrayAndReportData.xray_reports &&
          xrayAndReportData.xray_reports.length > 0 ? (
            xrayAndReportData.xray_reports.slice(0, 1).map((xrayimages) => {
              return (
                <>
                  <DashboardXrayThumbnail
                    file_name={xrayimages.file_name}
                    setXrayArrayId={setXrayArrayId}
                    arrayId={arrayId}
                    openXrayModalAndSendingImageUrl={
                      openXrayModalAndSendingImageUrl
                    }
                    s3Url={xrayimages.report_image.split(".com/")[1]}
                  />

                  {/* Modal for displaying multiple Xrays */}
                  <Modal
                    open={IsOpenFullXrayModal}
                    centered
                    footer={false}
                    closable={false}
                    width={window.innerWidth > 700 && "51.145833333333336vw"}
                    mask={false}
                  >
                    <div
                      style={{
                        minHeight: "75.07692307692308vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Row className="w-100">
                        <Row className="w-100 d-flex jc-between ai-center">
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: 22,
                              color: "#525252",
                            }}
                          >
                            ML Analysis
                          </span>
                          <CloseOutlined
                            style={{
                              color: "red",
                              fontSize: "20px",
                            }}
                            onClick={() => setIsOpenFullXrayModal(false)}
                          />
                        </Row>
                        <Row
                          style={{
                            width: "100%",
                            margin: "25px 0 0 0",
                            gap: "10px",
                          }}
                        >
                          {patientDashboardReportsDetails &&
                            patientDashboardReportsDetails
                              .filter((item, id) => id === xrayArrayId)
                              .map((item, id) => {
                                return (
                                  <Col lg={7}>
                                    {item.xray_reports.map((item) => {
                                      return (
                                        <ImageMap
                                          s3Url={
                                            item.report_image.split(".com/")[1]
                                          }
                                          file_name={item.file_name}
                                          title={item.title}
                                          file_mime_type={item.file_mime_type}
                                          handleSendingImageUrl={
                                            handleSendingImageUrl
                                          }
                                        />
                                      );
                                    })}
                                  </Col>
                                );
                              })}
                          <Col
                            lg={16}
                            style={{
                              margin: "5px 0 0 0",
                            }}
                          >
                            <img
                              src={xrayImageUrl}
                              style={{
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              alt=""
                            />
                          </Col>
                        </Row>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "85px",
                        }}
                      >
                        <Button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#D35850",
                            border: "1px solid #D35850",
                            borderRadius: "16px",
                          }}
                          onClick={() => setIsOpenFullXrayModal(false)}
                        >
                          Close
                        </Button>
                      </Row>
                    </div>
                  </Modal>
                </>
              );
            })
          ) : (
            <img
              src={xray}
              alt=""
              style={{
                margin: window.innerWidth < 576 && "10px",
                width: "81px",
                height: "56px",
                cursor: "pointer",
                borderRadius: 5,
              }}
            />
          )}
          <span
            style={{
              margin: "0 0 0 10px",
              color: "#2CA3FA",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            <u>{"(" + xrayAndReportData.xray_reports.length + ")"}</u>
          </span>
        </Col>
        <Col
          lg={4}
          xl={3}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ cursor: "pointer" }}>
            <img
              alt=""
              style={{
                width: "14px",
              }}
            />
            &nbsp;
            {xrayAndReportData.other_reports &&
            xrayAndReportData.other_reports.length > 0 ? (
              xrayAndReportData.other_reports
                .slice(0, 1)
                .map((reportImages) => {
                  return (
                    <span>
                      <DashboardReportThumbnail
                        file_name={reportImages.file_name}
                        setXrayArrayId={setXrayArrayId}
                        arrayId={arrayId}
                        openXrayModalAndSendingImageUrl={
                          openXrayModalAndSendingImageUrl
                        }
                        openReportModalAndSendingImageUrl={
                          openReportModalAndSendingImageUrl
                        }
                        s3Url={reportImages.report_image.split(".com/")[1]}
                      />
                      <Modal
                        width={"953px"}
                        open={downloadDocModal}
                        centered
                        footer={false}
                        closable={false}
                        mask={false}
                      >
                        <div
                          className="download-docs-modal d-flex flex-col ai-center"
                          style={{
                            background: "#FFF",
                            height: "747px",
                          }}
                        >
                          <Row className="d-flex ai-center jc-between w-100">
                            <span
                              style={{
                                fontWeight: 700,
                                fontSize: 22,
                                color: "#525252",
                              }}
                            >
                              Dental Reports
                            </span>
                            <CloseOutlined
                              style={{ color: "red", fontSize: "19px" }}
                              onClick={() => setDownloadDocModal(false)}
                            />
                          </Row>
                          <Row
                            style={{
                              width: "100%",
                              height: "60vh",
                              margin: "25px 0 0 0",
                              gap: "18px",
                            }}
                          >
                            {patientDashboardReportsDetails &&
                              patientDashboardReportsDetails
                                .filter((item, id) => id === xrayArrayId)
                                .map((item, id) => {
                                  return (
                                    <Col span={7}>
                                      {item.other_reports.map((item) => {
                                        return (
                                          <ImageMap
                                            s3Url={
                                              item.report_image.split(
                                                ".com/"
                                              )[1]
                                            }
                                            file_name={item.file_name}
                                            title={item.title}
                                            file_mime_type={item.file_mime_type}
                                            handleSendingImageUrl={
                                              handleSendingReportImageUrl
                                            }
                                          />
                                        );
                                      })}
                                    </Col>
                                  );
                                })}
                            <Col
                              span={16}
                              style={{
                                margin: "5px 0 0 0",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "100%",
                                  width: "100%",
                                }}
                              >
                                <img
                                  src={xrayImageUrl}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    borderRadius: "5px",
                                  }}
                                  alt=""
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "107px",
                            }}
                          >
                            <Button
                              style={{
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "#D35850",
                                border: "1px solid #D35850",
                                borderRadius: "16px",
                              }}
                              onClick={() => setDownloadDocModal(false)}
                            >
                              Close
                            </Button>
                          </Row>
                        </div>
                      </Modal>
                    </span>
                  );
                })
            ) : (
              <span>
                <u style={{ color: "#2CA3FA" }}>Attachments</u>
              </span>
            )}
            <span>
              <u style={{ color: "#2CA3FA" }}>
                {"(" + xrayAndReportData.other_reports.length + ")"}
              </u>
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};
