import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  options,
  routes_super_admin_sub_users,
} from "../../../../../utils/super_admin_constants/SuperAdminConsts";
import { Option } from "antd/lib/mentions";
import LocationIcon from "../../../../../assets/location-icon.png";
import bloodgroupicon from "../../../../../assets/Blood-drop.png";
import AssignRoleIcon from "../../../../../assets/assign-role-icon.png";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../../../../env";

const SubUserProfile = () => {
  const Token = localStorage.getItem("Token");
  const [form] = Form.useForm();
  const { user_id } = useParams();
  const [inputsEnable, setInputsEnable] = useState(true);
  const [subUserDetails, setSubUserDetails] = useState();
  const [superAdminRoles, setSuperAdminRoles] = useState();

  const handleGetSubUserDetails = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/user/${user_id}`, config)
      .then((res) => {
        setSubUserDetails(res.data.data);
      });
  };

  const handleEditSubUser = async (values) => {
    const payload = {
      name: values.name,
      email: values.email,
      phone_country_code: values.phone_country_code
        ? values.phone_country_code
        : "+91",
      phone_number: values.phone_number,
      address_line_one: values.user_address,
      address_line_two: values.user_apartment,
      city: values.user_city,
      province: values.user_state,
      country: values.user_country,
      zip_code: values.user_zipcode,
      insurance_id: values.user_insurance_id,
      citizen_id: values.user_citizen_id,
      blood_group: values.user_blood_group,
      gender: values.gender,
      role_type: values.assign_role,
      permissions: values.assign_permissions,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .put(
        `${API_BASE_URL}/api/v1/user/update-sub-user/${user_id}`,
        payload,
        config
      )
      .then((res) => {
        message.success("User Updated Sucessfully");
        handleGetSubUserDetails();
        setInputsEnable(true);
      });
  };

  const handleGetAllRoles = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    await axios
      .get(`${API_BASE_URL}/api/v1/static/roles`, config)
      .then((res) => {
        setSuperAdminRoles(res.data.data);
      });
  };

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };

  useEffect(() => {
    handleGetSubUserDetails();
  }, []);
  return (
    <div className="super-admin-add-sub-user-main-div">
      <Helmet>
        <title>Sub User Profile</title>
      </Helmet>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "13px 23px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: 22,
              color: "#525252",
            }}
          >
            Sub User Profile
          </span>
          <Breadcrumb>
            <Breadcrumb.Item
              style={{
                background: "#2381C6",
                padding: "1px 10px",
                borderRadius: "3px",
              }}
            >
              <a href={routes_super_admin_sub_users} style={{ color: "#FFF" }}>
                Sub User's
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{
                background: "#2381C6",
                padding: "1px 10px",
                borderRadius: "3px",
                color: "#FFF",
              }}
            >
              Edit Sub User
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Row>
      <Row className="w-100" style={{ padding: "0 0 0 23px" }}>
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          onFinish={handleEditSubUser}
          autoComplete="off"
          className="w-100"
        >
          <Row className="d-flex">
            <Col xs={24} sm={24} md={12} xl={6}>
              <div className="add-user-details-col-main-div">
                <div
                  style={{
                    padding: "3.3333333333333335vw",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2.302vh",
                    }}
                  >
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Name<span style={{ color: "red" }}>*</span>
                      </span>
                      {subUserDetails && (
                        <Form.Item
                          name="name"
                          initialValue={subUserDetails.name}
                          rules={[
                            {
                              required: true,
                              message: "Please enter name",
                            },
                            {
                              min: 3,
                              message:
                                "Name must be at least 3 characters long",
                            },
                            {
                              max: 100,
                              message:
                                "Name must be no more than 100 characters long",
                            },
                            {
                              pattern: /^[A-Za-z][A-Za-z\s]*$/,
                              message:
                                "First character should be alphabetical and Name must contain only alphabeticals",
                            },
                            { whitespace: true, message: "No white spaces" },
                          ]}
                        >
                          <Input
                            name="name"
                            label="name"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#525252",
                              height: "40px",
                              borderRadius: "3px",
                              border: "1px solid #E3E3E3",
                            }}
                            bordered={false}
                            disabled={inputsEnable}
                            maxLength={101}
                            onKeyPress={(e) => {
                              const charCode = e.which || e.keyCode;
                              const inputValue = e.target.value;
                              if (
                                !(
                                  (charCode >= 65 && charCode <= 90) ||
                                  (charCode >= 97 && charCode <= 122) ||
                                  (charCode === 32 && inputValue.trim() !== "")
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      )}
                    </Row>
                    <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        User Identification
                      </span>
                      {subUserDetails && (
                        <Form.Item
                          name="userID"
                          initialValue={"UID#" + subUserDetails.user_id}
                        >
                          <Input
                            name="userID"
                            label="userID"
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#525252",
                              height: "40px",
                              borderRadius: "3px",
                              border: "1px solid #E3E3E3",
                              background: "#FFF8E7",
                            }}
                            disabled={true}
                            bordered={false}
                          />
                        </Form.Item>
                      )}
                    </Row>
                    {/* <Row style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#A5A4A4",
                        }}
                      >
                        Gender<span style={{ color: "red" }}>*</span>
                      </span>
                      {subUserDetails && (
                        <Form.Item
                          name="gender"
                          initialValue={subUserDetails.gender}
                          rules={[{ required: true, message: "Select Gender" }]}
                        >
                          <Select
                            name="gender"
                            bordered={false}
                            disabled={inputsEnable}
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#525252",
                              height: "40px",
                              borderRadius: "3px",
                              border: "1px solid #E3E3E3",
                            }}
                          >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Others">Others</Option>
                          </Select>
                        </Form.Item>
                      )}
                    </Row> */}
                  </Row>
                </div>
                <div
                  style={{
                    padding:
                      "3.7151702786377707vh 3.3333333333333335vw 4vh 3.3854166666666665vw",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#A5A4A4",
                    }}
                  >
                    Email<span style={{ color: "red" }}>*</span>
                  </span>
                  {subUserDetails && (
                    <Form.Item
                      name="email"
                      initialValue={subUserDetails.email}
                      rules={[
                        { required: true, message: "Please Enter email" },
                        {
                          type: "email",
                          message: "Please Enter valid email address",
                        },
                        {
                          max: 320,
                          message:
                            "Email address must be no more than 320 characters long",
                        },
                      ]}
                    >
                      <Input
                        name="email"
                        label="email"
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#7D7D7D",
                          height: "40px",
                          borderRadius: "3px",
                          border: "1px solid #E3E3E3",
                        }}
                        bordered={false}
                        disabled={inputsEnable}
                        maxLength={321}
                      />
                    </Form.Item>
                  )}
                </div>
                <div
                  style={{
                    padding:
                      "3.7151702786377707vh 3.3333333333333335vw 4.615384615384615vh 3.3854166666666665vw",
                  }}
                >
                  <Row>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#A5A4A4",
                      }}
                    >
                      Phone<span style={{ color: "red" }}>*</span>
                    </span>
                  </Row>
                  {subUserDetails && (
                    <Space.Compact className="w-100">
                      <Form.Item
                        name="phone_country_code"
                        initialValue={subUserDetails.phone_country_code}
                      >
                        <Select
                          bordered={false}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: 40,
                            background: "#FFFFFF",
                            border: "1px solid #E3E3E3",
                            margin: "3px 0 0 0",
                          }}
                          defaultValue="+91"
                          options={options}
                          disabled={inputsEnable}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ width: "100%" }}
                        name="phone_number"
                        initialValue={subUserDetails.phone_number}
                        rules={[
                          {
                            required: true,
                            message: "Please enter phone number",
                          },
                          { validator: validatePhoneNumber },
                        ]}
                      >
                        <Input
                          bordered={false}
                          disabled={inputsEnable}
                          style={{
                            height: 40,
                            background: "#FFFFFF",
                            borderTop: "1px solid #E3E3E3",
                            borderRight: "1px solid #E3E3E3",
                            borderBottom: "1px solid #E3E3E3",
                            margin: "3px 0 0 0",
                          }}
                          onKeyPress={(e) => {
                            const charCode = e.which || e.keyCode;
                            if (!(charCode >= 48 && charCode <= 57)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Space.Compact>
                  )}
                </div>
              </div>
            </Col>
            {/* Assign Role */}
            <Col xs={24} sm={24} md={12} xl={6}>
              <div>
                {/* Assign Role */}
                <div
                  className="profile-pic-col-main-div"
                  style={{
                    margin: "0 0 2.5641025641025643vh 0",
                    padding: "1.74vh 24px",
                  }}
                >
                  <Row className="d-flex ai-center jc-between">
                    <Col>
                      <img
                        src={AssignRoleIcon}
                        alt=""
                        style={{ width: "0.9895833333333334vw" }}
                      />
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: 18,
                          color: "#525252",
                          margin: "0 0 0 13px",
                        }}
                      >
                        Assign Role
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      margin: "5vh 0 6.2vh 0",
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Row className="w-100">
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          color: "#A5A4A4",
                        }}
                      >
                        Assign Role
                      </span>
                    </Row>
                    {subUserDetails && (
                      <Row className="w-100">
                        <Form.Item
                          className="w-100"
                          name="assign_role"
                          initialValue={subUserDetails.role_type}
                          rules={[
                            {
                              required: true,
                              message: "Please choose Role",
                            },
                          ]}
                        >
                          <Select
                            name="assign_role"
                            label="assign_role"
                            bordered={false}
                            disabled={inputsEnable}
                            onClick={handleGetAllRoles}
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid #E3E3E3",
                              borderRadius: 3,
                              height: "40px",
                              color: "#7D7D7D",
                            }}
                          >
                            {superAdminRoles &&
                              superAdminRoles.map((item) => {
                                return (
                                  <Select.Option value={item.name}>
                                    {item.name}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Row>
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "19px",
              margin: "40px 0 50px 0",
            }}
          >
            {inputsEnable ? (
              <Button
                style={{
                  height: "30px",
                  border: "1px solid #009DF7",
                  borderRadius: "30px",
                  color: "#2CA3FA",
                  background: "#FFFFFF",
                }}
                onClick={() => {
                  setInputsEnable(false);
                }}
              >
                Edit
              </Button>
            ) : (
              <Button
                style={{
                  height: "30px",
                  border: "1px solid red",
                  borderRadius: "30px",
                  color: "red",
                  background: "#red",
                }}
                onClick={() => {
                  setInputsEnable(true);
                }}
              >
                Cancel
              </Button>
            )}
            {inputsEnable === false && (
              <Form.Item>
                <Button
                  style={{
                    height: "30px",
                    width: "64px",
                    border: "1px solid #009DF7",
                    borderRadius: "30px",
                    color: "#2CA3FA",
                    background: "#E0F2FF",
                  }}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            )}
          </Row>
        </Form>
      </Row>
      s
    </div>
  );
};

export default SubUserProfile;
