import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/home/Home";
import Articles from "./components/home/modules/articles/Articles";
import Clinics from "./components/home/modules/clinics/Clinics";
import MlAnalysis from "./components/home/modules/ml_analysis/MlAnalysis";
import MyAccount from "./components/home/modules/my_account/MyAccount";
import PatientDashboard from "./components/home/modules/patient_dashboard/PatientDashboard";
import Surveys from "./components/home/modules/surveys/Surveys";
import PatientLogin from "./components/login/Login";
import {
  routes_forgot_password,
  routes_home,
  routes_patientlogin,
  routes_patientsignup,
  routes_signup_thanks_message,
} from "./utils/patient_navbar_constants/PatientNavbarConstants";
import { AuthContextProvider } from "./context_api/ContextApi";
import ProtectedRoute from "./protected_route/ProtectedRoute";
import PatientNotifications from "./components/home/modules/patient_notifications/PatientNotifications";
import Help from "./components/home/modules/help/Help";
import {
  routes_clinic_user_add_patient,
  routes_clinic_user_appointments,
  routes_clinic_user_dashboard,
  routes_clinic_user_home,
  routes_clinic_user_message,
  routes_clinic_user_calendar,
  routes_clinic_user_my_account,
  routes_clinic_user_patients,
  routes_clinic_user_personal_profile,
  routes_clinic_user_view_patient,
  routes_clinic_user_view_patient_by_id,
} from "./utils/clinic_user_constants/ClinicUserConstants";
import PatientSignup from "./components/home/patient_signup/PatientSignup";
import ClinicUserDashboard from "./components/clinic_user/modules/clinic_user_dashboard/ClinicUserDashboard";
import {
  routes_adminsignup,
  routes_ading_new_staff,
  routes_clinic_admin_add_admin,
  routes_clinic_admin_add_patient,
  routes_clinic_admin_administrator_profile,
  routes_clinic_admin_dashboard,
  routes_clinic_admin_home,
  routes_clinic_admin_patients,
  routes_clinic_admin_staff,
  routes_clinic_appointments,
  routes_clinic_admin_view_patient,
  routes_clinic_my_account,
  routes_clinic_admin_personal_profile,
  routes_clinic_master_admin_add_clinic_branch,
  routes_clinic_master_admin_edit_clinic_branch,
} from "./utils/clinic_admin_constants/ClinicAdminConstants";
import ClinicUser from "./components/clinic_user/ClinicUser";
import ClinicAdmin from "./components/clinic_admin/ClinicAdmin";
import ClinicAdminDashboard from "./components/clinic_admin/modules/clinic_admin_dashboard/ClinicAdminDashboard";
import AdminPatients from "./components/clinic_admin/modules/admin_patients/AdminPatients";
import AdminStaff from "./components/clinic_admin/modules/admin_staff/AdminStaff";
import AddingNewStaff from "./components/clinic_admin/modules/admin_staff/adding_new_staff/AddingNewStaff";
import ViewPatientById from "./components/clinic_user/modules/clinic_user_patients/view_patient_by_id/ViewPatientById";
import AddAdminPatient from "./components/clinic_admin/modules/admin_patients/add_admin_patient/AddAdminPatient";
import AddClinicUserPatient from "./components/clinic_user/modules/clinic_user_patients/add_clinic_user_patient/AddClinicUserPatient";
import ForgotPassword from "./components/forgot_password/ForgotPassword";
import AdministratorProfile from "./components/clinic_admin/modules/admin_staff/administrator_profile/AdministratorProfile";
import AddAdmin from "./components/clinic_admin/modules/admin_staff/add_new_admin/AddAdmin";
import AdminSignup from "./components/clinic_admin/admin_signup/AdminSignup";
import AdminPatientProfile from "./components/clinic_admin/modules/admin_patients/admin_patient_profile/AdminPatientProfile";
import AdminAppointment from "./components/clinic_admin/modules/admin_appointments/AdminAppointmentModule";
import GlobalErrorPage from "./components/global_error_page/GlobalErrorPage";
import {
  routes_super_admin_add_patient,
  routes_super_admin_add_sub_user,
  routes_super_admin_articles,
  routes_super_admin_clinic_profile,
  routes_super_admin_create_surveys,
  routes_super_admin_dashboard,
  routes_super_admin_home,
  routes_super_admin_patient_profile,
  routes_super_admin_patients,
  routes_super_admin_sub_user_profile,
  routes_super_admin_sub_users,
  routes_super_admin_surveys,
} from "./utils/super_admin_constants/SuperAdminConsts";
import SuperAdmin from "./components/super_admin/SuperAdmin";
import SuperAdminClinics from "./components/super_admin/modules/super_admin_clinics/SuperAdminClinics";
import SuperAdminArticles from "./components/super_admin/modules/super_admin_articles/SuperAdminArticles";
import SuperAdminPatients from "./components/super_admin/modules/super_admin_patients/SuperAdminPatients";
import SuperAdminClinicDetails from "./components/super_admin/modules/super_admin_clinics/clinic_list/clinic_details/SuperAdminClinicDetails";
import SuperAdminPatientProfile from "./components/super_admin/modules/super_admin_patients/patient_profile/PatientProfile";
import Cookies from "js-cookie";
import SignupThanksMsg from "./components/signup_thanks_page/SignupThanksMsg";
import AdminMyAccount from "./components/clinic_admin/modules/admin_staff/admin_my_account/AdminMyAccount";
import RescheduleAppointment from "./components/home/modules/appointment_page/reschedule_appointment/RescheduleAppointment";
import CreateSurvey from "./components/super_admin/modules/super_admin_surveys/create_survey/CreateSurvey";
import EditSurveys from "./components/super_admin/modules/super_admin_surveys/edit_surveys/EditSurveys";
import SuperAdminSubUsers from "./components/super_admin/modules/super_admin_sub_users/SuperAdminSubUsers";
import AddSubUser from "./components/super_admin/modules/super_admin_sub_users/add_sub_user/AddSubUser";
import SubUserProfile from "./components/super_admin/modules/super_admin_sub_users/sub_user_profile/SubUserProfile";
import AddClinicBranch from "./components/clinic_admin/modules/clinic_add_branch/AddClinicBranch";
import ClinicMyAccount from "./components/clinic_admin/clinic_admin_navbar/clinic_my_account/ClinicMyAccount";
import PatientAccountSettings from "./components/home/modules/patient_profile/PatientAccountSettings";
import ClinicUserWorkinghours from "./components/clinic_user/modules/clinic_user_workinghours/ClinicUserWorkinghours";
import ClinicUserCalendar from "./components/clinic_user/modules/clinic_user_calendar/ClinicUserCalendar";

function App() {
  const rememberMe = Cookies.get("rememberMe");
  const userRole = localStorage.getItem("role");

  // Define your roles and corresponding routes
  const roles = {
    STANDARD: "/patient",
    CLINIC_USER: "/clinic-user",
    CLINIC_ADMIN: "/clinic-admin",
    CLINIC_MASTER_ADMIN: "/clinic-admin",
    SUPER_ADMIN: "/super-admin",
  };

  // Get the component based on the user's role
  const Component = roles[userRole];
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              rememberMe ? (
                <Navigate replace to={`${Component}/dashboard`} />
              ) : (
                <PatientLogin />
              )
            }
          />
          <Route
            exact
            path={routes_patientsignup}
            element={<PatientSignup />}
          />
          <Route exact path={routes_adminsignup} element={<AdminSignup />} />
          <Route
            exact
            path={routes_signup_thanks_message}
            element={<SignupThanksMsg />}
          />
          <Route
            exact
            path={routes_forgot_password}
            element={<ForgotPassword />}
          />
          <Route exact path={routes_patientlogin} element={<PatientLogin />} />

          {/* patient routes */}
          <Route
            exact
            path={routes_home}
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          >
            <Route
              exact
              path="dashboard"
              element={
                <ProtectedRoute>
                  <PatientDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="my-account"
              element={
                <ProtectedRoute>
                  <MyAccount />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="ml-analysis"
              element={
                <ProtectedRoute>
                  <MlAnalysis />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="view-report/:index"
              element={
                <ProtectedRoute>
                  <MlAnalysis />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="clinics"
              element={
                <ProtectedRoute>
                  <Clinics />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="surveys"
              element={
                <ProtectedRoute>
                  <Surveys />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="appointments"
              element={
                <ProtectedRoute>
                  <AdminAppointment />
                  {/* <AppointmentPage /> */}
                </ProtectedRoute>
              }
            />
            {/* <Route
              exact
              path="book-appointment"
              element={
                <ProtectedRoute>
                  <CreateAppointment />
                </ProtectedRoute>
              }
            /> */}
            <Route
              exact
              path="reschedule-appointment/:appointment_id"
              element={
                <ProtectedRoute>
                  <RescheduleAppointment />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="articles"
              element={
                <ProtectedRoute>
                  <Articles />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="account-settings"
              element={
                <ProtectedRoute>
                  <PatientAccountSettings />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="notifications"
              element={
                <ProtectedRoute>
                  <PatientNotifications />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="help"
              element={
                <ProtectedRoute>
                  <Help />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* clinic user routes */}
          <Route
            exact
            path={routes_clinic_user_home}
            element={
              <ProtectedRoute>
                <ClinicUser />
              </ProtectedRoute>
            }
          >
            <Route
              path={routes_clinic_user_dashboard}
              element={<ClinicUserDashboard />}
            />
            <Route
              path={routes_clinic_user_patients}
              element={<AdminPatients />}
            />
            <Route
              path={routes_clinic_user_message}
              element={<ClinicUserWorkinghours />}
            />
            <Route
              path={routes_clinic_user_calendar}
              element={<ClinicUserCalendar />}
            />
            <Route
              path={routes_clinic_user_appointments}
              element={<AdminAppointment />}
            />
            <Route
              path={routes_clinic_user_add_patient}
              element={<AddClinicUserPatient />}
            />
            <Route
              path={routes_clinic_user_view_patient}
              element={<AdminPatientProfile />}
            />
            <Route
              path={routes_clinic_user_my_account}
              element={<ClinicMyAccount />}
            />
            <Route
              path={routes_clinic_user_view_patient_by_id}
              element={<ViewPatientById />}
            />
            <Route
              path={routes_clinic_user_personal_profile}
              element={<AdminMyAccount />}
            />
            <Route
              path={"/clinic-user/add-report/:patient_id/:appointment_id"}
              element={<MlAnalysis />}
            />
            <Route
              path={"/clinic-user/reports/:patient_id"}
              element={<MlAnalysis />}
            />
            <Route
              path={"/clinic-user/view-report/:patient_id/:index"}
              element={<MlAnalysis />}
            />
            <Route
              path={"/clinic-user/view-report/:patient_id/allreports/:index"}
              element={<MlAnalysis />}
            />
          </Route>

          {/* clinic admin routes */}
          <Route
            exact
            path={routes_clinic_admin_home}
            element={
              <ProtectedRoute>
                <ClinicAdmin />
              </ProtectedRoute>
            }
          >
            <Route
              path={"/clinic-admin/view-report/:patient_id/allreports/:index"}
              element={<MlAnalysis />}
            />
            <Route
              path={routes_clinic_admin_dashboard}
              element={<ClinicAdminDashboard />}
            />
            <Route
              path={routes_clinic_admin_patients}
              element={<AdminPatients />}
            />
            <Route path={routes_clinic_admin_staff} element={<AdminStaff />} />
            <Route
              path={routes_clinic_appointments}
              element={<AdminAppointment />}
            />
            <Route path={routes_ading_new_staff} element={<AddingNewStaff />} />
            <Route
              path={routes_clinic_admin_add_patient}
              element={<AddAdminPatient />}
            />
            <Route
              path={routes_clinic_admin_view_patient}
              element={<AdminPatientProfile />}
            />
            <Route
              path={routes_clinic_admin_administrator_profile}
              element={<AdministratorProfile />}
            />
            <Route
              path={routes_clinic_my_account}
              // element={<MyAccount />}
              element={<ClinicMyAccount />}
            />
            <Route
              path={routes_clinic_admin_add_admin}
              element={<AddAdmin />}
            />
            <Route
              path={routes_clinic_admin_personal_profile}
              element={<AdminMyAccount />}
            />
            <Route
              path={"/clinic-admin/reports/:patient_id"}
              element={<MlAnalysis />}
            />
            <Route
              path={"/clinic-admin/view-report/:patient_id/:index"}
              element={<MlAnalysis />}
            />
            <Route
              path={"/clinic-admin/add-report/:patient_id/:appointment_id"}
              element={<MlAnalysis />}
            />
            <Route
              path={routes_clinic_master_admin_add_clinic_branch}
              element={<AddClinicBranch />}
            />
            <Route
              path={routes_clinic_master_admin_edit_clinic_branch}
              element={<AddClinicBranch />}
            />
          </Route>

          {/* Super admin routes */}
          <Route
            exact
            path={routes_super_admin_home}
            element={
              <ProtectedRoute>
                <SuperAdmin />
              </ProtectedRoute>
            }
          >
            <Route
              path={routes_super_admin_dashboard}
              element={<SuperAdminClinics />}
            />
            <Route
              path={routes_super_admin_patients}
              element={<SuperAdminPatients />}
            />
            <Route path={routes_super_admin_surveys} element={<Surveys />} />
            <Route
              path={`/super-admin/edit-surveys/:survey_id`}
              element={<EditSurveys />}
            />
            <Route
              path={routes_super_admin_articles}
              element={<SuperAdminArticles />}
            />
            <Route
              path={routes_super_admin_sub_users}
              element={<SuperAdminSubUsers />}
            />
            <Route
              path="/super-admin/clinic/:clinic_id/staff-profile/:id"
              element={<AdministratorProfile />}
            />
            <Route
              path="/super-admin/clinic/:clinic_id/patient-profile/:patient_id"
              element={<AdminPatientProfile />}
            />
            <Route
              path="/super-admin/clinic/:clinic_id/add-staff"
              element={<AddingNewStaff />}
            />
            <Route
              path="/super-admin/clinic/:clinic_id/add-patient"
              element={<AddAdminPatient />}
            />
            <Route
              path={routes_super_admin_add_patient}
              element={<AddAdminPatient />}
            />
            <Route
              path={routes_super_admin_clinic_profile}
              element={<SuperAdminClinicDetails />}
            />
            <Route
              path={routes_super_admin_patient_profile}
              element={<SuperAdminPatientProfile />}
            />
            <Route
              path={routes_super_admin_add_sub_user}
              element={<AddSubUser />}
            />
            <Route
              path={routes_super_admin_sub_user_profile}
              element={<SubUserProfile />}
            />
            <Route
              path={routes_super_admin_create_surveys}
              element={<CreateSurvey />}
            />
          </Route>
          <Route path="*" element={<GlobalErrorPage />} />
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
