import { Button, Col, Row, Spin, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./ClinicUserDashboard.css";
import { Bar, Column } from "@ant-design/plots";
import API_BASE_URL from "../../../../env";
import axios from "axios";
import DashboardAppointment from "./dashboard_appointment/DashboardAppointment";
import { LoadingOutlined } from "@ant-design/icons";
import DashboardPatients from "./dashboard_patients/DashboardPatients";
import { useNavigate } from "react-router";
import {
  clinic_user_tabs_appointments,
  clinic_user_tabs_patients,
  routes_clinic_user_add_patient,
  routes_clinic_user_appointments,
  routes_clinic_user_patients,
} from "../../../../utils/clinic_user_constants/ClinicUserConstants";
import { UserAuth } from "../../../../context_api/ContextApi";
import no_total_patients from "../../../../assets/no_total_patients.png";
import no_treatments from "../../../../assets/no_treatments.png";
import no_todays from "../../../../assets/no_todays.png";
import no_patients from "../../../../assets/no_patients.png";
import no_appointment from "../../../../assets/no_appointment.png";

const ClinicUserDashboard = () => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 50, margin: "130px 0 0 0" }} spin />
  );
  const navigate = useNavigate();
  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");
  const branch_id = localStorage.getItem("main_branch_id") ? localStorage.getItem("main_branch_id") : localStorage.getItem("branch_id") ;
  const [appointmentData, setAppointmentData] = useState();
  const [patientData, setPatientData] = useState();
  const [todaysAppointmentData, setTodaysAppointmentData] = useState();
  const [topTreatmentsData, setTopTreatmentsData] = useState();
  const [totalPatientCountData, setTotalPatientCountData] = useState();
  const [spinner, setspinner] = useState(false);

  const data = totalPatientCountData && totalPatientCountData;

  const config = {
    data,
    xField: "key",
    yField: "value",
    yAxis: {
      grid: null,
    },
  };

  const barconfig = {
    data: topTreatmentsData && topTreatmentsData.reverse(),
    xField: "value",
    yField: "key",
    barWidthRatio: 0.8,
    label: {
      layout: [
        {
          type: "adjust-color",
        },
      ],
    },
  };

  const handleGetClinicUserDashboard = () => {
    setspinner(true);
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    if (branch_id) {
      axios
        .get(
          `${API_BASE_URL}/api/v1/clinic/${clinic_id}/dashboard?branch_id=${branch_id}`,
          config
        )
        .then((res) => {
          setTotalPatientCountData(res.data.data.total_patients_on_each_month);
          setTopTreatmentsData(res.data.data.top_treatments);
          setTodaysAppointmentData(res.data.data.today_appointments);
          setPatientData(res.data.data.patients);
          setAppointmentData(res.data.data.appointments);
          setspinner(false);
        })
        .catch((err) => {
          message.error("error occured");
          setspinner(false);
        });
    } else {
      axios
        .get(`${API_BASE_URL}/api/v1/clinic/${clinic_id}/dashboard`, config)
        .then((res) => {
          setTotalPatientCountData(res.data.data.total_patients_on_each_month);
          setTopTreatmentsData(res.data.data.top_treatments);
          setTodaysAppointmentData(res.data.data.today_appointments);
          setPatientData(res.data.data.patients);
          setAppointmentData(res.data.data.appointments);
          setspinner(false);
        })
        .catch((err) => {
          message.warning(err.response.data.data.errors);
          setspinner(false);
        });
    }
  };
  const { setTabActiveKey } = UserAuth();
  useEffect(() => {
    handleGetClinicUserDashboard();
  }, []);

  return (
    <div className="clinic-user-dashboard-main-div">
      <Helmet>
        <title>Patients</title>
      </Helmet>
      <Row
        style={{
          height: "6.564102564102564vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontWeight: 700,
            fontSize: 22,
            margin: "0 0 0 1.25vw",
            color: "#525252",
          }}
        >
          Clinic User Dashboard
        </span>
      </Row>
      <Spin
        indicator={antIcon}
        size="large"
        spinning={spinner && true}
        delay={500}
      >
        <Row style={{ display: "flex", gap: "1.25vw", margin: "0 0 0 1.25vw" }}>
          {/* total patient count col */}
          <Col>
            <div className="total-patients-main-div">
              <Row className="d-flex jc-between">
                <span className="total-patients-heading">Total Patients</span>
              </Row>
              <Row className="h-100 d-flex ai-center w-100 jc-center">
                {totalPatientCountData && totalPatientCountData.length > 0 ? (
                  <Column
                    {...config}
                    style={{
                      height: "80%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <div
                    className="d-flex jc-center ai-center flex-col"
                    style={{ gap: 15 }}
                  >
                    <img alt="" src={no_total_patients} style={{ width: 96 }} />
                    <span
                      style={{
                        color: "#525252",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      No data to display statistics.
                    </span>
                  </div>
                )}
              </Row>
            </div>
          </Col>
          {/* top treatments col */}
          <Col>
            <div className="top-treatments-main-div">
              <Row className="d-flex jc-between">
                <span className="total-patients-heading">Top Treatments</span>
              </Row>
              <Row className="h-100 d-flex ai-center w-100 jc-center">
                {topTreatmentsData && topTreatmentsData.length > 0 ? (
                  <Bar
                    {...barconfig}
                    style={{
                      height: "80%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <div
                    className="d-flex jc-center ai-center flex-col"
                    style={{ gap: 15 }}
                  >
                    <img alt="" src={no_treatments} style={{ width: 96 }} />
                    <span
                      style={{
                        color: "#525252",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      No data to display statistics.
                    </span>
                  </div>
                )}
              </Row>
            </div>
          </Col>
          {/* todays appointments col */}
          <Col>
            <div className="todays-appointments-main-div">
              <Row className="d-flex jc-between">
                <span className="total-patients-heading">
                  Todays Appointments
                </span>
              </Row>
              <Row style={{ margin: "20px 0 0 0" }}>
                <div
                  style={{
                    maxHeight: "30vh",
                    width: "100%",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  }}
                >
                  {todaysAppointmentData && todaysAppointmentData.length > 0 ? (
                    todaysAppointmentData.map((item) => {
                      return (
                        <DashboardAppointment
                          key={item.id}
                          data={item}
                          handleGetAllAppointmentsForClinic={
                            handleGetClinicUserDashboard
                          }
                        />
                      );
                    })
                  ) : (
                    <div
                      className="d-flex jc-center ai-center flex-col"
                      style={{ gap: 15, height: "25vh" }}
                    >
                      <img alt="" src={no_todays} style={{ width: 96 }} />
                      <span
                        style={{
                          color: "#525252",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        There are no appointments scheduled for today.
                      </span>
                    </div>
                  )}
                </div>
              </Row>
            </div>
          </Col>
          {/* patients col */}
          <Col>
            <div className="todays-appointments-main-div">
              <Row className="d-flex jc-between">
                <div>
                  <span className="total-patients-heading">Patients</span>
                </div>
                <div style={{ display: "flex", gap: 44, alignItems: "center" }}>
                  <u
                    style={{ color: "#2CA3FA" }}
                    onClick={() => {
                      navigate(routes_clinic_user_patients);
                      setTabActiveKey(clinic_user_tabs_patients);
                    }}
                  >
                    View all
                  </u>
                  {patientData && patientData.length > 0 && (
                    <Button
                      onClick={() => {
                        navigate(routes_clinic_user_add_patient);
                        setTabActiveKey(clinic_user_tabs_patients);
                      }}
                      className="appointment-btn"
                    >
                      Add Patient
                    </Button>
                  )}
                </div>
              </Row>
              <Row
                style={{
                  display: "flex",
                  gap: 5,
                  margin: "20px 0 0 0",
                  height: "30vh",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                {patientData && patientData.length > 0 ? (
                  patientData.map((item) => {
                    return <DashboardPatients item={item} />;
                  })
                ) : (
                  <div
                    className="d-flex jc-center ai-center flex-col w-100"
                    style={{ gap: 15 }}
                  >
                    <img alt="" src={no_patients} style={{ width: 96 }} />
                    <span
                      style={{
                        color: "#525252",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      No patients added in the clinic. Please add a patient to
                      continue...
                    </span>
                    <Button
                      onClick={() => {
                        navigate(routes_clinic_user_add_patient);
                        setTabActiveKey(clinic_user_tabs_patients);
                      }}
                      className="appointment-btn"
                    >
                      Add Patient
                    </Button>
                  </div>
                )}
              </Row>
            </div>
          </Col>
          {/* appointment requests col */}
          <Col>
            <div className="todays-appointments-main-div">
              <Row className="d-flex jc-between">
                <span className="total-patients-heading">
                  Appointment Requests
                </span>
                <u
                  style={{
                    color: "#2CA3FA",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(routes_clinic_user_appointments);
                    setTabActiveKey(clinic_user_tabs_appointments);
                  }}
                >
                  View All
                </u>
              </Row>
              <Row
                style={{
                  height: "30vh",
                }}
              >
                <Row className="w-100">
                  {appointmentData && appointmentData.length > 0 ? (
                    <Tabs
                      className="w-100"
                      defaultActiveKey="1"
                      items={[
                        {
                          key: "1",
                          label: (
                            <div>
                              Scheduled (
                              {appointmentData &&
                                appointmentData
                                  .filter(
                                    (item) => item.scheduled_by === "Patient"
                                  )
                                  .filter((item) => item.status === "Scheduled")
                                  .length}
                              )
                            </div>
                          ),
                          children: (
                            <div
                              style={{
                                maxHeight: "25vh",
                                width: "100%",
                                overflowY: "auto",
                                display: "flex",
                                flexDirection: "column",
                                gap: 4,
                              }}
                            >
                              {appointmentData &&
                                appointmentData
                                  .filter(
                                    (item) => item.scheduled_by === "Patient"
                                  )
                                  .filter((item) => item.status === "Scheduled")
                                  .map((item) => {
                                    return (
                                      <DashboardAppointment
                                        key={item.id}
                                        data={item}
                                        handleGetAllAppointmentsForClinic={
                                          handleGetClinicUserDashboard
                                        }
                                      />
                                    );
                                  })}
                            </div>
                          ),
                        },
                        {
                          key: "2",
                          label: (
                            <div>
                              Confirmed (
                              {appointmentData &&
                                appointmentData
                                  .filter(
                                    (item) => item.scheduled_by === "Patient"
                                  )
                                  .filter((item) => item.status === "Confirmed")
                                  .length}
                              )
                            </div>
                          ),
                          children: (
                            <div
                              style={{
                                maxHeight: "25vh",
                                width: "100%",
                                overflowY: "auto",
                                display: "flex",
                                flexDirection: "column",
                                gap: 4,
                              }}
                            >
                              {appointmentData &&
                                appointmentData
                                  .filter(
                                    (item) => item.scheduled_by === "Clinic"
                                  )
                                  .filter((item) => item.status === "Confirmed")
                                  .map((item) => {
                                    return (
                                      <DashboardAppointment
                                        key={item.id}
                                        data={item}
                                        handleGetAllAppointmentsForClinic={
                                          handleGetClinicUserDashboard
                                        }
                                      />
                                    );
                                  })}
                            </div>
                          ),
                        },
                        {
                          key: "3",
                          label: (
                            <div>
                              Completed (
                              {appointmentData &&
                                appointmentData
                                  .filter(
                                    (item) => item.scheduled_by === "Patient"
                                  )
                                  .filter((item) => item.status === "Completed")
                                  .length}
                              )
                            </div>
                          ),
                          children: (
                            <div
                              style={{
                                maxHeight: "25vh",
                                width: "100%",
                                overflowY: "auto",
                                display: "flex",
                                flexDirection: "column",
                                gap: 4,
                              }}
                            >
                              {appointmentData &&
                                appointmentData
                                  .filter((item) => item.status === "Complete")
                                  .map((item) => {
                                    return (
                                      <DashboardAppointment
                                        key={item.id}
                                        data={item}
                                        handleGetAllAppointmentsForClinic={
                                          handleGetClinicUserDashboard
                                        }
                                      />
                                    );
                                  })}
                            </div>
                          ),
                        },
                      ]}
                    />
                  ) : (
                    <div
                      className="d-flex jc-center ai-center flex-col w-100"
                      style={{ gap: 15 }}
                    >
                      <img alt="" src={no_appointment} style={{ width: 96 }} />
                      <span
                        style={{
                          color: "#525252",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        There are currently no appointments, please send an
                        appointment request.
                      </span>
                    </div>
                  )}
                </Row>
              </Row>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ClinicUserDashboard;
