import { Col, Dropdown, Row } from "antd";
import React, { useState } from "react";
import moment from "moment";
import report from "../../../../../assets/add-report-icon.png";
import { useNavigate } from "react-router-dom";
import OptionDots from "../../../../../assets/option-vertical-dots.png";
import GlobalPatientsXrays from "./global_patients_xray/GlobalPatientsXrays";
import ReportsModal from "../../../../clinic_admin/modules/clinic_admin_dashboard/clinic_admin_xrays_and_reports/reports_modal/ReportsModal";
import StaffProfilePicture from "../../../../clinic_admin/modules/clinic_admin_dashboard/StaffProfilePicture";
import { UserAuth } from "../../../../../context_api/ContextApi";

const PatientXrayReports = (props) => {
  const { item, xraysAndReports, handleGetXrayAndReportDetails } = props;
  const role = localStorage.getItem("role");
  const { getSubUuserPpermissions } = UserAuth();
  const navigate = useNavigate();
  const [openXrayModal, setOpenXrayModal] = useState(false);
  const [IsOpenFullReportModal, setIsOpenFullReportModal] = useState(false);
  const xray = "xray";
  const other = "other";

  const handleGetPatientDetailsById = (user_id) => {
    // if (
    //   role === "SUPER_ADMIN" ||
    //   (role !== "SUPER_ADMIN" &&
    //     getSubUuserPpermissions &&
    //     getSubUuserPpermissions.GET_PATIENT)
    // ) {
    navigate(`/super-admin/patient-profile/${user_id}`);
    // }
  };

  return (
    <>
      <Row
        style={{
          minHeight: 93,
          margin: "0 0 5px 0",
          padding: "0 23px",
          borderBottom: "0.5px solid #E3E3E3",
        }}
        className="d-flex ai-center"
      >
        <Col span={2}>
          <span
            style={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "16px",
              color: "#7d7d7d",
            }}
          >
            {moment(item.created_at).format("ddd DD/MM/YYYY HH:mm A")}
          </span>
        </Col>
        <Col span={4}>
          <Row>
            <Col>
              <StaffProfilePicture
                pro={
                  item.profile_picture_url &&
                  item.profile_picture_url.split(".com/")[1]
                }
              />
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 0 0 10px",
              }}
            >
              <Row>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 15,
                    textDecorationLine: "underline",
                    color: "#2CA3FA",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleGetPatientDetailsById(item.user_id);
                  }}
                >
                  {item.name}
                </span>
              </Row>
              <Row>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000000",
                  }}
                >
                  #PID
                  <span
                    style={{
                      color: "#7D7D7D",
                    }}
                  >
                    &nbsp;{item.user_id}
                  </span>
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={3}>
          <span
            style={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#7D7D7D",
            }}
          >
            Clinic Name
          </span>
        </Col>
        <Col span={4}>
          <span
            style={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#7D7D7D",
              display: "block",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {item.email}
          </span>
        </Col>
        <Col span={3}>
          <span
            style={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#7D7D7D",
            }}
          >
            {item.phone_number}
          </span>
        </Col>
        <Col span={3}>
          <span
            style={{ color: "#2CA3FA", cursor: "pointer", margin: "0 0 0 15%" }}
            onClick={() => {
              setOpenXrayModal(true);
              handleGetXrayAndReportDetails(item.user_id, xray);
            }}
          >
            View
          </span>
          <GlobalPatientsXrays
            openXrayModal={openXrayModal}
            setOpenXrayModal={setOpenXrayModal}
            xraysAndReports={xraysAndReports}
            // xrayData={xrayData}
          />
        </Col>
        <Col style={{ display: "flex", gap: 5 }} span={4}>
          <img src={report} alt="" style={{ width: 16 }} />
          <u
            style={{ color: "#2CA3FA", cursor: "pointer" }}
            onClick={() => {
              setIsOpenFullReportModal(true);
              handleGetXrayAndReportDetails(item.user_id, other);
            }}
          >
            Attachments
          </u>
          <ReportsModal
            IsOpenFullReportModal={IsOpenFullReportModal}
            setIsOpenFullReportModal={setIsOpenFullReportModal}
            xraysAndReports={xraysAndReports}
          />
        </Col>
        <Col span={1}>
          <Dropdown
            overlay={
              <div className="patient-delete-dropdown">
                {/* {(role !== "SUPER_ADMIN" &&
                  getSubUuserPpermissions &&
                  getSubUuserPpermissions.GET_PATIENT) ||
                role === "SUPER_ADMIN" ? ( */}
                  <Row
                    className="full-profile-row"
                    onClick={() => {
                      navigate(`/super-admin/patient-profile/${item.user_id}`);
                    }}
                  >
                    view profile
                  </Row>
                {/* ) : (
                  ""
                )} */}
              </div>
            }
            placement="bottomRight"
          >
            <img src={OptionDots} alt="" style={{ height: "40px" }} />
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default PatientXrayReports;
